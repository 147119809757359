import { lazyImport } from 'utils/lazyImport'

const { SuperAdminDashboardPage } = lazyImport(
  () => import('./SuperAdminDashboardPage'),
  'SuperAdminDashboardPage',
)

const { AgencyConfigurationPage } = lazyImport(
  () => import('./AgencyConfigurationPage'),
  'AgencyConfigurationPage',
)

export { AgencyConfigurationPage, SuperAdminDashboardPage }
