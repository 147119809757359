import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import CN from 'classnames'
import { useUpdateUserProfileDetails } from 'framework/api/methods'

import { UserDetailViewAndEditSection } from 'components/molecules'
import { isEmail } from 'utils'

type ContactTabViewProps = {
  [x: string]: any
}

export const ContactTabView = ({
  className,
  getUserProfileDetailsById,
  userData,
  notify,
  ...restProps
}: ContactTabViewProps) => {
  const { userDetails }: any = useSelector<any>((state) => state.user)
  const [userContactDetails, setUserContactDetails] = useState({
    email: '',
    phoneNumber: '',
    alternateEmail: '',
    emailSignature: '',
  })

  const ContactTabViewClasses = CN(`security-tab-view px-6`, className)

  /** Phone number Variables */
  const [phoneNumberVariables, setPhoneNumberVariables] = useState({
    key: Math.random(),
    isLoading: false,
  })

  /** Alternative mail Variables */
  const [alternativeEmailVariables, setAlternativeEmailVariables] = useState({
    key: Math.random(),
    isLoading: false,
  })

  /** Email signatuew Variables */
  const [emailSignatureVariables, setEmailSignatureVariables] = useState({
    key: Math.random(),
    isLoading: false,
  })

  useEffect(() => {
    const { email, phoneNumber, alternateEmail, emailSignature } =
      userData || {}

    setUserContactDetails({
      email,
      phoneNumber,
      alternateEmail,
      emailSignature,
    })
  }, [userData])

  /** API Call for Update user profile data */
  const {
    mutate: updateUserProfileDetailsMutate,
    isLoading: updateUserProfileDetailsIsLoading,
  } = useUpdateUserProfileDetails()

  /** Process update user data API call */
  async function updateUserProfileDetails(method: any) {
    handleIsLoading(true, method)
    updateUserProfileDetailsMutate(
      { obj: { ...userContactDetails, id: userDetails.id }, method },
      {
        onSuccess: ({ data: successData }: any) => {
          userDetails.id && getUserProfileDetailsById()
          notify({
            alertHeader: successData.messageTitle
              ? successData.messageTitle
              : 'Success!',
            alertBody: successData?.message,
            status: 'Success',
          })

          handleIsLoading(false, method)
          handleKey(method)
        },

        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: errData.messageTitle ? errData.messageTitle : 'Error!',
            alertBody: errData.message,
            status: 'Error',
          })
        },
      },
    )
  }

  const handleKey = (method: any) => {
    method === 'phoneNumber' &&
      setPhoneNumberVariables({
        ...phoneNumberVariables,
        key: Math.random(),
      })
    method === 'alternateEmail' &&
      setAlternativeEmailVariables({
        ...alternativeEmailVariables,
        key: Math.random(),
      })
    method === 'emailSignature' &&
      setEmailSignatureVariables({
        ...emailSignatureVariables,
        key: Math.random(),
      })
  }

  /** handle save button loading */
  const handleIsLoading = (isLoading: boolean, method: any) => {
    method === 'phoneNumber' &&
      setPhoneNumberVariables({ ...phoneNumberVariables, isLoading: isLoading })
    method === 'alternateEmail' &&
      setAlternativeEmailVariables({
        ...alternativeEmailVariables,
        isLoading: isLoading,
      })
    method === 'emailSignature' &&
      setEmailSignatureVariables({
        ...emailSignatureVariables,
        isLoading: isLoading,
      })
  }

  /** handle save button click */
  const onSaveButtonClick = (method: any) => {
    if (method === 'alternateEmail')
      isEmail(userContactDetails.alternateEmail) ||
      userContactDetails.alternateEmail === ''
        ? updateUserProfileDetails(method)
        : notify({
            alertHeader: 'Invalid input type!',
            alertBody: '',
            status: 'Warning',
          })
    else {
      updateUserProfileDetails(method)
    }
  }

  return (
    <div className={ContactTabViewClasses} {...restProps}>
      {/* tab container */}
      <div className='divide-y divide-Gray-100'>
        {/* email section */}
        <UserDetailViewAndEditSection
          sectionTitle='Email'
          sectionValue={userContactDetails?.email}
          textFieldLabel='Email'
          inputFieldValue={
            userContactDetails?.email && userContactDetails?.email
          }
          onChangeInputField={(e: any) => {
            setUserContactDetails({
              ...userContactDetails,
              email: e.target.value,
            })
          }}
          onSaveButtonClick={() => {
            onSaveButtonClick('email')
          }}
          saveIsLoading={updateUserProfileDetailsIsLoading}
          editableMode={false}
        />

        {/* phone number section */}
        <UserDetailViewAndEditSection
          sectionTitle='Phone number'
          key={phoneNumberVariables?.key}
          sectionValue={userContactDetails?.phoneNumber}
          textFieldLabel='Phone number'
          inputFieldValue={
            userContactDetails?.phoneNumber && userContactDetails?.phoneNumber
          }
          onChangeInputField={(number) => {
            setUserContactDetails({
              ...userContactDetails,
              phoneNumber: number,
            })
          }}
          onSaveButtonClick={() => {
            onSaveButtonClick('phoneNumber')
          }}
          onCancel={() => {
            setPhoneNumberVariables({
              ...phoneNumberVariables,
              key: Math.random(),
            })
            setUserContactDetails({
              ...userContactDetails,
              phoneNumber: userData?.phoneNumber,
            })
          }}
          saveIsLoading={phoneNumberVariables.isLoading}
          type='phone'
        />

        {/* alternate email section */}
        <UserDetailViewAndEditSection
          sectionTitle='Alternate email'
          key={alternativeEmailVariables?.key}
          placeholder='your@email.com'
          sectionValue={userContactDetails?.alternateEmail}
          textFieldLabel='Alternate email'
          inputFieldValue={
            userContactDetails?.alternateEmail &&
            userContactDetails?.alternateEmail
          }
          onChangeInputField={(e: any) => {
            setAlternativeEmailVariables({
              ...alternativeEmailVariables,
            })
            setUserContactDetails({
              ...userContactDetails,
              alternateEmail: e.target.value,
            })
          }}
          onSaveButtonClick={() => {
            onSaveButtonClick('alternateEmail')
          }}
          onCancel={() => {
            setAlternativeEmailVariables({
              ...alternativeEmailVariables,

              key: Math.random(),
            })
            setUserContactDetails({
              ...userContactDetails,
              alternateEmail: userData?.alternateEmail,
            })
          }}
          saveIsLoading={alternativeEmailVariables?.isLoading}
        />

        {/* email signature section */}
        <UserDetailViewAndEditSection
          sectionTitle='Email signature'
          placeholder='Jane Doe'
          key={emailSignatureVariables?.key}
          sectionValue={userContactDetails?.emailSignature}
          textFieldLabel='Email signature'
          inputFieldValue={
            userContactDetails?.emailSignature &&
            userContactDetails?.emailSignature
          }
          onChangeInputField={(e: any) => {
            setUserContactDetails({
              ...userContactDetails,
              emailSignature: e.target.value,
            })
          }}
          type='textarea'
          onSaveButtonClick={() => {
            onSaveButtonClick('emailSignature')
          }}
          onCancel={() => {
            setEmailSignatureVariables({
              ...emailSignatureVariables,
              key: Math.random(),
            })
            setUserContactDetails({
              ...userContactDetails,
              emailSignature: userData?.emailSignature,
            })
          }}
          saveIsLoading={emailSignatureVariables?.isLoading}
        />
      </div>
    </div>
  )
}
