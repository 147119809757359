import { useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { Button } from '@labourhub/labour-hub-ds'
import { assessmentTypes } from 'features/candidates/data/assessmentTypes'
import { AssessmentLibraryPreviewModal } from 'features/questionnaires/components/AssessmentTab/AssessmentLibraryCardOverview'

import { Card } from 'components/atoms/Card'
import { RowWithAlignment } from 'components/atoms/Row'
import { cn, getS3File } from 'utils'

import { type Addon } from '../SendAssessmentModal'

import DeleteAddonConfirmationModal from './DeleteAddonConfirmationModal'

const AddonCard = ({
  addon,
  isSelected = false,
  handleAddAssessment,
  handleRemoveAssessment,
  isDisabled,
}: {
  addon: Addon
  isSelected?: boolean
  handleAddAssessment: (addon: Addon) => void
  handleRemoveAssessment?: (addon: Addon) => void
  isDisabled: boolean
}) => {
  const [isShowPreviewModal, setIsShowPreviewModal] = useState(false)
  const [isOpenAddonDelete, setIsOpenAddonDelete] = useState(false)

  const handleDeleteConfirm = () => {
    handleRemoveAssessment ? handleRemoveAssessment(addon) : null
  }

  return (
    <>
      <Card
        className={cn('w-full h-[132px] flex flex-row gap-7', {
          'bg-Cobalt-50': isSelected,
          'bg-white': !isSelected,
        })}>
        <div className=' w-[133px] h-[92px]'>
          <img
            src={getS3File(addon.imageUrl)}
            alt='plat'
            className='object-fill w-[133px] h-[92px]'
          />
        </div>
        <div className=' w-auto flex flex-col h-[92px]'>
          <div className='flex gap-3'>
            <h1
              className='text-[16px] leading-8 font-SemiBold truncate max-w-[260px]'
              data-tip={addon.assessmentName}
              data-for={addon.assessmentId}>
              <ReactTooltip
                place='right'
                id={addon.assessmentId}
                effect='solid'
                textColor='#FFFFFF'
                backgroundColor='#4B5563'
                delayShow={800}
              />
              {addon.assessmentName}
            </h1>
            <span className='my-auto text-extra-small text-[#105DA4] font-Medium rounded-full bg-Cobalt-50 px-[6px] flex items-center gap-1'>
              <i className='ri-coins-line text-small' />
              <span>
                {addon.credits}{' '}
                {(addon?.credits || 1) > 1 ? ' credits' : 'credit'}
              </span>
            </span>
          </div>
          <p className='text-small text-Gray-500 font-Medium'>
            {addon.duration}{' '}
            {parseInt(addon.duration) > 1 ? ' minutes' : 'minute'} to complete
          </p>
          {/* Buttons in one line */}
          <RowWithAlignment
            className='w-full gap-4 mt-auto  '
            justifyAlignment='start'>
            <Button
              className={cn('h-[32px] text-extra-small font-Medium w-[120px]', {
                'invisible':
                  addon.assessmentName === assessmentTypes.PersonalityTest,
                '': addon.assessmentName != assessmentTypes.PersonalityTest,
              })}
              theme='white'
              textTheme='blue'
              onClick={() => {
                setIsShowPreviewModal(true)
              }}
              iconRight={<i className='ri-external-link-line text-heading-5' />}
              isDisabled={
                addon.assessmentName === assessmentTypes.PersonalityTest
              }>
              Preview
            </Button>
            {isSelected ? (
              <Button
                className='h-[32px] px-[9px] text-extra-small font-Medium'
                iconLeft={<i className='ri-delete-bin-line text-[16px]' />}
                onClick={() => setIsOpenAddonDelete(true)}
                textTheme='white'
                theme='red'>
                Remove
              </Button>
            ) : (
              <Button
                className='h-[32px] px-[9px] text-extra-small font-Medium w-[150px]'
                textTheme='white'
                onClick={() => handleAddAssessment(addon)}
                isDisabled={isDisabled}
                iconLeft={<i className='ri-add-line text-base text-white' />}>
                Add Assessment
              </Button>
            )}
          </RowWithAlignment>
        </div>
      </Card>
      {isShowPreviewModal && addon.assessmentId && (
        <AssessmentLibraryPreviewModal
          isModalActive={isShowPreviewModal}
          setIsModalActive={setIsShowPreviewModal}
          templateId={addon.assessmentId}
          templateName={addon?.assessmentName}
        />
      )}
      <DeleteAddonConfirmationModal
        isOpenAddonDelete={isOpenAddonDelete}
        setIsOpenAddonDelete={setIsOpenAddonDelete}
        handleDeleteConfirm={handleDeleteConfirm}
        deleteMessage='Removing the add-on assessment will ensure it will not be sent to the candidate.'
      />
    </>
  )
}

export default AddonCard
