import ReactTooltip from 'react-tooltip'
import { CandidateReferee } from 'framework/api/methods'
import moment from 'moment'
import { ReferenceCheckType } from 'types'

import { ColumnWithAlignment } from 'components/atoms/Column'

import CardDetail from './CardDetail'

type RefereeCardDetailsProp = {
  referee: CandidateReferee
}

const RefereeCardDetails = ({ referee }: RefereeCardDetailsProp) => {
  return (
    <div className='flex justify-start w-full my-2 gap-[30px]'>
      <ColumnWithAlignment
        itemAlignment='start'
        justifyAlignment='start'
        className='gap-2 w-[325px]'>
        <div className='flex items-start'>
          <i className='flex items-center mr-3 ri-phone-fill text-small text-[#09121F]' />
          <a
            href={`tel:${referee?.phone}`}
            className='break-words text-small font-Medium text-Gray-600'>
            {referee.phone}
          </a>
        </div>

        <div className='flex items-start'>
          <i className='flex items-center mr-3 ri-mail-fill text-small text-[#09121F]' />
          <a
            href={`mailto:${referee?.email}`}
            className='break-all text-small font-Medium text-Gray-600'>
            {referee.email}
          </a>
        </div>

        {referee?.referenceType?.type != ReferenceCheckType.FriendFamily && (
          <div className='flex items-start'>
            <i className='flex items-center mr-3 ri-suitcase-fill text-small text-[#09121F]' />
            <span className='break-words text-small font-Medium text-Gray-600 '>
              {referee.jobTitle} at {referee.company}
            </span>
          </div>
        )}
      </ColumnWithAlignment>
      <div className='bg-Gray-200 w-[1.5px]'></div>
      <ColumnWithAlignment
        itemAlignment='start'
        justifyAlignment='start'
        className='gap-2 w-[385px]'>
        <CardDetail
          label='Relationship to candidate'
          data={referee.candidateRelation ? referee.candidateRelation : 'NA'}
          isIcon={true}
          icon='ri-user-heart-line text-[#6B7280]'
        />

        {referee?.referenceType?.type == ReferenceCheckType.Professional ? (
          <CardDetail
            label='Referee Company'
            data={referee.company ? referee.company : 'NA'}
            isIcon={true}
            icon='ri-building-4-line text-[#6B7280]'
          />
        ) : null}

        {referee?.referenceType?.type != ReferenceCheckType.FriendFamily ? (
          <CardDetail
            label='Referee job position'
            data={referee.jobTitle ? referee.jobTitle : 'NA'}
            isIcon={true}
            icon='ri-suitcase-2-line text-[#6B7280]'
          />
        ) : (
          <div />
        )}
        {referee?.referenceType?.type == ReferenceCheckType.Employment && (
          <CardDetail
            label='Work Period'
            isIcon={true}
            icon='ri-history-line text-[#6B7280]'
            data={
              referee.isCurrentlyWorking
                ? 'Currently working'
                : referee.endDate && referee.startDate
                ? `${moment(referee.startDate).format(
                    'DD MMM YYYY',
                  )} - ${moment(referee.endDate).format('DD MMM YYYY')}`
                : 'Not Available'
            }
            dataPrefix={
              referee?.state == 'RESPONSE_RECEVIED' ? (
                <>
                  <i
                    data-tip='Work Period'
                    data-for='Work Period'
                    className='text-[18px] ri-checkbox-circle-fill text-Green-600'
                  />
                  <ReactTooltip
                    place='top'
                    className='absolute w-auto'
                    id='Work Period'
                    effect='solid'
                    textColor='#FFFFFF'
                    backgroundColor='#1F2937'>
                    <div>Verified by the Referee</div>
                  </ReactTooltip>
                </>
              ) : undefined
            }
          />
        )}
      </ColumnWithAlignment>
    </div>
  )
}

export default RefereeCardDetails
