import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  AppSettingKey,
  getAgencyAppSettings,
  getSettingValue,
} from 'features/settings'

import { RefereeTypeAccordionContent } from './RefereeTypeAccordionContent'
import { SpecificRefereeAccordion } from './SpecificRefereeAccordion'

export type RefereeTypeAccordionContentType = {
  type: string
  note: string
  specificEmployer: string
}

export const RequestSpecificRefereeForm = ({
  refereeCount,
  setReferenceTypes,
}) => {
  const [refereeDetails, setRefereeDetails] = useState<
    RefereeTypeAccordionContentType[]
  >([])

  const agencyAppSettings = useSelector(getAgencyAppSettings)
  const defaultRefType = getSettingValue(
    AppSettingKey.DefaultReferenceCheckType,
    agencyAppSettings,
  )

  useEffect(() => {
    const initialRefereeDetails: RefereeTypeAccordionContentType[] = Array.from(
      { length: refereeCount },
      () => ({
        type: defaultRefType,
        note: '',
        specificEmployer: '',
      }),
    )
    setRefereeDetails(initialRefereeDetails)
  }, [refereeCount])

  const sections = refereeDetails.map((referee, index) => ({
    sectionIndex: index,
    title: `Referee ${index + 1}`,
    content: (
      <RefereeTypeAccordionContent
        refereeDetails={referee}
        setRefereeDetails={(updatedReferee) => {
          const updatedReferees: any = [...refereeDetails]
          updatedReferees[index] = updatedReferee
          setRefereeDetails(updatedReferees)
        }}
      />
    ),
  }))

  useEffect(() => {
    setReferenceTypes(refereeDetails)
  }, [refereeDetails])

  return (
    <div className='flex flex-col w-full p-5'>
      <div className='text-heading-5 font-Medium'>
        Request Specific Referee For:
      </div>

      <div className='mt-4 text-Gray-500'>
        <span className='text-Red-600 font-Bold'>Optional:</span> Request a
        specific reference or referee type. If not specified, it will default to
        your system settings.
      </div>

      <div className='w-full mt-5'>
        <SpecificRefereeAccordion items={sections} />
      </div>
    </div>
  )
}
