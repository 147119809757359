import { QuestionnaireCategoryType } from 'api/questionnaires'

export const questionnairesTemplateSurveys = [
  {
    value: QuestionnaireCategoryType.CandidateSurvey,
    label: QuestionnaireCategoryType.CandidateSurvey,
  },
  {
    value: QuestionnaireCategoryType.EmployeeSurvey,
    label: QuestionnaireCategoryType.EmployeeSurvey,
  },
]
