import { useNavigate } from 'react-router-dom'
import { Button } from '@labourhub/labour-hub-ds'
import PageNotFound from 'assets/animations/404-page-not-found.json'
import CN from 'classnames'
import { useLottie } from 'lottie-react'

type Error404Props = {
  [x: string]: any
}

export const Error404 = ({ className, ...restProps }: Error404Props) => {
  const navigate = useNavigate()

  const Error404Classes = CN(
    `error404 flex flex-col items-center justify-center w-ful h-screen`,
    className,
    {},
  )

  const AnimatedView = () => {
    const options = {
      animationData: PageNotFound,
      loop: true,
      autoplay: true,
      speed: 5,
    }

    const { View } = useLottie(options)

    return View
  }

  return (
    <div className={Error404Classes} {...restProps}>
      <div className='w-[300px]'>
        <AnimatedView />
      </div>

      <span className='mt-1 text-heading-4 text-Gray-800 font-Medium'>
        We couldn't find the content you were looking for
      </span>

      <span className='mt-2 text-Gray-600'>
        If the problem reoccurs please
        <a
          className='text-Cobalt-500 pl-2 active:outline-none focus:outline-none'
          href='https://www.refhub.com.au/help-center'
          target='_blank'>
          contact us
        </a>
      </span>

      <Button
        textTheme='white'
        iconLeft={
          <i className='ri-arrow-left-s-line text-[16px] font-SemiBold' />
        }
        theme='cobalt'
        onClick={() => {
          navigate(-1)
        }}
        className='mt-10'>
        Go Back to the Previous Page
      </Button>
    </div>
  )
}
