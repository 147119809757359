import { cn } from 'utils'

type OnboardingFooterProps = {
  hasMaxPadding?: boolean
}

export const OnboardingFooter = ({
  hasMaxPadding = false,
}: OnboardingFooterProps) => {
  return (
    <div
      className={cn(
        'main-layout__Footer absolute bottom-0 pb-4 flex justify-between items-center w-full',
        {
          'px-[110px]': hasMaxPadding,
          'px-6': !hasMaxPadding,
        },
      )}>
      {/* footer left */}
      <div className='flex flex-col text-extra-small'>
        <div className='flex divide-x divide-Gray-200'>
          <a
            className='pr-2 text-Cobalt-500 active:outline-none focus:outline-none'
            href='https://www.refhub.com.au/privacy-policy'
            target='_blank'>
            Terms and conditions
          </a>

          <a
            className='pl-2 text-Cobalt-500 active:outline-none focus:outline-none'
            href='https://craft.me/s/1E9yDjlzX5Ax2A'
            target='_blank'>
            Help
          </a>
        </div>
      </div>

      {/* footer left */}
      <div className='flex flex-col text-extra-small md:flex-row'>
        <p className='text-Gray-500'>
          © {new Date().getFullYear()} Refhub. All rights reserved.
        </p>
      </div>
    </div>
  )
}
