import CN from 'classnames'
import { PaymentErrorType } from 'features/payments'
import { getPaymentErrorMessage } from 'features/payments/utils'

import { RowWithAlignment } from 'components/atoms/Row'

type FixedInvoiceNotificationBoxProps = {
  isSuccess: boolean
  message: string
  paymentError?: PaymentErrorType
}

export const FixedInvoiceNotificationBox = ({
  isSuccess,
  message,
  paymentError,
}: FixedInvoiceNotificationBoxProps) => {
  return (
    <div className='w-[100%] h-fit bg-Cobalt-200'>
      <RowWithAlignment
        className={CN('px-4 h-fit py-3', {
          'bg-Green-50 border border-Green-300': isSuccess,
          'bg-Red-50 border border-Red-300': !isSuccess,
        })}>
        <div className='flex flex-col items-center justify-center w-full h-fit'>
          <i
            className={CN(
              'text-[58px] h-[58px] flex justify-center items-center',
              {
                'ri-checkbox-circle-fill text-[#2AB346]': isSuccess,
                'ri-close-circle-fill text-[#F44336]': !isSuccess,
              },
            )}></i>

          <div className='w-full text-center'>
            <p className='text-heading-4 text-[#000] font-Medium'>{message}</p>
          </div>

          {!isSuccess && (
            <span className='text-center text-Gray-600'>
              {getPaymentErrorMessage(paymentError)}
            </span>
          )}
        </div>
      </RowWithAlignment>
    </div>
  )
}
