import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

export type QuestionnaireLibraryDto = {
  id: string
  role: string
  imageUrl: string
  timeToComplete: string
  description: string
  type: string
  questionCount: string
  customTemplateId?: string
  isPublished: boolean
  isDefault: boolean
  isDescription: boolean
  isSentToSurveyResponse: boolean
}

type QuestionnaireLibraryQueryParams = {
  search: string
  type: QuestionnaireCategoryType
  cId?: string
}

export enum QuestionnaireCategoryType {
  ReferenceCheck = 'ReferenceCheck',
  CandidateSurvey = 'CandidateSurvey',
  EmployeeSurvey = 'EmployeeSurvey',
}

const getQuestionnaireLibraryList = async ({ queryKey }: any) => {
  const { search, type, cId } = queryKey[1] as QuestionnaireLibraryQueryParams

  let URI = `/questionnaires/library?type=${type}`

  if (search) {
    URI += `&search=${search}`
  }
  if (cId) {
    URI += `&CId=${cId}`
  }

  const res = await get(URI)
  return res.data.questionnaireLibrary
}

export const useGetQuestionnaireLibraryList = (
  queryParams: QuestionnaireLibraryQueryParams,
  onSuccess: (data: QuestionnaireLibraryDto[]) => void,
) => {
  return useQuery(
    ['questionnaire_library_list', queryParams],
    getQuestionnaireLibraryList,
    {
      enabled: true,
      refetchOnWindowFocus: false,
      onSuccess,
      onError: () => {
        Toast({
          alertHeader: 'Error fetching questionnaire library list',
          status: 'Error',
        })
      },
    },
  )
}
