// eslint-disable-next-line simple-import-sort/imports
import { useEffect, useState } from 'react'
import successLottie from 'assets/animations/import-success.json'
import CN from 'classnames'
import {
  Alerts,
  Button,
  TextField,
  ProgressBar,
} from '@labourhub/labour-hub-ds'
import { passwordStrengthCalculate } from 'utils/passwordStrengthCalculate'
import { useLottie } from 'lottie-react'

import { useNavigate, useSearchParams } from 'react-router-dom'
import { useResetPassword } from 'framework/api/methods'

export const ResetPassword = () => {
  const AnimatedView = () => {
    const options = {
      animationData: successLottie,
      loop: false,
      autoplay: true,
    }

    const { View } = useLottie(options)
    return View
  }

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [showSuccessScreen, setShowSuccessScreen] = useState(false)
  const [alertDetails, setAlertDetails] = useState({
    isActive: false,
    messageHeader: '',
    messageBody: '',
  })

  const [dataObject, setDataObject] = useState({
    username: '',
    resetToken: '',
    password: '',
    confirmPassword: '',
  })

  const [helperText, setHelperText] = useState(
    'Your password must be at least 8 characters',
  )

  const [showProgressBar, setShowProgressBar] = useState(false)

  const [showNewPassword, setShowNewPassword] = useState(false)

  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const [passwordStrength, setPasswordStrength] = useState(0)

  /** get email from route params */
  useEffect(() => {
    const username: any = searchParams.get('username')
    const resetToken: any = searchParams.get('resetToken')

    /** exit from UI when there is no token or username */
    if (
      username === null ||
      resetToken === null ||
      username === '' ||
      resetToken == ''
    ) {
      navigate('/')
    }

    setDataObject({
      ...dataObject,
      username: username,
      resetToken: resetToken,
    })
  }, [searchParams])

  /** handle new password field on change */
  const newPasswordHandle = (e: any) => {
    const value = e.target.value
    setDataObject({ ...dataObject, password: value })

    /** Set helper text value,password strength bar visibility according to new password field value. */
    if (value !== '') {
      setHelperText('')
      setShowProgressBar(true)
      setPasswordStrength(passwordStrengthCalculate(value))
    } else {
      setHelperText('Your password must be at least 8 characters')
      setShowProgressBar(false)
    }
  }

  /** Reset password User API Call ------> Mutate */
  const { mutate: resetPasswordMutate, isLoading: resetPasswordIsLoading } =
    useResetPassword()

  /** Process the reset password submission */
  async function resetPassword(dataObj: any) {
    resetPasswordMutate(dataObj, {
      onSuccess: () => {
        setShowSuccessScreen(true)
      },
      onError: ({ response: { data: errData } }: any) => {
        setAlertDetails({
          ...alertDetails,
          isActive: true,
          messageHeader: errData?.messageTitle
            ? errData?.messageTitle
            : 'Error!',
          messageBody: errData?.message,
        })
      },
    })
  }

  /** handle reset password button */
  const handleResetPasswordButton = () => {
    resetPassword(dataObject)
  }

  return (
    <div className='flex flex-col main-container'>
      {!showSuccessScreen && (
        <div className='reset-password-form w-[380px] flex flex-col relative pt-16'>
          {/* header */}
          <span className='pb-3 text-heading-2 font-Medium text-Gray-800'>
            Reset Your Password
          </span>

          {/* header sub text */}
          <span className='pb-10 text-small font-Regular text-Gray-700'>
            It’s time to change your password to a new one.
          </span>

          {/* New password form field with progress bar */}
          <div
            className={CN('w-full', {
              'pb-4': dataObject.password !== '',
              'pb-6': dataObject.password === '',
            })}>
            {/* text field */}
            <TextField
              type={showNewPassword ? 'text' : 'password'}
              label='New password'
              placeholder='••••••••'
              value={dataObject.password}
              helperText={helperText}
              iconAfter={
                dataObject.password !== '' ? (
                  <i
                    className={CN('hover:cursor-pointer', {
                      'ri-eye-off-line': showNewPassword == false,
                      'ri-eye-line': showNewPassword == true,
                    })}
                  />
                ) : (
                  <></>
                )
              }
              iconAfterOnClick={() => {
                setShowNewPassword(!showNewPassword)
              }}
              onChange={newPasswordHandle}
            />

            {/* password strength bar */}
            {showProgressBar && (
              <ProgressBar progressCount={passwordStrength} isForPassword />
            )}
          </div>

          {/* Confirm password form field */}
          <div className='w-full pb-6'>
            <TextField
              type={showConfirmPassword ? 'text' : 'password'}
              label='Confirm password'
              placeholder='••••••••'
              value={dataObject.confirmPassword}
              iconAfter={
                dataObject.confirmPassword !== '' ? (
                  <i
                    className={CN('hover:cursor-pointer', {
                      'ri-eye-off-line': showConfirmPassword == false,
                      'ri-eye-line': showConfirmPassword == true,
                    })}
                  />
                ) : (
                  <></>
                )
              }
              iconAfterOnClick={() => {
                setShowConfirmPassword(!showConfirmPassword)
              }}
              onChange={(e: any) => {
                setDataObject({
                  ...dataObject,
                  confirmPassword: e.target.value,
                })
              }}
            />
          </div>

          {/* Reset password button */}
          <Button
            isBlock
            className='mb-6'
            isLoading={resetPasswordIsLoading}
            onClick={handleResetPasswordButton}
            isDisabled={
              dataObject.password === '' ||
              dataObject.confirmPassword === '' ||
              dataObject.password !== dataObject.confirmPassword ||
              passwordStrength < 81
                ? true
                : false
            }>
            Reset My Password
          </Button>

          {/* error alert */}
          {alertDetails.isActive && (
            <Alerts
              alertBody={alertDetails.messageBody}
              alertHeader={alertDetails.messageHeader}
              className='bottom-[-120px] absolute'
              onCloseIconClick={() => {
                setAlertDetails({
                  ...alertDetails,
                  isActive: false,
                })
              }}
              status='Error'
            />
          )}
        </div>
      )}
      {showSuccessScreen && (
        <div className='flex flex-col w-[380px] items-center pt-28'>
          <span className='font-Medium text-heading-2 text-Gray-800'>
            Congratulations
          </span>

          <span className='font-Regular text-small text-Gray-700 pt-3'>
            Your password has been changed.
          </span>

          {/* success circle */}
          <div className='flex justify-center w-[176px] mt-[41px]'>
            <AnimatedView />
          </div>

          <Button
            isBlock
            className='mb-6 mt-[23px]'
            onClick={() => {
              navigate('/auth/login')
            }}>
            Go to Login Page
          </Button>
        </div>
      )}
    </div>
  )
}
