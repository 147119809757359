/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { Button } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import {
  getSubscriptionStore,
  SubscriptionPlanType,
} from 'features/subscriptions'
import { SubscriptionPlanPackageBadge } from 'features/subscriptions/components/SubscriptionPlanPackageBadge'

import {
  addHttpsIfNeeded,
  dateToReadableString,
  removeHttpsOrHttp,
} from 'utils'

type AgencyProfileCardProps = {
  isImage?: boolean
  isSecondaryImage?: boolean
  isEditEnabled?: boolean
  logoUrl?: string | undefined
  onBasicInfoEditClick?: () => void | undefined
  onChangePhotoClick?: () => void | undefined
  [x: string]: any
}

export const AgencyProfileCard = ({
  agencyProfileDetails,
  isImage,
  isSecondaryImage,
  isEditEnabled = true,
  logoUrl,
  onBasicInfoEditClick,
  onChangePhotoClick,
  setIsSecondaryLogoModalOpen,
  ...restProps
}: AgencyProfileCardProps) => {
  const [optionDropDown, setOptionDropDown] = useState(false)
  const {
    activeSubscriptions: { fixedSubscription },
  } = useSelector(getSubscriptionStore)

  const formattedWebsite = useMemo(() => {
    return agencyProfileDetails?.website
      ? addHttpsIfNeeded(agencyProfileDetails.website)
      : '-'
  }, [agencyProfileDetails?.website])

  return (
    <div className='' {...restProps}>
      <div className='flex justify-between w-full h-fit border-[1px] border-Gray-200 rounded-md bg-white'>
        <div className='flex gap-10 pl-[27px] py-5'>
          <div className='relative '>
            {/** Agency Logo Section */}
            <div className='flex justify-center items-center w-[132px] h-[132px] rounded-full object-cover overflow-hidden'>
              {isImage ? (
                <img src={logoUrl} alt='' className='object-contain w-full' />
              ) : (
                <div className='flex justify-center items-center rounded-full overflow-hidden w-[132px] h-[132px] bg-Gray-200'>
                  <i className='ri-image-2-fill text-[100px] text-Gray-400'></i>
                </div>
              )}

              {isEditEnabled && (
                <div className='pl-[84px] pt-[84px] absolute'>
                  {/** Agency Logo Edit Button */}
                  <div
                    className='flex items-center justify-center w-10 h-10 bg-white rounded-full shadow-md cursor-pointer'
                    onClick={() => {
                      setOptionDropDown(!optionDropDown)
                    }}>
                    <i className='ri-pencil-line text-Cobalt-500 text-heading-5' />
                  </div>
                </div>
              )}
            </div>

            {/** Logo changing and removing option */}
            {optionDropDown && (
              <div
                className={CN(
                  'flex flex-col justify-start shadow-md border-[2px] border-Gray-100 rounded-md py-1 mt-1 z-40 bg-white absolute select-none',
                  {
                    'w-[230px] left-2': isSecondaryImage,
                    'w-[200px] -left-6': !isSecondaryImage,
                  },
                )}>
                <div
                  className='px-3 py-2 cursor-pointer select-none hover:bg-Gray-100'
                  onClick={() => {
                    onChangePhotoClick && onChangePhotoClick()
                    setOptionDropDown(!optionDropDown)
                    setIsSecondaryLogoModalOpen(false)
                  }}>
                  {isImage ? `Change Primary Logo` : `Add Primary Logo`}
                </div>

                <div
                  className='px-3 py-2 cursor-pointer hover:bg-Gray-100'
                  onClick={() => {
                    onChangePhotoClick && onChangePhotoClick()
                    setOptionDropDown(!optionDropDown)
                    setIsSecondaryLogoModalOpen(true)
                  }}>
                  {isSecondaryImage
                    ? `Change Secondary Logo`
                    : ` Add Secondary Logo`}
                </div>
              </div>
            )}
          </div>

          <div className='flex flex-col'>
            <span className='font-Bold text-heading-4 text-Gray-800'>
              {agencyProfileDetails?.name ? agencyProfileDetails?.name : '-'}
            </span>

            <div className='flex gap-10'>
              <div>
                {/** ABN  */}
                <div className='flex pt-5'>
                  <div className='w-[120px] font-Medium text-extra-small text-Gray-600'>
                    ABN
                  </div>

                  <div className='font-SemiBold text-small text-Gray-800'>
                    {agencyProfileDetails?.abn
                      ? agencyProfileDetails?.abn
                      : '-'}
                  </div>
                </div>

                {/** Website */}
                <div className='flex pt-3'>
                  <div className='w-[120px] font-Medium text-extra-small text-Gray-600'>
                    Website
                  </div>

                  <a
                    href={formattedWebsite}
                    target='_blank'
                    className='font-SemiBold text-small text-Cobalt-600 w-[200px] line-clamp-1 break-words'
                    data-for='WebSite'>
                    {removeHttpsOrHttp(formattedWebsite)}
                  </a>
                  <ReactTooltip
                    place='top'
                    id='WebSite'
                    effect='solid'
                    textColor='#FFFFFF'
                    backgroundColor='#4B5563'
                  />
                </div>

                {/** Company Size */}
                <div className='flex pt-3'>
                  <div className='w-[120px] font-Medium text-extra-small text-Gray-600'>
                    Company Size
                  </div>

                  <div className='font-SemiBold text-small text-Gray-800'>
                    {agencyProfileDetails?.size
                      ? agencyProfileDetails?.size
                      : '-'}
                  </div>
                </div>
              </div>

              <div>
                {/** Phone number */}
                <div className='flex pt-5'>
                  <div className='w-[120px] font-Medium text-extra-small text-Gray-600'>
                    Phone
                  </div>

                  <div className='font-SemiBold text-small text-Gray-800'>
                    <a
                      href={`tel:${agencyProfileDetails?.phone}`}
                      className='text-small font-Medium'>
                      {agencyProfileDetails?.phone
                        ? agencyProfileDetails?.phone
                        : '-'}
                    </a>
                  </div>
                </div>

                {/** Company Email */}
                <div className='flex pt-3'>
                  <div className='w-[120px] font-Medium text-extra-small text-Gray-600'>
                    Email
                  </div>

                  <div className='font-SemiBold text-small text-Cobalt-600'>
                    <a
                      href={`mailto:${agencyProfileDetails?.email}`}
                      className='break-words text-small font-Medium line-clamp-1'>
                      {agencyProfileDetails?.email
                        ? agencyProfileDetails?.email
                        : '-'}
                    </a>
                  </div>
                </div>

                {/** Address */}
                <div className='flex pt-3'>
                  <div className='w-[120px] font-Medium text-extra-small text-Gray-600'>
                    Address
                  </div>

                  <div className='font-SemiBold text-small text-Gray-800 max-w-[500px] line-clamp-1'>
                    {agencyProfileDetails?.address !== null
                      ? `${
                          agencyProfileDetails?.address?.lineOne &&
                          `${agencyProfileDetails?.address?.lineOne},`
                        }
                      ${
                        agencyProfileDetails?.address?.suburb &&
                        `${agencyProfileDetails?.address?.suburb},`
                      }
                      ${
                        agencyProfileDetails?.address?.state &&
                        `${agencyProfileDetails?.address?.state},`
                      }
                      ${
                        agencyProfileDetails?.address?.postCode &&
                        `${agencyProfileDetails?.address?.postCode},`
                      }
                      ${
                        agencyProfileDetails?.address?.country &&
                        `${agencyProfileDetails?.address?.country}`
                      }
                      `
                      : 'N/A'}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/** Edit Button */}
        {isEditEnabled && (
          <div className='flex flex-col items-end justify-between pt-5 pb-5 pr-5 edit-button'>
            <Button
              size='sm'
              theme='white'
              className='w-fit'
              onClick={() => {
                onBasicInfoEditClick && onBasicInfoEditClick()
              }}
              textTheme='blue'>
              Edit
            </Button>

            <div className='flex flex-col p-4 mt-2 border rounded-lg border-Cobalt-200'>
              <div className='w-fit'>
                <SubscriptionPlanPackageBadge
                  packageName={
                    fixedSubscription?.package?.name ===
                    SubscriptionPlanType.FiftyPack
                      ? SubscriptionPlanType.FiftyPack
                      : fixedSubscription?.package?.name ===
                        SubscriptionPlanType.TwentyfivePack
                      ? SubscriptionPlanType.TwentyfivePack
                      : fixedSubscription?.package?.name ===
                        SubscriptionPlanType.FifteenPack
                      ? SubscriptionPlanType.FifteenPack
                      : SubscriptionPlanType.PAYG
                  }
                  isFill={true}
                  size='large'
                />
              </div>

              {fixedSubscription && (
                <div className='flex items-center justify-between mt-3 text-extra-small'>
                  <div className='text-Gray-700 font-Medium'>
                    {fixedSubscription?.renewalDate ? 'Renewal' : 'Expiry'} Date
                  </div>
                  <div className='text-Gray-500 font-Regular'>
                    {dateToReadableString(
                      fixedSubscription?.renewalDate ??
                        fixedSubscription?.endDate,
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
