import { useMutation } from 'react-query'
import { post } from 'framework/api/http'

type GetAssessmentReportViewRequestBody = {
  assessmentId: string
  assessmentResponseId: string
}

type GetAssessmentReportViewbyRequest = {
  candidateId: string
  assessmentData: GetAssessmentReportViewRequestBody[]
}

const getAssessmentReportView = async (
  data: GetAssessmentReportViewbyRequest,
) => {
  const res = await post(`/candidate/view-assessment`, data)
  return res.data
}

export const useGetAssessmentReportView = (
  onSuccess: (data: any) => void,
  onError: (error: any) => void,
) => {
  return useMutation(getAssessmentReportView, {
    onSuccess,
    onError,
  })
}
