import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import CN from 'classnames'
import { useBrowserDetection } from 'hooks'

import { BrowserBlockedPage } from 'components/common/BrowserBlockedPage'
import { MobileBlockedOverlay } from 'components/common/MobileBlockedOverlay'
import { useWidth } from 'utils'

import { OnboardingFooter, SignUpStepper } from '../components/SignUpStepper'
import {
  mandatorySignupStepperComponents,
  optionalSignupStepperComponents,
} from '../data'
import { getOnboardingStore } from '../store'

export const OnboardPage = () => {
  const { currentStep } = useSelector(getOnboardingStore)

  const [stepperComponents, setStepperComponents] = useState(
    mandatorySignupStepperComponents,
  )

  useEffect(() => {
    if (currentStep >= 6 && stepperComponents?.length === 5) {
      setStepperComponents([
        ...mandatorySignupStepperComponents,
        ...optionalSignupStepperComponents,
      ])
    }
  }, [currentStep])

  const ref = useRef(null)

  const [width] = useWidth(ref)

  const browserInfo = useBrowserDetection()

  const isBrowserNotAllowed = browserInfo.isFirefox

  const [isMobileUsing, setIsMobileUsing] = useState(false)

  useEffect(() => {
    if (width && width < 840) {
      setIsMobileUsing(true)
    }

    if (width && width > 840) {
      setIsMobileUsing(false)
    }
  }, [width])

  return (
    <div className='absolute flex w-full h-full' ref={ref}>
      {isMobileUsing && <MobileBlockedOverlay mailto='info@refhub.com.au' />}
      {isBrowserNotAllowed && (
        <BrowserBlockedPage mailto='info@refhub.com.au' />
      )}
      <div className='relative flex items-center justify-center flex-1 w-full h-full'>
        <div
          className={CN('flex flex-col items-center justify-center w-full', {
            'h-full': currentStep === 3,
          })}>
          {stepperComponents[currentStep - 1]?.component}

          {currentStep !== 3 && (
            <SignUpStepper
              stepperComponents={stepperComponents}
              currentStep={currentStep}
            />
          )}
        </div>
        {currentStep !== 3 && <OnboardingFooter />}
      </div>
      {currentStep !== 3 && (
        <>
          <div className=' flex items-start justify-start w-auto right-side'>
            {currentStep !== 1 && (
              <div className='flex flex-col text-[#4B5563] absolute mt-5 px-[30px]'>
                <span className='text-[1.1rem] font-Bold leading-[20px]'>
                  {stepperComponents[currentStep - 1]?.header}
                </span>
                <span className='mt-4 text-[0.8rem] leading-[20px] font-Medium'>
                  {stepperComponents[currentStep - 1]?.description}
                </span>
              </div>
            )}
          </div>
          <img
            src={stepperComponents[currentStep - 1]?.backgroundImage}
            alt=''
            className='object-fill h-full'
          />
        </>
      )}
    </div>
  )
}
