import { useMutation } from 'react-query'
import { putAPIwithFormData } from 'framework/api/http'

export type UpdateReportSettingBackgroundImageRequest = {
  id: string
  backgroundImage?: File | null
  companyLogo?: File | null
  IsCompanyLogo?: boolean
}

export const useUpdateReportSettingBackgroundImage = () => {
  const mutate = useMutation(
    ({
      id,
      backgroundImage,
      companyLogo,
      IsCompanyLogo = false,
    }: UpdateReportSettingBackgroundImageRequest) => {
      const bodyFormData = new FormData()

      //   backgroundImage && bodyFormData.append('BackgroundImage', backgroundImage)
      //   companyLogo && bodyFormData.append('BackgroundImage', companyLogo)

      if (backgroundImage && !IsCompanyLogo) {
        bodyFormData.append('BackgroundImage', backgroundImage)
      } else if (companyLogo) {
        bodyFormData.append('BackgroundImage', companyLogo)
      }

      return putAPIwithFormData(
        `/reports/${id}/iscompanylogo/${IsCompanyLogo}/background-image`,
        bodyFormData,
        {},
      )
    },
  )

  return mutate
}
