/* eslint-disable @typescript-eslint/no-unused-vars */

import { PromotionDto } from '../types'

import {
  setFixedPromotionState,
  setIsRedeemSuccessModalOpenState,
  setPromotionMessageState,
  setPromotionState,
} from './reducer'

export const setPromotion =
  (value: PromotionDto | null, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setPromotionState(value))
  }

export const setFixedPromotion =
  (value: PromotionDto | null, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setFixedPromotionState(value))
  }

export const setPromotionMessage =
  (value: string, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setPromotionMessageState(value))
  }

export const setIsRedeemSuccessModalOpen =
  (value: boolean, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setIsRedeemSuccessModalOpenState(value))
  }
