export const defaultBillingAddress = {
  firstName: '',
  lastName: '',
  lineOne: '',
  lineTwo: '',
  suburb: '',
  state: '',
  stateCode: '',
  country: 'Australia',
  countryCode: 'AU',
  postCode: '',
}
