import { ModalProps } from 'types'

import { Modal } from 'components/molecules/Modal'

import { type AutoMarkingProgressProps } from '../AutoMarkingProgress'

import AssessmentDetailedCertificate from './AssessmentDetailedCertificate'

export default function AssesmentcertificateModal({
  isModalActive,
  setIsModalActive,
  values,
  totalMarks,
  template,
  candidateDetails,
  certificateId,
  issueDate,
}: Partial<AutoMarkingProgressProps> &
  ModalProps & { certificateId: string; issueDate: Date }) {
  return (
    <Modal
      isHeaderShow={false}
      customWidth='1125px'
      modalContainerClassNames='py-[35px] h-[816px] overflow-y-scroll w-[1056px]'
      isActive={isModalActive}
      isFooterShow={false}
      size='lg'
      onOverlayClick={() => setIsModalActive(false)}>
      <AssessmentDetailedCertificate
        certificateId={certificateId}
        issueDate={issueDate}
        values={values!}
        totalMarks={totalMarks}
        template={template}
        candidateDetails={candidateDetails}
        hasCompanyLogo={true}
      />
    </Modal>
  )
}
