import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, TextField } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { useUpdateUserPassword } from 'framework/api/methods'
import moment from 'moment'

import { TwoFaSettingsSection } from './TwoFaSettingsSection'

type SecurityTabViewProps = {
  [x: string]: any
  userDataLoading?: boolean
}

export const SecurityTabView = ({
  className,
  userData,
  getUserProfileDetailsById,
  notify,
  ...restProps
}: SecurityTabViewProps) => {
  const { userDetails }: any = useSelector<any>((state) => state.user)
  const [editMode, setEditMode] = useState(false)
  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false)

  const [dataObject, setDataObject] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  })

  const SecurityTabViewClasses = CN(
    `security-tab-view px-6 pt-5 pb-7 divide-y divide-Gray-100`,
    className,
  )

  /** API Call for Update user password */
  const {
    mutate: updateUserPasswordMutate,
    isLoading: updateUserPasswordIsLoading,
  } = useUpdateUserPassword()

  /** Process update user password API call */
  async function updateUserPassword() {
    updateUserPasswordMutate(
      {
        username: userDetails.userName,
        currentPassword: dataObject.currentPassword,
        newPassword: dataObject.newPassword,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          userDetails.id && getUserProfileDetailsById()
          notify({
            alertHeader: successData.messageTitle
              ? successData.messageTitle
              : 'Success!',
            alertBody: successData?.message,
            status: 'Success',
          })
          setEditMode(false)
        },

        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: errData.messageTitle ? errData.messageTitle : 'Error!',
            alertBody: errData.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** reset password button click */
  const onResetPasswordClick = () => {
    updateUserPassword()
  }

  return (
    <div className={SecurityTabViewClasses} {...restProps}>
      {!editMode ? (
        <div className='flex items-center justify-between w-full mb-5'>
          {/* left side container */}
          <div className='flex flex-col w-full'>
            <span className='font-Medium text-Gray-800'>Password</span>

            {userData?.passwordResetRequestedOn && (
              <span className='pt-2 text-Gray-400 text-small font-Medium'>
                Last modified: &nbsp;
                {moment(userData?.passwordResetRequestedOn).format('ll')}
              </span>
            )}
          </div>

          {/* right side container */}
          <Button
            onClick={() => {
              setEditMode(true)
            }}
            textTheme='blue'
            theme='link'>
            Change
          </Button>
        </div>
      ) : (
        <div className='flex flex-col w-full mb-5'>
          {/* current password section */}
          <TextField
            type={showCurrentPassword ? 'text' : 'password'}
            label='Current password'
            isRequired={true}
            className='w-[326px]'
            placeholder='••••••••'
            value={dataObject.currentPassword}
            iconAfter={
              dataObject.currentPassword !== '' ? (
                <i
                  className={CN('hover:cursor-pointer', {
                    'ri-eye-off-line': showCurrentPassword == false,
                    'ri-eye-line': showCurrentPassword == true,
                  })}
                />
              ) : (
                <></>
              )
            }
            iconAfterOnClick={() => {
              setShowCurrentPassword(!showCurrentPassword)
            }}
            onChange={(e: any) => {
              setDataObject({
                ...dataObject,
                currentPassword: e.target.value,
              })
            }}
          />

          {/* new password section */}
          <TextField
            type={showNewPassword ? 'text' : 'password'}
            label='New password'
            isRequired={true}
            className='w-[326px] mt-4'
            placeholder='••••••••'
            value={dataObject.newPassword}
            iconAfter={
              dataObject.newPassword !== '' ? (
                <i
                  className={CN('hover:cursor-pointer', {
                    'ri-eye-off-line': showNewPassword == false,
                    'ri-eye-line': showNewPassword == true,
                  })}
                />
              ) : (
                <></>
              )
            }
            iconAfterOnClick={() => {
              setShowNewPassword(!showNewPassword)
            }}
            onChange={(e: any) => {
              setDataObject({
                ...dataObject,
                newPassword: e.target.value,
              })
            }}
          />

          {/* confirm new password section */}
          <TextField
            type={showConfirmNewPassword ? 'text' : 'password'}
            label='Confirm new password'
            isRequired={true}
            className='w-[326px] mt-4'
            placeholder='••••••••'
            value={dataObject.confirmPassword}
            iconAfter={
              dataObject.confirmPassword !== '' ? (
                <i
                  className={CN('hover:cursor-pointer', {
                    'ri-eye-off-line': showConfirmNewPassword == false,
                    'ri-eye-line': showConfirmNewPassword == true,
                  })}
                />
              ) : (
                <></>
              )
            }
            iconAfterOnClick={() => {
              setShowConfirmNewPassword(!showConfirmNewPassword)
            }}
            onChange={(e: any) => {
              setDataObject({
                ...dataObject,
                confirmPassword: e.target.value,
              })
            }}
          />

          {/* action buttons */}
          <div className='flex mt-4'>
            <Button
              className='mr-2'
              isDisabled={
                !dataObject.currentPassword ||
                !dataObject.newPassword ||
                !dataObject.confirmPassword ||
                dataObject.newPassword !== dataObject.confirmPassword
              }
              onClick={onResetPasswordClick}
              isLoading={updateUserPasswordIsLoading}>
              Reset Password
            </Button>

            <Button
              onClick={() => {
                setEditMode(false)
              }}
              textTheme='blue'
              theme='white'>
              Cancel
            </Button>
          </div>
        </div>
      )}

      <div className='pt-5'>
        <TwoFaSettingsSection
          userData={userData}
          getUserProfileDetailsById={getUserProfileDetailsById}
          notify={notify}
        />
      </div>
    </div>
  )
}
