import { FC } from 'react'

export const RefereeIcon: FC = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <rect width='40' height='40' rx='20' fill='#B7413A' />
      <g clip-path='url(#clip0_8411_58194)'>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M11 16C10.7261 16 10.5 16.2261 10.5 16.5V29H9.5V16.5C9.5 15.6739 10.1739 15 11 15H14.5V16H11Z'
          fill='#EEFFF3'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M29.5 29V23H30.5V29H29.5Z'
          fill='#EEFFF3'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M8 28.5H18.2071L19.2071 29.5H21.2929L22.2929 28.5H32V29.5C32 30.8811 30.8811 32 29.5 32H10.5C9.11886 32 8 30.8811 8 29.5V28.5ZM9 29.5C9 30.3289 9.67114 31 10.5 31H29.5C30.3289 31 31 30.3289 31 29.5H22.7071L21.7071 30.5H18.7929L17.7929 29.5H9Z'
          fill='#EEFFF3'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M17 10C16.4461 10 16 10.4461 16 11V20.495C16 21.0489 16.4461 21.495 17 21.495H21V24.79L23.7672 21.5H30C30.5539 21.5 31 21.0539 31 20.5V11C31 10.4461 30.5539 10 30 10H17ZM15 11C15 9.89386 15.8939 9 17 9H30C31.1061 9 32 9.89386 32 11V20.5C32 21.6061 31.1061 22.5 30 22.5H24.2328L21.7238 25.4833L21.7086 25.4986C21.0769 26.1302 20 25.6781 20 24.79V22.495H17C15.8939 22.495 15 21.6011 15 20.495V11Z'
          fill='#EEFFF3'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M20 12.5H25V13.5H21V18.5H26V16H27V19.5H20V12.5Z'
          fill='#EEFFF3'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M27.3947 13.3073L23.547 18.2544L21.6465 16.3539L22.3536 15.6468L23.4531 16.7463L26.6054 12.6934L27.3947 13.3073Z'
          fill='#EEFFF3'
        />
      </g>
      <defs>
        <clipPath id='clip0_8411_58194'>
          <rect
            width='24'
            height='23'
            fill='white'
            transform='translate(8 9)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default RefereeIcon
