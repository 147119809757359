import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

const getClientLogo = async ({ queryKey }: any) => {
  const id = queryKey[1]
  const res = await get(`/clients/${id}/logo`)
  return res.data
}

export const useGetClientLogo = (
  id: string,
  onSuccess: (data: any) => void,
) => {
  return useQuery(['client_logo', id], getClientLogo, {
    enabled: id != '',
    refetchOnWindowFocus: false,
    onSuccess,
    onError: () => {
      Toast({
        alertHeader: 'Error fetching client logo',
        status: 'Error',
      })
    },
  })
}
