import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

type UpdateCandidateMarkPassFailInterface = {
  candidateId?: string
  markingStatus?: string | null
}

export const useUpdateCandidateMarkPassFail = () => {
  const mutate = useMutation(
    ({ candidateId, markingStatus }: UpdateCandidateMarkPassFailInterface) => {
      return putAPI(
        `/Candidate/${candidateId}/MarkProfile/${markingStatus}`,
        {},
      )
    },
  )

  return mutate
}
