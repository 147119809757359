import { useState } from 'react'
import { Badge, Button, Toast } from '@labourhub/labour-hub-ds'

import { RowWithAlignment } from 'components/atoms/Row'

type CompetencyBadgeListProps = {
  formCompetencies: string[]
  selectedCompetencies: string[]
  setSelectedCompetencies: (competencies: string[]) => void
}

export const CompetencyBadgeList = ({
  selectedCompetencies,
  formCompetencies,
  setSelectedCompetencies,
}: CompetencyBadgeListProps) => {
  const [viewMoreClicked, setViewMoreClicked] = useState(false)

  const visibleCompetencies = viewMoreClicked
    ? formCompetencies
    : formCompetencies.slice(0, 12)

  const handleCompetencyClick = (competency: string) => {
    if (
      !selectedCompetencies.includes(competency) &&
      selectedCompetencies.length >= 7
    ) {
      Toast({
        alertHeader: 'You can select up to 7 badges.',
        status: 'Error',
      })
      return
    }

    setSelectedCompetencies(
      selectedCompetencies.includes(competency)
        ? selectedCompetencies.filter(
            (selectedBadge) => selectedBadge !== competency,
          )
        : [...selectedCompetencies, competency],
    )
  }

  return (
    <>
      <div className='px-2'>
        <p className='text-base text-black'>
          Identify the key competencies that are important for this role
          <span className='text-Red-500 pl-[2px] font-Bold'>*</span>
        </p>
        <span className='flex pb-1 mt-1 text-Gray-500 font-Medium'>
          Choose 3 to 7 options from the list below
        </span>
      </div>

      <RowWithAlignment justifyAlignment='start' className='flex flex-wrap m-2'>
        {visibleCompetencies.map((competency, index) => (
          <Badge
            key={index}
            theme={selectedCompetencies.includes(competency) ? 'blue' : 'gray'}
            size='large'
            className={'p-5 m-1.5 cursor-pointer font-Medium text-extra-small'}
            onClick={() => handleCompetencyClick(competency)}>
            {competency}
          </Badge>
        ))}
      </RowWithAlignment>

      {formCompetencies.length > 12 && (
        <RowWithAlignment justifyAlignment='start' className='px-4'>
          <Button
            theme='link'
            textTheme='blue'
            onClick={() => {
              setViewMoreClicked((prev) => !prev)
            }}>
            {viewMoreClicked ? 'View Less' : 'View More'}
          </Button>
        </RowWithAlignment>
      )}
    </>
  )
}
