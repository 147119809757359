import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { Button, Toast } from '@labourhub/labour-hub-ds'
import { useSendCustomAssessment } from 'api/assessment/useSendCustomAssessment'
import { useGetActiveSubscriptions } from 'api/subscriptions'
import popoverCareerForm from 'assets/images/assessment/AssessmentPreview.svg'
import cn from 'classnames'
import { difficultyImages } from 'features/candidates/data/difficultyLevelImages'
import { AssessmentLibraryPreviewModal } from 'features/questionnaires/components/AssessmentTab/AssessmentLibraryCardOverview'
import { AssessmentPreviewModal } from 'features/questionnaires/components/AssessmentTab/AssessmentTable/AssessmentPreviewModal'
import { getSubscriptionStore, setSubscriptions } from 'features/subscriptions'

import { Card } from 'components/atoms/Card'
import { Column } from 'components/atoms/Column'
import { RowWithAlignment } from 'components/atoms/Row'

import { ResendAssessmentConfirmationModal } from './ResendAssessmentConfirmationModal'
import SendAssessmentModal, { Addon } from './SendAssessmentModal'

type CustomAssessmentCardItemProps = {
  assessment: any
  refetch: any
}
export type AssessmentData = {
  isMandatoryWebcam: boolean
  assessmentDate: string
  addonIds: string[] | null
}
const typeConfig = {
  Library: {
    icon: 'ri-file-add-line',
    className: 'bg-Green-50 text-Green-base',
  },
  Custom: {
    icon: 'ri-draft-line',
    className: 'bg-[#881798]/10 text-[#881798]',
  },
  AI: {
    icon: 'ri-app-store-line',
    className: 'bg-[#FADA8033]/20 text-[#EA4335A8]/60',
  },
}

export const CustomAssessmentCardItem = ({
  assessment,
  refetch,
}: CustomAssessmentCardItemProps) => {
  const dispatch = useDispatch()
  const [isPreviewModalActive, setIsPreviewModalActive] = useState(false)
  const [isOpenSendAssessment, setIsOpenSendAssessment] = useState(false)
  const [sendAssessmentData, setSendAssessmentData] = useState<AssessmentData>({
    isMandatoryWebcam: false,
    assessmentDate: '',
    addonIds: null,
  })

  const [selectedAddons, setSelectedAddons] = useState<Addon[]>([])

  const [isResendAssessmentModalOpen, setIsResendAssessmentModalOpen] =
    useState(false)

  const togglePreviewModal = () => {
    setIsPreviewModalActive(!isPreviewModalActive)
  }
  const [searchParams] = useSearchParams()
  const candidateId: string = searchParams.get('candidate_id') || ''

  const { remainingCredit } = useSelector(getSubscriptionStore)

  const {
    mutate: sendCustomAssessmentMutate,
    isLoading: sendCustomTemplateIsLoading,
  } = useSendCustomAssessment()

  const { refetch: refetchSubscriptions } = useGetActiveSubscriptions(
    (subscriptions) => {
      dispatch(setSubscriptions(subscriptions))
    },
  )

  useEffect(() => {
    refetch()
    refetchSubscriptions()
  }, [sendCustomTemplateIsLoading])

  const handleSend = () => {
    if (!sendAssessmentData.assessmentDate) {
      Toast({
        alertHeader: 'Please fill in all required fields.',
        status: 'Warning',
      })
      return
    }

    const requestData = {
      assessmentId: assessment.assessmentId,
      assessmentLibraryId: assessment.assessmentLibraryId,
      templateName: assessment.role,
      isWebCamMandatory: sendAssessmentData.isMandatoryWebcam,
      assessmentDuedate: sendAssessmentData.assessmentDate,
      addonIds: sendAssessmentData.addonIds,
    }
    sendCustomAssessmentMutate(
      {
        candidateIds: [candidateId],
        requestData: requestData,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          Toast({
            alertBody: successData?.message,
            alertHeader: successData?.messageTitle,
            status: 'Success',
          })
          refetch()
          refetchSubscriptions()
          if (!sendCustomTemplateIsLoading) {
            setIsOpenSendAssessment(false)
            setIsResendAssessmentModalOpen(false)
          }
        },
        onError: ({ response: { data: errData } }: any) => {
          if (
            errData &&
            (errData?.messageTitle as string).includes(
              'Test SMS count Exceeded',
            )
          ) {
            Toast({
              alertHeader: errData?.messageTitle,
              alertBody: errData?.message,
              status: 'Warning',
            })
          } else {
            Toast({
              alertHeader: 'Error...!',
              alertBody: errData?.message,
              status: 'Error',
            })
          }
        },
      },
    )
  }

  const getByLabel = (type: string) => {
    const { icon, className } = typeConfig[type] || typeConfig.AI
    const commonClasses =
      'flex px-2 py-1 rounded-xl gap-x-1 text-extra-small w-fit'

    return (
      <div className={cn(commonClasses, className)}>
        <i className={icon}></i>
        {type}
      </div>
    )
  }

  return (
    <Card className='shadow-sm hover:shadow-xl rounded-xl relative min-h-[245px]'>
      <div className='absolute left-3 top-3 flex items-center gap-1'>
        <div>{getByLabel(assessment.type)}</div>
      </div>
      <div className='absolute right-3 top-3 flex px-2 py-1 border rounded-md border-Gray-100 items-center gap-1'>
        <img
          src={difficultyImages[assessment.difficultyLevel]}
          alt='difficulty-img'
        />
        <span className='text-extra-small'>{assessment.difficultyLevel}</span>
      </div>
      <Column className='items-center pt-3'>
        <RowWithAlignment justifyAlignment='center'>
          {/* Image */}
          <img
            src={popoverCareerForm}
            alt=''
            className='flex w-[120px] justify-center items-center h-[92px]'
          />
        </RowWithAlignment>
        {/* Title */}
        <span className='mt-2 text-base text-center text-Gray-800 font-SemiBold'>
          {assessment.name}
        </span>
        {/* Subtitle*/}
        <p className='mt-1 text-small text-Gray-500 text-center'>
          {assessment.numberOfQuestions} Questions / {assessment.duration}{' '}
          Minutes to Complete
        </p>
        <RowWithAlignment className='w-full p-2' justifyAlignment='center'>
          <Button
            className='h-[32px] w-[125px] mx-2'
            theme='white'
            textTheme='blue'
            onClick={togglePreviewModal}
            iconRight={<i className='ri-external-link-line text-heading-5' />}>
            Preview
          </Button>
          <Button
            className='h-[32px] w-[125px] mx-2'
            textTheme='white'
            onClick={() => {
              if (assessment.isSentToAssessmentResponse) {
                setIsResendAssessmentModalOpen(true)
              } else {
                setIsOpenSendAssessment(true)
              }
            }}
            isLoading={sendCustomTemplateIsLoading}
            isDisabled={remainingCredit == 0}
            iconRight={
              <i className='ri-send-plane-line text-heading-5 pr-[6px]' />
            }>
            Send
          </Button>
        </RowWithAlignment>
      </Column>
      {isPreviewModalActive && assessment.assessmentLibraryId && (
        <AssessmentLibraryPreviewModal
          isModalActive={isPreviewModalActive}
          setIsModalActive={setIsPreviewModalActive}
          templateId={assessment?.assessmentLibraryId}
          templateName={assessment?.name}
        />
      )}
      {isPreviewModalActive && assessment.assessmentId && (
        <AssessmentPreviewModal
          isDraft={false}
          isModalActive={isPreviewModalActive}
          setIsModalActive={setIsPreviewModalActive}
          templateId={assessment?.assessmentId}
          templateName={assessment?.name}
        />
      )}

      {isOpenSendAssessment && (
        <SendAssessmentModal
          isModalActive={isOpenSendAssessment}
          setIsModalActive={setIsOpenSendAssessment}
          setSendAssessmentData={setSendAssessmentData}
          sendAssessmentData={sendAssessmentData}
          selectedAddons={selectedAddons}
          setSelectedAddons={setSelectedAddons}
          handleSend={handleSend}
          sendCustomTemplateIsLoading={sendCustomTemplateIsLoading}
          assessment={assessment}
          setIsPreviewModalActive={setIsPreviewModalActive}
        />
      )}

      {isResendAssessmentModalOpen && (
        <ResendAssessmentConfirmationModal
          isOpen={isResendAssessmentModalOpen}
          assessment={assessment}
          sendAssessmentData={sendAssessmentData}
          selectedAddons={selectedAddons}
          sendCustomTemplateIsLoading={sendCustomTemplateIsLoading}
          setIsOpen={setIsResendAssessmentModalOpen}
          setSendAssessmentData={setSendAssessmentData}
          setSelectedAddons={setSelectedAddons}
          handleSend={handleSend}
          //   setIsPreviewModalActive={setIsPreviewModalActive}
        />
      )}
    </Card>
  )
}
