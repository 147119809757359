import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'
import { QueryParams } from 'types/queryParams'

export type TemplateVariablesDto = {
  variable: string
  previewData: string
  description: string
  columnName: string
  tableName: string
}

const getTemplateVariablesList = async ({ queryKey }: any) => {
  const { take, skip, search, sortColumnName, sortDirection, isActive } =
    queryKey[1] as QueryParams

  let URI = `/templates/variables?take=${take}&skip=${skip}`

  if (search) {
    URI += `&search=${search}`
  }

  if (sortColumnName) {
    URI += `&sortColumnName=${sortColumnName}`
  }

  if (sortDirection) {
    URI += `&sortDirection=${sortDirection}`
  }

  if (isActive == true || isActive == false) {
    URI += `&isActive=${isActive}`
  }

  const res = await get(URI)
  return res.data?.templateVariables
}

export const useGetTemplateVariablesList = (
  queryParams: QueryParams,
  onSuccess: (templateVariables: TemplateVariablesDto[]) => void,
) => {
  return useQuery(
    ['template_variables', queryParams],
    getTemplateVariablesList,
    {
      enabled: true,
      refetchOnWindowFocus: false,
      onSuccess,
      onError: () => {
        Toast({
          alertHeader: 'Error fetching template variable list',
          status: 'Error',
        })
      },
    },
  )
}
