import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

type convertEmployeeRequest = {
  candidateId: string
  isEmployee: boolean
}

export const useConvertEmployee = () => {
  const mutate = useMutation(
    ({ candidateId, isEmployee }: convertEmployeeRequest) => {
      return putAPI(`/Candidate/${candidateId}/ConvertEmployee`, { isEmployee })
    },
  )

  return mutate
}
