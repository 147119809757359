/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { Avatar, Badge } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import moment from 'moment'

import { MoreButton } from 'components/atoms'
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter'
import { dateToReadableString } from 'utils/dateToReadableString'
import { timeDifference } from 'utils/timeDifference'

export const UserListColumn = (
  tableData: any,
  setTableData: any,
  navigate: any,
  setSelectedUser: any,
  setIsActivateDeactivateModal,
  reinviteUser: any,
) => {
  const COLUMNS = [
    {
      Header: 'NAME',
      accessor: 'displayName',
      forSorting: 'FirstName',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[650px]',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return (
          <div>
            <Avatar
              imageURL={original?.avatarUrl ? original?.avatarUrl : undefined}
              titleClassName='line-clamp-1 w-max'
              size='xs'
              title={`${original?.firstName} ${original?.lastName}`}
              tag={original?.email}
            />
          </div>
        )
      },
    },
    {
      Header: 'STATUS',
      accessor: 'status',
      forSorting: 'IsActive',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[140px]',
      headerClassName: 'flex items-center justify-center',
      cellClassName: 'flex items-center justify-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        const { status } = original || {}

        let theme
        switch (status) {
          case 'DEACTIVE':
            theme = 'red'
            break
          case 'ACTIVE':
            theme = 'green'
            break
          case 'PENDING':
            theme = 'yellow'
            break

          default:
            theme = 'green'
            break
        }

        return (
          <div>
            <Badge size='large' theme={theme}>
              {original?.status === 'DEACTIVE'
                ? `Inactive`
                : capitalizeFirstLetter(original?.status)}
            </Badge>
          </div>
        )
      },
    },
    {
      Header: 'DATE ADDED',
      accessor: 'createdOn',
      forSorting: 'CreatedOn',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[120px]',
      headerClassName: 'flex items-center justify-center',
      cellClassName: 'flex items-center justify-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <div>{dateToReadableString(original?.createdOn)}</div>
      },
    },
    {
      Header: 'LAST LOGGED IN',
      accessor: 'updatedOn',
      forSorting: 'UpdatedOn',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[150px]',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return (
          <div>
            {original?.lastLoggedOn
              ? timeDifference(
                  new Date().toISOString(),
                  moment(`${original?.lastLoggedOn}Z`).toISOString(),
                )
              : 'N/A'}
          </div>
        )
      },
    },
    {
      Header: '',
      accessor: 'action',
      forSorting: '',
      isSortable: false,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[64px]',
      headerClassName: 'flex items-center justify-center',
      cellClassName: 'flex items-center justify-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        return (
          <div
            onClick={() => {
              navigate(`user-permission?userId=${original?.id}`)
            }}>
            <i
              className='cursor-pointer ri-lock-2-line text-heading-5 text-Gray-500'
              data-tip='Permission'
              data-for='Permission'
            />
            <ReactTooltip
              place='top'
              id='Permission'
              effect='solid'
              textColor='#FFFFFF'
              backgroundColor='#4B5563'
            />
          </div>
        )
      },
    },
    {
      Header: '',
      accessor: 'action',
      forSorting: '',
      isSortable: false,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[65px]',
      headerClassName: 'flex items-center justify-center',
      cellClassName: 'flex items-center justify-center',
      Cell: (props: any) => {
        const {
          row,
          row: { original },
        } = props || {}

        const { status, showMore, id } = original
        const {
          userDetails: { id: currentUserId },
        } = useSelector((state: any) => state.user)

        const toggleShowMore = (rowData: any) => {
          const toBeUpdateTableData = {
            ...tableData,
            userProfiles: tableData?.userProfiles?.map((item: any) => {
              if (item.id === rowData.id) {
                return {
                  ...item,
                  showMore: !item?.showMore,
                }
              } else {
                return {
                  ...item,
                  showMore: false,
                }
              }
            }),
          }
          setTableData(toBeUpdateTableData)
        }

        return (
          <>
            <div className='relative'>
              <MoreButton
                onClick={(e: any) => {
                  e.stopPropagation()
                  toggleShowMore(original)
                  setSelectedUser(original)
                }}
                className={CN({
                  'bg-Gray-100': showMore,
                })}
              />
            </div>

            {showMore && (
              <div
                className={CN(
                  'absolute  right-[25px] z-40 flex flex-col justify-start w-[214px] shadow-xl border-[2px] border-Gray-200 rounded-md cursor-pointer bg-white divide-y-2 divide-Gray-200',
                  {
                    'top-[40px]': row.index < 5,
                    'top-[-40px]': row.index >= 5,
                  },
                )}
                key={original.id}>
                <span
                  className={CN('font-Regular  pl-2 text-[16px] py-3', {
                    'text-Gray-800':
                      status === 'DEACTIVE' && id !== currentUserId,
                    'text-Red-600': status === 'ACTIVE' && id !== currentUserId,
                    'text-Gray-400 cursor-not-allowed':
                      status === 'PENDING' || id === currentUserId,
                  })}
                  onClick={() => {
                    if (status !== 'PENDING' && id !== currentUserId) {
                      setIsActivateDeactivateModal({
                        enable: true,
                        type: status,
                      })
                      toggleShowMore(original)
                    }
                  }}>
                  {status === 'DEACTIVE' ? `Activate User` : `Deactivate User`}
                </span>

                {status === 'PENDING' && (
                  <span
                    className='font-Regular pl-2 text-[16px] text-Gray-800 py-3'
                    onClick={() => {
                      toggleShowMore(original)
                      reinviteUser(original?.id)
                    }}>
                    Resend Activation Email
                  </span>
                )}
              </div>
            )}
          </>
        )
      },
    },
  ]

  return COLUMNS
}

export default UserListColumn
