import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { addMonths, addYears } from 'date-fns'
import { getPromotionStore } from 'features/promotions'
import {
  FixedSubscriptionPackageDto,
  getSubscriptionStore,
  SubscriptionMode,
  SubscriptionType,
} from 'features/subscriptions'

import { Column } from 'components/atoms/Column'
import { timeToAESTWithFormat } from 'utils'

type SubscriptionConfirmationProps = {
  subscriptionPackage: FixedSubscriptionPackageDto
  subscriptionMode: SubscriptionMode
}

export const SubscriptionConfirmation = ({
  subscriptionPackage,
  subscriptionMode,
}: SubscriptionConfirmationProps) => {
  const {
    activeSubscriptions: { fixedSubscription },
  } = useSelector(getSubscriptionStore)

  const { fixedPromotion } = useSelector(getPromotionStore)

  const getPromotionDiscount = () => {
    if (fixedPromotion && fixedPromotion?.promotionType === 'Percentage')
      return fixedPromotion?.amount / 100
    return fixedPromotion?.amount
  }

  const originalAmountWithTax = useMemo(() => {
    const amount = subscriptionPackage?.packageAmount * 1.1
    return amount.toFixed(2)
  }, [subscriptionPackage?.packageAmount])

  const discountedAmountWithTax = useMemo(() => {
    const discountRate =
      getPromotionDiscount() || subscriptionPackage.promotionDiscountRate || 0
    const amount =
      fixedPromotion?.promotionType === 'Fixed'
        ? subscriptionPackage.packageAmount - discountRate
        : subscriptionPackage.packageAmount * (1 - discountRate) * 1.1
    return amount.toFixed(2)
  }, [subscriptionPackage?.packageAmount, fixedPromotion])

  const yearDateString = useMemo(() => {
    const renewalDate = fixedSubscription?.renewalDate
      ? new Date(`${fixedSubscription.renewalDate}Z`)
      : new Date()

    const newDateAfterAddingYear = addMonths(renewalDate, 12)
    return timeToAESTWithFormat(newDateAfterAddingYear)
  }, [fixedSubscription])

  const monthDateString = useMemo(() => {
    const renewalDate = fixedSubscription?.renewalDate
      ? new Date(`${fixedSubscription.renewalDate}Z`)
      : new Date()

    const newDateAfterAddingMonth = addMonths(renewalDate, 1)
    return timeToAESTWithFormat(newDateAfterAddingMonth)
  }, [fixedSubscription])

  return (
    <Column>
      <div className='flex flex-col text-base leading-[20px] font-Medium text-[#414244] mt-6'>
        <div>
          <span className='mr-1'>You will be charged</span>
          {getPromotionDiscount() ? (
            <span>
              <del className='text-Red-600'> ${originalAmountWithTax} </del> $
              {discountedAmountWithTax}{' '}
            </span>
          ) : (
            <span>${originalAmountWithTax} </span>
          )}{' '}
          <span>
            AUD Per
            {subscriptionPackage?.subscriptionType === SubscriptionType.Yearly
              ? ' Year '
              : ' Month '}
          </span>
          {(!fixedSubscription?.isCanceled ||
            (subscriptionMode == SubscriptionMode.Downgrade &&
              fixedSubscription?.renewalDate)) && (
            <span>
              {' '}
              starting from (
              {subscriptionMode == SubscriptionMode.Downgrade
                ? timeToAESTWithFormat(fixedSubscription?.renewalDate)
                : timeToAESTWithFormat(new Date())}
              -{' '}
              {subscriptionPackage?.subscriptionType === SubscriptionType.Yearly
                ? fixedSubscription &&
                  subscriptionMode == SubscriptionMode.Downgrade
                  ? yearDateString
                  : timeToAESTWithFormat(addYears(new Date(), 1))
                : fixedSubscription &&
                  subscriptionMode == SubscriptionMode.Downgrade
                ? monthDateString
                : timeToAESTWithFormat(addMonths(new Date(), 1))}
              )
            </span>
          )}
        </div>
        <span>You can cancel anytime</span>
      </div>

      <span className='text-[#64676C] text-extra-small font-Regular tracking-[0.25px] mt-1'>
        By clicking Subscribe, you agree to our
        <a
          href='https://www.refhub.com.au/privacy-policy'
          target='_blank'
          className='mx-1 text-Cobalt-600 font-Medium'>
          terms & conditions
        </a>
        and
        <a
          href='https://www.refhub.com.au/privacy-policy'
          target='_blank'
          className='mx-1 text-Cobalt-600 font-Medium'>
          terms of service
        </a>
      </span>
    </Column>
  )
}
