import { useMemo, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { Badge } from '@labourhub/labour-hub-ds'
import { CandidateProfileBasicDetailsType } from 'api/candidates'
import CN from 'classnames'
import { CandidateMarkingStatus } from 'features/candidates/data'

import { cn } from 'utils'

import { DeleteNoteModal } from '../HistoryTab/DeleteNoteModal'
import { EditNoteModal } from '../HistoryTab/EditNoteModal'
import { NoteItemCard } from '../HistoryTab/HistoryItemCard'

import { UpdateCandidateMarkPassFailModal } from './UpdateCandidateMarkPassFailModal'

type OverviewTabProps = {
  candidateDetails: CandidateProfileBasicDetailsType
  getCandidateById: any
}

export const OverviewTab = ({
  candidateDetails,
  getCandidateById,
}: OverviewTabProps) => {
  const [markType, setMarkType] = useState<CandidateMarkingStatus>(
    CandidateMarkingStatus.Reset,
  )
  const [showMarkAsPassFailModal, setShowMarkAsPassFailModal] = useState(false)

  const [dropdownVisible, setDropdownVisible] = useState(false)

  const [isEditNoteModalActive, setIsEditNoteModalActive] = useState(false)

  const [isDeleteNoteModalActive, setIsDeleteNoteModalActive] = useState(false)

  const [selectedNoteId, setSelectedNoteId] = useState<string>('')

  const { isEmployee, positiveSentiment, negativeSentiment, neutralSentiment } =
    candidateDetails

  const isSentimentExist = useMemo(() => {
    const pos = positiveSentiment || 0
    const neg = negativeSentiment || 0
    const neu = neutralSentiment || 0
    return pos + neg + neu !== 0
  }, [positiveSentiment, negativeSentiment, neutralSentiment])

  const notes = useMemo(
    () => candidateDetails.notes.filter((note) => note.description) || [],
    [candidateDetails.notes],
  )

  return (
    <div className='flex flex-col items-center justify-between h-[420px] w-full'>
      <div className='w-full'>
        {/* Sentiment Temporary Hide */}
        {/* {!isEmployee && isSentimentExist && (
          <div className='space-y-2 mt-1'>
            <ReferenceSentimentCard
              sentiment={{
                negativeSentiment,
                neutralSentiment,
                positiveSentiment,
              }}
            />
          </div>
        )} */}

        <div
          className={cn('space-y-2 ', {
            'my-6': isSentimentExist,
            'my-2': !isSentimentExist,
          })}>
          <div className='flex items-start w-full mb-2'>
            <div className='text-extra-small text-Gray-600 w-[40%] pt-[2.5px]'>
              ID No:
            </div>
            <div className='w-[60%] text-small font-SemiBold text-Gray-800'>
              {candidateDetails?.candidateNo}
            </div>
          </div>

          <div className='flex items-start w-full mb-2'>
            <div className='text-extra-small text-Gray-600 w-[40%] pt-[2.5px]'>
              Email:
            </div>
            <div className='w-[60%] text-small font-SemiBold text-Gray-800 truncate'>
              {!candidateDetails?.email.includes('@noreply.com') ? (
                <a
                  href={`mailto:${candidateDetails?.email}`}
                  className='text-small text-Cobalt-500 font-SemiBold'
                  data-tip={candidateDetails?.email}
                  data-for={candidateDetails?.candidateId}>
                  <ReactTooltip
                    place='right'
                    id={candidateDetails?.candidateId}
                    effect='solid'
                    textColor='#FFFFFF'
                    backgroundColor='#4B5563'
                    delayShow={800}
                  />
                  {candidateDetails?.email}
                </a>
              ) : (
                <span className='text-small font-SemiBold'>N/A</span>
              )}
            </div>
          </div>

          <div className='flex items-start w-full mb-2'>
            <div className='text-extra-small text-Gray-600 w-[40%] pt-[2.5px]'>
              Phone:
            </div>
            <div className='w-[60%] text-small font-SemiBold text-Gray-800'>
              <a
                href={`tel:${candidateDetails?.phone}`}
                className='text-small text-Cobalt-500 font-SemiBold'>
                {candidateDetails?.phone}
              </a>
            </div>
          </div>

          <div className='flex items-start w-full mb-2'>
            <div className='text-extra-small text-Gray-600 w-[40%] pt-[2.5px]'>
              Job Title:
            </div>
            <div className='w-[60%] text-small font-SemiBold text-Gray-800'>
              {candidateDetails?.jobTitle ? candidateDetails?.jobTitle : `-`}
            </div>
          </div>

          {!isEmployee && <div className='bg-Gray-100 h-[1px]  w-[380px]' />}

          {!isEmployee && (
            <div className='flex items-start w-full mb-2'>
              <div className='text-extra-small text-Gray-600 w-[40%] pt-[2.5px]'>
                Referees #:
              </div>
              <div className='w-[60%] text-small font-SemiBold text-Gray-800'>
                {candidateDetails?.refereeCount}
              </div>
            </div>
          )}

          <div className='flex items-start w-full mb-2'>
            <div className='text-extra-small text-Gray-600 w-[40%] pt-[2.5px]'>
              Managing Consultant:
            </div>
            <div className='w-[60%] text-small font-SemiBold text-Gray-800'>
              {candidateDetails?.consultantName
                ? candidateDetails?.consultantName
                : `-`}
            </div>
          </div>

          {!isEmployee && (
            <div className='flex items-start w-full mt-1'>
              <div className='text-extra-small text-Gray-600 w-[40%] pt-[2.5px]'>
                Marked Status:
              </div>
              <div className='flex flex-row space-x-2 w-[60%] text-small font-SemiBold text-Gray-800'>
                <Badge
                  size='small'
                  type='basic'
                  isDot={true}
                  theme={
                    candidateDetails?.isMarkAsPass === null
                      ? 'yellow'
                      : candidateDetails?.isMarkAsPass
                      ? 'green'
                      : 'red'
                  }>
                  {candidateDetails?.isMarkAsPass === null
                    ? 'Pending'
                    : candidateDetails?.isMarkAsPass
                    ? 'Passed'
                    : 'Failed'}
                </Badge>
                <div className='relative'>
                  <button
                    onClick={() => setDropdownVisible(!dropdownVisible)}
                    className='focus:outline-none'>
                    <i
                      className={CN(
                        'text-[16px] ri-pencil-line mr-1',
                        dropdownVisible ? 'text-Cobalt-700' : 'text-Cobalt-500',
                      )}
                    />
                  </button>
                  {dropdownVisible && (
                    <div
                      className={CN(
                        'flex flex-col justify-start shadow-md border-[2px] border-Gray-100 rounded-md mt-1 z-40 bg-white absolute select-none',
                        'w-[120px]',
                      )}>
                      <button
                        className={CN('py-2 cursor-pointer select-none', {
                          'text-black hover:bg-Gray-100':
                            candidateDetails?.isMarkAsPass === null ||
                            candidateDetails?.isMarkAsPass === false,
                          'text-Gray-500 cursor-not-allowed hover:bg-Gray-100':
                            candidateDetails?.isMarkAsPass === true,
                        })}
                        onClick={() => {
                          setMarkType(CandidateMarkingStatus.Passed)
                          setShowMarkAsPassFailModal(true)
                          setDropdownVisible(false)
                        }}
                        disabled={candidateDetails?.isMarkAsPass === true}>
                        Mark as Passed
                      </button>
                      <button
                        className={CN('py-2 cursor-pointer select-none', {
                          'text-black hover:bg-Gray-100':
                            candidateDetails?.isMarkAsPass === null ||
                            candidateDetails?.isMarkAsPass === true,
                          'text-Gray-500 cursor-not-allowed hover:bg-Gray-100':
                            candidateDetails?.isMarkAsPass === false,
                        })}
                        onClick={() => {
                          setMarkType(CandidateMarkingStatus.Failed)
                          setShowMarkAsPassFailModal(true)
                          setDropdownVisible(false)
                        }}
                        disabled={candidateDetails?.isMarkAsPass === false}>
                        Mark as Failed
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {isEmployee && notes.length > 0 && (
            <>
              <div className='bg-Gray-100 h-[1px] w-[380px]' />
              <div className='py-2'>
                <span className='font-Medium text-small pb-2'>Notes</span>
                <div className='h-[210px] py-2 pr-1 overflow-y-auto styled-scroll'>
                  {notes.map((note) => {
                    return note?.description && note?.description != '' ? (
                      <NoteItemCard
                        key={note.id}
                        noteId={note.id}
                        description={note?.description}
                        setIsEditNoteModalActive={setIsEditNoteModalActive}
                        setIsDeleteNoteModalActive={setIsDeleteNoteModalActive}
                        setSelectedNoteId={setSelectedNoteId}
                      />
                    ) : null
                  })}
                </div>
              </div>
              <DeleteNoteModal
                isModalActive={isDeleteNoteModalActive}
                setIsModalActive={setIsDeleteNoteModalActive}
                noteId={selectedNoteId}
                getCandidateById={getCandidateById}
              />

              <EditNoteModal
                isModalActive={isEditNoteModalActive}
                setIsModalActive={setIsEditNoteModalActive}
                noteId={selectedNoteId}
                getCandidateById={getCandidateById}
                description={
                  notes.find((note) => note.id === selectedNoteId)
                    ?.description || ''
                }
              />
            </>
          )}
        </div>
      </div>

      <UpdateCandidateMarkPassFailModal
        isModalActive={showMarkAsPassFailModal}
        setIsModalActive={setShowMarkAsPassFailModal}
        markType={markType}
        candidateId={candidateDetails.candidateId}
        getCandidateById={getCandidateById}
      />
    </div>
  )
}
