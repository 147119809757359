/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from 'react'
import {
  AssessmentLibraryDto,
  useGetAssessmentLibraryList,
} from 'api/assessment/getAssessmentLibraryList'

import { Card } from 'components/atoms/Card'

import { AssessmentLibraryCardItem } from './AssessmentLibraryCardItem'

export const AssessmentLibraryCardOverview = () => {
  const [library, setLibrary] = useState<AssessmentLibraryDto[]>([])
  const [searchKeyWord, setSearchKeyWord] = useState<string>('')

  const { refetch } = useGetAssessmentLibraryList(
    {
      search: searchKeyWord,
      type: 'library',
    },

    (data) => setLibrary(data),
  )

  return (
    <Card className='mt-7'>
      <span className='text-base text-Gray-800 font-SemiBold'>
        Start with our Library
      </span>

      {/* Subtitles in one line */}

      <p className='mt-1 text-small text-Gray-500'>
        Here you can see our templates
      </p>

      <div className='flex items-center justify-between w-full py-[13px] border-b border-Gray-200'>
        <div className='relative w-full'>
          <input
            value={searchKeyWord}
            onKeyUp={(e: any) => {
              if (e.keyCode == 13) {
                setSearchKeyWord(searchKeyWord)
              }
              if (searchKeyWord.length === 0) {
                setSearchKeyWord(searchKeyWord)
              }
            }}
            onChange={(e: any) => {
              setSearchKeyWord(e.target.value ?? '')
            }}
            type='text'
            className='w-full font-Regular py-[6px] pl-10 pr-[30px] text-Gray-600 active:outline-Gray-50 focus:outline-Gray-50 rounded'
            placeholder='Search by template'
          />

          <i className='absolute left-[6px] top-[5px] ri-search-line text-Gray-400'></i>

          {searchKeyWord && (
            <i
              className='absolute right-[10px] top-[6px] ri-close-line text-Gray-400'
              onClick={() => {
                setSearchKeyWord('')
              }}></i>
          )}
        </div>
      </div>

      <div className='h-full mt-4 space-y-5 overflow-y-auto styled-scroll w-[403px]'>
        {library &&
          library.map((libraryItem) => (
            <AssessmentLibraryCardItem
              key={libraryItem.id}
              libraryItem={libraryItem}
              refetchLibraryList={refetch}
            />
          ))}
      </div>
    </Card>
  )
}
