import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

export const useRequestRefereeReminder = () => {
  const mutate = useMutation((candidateId: string | null) => {
    return postAPI(`/Candidate/${candidateId}/Reminder`, {})
  })

  return mutate
}

export default useRequestRefereeReminder
