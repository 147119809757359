import { useEffect } from 'react'
import { Button, TextField } from '@labourhub/labour-hub-ds'

type Option = {
  value: string
}

type OptionSectionProps = {
  options: Option[]
  setOptions: (updatedOptions: Option[]) => void
  setDisabledButton: any
}

export const OptionSection = ({
  options,
  setOptions,
  setDisabledButton,
}: OptionSectionProps) => {
  useEffect(() => {
    const checkForInvalidOptions = () => {
      const valueArr = options.map((option) => option.value.toLowerCase())
      const isDuplicate = valueArr.some(
        (item, idx) => valueArr.indexOf(item) !== idx,
      )
      const isEmptyExist = valueArr.some((item) => item === '')
      setDisabledButton(isDuplicate || isEmptyExist)
    }

    checkForInvalidOptions()
  }, [options])

  const handleChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const updatedOptions = options.map((option, idx) =>
      idx === index ? { ...option, value: event.target.value } : option,
    )
    setOptions(updatedOptions)
    setDisabledButton(updatedOptions.some((option) => option.value === ''))
  }

  const addFormFields = () => {
    setOptions([...options, { value: '' }])
    setDisabledButton(true)
  }

  const removeFormFields = (index: number) => {
    const updatedOptions = options.filter((_, idx) => idx !== index)
    setOptions(updatedOptions)
    setDisabledButton(updatedOptions.some((option) => option.value === ''))
  }

  return (
    <div className='flex flex-col gap-y-5'>
      <div className='w-full px-4 py-2 rounded-lg font-Regular text-Cobalt-800 bg-Cobalt-50'>
        <span>Options</span>
      </div>
      <div className='flex flex-col w-full gap-y-3'>
        {options.map((option, index) => (
          <div className='flex items-center gap-x-3' key={index}>
            <TextField
              value={option.value}
              onChange={(e) => handleChange(index, e)}
              placeholder={`Option ${index + 1}`}
            />
            {index !== 0 && (
              <Button
                view='outline'
                theme='terracotta'
                isIconOnly
                size='sm'
                className='h-fit'
                onClick={() => removeFormFields(index)}
                icon={
                  <i className='ri-delete-bin-line text-TerraCotta-500'></i>
                }
              />
            )}
          </div>
        ))}
        <Button
          className='w-fit focus:outline-none'
          theme='link'
          textTheme='blue'
          iconLeft={<i className='ri-add-line'></i>}
          onClick={addFormFields}>
          Add Option
        </Button>
      </div>
    </div>
  )
}
