import { useMutation } from 'react-query'
import { AgencyAppSettingDto, AppSettingModule } from 'features/settings'
import { putAPI } from 'framework/api/http'

export const useEditAgencySettings = () => {
  const mutate = useMutation((dto: AgencyAppSettingDto) => {
    dto.module = AppSettingModule.AgencySettings
    return putAPI(`/AppSettings/Agencies`, dto)
  })
  return mutate
}
