import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Modal, TextField, Toast } from '@labourhub/labour-hub-ds'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { getPaymentErrorMessage } from 'features/payments/utils'
import { ModalProps } from 'types'

import { useSaveCustomerCard } from '../../../../../api/payments'
import { toggleIsRefetchPaymentCards } from '../../../store'

type SaveCardModalProps = ModalProps & {
  clientSecret: string
}

export const SaveCardModal = ({
  isModalActive,
  setIsModalActive,
  clientSecret,
}: SaveCardModalProps) => {
  const dispatch = useDispatch()

  const notify = (props: any) => Toast(props)
  const [isLoading, setIsLoading] = useState(false)
  const stripe = useStripe()
  const elements = useElements()
  const [nameOnCard, setNameOnCard] = useState('')

  const [cardComplete, setCardComplete] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  })

  function handleCardElementOnChange(e) {
    setCardComplete({ ...cardComplete, [e.elementType]: e.complete })
  }

  const { mutate } = useSaveCustomerCard()

  const handleSubmit = async () => {
    if (!stripe || !elements || !clientSecret) {
      return
    }

    setIsLoading(true)

    const result = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: elements.getElement(CardNumberElement)!,
        billing_details: {
          name: nameOnCard,
        },
      },
    })

    if (
      result.error ||
      !result.setupIntent.payment_method ||
      result.setupIntent.status != 'succeeded'
    ) {
      notify({
        alertHeader: 'Error!',
        alertBody: getPaymentErrorMessage({
          errorCode: result.error?.code,
          declinedCode: result.error?.decline_code,
        }),
        status: 'Error',
      })
      setIsLoading(false)
      return
    }

    mutate(
      {
        paymentCardId: result.setupIntent.payment_method as string,
      },
      {
        onSuccess: () => {
          notify({
            alertHeader: 'Card Added Successfully',
            alertBody:
              'Your new payment card has been successfully added to your account.',
            status: 'Success',
          })
          dispatch(toggleIsRefetchPaymentCards())
          setIsLoading(false)
          setIsModalActive(false)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
          setIsLoading(false)
        },
      },
    )

    return
  }

  return (
    <Modal
      isActive={isModalActive}
      className='!z-[150] py-10 w-full'
      modalProps={{ style: { width: '500px', height: 'auto' } }}
      isSeparator={true}
      isHeaderShow={true}
      headerTitle='Add Card Details'
      headerCloseButtonProps={{
        style: {
          fontSize: '22px',
        },
      }}
      onHeaderCloseButtonClick={() => setIsModalActive(false)}
      isFooterShow={true}
      footerProps={{
        style: {
          flexDirection: 'row-reverse',
        },
      }}
      primaryButtonTitle={'Add Card'}
      primaryButtonProps={{
        isLoading: isLoading,
        isDisabled:
          !stripe ||
          !nameOnCard ||
          !cardComplete.cardNumber ||
          !cardComplete.cardExpiry ||
          !cardComplete.cardCvc,
        style: {
          marginLeft: '12px',
          paddingLeft: '30px',
          paddingRight: '30px',
        },
      }}
      onClickPrimaryBtn={handleSubmit}
      secondaryButtonTitle='Cancel'
      secondaryButtonProps={{
        theme: 'white',
        textTheme: 'black',
      }}
      onClickSecondaryBtn={() => setIsModalActive(false)}>
      <div className='w-full h-full p-5'>
        <div className='pb-1 text-small'>
          <div className='flex text-Gray-800 font-Medium'>
            <span>Card Number</span>
            {<span className='text-Red-500 pl-[2px]'>*</span>}
          </div>
        </div>

        <CardNumberElement
          onChange={handleCardElementOnChange}
          options={{
            showIcon: true,
            classes: {
              base: 'p-3 border border-solid rounded-md shadow-sm border-Gray-300 text-Gray-800 focus:outline-Cobalt-400',
              focus: 'ring-1 ring-Cobalt-400',
            },
          }}
        />

        <div className='flex items-center justify-around w-full py-5 space-x-5'>
          <div className='w-full flex-col'>
            <div className='flex text-Gray-800 font-Medium mb-1'>
              <span>Expiry</span>
              <span className='text-Red-500 pl-[2px]'>*</span>
            </div>
            <CardExpiryElement
              onChange={handleCardElementOnChange}
              options={{
                classes: {
                  base: 'p-3 border border-solid rounded-md shadow-sm border-Gray-300 text-Gray-800 w-full bg-white',
                  focus: 'ring-1 ring-Cobalt-400',
                },
              }}
            />
          </div>
          <div className='w-full flex-col'>
            <div className='flex text-Gray-800 font-Medium mb-1'>
              <span>CVC</span>
              <span className='text-Red-500 pl-[2px]'>*</span>
            </div>
            <CardCvcElement
              onChange={handleCardElementOnChange}
              options={{
                classes: {
                  base: 'p-3 border border-solid rounded-md shadow-sm border-Gray-300 text-Gray-800 focus:outline-Cobalt-400 w-[200px] bg-white',
                  focus: 'ring-1 ring-Cobalt-400',
                },
              }}
            />
          </div>
        </div>

        <TextField
          className='mb-4'
          value={null}
          isRequired
          onChange={(e: any) => setNameOnCard(e.target.value)}
          label='Name on the card'
          placeholder=''
        />
      </div>
    </Modal>
  )
}
