export const updateNode = (componentRef: React.RefObject<HTMLElement>) => {
  if (!componentRef.current) {
    return
  }
  const containers = Array.from(
    componentRef.current.querySelectorAll('.append-class-container'),
  )

  const elementsToAppend = Array.from(
    componentRef.current.querySelectorAll(
      '.form-control, .checkboxes, .field-radio-group',
    ),
  )

  if (containers.length > 0 && elementsToAppend.length > 0) {
    elementsToAppend.forEach((element, index) => {
      const containerIndex = index % containers.length
      const container = containers[containerIndex]

      if (element && container) {
        if (!container.contains(element)) {
          element.classList.add('w-full')

          try {
            container.appendChild(element)
          } catch (error) {
            console.error('Error appending element:', error)
          }
        }
      }
    })
  } else {
    console.log('No containers or elements to append found')
  }
}
