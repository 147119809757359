export const QuestionType = {
  Boolean: 'boolean',
  Radio: 'radio',
  RadioPositiveDetail: 'radio_pos_detail',
  RadioNegativeDetail: 'radio_neg_detail',
  Text: 'text',
  TextArea: 'text_area',
  TextArea2: 'textarea',
  Select: 'select',
  CheckBox: 'checkbox',
  Rating: 'rating',
  Heading: 'heading',
  Paragraph: 'paragraph',
} as const

// Create a type alias for question types
export type QuestionType = (typeof QuestionType)[keyof typeof QuestionType]

export function getQuestionType(type: QuestionType): string {
  switch (type) {
    case QuestionType.Text:
      return 'Short Answer'
    case QuestionType.TextArea:
      return 'Long Answer'
    case QuestionType.TextArea2:
      return 'Long Answer'
    case QuestionType.Rating:
      return 'Rating'
    case QuestionType.CheckBox:
      return 'Multi Choice'
    case QuestionType.Radio:
      return 'Yes/No'
    case QuestionType.Select:
      return 'Select Box'
    case QuestionType.RadioPositiveDetail:
      return 'Detailed Yes'
    case QuestionType.RadioNegativeDetail:
      return 'Detailed No'
    case QuestionType.Paragraph:
      return 'Paragraph'
    case QuestionType.Heading:
      return 'Heading'
    case QuestionType.Boolean:
      return 'Boolean'
    default:
      return 'Invalid Type'
  }
}
