export const createAccount = 'createAccount'
export const verifyAccount = 'verifyAccount'
export const paymentData = 'paymentData'
export const aboutYou = 'aboutYou'
export const aboutYourBusiness = 'aboutYourBusiness'
export const brandingLogos = 'brandingLogos'
export const contactInformation = 'contactInformation'
export const companyLocation = 'companyLocation'
export const colleagues = 'colleagues'
export const companySettingsOne = 'companySettingsOne'
export const companySettingsTwo = 'companySettingsTwo'

export * from './bonusCreditMap'
export * from './mandatorySignupStepperComponents'
export * from './optionalSignupStepperComponents'
