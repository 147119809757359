/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */

import { AgencyProfileDto } from '../types'

import { setAgencyState } from './reducer'

export const setAgency =
  (value: AgencyProfileDto | null, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setAgencyState(value))
  }
