import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

export const usePublishAssessment = () => {
  const mutate = useMutation((id: string) => {
    return putAPI(`/assessment/${id}/publish`, {})
  })

  return mutate
}
