import React, { useEffect, useImperativeHandle, useMemo, useState } from 'react'
import {
  SimpleSelectOption,
  ToggleDetailSection,
} from '@labourhub/labour-hub-ds'
import { useGetQuestionnaireTemplateList } from 'api/questionnaires'
import {
  AppSettingKey,
  AppSettingProps,
  getSettingValue,
  isSettingEnabled,
  updateSettingByKey,
} from 'features/settings'

import { CustomSelect } from './CustomSelect'

type CandidateSettingsFormProps = {
  agencySettings: AppSettingProps[]
  candidateSettings: AppSettingProps[]
  setCandidateSettings: (settings: AppSettingProps[]) => void
}

export type CandidateSettingsFormRef = {
  resetForm: () => void
}

export const CandidateSettingsForm = React.forwardRef<
  CandidateSettingsFormRef,
  CandidateSettingsFormProps
>(({ agencySettings, candidateSettings, setCandidateSettings }, ref) => {
  useImperativeHandle(ref, () => ({
    resetForm,
  }))

  const [selectedTemplate, setSelectedTemplate] = useState({
    value: 'Blank',
    label: 'Blank',
  })

  // Fetching Questionnaire list
  const { data: questionnaireTemplates } = useGetQuestionnaireTemplateList()

  useEffect(() => {
    const template = questionnaireTemplates?.find(
      (q) =>
        q.id ===
        getSettingValue(
          AppSettingKey.QuestionnaireTemplateId,
          candidateSettings,
        ),
    )

    if (template) {
      setSelectedTemplate({
        value: template.id,
        label: template.name,
      })
    }
  }, [candidateSettings, questionnaireTemplates])

  const templateOptions = useMemo(
    () => [
      { value: 'Blank', label: 'Blank' },
      ...(questionnaireTemplates?.map((template) => ({
        value: template?.id,
        label: template?.name,
      })) || []),
    ],
    [questionnaireTemplates],
  )

  const disableFeatureToggle = (key: AppSettingKey) => {
    const tenantAppSetting = getSettingValue(key, agencySettings)
    const candidateAppSetting = getSettingValue(key, candidateSettings)
    if (
      tenantAppSetting === 'true' &&
      (candidateAppSetting === 'true' || candidateAppSetting === 'false')
    ) {
      return false
    }

    return true
  }

  const handleToggle = (
    key: AppSettingKey,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const updatedSettings = updateSettingByKey(key, candidateSettings, {
      value: `${e.target.checked}`,
    })

    setCandidateSettings(updatedSettings)
  }

  const handleTemplateChange = (template: SimpleSelectOption) => {
    const updatedSettings = updateSettingByKey(
      AppSettingKey.QuestionnaireTemplateId,
      candidateSettings,
      {
        value: template.value,
      },
    )
    setCandidateSettings(updatedSettings)
    setSelectedTemplate(template)
  }

  const resetForm = () => {
    setSelectedTemplate({
      value: 'Blank',
      label: 'Blank',
    })
  }

  return (
    <div className='pb-5'>
      {/* ----------- Auto-referencing Toggle --------------- */}
      <ToggleDetailSection
        id='auto-referencing-feature'
        label='Turn on Auto-referencing'
        description='Once candidates are added, the system promptly requests referee details and automatically sends out reference surveys upon receiving the required information.'
        hasTooltip={
          !isSettingEnabled(AppSettingKey.AutoReferencing, agencySettings)
        }
        tooltipText='Auto-referencing setting is disabled.\nPlease contact the administrator to enable it.'
        isDisabled={disableFeatureToggle(AppSettingKey.AutoReferencing)}
        value={isSettingEnabled(
          AppSettingKey.AutoReferencing,
          candidateSettings,
        )}
        onClick={(e: any) => handleToggle(AppSettingKey.AutoReferencing, e)}
      />

      {/* ----------- Select Template --------------- */}
      {isSettingEnabled(AppSettingKey.AutoReferencing, candidateSettings) && (
        <CustomSelect
          label='Choose Template'
          className='mt-5'
          options={templateOptions}
          value={selectedTemplate}
          onChange={handleTemplateChange}
          isRequired={isSettingEnabled(
            AppSettingKey.AutoReferencing,
            candidateSettings,
          )}
        />
      )}
      {/* ----------- Auto-referencing Toggle ------------- */}
      {/* <ToggleDetailSection
        label='Start process by requesting referee details'
        description='When candidates are added, the system promptly requests referee details. Please note that each request for reference details will count as 1 credit.'
        isDisabled={true}
        value={candidateSettings.autoReferencing === 'true'}
      /> */}

      {/* ----------- SMS Toggle --------------- */}
      {/* <ToggleDetailSection
          id='sms-feature'
          label='Enable SMS Feature'
          description='This will enable the sms feature for a candidate'
          // hasTooltip={
          //   !isSettingEnabled(AppSettingKey.SmsFeature, agencySettings)
          // }
          // tooltipText='SMS is a premium feature.\nUpgrade your account or contact support to enable it.'
          isDisabled={
            disableFeatureToggle(AppSettingKey.SmsFeature) || !isEmailRequired
          }
          value={
            isSettingEnabled(AppSettingKey.SmsFeature, agencySettings) &&
            isSettingEnabled(AppSettingKey.SmsFeature, candidateSettings)
          }
          onClick={(e: any) => handleToggle(AppSettingKey.SmsFeature, e)}
        /> */}
    </div>
  )
})
