import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const getAssessmentBuildById = async ({ queryKey }: any) => {
  const reqBody = queryKey[1]
  const res = await get(`/assessment/${reqBody.aId}`)
  return res.data
}

export const useGetAssessmentBuildById = (
  { ...reqBody },
  onSuccess,
  onError,
) => {
  return useQuery(['Assessments', reqBody], getAssessmentBuildById, {
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess,
    onError,
  })
}
