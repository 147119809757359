/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  setCandidatesState,
  setIsNavigatingFromNewAssessmentModalState,
  setIsRefreshCandidateTableState,
} from './reducer'

export const setCandidates =
  (value: any, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setCandidatesState(value))
  }

export const setIsRefreshCandidateTable = () => (dispatch: any) => {
  dispatch(setIsRefreshCandidateTableState())
}

export const setIsNavigatingFromNewAssessmentModal =
  (data: any, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setIsNavigatingFromNewAssessmentModalState(data))
    cb()
  }
