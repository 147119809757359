import { useState } from 'react'
import { useGetAssessmentLibraryList } from 'api/assessment'
import EmptAddOnsIcon from 'assets/images/assessment/EmptAddOnsIcon.svg'

import { PageLoader } from 'components/atoms'

import { Addon } from '../SendAssessmentModal'

import AddonCard from './AddonCard'

export default function PopularAddons({
  handleAddAssessment,
  handleRemoveAssessment,
  selectedAddons,
  isSingleView = false,
}: {
  handleAddAssessment: (addon: Addon) => void
  handleRemoveAssessment: (addon: Addon) => void
  selectedAddons: Addon[]
  isSingleView?: boolean
}) {
  const [searchKeyWord, setSearchKeyWord] = useState<string>('')
  const [searchAddons, setSearchAddons] = useState<Addon[]>()
  const [addons, setAddons] = useState<Addon[]>()

  const {
    isLoading: assessmentLibraryLoading,
    isFetching: assessmentLibraryFetching,
  } = useGetAssessmentLibraryList(
    {
      search: '',
      type: 'AddOn',
    },
    (data) => {
      const addonArray: Addon[] = data.map((item) => ({
        assessmentId: item.id,
        assessmentName: item.role,
        credits: 1,
        imageUrl: item.imageUrl,
        duration: item.timeToComplete,
        questionCount: item.questionCount,
      }))
      setAddons(addonArray)
    },
  )

  if (assessmentLibraryLoading || assessmentLibraryFetching)
    return <PageLoader size='xxs' />
  return (
    <div
      className={`space-y-4 bg-white pt-5  rounded-md ${
        !isSingleView ? 'px-5 w-[592px]' : 'w-full'
      }`}>
      {!isSingleView && (
        <h1 className='text-[18px] leading-8 font-SemiBold mb-4'>
          Popular Add-on Assessments
        </h1>
      )}

      {/* search bar */}
      <div className='flex flex-row gap-2'>
        <i className='ri-search-line text-Gray-400  flex items-center  pointer-events-none' />
        <input
          type='text'
          value={searchKeyWord}
          autoComplete='off'
          className='w-full font-Regular pr-[30px] text-Gray-600 active:outline-Gray-50 focus:outline-Gray-50 rounded'
          placeholder='Search by Assessment Name'
          onKeyUp={() => {
            if (searchKeyWord.length > 0) {
              const selected = addons?.filter((addon) =>
                addon.assessmentName
                  .toLowerCase()
                  .includes(searchKeyWord.toLowerCase()),
              )
              setSearchAddons(selected)
            }
          }}
          onChange={(e: any) => {
            setSearchKeyWord(e.target.value ?? '')
          }}
        />
        {searchKeyWord != '' && (
          <button
            className='cursor-pointer '
            onClick={() => {
              setSearchKeyWord('')
            }}>
            <i className=' text-Gray-400 ri-close-line text-[16px]' />
          </button>
        )}
      </div>
      {/* Addon list */}
      <div
        className={`styled-scroll flex flex-col gap-5 pr-2 -mr-2 overflow-y-auto h-[458px] ${
          searchKeyWord === '' ? 'overflow-x-hidden' : ''
        } `}>
        {(searchKeyWord === '' ? addons : searchAddons)?.map((addon, i) => {
          return (
            <AddonCard
              key={`${addon.assessmentName}-${i}`}
              handleAddAssessment={handleAddAssessment}
              handleRemoveAssessment={handleRemoveAssessment}
              addon={addon}
              isDisabled={selectedAddons?.some(
                (selected) => selected.assessmentId === addon.assessmentId,
              )}
              isSelected={
                isSingleView
                  ? selectedAddons?.some(
                      (selected) =>
                        selected.assessmentId === addon.assessmentId,
                    )
                  : false
              }
            />
          )
        })}
        {searchKeyWord != '' && searchAddons?.length === 0 && (
          <div className='flex flex-col items-center justify-center'>
            <img
              src={EmptAddOnsIcon}
              alt='empty add-ons'
              className='h-[80px]'
            />

            <div className='flex flex-col items-center pt-2'>
              <span className='text-heading-4 text-Gray-800 font-Medium pb-1'>
                No Results Found
              </span>

              <span className='font-Regular text-Gray-600'>
                We’re sorry, we could not find any results for you.
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
