import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

export type NoteHistory = {
  message: string
  action?: string
  id: string
  createdOn: string
  updatedOn: string
  noteId: string
  notes: Notes
  previousDescription?: string
}
export type Notes = {
  userProfileId: string
  type: string
  title: string
  description?: string
  icon?: 'Success' | 'Info' | 'Error' | 'Send' | 'Add' | 'Note'
  uuid: string
  meta?: any
  isRead: boolean
  id: string
  createdOn: string
  updatedOn: string
}

const listCandidateNoteHistory = async ({ queryKey }: any) => {
  const { candidateId } = queryKey[1]
  const res = await get(`/CandidateNoteHistory?CandidateId=${candidateId}`)
  return res.data.noteTracking
}

export const useListCandidateNoteHistory = (
  queryParams,
  onSuccess: (data: NoteHistory[]) => void,
) => {
  return useQuery(
    ['list_candidate_note_history', queryParams],
    listCandidateNoteHistory,
    {
      enabled: true,
      refetchOnWindowFocus: false,
      onSuccess,
      onError: () => {
        Toast({
          alertHeader: 'Error fetching candidate history',
          status: 'Error',
        })
      },
    },
  )
}
