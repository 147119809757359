import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

type ManagingConsultantType = {
  value: string
  label: string
}

export const useGetManagingConsultantList = () => {
  return useQuery(
    ['ManagingConsultantList'],
    async () => {
      const res = await get(`/Candidate/ListConsultant`)
      return res?.data?.managingConsultantList as ManagingConsultantType[]
    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
      onError: () => {
        Toast({
          alertHeader: 'Error fetching managing consultant list',
          status: 'Error',
        })
      },
    },
  )
}
