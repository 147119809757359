import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface CreateNewAgencyInterface {
  userFormDetails?: any
}

export const useCreateNewAgency = () => {
  const mutate = useMutation(
    ({ userFormDetails }: CreateNewAgencyInterface) => {
      const {
        firstName,
        lastName,
        email,
        phoneNumber,
        companyName,
        subDomain,
        companySize,
        password,
        referredBy,
        utm_source,
      } = userFormDetails || {}

      return postAPI('/facade/SignUp', {
        firstName,
        lastName,
        email,
        phoneNumber,
        companyName,
        companySize,
        subdomain: subDomain,
        password,
        referedBy: utm_source ? utm_source : referredBy,
      })
    },
  )

  return mutate
}

export default useCreateNewAgency
