import { lazyImport } from 'utils/lazyImport'

const { QuestionnaireBuilderPage } = lazyImport(
  () => import('../pages/QuestionnaireBuilderPage'),
  'QuestionnaireBuilderPage',
)

const { QuestionnaireTabsPage } = lazyImport(
  () => import('../pages/QuestionnaireTabsPage'),
  'QuestionnaireTabsPage',
)

export { QuestionnaireBuilderPage, QuestionnaireTabsPage }
