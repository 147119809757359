import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

const getAgencyList = async () => {
  const res = await get(`/AgencyProfiles`)
  return res.data
}

export const useGetAgencyList = (onSuccess: (data: any) => void) => {
  return useQuery(['AgencyProfiles'], getAgencyList, {
    enabled: true,
    refetchOnWindowFocus: false,
    onSuccess,
    onError: () => {
      Toast({
        alertHeader: 'Error fetching agency list',
        status: 'Error',
      })
    },
  })
}
