import americanExpressLogo from 'assets/images/payment/american-express.svg'
import anzLogo from 'assets/images/payment/anz.svg'
import bankOfMelbourneLogo from 'assets/images/payment/bank-of-melbourne.svg'
import commonWealthLogo from 'assets/images/payment/common-wealth.svg'
import mastercardLogo from 'assets/images/payment/mastercard.svg'
import nabLogo from 'assets/images/payment/nab.svg'
import uniounPayLogo from 'assets/images/payment/union-pay.svg'
import visaLogo from 'assets/images/payment/visa.svg'
import westpacLogo from 'assets/images/payment/westpac.svg'

import { CreditCardVariant } from '../types'

export const creditCardvariants: Record<CreditCardVariant, any> = {
  'visa': {
    icon: visaLogo,
    gradient: 'from-[#1E8282] to-[#3B9B9AE8] hover:to-[#1E8282]',
    position: 'right-4 bottom-4',
  },
  'mastercard': {
    icon: mastercardLogo,
    iconClass: 'h-[35px]',
    gradient: 'from-[#1B599D] to-[#4989CD] hover:to-[#1B599D]',
    position: 'right-1 bottom-2',
  },
  'nab': {
    icon: nabLogo,
    gradient: 'from-[#3D2CA0] to-[#8265C7] hover:to-[#3D2CA0]',
    position: 'right-2 bottom-4',
  },
  'anz': {
    icon: anzLogo,
    gradient: 'from-[#763088] to-[#FC95B4] hover:to-[#763088]',
    position: 'right-4 bottom-4',
  },
  'amex': {
    icon: americanExpressLogo,
    gradient: 'from-[#071790] to-[#D5018B] hover:to-[#071790]',
    position: 'right-3 bottom-4',
  },
  'common-wealth': {
    icon: commonWealthLogo,
    gradient: 'from-[#06668E] to-[#B567A6] hover:to-[#06668E]',
    position: 'right-0 bottom-4',
  },
  'union-pay': {
    icon: uniounPayLogo,
    gradient: 'from-[#B14110] to-[#075BA9] hover:to-[#B14110]',
    position: 'right-2 bottom-4',
  },
  'westpac': {
    icon: westpacLogo,
    gradient: 'from-[#56328D] to-[#27B3C7] hover:to-[#56328D]',
    position: 'right-2 bottom-4',
  },
  'bank-of-melbourne': {
    icon: bankOfMelbourneLogo,
    gradient: 'from-[#B60019] to-[#F67B5299]  hover:to-[#B60019]',
    position: 'right-4 bottom-4',
  },
  'blank': {
    icon: visaLogo,
    gradient: 'from-[#075BA9] to-[#E57062] hover:to-[#075BA9]',
    position: 'right-2 bottom-4',
  },
  'other': {
    gradient: 'from-[#075BA9] to-[#E57062] hover:to-[#075BA9]',
    position: 'right-2 bottom-4',
  },
}
