/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Checkbox, TextField } from '@labourhub/labour-hub-ds'
import { format, parse } from 'date-fns'
import { RefereeTypeCard } from 'features/candidates/components/AddEditCandidateModals/RefereeTypeCard'
import {
  AppSettingKey,
  getAgencyAppSettings,
  isSettingEnabled,
} from 'features/settings'
import { useEditReferee } from 'framework/api/methods'
import { ModalProps, ReferenceCheckType } from 'types'

import { CalendarInput, PageLoader, PhoneInputField } from 'components/atoms'
import { RoundedModal } from 'components/ui/RoundedModal'
import { isEmail, isLetters, isPhoneNumber, referenceTypeToText } from 'utils'

import { refTypeStepMap } from './AddRefereeCard/AddRefereeModal'

type EditRefereeModalProps = ModalProps & {
  [x: string]: any
}

export const EditRefereeModal = ({
  candidateId,
  isModalActive,
  setIsModalActive,
  notify,
  getCandidateRefereesList,
  selectedRefereeDetails,
  setSelectedRefereeDetails,
  ...restProps
}: EditRefereeModalProps) => {
  const agencyAppSettings = useSelector(getAgencyAppSettings)

  const isEmailRequired = isSettingEnabled(
    AppSettingKey.IsRefereeEmailRequired,
    agencyAppSettings,
  )

  const isSmsEnabled = isSettingEnabled(
    AppSettingKey.SmsFeature,
    agencyAppSettings,
  )

  const [refereeDetails, setRefereeDetails] = useState<any>({
    refId: '',
    firstName: '',
    lastName: '',
    countryCode: '',
    phone: '',
    email: '',
    company: '',
    jobTitle: '',
    relationshipToCandidate: '',
    preCompanyName: '',
    preCandidatePosition: '',
    preJobStartDate: '',
    preJobFinishDate: '',
    referenceType: '',
    isCurrentlyWorking: false,
  })

  const [renderKeys, setRenderKeys] = useState<any>({
    jobStartDate: 0,
    jobFinishDate: 0,
  })

  const [isErrorField, setIsErrorField] = useState<any>({
    firstName: false,
    lastName: false,
    relationshipToCandidate: false,
    email: false,
    phone: false,
    company: false,
    jobTitle: false,
    preCompanyName: false,
    preCandidatePosition: false,
  })

  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(true)

  const [stepperState, setStepperState] = useState<any>({
    currentStep: 2,
    refTypeDetails: '',
  })

  useEffect(() => {
    if (isModalActive) {
      const {
        id,
        firstName,
        lastName,
        phone,
        countryCode,
        email,
        company,
        jobTitle,
        candidateRelation,
        candidateCompany,
        candidatePosition,
        startDate,
        endDate,
        referenceType,
        isCurrentlyWorking,
      } = selectedRefereeDetails || {}

      setRefereeDetails({
        ...refereeDetails,
        refId: id,
        firstName,
        lastName,
        phone,
        countryCode,
        email,
        company,
        jobTitle,
        relationshipToCandidate: candidateRelation,
        preCompanyName: candidateCompany,
        preCandidatePosition: candidatePosition,
        preJobStartDate:
          referenceType?.type === ReferenceCheckType?.Employment
            ? startDate
            : '',
        preJobFinishDate:
          referenceType?.type === ReferenceCheckType?.Employment ? endDate : '',
        referenceType: referenceType?.type,
        isCurrentlyWorking,
      })

      setStepperState({
        ...stepperState,
        refTypeDetails: referenceType?.type,
      })
    }
  }, [selectedRefereeDetails, isModalActive])

  /** front end validation input fields */
  const validateInputs = () => {
    const { firstName, lastName, relationshipToCandidate, email, phone } =
      refereeDetails

    setIsErrorField({
      ...isErrorField,
      firstName: isLetters(firstName) && firstName?.length < 100 ? false : true,
      lastName: isLetters(lastName) && lastName?.length < 100 ? false : true,
      relationshipToCandidate:
        relationshipToCandidate?.length < 100 ? false : true,
      phone: isPhoneNumber(phone) && phone?.length < 20 ? false : true,
    })

    const isAllValidFields =
      isLetters(firstName) &&
      firstName?.length < 100 &&
      isLetters(lastName) &&
      lastName?.length < 100 &&
      relationshipToCandidate?.length < 100 &&
      isPhoneNumber(phone) &&
      phone?.length < 20

    if (isEmailRequired) {
      setIsErrorField({
        ...isErrorField,
        email: isEmail(email) && email?.length < 200 ? false : true,
        firstName:
          isLetters(firstName) && firstName?.length < 100 ? false : true,
        lastName: isLetters(lastName) && lastName?.length < 100 ? false : true,
        relationshipToCandidate:
          relationshipToCandidate?.length < 100 ? false : true,
        phone: isPhoneNumber(phone) && phone?.length < 20 ? false : true,
      })
      return isAllValidFields && email?.length < 200 && isEmail(email)
    }

    return isAllValidFields
  }

  /** handle add referee */
  const onEditReferee = () => {
    editReferee()
  }

  /** APi call for the edit referee */
  const { mutate: editRefereeMutate, isLoading: editRefereeIsLoading } =
    useEditReferee()

  /** Process the edit existing candidate */
  const editReferee = () => {
    editRefereeMutate(
      {
        candidateId,
        refereeData: refereeDetails,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData?.message,
            alertBody: '',
            status: 'Success',
          })

          resetInputFields()
          getCandidateRefereesList()
          setSelectedRefereeDetails(null)
          setIsModalActive(false)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  const resetInputFields = () => {
    setRefereeDetails({
      firstName: '',
      lastName: '',
      countryCode: '',
      phone: '+61',
      email: '',
      company: '',
      jobTitle: '',
      relationshipToCandidate: '',
      preCompanyName: '',
      preCandidatePosition: '',
      preJobStartDate: '',
      preJobFinishDate: '',
      referenceType: '',
      isCurrentlyWorking: false,
    })

    setStepperState({
      currentStep: 2,
      refTypeDetails: '',
    })
    setRenderKeys({
      ...renderKeys,
      preJobStartDate: Math.random(),
      preJobFinishDate: Math.random(),
    })
  }

  /** handle primary button disable */
  useEffect(() => {
    const {
      firstName,
      lastName,
      phone,
      countryCode,
      email,
      company,
      jobTitle,
      relationshipToCandidate,
      preCompanyName,
      preCandidatePosition,
      preJobStartDate,
      preJobFinishDate,
    } = refereeDetails || {}

    if (isEmailRequired) {
      firstName &&
      lastName &&
      phone &&
      countryCode &&
      email &&
      (stepperState?.currentStep === 2 &&
      stepperState?.refTypeDetails !== ReferenceCheckType?.FriendFamily
        ? company
        : true) &&
      (stepperState?.currentStep === 2 &&
      stepperState?.refTypeDetails !== ReferenceCheckType?.FriendFamily
        ? jobTitle
        : true) &&
      relationshipToCandidate &&
      (stepperState?.currentStep === 3 &&
      stepperState?.refTypeDetails !== ReferenceCheckType?.FriendFamily
        ? preCompanyName
        : true) &&
      (stepperState?.currentStep === 3 &&
      stepperState?.refTypeDetails !== ReferenceCheckType?.FriendFamily
        ? preCandidatePosition
        : true) &&
      (stepperState?.currentStep === 3 &&
      stepperState?.refTypeDetails === ReferenceCheckType?.Employment
        ? preJobStartDate
        : true) &&
      (stepperState?.currentStep === 3 &&
      stepperState?.refTypeDetails === ReferenceCheckType?.Employment
        ? refereeDetails.isCurrentlyWorking
          ? true
          : preJobFinishDate
        : true)
        ? setIsPrimaryButtonDisabled(false)
        : setIsPrimaryButtonDisabled(true)
    } else {
      firstName &&
      lastName &&
      phone &&
      countryCode &&
      (stepperState?.currentStep === 2 &&
      stepperState?.refTypeDetails !== ReferenceCheckType?.FriendFamily
        ? company
        : true) &&
      (stepperState?.currentStep === 2 &&
      stepperState?.refTypeDetails !== ReferenceCheckType?.FriendFamily
        ? jobTitle
        : true) &&
      relationshipToCandidate &&
      (stepperState?.currentStep === 3 &&
      stepperState?.refTypeDetails !== ReferenceCheckType?.FriendFamily
        ? preCompanyName
        : true) &&
      (stepperState?.currentStep === 3 &&
      stepperState?.refTypeDetails !== ReferenceCheckType?.FriendFamily
        ? preCandidatePosition
        : true) &&
      (stepperState?.currentStep === 3 &&
      stepperState?.refTypeDetails === ReferenceCheckType?.Employment
        ? preJobStartDate
        : true) &&
      (stepperState?.currentStep === 3 &&
      stepperState?.refTypeDetails === ReferenceCheckType?.Employment
        ? refereeDetails.isCurrentlyWorking
          ? true
          : preJobFinishDate
        : true) &&
      isSmsEnabled
        ? setIsPrimaryButtonDisabled(false)
        : setIsPrimaryButtonDisabled(true)
    }
  }, [refereeDetails])

  // Add a function to reset error state
  const resetErrorState = () => {
    setIsErrorField({
      firstName: false,
      lastName: false,
      relationshipToCandidate: false,
      email: false,
      phone: false,
    })
  }

  const handleEditReferee = () => {
    const valid = validateInputs()
    if (
      stepperState?.currentStep ===
        refTypeStepMap[stepperState?.refTypeDetails] &&
      valid
    ) {
      onEditReferee()
    } else {
      if (valid) {
        setStepperState({
          ...stepperState,
          currentStep: stepperState?.currentStep + 1,
        })
      } else {
        notify({
          alertHeader: 'Invalid input type.',
          status: 'Warning',
        })
      }
    }
  }

  const handleCloseModal = () => {
    resetInputFields()
    setIsModalActive(false)
    resetErrorState()
  }

  return (
    <RoundedModal
      isActive={isModalActive}
      className='w-full'
      headerTitle={
        referenceTypeToText(stepperState?.refTypeDetails) + ` Referee Details`
      }
      headerSubtitle={`Edit ${referenceTypeToText(
        stepperState?.refTypeDetails,
      )} Referee Details`}
      primaryButtonTitle={
        stepperState?.currentStep ===
        refTypeStepMap[stepperState?.refTypeDetails]
          ? `Update Referee`
          : 'Continue'
      }
      secondaryButtonTitle='Cancel'
      isPrimaryButtonDisable={isPrimaryButtonDisabled}
      isHeaderShow={true}
      isFooterShow={stepperState?.currentStep === 1 ? false : true}
      isSeparator={true}
      onHeaderCloseButtonClick={() => {
        handleCloseModal()
      }}
      onClickPrimaryBtn={() => {
        handleEditReferee()
      }}
      onClickSecondaryBtn={() => {
        handleCloseModal()
      }}
      {...restProps}>
      {/** drawer loader */}
      {editRefereeIsLoading && <PageLoader size='xxs' />}

      {/** Body section of the drawer modal */}
      <div className='w-full px-6 py-5 h-fit'>
        {/* referee details section ------------------------------------------------------------------------------*/}

        {stepperState?.currentStep == 3 ? (
          <div className='w-full px-4 py-2 rounded-lg bg-Cobalt-50 text-Cobalt-800 text-small font-SemiBold'>
            Candidate role details of the previous company
          </div>
        ) : (
          <div className='w-full px-4 py-2 rounded-lg bg-Cobalt-50 text-Cobalt-800 text-small font-SemiBold'>
            Referee details
          </div>
        )}

        {stepperState?.currentStep == 1 && (
          <div
            className='flex flex-col gap-y-4'
            key={stepperState?.refTypeDetails}>
            <span className='mt-5 text-small font-SemiBold'>
              Select type of Reference
            </span>

            <RefereeTypeCard
              id={ReferenceCheckType?.Employment}
              name='referee'
              onChange={() => {
                setStepperState({
                  currentStep: stepperState?.currentStep + 1,
                  refTypeDetails: ReferenceCheckType?.Employment,
                })

                setRefereeDetails({
                  ...refereeDetails,
                  referenceType: ReferenceCheckType?.Employment,
                })
              }}
              defaultChecked={
                stepperState?.refTypeDetails === ReferenceCheckType?.Employment
              }
              isSelected={
                stepperState?.refTypeDetails === ReferenceCheckType?.Employment
              }
              icon='ri-community-line'
              title='Employment Reference'
              subTitle='From a past employer. Candidate must enter prior employment details with this referee.'
            />

            <RefereeTypeCard
              id={ReferenceCheckType?.Professional}
              name='referee'
              onChange={() => {
                setStepperState({
                  currentStep: stepperState?.currentStep + 1,
                  refTypeDetails: ReferenceCheckType?.Professional,
                })
                setRefereeDetails({
                  ...refereeDetails,
                  referenceType: ReferenceCheckType?.Professional,
                })
              }}
              isSelected={
                stepperState?.refTypeDetails ===
                ReferenceCheckType?.Professional
              }
              defaultChecked={
                stepperState?.refTypeDetails ===
                ReferenceCheckType?.Professional
              }
              icon='ri-briefcase-fill'
              title='Professional Reference'
              subTitle='From a professional contact, not necessarily a past employer. No prior employment details required.'
            />

            <RefereeTypeCard
              id={ReferenceCheckType?.FriendFamily}
              name='referee'
              onChange={() => {
                setStepperState({
                  currentStep: stepperState?.currentStep + 1,
                  refTypeDetails: ReferenceCheckType?.FriendFamily,
                })
                setRefereeDetails({
                  ...refereeDetails,
                  referenceType: ReferenceCheckType?.FriendFamily,
                })
              }}
              isSelected={
                stepperState?.refTypeDetails ===
                ReferenceCheckType?.FriendFamily
              }
              defaultChecked={
                stepperState?.refTypeDetails ===
                ReferenceCheckType?.FriendFamily
              }
              icon='ri-account-pin-circle-line'
              title='Friends & Family Reference'
              subTitle='Personal reference on character and work ethic. No prior employment details required.'
            />
          </div>
        )}

        {stepperState?.currentStep == 2 && (
          <>
            <TextField
              label='First Name'
              isRequired
              className='mt-5'
              value={refereeDetails?.firstName}
              onChange={(e: any) => {
                setRefereeDetails({
                  ...refereeDetails,
                  firstName: e.target.value,
                })
              }}
              placeholder=''
              isErrored={isErrorField?.firstName}
            />

            {/* last name section */}
            <TextField
              label='Last Name'
              isRequired
              className='mt-5'
              value={refereeDetails?.lastName}
              onChange={(e: any) => {
                setRefereeDetails({
                  ...refereeDetails,
                  lastName: e.target.value,
                })
              }}
              placeholder=''
              isErrored={isErrorField?.lastName}
            />

            {/* relationship to you section */}
            <TextField
              label='Relationship to you'
              className='mt-5'
              isRequired
              value={refereeDetails?.relationshipToCandidate}
              onChange={(e: any) => {
                setRefereeDetails({
                  ...refereeDetails,
                  relationshipToCandidate: e.target.value,
                })
              }}
              placeholder=''
              isErrored={isErrorField?.relationshipToCandidate}
            />

            {/** Phone number section */}
            <PhoneInputField
              label='Phone'
              isRequired
              value={refereeDetails?.phone}
              onChangeWithCountry={(number: any, countryCode: string) =>
                setRefereeDetails({
                  ...refereeDetails,
                  phone: number,
                  countryCode,
                })
              }
              className='mt-5'
              isErrored={isErrorField?.phone}
            />

            {/* email section */}
            <TextField
              label='Email'
              isRequired={isEmailRequired}
              className='mt-5'
              value={refereeDetails?.email}
              onChange={(e: any) => {
                setRefereeDetails({
                  ...refereeDetails,
                  email: e.target.value,
                })
              }}
              placeholder=''
              isErrored={isEmailRequired && isErrorField.email}
            />

            {stepperState?.refTypeDetails !==
              ReferenceCheckType.FriendFamily && (
              <>
                {/* company section */}
                <TextField
                  label={
                    stepperState?.refTypeDetails ==
                    ReferenceCheckType.Professional
                      ? "Referee's Current Company Name"
                      : 'Company Name'
                  }
                  isRequired
                  className='mt-5'
                  value={refereeDetails?.company}
                  onChange={(e: any) => {
                    setRefereeDetails({
                      ...refereeDetails,
                      company: e.target.value,
                    })
                  }}
                  placeholder=''
                />

                {/* job title section */}
                <TextField
                  label="Referee's Job Title"
                  className='mt-5'
                  isRequired
                  value={refereeDetails?.jobTitle}
                  onChange={(e: any) => {
                    setRefereeDetails({
                      ...refereeDetails,
                      jobTitle: e.target.value,
                    })
                  }}
                  placeholder=''
                />
              </>
            )}
          </>
        )}
        {stepperState?.currentStep == 3 &&
          stepperState?.refTypeDetails === ReferenceCheckType?.Employment && (
            <>
              {/* company name section */}
              <TextField
                label='Company Name'
                isRequired
                className='mt-5'
                value={refereeDetails?.preCompanyName}
                onChange={(e: any) => {
                  setRefereeDetails({
                    ...refereeDetails,
                    preCompanyName: e.target.value,
                  })
                }}
                placeholder=''
              />
              {/* Position held at this company section */}
              <TextField
                label='Position held at this company'
                isRequired
                className='mt-5'
                value={refereeDetails?.preCandidatePosition}
                onChange={(e: any) => {
                  setRefereeDetails({
                    ...refereeDetails,
                    preCandidatePosition: e.target.value,
                  })
                }}
                placeholder=''
              />
              {/* Job Start Date section */}
              <div className='flex flex-col pt-5'>
                <span className='text-small text-Gray-800 font-Medium'>
                  Job Start Date
                  <span className='text-Red-500 pl-[2px]'>*</span>
                </span>

                <CalendarInput
                  key={renderKeys?.preJobStartDate}
                  value={refereeDetails.preJobStartDate}
                  formatDate={(date) => format(date, 'dd/MM/yyyy')}
                  parseDate={(str) => parse(str, 'dd/MM/yyyy', new Date())}
                  className='mt-1'
                  disableFutureDates={true}
                  onChange={(date) => {
                    setRefereeDetails({
                      ...refereeDetails,
                      preJobStartDate: date !== undefined ? date : null,
                    })
                  }}
                />
              </div>
              {/* Job Finish Date section */}
              <div className='flex flex-col pt-5'>
                <span className='text-small text-Gray-800 font-Medium'>
                  Job End Date
                  {!refereeDetails.isCurrentlyWorking && (
                    <span className='text-Red-500 pl-[2px]'>*</span>
                  )}
                </span>

                <CalendarInput
                  key={renderKeys?.preJobFinishDate}
                  value={
                    refereeDetails.isCurrentlyWorking
                      ? null
                      : refereeDetails.preJobFinishDate
                  }
                  formatDate={(date) => format(date, 'dd/MM/yyyy')}
                  parseDate={(str) => parse(str, 'dd/MM/yyyy', new Date())}
                  className='mt-1'
                  disabled={refereeDetails.isCurrentlyWorking}
                  onChange={(date) => {
                    setRefereeDetails({
                      ...refereeDetails,
                      preJobFinishDate: date !== undefined ? date : null,
                    })
                  }}
                />
              </div>
              {stepperState?.refTypeDetails ===
                ReferenceCheckType?.Employment && (
                <div className='mt-4'>
                  <Checkbox
                    labelText='I am currently working in this role'
                    className='mt-4'
                    labelTextClassName='font-Regular'
                    checkboxClassName='mt-0.5'
                    checked={refereeDetails.isCurrentlyWorking}
                    onChange={(e: any) => {
                      setRefereeDetails({
                        ...refereeDetails,
                        isCurrentlyWorking: e.target.checked,
                      })
                    }}
                  />
                </div>
              )}
            </>
          )}
      </div>
    </RoundedModal>
  )
}
