/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FC, useEffect, useState } from 'react'
import { Button } from '@labourhub/labour-hub-ds'
import { Viewer, Worker } from '@react-pdf-viewer/core'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import docxIcon from 'assets/images/docxIcon.svg'
import CN from 'classnames'
import { saveAs } from 'file-saver'
import { motion } from 'framer-motion'

import { PageLoader, SimpleSlider } from 'components/atoms'

import './CandidateDocumentsViewModal.scss'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'

export interface CandidateDocumentsViewModalProps {
  className?: string | undefined
  headerTitle?: string | undefined
  isActive?: boolean | undefined
  isCustomStyles?: boolean
  isViewHistoryButton?: boolean | undefined
  onClickCloseBtn?: () => void | undefined
  onClickViewHistoryButton?: () => void | undefined
  onHeaderCloseButtonClick?: () => void | undefined
  onOverlayClick?: () => void | undefined
  [x: string]: any
}

export const CandidateDocumentsViewModal: FC<
  CandidateDocumentsViewModalProps
> = ({
  className,
  docList,
  getDocumentSignedUrlsByIdIsLoading,
  headerTitle,
  isActive,
  isCustomStyles,
  isViewHistoryButton,
  onClickCloseBtn,
  onClickViewHistoryButton,
  onHeaderCloseButtonClick,
}: CandidateDocumentsViewModalProps) => {
  const [selectedFileUrl, setSelectedFileUrl] = useState<any>('')
  const [sliderData, setSliderData] = useState<any>()

  /** loading state*/
  const [isLoadState, setIsLoadState] = useState(true)

  /** current slide count state*/
  const [selectedSlideIndex, setSelectedSlideIndex] = useState<any>(0)

  /** creating new plugin instance*/
  const defaultLayoutPluginInstance = defaultLayoutPlugin()

  const OverlayClassName = !isCustomStyles
    ? CN(
        'modal-overlay fixed flex top-0 bottom-0 left-0 right-0 z-50 justify-center items-center h-full bg-black bg-opacity-20',
        className,
        {
          hidden: !isActive,
        },
      )
    : className

  /** prevent modal hide when click inside modal */
  const disableOverlayClick = (event: any) => {
    event.stopPropagation()
  }

  /** set download file url */
  useEffect(() => {
    setSelectedFileUrl(docList[selectedSlideIndex]?.attachmentSignedUrls)
  }, [selectedSlideIndex, docList])

  /** handle download file */
  const saveFile = () => {
    saveAs(selectedFileUrl, `Attachments`)
  }

  useEffect(() => {
    const sliderContent = docList.map((item: any, idx: any) => {
      return {
        id: idx,
        sliderContent:
          item.type == 'jpg' || item.type == 'png' || item.type == 'jpeg' ? (
            <div className='flex h-full w-full justify-center items-center'>
              <img
                src={item.attachmentSignedUrls}
                alt='img'
                className='h-full object-contain'
              />
            </div>
          ) : item.type == 'pdf' ? (
            <div
              className='w-full h-full flex justify-center items-center text-center'
              key={selectedSlideIndex}>
              <Worker
                workerUrl={
                  'https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js'
                }>
                <Viewer
                  fileUrl={item.attachmentSignedUrls}
                  plugins={[defaultLayoutPluginInstance]}></Viewer>
              </Worker>
            </div>
          ) : item.type == 'doc' || item.type == 'docx' ? (
            <div className='h-full flex flex-col w-full justify-center items-center px-[138px]'>
              <img src={docxIcon} alt='docx' />

              <span className='text-heading-4 text-Gray-800 font-Medium pt-3'>
                You are viewing a MS Word document!
              </span>

              <span className='text-Gray-600 font-Regular pt-2 text-center'>
                Due to technical limitations you can only download this file.
                Download and open this with MS word application.
              </span>

              <Button
                iconLeft={<i className='ri-download-2-line text-[20px]' />}
                className='mt-3'
                onClick={() => {
                  saveAs(docList[selectedSlideIndex]?.attachmentSignedUrls)
                }}
                target='_blank'
                download>
                Download File
              </Button>
            </div>
          ) : null,
      }
    })

    setSliderData(sliderContent)
  }, [docList, selectedSlideIndex])

  /** handle loading state */
  useEffect(() => {
    setIsLoadState(true)

    !getDocumentSignedUrlsByIdIsLoading && setIsLoadState(false)
  }, [getDocumentSignedUrlsByIdIsLoading])

  return (
    <div className={OverlayClassName}>
      {/* Modal main container  */}
      <motion.div
        animate={isActive ? 'open' : 'closed'}
        variants={{
          open: { opacity: 1, y: 0, transition: { duration: 0.1 } },
          closed: { opacity: 1, y: '-100%' },
        }}
        className='modal-container w-[800px] flex flex-col justify-between items-center h-auto bg-white shadow-lg rounded-[2px]'
        onClick={(e: any) => disableOverlayClick(e)}>
        {/* Modal Header  */}
        <div className='modal-Header flex w-full items-center justify-between pl-6 py-3 pr-2 border-b-[1px] border-Gray-200'>
          <div className='header-title flex flex-col justify-center items-start'>
            <span className='text-[18px] font-Medium text-Gray-700'>
              {headerTitle}
            </span>
          </div>

          <Button
            icon={<i className='ri-close-line text-heading-4' />}
            isIconOnly
            onClick={(e: any) => {
              e.stopPropagation()
              e.preventDefault()
              onHeaderCloseButtonClick && onHeaderCloseButtonClick()
            }}
            textTheme='black'
            theme='link'
          />
        </div>
        {/* Modal body */}
        <div className='flex max-h-[80vh] min-h-[35px] h-full w-full overflow-y-auto justify-center mx-6 mt-5'>
          {isLoadState && <PageLoader size='xxs' />}
          {docList?.length > 0 ? (
            <SimpleSlider
              sliderData={sliderData}
              contentClassName=' flex h-[65vh]'
              setSelectedSlideIndex={setSelectedSlideIndex}
            />
          ) : (
            <div className='flex w-full justify-center items-center p-20'>
              {!isLoadState && `No Attachments.`}
            </div>
          )}
        </div>

        {/* Modal footer */}
        <div
          className={CN(
            'modal-footer flex w-full py-3 px-4 items-center justify-end border-t-[1px] border-Gray-200 bg-Gray-50 rounded-b-[2px]',
            {
              'justify-between': isViewHistoryButton,
              'justify-end': !isViewHistoryButton,
            },
          )}>
          {isViewHistoryButton && (
            <Button
              textTheme='black'
              theme='white'
              onClick={() => {
                onClickViewHistoryButton && onClickViewHistoryButton()
              }}>
              View History
            </Button>
          )}

          <div className='flex'>
            {/*footer primary button*/}
            <Button
              className='mr-2 w-[101px]'
              isDisabled={docList.length <= 0}
              onClick={() => {
                saveFile()
              }}
              target='_blank'
              download>
              Download
            </Button>

            {/*footer secondary button*/}
            <Button
              className='w-[72px]'
              textTheme='black'
              theme='white'
              onClick={() => {
                onClickCloseBtn && onClickCloseBtn()
              }}>
              Close
            </Button>
          </div>
        </div>
      </motion.div>
    </div>
  )
}

CandidateDocumentsViewModal.defaultProps = {
  isViewHistoryButton: false,
}

export default CandidateDocumentsViewModal
