import { Button, Toast } from '@labourhub/labour-hub-ds'
import { useWebRefSendReminder } from 'framework/api/methods'
import moment from 'moment'

import { Modal, ModalFooter } from 'components/molecules'
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter'

type SendWebRefCheckReminderModalProps = {
  isActive?: boolean
  setIsActive: (value: boolean) => void
  [x: string]: any
}

export const SendWebRefCheckReminderModal = ({
  referee,
  isActive,
  refereeIndex,
  setIsActive,
  getCandidateRefereesList,
}: SendWebRefCheckReminderModalProps) => {
  const { id, firstName, lastName, requestSendDate, lastSendRequest } =
    referee || {}

  const {
    mutate: webRefSendReminderMutate,
    isLoading: webRefSendReminderIsLoading,
  } = useWebRefSendReminder()

  /** web ref send Reminder */
  async function webRefSendReminder() {
    webRefSendReminderMutate(id, {
      onSuccess: () => {
        Toast({
          alertBody:
            'Web ref check reminder successfully sent to referee’s inbox',
          status: 'Success',
        })
        setIsActive(false)
        getCandidateRefereesList()
      },
      onError: ({ response: { data: errData } }: any) => {
        Toast({
          alertHeader: 'Error...!',
          alertBody: errData?.message
            ? errData?.message
            : 'Error requesting web ref check reminder!',
          status: 'Error',
        })
      },
    })
  }

  return (
    <Modal
      subHeaderTitle={`Referee ${refereeIndex} - ${
        capitalizeFirstLetter(firstName) + ' ' + capitalizeFirstLetter(lastName)
      }`}
      headerTitle='Send Web Ref Check Reminder'
      size='md'
      isFooterShow={true}
      isActive={isActive}
      onHeaderCloseButtonClick={() => {
        setIsActive(false)
      }}
      onOverlayClick={() => {
        setIsActive(false)
      }}
      footerButtonAlignment='right'
      primaryButtonTitle='Send Reminder'
      secondaryButtonTitle='Cancel'>
      <div className='flex flex-col p-6 w-full'>
        <div className='flex flex-col w-full mb-6'>
          <div className='flex text-small text-Gray-500'>
            <span className=' mb-3 w-1/3'>Request sent on:</span>

            <span className='font-SemiBold w-2/3'>
              {requestSendDate
                ? moment(requestSendDate).format('DD/MM/yyyy')
                : 'Never'}
            </span>
          </div>

          <div className='flex text-small text-Gray-500'>
            <span className='w-1/3'>Reminder last sent on:</span>

            <span className='font-SemiBold w-2/3'>
              {lastSendRequest
                ? moment(lastSendRequest).format('DD/MM/yyyy')
                : 'Never'}
            </span>
          </div>
        </div>

        <span className='text-small text-Gray-800'>
          by clicking on Send Reminder, you will send a new reminder to
          referee’s inbox.
        </span>
      </div>

      <ModalFooter>
        <div className='flex justify-end flex-1'>
          <Button
            onClick={() => {
              webRefSendReminder()
            }}
            isLoading={webRefSendReminderIsLoading}
            className='mr-2'
            theme='cobalt'>
            Send Reminder
          </Button>

          <Button
            onClick={() => {
              setIsActive(false)
            }}
            textTheme='blue'
            theme='white'>
            Cancel
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}
