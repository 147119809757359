import CN from 'classnames'

type failIconProps = {
  className?: string | undefined
  isCustomStyles?: boolean
}

export const FailIcon = ({ className, isCustomStyles }: failIconProps) => {
  /** button main styles*/
  const FailIconClassName = !isCustomStyles
    ? CN(
        'flex justify-center items-center w-12 h-12 rounded-full bg-Red-300 border-[6px] border-Red-100 hover:bg-Red-400 hover:border-Red-200',
        className,
      )
    : className

  return (
    <div className={FailIconClassName}>
      <i className='ri-close-line text-white text-[22px]'></i>
    </div>
  )
}
