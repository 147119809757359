/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { guideStepperComponents } from 'features/onboarding/data/guideStepperComponents'
import {
  getOnboardingStore,
  setCurrentStep,
  setGuideStepperSubTitle,
  setGuideStepperTitle,
  setShowGuideModal,
} from 'features/onboarding/store'
import { motion } from 'framer-motion'

import { Column } from 'components/atoms/Column'
import { RowWithAlignment } from 'components/atoms/Row'
import { cn } from 'utils'

import { GuideStepper } from './GuideStepper/GuideStepper'

import './OnboardingViewModal.scss'

export const OnboardingViewModal = () => {
  const dispatch = useDispatch()
  const {
    currentStep,
    guideStepperTitle,
    guideStepperSubTitle,
    showGuideModal,
  } = useSelector(getOnboardingStore)

  /** prevent modal hide when click inside modal */
  const disableOverlayClick = (event: any) => {
    event.stopPropagation()
  }

  const stepperData = useMemo(() => {
    const data = guideStepperComponents.find((s) => s.orderNo === currentStep)
    dispatch(setGuideStepperTitle(data?.title))
    if (!data?.hasSubTitle) dispatch(setGuideStepperSubTitle(undefined))
    return data
  }, [currentStep])

  return (
    <div
      className={cn(
        'modal-overlay fixed flex top-0 bottom-0 left-0 right-0 z-50 justify-center items-center h-full bg-black bg-opacity-40 rounded-lg',
        {
          hidden: !showGuideModal,
        },
      )}>
      <motion.div
        animate={showGuideModal ? 'open' : 'closed'}
        variants={{
          open: { opacity: 1, y: 0, transition: { duration: 0.1 } },
          closed: { opacity: 1, y: '-100%' },
        }}
        className='modal-container w-[580px] flex flex-col justify-between items-center bg-white shadow-lg rounded-xl'
        onClick={(e: any) => disableOverlayClick(e)}>
        <Column className='w-full'>
          <RowWithAlignment
            justifyAlignment={guideStepperTitle ? 'between' : 'end'}>
            <Column className='w-full'>
              {guideStepperTitle && (
                <p className='text-heading-4 font-SemiBold text-[#105DA4] mt-5 ml-5'>
                  {guideStepperTitle}
                </p>
              )}
              {stepperData?.hasSubTitle && guideStepperSubTitle && (
                <p className='text-extra-small mt-0.5 text-Gray-500 ml-5'>
                  {guideStepperSubTitle}
                </p>
              )}
            </Column>
            <button
              className={cn('mt-1 focus:outline-none', {
                'mt-3 mr-3': guideStepperTitle,
              })}
              onClick={() => {
                localStorage.setItem('isFirstAttempt', 'false')
                dispatch(setCurrentStep(0))
                dispatch(setShowGuideModal(false))
              }}>
              <i className='px-2 py-3 ri-close-line text-heading-3' />
            </button>
          </RowWithAlignment>
          {guideStepperTitle && (
            <div className='mx-5 my-3 border border-Gray-200' />
          )}

          <div className='max-h-[98vh] overflow-y-auto styled-scroll mb-4'>
            {stepperData && <GuideStepper stepperData={stepperData} />}
          </div>
        </Column>
      </motion.div>
    </div>
  )
}
