/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { Modal, Toast } from '@labourhub/labour-hub-ds'
import { CandidateProfileBasicDetailsType } from 'api/candidates'
import { useSendCustomSurvey } from 'api/candidates/custom-surveys/useSendCustomSurvey'
import {
  QuestionnaireCategoryType,
  useGetQuestionnairesList,
} from 'api/questionnaires'
import { ModalProps } from 'types'

import { PageLoader } from 'components/atoms'

import { BuildNewTemplateModal } from '../BuildNewTemplateCard/BuildNewTemplateModal'

import { ChooseTemplateSearchHeader } from './ChooseTemplateSearchHeader'
import { SurveyRadioList } from './SurveyRadioList'

type ChooseTemplateModalProps = ModalProps & {
  getCandidateById: () => void
  candidateDetails: CandidateProfileBasicDetailsType
}

export const ChooseTemplateModal = ({
  isModalActive,
  setIsModalActive,
  getCandidateById,
  candidateDetails,
}: ChooseTemplateModalProps) => {
  const notify = (props: any) => Toast(props)

  const [searchParams] = useSearchParams()
  const candidateId: string = searchParams.get('candidate_id') || ''

  const [questionnaires, setQuestionnaires] = useState<any[]>([])
  const [filters, setFilters] = useState<any>({
    searchKeyword: undefined,
    status: undefined,
    type: undefined,
  })
  const [selectedSurvey, setSelectedSurvey] = useState<any>(null)
  const [radioKey, setRadioKey] = useState<any>(0)

  const [showBuildTemplateModal, setShowBuildTemplateModal] = useState(false)

  useEffect(() => {
    setFilters({
      searchKeyword: undefined,
      status: undefined,
      type: undefined,
    })
  }, [])

  const { refetch, isLoading, isFetching } = useGetQuestionnairesList(
    {
      take: 1000,
      skip: 0,
      status: filters.status || '',
      category: filters.type || '',
      search: filters.searchKeyword || '',
      surveyType: candidateDetails.isEmployee
        ? QuestionnaireCategoryType.EmployeeSurvey
        : QuestionnaireCategoryType.CandidateSurvey,
      filteredSurveyType: candidateDetails.isEmployee
        ? QuestionnaireCategoryType.EmployeeSurvey
        : QuestionnaireCategoryType.CandidateSurvey,
    },
    (data: any) => {
      setQuestionnaires(
        data.questionnaires?.filter(
          (q) => q.type != 'Library' && q.isActive == true,
        ) || [],
      )
    },
    ({ response: { data: errData } }: any) => {
      notify({
        alertHeader: errData.messageTitle ? errData.messageTitle : '',
        alertBody: errData.message ? errData.message : 'Error!',
        status: 'Error',
      })
    },
  )

  useEffect(() => {
    if (isModalActive) {
      refetch()
      setSelectedSurvey(null)
      setRadioKey(Math.random())
    }
  }, [isModalActive])

  const {
    mutate: sendCustomSurveyMutate,
    isLoading: sendCustomTemplateIsLoading,
  } = useSendCustomSurvey()

  const handleClose = () => {
    setSelectedSurvey(null)
    setFilters({
      searchKeyword: undefined,
      status: undefined,
      type: undefined,
    })
    setIsModalActive(false)
  }

  const handleSend = () => {
    if (!selectedSurvey) {
      Toast({
        alertBody: 'Please select an option',
        status: 'Warning',
      })
    } else {
      sendCustomSurveyMutate(
        {
          candidateId: candidateId,
          requestData: {
            surveyId: selectedSurvey.id,
            templateName: selectedSurvey.name,
          },
        },
        {
          onSuccess: ({ data: successData }: any) => {
            Toast({
              alertBody: successData?.message,
              alertHeader: successData?.messageTitle,
              status: 'Success',
            })
            handleClose()
            getCandidateById()
          },
          onError: ({ response: { data: errData } }: any) => {
            Toast({
              alertHeader: 'Error...!',
              alertBody: errData?.message,
              status: 'Error',
            })
          },
        },
      )
    }
  }

  return (
    <>
      <Modal
        headerTitle={'Choose Survey Template'}
        isFooterShow={true}
        isActive={isModalActive}
        onHeaderCloseButtonClick={handleClose}
        onOverlayClick={handleClose}
        onClickPrimaryBtn={handleSend}
        onClickSecondaryBtn={handleClose}
        primaryButtonProps={{
          style: { marginLeft: '12px' },
          isLoading: sendCustomTemplateIsLoading,
        }}
        secondaryButtonProps={{
          style: { color: '#1F2937' },
        }}
        modalProps={{
          style: { width: '492px' },
        }}
        footerProps={{
          style: {
            flexDirection: 'row-reverse',
          },
        }}
        primaryButtonTitle='Send'
        secondaryButtonTitle='Cancel'>
        {isLoading || (isFetching && <PageLoader size='xxs' />)}
        <div className='w-[453px] p-5 my-5 border rounded-md border-Gray-200 bg-Gray-50'>
          {questionnaires && questionnaires?.length > 0 && (
            <div className='flex w-full p-4 mb-[30px] border rounded bg-Blue-50 text-Blue-800 border-Blue-300'>
              <i className='mr-2 ri-information-fill text-Blue-600 text-base' />
              <div className='font-Regular text-Blue-700 text-base'>
                If you need to create a new template or customize the existing
                one, please{' '}
                <Link
                  to='/questionnaires?tab=2'
                  className='underline font-SemiBold'>
                  Click here
                </Link>
              </div>
            </div>
          )}
          <ChooseTemplateSearchHeader
            filters={filters}
            setFilters={setFilters}
          />

          <SurveyRadioList
            radioKey={radioKey}
            questionnaires={questionnaires}
            setShowBuildTemplateModal={setShowBuildTemplateModal}
            setSelectedSurvey={setSelectedSurvey}
          />
        </div>
      </Modal>
      <BuildNewTemplateModal
        isModalActive={showBuildTemplateModal}
        setIsModalActive={setShowBuildTemplateModal}
        getCandidateById={getCandidateById}
        candidateDetails={candidateDetails}
      />
    </>
  )
}
