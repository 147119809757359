import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

type GenerateExecutiveSummaryRequest = {
  cId: string
  selectedReferee: string[]
}

export const useGenerateExecutiveSummary = () => {
  const mutate = useMutation((request: GenerateExecutiveSummaryRequest) => {
    return postAPI('/AI/VertexAI/Candidate/ExecutiveSummary', { ...request })
  })
  return mutate
}
