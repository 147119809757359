import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface userInviteProps {
  email?: string
  roleId?: string
  displayName?: string
  fullName?: string
  jobTitle?: string
  phoneNumber?: string
}

export const useUserInvite = () => {
  return useMutation(
    ({
      email,
      roleId,
      displayName,
      fullName,
      jobTitle,
      phoneNumber,
    }: userInviteProps) => {
      return postAPI(`/UserProfile`, {
        email,
        roleId,
        displayName,
        fullName,
        jobTitle,
        phoneNumber,
      })
    },
  )
}

export default useUserInvite
