import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

export type SendCustomAssessmentDto = {
  assessmentId?: string
  templateName?: string
  assessmentLibraryId?: string
  isWebCamMandatory?: boolean
  assessmentDuedate?: string
  addonIds?: string[] | null
}
type SendCustomAssessment = {
  candidateIds: Array<string>
  requestData: SendCustomAssessmentDto
}

export const useSendCustomAssessment = () => {
  const mutate = useMutation(
    ({ requestData, candidateIds }: SendCustomAssessment) => {
      const {
        assessmentId,
        templateName,
        assessmentLibraryId,
        isWebCamMandatory,
        assessmentDuedate,
        addonIds,
      } = requestData || {}
      return postAPI(`/assessment/candidates/SendAssessment`, {
        assessmentId,
        templateName,
        assessmentLibraryId,
        isWebCamMandatory,
        assessmentDuedate,
        candidateId: candidateIds,
        assessmentAddOnIds: addonIds,
      })
    },
  )

  return mutate
}
