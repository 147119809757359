/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import emptyNotificationLottie from 'assets/animations/empty-notification.json'
import CN from 'classnames'
import { CandidateTab } from 'features/candidates/data/CandidateTab'
import { Notification } from 'framework/api/methods'
import { useLottie } from 'lottie-react'

import { useOutsideClickIdentify } from 'utils'

import { Loader } from './Loader'
import { NotificationCard } from './NotificationCard'

interface ParsedData {
  CandidateName: string
  Action: string
}

type NotificationPanelProps = {
  [x: string]: any
}

export const NotificationPanel = ({
  className,
  viewMoreNotification,
  fetchingCandidateById,
  list,
  markThisAsRead,
  setIsNotificationPanelOpen,
  markThisAllAsRead,
  totalNotificationCount,
  ...restProps
}: NotificationPanelProps) => {
  const focusedDivRef = useRef(null)
  useOutsideClickIdentify('notification-action-button', focusedDivRef, () => {
    setIsNotificationPanelOpen(false)
  })
  //   const [candidateName, setCandidateName] = useState(null)
  //   const [action, setAction] = useState(null)

  const AnimatedView = () => {
    const options = {
      animationData: emptyNotificationLottie,
      loop: false,
      autoplay: true,
    }

    const { View } = useLottie(options)
    return View
  }

  const navigate = useNavigate()

  const textFieldMainClassName = CN(
    'absolute top-2 right-[-40px] flex flex-col w-[524px] rounded-md z-20 bg-white custom-shadow px-2 max-h-[600px] overflow-y-auto styled-scrollbar',
    className,
  )

  const parseDataString = async (str: string): Promise<ParsedData> => {
    return new Promise((resolve) => {
      const candidateNameMatch = str.match(/CandidateName='([^']*)'/)
      const actionMatch = str.match(/Action='([^']*)'/)
      resolve({
        CandidateName: candidateNameMatch ? candidateNameMatch[1] : '',
        Action: actionMatch ? actionMatch[1] : '',
      })
    })
  }

  const handleClick = async (item: Notification) => {
    const parsedData = await parseDataString(item.meta ?? '')
    const baseLink = item.isEmployee
      ? '/employee-list/employee-profile'
      : '/candidate-list/candidate-profile'
    const commonParams = `candidate_id=${item?.uuid}&candidate_name=${item.candidateName}&notification_type=${item.type}`

    const getLink = (tabType: string, action: string) => {
      return `${baseLink}?${commonParams}&${tabType}=${action ?? ''}`
    }

    const actionAsCandidateTab = parsedData.Action as CandidateTab
    const tabType = [
      CandidateTab.OverviewTab,
      CandidateTab.HistoryTab,
    ].includes(actionAsCandidateTab)
      ? 'centertab'
      : 'tab'
    navigate(getLink(tabType, parsedData.Action), { replace: true })
    setIsNotificationPanelOpen(false)
  }

  return (
    <div className={textFieldMainClassName} {...restProps} ref={focusedDivRef}>
      <div className='flex items-center justify-between px-2 py-5'>
        <p className='text-heading-3 text-Gray-700 font-Medium'>
          Notifications
        </p>
        <p
          onClick={() => {
            markThisAllAsRead()
          }}
          className='cursor-pointer select-none text-extra-small text-Cobalt-600 font-Medium hover:text-Cobalt-500 active:text-Cobalt-700'>
          Mark All As Read
        </p>
      </div>
      {list?.length > 0 ? (
        list?.map((item: any) => (
          <NotificationCard
            key={item.id}
            title={item.title}
            description={item.description}
            type={item.type}
            createdOn={item.createdOn}
            isRead={item.isRead}
            onClick={() => {
              item.isRead === false && markThisAsRead(item.id)
              handleClick(item)
            }}
          />
        ))
      ) : (
        <div className='flex flex-col items-center justify-center w-full my-10'>
          <div className='w-[144px] mb-4'>
            <AnimatedView />
          </div>
          <span className='mb-1 text-Gray-800 font-Medium'>
            No New Notifications
          </span>
          <span className='text-Gray-600 text-small'>
            Please check back later
          </span>
        </div>
      )}
      <div className='flex items-center justify-center w-full pt-2 pb-4'>
        {fetchingCandidateById ? (
          <Loader appearance='Cobalt' />
        ) : (
          totalNotificationCount > 0 && (
            <p
              onClick={() => {
                viewMoreNotification()
              }}
              className='cursor-pointer select-none text-extra-small text-Cobalt-600 font-Medium hover:text-Cobalt-500 active:text-Cobalt-700'>
              View More
            </p>
          )
        )}
      </div>
    </div>
  )
}
