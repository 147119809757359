import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  setGuideStepperTitle,
  setShowGuideStepper,
} from 'features/onboarding/store'

import { EditCompanyProfile } from './EditCompanyProfile/EditCompanyProfile'
import { ViewCompanyProfile } from './ViewCompanyProfile/ViewCompanyProfile'

export const ConfirmBranding = () => {
  const dispatch = useDispatch()

  const [isEditView, setIsEditView] = useState(false)

  useEffect(() => {
    dispatch(
      setGuideStepperTitle(
        isEditView ? 'Edit Company Profile' : 'Confirm Branding',
      ),
    )

    dispatch(setShowGuideStepper(!isEditView))
  }, [isEditView])

  return isEditView ? (
    <EditCompanyProfile setIsEditView={setIsEditView} />
  ) : (
    <ViewCompanyProfile setIsEditView={setIsEditView} />
  )
}
