import ReactQuill from 'react-quill'

// import 'react-quill/dist/quill.snow.css'
import './TextEditor.scss'

type ReadOnlyTextEditorProps = {
  value: string
}

export const ReadOnlyTextEditor = ({ value }: ReadOnlyTextEditorProps) => {
  return (
    <div className='readonly-editor'>
      <ReactQuill
        readOnly={true}
        className='p-0 m-0'
        theme='snow'
        defaultValue={value}
        value={value}
        modules={{
          toolbar: null,
        }}
      />
    </div>
  )
}
