import {
  Badge,
  Button,
  SimpleSelect,
  TextField,
} from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { difficultyList } from 'features/questionnaires/data/assessmentDifficultyLevels'
import { AssessmentQuestion } from 'features/questionnaires/types'

import { timeToAESTWithSeconds } from 'utils'

type AssessmentBuilderHeaderSectionProps = {
  onPreviewClick?: () => void
  onSaveClick?: any
  onPublishClick?: any
  showlastUpdatedBy?: boolean
  questionCardItems: AssessmentQuestion[]
  [x: string]: any
}

export const AssessmentBuilderHeaderSection = ({
  className,
  formData,
  editableFields,
  lastUpdatedBy,
  onPreviewClick,
  onPublishClick,
  onSaveClick,
  setFormData,
  questionCardItems,
  isSaveBtnEnabled,
  isDraft,
  isLoading,
  assessmentId,
  showlastUpdatedBy = true,
  ...restProps
}: AssessmentBuilderHeaderSectionProps) => {
  const assessmentBuilderHeaderSectionClasses = CN(
    `questionnaire-builder-header-section flex flex-col w-full bg-white rounded-lg border border-Gray-200 p-5 mb-5 gap-y-2`,
    className,
  )
  const hasNullOrEmptyCorrectAnswer = (
    questions: AssessmentQuestion[],
  ): boolean => {
    return questions
      .filter(
        (question) =>
          question.type !== 'heading' && question.type !== 'paragraph',
      ) // Skip questions of type 'heading' and 'paragraph'
      .some((question) => {
        const correctAnswer = question.correctAnswer
        return (
          correctAnswer === null ||
          correctAnswer === '' ||
          correctAnswer === undefined
        )
      })
  }
  const handleKeyDown = (event) => {
    if (
      event.key === 'Backspace' ||
      event.key === 'ArrowLeft' ||
      event.key === 'ArrowRight' ||
      event.key === 'Delete'
    ) {
      return
    }
    if (event.key < '0' || event.key > '9') {
      event.preventDefault()
    }
  }

  return (
    <div className={assessmentBuilderHeaderSectionClasses} {...restProps}>
      <div className='flex flex-row w-full'>
        <div className='flex items-end justify-start w-7/12 mr-5 gap-x-5'>
          <TextField
            className='w-2/3 name'
            isRequired
            value={formData?.name}
            onChange={(e: any) => {
              setFormData({
                ...formData,
                name: e.target.value,
              })
            }}
            label='Assessment Name'
            placeholder='Designer'
          />
          <TextField
            className='w-2/3 name'
            isRequired
            value={formData?.duration}
            onChange={(e: any) => {
              setFormData({
                ...formData,
                duration: e.target.value,
              })
            }}
            onKeyDown={handleKeyDown}
            label='Assessment Duration (In minutes)'
            placeholder='15'
          />
          <SimpleSelect
            className='w-2/3 name'
            label='Difficulty Level'
            placeholder='Select Difficulty'
            value={difficultyList?.filter(
              (item: any) => item?.value === formData.difficultyLevel,
            )}
            onChange={(selectedItem: any) => {
              setFormData({
                ...formData,
                difficultyLevel: selectedItem.value,
              })
            }}
            options={difficultyList}
            isRequired
          />
        </div>

        <div className='flex items-end justify-end w-5/12'>
          <Button
            className='w-1/3 h-10'
            textTheme='blue'
            iconRight={<i className='ri-external-link-line' />}
            theme='white'
            isDisabled={assessmentId === undefined || assessmentId === null}
            onClick={() => {
              onPreviewClick && onPreviewClick()
            }}>
            Preview
          </Button>

          <Button
            className='w-1/3 h-10 ml-2'
            textTheme='blue'
            isDisabled={
              !formData.name ||
              !formData.duration ||
              formData.duration == 0 ||
              questionCardItems.length == 0 ||
              formData.difficultyLevel == null ||
              formData.difficultyLevel == '' ||
              isLoading ||
              hasNullOrEmptyCorrectAnswer(questionCardItems) ||
              (isSaveBtnEnabled &&
                editableFields?.name === formData?.name &&
                editableFields?.duration === formData?.duration &&
                editableFields.category === formData?.category)
            }
            iconRight={<i className='ri-save-line' />}
            theme='white'
            onClick={() => {
              onSaveClick && onSaveClick()
            }}>
            Save
          </Button>
          <Button
            className='w-1/3 h-10 ml-2'
            textTheme='white'
            iconRight={<i className='ri-share-forward-box-line' />}
            theme='cobalt'
            isDisabled={
              !formData.name ||
              formData.duration == 0 ||
              questionCardItems.length == 0 ||
              formData.difficultyLevel == null ||
              formData.difficultyLevel == '' ||
              hasNullOrEmptyCorrectAnswer(questionCardItems) ||
              !formData.duration ||
              isLoading
            }
            onClick={() => {
              onPublishClick && onPublishClick()
            }}>
            Publish
          </Button>
        </div>
      </div>
      {lastUpdatedBy && (
        <div className='flex items-center gap-x-2'>
          <span className='text-Gray-500 font-Regular text-[12px]'>
            {`Last edited on: ${timeToAESTWithSeconds(lastUpdatedBy)}`}
          </span>
          {showlastUpdatedBy && (
            <Badge size='small' theme={!isDraft ? 'red' : 'green'} type='basic'>
              {!isDraft ? 'Draft' : 'Published'}
            </Badge>
          )}
        </div>
      )}
    </div>
  )
}
