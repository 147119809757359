import { useState } from 'react'
import { useMutation } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { AxiosError, AxiosResponse } from 'axios'
import { postAPIwithFormData } from 'framework/api/http'

export const useImportCandidateFromCSV = (
  csvFile: File | undefined,
  isEmployee: boolean,
  moveStepper: () => void,
) => {
  const [progress, setProgress] = useState(0)
  const [sucessfulRecordCount, setSucessfulRecordCount] = useState<number>()
  const [errorMsg, setErrorMsg] = useState<string[]>()
  const [warningMsg, setWarningMsg] = useState<string[]>()
  const { mutate, isLoading, isSuccess } = useMutation(
    () => {
      const bodyFormData = new FormData()
      if (csvFile) {
        bodyFormData.append('CSVFile', csvFile)
        bodyFormData.append('IsEmployee', isEmployee.toString())
      }
      return postAPIwithFormData(`/Candidate/CSVImport`, bodyFormData, {
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 50
          setProgress(progress)
        },
        onDownloadProgress: (progressEvent) => {
          const progress =
            50 + (progressEvent.loaded / progressEvent.total) * 50
          setProgress(progress)
        },
      })
    },
    {
      onSuccess: (response: AxiosResponse) => {
        setSucessfulRecordCount(response?.data?.checkRecordsCount)
        moveStepper()
        setErrorMsg(undefined)
      },
      onError: (e: AxiosError) => {
        const { errors, warnings, checkRecordsCount } = e.response?.data || {}

        if (errors?.length) {
          setErrorMsg(errors)
        } else if (warnings?.length) {
          setWarningMsg(warnings)
        }

        if ((errors?.length || warnings?.length) && checkRecordsCount !== 0) {
          setSucessfulRecordCount(checkRecordsCount)
        }

        if (e?.response?.status === 400 && checkRecordsCount === 0) {
          setErrorMsg(['Failed to parse csv file'])
          Toast({
            alertHeader: 'Error',
            alertBody: 'Failed to parse csv file',
            status: 'Error',
          })
        }

        moveStepper()
      },
    },
  )

  return {
    progress,
    mutate,
    isLoading,
    isSuccess,
    sucessfulRecordCount,
    errorMsg,
    warningMsg,
  }
}
