import { ReactNode } from 'react'
import Select from 'react-select'
import CN from 'classnames'
import { SelectOptionDto } from 'types'

export type CustomSelectOption = {
  label: string
  value: string
}

interface CustomSelectProps {
  className?: string | undefined
  defaultValue?: any | undefined
  description?: string | undefined
  helperText?: ReactNode | string | undefined
  isCustomStyles?: boolean
  isDisabled?: boolean
  isErrored?: boolean
  isRequired?: boolean
  label?: string | undefined
  onBlur?: (e: any) => void | undefined
  onChange?: (e: any) => void | undefined
  options?: SelectOptionDto[]
  placeholder?: string | undefined
  value?: any | undefined
  [x: string]: any
}

export const CustomSelect = ({
  className,
  defaultValue,
  description,
  helperText,
  isCustomStyles,
  isErrored,
  isDisabled,
  isRequired,
  label,
  onBlur,
  onChange,
  options,
  placeholder,
  value,
  ...restProps
}: CustomSelectProps) => {
  /** simple select main class name */
  const simpleSelectMainClassName = !isCustomStyles
    ? CN('main-container flex flex-col', className)
    : className

  /** helper text style */
  const helperTextClassName = CN('text-small font-Regular pt-1', {
    'text-Gray-500': !isErrored,
    'text-Red-600': isErrored,
  })

  /** select input class name */
  const inputSelectStyles = {
    menu: (provided: any) => ({
      ...provided,
      zIndex: 9999,
      position: 'absolute',
      top: 'auto',
      bottom: '100%',
    }),
    control: (provided: any) => ({
      ...provided,
      height: 40,
      borderRadius: 6,
      color: 'red',
      border: isErrored && '1px solid red',
    }),
    option: (styles: any, { isSelected }: any) => {
      return {
        ...styles,
        color: isSelected ? 'black' : 'black',
      }
    },
  }

  return (
    <div className={simpleSelectMainClassName} {...restProps}>
      {/* label */}
      {label !== undefined && (
        <div className='pb-1 label-container text-small'>
          <div className='flex label text-Gray-800 font-Medium'>
            {/* label text */}
            <span>{label}</span>

            {/* required */}
            {isRequired && <span className='text-Red-500 pl-[2px]'>*</span>}
          </div>

          {/* description */}
          {description && (
            <span className='pt-1 text-Gray-500 font-Regular'>
              ({description})
            </span>
          )}
        </div>
      )}

      {/* select fields */}
      <Select
        styles={inputSelectStyles}
        options={options}
        className='sd-classes-select'
        placeholder={placeholder}
        theme={(theme: any) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            text: '#6B7280',
            primary25: '#F3F4F6', // option Hover color
            neutral80: '#1F2937', // typing Inside the select
            neutral20: '#D1D5DB', // Outline color
            primary75: '#1F2937',
            primary: '#F3F4F6',
            neutral90: '#F3F4F6',
          },
        })}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        defaultValue={defaultValue}
        isDisabled={isDisabled}
        {...restProps}
      />

      {/* helper text */}
      {helperText !== undefined && (
        <span className={helperTextClassName}>{helperText}</span>
      )}
    </div>
  )
}

CustomSelect.defaultProps = {
  className: undefined,
  defaultValue: undefined,
  description: undefined,
  helperText: undefined,
  isCustomStyles: false,
  isDisabled: false,
  isErrored: false,
  isRequired: false,
  label: undefined,
  onBlur: undefined,
  onChange: undefined,
  options: undefined,
  placeholder: 'select...',
  value: undefined,
}
