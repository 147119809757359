import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Toast } from '@labourhub/labour-hub-ds'
import { ReportAssessmentStatsCountDto } from 'api/assessment/getAssessmentCountByCandidate'
import addon from 'assets/images/addon.svg'
import { difficultyImages } from 'features/candidates/data/difficultyLevelImages'
import { getReportSettings } from 'features/settings'
import { useGetAgencyDetails } from 'framework/api/methods'

import { Column } from 'components/atoms/Column'
import { Row } from 'components/atoms/Row'
import { envConfig } from 'config/framework.config'

import { ReportDetailColumns } from '../ReportDetailColumns'

import { TotalAssessmentsSection } from './TotalAssessmentsSection'

type CombineReportCandidateDetailsProps = {
  details: CombineReportCandidateDetailsDto
  stats: ReportAssessmentStatsCountDto
  data?: any
}

export type CombineReportCandidateDetailsDto = {
  name: string
  candidateNo: string
  email: string
  phoneNumber: string
  avatarUrl?: string
  jobTitle?: string
  consultantName: string
}

export const CombineReportCandidateDetails = ({
  details,
  stats,
  data,
}: CombineReportCandidateDetailsProps) => {
  const notify = (props: any) => Toast(props)
  const { textColour } = useSelector(getReportSettings)
  const [agencyProfileDetails, setAgencyProfileDetails] = useState<any>({})

  /** Get agency profile details on success */
  const getAgencyDetailsOnSuccess = ({ data: successData }: any) => {
    setAgencyProfileDetails(successData?.agencyProfile)
  }

  /** Get agency profile details on error */
  const getAgencyDetailsOnError = ({ response: { data: errData } }: any) => {
    notify({
      alertHeader: errData.messageTitle || '',
      alertBody: errData.message || 'Error!',
      status: 'Error',
    })
  }

  /** API call for getting agency profile details */
  const { refetch: getAgencyProfileDetailsRefetch } = useGetAgencyDetails(
    {},
    getAgencyDetailsOnSuccess,
    getAgencyDetailsOnError,
  )

  /** Hide candidate details for specific agency */
  const reportCandidateDetailHideId =
    envConfig?.reportCandidateDetailHideTenantId

  useEffect(() => {
    getAgencyProfileDetailsRefetch()
  }, [])

  return (
    <Column className='px-6 py-4 mt-4'>
      <Row className='w-full h-auto space-x-4'>
        {details.avatarUrl && (
          <img
            src={details.avatarUrl}
            alt=''
            className='object-fill rounded-full w-[62px] h-[62px] overflow-hidden'
          />
        )}
        <div className='max-w-[650px]'>
          {data && (
            <div
              className='text-small font-Bold mb-5'
              style={{ color: textColour }}>
              {data?.isAddon === false ? (
                <div className='flex flex-row'>
                  {`MAIN ASSESSMENT - ${data?.assessmentName.toUpperCase()}`}
                  <img
                    className='w-[20px] h-[10px] mt-1 ml-2'
                    src={difficultyImages[data?.difficultyLevel]}
                    alt='main assessment'
                  />
                </div>
              ) : (
                <div className='flex flex-row'>
                  {data?.assessmentName === 'Typing Test' ? (
                    <>
                      {`${data?.assessmentName.toUpperCase()} (LOWERCASE ONLY) - ADD-ON FOR ${data?.mainAssessmentName.toUpperCase()}`}
                      <img className='w-[16px] ml-1' src={addon} alt='addon' />
                    </>
                  ) : (
                    <>
                      {`${data?.assessmentName.toUpperCase()} - ADD-ON FOR ${data?.mainAssessmentName.toUpperCase()}`}
                      <img className='w-[16px] ml-1' src={addon} alt='addon' />
                    </>
                  )}
                </div>
              )}
            </div>
          )}
          <p className='text-small text-Gray-500 font-Bold'>
            ASSESSMENT REPORT
          </p>
          <p
            className='leading-6 uppercase text-heading-3 font-Bold'
            style={{ color: textColour }}>
            {details.name}
          </p>
          {details.jobTitle && (
            <p className='text-base font-Medium text-Gray-600'>
              Applying for role of {details.jobTitle}
            </p>
          )}
        </div>
      </Row>

      <div className='py-4 mt-5'>
        <TotalAssessmentsSection stats={stats} />
      </div>

      {agencyProfileDetails?.id?.toLowerCase() !==
        reportCandidateDetailHideId?.toLowerCase() && (
        <div className='py-4 mt-5'>
          <ReportDetailColumns
            title='Candidate Details'
            leftDetails={[
              { label: 'ID No', value: details.candidateNo, type: 'text' },
              { label: 'Phone', value: details.phoneNumber, type: 'link' },
            ]}
            rightDetails={[
              { label: 'Email', value: details.email, type: 'link' },
              {
                label: 'Managing Consultant',
                value: details.consultantName,
                type: 'text',
              },
            ]}
          />
        </div>
      )}
    </Column>
  )
}
