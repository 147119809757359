import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

interface UpdateUserProfileDetailsInterface {
  obj?: any
  method?: any
}

export const useUpdateUserProfileDetails = () => {
  const mutate = useMutation(
    ({ obj, method }: UpdateUserProfileDetailsInterface) => {
      let reqBody = {}

      switch (method) {
        case 'fullName':
          reqBody = {
            firstName: obj.firstName,
            lastName: obj.lastName,
          }
          break

        case 'displayName':
          reqBody = {
            displayName: obj.displayName,
          }
          break

        case 'jobTitle':
          reqBody = {
            jobTitle: obj.jobTitle,
          }
          break

        case 'dob':
          reqBody = {
            dob: obj.dob || null,
          }
          break

        case 'gender':
          reqBody = {
            gender: obj.gender,
          }
          break

        case 'email':
          reqBody = {
            email: obj.email,
          }
          break

        case 'phoneNumber':
          reqBody = {
            phoneNumber: obj.phoneNumber,
          }
          break

        case 'alternateEmail':
          reqBody = {
            alternateEmail: obj.alternateEmail,
          }
          break

        case 'emailSignature':
          reqBody = {
            emailSignature: obj.emailSignature,
          }
          break

        default:
          reqBody = {}
          break
      }
      return putAPI(`/UserProfile/${obj.id}`, {
        ...reqBody,
      })
    },
  )

  return mutate
}

export default useUpdateUserProfileDetails
