import React from 'react'
import { MutateOptions } from 'react-query'
import { Modal } from '@labourhub/labour-hub-ds'

type DeleteRefereeModalProps = {
  isOpenRefereeDelete: boolean
  deleteCandidateReferee: (
    variables: void,
    options?:
      | MutateOptions<
          {
            status: number
            statusText: string
            data: any
          },
          unknown,
          void,
          unknown
        >
      | undefined,
  ) => Promise<{
    status: number
    statusText: string
    data: any
  }>
  setIsOpenRefereeDelete: (value: React.SetStateAction<boolean>) => void
  getCandidateRefereesList: () => void
  getCandidateById: any
  [x: string]: any
}

const DeleteRefereeModal = ({
  isOpenRefereeDelete,
  deleteCandidateReferee,
  setIsOpenRefereeDelete,
  getCandidateRefereesList,
  getCandidateById,
}: DeleteRefereeModalProps) => {
  return (
    <Modal
      isActive={isOpenRefereeDelete}
      onClickPrimaryBtn={() => {
        deleteCandidateReferee().finally(() => {
          setIsOpenRefereeDelete(false)
          getCandidateRefereesList()
          getCandidateById()
        })
      }}
      onClickSecondaryBtn={() => {
        setIsOpenRefereeDelete(false)
      }}
      primaryButtonProps={{
        style: { width: '100%', marginRight: '12px' },
      }}
      secondaryButtonProps={{
        style: { width: '100%', color: '#1F2937' },
      }}
      onOverlayClick={() => {
        setIsOpenRefereeDelete(false)
      }}
      modalProps={{
        style: { width: '466px' },
      }}
      footerProps={{
        style: {
          justifyContent: 'space-between',
          paddingLeft: '23px',
          paddingRight: '23px',
        },
      }}
      isHeaderShow={false}
      primaryButtonTitle='Yes, I’m Sure'
      secondaryButtonTitle='Close'>
      <div className='flex flex-col items-center justify-center p-6'>
        <div className='flex items-center justify-center w-12 h-12 rounded-full bg-Red-100'>
          <i className='ri-delete-bin-6-line text-Red-500 text-heading-3'></i>
        </div>

        <span className='pt-5 text-Gray-900 text-heading-5 font-Medium'>
          Delete Referee?
        </span>

        <span className='pt-2 text-center text-Gray-500 text-small font-Regular'>
          Are you sure you want to delete referee?
        </span>
      </div>
    </Modal>
  )
}

export default DeleteRefereeModal
