import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface useActivateDeactivateUserProps {
  type?: string
  userId?: string
}

export const useActivateDeactivateUser = () => {
  const mutate = useMutation(
    ({ userId, type }: useActivateDeactivateUserProps) => {
      let apiRoute = ``

      type === 'DEACTIVE'
        ? (apiRoute = `UserProfile/${userId}/Activate`)
        : (apiRoute = `UserProfile/${userId}/Deactive`)

      return postAPI(apiRoute, {})
    },
  )

  return mutate
}

export default useActivateDeactivateUser
