export const defaultCompetencies = [
  'Ability to Learn',
  'Attendance, Punctuality, and Reliability',
  'Attention to Detail',
  'Computer Literacy',
  'Conduct and Behaviour',
  'Creativity',
  'Customer Service',
  'Decision Making',
  'Effective Communication',
  'Health and Safety',
  'Honesty and Integrity',
  'Innovation',
  'Leadership',
  'Problem Solving',
  'Strategic Thinking',
  'Team Collaboration',
  'Time Management',
]
