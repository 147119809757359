import * as React from 'react'
import { useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Provider } from 'react-redux'
import { ToastContainer } from '@labourhub/labour-hub-ds'

import { CookieDetectionAlert } from 'components/ui/CookieDetectionAlert'

import App from './App'
import reportWebVitals from './reportWebVitals'
import store from './store'

import './styles/index.scss'
import 'remixicon/fonts/remixicon.css'

const queryClient = new QueryClient()

const container = document.getElementById('root')
const root = createRoot(container!)

function AppWithErrorBoundary() {
  const [cookiesEnabled, setCookiesEnabled] = useState<boolean | null>(null)
  const [cookieSettingsUrl, setCookieSettingsUrl] = useState('')

  useEffect(() => {
    const testCookieName = 'testCookie'
    document.cookie = `${testCookieName}=test;`

    // Check if the cookie was set
    const cookiesEnabled = document.cookie.indexOf(testCookieName) !== -1

    // Clean up by removing the test cookie
    document.cookie = `${testCookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT`

    // Update state to reflect whether cookies are enabled
    setCookiesEnabled(cookiesEnabled)

    // Determine the appropriate link based on the user's browser
    const userAgent = navigator.userAgent.toLowerCase()
    let detectedLink = ''
    if (userAgent.includes('chrome')) {
      detectedLink =
        'https://support.google.com/accounts/answer/61416?hl=en&co=GENIE.Platform%3DDesktop'
    } else if (userAgent.includes('firefox')) {
      detectedLink =
        'https://support.mozilla.org/en-US/kb/websites-say-cookies-are-blocked-unblock-them#w_check-cookie-settings'
    } else if (userAgent.includes('safari')) {
      detectedLink =
        'https://help.apple.com/safari/mac/8.0/en.lproj/sfri11471.html'
    } else if (userAgent.includes('edge')) {
      detectedLink =
        'https://support.microsoft.com/en-au/office/enable-cookies-6b018d22-1d24-43d9-8543-3d35ddb2cb52'
    }
    setCookieSettingsUrl(detectedLink)

    // Cleanup
    return () => {
      setCookiesEnabled(null)
      setCookieSettingsUrl('')
    }
  }, [])

  const memoizedLink = React.useMemo(
    () => cookieSettingsUrl,
    [cookieSettingsUrl],
  )

  return (
    <React.StrictMode>
      {cookiesEnabled == true ? (
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <App />
            <ToastContainer className='w-full max-w-[420px] min-w-[350px]' />
            <ReactQueryDevtools />
          </QueryClientProvider>
        </Provider>
      ) : cookiesEnabled == false ? (
        <CookieDetectionAlert link={memoizedLink} />
      ) : (
        <></>
      )}
    </React.StrictMode>
  )
}

root.render(<AppWithErrorBoundary />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
