import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

export type senSCustomAssessmentReminderDto = {
  id: string
  surveyLibraryId?: string
  surveyId?: string
  templateName?: string
}
type SendCustomAssessmentReminder = {
  assessmentId: string
  cId: string
  responseId: string
}

export const useSendCustomAssessmentReminder = () => {
  const mutate = useMutation(
    ({ assessmentId, cId, responseId }: SendCustomAssessmentReminder) => {
      return postAPI(
        `/assessment/${assessmentId}/candidate/${cId}/assessmentResponse/${responseId}/Reminder`,
        {},
      )
    },
  )

  return mutate
}
