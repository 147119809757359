import { Modal, ToggleDetailSection } from '@labourhub/labour-hub-ds'
import { format, parse } from 'date-fns'
import { ModalProps } from 'types'

import { CalendarInput } from 'components/atoms'

import { AssessmentData } from './CustomAssessmentCardItem'

type SendAssessmentConfirmationModelProps = ModalProps & {
  setSendAssessmentData: React.Dispatch<React.SetStateAction<AssessmentData>>
  sendAssessmentData: AssessmentData
  handleSend: () => void
  sendCustomTemplateIsLoading: boolean
  totalCredits: number
}

const SendAssessmentConfirmationModel = ({
  isModalActive,
  setIsModalActive,
  setSendAssessmentData,
  sendAssessmentData,
  handleSend,
  sendCustomTemplateIsLoading,
  totalCredits,
}: SendAssessmentConfirmationModelProps) => {
  const handleClose = () => {
    setIsModalActive(false)
    resetInputFields()
  }

  const resetInputFields = () => {
    setSendAssessmentData({
      isMandatoryWebcam: false,
      assessmentDate: '',
      addonIds: null,
    })
  }

  return (
    <>
      <Modal
        isActive={isModalActive}
        onOverlayClick={handleClose}
        modalProps={{
          style: { width: '450px' },
        }}
        className='!z-[200]'
        onClickPrimaryBtn={handleSend}
        onClickSecondaryBtn={handleClose}
        onHeaderCloseButtonClick={handleClose}
        primaryButtonProps={{
          style: { marginLeft: '12px' },
          isLoading: sendCustomTemplateIsLoading,
          isDisabled: !sendAssessmentData.assessmentDate,
        }}
        secondaryButtonProps={{ textTheme: 'black' }}
        footerProps={{
          style: {
            justifyContent: 'flex-start',
            flexDirection: 'row-reverse',
          },
        }}
        headerTitle='Send Assessment'
        primaryButtonTitle='Confirm & send'
        secondaryButtonTitle='Cancel'>
        <div className='px-8'>
          <div className='space-y-4'>
            <div className='py-2  px-4 mt-6 mb-3 text-small font-Regular border-2 text-Gray-800 rounded-[4px]  bg-Blue-50 border-Blue-300'>
              You will use {totalCredits}{' '}
              {totalCredits > 1 ? ' Credits' : 'Credit'} to request{' '}
              {totalCredits > 1 ? 'these assessments' : 'this assessment'}.
            </div>
            <div>
              <ToggleDetailSection
                clasName='mt-0'
                id='send-assessment'
                label='Enable Mandatory Webcam'
                description='This will require the candidate to activate their webcam during the assessment.'
                value={sendAssessmentData.isMandatoryWebcam}
                onClick={(e: any) =>
                  setSendAssessmentData({
                    ...sendAssessmentData,
                    isMandatoryWebcam: e.target.checked,
                  })
                }
              />
            </div>

            <div className='flex flex-col border-t-2 border-Gray-200 py-4'>
              <span className='flex text-Gray-800 font-Medium text-small pb-3'>
                Please select a due date for the assessment:
              </span>
              <CalendarInput
                className='assessment-due-date'
                placeHolder='DD/MM/YYYY'
                defaultValue={sendAssessmentData.assessmentDate || ''}
                value={sendAssessmentData.assessmentDate}
                formatDate={(date) => format(date, 'dd/MM/yyyy')}
                parseDate={(str) => parse(str, 'dd/MM/yyyy', new Date())}
                onChange={(date) => {
                  if (date) {
                    setSendAssessmentData({
                      ...sendAssessmentData,
                      assessmentDate: date,
                    })
                  }
                }}
                minDate={new Date()}
              />
              <span className='flex text-Gray-800 text-small pb-1 pt-1'>
                Candidates will be required to submit their responses by this
                date.
              </span>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default SendAssessmentConfirmationModel
