import { useState } from 'react'
import { Button } from '@labourhub/labour-hub-ds'
import { ModalProps } from 'types'

import { Modal, ModalFooter } from 'components/molecules'
import PersonalityTestResult from 'components/molecules/AddonPreviewModal/PersonalityTestResultReport/PersonalityTestResult'
import { AssessmentDownloadModal } from 'components/molecules/AssesmentPreviewModal/DownloadReport/AssessmentDownloadModal'

type PersonalityTestAddonPreviewReportProps = ModalProps & {
  candidateDetails: any
  reportViewData: any
  selectedResponse: any
}

export const PersonalityTestAddonPreviewReport = ({
  isModalActive,
  setIsModalActive,
  candidateDetails,
  reportViewData,
  selectedResponse,
}: PersonalityTestAddonPreviewReportProps) => {
  const [
    isOpenPersonalityTestResultModal,
    setIsOpenPersonalityTestResultModal,
  ] = useState(false)

  const markingValue = {
    correct: 0,
    partiallyCorrect: 0,
    incorrect: 0,
    notAnswered: 0,
  }

  const handleCloseModals = () => {
    setIsModalActive(false)
    setIsOpenPersonalityTestResultModal(false)
  }

  return (
    <>
      <Modal
        headerTitle='Big Five Personality Test Report'
        customWidth='1261px'
        isActive={isModalActive}
        isFooterShow={false}
        size='lg'
        onClickSecondaryBtn={() => setIsModalActive(false)}
        onHeaderCloseButtonClick={() => setIsModalActive(false)}
        onOverlayClick={() => setIsModalActive(false)}
        secondaryButtonTitle='Close'>
        <PersonalityTestResult reportViewData={reportViewData} />
        <ModalFooter>
          <div className='flex justify-end flex-1'>
            <Button
              onClick={() => setIsModalActive(false)}
              textTheme='blue'
              theme='white'>
              Cancel
            </Button>
            <Button
              onClick={() => setIsOpenPersonalityTestResultModal(true)}
              // isLoading={questionsUpdateLoading}
              // isDisabled={questionsUpdateLoading}
              className='ml-2'
              theme='cobalt'>
              Download
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      {/* {isOpenPersonalityTestResultModal && (
        <PersonalityTestResultModal
          isModalActive={isOpenPersonalityTestResultModal}
          setIsModalActive={setIsOpenPersonalityTestResultModal}
          reportViewData={reportViewData}
          candidateDetails={candidateDetails}
          handleCloseModals={handleCloseModals}
        />
      )} */}
      {isOpenPersonalityTestResultModal && (
        <AssessmentDownloadModal
          markingValue={markingValue}
          reportViewData={reportViewData}
          isModalActive={isOpenPersonalityTestResultModal}
          setIsModalActive={setIsOpenPersonalityTestResultModal}
          handleCloseModals={handleCloseModals}
          candidateDetails={candidateDetails}
          isLoading={false}
          selectedResponse={selectedResponse}
        />
      )}
    </>
  )
}
