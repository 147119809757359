import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

import { PromotionDto } from '../types'

export const useGetFixedPromotionByCode = (
  isEnabled: boolean,
  payload: {
    promoCode: string
    packageType: string
  },
  onSuccess: (promotion: PromotionDto) => void,
  onError: () => void,
) => {
  return useQuery(
    ['promotions_fixed', payload],
    async (): Promise<PromotionDto> => {
      const res = await get(
        `/promotions/Fixed/${payload.promoCode}/Package/${payload.packageType}`,
      )
      return res.data.promotion
    },
    {
      enabled: isEnabled && payload.promoCode != '',
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess,
      onError,
    },
  )
}
