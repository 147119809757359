/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@labourhub/labour-hub-ds'
import bfBanner from 'assets/images/blackfriday/banner.jpg'
import enterprise from 'assets/images/subscription/enterprice-icon.svg'
import payg from 'assets/images/subscription/payg-icon.svg'
import CN from 'classnames'
import { setPayAsYouGoCreditsModalActive } from 'features/payments'
import { FixedSubscriptionPackageDto } from 'features/subscriptions/types'
import { ModalProps } from 'types'

import { HelpCenterModal, Modal, ModalFooter } from 'components/molecules'
import { isBlackFriday } from 'utils/offers'

import { getSubscriptionStore } from '../../store'
import { LegacyPromotion } from '../LegacyPromotion'
import { RequestEnterprisePlanModal } from '../RequestEnterprisePlanModal'

import { PlanPackageCard } from './PlanPackageCard'

type FixedSubscriptionPackagesModalProps = ModalProps

export const FixedSubscriptionPackagesModal = ({
  isModalActive,
  setIsModalActive,
}: FixedSubscriptionPackagesModalProps) => {
  const dispatch = useDispatch()

  const {
    packages: { fixedMonthlyPackages, fixedYearlyPackages },
  } = useSelector(getSubscriptionStore)

  const [isActiveHelpCenterModal, setIsActiveHelpCenterModal] = useState(false)

  const [isEnterpriseDrawerActive, setIsEnterpriseDrawerActive] =
    useState(false)

  const [currentSubscriptionPackages, setCurrentSubscriptionPackages] =
    useState<FixedSubscriptionPackageDto[]>(fixedMonthlyPackages)

  const [isYearlyPlan, setIsYearlyPlan] = useState(false)

  useEffect(() => {
    isYearlyPlan
      ? setCurrentSubscriptionPackages(fixedYearlyPackages)
      : setCurrentSubscriptionPackages(fixedMonthlyPackages)
  }, [isYearlyPlan, fixedMonthlyPackages, fixedYearlyPackages])

  return (
    <>
      <Modal
        customWidth='90vw'
        modalProps={{ style: { width: '80vw', height: '750px' } }}
        isActive={isModalActive}
        onHeaderCloseButtonClick={() => {
          setIsModalActive(false)
        }}
        isFooterShow={true}
        isHeaderShow={false}
        onOverlayClick={() => {
          setIsModalActive(false)
        }}>
        <div className='bg-Gray-50 flex flex-col h-[100vh] w-full py-6 px-[50px] overflow-y-auto styled-scroll justify-start items-center'>
          <div className='flex items-end justify-end w-full'>
            <Button
              className='w-[20px] h-[20px] flex justify-center items-center'
              size='sm'
              isIconOnly
              textTheme='black'
              theme='link'
              onClick={() => {
                setIsModalActive(false)
              }}
              icon={
                <i className='cursor-pointer ri-close-fill text-Gray-800 text-[16px] leading-5'></i>
              }
            />
          </div>

          {isBlackFriday() && (
            <img src={bfBanner} alt='' className='mt-5 mb-10' />
          )}

          <span className='text-[28px] leading-normal font-SemiBold text-black'>
            Select package
          </span>

          <span className='text-heading-5 leading-normal text-[#757575]'>
            You can upgrade or downgrade anytime
          </span>

          <div className='flex gap-x-[9px] justify-center items-center mx-[11px] mt-3'>
            <div className='flex justify-center items-center px-4 py-2 bg-[#F3F4F6] border border-Gray-200 rounded-md'>
              <div
                className={CN(
                  'px-[17px] py-[9px] rounded-md text-[#003A4F] text-small font-Medium cursor-pointer',
                  {
                    'bg-white text shadow-sm !text-[#105DA4]': !isYearlyPlan,
                  },
                )}
                onClick={() => {
                  setIsYearlyPlan(false)
                }}>
                Monthly
              </div>

              <div
                className={CN(
                  'px-[17px] py-[9px] rounded-md text-[#003A4F] text-small font-Medium cursor-pointer',
                  {
                    'bg-white text shadow-sm !text-[#105DA4]': isYearlyPlan,
                  },
                )}
                onClick={() => {
                  setIsYearlyPlan(true)
                }}>
                {isBlackFriday()
                  ? 'Upgrade to Yearly (40% Discount)'
                  : 'Yearly (15% Discount)'}
              </div>
            </div>
          </div>
          <div className='w-full my-3'>
            <LegacyPromotion />
          </div>
          {currentSubscriptionPackages &&
          currentSubscriptionPackages?.length != 0 ? (
            <div
              className={CN('flex gap-x-[34px] w-full justify-between', {
                'mt-8': isBlackFriday(),
                'mt-4': !isBlackFriday(),
              })}>
              {currentSubscriptionPackages?.map((subscriptionPackage) => (
                <PlanPackageCard
                  key={subscriptionPackage.id}
                  subscriptionPackage={subscriptionPackage}
                  setIsFixedSubscriptionPackagesModalActive={setIsModalActive}
                  isYearlyPlan={isYearlyPlan}
                />
              ))}
            </div>
          ) : (
            <>Packages not found</>
          )}

          <div className='w-full flex mt-[16px] gap-x-[9px]'>
            <div className='w-1/2 flex py-[15px] px-8 border border-Gray-200 rounded-lg bg-white'>
              <div className='flex flex-col mr-[58px]'>
                <div className='text-heading-4 font-SemiBold'>Enterprise</div>

                <img
                  src={enterprise}
                  alt={'enterprise'}
                  className={CN('object-fill h-[46px] mt-[18px]')}
                />
              </div>

              <div className='flex flex-col pt-[7px]'>
                <div className='text-heading-5 font-Regular'>
                  For packs over 50
                </div>

                <Button
                  size='sm'
                  className='mt-3 w-fit'
                  theme='cobalt'
                  textTheme='white'
                  onClick={() => {
                    setIsEnterpriseDrawerActive(true)
                  }}>
                  Contact us
                </Button>
              </div>
            </div>

            <div className='w-1/2 flex py-[14px] px-8 border border-Gray-200 rounded-lg bg-white'>
              <div className='flex flex-col mr-[58px]'>
                <div className='text-heading-4 font-SemiBold'>PAYG</div>

                <img
                  src={payg}
                  alt={'PAYG'}
                  className={CN('object-fill h-[46px] mt-[18px]')}
                />
              </div>

              <div className='flex flex-col pt-[7px]'>
                <div className='text-heading-5 font-Regular'>
                  Pay as you go credits
                </div>

                <Button
                  size='sm'
                  className='mt-3 w-fit'
                  theme='cobalt'
                  textTheme='white'
                  onClick={() => {
                    dispatch(setPayAsYouGoCreditsModalActive(true))
                  }}>
                  Buy Now
                </Button>
              </div>
            </div>
          </div>
        </div>

        <ModalFooter>
          <div className='flex items-center justify-between w-full'>
            <div className='flex divide-x-2 divide-[#D1D5DB] font-SemiBold text-Gray-500'>
              {/* <span className='pr-[10px]'>Free Trials</span> */}
              <span className='px-[10px]'>Cancel Anytime</span>
              <div className='pl-[10px] flex'>
                <div className='pr-[10px]'>No Contracts</div>
                <i
                  className='ri-share-box-fill text-[16px] text-[#105DA4] cursor-pointer'
                  onClick={() => {
                    setIsActiveHelpCenterModal(true)
                  }}
                />
              </div>
            </div>

            <div className='flex gap-x-[10px]'>
              <div className='flex items-center justify-center text-Gray-500'>
                <i className='ri-phone-line text-[24px]' />
                <span className='ml-1 font-SemiBold'>0396569750</span>
              </div>

              <div className='flex items-center justify-center text-Gray-500'>
                <i className='ri-mail-line text-[24px]' />
                <span className='ml-1 font-SemiBold'>info@refhub.com.au</span>
              </div>

              {/* <Button
                onClick={() => {
                  window.open('https://www.refhub.com.au/book-a-demo')
                }}
                size='sm'
                textTheme='black'
                theme='white'>
                Book A Demo
              </Button> */}
            </div>
          </div>
        </ModalFooter>
      </Modal>

      <RequestEnterprisePlanModal
        isModalActive={isEnterpriseDrawerActive}
        setIsModalActive={setIsEnterpriseDrawerActive}
      />

      <HelpCenterModal
        isActive={isActiveHelpCenterModal}
        setIsActive={setIsActiveHelpCenterModal}
      />
    </>
  )
}
