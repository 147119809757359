/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useMemo, useRef, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Button, Toast } from '@labourhub/labour-hub-ds'
import { useGetAgencySettings } from 'api'
import { useGetAssessmentsList } from 'api/assessment'
import { useGetQuestionnairesList } from 'api/questionnaires'
import { ReportSettingItemKey } from 'api/settings/report'
import { useGetAllReportSettings } from 'api/settings/report/getAllReportSettings'
import {
  useGetActiveSubscriptions,
  useGetSubscriptionPackagesList,
} from 'api/subscriptions'
import {
  TemplateVariablesDto,
  useGetTemplateVariablesList,
} from 'api/templates'
import xmasBanner from 'assets/images/xmas/xmas-banner.png'
import CN from 'classnames'
import { setAgency } from 'features/agencies/store/actions'
import { SessionTimeoutModal } from 'features/misc'
import { OnboardingViewModal } from 'features/onboarding'
import { CreditAlertModal } from 'features/payments/components/CreditAlertModal'
import { ZeroCreditAlertModal } from 'features/payments/components/ZeroCreditAlertModal'
import {
  setTotalAssessments,
  setTotalQuestionnaires,
} from 'features/questionnaires/store/actions'
import {
  INITIAL_REPORT_SETTINGS,
  ReportSettingsForm,
  setAgencyAppSettings,
  setAssessmentReportSettings,
  setReportSettings,
} from 'features/settings'
import {
  setSubscriptionPackages,
  setSubscriptions,
} from 'features/subscriptions'
import {
  useGetAgencyDetails,
  useGetHeaderBarDetails,
  useUserDetails,
} from 'framework/api/methods'
import { useBrowserDetection, useIdleTimeout } from 'hooks'
import moment from 'moment'
import { setDateRange } from 'store/reducers/dashboard/dashboardSlice'
import { setTemplateVariables } from 'store/reducers/global/globalSlice'
import { setLayoutClickValue } from 'store/reducers/layout/layoutSlice'
import { setIsExpanded } from 'store/reducers/side-menu/mainSideMenuReducer'
import {
  setHeaderBarDetails,
  setIsLoading,
  setUserDetails,
} from 'store/reducers/user/userSlice'

import { CalendarRangePicker } from 'components/atoms/CalendarRangePicker'
import { Breadcrumb } from 'components/common/Breadcrumb'
import { BrowserBlockedPage } from 'components/common/BrowserBlockedPage'
import { Header } from 'components/common/Header'
import { MobileBlockedOverlay } from 'components/common/MobileBlockedOverlay'
import { MainSideBar } from 'components/common/Sidebar'
import { cn, isChristmas, useWidth } from 'utils'
import { QLD_CLIENT } from 'utils/QLD-Client'
import useAgencySubdomainValidator from 'utils/useAgencySubdomainValidator'

import { VideoBackground } from './VideoBackground'

import './styles.scss'

type MainLayoutProps = {
  [x: string]: any
}

export const MainLayout = ({
  hasSidebar,
  heading,
  children,
  isBackButton,
  isShowRangePicker,
}: MainLayoutProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const notify = (props: any) => Toast(props)
  function Fallback({ error, resetErrorBoundary }) {
    if (error) {
      notify({
        alertHeader: 'Error',
        alertBody: 'Something went wrong. Please try again later.',
        status: 'Error',
      })
    }
    resetErrorBoundary()
    return (
      <div role='alert'>
        <pre style={{ color: 'red' }}>
          Something went wrong. Please try again later.
        </pre>
      </div>
    )
  }

  const { isBreadCrumbVisible } = useSelector((state: any) => state.breadcrumbs)
  const { backFunction } = useSelector((state: any) => state.layout)
  const { isHeaderBarDetailsUpdated }: any = useSelector<any>(
    (state) => state.user,
  )

  const [isCreditAlertModalActive, setIsCreditAlertModalActive] = useState(
    localStorage.getItem('isCreditLow') === 'true',
  )

  const [isZeroCreditAlertModalActive, setIsZeroCreditAlertModalActive] =
    useState(localStorage.getItem('isZeroCreditLow') === 'true')

  const [isSessionTimeoutModalOpen, setIsSessionTimeoutModalOpen] =
    useState<boolean>(false)

  useIdleTimeout(50 * 60 * 5000, () => setIsSessionTimeoutModalOpen(true))

  const [header, setHeader] = useState(heading)

  const myRef = useRef<HTMLDivElement>(null)

  const LocalStorageToken = localStorage.getItem('token')
  const sessionStorageToken = sessionStorage.getItem('token')
  const token = LocalStorageToken || sessionStorageToken

  const { dateRange } = useSelector((state: any) => state.dashboard)
  // /** Process the Login submission */
  const tokenDataOnSuccess = (data: any) => {
    dispatch(setUserDetails(data.profile))
  }

  const tokenDataOnError = () => {
    localStorage.removeItem('token')
    navigate('/auth/login')
  }

  const {
    data: userData,
    refetch,
    isLoading: userIsLoading,
  } = useUserDetails({}, tokenDataOnSuccess, tokenDataOnError)

  useEffect(() => {
    dispatch(setIsLoading(userIsLoading))

    if (
      userData?.profile?.roleName === 'Consultant' &&
      (location.pathname === '/agency-profile' ||
        location.pathname === '/user-groups' ||
        location.pathname === '/user-groups/user-list' ||
        location.pathname === '/settings' ||
        location.pathname.includes('/super-admin'))
    ) {
      navigate('/')
    }

    if (
      userData?.profile?.roleName === 'Administrator' &&
      location.pathname.includes('/super-admin')
    ) {
      navigate('/')
    }
  }, [userIsLoading, location])

  // fetch toolbar details
  const { refetch: getHeaderBarDetails } = useGetHeaderBarDetails(
    (data: any) => {
      dispatch(setHeaderBarDetails(data.toolBarDetails))
    },
  )

  // fetch active subscriptions
  const { refetch: refetchSubscriptions } = useGetActiveSubscriptions(
    (subscriptions) => {
      dispatch(setSubscriptions(subscriptions))
    },
  )

  useGetSubscriptionPackagesList((packages) => {
    dispatch(setSubscriptionPackages(packages))
  })

  // fetch agency settings
  const { refetch: refetchSettings } = useGetAgencySettings((appSettings) => {
    dispatch(setAgencyAppSettings(appSettings))
  })

  /** Get agency profile details on success */
  const getAgencyDetailsOnSuccess = ({ data: successData }: any) => {
    dispatch(setAgency(successData?.agencyProfile))
  }

  /** Get agency profile details on error */
  const getAgencyDetailsOnError = () => {
    //
  }

  /** API call for getting agency profile details */
  const { refetch: getAgencyProfileDetailsRefetch } = useGetAgencyDetails(
    {},
    getAgencyDetailsOnSuccess,
    getAgencyDetailsOnError,
  )

  // get report settings
  useGetAllReportSettings((data) => {
    const mapSettingsToForm = (settings) => {
      const form: ReportSettingsForm = { ...INITIAL_REPORT_SETTINGS }

      settings?.forEach((setting) => {
        switch (setting.id) {
          case ReportSettingItemKey.BackgroundImage:
            form.backgroundImage = {
              isChecked: setting.isChecked,
              value: setting.value,
            }
            break
          case ReportSettingItemKey.CompanyLogo:
            form.companyLogo = {
              isChecked: setting.isChecked,
              value: setting.value,
            }
            break
          case ReportSettingItemKey.TextColour:
            form.textColour =
              setting.isChecked && setting.value ? setting.value : '#085DA8'
            break
          case ReportSettingItemKey.LineColour:
            form.lineColour =
              setting.isChecked && setting.value ? setting.value : '#1E75C6'
            break
          case ReportSettingItemKey.PrivateReportsEmailPhone:
            form.privateReportsEmailPhone = setting.isChecked
            break
          case ReportSettingItemKey.PrivateReportsName:
            form.privateReportsName = setting.isChecked
            break
          default:
            break
        }
      })

      return form
    }

    const reportSettingsForm = mapSettingsToForm(data?.referenceReportSettings)
    const assessmentReportSettingsForm = mapSettingsToForm(
      data?.assessmentReportSettings,
    )

    dispatch(setReportSettings(reportSettingsForm))
    dispatch(setAssessmentReportSettings(assessmentReportSettingsForm))
  })

  /** questionnaires List on success */
  const onSuccessQuestionnairesList = (data: any) => {
    dispatch(setTotalQuestionnaires(data.totalCount))
  }

  /** questionnaires List on error */
  const onErrorQuestionnairesList = () => {
    //
  }

  /** Assessment List on success */
  const onSuccessAssessmentList = (data: any) => {
    dispatch(setTotalAssessments(data.totalCount))
  }

  /** API Handling request for Get All questionnaires List */
  useGetQuestionnairesList(
    {
      take: 0,
      skip: 0,
      status: '',
      search: '',
      category: '',
      sortColumnName: '',
      sortDirection: '',
      surveyType: '',
      filteredSurveyType: '',
    },
    onSuccessQuestionnairesList,
    onErrorQuestionnairesList,
  )

  useGetAssessmentsList(
    {
      take: 20,
      skip: 0,
      status: '',
      category: '',
      search: '',
      sortColumnName: '',
      sortDirection: '',
    },
    onSuccessAssessmentList,
    () => {},
  )

  useEffect(() => {
    if (!token) {
      navigate('/auth/login')
    }
  }, [token])

  useEffect(() => {
    token && refetch()
  }, [token])

  const { refetch: getTemplateVariablesListRefetch } =
    useGetTemplateVariablesList(
      {
        take: 20,
        skip: 0,
      },
      (templateVariables: TemplateVariablesDto[]) => {
        dispatch(setTemplateVariables(templateVariables))
      },
    )

  useEffect(() => {
    token && getHeaderBarDetails()
    token && refetchSubscriptions()
    token && refetchSettings()
    token && getAgencyProfileDetailsRefetch()
    token && getTemplateVariablesListRefetch()
  }, [token, isHeaderBarDetailsUpdated])

  const ref = useRef(null)

  const [width] = useWidth(ref)

  const browserInfo = useBrowserDetection()

  const isBrowserNotAllowed = browserInfo.isFirefox

  const [isMobileUsing, setIsMobileUsing] = useState(false)

  useEffect(() => {
    if (width && width < 840) {
      setIsMobileUsing(true)
    }

    if (width && width > 840) {
      setIsMobileUsing(false)
    }
  }, [width])

  useEffect(() => {
    // Function to scroll to the element
    const scrollToMyRef = () => {
      if (myRef.current) {
        myRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }

    // Scroll to the element on route change
    scrollToMyRef()
  }, [location.pathname])
  const check_QLD_Subdomains = useAgencySubdomainValidator({
    subdomainList: QLD_CLIENT.subdomainList,
  })

  const getUserRole = searchParams.get('user_role')

  const userRole =
    getUserRole === QLD_CLIENT.previousTitle && check_QLD_Subdomains
      ? QLD_CLIENT.newTitle
      : getUserRole

  //   const userRole = searchParams.get('user_role')

  useEffect(() => {
    if (userRole != null) {
      setHeader(userRole + 's')
    } else {
      setHeader(heading)
    }
  }, [userRole, heading])

  const renderLayoutInner = () => {
    return (
      <div className={CN('flex flex-col h-full relative px-8 py-5', {})}>
        {heading && (
          <div className='flex items-start justify-between'>
            <div className={CN('flex w-full items-left flex-col', {})}>
              {isBreadCrumbVisible && (
                <Breadcrumb
                  check_QLD_Subdomains={check_QLD_Subdomains}
                  userRole={userRole}
                />
              )}
              <p className='mt-1 font-Medium text-heading-4 text-Gray-800'>
                {header}
              </p>
            </div>

            {isBackButton && (
              <div className='flex items-center justify-end w-full'>
                <Button
                  iconLeft={<i className='ri-arrow-left-line text-[18px]' />}
                  className='w-auto card-edit-button'
                  onClick={backFunction}
                  textTheme='blue'
                  theme='white'>
                  {isBackButton}
                </Button>
              </div>
            )}
            {isShowRangePicker && (
              <div className='flex items-center justify-end w-full'>
                <div className='flex flex-col items-start justify-center'>
                  <span className='mb-1 text-Gray-800 font-Medium text-small'>
                    Filter by Date
                  </span>
                  <CalendarRangePicker
                    defaultValue={[
                      new Date(dateRange[0]),
                      new Date(dateRange[1]),
                    ]}
                    onChange={(selectedRange) => {
                      if (
                        selectedRange[0] !== null &&
                        selectedRange[1] !== null
                      ) {
                        dispatch(
                          setDateRange([
                            moment(selectedRange[0])
                              .format('YYYY-MM-DD')
                              .toString(),
                            moment(selectedRange[1])
                              .format('YYYY-MM-DD')
                              .toString(),
                          ]),
                        )
                      }
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        )}

        <div
          className={CN('main-layout__inner flex flex-col h-full w-full mt-6')}>
          <div className='pb-[50px]'>{children}</div>
        </div>
      </div>
    )
  }

  const wrapperClassName = useMemo(() => {
    const isGuideStepper = searchParams.get('builderType') === 'guideStepper'
    if (isGuideStepper) {
      dispatch(setIsExpanded(false))
      return 'opacity-50 pointer-events-none'
    } else {
      return undefined
    }
  }, [searchParams])

  const renderLayout = () => {
    // Render with sidebar
    if (hasSidebar) {
      return (
        <>
          <MainSideBar
            className='top-[0px] left-0'
            wrapperClassName={wrapperClassName}
          />
          <div className='flex flex-col w-full h-full overflow-y-auto styled-scroll'>
            <div ref={myRef} />
            {isChristmas() && (
              <img src={xmasBanner} alt='' className='z-10 w-full' />
            )}
            {/* <button
              onClick={() => {
                dispatch(setCurrentStep(1))
                dispatch(setShowGuideModal(true))
              }}
              className='bg-Red-500'>
              Open{' '}
            </button> */}

            {renderLayoutInner()}
          </div>
        </>
      )
    }

    // Render without sidebar
    return <div className='container'>{renderLayoutInner()}</div>
  }

  return (
    <>
      <ErrorBoundary FallbackComponent={Fallback}>
        <Header
          className={cn('fixed left-0 top-0 h-[56px]', wrapperClassName)}
        />
        {isMobileUsing && <MobileBlockedOverlay mailto='info@refhub.com.au' />}
        {isBrowserNotAllowed && (
          <BrowserBlockedPage mailto='info@refhub.com.au' />
        )}

        <div
          ref={ref}
          onClick={() => {
            dispatch(setLayoutClickValue())
          }}
          className={CN(
            'main-layout__container fixed flex top-[56px] w-full h-[calc(100%-56px)] bg-Gray-100',
          )}>
          {isChristmas() && <VideoBackground />}
          {renderLayout()}
        </div>

        <OnboardingViewModal />

        <CreditAlertModal
          isModalActive={isCreditAlertModalActive}
          setIsModalActive={setIsCreditAlertModalActive}
        />

        <ZeroCreditAlertModal
          isModalActive={isZeroCreditAlertModalActive}
          setIsModalActive={setIsZeroCreditAlertModalActive}
        />

        <SessionTimeoutModal
          isModalActive={isSessionTimeoutModalOpen}
          setIsModalActive={setIsSessionTimeoutModalOpen}
        />
      </ErrorBoundary>
    </>
  )
}
