import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface UseResetPasswordInterface {
  username: string
  resetToken: string
  password: string
}

export const useResetPassword = () => {
  const mutate = useMutation(
    ({ username, resetToken, password }: UseResetPasswordInterface) => {
      return postAPI('/identity/ResetPassword', {
        username: username,
        resetToken: resetToken,
        password: password,
      })
    },
  )

  return mutate
}

export default useResetPassword
