import { FC, ReactNode, useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import CN from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'

import { RowWithAlignment } from '../Row'

type CustomAccordionProps = {
  className?: string | undefined
  children?: ReactNode | string | number | undefined
  heading: string
  description?: string
  isCustomStyles?: boolean
  childrenWrapperClassName?: string | undefined
  initialState?: boolean
  onClick?: () => void
  HeaderComponent?: React.ReactElement
  itemCenter?: boolean
  id?: any
  [x: string]: any
}

export const CustomAccordion: FC<CustomAccordionProps> = ({
  children,
  className,
  heading,
  description,
  childrenWrapperClassName,
  isCustomStyles,
  initialState,
  headerComponent,
  itemCenter = false,
  id,
  ...restProps
}: CustomAccordionProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const isOpenQuery = searchParams.get(`accordion${id}`) === 'true'
  const [isOpen, setIsOpen] = useState(isOpenQuery || initialState)

  useEffect(() => {
    setIsOpen(searchParams.get(`accordion${id}`) === 'true')
  }, [location, id])

  const handleOnclick = () => {
    if (isOpen) {
      searchParams.delete(`accordion${id}`)
    } else {
      searchParams.set(`accordion${id}`, 'true')
    }
    navigate(
      {
        pathname: location.pathname,
        search: searchParams.toString(),
      },
      { replace: true },
    )
    setIsOpen(!isOpen)
  }

  const AccordionMainClass = !isCustomStyles
    ? CN(
        'w-full bg-white py-3 px-5  rounded-lg border border-Gray-200',
        className,
      )
    : className

  return (
    <motion.div className={AccordionMainClass} {...restProps}>
      <motion.div className='flex flex-col items-start justify-between'>
        <RowWithAlignment itemAlignment={`${itemCenter ? 'center' : 'start'}`}>
          {headerComponent ? (
            headerComponent
          ) : (
            <div className='flex flex-col'>
              <span className='text-base text-Gray-800 font-SemiBold'>
                {heading}
              </span>
              <span className='mt-1 text-small text-Gray-500 font-Regular'>
                {description}
              </span>
            </div>
          )}

          <motion.div
            // animate={isOpen ? { rotateZ: -180 } : { rotateZ: 0 }}
            className={`${itemCenter ? 'item-center' : 'items-start'}`}
            initial={false}
            onClick={async () => await handleOnclick()}>
            {isOpen ? (
              <i className='ri-arrow-up-s-line text-[25px] leading-none text-Cobalt-600 cursor-pointer' />
            ) : (
              <i className='ri-arrow-down-s-line text-[25px] leading-none text-Cobalt-600 cursor-pointer' />
            )}
          </motion.div>
        </RowWithAlignment>
      </motion.div>

      <AnimatePresence initial={false}>
        {isOpen && (
          /** Drop Down Children */
          <motion.div
            className={CN('flex w-full', childrenWrapperClassName)}
            initial='collapsed'
            animate='open'
            exit='collapsed'
            transition={{ type: 'tween', duration: 0.3 }}
            variants={{
              open: { opacity: 1, y: 0, height: 'auto' },
              collapsed: { opacity: -1, y: -35, height: 0 },
            }}>
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  )
}

export default CustomAccordion

CustomAccordion.defaultProps = {
  children: undefined,
  heading: undefined,
  initialState: false,
  onClick: undefined,
  className: undefined,
  isCustomStyles: false,
  childrenWrapperClassName: undefined,
}
