import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Toast, ToggleDetailSection } from '@labourhub/labour-hub-ds'
import { useCreateCandidate } from 'api/candidates'
import { setIsRefreshCandidateTable } from 'features/candidates'
import {
  getOnboardingStore,
  setCurrentStep,
  setGuideStepperCandidate,
  setShowGuideModal,
} from 'features/onboarding/store'
import {
  AppSettingKey,
  getAgencyAppSettings,
  getSettingValue,
  isSettingEnabled,
  updateSettingByKey,
} from 'features/settings'
import { useRequestReferee } from 'framework/api/methods'
import { ReferenceCheckType } from 'types'

import { ReferenceTypeLabel } from 'components/atoms'
import { ColumnWithAlignment } from 'components/atoms/Column'
import { RowWithAlignment } from 'components/atoms/Row'
import { ProfileAvatar } from 'components/ui/ProfileAvatar'
import { getInitials } from 'utils'

export const InitiateReferenceCheck = () => {
  const dispatch = useDispatch()

  const notify = (props: any) => Toast(props)

  const agencySettings = useSelector(getAgencyAppSettings)
  const [candidateDetails, setCandidateDetails] = useState<any>(null)

  const { currentStep, guideStepperCandidate, guideStepperPreviewTemplate } =
    useSelector(getOnboardingStore)

  useEffect(() => {
    if (!guideStepperCandidate) dispatch(setCurrentStep(currentStep + 1))
  }, [guideStepperCandidate])

  const isAgencyAutoReferencingEnabled = useMemo(
    () => isSettingEnabled(AppSettingKey.AutoReferencing, agencySettings),
    [agencySettings],
  )

  useEffect(() => {
    setCandidateDetails({
      ...guideStepperCandidate,
      candidateSettings: [
        {
          key: AppSettingKey.AutoReferencing,
          value:
            isAgencyAutoReferencingEnabled && guideStepperPreviewTemplate?.id
              ? 'true'
              : 'false',
        },
        {
          key: AppSettingKey.QuestionnaireTemplateId,
          value: guideStepperPreviewTemplate?.id
            ? guideStepperPreviewTemplate?.id
            : 'Blank',
        },
        {
          key: AppSettingKey.SmsFeature,
          value: 'true',
        },
      ],
    })
  }, [
    guideStepperCandidate,
    guideStepperPreviewTemplate,
    isAgencyAutoReferencingEnabled,
  ])

  const isAutoReferencingDisabled = useMemo(
    () =>
      getSettingValue(AppSettingKey.AutoReferencing, agencySettings) ===
        'false' ||
      (candidateDetails?.candidateSettings &&
        getSettingValue(
          AppSettingKey.QuestionnaireTemplateId,
          candidateDetails?.candidateSettings,
        ) == 'Blank'),
    [agencySettings, candidateDetails],
  )

  const { mutate, isLoading } = useRequestReferee()
  const { mutate: createCandidateMutate, isLoading: isSaving } =
    useCreateCandidate()

  /** request referees */
  const onClickInitiateReference = () => {
    const isAutoRefEnabled = isSettingEnabled(
      AppSettingKey.AutoReferencing,
      candidateDetails?.candidateSettings,
    )

    createCandidateMutate(
      {
        candidateDetails,
        referenceTypes: Array.from(
          {
            length: candidateDetails?.noOfReferences || 1,
          },
          () => {
            return {
              type: ReferenceCheckType.Employment,
              note: '',
              specificEmployer: '',
            }
          },
        ),
      },
      {
        onSuccess: ({ data }) => {
          if (data?.candidate?.id) {
            if (!isAutoRefEnabled) {
              mutate(data.candidate.id, {
                onSuccess: () => {
                  dispatch(
                    setGuideStepperCandidate({
                      ...candidateDetails,
                      id: data.candidate.id,
                    }),
                  )
                  dispatch(setCurrentStep(currentStep + 1))
                  dispatch(setIsRefreshCandidateTable())
                  Toast({
                    alertBody:
                      'Candidate created and reference request successfully sent to candidate.',
                    status: 'Success',
                  })
                },
                onError: ({ response: { data: errData } }: any) => {
                  Toast({
                    alertHeader: 'Error...!',
                    alertBody: errData?.message
                      ? errData?.message
                      : 'Error requesting referees!',
                    status: 'Error',
                  })
                },
              })
            } else {
              dispatch(
                setGuideStepperCandidate({
                  ...candidateDetails,
                  id: data.candidate.id,
                }),
              )
              dispatch(setCurrentStep(currentStep + 1))
              dispatch(setIsRefreshCandidateTable())
              notify({
                alertHeader: data?.message,
                alertBody: '',
                status: 'Success',
              })
            }
          }
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: errData?.messageTitle
              ? errData?.messageTitle
              : 'Error!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  const handleToggle = (
    key: AppSettingKey,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const candidateSettings = updateSettingByKey(
      key,
      candidateDetails.candidateSettings,
      {
        value: `${e.target.checked}`,
      },
    )

    setCandidateDetails({ ...candidateDetails, candidateSettings })
  }

  return candidateDetails ? (
    <div className='w-full'>
      <ColumnWithAlignment className='my-6' justifyAlignment='center'>
        <div className='w-[450px] bg-[#DCEDFB] rounded-t-xl px-5 pb-4 pt-8'>
          <ColumnWithAlignment justifyAlignment='center' itemAlignment='center'>
            <ProfileAvatar
              bgColor='bg-TerraCotta-500'
              imageText={getInitials(
                candidateDetails.firstName,
                candidateDetails.lastName,
              )}
            />
            <span className='font-Medium text-[#2E2E2E] my-1 text-base'>
              {`${candidateDetails.firstName} ${candidateDetails.lastName}`}
            </span>
            <span className='text-[#2E2E2E] text-small font-Regular'>
              {candidateDetails.email}
            </span>
          </ColumnWithAlignment>
        </div>

        <div className='w-[450px] border border-[#DCEDFB] rounded-b-xl p-5 '>
          <RowWithAlignment justifyAlignment='end' itemAlignment='end'>
            <table className='w-full mx-4 space-y-4'>
              <tr className='flex items-center w-full'>
                <td className='text-Gray-500 text-small w-[100px] flex items-center justify-start'>
                  Survey Name:
                </td>
                <td className='flex items-center justify-start w-[300px] break-words text-small font-Medium text-Gray-800'>
                  {guideStepperPreviewTemplate
                    ? guideStepperPreviewTemplate?.name
                    : 'NA'}{' '}
                </td>
              </tr>

              <tr className='flex items-center w-full'>
                <td className='text-Gray-500 text-small w-[100px] flex items-center justify-start'>
                  Referee Type:
                </td>
                <td className='flex items-center justify-start w-[300px] break-words text-small font-Medium text-Gray-800'>
                  <ReferenceTypeLabel type={ReferenceCheckType.Employment} />
                </td>
              </tr>

              {/* <tr className='flex items-center w-full'>
                <td className='text-Gray-500 text-small w-[100px] flex items-center justify-start'>
                  Sent on:
                </td>
                <td className='flex items-center justify-start w-[300px] break-words text-small font-Medium text-Gray-800'>
                  {candidateDetails.requestSendDate
                    ? timeToAESTWithFormat(
                        candidateDetails.requestSendDate,
                        'DD/MM/YYYY',
                      )
                    : 'Never'}
                </td>
              </tr> */}

              <tr className='flex items-center w-full'>
                <td className='text-Gray-500 text-small w-[100px] flex items-center justify-start'>
                  Credit Usage:
                </td>
                <td className='flex items-center justify-start w-[300px] break-words text-small font-Medium text-Gray-800'>
                  1 out of 3
                </td>
              </tr>
            </table>
          </RowWithAlignment>
        </div>

        <div className='w-[450px] border border-[#DCEDFB] rounded-xl p-2 pt-1 pb-4 mt-4'>
          <ToggleDetailSection
            id='auto-referencing-feature'
            label='Turn on Auto-referencing'
            description='Once candidates are added, the system promptly requests referee details and automatically sends out reference surveys upon receiving the required information.'
            hasTooltip={!isAgencyAutoReferencingEnabled}
            tooltipText='Auto-referencing setting is disabled.\nPlease contact the administrator to enable it.'
            isDisabled={isAutoReferencingDisabled}
            value={isSettingEnabled(
              AppSettingKey.AutoReferencing,
              candidateDetails?.candidateSettings ?? [],
            )}
            onClick={(e: any) => handleToggle(AppSettingKey.AutoReferencing, e)}
          />
        </div>
      </ColumnWithAlignment>

      <div className='flex flex-col justify-center px-5'>
        <Button
          isLoading={isLoading || isSaving}
          onClick={onClickInitiateReference}
          className='mt-2 cursor-pointer'>
          Initiate reference check
        </Button>
        <Button
          theme='white'
          className='w-full h-[38px] text-Gray-800 mt-4'
          onClick={() => {
            dispatch(setShowGuideModal(false))
            dispatch(setCurrentStep(1))
          }}>
          <span className='ml-2 font-SemiBold text-Gray-800'>Cancel</span>
        </Button>
      </div>
    </div>
  ) : (
    <></>
  )
}
