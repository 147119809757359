import { useState } from 'react'
import { Tabs } from '@labourhub/labour-hub-ds'

import { lazyImport } from 'utils/lazyImport'

const { RefcheckReporting } = lazyImport(
  () => import('./RefcheckReporting'),
  'RefcheckReporting',
)
const { AssessmentReporting } = lazyImport(
  () => import('./AssessmentReporting'),
  'AssessmentReporting',
)

export const Reporting = () => {
  const [openTab, setOpenTab] = useState<any>()
  const tabDetails = [
    {
      id: 1,
      tabButtonContent: 'Ref Check',
      tabContent: <RefcheckReporting />,
    },
    {
      id: 2,
      tabButtonContent: 'Assessment',
      tabContent: <AssessmentReporting />,
    },
  ]

  return (
    <div className='h-auto'>
      <Tabs
        onHeaderBtnClick={() => {
          setOpenTab(undefined)
        }}
        contentClassName='pt-4'
        defaultTabId={1}
        openTabId={openTab}
        tabDetails={tabDetails}
      />
    </div>
  )
}
