import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

import { ActiveSubscriptionDto } from '../../features/subscriptions/types'

const getActiveSubscription = async (): Promise<ActiveSubscriptionDto> => {
  const res = await get(`/Subscriptions/Active`)
  return res.data.subscriptions
}

export const useGetActiveSubscriptions = (
  onSuccess: (subscriptions: ActiveSubscriptionDto) => void,
) => {
  return useQuery(['subscriptions_active'], getActiveSubscription, {
    enabled: true,
    refetchOnWindowFocus: false,
    onSuccess,
    onError: () => {
      Toast({
        alertHeader: 'Error fetching active subscription list',
        status: 'Error',
      })
    },
  })
}
