import { useSearchParams } from 'react-router-dom'
import { CandidateProfileBasicDetailsType } from 'api/candidates'
import { CandidateTab } from 'features/candidates/data/CandidateTab'

import { ColumnWithAlignment } from 'components/atoms/Column'
import { CenteredTabs } from 'components/atoms/Tabs'

import { CandidateAvatarSection } from './CandidateAvatarSection'
import { CandidateMenuSection } from './CandidateMenuSection'
import { HistoryTab } from './HistoryTab'
import { OverviewTab } from './OverviewTab'

type CandidateEmployeeProfileCardProps = {
  candidateDetails: CandidateProfileBasicDetailsType
  getCandidateById: any
  activeTabId: any
  onTabChange: (id: any) => void
}

export const CandidateEmployeeProfileCard = ({
  candidateDetails,
  getCandidateById,
  activeTabId,
  onTabChange,
}: CandidateEmployeeProfileCardProps) => {
  const [searchParams] = useSearchParams()
  const centertab = searchParams.get('centertab')
  const defaultTab = centertab ? centertab : activeTabId
  const tabDetails = [
    {
      id: CandidateTab.OverviewTab,
      tabButtonContent: 'Overview',
      tabContent: (
        <OverviewTab
          candidateDetails={candidateDetails}
          getCandidateById={getCandidateById}
        />
      ),
    },
    {
      id: CandidateTab.HistoryTab,
      tabButtonContent: 'History',
      tabContent: (
        <HistoryTab
          candidateId={candidateDetails.candidateId}
          candidateDetails={candidateDetails}
          getCandidateById={getCandidateById}
        />
      ),
    },
  ]

  return (
    <div className='bg-white border border-Gray-200 w-[425px] h-full rounded-lg shadow-sm py-[27px] px-[31px] relative'>
      <ColumnWithAlignment
        itemAlignment='center'
        justifyAlignment='center'
        className='w-full'>
        <CandidateMenuSection
          candidateDetails={candidateDetails}
          getCandidateById={getCandidateById}
        />

        <CandidateAvatarSection
          candidateId={candidateDetails.candidateId}
          avatarUrl={candidateDetails.avatarUrl}
          getCandidateById={getCandidateById}
        />

        <span className='text-base mt-3 text-Gray-800 font-Bold'>
          {candidateDetails?.name}
        </span>

        <ColumnWithAlignment
          itemAlignment='center'
          justifyAlignment='center'
          className='mt-3 w-full'>
          <CenteredTabs
            className='w-full'
            contentClassName='w-full py-3'
            defaultTabId={defaultTab}
            tabDetails={tabDetails}
            onChange={onTabChange}
          />
        </ColumnWithAlignment>
      </ColumnWithAlignment>
    </div>
  )
}
