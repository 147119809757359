import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

type SaveCustomerCardDto = {
  paymentCardId: string
  isDefaultCard: boolean
}

export const useMarkDefaultCustomerCard = () => {
  const mutate = useMutation((dto: SaveCustomerCardDto) => {
    return putAPI(`/payments/cards/default`, dto)
  })

  return mutate
}
