import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

export type GenerateQuestionnairesTemplateRequest = {
  questionNumber: number
  jobRole: string
  industry: string
  jobDescription: string
  typeOfReferenceCheck: string
  competencies: string[]
}

export const useGenerateQuestionnairesTemplate = () => {
  const mutate = useMutation(
    (request: GenerateQuestionnairesTemplateRequest) => {
      return postAPI('/AI/VertexAI/GenerateAiQuestions', { request })
    },
  )
  return mutate
}
