import { useMutation } from 'react-query'
import { putAPIwithFormData } from 'framework/api/http'

type UpdateAgencyWithPrimaryLogoRequest = {
  id: string
  primaryLogo?: any
  name: string
  phone: string
  website?: string
  privacyPolicy?: string
}

export const useUpdateAgencyWithPrimaryLogo = () => {
  const mutate = useMutation((request: UpdateAgencyWithPrimaryLogoRequest) => {
    const bodyFormData = new FormData()
    bodyFormData.append('PrimaryLogo', request.primaryLogo)
    bodyFormData.append('Name', request.name)
    bodyFormData.append('Phone', request.phone)
    if (request.website) bodyFormData.append('website', request.website)
    if (request.privacyPolicy)
      bodyFormData.append('privacyPolicy', request.privacyPolicy)

    return putAPIwithFormData(
      `/AgencyProfile/${request.id}/stepper-with-logo`,
      bodyFormData,
      {},
    )
  })

  return mutate
}
