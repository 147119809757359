import { useMutation } from 'react-query'
import { ContactType } from 'features/contacts'
import { postAPI } from 'framework/api/http'

export type CreateContactDto = {
  name: string
  phoneNumber: string
  email: string
  message: string
  isRequestCall: boolean
  isRequestEmail: boolean
  contactType: ContactType
}

export const useCreateContact = () => {
  const mutate = useMutation((dto: CreateContactDto) => {
    return postAPI(`/contacts`, dto)
  })

  return mutate
}
