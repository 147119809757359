import React, { useEffect, useState } from 'react'

import Characters from 'components/molecules/AddonPreviewModal/TypedTestResultReport/Characters'
import InputHistory from 'components/molecules/AddonPreviewModal/TypedTestResultReport/InputHistory'
import ResultChart from 'components/molecules/AddonPreviewModal/TypedTestResultReport/ResultChart'
import TestStats from 'components/molecules/AddonPreviewModal/TypedTestResultReport/TestStats'
import { ChartData } from 'components/ui/reports/addonReportsPreviewModal/TypingTestAddon/TypingTestAddonPreviewReport'
import { ReportFooter } from 'components/ui/reports/sections'

import AntiCheatinMonitor from '../AntiCheatinMonitor'
import AssessmentCompletedSection from '../DownloadReport/AssessmentCompletedSection'

type TypingTestReportPreviewSectionProps = {
  reportViewData: any
  isPrinting: boolean
}

const TypingTestReportPreviewSection: React.FC<
  TypingTestReportPreviewSectionProps
> = ({ reportViewData, isPrinting }) => {
  const [chartDataArray, setChartDataArray] = useState<ChartData[]>([])
  const [typedHistory, setTypedHistory] = useState<string[]>([])
  const [wordList, setWordList] = useState<string[]>([])
  const [duration, setDuration] = useState(0)

  useEffect(() => {
    const data = JSON.parse(reportViewData.jsonData)
    if (data && Object.keys(data).length > 0) {
      setWordList(data.wordList || [])
      setTypedHistory(data.typedHistory || [])
      setChartDataArray(data.chartDataArray || [])
      setDuration(data.chartDataArray?.length || 0)
    }
  }, [reportViewData])

  return (
    <>
      <div className='m-3'>
        <AssessmentCompletedSection reportViewData={reportViewData} />
        <div className='my-4' style={{ pageBreakBefore: 'always' }}>
          <AntiCheatinMonitor isReport={true} reportViewData={reportViewData} />
        </div>
      </div>

      <div className='w-fit h-fit'>
        <ResultChart chartDataArray={chartDataArray} isReport={true} />
        <TestStats
          chartDataArray={chartDataArray}
          duration={duration}
          typedHistory={typedHistory}
          wordList={wordList}
          isReport={true}
        />
        <div>
          <Characters
            typedHistory={typedHistory}
            wordList={wordList}
            isReport={true}
          />
          <div
            style={{ pageBreakBefore: 'always' }}
            className={isPrinting ? 'mt-6' : ''}>
            <InputHistory
              typedHistory={typedHistory}
              wordList={wordList}
              isReport={true}
            />
          </div>
        </div>
      </div>
      {isPrinting && <ReportFooter isFixed={true} />}
    </>
  )
}

export default TypingTestReportPreviewSection
