import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Button, Toast } from '@labourhub/labour-hub-ds'
import { useSendCustomSurvey } from 'api/candidates/custom-surveys/useSendCustomSurvey'
import { QuestionnaireLibraryDto } from 'api/questionnaires'

import { Card } from 'components/atoms/Card'
import { Column } from 'components/atoms/Column'
import { RowWithAlignment } from 'components/atoms/Row'
import { getS3File } from 'utils'

import { CustomSurveyPreviewModal } from './CustomSurveyPreviewModal/CustomSurveyPreviewModal'

type CustomSurveyCardItemProps = {
  survey: QuestionnaireLibraryDto
  getCandidateById: () => void
  refetch: any
}

export const CustomSurveyCardItem = ({
  survey,
  getCandidateById,
  refetch,
}: CustomSurveyCardItemProps) => {
  const [isPreviewModalActive, setIsPreviewModalActive] = useState(false)
  const togglePreviewModal = () => {
    setIsPreviewModalActive(!isPreviewModalActive)
  }
  const [searchParams] = useSearchParams()
  const candidateId: string = searchParams.get('candidate_id') || ''

  const {
    mutate: sendCustomSurveyMutate,
    isLoading: sendCustomTemplateIsLoading,
  } = useSendCustomSurvey()

  useEffect(() => {
    refetch()
  }, [sendCustomTemplateIsLoading])

  const sendSurveyTemplate = () => {
    const requestData = {
      surveyLibraryId: survey.id,
      templateName: survey.role,
    }
    sendCustomSurveyMutate(
      {
        candidateId: candidateId,
        requestData: requestData,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          Toast({
            alertBody: successData?.message,
            alertHeader: successData?.messageTitle,
            status: 'Success',
          })
          getCandidateById()
          refetch()
        },
        onError: ({ response: { data: errData } }: any) => {
          if (
            errData &&
            (errData?.messageTitle as string).includes(
              'Test SMS count Exceeded',
            )
          ) {
            Toast({
              alertHeader: errData?.messageTitle,
              alertBody: errData?.message,
              status: 'Warning',
            })
          } else {
            Toast({
              alertHeader: 'Error...!',
              alertBody: errData?.message,
              status: 'Error',
            })
          }
        },
      },
    )
  }
  return (
    <Card className='shadow-sm hover:shadow-xl rounded-xl relative cursor-pointer min-h-[245px]'>
      <Column className='items-center pt-3'>
        <RowWithAlignment justifyAlignment='center'>
          {/* Image */}
          <img
            src={getS3File(survey.imageUrl)}
            alt=''
            className='flex w-[120px] justify-center items-center h-[92px]'
          />
        </RowWithAlignment>
        {/* Title */}
        <span className='mt-2 text-base text-center text-Gray-800 font-SemiBold'>
          {survey.role}
        </span>
        {/* Subtitle*/}
        <p className='mb-2 text-center text-small text-Gray-500'>
          {survey.description}
        </p>
        <RowWithAlignment className='w-full p-2' justifyAlignment='center'>
          <Button
            className='h-[32px] w-[125px] mx-2'
            theme='white'
            textTheme='blue'
            onClick={togglePreviewModal}
            iconRight={<i className='ri-external-link-line text-heading-5' />}>
            Preview
          </Button>
          <Button
            className='h-[32px] w-[125px] mx-2'
            textTheme='white'
            onClick={sendSurveyTemplate}
            isLoading={sendCustomTemplateIsLoading}
            isDisabled={survey.isSentToSurveyResponse}
            iconRight={
              <i className='ri-send-plane-line text-heading-5 pr-[6px]' />
            }>
            Send
          </Button>
        </RowWithAlignment>
      </Column>
      {isPreviewModalActive && (
        <CustomSurveyPreviewModal
          isModalActive={isPreviewModalActive}
          setIsModalActive={setIsPreviewModalActive}
          templateId={survey.id}
          templateName={survey.role}
        />
      )}
    </Card>
  )
}
