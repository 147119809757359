import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, TextField, Toast } from '@labourhub/labour-hub-ds'
import { useUpdateOnboardingStepper } from 'api/onboarding'
import { aboutYourBusiness } from 'features/onboarding/data'
import { setAboutYourBusiness, setCurrentStep } from 'features/onboarding/store'
import { getOnboardingStore } from 'features/onboarding/store/selectors'
import { useVerifyAgencySubDomainAvailability } from 'framework/api/methods'

import { URLTextInput } from 'components/atoms'

export const TellUsAboutBusinessSection = () => {
  const dispatch = useDispatch()
  const { currentStep } = useSelector(getOnboardingStore)

  const [userFormDetails, setUserFormDetails] = useState({
    companyName: '',
    subDomain: '',
    industry: '',
  })

  const [isErrorField, setIsErrorField] = useState({
    companyName: false,
    subDomain: false,
    industry: false,
  })

  /** next button disable state */
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false)

  const [isSubDomainValid, setIsSubDomainValid] = useState(false)

  const onNextStepClick = () => {
    const valid = validateInputs()

    if (valid) {
      companyOnboarding()
    } else {
      Toast({
        alertHeader: 'Invalid input type.',
        status: 'Warning',
      })
    }
  }

  /** front end validation input fields */
  const validateInputs = () => {
    const { companyName, industry, subDomain } = userFormDetails

    setIsErrorField({
      ...isErrorField,
      companyName: companyName ? false : true,
      industry: industry ? false : true,
      subDomain: subDomain.substr(subDomain.length - 1) !== '-' ? false : true,
    })

    const isAllValidFields =
      companyName && industry && subDomain.substr(subDomain.length - 1) !== '-'

    return isAllValidFields
  }

  /** sub domain on success */
  const getVerifyAgencySubDomainAvailabilityOnSuccess = () => {
    setIsSubDomainValid(true)
  }

  /** sub domain on error */
  const getVerifyAgencySubDomainAvailabilityOnError = () => {
    setIsSubDomainValid(false)
  }

  /** verify sub domain */
  const {
    refetch: verifyAgencySubDomainAvailability,
    isLoading: verifyAgencySubDomainAvailabilityIsLoading,
  } = useVerifyAgencySubDomainAvailability(
    {
      subDomain: userFormDetails?.subDomain ? userFormDetails?.subDomain : null,
    },
    getVerifyAgencySubDomainAvailabilityOnSuccess,
    getVerifyAgencySubDomainAvailabilityOnError,
  )

  /** on sub domain change verify email address */
  useEffect(() => {
    const timer = setTimeout(() => {
      userFormDetails?.subDomain && verifyAgencySubDomainAvailability()
    }, 1000)
    return () => clearTimeout(timer)
  }, [userFormDetails?.subDomain])

  /** handle submit button disable */
  useEffect(() => {
    const { companyName, industry, subDomain } = userFormDetails || {}

    companyName &&
    isSubDomainValid &&
    industry &&
    subDomain &&
    !verifyAgencySubDomainAvailabilityIsLoading
      ? setIsNextButtonDisabled(false)
      : setIsNextButtonDisabled(true)
  }, [
    userFormDetails,
    isSubDomainValid,
    verifyAgencySubDomainAvailabilityIsLoading,
  ])

  /** APi call for the onboarding stepper */
  const {
    mutate: companyOnboardingMutate,
    isLoading: companyOnboardingIsLoading,
  } = useUpdateOnboardingStepper()

  /** Process the onboarding stepper */
  const companyOnboarding = () => {
    companyOnboardingMutate(
      {
        aboutYourBusiness: {
          id: aboutYourBusiness,
          companyName: userFormDetails?.companyName,
          industry: userFormDetails?.industry,
          subDomain: userFormDetails?.subDomain,
        },
      },
      {
        onSuccess: () => {
          dispatch(
            setAboutYourBusiness({
              id: aboutYourBusiness,
              companyName: userFormDetails?.companyName,
              industry: userFormDetails?.industry,
              subDomain: userFormDetails?.subDomain,
            }),
          )
          dispatch(setCurrentStep(currentStep + 1))
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: errData.messageTitle ? errData.messageTitle : 'Error!',
            alertBody: errData.message,
            status: 'Error',
          })
        },
      },
    )
  }

  return (
    <div className='flex flex-col items-center justify-center w-7/12'>
      <div className='w-full mt-5 text-left text-heading-2 font-Medium text-Gray-800'>
        Tell us about your Business
      </div>

      <div className='flex w-full gap-x-8'>
        <TextField
          type='text'
          label='Company Name'
          placeholder=''
          className='w-full pt-5'
          isErrored={isErrorField.companyName}
          value={userFormDetails?.companyName}
          onChange={(e: any) => {
            setUserFormDetails({
              ...userFormDetails,
              companyName: e.target.value,
              subDomain: e.target.value
                .replace(/[-\s]+/g, '-')
                .replace(/^-/, '')
                .replace(/[^a-zA-Z0-9-]+/g, '')
                .toLowerCase(),
            })
          }}
        />

        <TextField
          type='text'
          label='Industry'
          placeholder=''
          className='w-full pt-5'
          isErrored={isErrorField.industry}
          value={userFormDetails?.industry}
          onChange={(e: any) => {
            setUserFormDetails({
              ...userFormDetails,
              industry: e.target.value,
            })
          }}
        />
      </div>

      <URLTextInput
        label='Preferred Sub-domain'
        value={userFormDetails?.subDomain}
        className='sub-domain w-full mt-6 ml-[0.5px]'
        onChange={(e: any) => {
          setUserFormDetails({
            ...userFormDetails,
            subDomain: e.target.value
              .replace(/[-\s]+/g, '-')
              .replace(/^-/, '')
              .replace(/[^a-zA-Z0-9-]+/g, '')
              .toLowerCase(),
          })

          setIsErrorField({
            ...isErrorField,
            subDomain: false,
          })
        }}
        placeholder='Your preferred sub-domain'
        isErrored={isErrorField?.subDomain}
        helperText={
          isErrorField?.subDomain
            ? 'Oops! Last character of the domain name can not be " - ".'
            : 'Please note that the sub-domain can’t be changed later.'
        }
        verifyContent={
          userFormDetails?.subDomain ? (
            isSubDomainValid ? (
              <div className='flex items-center justify-center text-Green-600 text-extra-small'>
                <i className='mr-1 ri-checkbox-circle-fill' />

                <span>Sub-domain available</span>
              </div>
            ) : (
              <div className='flex items-center justify-center text-Red-400 text-extra-small'>
                <i className='mr-1 ri-checkbox-circle-fill' />

                <span>Sub-domain not available</span>
              </div>
            )
          ) : (
            <></>
          )
        }
      />

      {/* next button */}
      <Button
        isBlock
        onClick={onNextStepClick}
        isLoading={companyOnboardingIsLoading}
        isDisabled={isNextButtonDisabled}
        className='mt-5 next-btn'>
        Next
      </Button>
    </div>
  )
}

TellUsAboutBusinessSection.defaultProps = {}

export default TellUsAboutBusinessSection
