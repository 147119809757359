/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ChangeEvent, FC, ReactNode, useEffect, useRef } from 'react'
import CN from 'classnames'

import getWordCount from 'utils/getWordCount'

interface TextAreaProps {
  [x: string]: any
  className?: string | undefined
  description?: string | undefined
  helperText?: string | undefined
  icon?: ReactNode | undefined
  iconOnClick?: (e: any) => void | undefined
  isCustomStyles?: boolean
  isDisabled?: boolean
  isReadonly?: boolean
  isErrored?: boolean
  isRequired?: boolean
  label?: string | undefined
  onChange?: (e: any) => void | undefined
  onKeyPress?: (e: any) => void | undefined
  placeholder?: string | undefined
  rows?: number | undefined
  textAreaClassName?: string | undefined
  value?: string
}

export const TextArea: FC<TextAreaProps> = ({
  className,
  description,
  helperText,
  icon,
  iconOnClick,
  isCustomStyles,
  isDisabled,
  isReadonly,
  isErrored,
  isRequired,
  label,
  onChange,
  onKeyPress,
  placeholder,
  rows,
  textAreaClassName,
  value,
  ...restProps
}: TextAreaProps) => {
  /** text area main class name */
  const textAreaMainClassName = !isCustomStyles
    ? CN('main-container flex flex-col', className)
    : className

  /** helper text style */
  const helperTextClassName = CN('text-small font-Regular pt-1', {
    'text-Gray-500': !isErrored,
    'text-Red-600': isErrored,
  })

  /** text area style */
  const textAreaInputClassName = CN(
    'flex w-full items-center py-2 text-Gray-800 rounded-md border shadow-sm focus:outline-Cobalt-400',
    textAreaClassName,
    {
      'border-Gray-300': !isErrored,
      'border-Red-500': isErrored,
    },
    {
      'px-3': icon === undefined,
      'pr-3 pl-10': icon,
    },
  )

  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    const textarea = textAreaRef.current
    if (textarea) {
      textarea.style.height = 'auto'

      if (value && value.length != 0) {
        const lineCount = Math.ceil(getWordCount(value) / 10) + 1
        if (lineCount > textarea.rows) {
          textarea.rows = lineCount
        }
      }
    }
  }, [])

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) onChange(event)
    const textarea = textAreaRef.current
    if (textarea) {
      textarea.style.height = 'auto'
      textarea.style.height = `${textarea.scrollHeight}px`
    }
  }

  return (
    <div className={textAreaMainClassName} {...restProps}>
      {/* label */}
      {label !== undefined && (
        <div className='label-container text-small pb-1'>
          <div className='label flex text-Gray-800 font-Medium'>
            {/* label text */}
            <span>{label}</span>

            {/* required */}
            {isRequired && <span className='text-Red-500 pl-[2px]'>*</span>}
          </div>

          {/* description */}
          {description && (
            <span className='text-Gray-500 font-Regular pt-1'>
              ({description})
            </span>
          )}
        </div>
      )}

      {/* text area fields*/}
      <div className='relative flex items-start'>
        {/*custom before icon*/}
        {icon && (
          <div
            className='absolute text-Gray-400 text-heading-4 pl-3 pt-2'
            onClick={iconOnClick}>
            {icon}
          </div>
        )}

        {/* text area field */}
        <textarea
          ref={textAreaRef}
          rows={rows}
          value={value}
          onChange={handleChange}
          onKeyPress={onKeyPress}
          placeholder={placeholder}
          disabled={isDisabled}
          readOnly={isReadonly}
          className={textAreaInputClassName}
          {...restProps.textAreaProps}
        />
      </div>

      {/* helper text */}
      {helperText !== undefined && (
        <span className={helperTextClassName}>{helperText}</span>
      )}
    </div>
  )
}

export default TextArea

TextArea.defaultProps = {
  className: undefined,
  description: undefined,
  helperText: undefined,
  icon: undefined,
  iconOnClick: undefined,
  isCustomStyles: false,
  isDisabled: false,
  isErrored: false,
  isRequired: false,
  label: undefined,
  onChange: undefined,
  onKeyPress: undefined,
  placeholder: 'Placeholder',
  rows: undefined,
  textAreaClassName: undefined,
  value: undefined,
}
