/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { Avatar } from '@labourhub/labour-hub-ds'
import candidateDefaultLogo from 'assets/images/Profile-Icon.png'
import { GuideTooltipModal } from 'features/onboarding'

const CandidatesTableColumn = (
  isEmailRequired: boolean,
  navigate: any,
  guideStepperCandidateId?: string,
) => {
  const COLUMNS = [
    {
      Header: 'NAME',
      accessor: 'firstName',
      forSorting: 'FirstName',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[250px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center w-full',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return (
          <div className='relative w-full cursor-pointer'>
            <div
              onClick={() => {
                navigate(
                  `/candidate-list/candidate-profile?&candidate_id=${original?.candidateId}&candidate_name=${original?.firstName} ${original?.lastName}`,
                )
              }}>
              <Avatar
                imageURL={
                  original?.avatarUrl
                    ? original?.avatarUrl
                    : candidateDefaultLogo
                }
                titleClassName='flex w-max line-clamp-1'
                size='xs'
                color='one'
                title={`${original?.firstName} ${original?.lastName}`}
                /** Showing warning and medical notification icons */
                titleIcons={
                  <div className='flex'>
                    {original.isWarning && (
                      <i className='pl-1 ri-error-warning-fill text-Red-500'></i>
                    )}

                    {original.isMedicalNotification && (
                      <i className='pl-1 ri-shield-cross-fill text-Cobalt-500'></i>
                    )}
                  </div>
                }
                tag={isEmailRequired ? original?.email : original.phone}
              />
            </div>

            {guideStepperCandidateId &&
              original?.candidateId == guideStepperCandidateId && (
                <GuideTooltipModal />
              )}
          </div>
        )
      },
    },
    {
      Header: 'REF CHECK FEEDBACK REQUESTED',
      accessor: 'refereeSurveysSentCount',
      forSorting: 'RefereeSurveysSentCount',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[120px] pl-6',
      headerClassName: 'flex items-center justify-start text-left',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <div>{original?.refereeSurveysSentCount}</div>
      },
    },
    {
      Header: 'REF CHECK FEEDBACK RECEIVED',
      accessor: 'refereeRespondedCount',
      forSorting: 'RefereeRespondedCount',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[120px] pl-6',
      headerClassName: 'flex items-center justify-start text-left',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <div>{original?.refereeRespondedCount}</div>
      },
    },
    {
      Header: 'ASSESSMENT REQUESTED',
      accessor: 'assessmentSentCount',
      forSorting: 'AssessmentSentCount',
      isSortable: false,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[120px] pl-6',
      headerClassName: 'flex items-center justify-start text-left',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <div>{original?.assessmentSentCount}</div>
      },
    },
    {
      Header: 'ASSESSMENT RECEIVED',
      accessor: 'assessmentRespondedCount',
      forSorting: 'AssessmentRespondedCount',
      isSortable: false,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[120px] pl-6',
      headerClassName: 'flex items-center justify-start text-left',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <div>{original?.assessmentRespondedCount}</div>
      },
    },
    {
      Header: 'MANAGING CONSULTANT',
      accessor: 'consultantName',
      forSorting: 'ConsultantName',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[167px] pl-6',
      headerClassName: 'flex items-center justify-start text-left',
      cellClassName: 'flex items-center justify-start h-auto',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <div>{original?.consultantName}</div>
      },
    },
  ]

  return COLUMNS
}

export default CandidatesTableColumn
