import React, { useMemo } from 'react'
import { Radio, SimpleSelect, TextField } from '@labourhub/labour-hub-ds'

import { Rate } from 'components/atoms'
import { Card } from 'components/atoms/Card'
import IFrameVideo from 'components/atoms/IFrameVideo/IFrameVideo'
import ImageViewer from 'components/atoms/ImageViewer/ImageViewer'
import { Checkbox } from 'components/core/Checkbox'
import { TextArea } from 'components/molecules/TextArea'
import { cn } from 'utils'
import { QuestionType } from 'utils/getQuestionType'

type AssesmentAnswerRatingViewProps = {
  selectedTemplate: any
  isPrint: boolean
  assessmentName: string
}

export const AssesmentAnswerRatingView: React.FC<
  AssesmentAnswerRatingViewProps
> = ({ selectedTemplate, isPrint, assessmentName }) => {
  const getIconAndColor = (rating: string) => {
    switch (rating) {
      case 'Incorrect':
        return {
          icon: 'ri-close-circle-fill',
          color: 'TerraCotta-800',
          text: 'Incorrect',
          textColor: 'TerraCotta-800',
        }
      case 'NotAnswered':
        return {
          icon: 'ri-close-line',
          color: 'Gray-400',
          text: 'Not Answered',
          textColor: 'Gray-800',
        }
      case '5':
        return {
          icon: 'ri-checkbox-circle-fill',
          color: 'Green-800',
          text: 'Correct',
          textColor: 'Green-800',
        }
      default:
        return {
          icon: '',
          color: '[#5B21B6]',
          text: 'Partially correct',
          textColor: '[#5B21B6]',
        }
    }
  }

  const {
    icon,
    color: iconColor,
    text,
    textColor,
  } = useMemo(
    () => getIconAndColor(selectedTemplate?.answerRating),
    [selectedTemplate?.answerRating],
  )

  const checkboxAnswerSelection = useMemo(() => {
    if (
      selectedTemplate.questionType === QuestionType.CheckBox &&
      selectedTemplate.answer
    ) {
      try {
        const parsedAnswer = JSON.parse(selectedTemplate.answer)
        return Array.isArray(parsedAnswer) ? parsedAnswer : []
      } catch {
        return []
      }
    }
    return []
  }, [selectedTemplate.questionType, selectedTemplate.answer])

  const renderQuestionContent = () => {
    switch (selectedTemplate.questionType) {
      case QuestionType.Text:
        return renderTextField()
      case QuestionType.TextArea:
        return renderTextArea()
      case QuestionType.Rating:
        return renderRating()
      case QuestionType.CheckBox:
        return renderCheckboxes()
      case QuestionType.Select:
        return renderSelect()
      case QuestionType.Radio:
        return renderRadio()
      case QuestionType.RadioPositiveDetail:
      case QuestionType.RadioNegativeDetail:
        return renderRadioWithDetails()
      default:
        return null
    }
  }

  const renderTextField = () => {
    const { answer } = selectedTemplate
    if (!answer) {
      return (
        <TextField
          className='w-full'
          isDisabled={true}
          value=''
          placeholder=''
        />
      )
    }
    return answer.length > 65 ? (
      <TextArea
        className='w-full'
        value={answer}
        isReadonly={true}
        placeholder=''
        textAreaClassName='h-auto'
      />
    ) : (
      <TextField
        className='w-full'
        isDisabled={true}
        value={answer}
        placeholder=''
      />
    )
  }

  const renderTextArea = () => (
    <TextArea
      className='w-full'
      value={selectedTemplate.answer}
      isReadonly={true}
      placeholder=''
      rows={4}
      textAreaClassName={`${isPrint ? 'h-auto' : '!h-[150px]'}`}
    />
  )

  const renderRating = () => (
    <Rate
      readonly={true}
      rating={selectedTemplate.answer || '0'}
      count={Number(selectedTemplate.ratingCount || '5')}
    />
  )

  const renderCheckboxes = () => {
    const answerDetails = JSON.parse(selectedTemplate.answerDetails)
    return (
      <div className='flex flex-col gap-y-2 pointer-events-none'>
        {answerDetails.map((item: string, idx: number) => (
          <Checkbox
            key={idx}
            id={`custom-checkbox-0${idx}`}
            labelText={item}
            checked={!!checkboxAnswerSelection.includes(item)}
            labelTextClassName='font-Regular'
            checkboxClassName='ml-0'
          />
        ))}
      </div>
    )
  }

  const renderSelect = () => (
    <SimpleSelect
      className='w-full'
      value={{
        value: selectedTemplate.answer,
        label: selectedTemplate.answer,
      }}
      placeholder='Select Option'
    />
  )

  const renderRadio = () => (
    <div className='flex gap-6'>
      <Radio
        id={`StatusYes_${selectedTemplate.questionID}`}
        checked={
          selectedTemplate.answer === 'Yes' ||
          selectedTemplate.answer === 'True'
        }
        className='cursor-pointer'
        name={`YesNoStatus_${selectedTemplate.questionID}`}
        helperText=''
        labelText=' Yes'
      />
      <Radio
        id={`StatusYes_${selectedTemplate.questionID}`}
        checked={
          selectedTemplate.answer === 'No' ||
          selectedTemplate.answer === 'False'
        }
        className='cursor-pointer'
        name={`YesNoStatus_${selectedTemplate.questionID}`}
        helperText=''
        labelText=' No'
      />
    </div>
  )

  const renderRadioWithDetails = () => (
    <div className='flex flex-col w-full'>
      <div className='flex gap-6'>
        <Radio
          checked={
            selectedTemplate.answer === 'Yes' ||
            selectedTemplate.answer === 'True'
          }
          id={`${selectedTemplate.questionID}-detailed-yes`}
          className='cursor-pointer select-none'
          name={`00${selectedTemplate.questionID}-detailed`}
          helperText=''
          labelText='Yes'
        />
        <Radio
          checked={
            selectedTemplate.answer === 'No' ||
            selectedTemplate.answer === 'False'
          }
          className='cursor-pointer select-none'
          id={`${selectedTemplate.questionID}-detailed-yes`}
          name={`00${selectedTemplate.questionID}-detailed`}
          helperText=''
          labelText='No'
        />
      </div>
      <TextArea
        className='w-full'
        value={selectedTemplate.answerDescription}
        isReadonly={true}
        placeholder=''
        rows={4}
        textAreaClassName='h-auto'
      />
    </div>
  )

  const renderRatingCard = () => {
    if (
      !selectedTemplate.questionNumber ||
      selectedTemplate.questionNumber === 0
    ) {
      return null
    }

    return (
      <Card className='mt-5 bg-[#F9FAFB]'>
        <div className='w-full flex flex-col gap-8'>
          <div>
            <p className='w-full text-Gray-800 font-SemiBold whitespace-pre-wrap'>
              Rate Answer
            </p>
            <div className='w-full space-x-3 items-center flex gap-3'>
              <Rate
                rating={
                  ['Incorrect', 'NotAnswered'].includes(
                    selectedTemplate?.answerRating,
                  )
                    ? 0
                    : selectedTemplate?.answerRating
                }
                readonly
                count={5}
              />
              {assessmentName == 'General Math Test' ||
              assessmentName == 'English Grammar & Punctuation Test' ? (
                <div />
              ) : selectedTemplate?.isAiGenerated ? (
                <li className='text-Gray-800'>Ai Generated</li>
              ) : (
                <li className='text-[#3730A3]'>Updated Manually</li>
              )}
            </div>
          </div>
          {selectedTemplate?.comment && (
            <div>
              <p className='w-full text-Gray-800 font-SemiBold whitespace-pre-wrap'>
                Comment
              </p>
              <div className='w-full space-x-3 items-center'>
                <li className='text-Gray-500'>{selectedTemplate?.comment}</li>
              </div>
            </div>
          )}
        </div>
      </Card>
    )
  }

  return (
    <div className='flex flex-col w-full justify-start h-auto my-5'>
      <div className='w-full'>
        {selectedTemplate.questionNumber && (
          <div className='flex justify-between w-full'>
            <div className='px-2 py-1 mb-3 max-w-[180px] rounded-full text-[#1E40AF] font-Medium text-extra-small bg-[#DBEAFE]'>
              <span>{`Question No ${
                selectedTemplate.questionNumber < 10
                  ? `0${selectedTemplate.questionNumber}`
                  : selectedTemplate.questionNumber
              }`}</span>
            </div>
            <div className='px-2 py-1 max-w-[180px] font-Medium text-extra-small'>
              <div className='flex gap-1 items-center'>
                <i className={`${icon} text-small text-${iconColor}`} />
                <span className={`text-${textColor}`}>{text}</span>
              </div>
            </div>
          </div>
        )}

        <p
          className={cn('w-full text-Gray-800 whitespace-pre-wrap my-4', {
            'text-[1.2rem] font-[700]':
              selectedTemplate.questionType === QuestionType.Heading,
            'font-Medium':
              selectedTemplate.questionType !== QuestionType.Heading,
            'whitespace-pre-line':
              selectedTemplate.questionType === QuestionType.Paragraph,
            "after:content-['*'] after:ml-0.5 after:text-TerraCotta-400":
              selectedTemplate.isRequired,
          })}>
          {selectedTemplate.questionNumber
            ? `${selectedTemplate.questionNumber}. `
            : ''}
          {selectedTemplate.question}
        </p>

        {selectedTemplate.imageUrl && (
          <ImageViewer
            imageUrl={selectedTemplate.imageUrl}
            isPrint={isPrint}
            className={`${isPrint ? 'w-1/4' : 'w-1/2'}`}
          />
        )}

        {selectedTemplate.videoUrl && !isPrint && (
          <IFrameVideo link={selectedTemplate.videoUrl} />
        )}
        {selectedTemplate.videoUrl && isPrint && (
          <a
            className='underline text-Blue-500'
            href={selectedTemplate.videoUrl}>
            {selectedTemplate.videoUrl}
          </a>
        )}

        <div className='border-[0.5px] border-[#E5E7EB] my-3' />

        <div className='w-full flex space-x-3 justify-evenly items-center pointer-events-auto'>
          <div className='w-full'>{renderQuestionContent()}</div>
        </div>
      </div>

      {renderRatingCard()}
    </div>
  )
}
