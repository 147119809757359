import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const onboardingResendOTP = async ({ queryKey }: any) => {
  const reqBody = queryKey[1]
  const res = await get(`/Onboarding/ResendOtp?tenantId=${reqBody?.tenantId}`)
  return res.data
}

export const useOnboardingResendOTP = (
  { ...reqBody },
  onSuccess: any,
  onError: any,
) => {
  return useQuery(['resend_otp', reqBody], onboardingResendOTP, {
    enabled: false,
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess,
    onError,
  })
}
