import { Column } from 'components/atoms/Column'
import { Row } from 'components/atoms/Row'
import { cn } from 'utils'

import { DetailColumn, Details } from './DetailColumn'

type ReportDetailColumnsProps = {
  title: string
  leftDetails: Details[]
  rightDetails: Details[]
  hasMiddleDivider?: boolean
}

export const ReportDetailColumns = ({
  title,
  leftDetails,
  rightDetails,
  hasMiddleDivider = false,
}: ReportDetailColumnsProps) => {
  return (
    <Column className='w-full space-y-3'>
      <label
        htmlFor={title}
        className='text-left text-black text-heading-5 font-Medium'>
        {title}
      </label>

      <Row
        className={cn(' border-Gray-200', {
          'border-t': hasMiddleDivider,
          'border-y pt-2': !hasMiddleDivider,
        })}>
        <DetailColumn details={leftDetails} type='left' />
        {hasMiddleDivider && (
          <div className='border border-l-0 border-Gray-200' />
        )}
        <DetailColumn details={rightDetails} type='right' />
      </Row>
    </Column>
  )
}
