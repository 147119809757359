import { cn } from 'utils'

export default function InfoCard({
  icon,
  heading,
  description,
  isReport = false,
}: {
  icon: string
  heading: string
  description: string
  isReport?: boolean
}) {
  return (
    <div
      className={cn(
        'my-4 p-6 bg-white border-1 border-[#085DA81C] rounded-xl break-inside-avoid',
        {
          'w-full': !isReport,
          'w-[747px]': isReport,
        },
      )}>
      <h5
        className={cn(
          'mb-2 flex items-center leading-[46px] font-SemiBold text-[#2E2E2E]',
          {
            'text-heading-4': !isReport,
            'text-[14px]': isReport,
          },
        )}>
        <span
          className={cn(
            'flex justify-center items-center bg-Cobalt-50 mr-[10px]',
            {
              'w-[42px] h-[42px]': !isReport,
              'w-[32px] h-[52px]': isReport,
            },
          )}>
          <i
            className={`${icon} text-Cobalt-500 text-[22px] leading-[22px]`}></i>
        </span>
        {heading}
      </h5>
      <p
        className={cn('ml-[52px] mb-3 font-Regular text-Gray-500 flex-wrap', {
          'text-base': !isReport,
          'text-[12px] leading-5': isReport,
        })}>
        {description}
      </p>
    </div>
  )
}
