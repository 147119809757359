import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

type SaveCustomerCardDto = {
  paymentCardId: string
}

export const useDeleteCustomerCard = () => {
  const mutate = useMutation(({ paymentCardId }: SaveCustomerCardDto) => {
    return postAPI(`/payments/cards/delete`, {
      paymentCardId,
    })
  })

  return mutate
}
