import { useEffect, useState } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { CategoryScale, Legend, Tooltip } from 'chart.js'
// eslint-disable-next-line import/no-extraneous-dependencies,, import/no-named-as-default
import Chart from 'chart.js/auto'

import { ChartData } from 'components/ui/reports/addonReportsPreviewModal/TypingTestAddon/TypingTestAddonPreviewReport'

import LineChart from './LineChart'

Chart.register(CategoryScale, Tooltip, Legend)
export default function ResultChart({
  chartDataArray,
  isReport = false,
}: {
  chartDataArray: ChartData[]
  isReport?: boolean
}) {
  const [lineChartData, setLineChartData] = useState<any>({})
  useEffect(() => {
    setLineChartData({
      labels: chartDataArray.map((data: any) => data.time),
      datasets: [
        {
          clip: false,
          label: 'WPM ',
          data: chartDataArray.map((data: any) => data.wpm),
          backgroundColor: ['rgba(0,0,255,1)'],
          borderColor: 'rgba(0,0,255,1)',
          borderWidth: 3,
          yAxisID: 'y',
          pointStyle: false,
          pointRadius: 10,
          pointHoverRadius: 15,
          order: 1,
        },
        {
          clip: false,
          label: 'RAW ',
          data: chartDataArray.map((data: any) => data.raw),
          backgroundColor: ['rgba(200,200,200,0.5)'],
          borderColor: 'rgba(200,200,200,0.5)',
          borderWidth: 3,
          yAxisID: 'y',
          pointStyle: false,
          pointRadius: 10,
          pointHoverRadius: 15,
          order: 2,
        },
        {
          clip: false,
          label: 'Errors ',
          data: chartDataArray.map((data: any) => data.error),
          backgroundColor: [
            'rgba(255,0,0,1)',
            //   "#ec00f1",
            //   "#ffAF95",
            //   "#f3ba2f",
            //   "#ff71d0"
          ],
          borderColor: 'rgba(255,0,0,1)',
          // borderWidth: 2,
          yAxisID: 'y1',
          type: 'scatter',
          order: 3,
          pointStyle: 'crossRot',
          pointRadius: 4,
          pointHoverRadius: 8,
        },
      ],
    })
  }, [chartDataArray.length])

  return (
    <>
      {lineChartData?.labels ? (
        <LineChart chartData={lineChartData} isReport={isReport} />
      ) : null}
    </>
  )
}
