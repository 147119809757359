import { useMutation } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { postAPI } from 'framework/api/http'

export const useDeclineRefCheck = (candidateId: string, refereeId: string) => {
  return useMutation(
    () => {
      return postAPI(
        `/Candidate/${candidateId}/Referee/${refereeId}/Decline`,
        {},
        {},
      )
    },
    {
      onSuccess: () => {
        Toast({
          alertHeader: `Ref check response saved successfully`,
          status: 'Success',
        })
      },
      onError: () => {
        Toast({
          alertHeader: `Referee check response saving failed`,
          status: 'Error',
        })
      },
    },
  )
}

export default useDeclineRefCheck
