import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Radio } from '@labourhub/labour-hub-ds'

import { Modal, ModalFooter } from 'components/molecules'

import { AddNewCandidateModal } from '../AddNewCandidateModal'

import { AddCandidateAndNavigateModal } from './AddCandidateAndNavigateModal'

const options = [
  {
    title: 'Start with Ref Check',
    description: 'Add candidate and begin the reference check process',
    type: 'refCheck',
  },
  {
    title: 'Start with Assessment ',
    description: 'Add candidate and start the assessment process',
    type: 'assessment',
  },
  {
    title: 'Add Candidate and decide later',
    description: `Add candidate’s basic information and start the ref check or assessment process later`,
    type: 'later',
  },
]

type AddCandidateOptionsModalProps = {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  refreshCandidateList: () => void
}

export const AddCandidateOptionsModal = ({
  isOpen,
  setIsOpen,
  refreshCandidateList,
}: AddCandidateOptionsModalProps) => {
  const navigate = useNavigate()

  const [option, setOption] = useState<
    'refCheck' | 'assessment' | 'later' | ''
  >('')

  const [isRefCheckModalOpen, setIsRefCheckModalOpen] = useState(false)
  const [isCreateNavigateModalOpen, setIsCreateNavigateModalOpen] =
    useState(false)

  const [modalDetails, setModalDetails] = useState<(typeof options)[number]>(
    options[1],
  )

  const handleCandidateTypeRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { id } = event.target
    setOption(id as 'refCheck' | 'assessment' | 'later')
  }

  const closeModal = () => {
    setIsOpen(false)
    setOption('')
  }

  const handleContinue = () => {
    if (option === 'refCheck') {
      setIsRefCheckModalOpen(true)
      setIsOpen(false)
    } else if (option === 'assessment') {
      setModalDetails(options[1])
      setIsOpen(false)
      setIsCreateNavigateModalOpen(true)
    } else {
      setModalDetails(options[2])
      setIsOpen(false)
      setIsCreateNavigateModalOpen(true)
    }
  }

  return (
    <div>
      <Modal
        isActive={isOpen}
        headerTitle={'Add Candidate'}
        subHeaderTitle='Select an option'
        size='md'
        className='!z-[150]'
        isHeaderShow={true}
        isFooterShow={true}
        modalProps={{
          style: { maxHeight: '95vh' },
        }}
        onHeaderCloseButtonClick={() => closeModal()}>
        <div className='flex flex-col w-full justify-start h-[650px] overflow-y-auto p-4 bg-Gray-50 styled-scrollbar'>
          <div className='mt-4'>
            {options.map((currentOption) => (
              <div
                key={currentOption.type}
                className='bg-white px-4 py-3 mb-4 border border-Gray-200 rounded-md'>
                <Radio
                  id={currentOption.type}
                  labelText={currentOption.title}
                  helperText={currentOption.description}
                  name='templateType'
                  onChange={handleCandidateTypeRadioChange}
                  value={currentOption}
                  checked={currentOption.type === option}
                  className='cursor-pointer'
                />
              </div>
            ))}
          </div>
        </div>

        <ModalFooter>
          <div className='flex flex-col flex-1 gap-y-2'>
            <Button
              isDisabled={!option}
              isLoading={false}
              onClick={() => handleContinue()}
              theme='cobalt'>
              Next
            </Button>
            <Button onClick={() => closeModal()} textTheme='blue' theme='white'>
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <AddNewCandidateModal
        modalTitle={'Start with Ref Check'}
        modalSubTitle={'Add candidate and begin the reference check process'}
        refreshCandidateList={refreshCandidateList}
        isModalActive={isRefCheckModalOpen}
        setIsModalActive={setIsRefCheckModalOpen}
        onSuccessCallback={(candidateData: any) => {
          const { id, firstName, lastName } = candidateData.candidate
          navigate(
            `/candidate-list/candidate-profile?&candidate_id=${id}&candidate_name=${firstName} ${lastName}`,
          )
        }}
      />
      <AddCandidateAndNavigateModal
        isOpen={isCreateNavigateModalOpen}
        setIsOpen={setIsCreateNavigateModalOpen}
        refreshCandidateList={refreshCandidateList}
        title={modalDetails.title}
        description={modalDetails.description}
        onSuccessCallback={(candidateData: any) => {
          const tab = option === 'assessment' ? 'assessment' : 'referee'
          const { id, firstName, lastName } = candidateData.candidate
          navigate(
            `/candidate-list/candidate-profile?&candidate_id=${id}&candidate_name=${firstName} ${lastName}&tab=${tab}`,
          )
        }}
      />
    </div>
  )
}
