import SelectedAssessment from 'assets/images/assessment/Selected_Assessment.svg'

import { Addon } from './SendAssessmentModal'

export default function AssessmentDetails({
  assessment,
  setIsPreviewModalActive,
  selectedAddons,
  isSingleView = false,
}: {
  assessment: any
  setIsPreviewModalActive: React.Dispatch<React.SetStateAction<boolean>>
  selectedAddons: Addon[]
  isSingleView?: boolean
}) {
  const handleOpenPreviewModal = () => {
    setIsPreviewModalActive(true)
    // setIsModalActive(false)
  }

  return (
    <div className='overflow-y-auto styled-scroll pr-2 -mr-2'>
      <div className='font-SemiBold text-heading-5'>Assessment Selected</div>
      {!isSingleView && (
        <div className='w-full justify-center items-center py-4'>
          <img
            src={SelectedAssessment}
            alt=''
            className='flex w-full justify-center items-center h-[106px] px-4'
          />
        </div>
      )}

      <div className='h-[132px] flex flex-col gap-2 '>
        <div className='flex items-center'>
          <div className='flex flex-col items-center gap-2'>
            <h3 className='text-Gray-500 text-small'>Assessment Selected</h3>
          </div>
          <button
            className='ml-auto  text-base  cursor-pointer underline text-[#71B0EB]'
            onClick={handleOpenPreviewModal}>
            {assessment?.name}
          </button>
        </div>
        <div className=' py-2 '>
          <h5 className='text-Gray-500 text-small '>Number of Questions</h5>
          <ul className='space-y-1 list-disc list-inside'>
            <li className='list-item ml-3 '>
              <span className='text-Gray-500 text-small  '>
                {assessment?.name}
              </span>
              <span className=' text-Gray-900 text-base font-SemiBold float-right mr-1'>
                {assessment?.numberOfQuestions || assessment?.questionCount}
              </span>
            </li>
            {selectedAddons?.map((addon) => (
              <li key={`q-${addon?.assessmentId}`} className='list-item ml-3'>
                <span className='text-Gray-500 text-small'>
                  {addon?.assessmentName}
                </span>
                <span className='text-Gray-900 text-base font-SemiBold float-right mr-1 '>
                  {addon?.questionCount || 1}
                </span>
              </li>
            ))}
          </ul>
        </div>
        <div className=' py-2 '>
          <h5 className='text-Gray-500 text-small '>Duration</h5>
          <ul className='space-y-1 list-disc list-inside'>
            <li className='list-item ml-3 '>
              <span className='text-Gray-500 text-small '>
                {assessment?.name}
              </span>
              <span className='text-Gray-900 text-base font-SemiBold float-right mr-1 '>
                {assessment?.duration}{' '}
                {parseInt(assessment?.duration) > 1 ? 'Minutes' : 'Minute'}
              </span>
            </li>
            {selectedAddons?.map((addon) => (
              <li key={`d-${addon?.assessmentId}`} className='list-item ml-3'>
                <span className='text-Gray-500 text-small '>
                  {addon?.assessmentName}
                </span>
                <span className='text-Gray-900 text-base font-SemiBold float-right mr-1 '>
                  {addon?.duration}{' '}
                  {parseInt(assessment?.duration) > 1 ? 'Minutes' : 'Minute'}
                </span>
              </li>
            ))}
          </ul>
        </div>
        <div className=' py-2 '>
          <h5 className='text-Gray-500 text-small '>Credit Usage</h5>
          <ul className='space-y-1 list-disc list-inside'>
            <li className='list-item ml-3 '>
              <span className='text-Gray-500 text-small'>
                {assessment?.name}
              </span>
              <span className='text-Gray-900 text-base font-SemiBold float-right mr-1 '>
                {'1'}
              </span>
            </li>
            {selectedAddons?.map((addon) => (
              <li key={`c-${addon?.assessmentId}`} className='list-item ml-3'>
                <span className='text-Gray-500 text-small '>
                  {addon?.assessmentName}
                </span>
                <span className='text-Gray-900 text-base font-SemiBold float-right mr-1 '>
                  {/* credits hard coded */}
                  {'1'}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}
