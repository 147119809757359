import { useMemo } from 'react'

import {
  CombinedReportNameHeader,
  ReportFooter,
} from 'components/ui/reports/sections'

import AntiCheatinMonitor from '../AntiCheatinMonitor'
import AutoMarkingProgress from '../AutoMarkingProgress'
import { AssesmentAnswerRatingView } from '../DownloadReport/AssesmentAnswerRatingView'
import AssessmentCompletedSection from '../DownloadReport/AssessmentCompletedSection'

type CombineReportPrintViewSectionProps = {
  markingValue: any
  reportViewData: any
  position: any
}

const getQuestionsPerPage = () => 2

const chunkArray = (array: any[], size: number, position: number) => {
  const result: any[] = []
  const startIndex = position === 1 ? 1 : 0

  if (position === 1 && array.length > 0) {
    result.push([array[0]])
  }

  for (let i = startIndex; i < array.length; i += size) {
    result.push(array.slice(i, i + size))
  }

  return result
}

const CombineReportPrintViewSection = ({
  markingValue,
  reportViewData,
  position,
}: CombineReportPrintViewSectionProps) => {
  const questionsPerPage = useMemo(getQuestionsPerPage, [])
  const reportChunks = useMemo(
    () => chunkArray(reportViewData.reportQuestion, questionsPerPage, position),
    [reportViewData.reportQuestion, questionsPerPage],
  )

  return (
    <>
      <div className='m-3'>
        <div
          className='h-auto pb-4'
          style={{ pageBreakBefore: position !== 1 ? 'always' : 'avoid' }}>
          {position !== 1 && (
            <div className={'pt-8'}>
              <CombinedReportNameHeader data={reportViewData} />
            </div>
          )}
          <AutoMarkingProgress
            values={markingValue}
            isReport={true}
            totalMarks={reportViewData?.assessmentAutoMarking?.totalMarks}
          />
        </div>
        <div
          className='h-auto pb-4'
          style={{ pageBreakAfter: position === 1 ? 'always' : 'avoid' }}>
          <AssessmentCompletedSection reportViewData={reportViewData} />
        </div>
        <div
          className={`h-auto pb-4 ${position === 1 ? 'pt-8' : ''}`}
          style={{ pageBreakAfter: position !== 1 ? 'always' : 'avoid' }}>
          <AntiCheatinMonitor reportViewData={reportViewData} isReport={true} />
        </div>
      </div>

      <div
        className={`px-6 font-Medium text-base ${
          position !== 1 ? 'pt-6' : ''
        }`}>
        RESPONSES
      </div>
      {reportChunks.map((chunk, index) => (
        <div
          key={index}
          className='relative'
          style={{ paddingTop: '40px', paddingBottom: '40px' }}>
          <div className='px-6'>
            {chunk.map((item, idx) => (
              <AssesmentAnswerRatingView
                selectedTemplate={item}
                key={idx}
                isPrint={true}
                assessmentName={reportViewData.assessmentName}
              />
            ))}
          </div>
          {index < reportChunks.length - 1 && (
            <div style={{ pageBreakAfter: 'always' }} />
          )}
        </div>
      ))}
      <ReportFooter isFixed={true} />
    </>
  )
}

export default CombineReportPrintViewSection
