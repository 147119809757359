import React, { useRef, useState } from 'react'
import { Toast } from '@labourhub/labour-hub-ds'
import useDeleteImage from 'api/assessment/deleteImage'
import { uploadTypes } from 'features/questionnaires/data/uploadTypes'
import { AssessmentQuestion } from 'features/questionnaires/types'

import { CustomizableImageUpload } from '../QuestionCardsColumn/DraggableQuestionCard/CustomizableImageUpload/CustomizableImageUpload'

type AddImageProps = {
  isDisabled: boolean
  setEditableQuestion: React.Dispatch<React.SetStateAction<AssessmentQuestion>>
  editableQuestion: AssessmentQuestion
  updateQuestion: (updatedQuestion: AssessmentQuestion) => void
}

const AddImage = ({
  isDisabled = true,
  setEditableQuestion,
  editableQuestion,
  updateQuestion,
}: AddImageProps) => {
  const notify = (props: any) => Toast(props)
  const fileInputUploadRef = useRef<HTMLInputElement>(null)
  const { mutate: deleteImageMutate } = useDeleteImage()

  const [imageUrl, setImageUrl] = useState<string | null>(null)

  const updateEditedQuestions = () => {
    setImageUrl(null)
    setEditableQuestion({
      ...editableQuestion,
      image: null,
      imagePath: '',
      imageUrl: '',
      uploadType: null,
      videoLink: '',
      videoPlatform: '',
    })
    updateQuestion({
      ...editableQuestion,
      image: null,
      imagePath: '',
      imageUrl: '',
      uploadType: null,
      videoLink: '',
      videoPlatform: '',
    })
  }

  const handleRemove = () => {
    if (editableQuestion.imagePath) {
      deleteImageMutate(
        { imagePath: editableQuestion.imagePath },
        {
          onSuccess: ({ data: successData }: any) => {
            notify({
              alertHeader: successData.messageTitle || 'Image was removed!',
              alertBody: '',
              status: 'Success',
            })
            updateEditedQuestions()
          },
          onError: ({ response: { data: errData } }: any) => {
            notify({
              alertHeader: errData?.messageTitle || 'Error!',
              alertBody: errData?.message || 'Error!',
              status: 'Error',
            })
          },
        },
      )
    } else {
      updateEditedQuestions()
    }
  }

  const handleReplace = () => {
    if (fileInputUploadRef.current) {
      if (editableQuestion.imagePath) {
        deleteImageMutate(
          { imagePath: editableQuestion.imagePath },
          {
            onSuccess: () => {
              setEditableQuestion({
                ...editableQuestion,
                image: null,
                imagePath: '',
                imageUrl: '',
                videoLink: '',
                videoPlatform: '',
              })
              updateQuestion({
                ...editableQuestion,
                image: null,
                imagePath: '',
                imageUrl: '',
                videoLink: '',
                videoPlatform: '',
              })
            },
            onError: ({ response: { data: errData } }: any) => {
              notify({
                alertHeader: errData?.messageTitle || 'Error!',
                alertBody: errData?.message || 'Error!',
                status: 'Error',
              })
            },
          },
        )
      } else {
        setEditableQuestion({
          ...editableQuestion,
          image: null,
          imagePath: '',
          imageUrl: '',
          videoLink: '',
          videoPlatform: '',
        })
        updateQuestion({
          ...editableQuestion,
          image: null,
          imagePath: '',
          imageUrl: '',
          videoLink: '',
          videoPlatform: '',
        })
      }
      fileInputUploadRef.current.click()
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
      setImageUrl(URL.createObjectURL(file))
      setEditableQuestion({
        ...editableQuestion,
        image: file,
        uploadType: uploadTypes.Image,
      })
      updateQuestion({
        ...editableQuestion,
        image: file,
        uploadType: uploadTypes.Image,
      })
    } else {
      console.log('No file selected')
    }

    // Reset file input value to allow re-selecting the same file
    event.target.value = ''
  }

  return (
    <div className={`w-full ${isDisabled && 'pointer-events-none'}`}>
      <CustomizableImageUpload
        isRectangleLogo={true}
        setImageUrl={setImageUrl}
        imageUrl={imageUrl}
        dropzoneDescription='Drag and drop your image here (PNG or Jpeg)
To get the best results, make sure your image width and height should be 595 * 190px'
        imageLink={editableQuestion?.imageUrl}
        imageFile={editableQuestion.image}
        setImageFile={(imageFile: any) => {
          setEditableQuestion({
            ...editableQuestion,
            image: imageFile,
            uploadType: uploadTypes.Image,
          })
        }}
      />

      {(editableQuestion.image || editableQuestion?.imagePath) && (
        <>
          <div className='flex justify-between'>
            <button
              className='text-[#4191DC] flex items-center gap-2 focus:border-0 outline-none'
              onClick={handleReplace}>
              <i className='ri-refresh-line'></i>
              <span>Replace Image</span>
            </button>
            <button
              className='text-[#DA3B2D] flex items-center gap-2 focus:border-0 outline-none'
              onClick={handleRemove}>
              <i className='ri-delete-bin-line'></i>
              <span>Remove Image</span>
            </button>
          </div>
        </>
      )}
      <input
        type='file'
        ref={fileInputUploadRef}
        accept='image/jpeg,image/png,image/jpg'
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
    </div>
  )
}

export default AddImage
