import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, TextField, Toast } from '@labourhub/labour-hub-ds'
import { useGenerateQuestionnairesSurveyTemplate } from 'api/ai'
import { setAIQuestionData } from 'features/questionnaires/store/actions'
import { ModalProps } from 'types'

import { PageLoader } from 'components/atoms'
import { MultiLableTextArea } from 'components/common/MultiLableTextArea'
import { Modal, ModalFooter } from 'components/molecules'

type TemplateCustomizeSurveyModalProps = ModalProps & {
  isRequired?: boolean
  surveyType: string
}

export const TemplateCustomizeSurveyModal = ({
  isModalActive,
  setIsModalActive,
  surveyType,
}: TemplateCustomizeSurveyModalProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const notify = (props: any) => Toast(props)
  const initialFormState = {
    surveyPurpose: '',
    noOfQuestions: 0,
    description: '',
    competencies: [],
  }
  const [formData, setFormData] = useState(initialFormState)

  const handleFormData = (name: string, value: string | string[]) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  useEffect(() => {
    handleFormData('competencies', [])
  }, [formData.surveyPurpose, formData.noOfQuestions])

  const { mutate: generateTemplate, isLoading: isTemplateLoading } =
    useGenerateQuestionnairesSurveyTemplate()

  const handleCreate = () => {
    const { surveyPurpose, noOfQuestions, description, competencies } = formData

    if (!surveyPurpose || !noOfQuestions) {
      Toast({
        alertHeader: 'Please fill in all required fields.',
        status: 'Warning',
      })
      handleFormData('competencies', [])
      return
    }
    if (noOfQuestions < 1) {
      Toast({
        alertHeader: 'Number of questions should be more than 0.',
        status: 'Warning',
      })
      handleFormData('competencies', [])
      return
    }

    generateTemplate(
      {
        surveyPurpose,
        surveyFor: surveyType,
        noOfQuestions,
        description,
        competencies,
      },
      {
        onSuccess: ({ data }: any) => {
          if (data?.aiResponse == null) {
            notify({
              alertHeader: 'Error...!',
              alertBody: data.message,
              status: 'Error',
            })
          } else {
            dispatch(setAIQuestionData(data?.aiResponse))
            navigate(`/questionnaires/new?buildMode=${surveyType}`)
            setIsModalActive(false)
          }
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  const handleClose = () => {
    setFormData(initialFormState)
    setIsModalActive(false)
  }

  return (
    <>
      <Modal
        isHeaderShow={true}
        isFooterShow={true}
        isActive={isModalActive}
        modalProps={{
          style: { width: '816px', maxHeight: '95vh' },
        }}
        onHeaderCloseButtonClick={handleClose}
        headerTitle="Let's get started"
        subHeaderTitle="Based on the information you share, we'll craft your editable template.">
        {isTemplateLoading && (
          <PageLoader
            size='xxs'
            hasBgOpacity={false}
            message={
              isTemplateLoading
                ? 'Your template is being generated...'
                : 'Generating competencies list...'
            }
          />
        )}
        <div className='flex flex-col w-full pb-5 px-6 my-5 overflow-y-auto styled-scrollbar'>
          <div className='flex flex-row justify-around items-start space-x-6'>
            <div>
              <TextField
                id={'surveyFor'}
                isRequired
                label='Survey Name'
                className='w-[360px]'
                value={formData.surveyPurpose}
                onChange={(e) =>
                  handleFormData('surveyPurpose', e.target.value)
                }
              />
              <span className='text-Gray-400 text-[12px]'>
                (Ex: Employee Engagement Survey)
              </span>
            </div>

            <TextField
              id={'noOfQuestions'}
              isRequired
              label='No of questions?'
              className='w-[360px]'
              type='number'
              value={formData.noOfQuestions}
              onChange={(e) => handleFormData('noOfQuestions', e.target.value)}
            />
          </div>
          <div className='pl-2'>
            <MultiLableTextArea
              firstLabel='Survey purpose'
              secondLabel='(Ex: I need to assess how engaged and satisfied my current employees are with their work. Some key areas of concern are cultural fit, work-life balance, and employee benefits.)'
              id={'description'}
              isRequired
              rows={5}
              value={formData.description}
              onChange={(value) => handleFormData('description', value)}
            />
          </div>
        </div>
        <ModalFooter>
          <div className='flex justify-end flex-1'>
            <Button
              textTheme='blue'
              onClick={() => {
                handleClose()
              }}
              theme='white'>
              Cancel
            </Button>
            <Button
              isLoading={isTemplateLoading}
              className='ml-3 mr-2'
              onClick={handleCreate}
              theme='cobalt'>
              Build Template
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  )
}
