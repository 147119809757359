import { forwardRef } from 'react'
import { useSelector } from 'react-redux'
import CN from 'classnames'
import { getPromotionStore } from 'features/promotions'
import { FixedSubscriptionPackageDto } from 'features/subscriptions'

import { ColumnWithAlignment } from 'components/atoms/Column'
import { Row } from 'components/atoms/Row'
import { formatCurrency } from 'utils'

type SubscriptionTypeRadioProps = {
  id?: string | undefined
  defaultChecked?: boolean
  onRadioClick?: () => void
  onChange?: any | undefined
  name?: string | undefined
  isSelected: boolean
  isMonthly?: boolean
  saveAmount?: number
  selectedFixedPackage?: FixedSubscriptionPackageDto
}

export const SubscriptionTypeRadio = forwardRef<
  HTMLInputElement,
  SubscriptionTypeRadioProps
>(
  (
    {
      id,
      onRadioClick,
      defaultChecked,
      onChange,
      name,
      isSelected = false,
      isMonthly = true,
      selectedFixedPackage,
      saveAmount,
      ...restProps
    }: SubscriptionTypeRadioProps,
    ref,
  ) => {
    const PackageTypeCardClassName = CN(
      'flex border-[2px] cursor-pointer p-4 rounded w-full justify-between px-4 py-5',
      {
        'border-[#105DA4]': isSelected,
        'border-Gray-200': !isSelected,
      },
    )

    const RadioClassName = CN(
      'form-check-input appearance-none rounded-full h-4 w-4  bg-white border border-Gray-300 checked:bg-white checked:border-Cobalt-600 checked:border-[5px] active:bg-Gray-400 focus:outline-none focus:ring focus:ring-Cobalt-300 transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer',
      {
        'hover:bg-Gray-300 ': !isSelected,
      },
    )

    const { fixedPromotion } = useSelector(getPromotionStore)

    const getPromotionDiscount = () => {
      if (fixedPromotion && fixedPromotion?.promotionType === 'Percentage')
        return fixedPromotion?.amount / 100
      return fixedPromotion?.amount
    }

    const getCurrentPackage = (item: FixedSubscriptionPackageDto) => {
      const promotionDiscount = getPromotionDiscount() || 0

      const finalAmount =
        fixedPromotion?.promotionType === 'Fixed'
          ? item.packageAmount - promotionDiscount
          : item.packageAmount * (1 - promotionDiscount)

      return (
        <>
          {promotionDiscount !== 0 && (
            <del className=' text-Red-600'>
              {formatCurrency(item.packageAmount)}
            </del>
          )}
          <p>
            {formatCurrency(finalAmount)}{' '}
            <span className='text-extra-small font-[300]'>
              Per {isMonthly ? 'Month' : 'Year'}
            </span>
          </p>
        </>
      )
    }

    return (
      <label htmlFor={id} className={PackageTypeCardClassName}>
        <div className='flex flex-col w-9/12 right-side'>
          <div className='flex items-center justify-start'>
            <input
              id={id}
              onChange={onChange}
              ref={ref}
              type='radio'
              onClick={onRadioClick}
              className={RadioClassName}
              defaultChecked={defaultChecked}
              name={name}
              {...restProps}
            />
            <div className='text-heading-4 font-Bold text-Gray-700 pl-[5px]'>
              {isMonthly ? 'Monthly' : 'Annually'}
            </div>
          </div>

          {selectedFixedPackage && (
            <Row>
              <ColumnWithAlignment
                itemAlignment='start'
                justifyAlignment='start'
                className='w-full mt-2'>
                {getCurrentPackage(selectedFixedPackage)}
              </ColumnWithAlignment>
            </Row>
          )}
        </div>

        {!isMonthly && saveAmount && saveAmount > 0 && (
          <div className='flex h-fit py-[5px] px-2 rounded-md border-[1px] border-[#DD7F0B] font-Bold text-extra-small text-[#DD7F0B]'>
            Save ${saveAmount.toFixed(2)}
          </div>
        )}
      </label>
    )
  },
)
