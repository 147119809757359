import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

export enum PoliceCheckType {
  EMPLOYMENT = 'EMPLOYMENT',
  EMPLOYMENT_HEALTH = 'EMPLOYMENT_HEALTH',
  VOLUNTEER = 'VOLUNTEER',
  VOLUNTEER_HEALTH = 'VOLUNTEER_HEALTH',
  WORK_EXP = 'WORK_EXP',
  WORK_EXP_HEALTH = 'WORK_EXP_HEALTH',
  ACCREDITATION = 'ACCREDITATION',
  OTHER = 'OTHER',
}

const getPoliceCheckDetails = async ({ queryKey }: any) => {
  const reqBody = queryKey[1]
  const res = await get(`/NCC/PoliceChecks/${reqBody.Id}`)
  return res
}

export const useGetPoliceCheckDetails = ({ ...reqBody }, onSuccess: any) => {
  return useQuery(['policeCheck', reqBody], getPoliceCheckDetails, {
    enabled: false,
    refetchOnWindowFocus: false,
    retry: 1,
    onSuccess,
  })
}
