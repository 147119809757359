import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

export const useGetAgencyDetailsById = (
  id: string,
  onSuccess: (data: any) => void,
) => {
  return useQuery(
    ['AgencyProfile', id],
    async () => {
      const res = await get(`/AgencyProfile/${id}`)
      return res
    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
      onSuccess,
      onError: () => {
        Toast({
          alertHeader: 'Error fetching agency profile details',
          status: 'Error',
        })
      },
    },
  )
}
