import { useState } from 'react'
import { MappedVariableRequest, useGetMappedVariables } from 'api/templates'

export const useDynamicFieldMapping = ({
  candidateId,
  refereeId,
}: MappedVariableRequest) => {
  const [dynamicFieldMapping, setDynamicFieldMapping] = useState<Record<
    string,
    string
  > | null>(null)

  const { isLoading, isFetching } = useGetMappedVariables(
    { candidateId, refereeId },
    (data) => {
      setDynamicFieldMapping(data)
    },
  )

  const changeTextSchema = (text: any) => {
    if (!dynamicFieldMapping) {
      return text
    }

    Object.entries(dynamicFieldMapping).forEach(([code, value]) => {
      text = text.replaceAll(`@${code}`, value)
    })

    return text
  }

  const changeJsonSchema = (schema: any) => {
    if (!dynamicFieldMapping) {
      return schema
    }

    const properties = { ...schema.properties }

    Object.keys(properties).forEach((key) => {
      const property = properties[key]

      Object.entries(dynamicFieldMapping).forEach(([code, value]) => {
        if (property?.title !== '') {
          property.title = property.title.replaceAll(`@${code}`, value)
        }

        if (property?.title === '' && property?.description !== '') {
          property.description = property.description.replaceAll(
            `@${code}`,
            value,
          )
        }
      })
    })

    return {
      ...schema,
      properties,
    }
  }

  return {
    dynamicFieldMapping,
    changeJsonSchema,
    changeTextSchema,
    isMappingVariablesLoading: isLoading || isFetching,
  }
}
