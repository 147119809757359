import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, ProfileCard, Tabs, Toast } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import {
  useCreateUserProfilePicture,
  useDeleteUserProfilePicture,
  useGetUserProfileDetailsById,
} from 'framework/api/methods'
import { setIsHeaderBarDetailsUpdated } from 'store/reducers/user/userSlice'

import { CustomizableEditProfilePictureModal } from 'components/molecules'

import {
  ContactTabView,
  PersonalTabView,
  SecurityTabView,
} from './userProfileTabs'

type UserProfileProps = {
  [x: string]: any
}

export const UserProfile = ({ className, ...restProps }: UserProfileProps) => {
  const dispatch = useDispatch()
  const { userDetails, isHeaderBarDetailsUpdated }: any = useSelector<any>(
    (state) => state.user,
  )

  const [files, setFiles] = useState<any>()
  const [profileCardData, setProfileCardData] = useState({
    jobTitle: '',
    avatarImageUrl: '' || undefined,
    fullName: '',
    email: '',
  })
  const [refetch, setRefetch] = useState(false)
  const [showEditProfilePictureModal, setShowEditProfilePictureModal] =
    useState(false)
  const [showDeleteProfilePictureModal, setShowDeleteProfilePictureModal] =
    useState(false)
  const notify = (props: any) => Toast(props)
  const [imageUrl, setImageUrl] = useState<any>('')

  /**logged user details on success*/
  const getUserDetailsOnSuccess = (data: any) => {
    setImageUrl('')
    setProfileCardData({
      jobTitle: data?.jobTitle,
      avatarImageUrl: data?.avatarUrl ? data?.avatarUrl : undefined,
      fullName: data?.firstName + ' ' + data?.lastName,
      email: data?.email,
    })
  }

  /**Fetch logged user details */
  const { refetch: getUserProfileDetailsById, data: userData } =
    useGetUserProfileDetailsById(userDetails.id, getUserDetailsOnSuccess)

  /** use effect for rerender user details */
  useEffect(() => {
    userDetails.id && getUserProfileDetailsById()
  }, [userDetails, refetch])

  /** API Call for delete user avatar using react-query */
  const {
    mutate: deleteUserProfilePictureMutate,
    isLoading: deleteUserProfilePictureIsLoading,
  } = useDeleteUserProfilePicture()

  /** Process the Delete User Avatar */
  async function deleteUserProfileAvatar(Id: string) {
    deleteUserProfilePictureMutate(
      { Id },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData.messageTitle
              ? successData.messageTitle
              : 'Profile photo was removed!',
            alertBody: '',
            status: 'Success',
          })
          getUserProfileDetailsById()
          setShowDeleteProfilePictureModal(false)
          dispatch(setIsHeaderBarDetailsUpdated(!isHeaderBarDetailsUpdated))
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: errData?.messageTitle
              ? errData?.messageTitle
              : 'Error!',
            alertBody: errData?.message ? errData?.message : 'Error!',
            status: 'Error',
          })
          setShowDeleteProfilePictureModal(false)
        },
      },
    )
  }

  const tabDetails = [
    {
      id: 1,
      tabButtonContent: 'Personal',
      tabContent: (
        <PersonalTabView
          userData={userData}
          getUserProfileDetailsById={getUserProfileDetailsById}
          refetch={refetch}
          setRefetch={setRefetch}
          notify={notify}
        />
      ),
    },
    {
      id: 2,
      tabButtonContent: 'Contact',
      tabContent: (
        <ContactTabView
          userData={userData}
          getUserProfileDetailsById={getUserProfileDetailsById}
          notify={notify}
        />
      ),
    },
    {
      id: 3,
      tabButtonContent: 'Security',
      tabContent: (
        <SecurityTabView
          userData={userData}
          getUserProfileDetailsById={getUserProfileDetailsById}
          notify={notify}
        />
      ),
    },
  ]

  /** upload profile picture api */
  const {
    mutate: UploadProfilePictureMutate,
    isLoading: uploadProfilePictureIsLoading,
  } = useCreateUserProfilePicture()

  /** Process the upload profile picture */
  async function UploadProfilePicture(Id: any, avatarObject: any) {
    UploadProfilePictureMutate(
      {
        Id,
        avatarObject,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData.messageTitle
              ? successData.messageTitle
              : 'Profile photo updated!',
            alertBody: successData?.message
              ? successData.message
              : `We've uploaded your profile photo. It may take a few minutes to display everywhere.`,
            status: 'Success',
          })
          setShowEditProfilePictureModal(false)
          getUserProfileDetailsById()

          /** updated header bar details */
          dispatch(setIsHeaderBarDetailsUpdated(!isHeaderBarDetailsUpdated))
        },
        onError: ({ response: { data: errData } }: any) => {
          setShowEditProfilePictureModal(false)
          notify({
            alertHeader: errData?.messageTitle
              ? errData?.messageTitle
              : 'Error!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** API Call for upload profile picture using react-query */
  const handleUploadProfileAvatar = (files: any) => {
    UploadProfilePicture(userDetails.id, files)
  }

  const UserProfileClasses = CN(`user-profile flex`, className)

  return (
    <div className={UserProfileClasses} {...restProps}>
      <div className='w-2/3 bg-white border rounded-md left-side-section h-fit border-Gray-200'>
        <Tabs
          className='h-full pt-6'
          contentClassName='h-full pt-3 pb-1 overflow-y-auto '
          defaultTabId={1}
          tabDetails={tabDetails}
        />
      </div>

      <div className='w-1/3 ml-5 right-side-section'>
        <ProfileCard
          className='bg-white'
          imageSource={profileCardData?.avatarImageUrl}
          userEmail={profileCardData?.email}
          userName={profileCardData?.fullName}
          group={userDetails?.roleName || '-'}
          jobTitle={profileCardData?.jobTitle || '-'}
          isProfilePictureAvailable={
            profileCardData?.avatarImageUrl ? true : false
          }
          onAddClick={() => {
            setShowEditProfilePictureModal(true)
          }}
          onEditClick={() => {
            setShowEditProfilePictureModal(true)
          }}
          onDeleteClick={() => {
            setShowDeleteProfilePictureModal(true)
          }}
        />
      </div>

      <CustomizableEditProfilePictureModal
        isActive={showEditProfilePictureModal}
        onHeaderCloseButtonClick={() => {
          setShowEditProfilePictureModal(false)
        }}
        onClickSecondaryBtn={() => {
          setShowEditProfilePictureModal(false)
        }}
        onOverlayClick={() => {
          setShowEditProfilePictureModal(false)
        }}
        onClickPrimaryBtn={() => {
          handleUploadProfileAvatar(files)
        }}
        imageUrl={imageUrl}
        setImageUrl={setImageUrl}
        setFiles={setFiles}
        headerTitle={
          profileCardData?.avatarImageUrl
            ? 'Change profile photo'
            : 'Add profile photo'
        }
        headerSubTitle='Update your profile photo here.'
        primaryButtonIsLoading={uploadProfilePictureIsLoading}
      />

      {/* delete user profile picture modal */}
      <Modal
        isActive={showDeleteProfilePictureModal}
        onClickPrimaryBtn={() => {
          deleteUserProfileAvatar(userDetails.id)
        }}
        onClickSecondaryBtn={() => {
          setShowDeleteProfilePictureModal(false)
        }}
        primaryButtonProps={{
          style: { width: '100%', marginRight: '12px' },
          isLoading: deleteUserProfilePictureIsLoading,
        }}
        secondaryButtonProps={{
          style: { width: '100%', color: '#1F2937' },
        }}
        onOverlayClick={() => {
          setShowDeleteProfilePictureModal(false)
        }}
        modalProps={{
          style: { width: '466px' },
        }}
        footerProps={{
          style: {
            justifyContent: 'space-between',
            paddingLeft: '23px',
            paddingRight: '23px',
          },
        }}
        isHeaderShow={false}
        primaryButtonTitle='Yes, I’m Sure'
        secondaryButtonTitle='Cancel'>
        <div className='flex flex-col items-center justify-center p-6'>
          <div className='flex items-center justify-center w-12 h-12 rounded-full bg-Red-100'>
            <i className='ri-delete-bin-6-line text-Red-500 text-heading-3'></i>
          </div>

          <span className='pt-5 text-Gray-900 text-heading-5 font-Medium'>
            Delete Profile Picture?
          </span>

          <span className='pt-2 text-center text-Gray-500 text-small font-Regular'>
            Are you sure you want to proceed?
          </span>
        </div>
      </Modal>
    </div>
  )
}
