import { agencyReducer } from 'features/agencies'
import { authReducer } from 'features/auth/store'
import { candidateReducer } from 'features/candidates'
import { onboardingReducer } from 'features/onboarding'
import { paymentReducer } from 'features/payments'
import { promotionReducer } from 'features/promotions'
import { reducer } from 'features/questionnaires/store/'
import { settingsReducer } from 'features/settings'
import { subscriptionReducer } from 'features/subscriptions'
import { combineReducers } from 'redux'

import breadcrumbsReducer from './breadcrumbs/breadcrumbs'
import dashboardReducer from './dashboard/dashboardSlice'
import globalReducer from './global/globalSlice'
import layOutReducer from './layout/layoutSlice'
import mainSideMenuReducer from './side-menu/mainSideMenuReducer'
import userReducer from './user/userSlice'

const appReducer = combineReducers({
  agency: agencyReducer,
  auth: authReducer,
  breadcrumbs: breadcrumbsReducer,
  candidate: candidateReducer,
  dashboard: dashboardReducer,
  layout: layOutReducer,
  onboarding: onboardingReducer,
  payment: paymentReducer,
  promotion: promotionReducer,
  questionnaire: reducer,
  settings: settingsReducer,
  sideMenuList: mainSideMenuReducer,
  subscription: subscriptionReducer,
  user: userReducer,
  global: globalReducer,
})

export const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined
  }

  return appReducer(state, action)
}

// Define RootState using ReturnType utility type
export type RootState = ReturnType<typeof appReducer>
