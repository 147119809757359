import { getS3File } from 'utils'

import {
  CompanyBrandingLogoSection,
  CompanyContactInformationSection,
  CompanyLocationSection,
  InviteColleagueSection,
  RefHubSettingsSectionOne,
  RefHubSettingsSectionTwo,
} from '../components/SignUpStepper/sections'
import { SignupStepperComponent } from '../types'

export const optionalSignupStepperComponents: SignupStepperComponent[] = [
  {
    component: <CompanyBrandingLogoSection />,
    backgroundImage: getS3File('brandingLogos.svg'),
    isActionsClickable: true,
    header: 'Customize Ref Hub with Your Branding',
    description:
      "With Ref Hub, your company's brand stays front and center. Ensure brand consistency and tap into new marketing opportunities by customizing your outreach.",
  },
  {
    component: <CompanyContactInformationSection />,
    backgroundImage: getS3File('contactInformation.svg'),
    isActionsClickable: true,
    header: 'Add your Contact Information ',
    description:
      "Candidates and referees will have access to your contact details. This ensures that if they have any questions, or if they'd simply prefer a direct chat, they can easily reach out to you.",
  },
  {
    component: <CompanyLocationSection />,
    backgroundImage: getS3File('companyLocation.svg'),
    isActionsClickable: true,
    header: 'Share Your Location',
    description:
      'Showcasing your office location can instill trust and ensure your candidates and referees are comfortable when submitting their details to you.',
  },
  {
    component: <InviteColleagueSection />,
    backgroundImage: getS3File('colleagues.svg'),
    isActionsClickable: true,
    header: 'Invite Colleagues',
    description:
      'Review user performance and monitor key metrics. Collaborate on reviewing results to make informed hiring decisions.',
  },
  {
    component: <RefHubSettingsSectionOne />,
    backgroundImage: getS3File('companySettingsOne.svg'),
    isActionsClickable: true,
    header: 'Generate New Business and Candidates',
    description:
      "Utilize Ref Hub's lead generation tools to announce your hiring needs. If you're a recruitment agency, inform referees that you can assist with staffing solutions.",
  },
  {
    component: <RefHubSettingsSectionTwo />,
    backgroundImage: getS3File('companySettingsTwo.svg'),
    isActionsClickable: true,
    header: 'Auto Referencing',
    description:
      "Speed up your hiring process with Ref Hub's auto-referencing. Once candidates are added, our system instantly requests referee details, and upon confirmation, automatically dispatches reference surveys.",
  },
]
