import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button } from '@labourhub/labour-hub-ds'
import {
  QuestionnaireCategoryType,
  useGetDynamicForm,
} from 'api/questionnaires'
import {
  getOnboardingStore,
  setCurrentStep,
  setShowGuideModal,
} from 'features/onboarding/store'

import { PageLoader } from 'components/atoms'
import { FormBuilder } from 'components/molecules'

export const PreviewReferenceCheckTemplate = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { currentStep, guideStepperPreviewTemplate, showGuideModal } =
    useSelector(getOnboardingStore)

  /** get dynamic form */
  const {
    data: dynamicForm,
    isLoading,
    isFetching,
    refetch,
  } = useGetDynamicForm(guideStepperPreviewTemplate?.id ?? '')

  useEffect(() => {
    if (!guideStepperPreviewTemplate?.id) {
      dispatch(setCurrentStep(currentStep + 1))
    } else {
      refetch()
    }
  }, [showGuideModal, guideStepperPreviewTemplate?.id])

  return (
    <div className='w-full'>
      {(isFetching || isLoading) && <PageLoader size='xxs' />}

      <div className='flex flex-col w-[94%] justify-start border border-Gray-200 rounded-md bg-Gray-50 p-5 mx-5 max-h-[62vh] overflow-y-auto styled-scroll'>
        {/* form builder */}
        {dynamicForm && (
          <FormBuilder
            schema={JSON.parse(dynamicForm.jsonSchema)}
            uiSchema={JSON.parse(dynamicForm.jsonUiSchema)}
            readonly={true}
          />
        )}
      </div>
      <div className='flex flex-col justify-center px-5 mt-5'>
        {guideStepperPreviewTemplate?.id != '' && (
          <Button
            onClick={() => {
              dispatch(setShowGuideModal(false))
              navigate(
                `/questionnaires/${guideStepperPreviewTemplate?.id}/edit?buildMode=${QuestionnaireCategoryType.ReferenceCheck}&builderType=guideStepper`,
              )
            }}
            textTheme='black'
            theme='white'
            className='mb-2 cursor-pointer'>
            Edit Template
          </Button>
        )}
        <Button
          onClick={() => {
            dispatch(setCurrentStep(currentStep + 1))
          }}
          className='mt-2 cursor-pointer'>
          Next
        </Button>
      </div>
    </div>
  )
}
