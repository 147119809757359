export const getInitials = (
  firstNameOrFullname: string,
  lastName?: string,
): string => {
  let firstName = ''
  let fullName = firstNameOrFullname

  if (lastName !== undefined) {
    firstName = firstNameOrFullname
    fullName = `${firstName} ${lastName}`
  } else {
    const nameParts = firstNameOrFullname?.split(' ')
    if (nameParts?.length > 0) {
      firstName = nameParts[0]
    }
  }

  const nameParts = fullName.split(' ')

  if (nameParts.length > 0) {
    const lastName = nameParts[nameParts.length - 1]
    return `${firstName.charAt(0)}${lastName.charAt(0)}`
  }

  return ''
}
