import { useMemo, useState } from 'react'
import { Button } from '@labourhub/labour-hub-ds'
import { CandidateProfileBasicDetailsType } from 'api/candidates'
import { assessmentReportPreviewData } from 'features/candidates/data/assessmentReportPreviewData'
import { ModalProps } from 'types'

import { Column } from 'components/atoms/Column'
import { Modal, ModalFooter } from 'components/molecules'

import { ReportLogoHeader } from '../../sections'
import {
  AssessmentReportCandidateDetails,
  AssessmentReportCandidateDetailsDto,
} from '../../sections/ReportCandidateDetails/AssessmentReportCandidateDetails'

type AssessmentSnapsPreviewProps = ModalProps & {
  candidateDetails: CandidateProfileBasicDetailsType
  previewData: assessmentReportPreviewData | undefined
  images: any
}

export const AssessmentSnapsPreview = ({
  candidateDetails,
  previewData,
  setIsModalActive,
  isModalActive,
  images,
}: AssessmentSnapsPreviewProps) => {
  const [imageLoadStates, setImageLoadStates] = useState<boolean[]>([])

  const handleImageLoad = (index: number) => {
    setImageLoadStates((prev) => {
      const newStates = [...prev]
      newStates[index] = true
      return newStates
    })
  }

  useMemo(() => {
    setImageLoadStates(new Array(images.length).fill(false))
  }, [images])

  const candidateReportDetails = useMemo(() => {
    const {
      name,
      candidateNo,
      email,
      phone,
      avatarUrl,
      jobTitle,
      consultantName,
    } = candidateDetails
    return {
      name,
      candidateNo,
      email,
      phoneNumber: phone,
      avatarUrl,
      jobTitle,
      consultantName,
    } as AssessmentReportCandidateDetailsDto
  }, [candidateDetails])

  const handleClose = () => {
    setIsModalActive(false)
  }

  return (
    <Modal
      headerTitle={`Assessment Report Snapshots Preview`}
      isFooterShow={true}
      isActive={isModalActive}
      className='!z-[999]'
      onHeaderCloseButtonClick={handleClose}
      onOverlayClick={handleClose}
      modalProps={{
        style: { width: '800px', maxHeight: '90vh' },
      }}>
      <div className='flex flex-col p-0 overflow-x-hidden overflow-y-auto styled-scroll bg-Gray-50'>
        <Column className='bg-Gray-50 w-[794px] pointer-events-none'>
          <div className='h-auto'>
            <ReportLogoHeader
              clientlogo={previewData?.clientLogoUrl}
              hasClientLogo={previewData?.hasClientLogo}
              hasCompanyLogo={previewData?.hasCompanyLogo}
            />
            <div className='m-3'>
              <AssessmentReportCandidateDetails
                details={candidateReportDetails}
              />
            </div>
          </div>

          <div className='p-6'>
            <div className='font-SemiBold text-heading-5 leading-4 my-4'>
              Snapshots
            </div>
            <div className='grid grid-cols-2 gap-4'>
              {images.map((image, idx) => (
                <div key={idx} className='relative w-[360px] h-[175px]'>
                  {!imageLoadStates[idx] && (
                    <div className='absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 z-10'>
                      <div className='w-12 h-12 border-4 border-Blue-500 border-t-transparent border-solid rounded-full animate-pulse200'></div>
                    </div>
                  )}
                  <img
                    src={image.imageUrl}
                    alt={`Snapshot ${idx + 1}`}
                    className={`w-full h-full object-cover rounded ${
                      !imageLoadStates[idx] ? 'opacity-0' : 'opacity-100'
                    }`}
                    onLoad={() => handleImageLoad(idx)}
                  />
                  <div className='absolute top-3 right-3 bg-Gray-100 text-Gray-800 font-Medium text-[10px] leading-4 px-2 py-1 rounded-full'>
                    {image.time}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Column>
      </div>

      <ModalFooter>
        <div className='flex justify-end flex-1'>
          <Button onClick={handleClose} textTheme='blue' theme='white'>
            Close
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}
