import { useMutation } from 'react-query'
import { deleteAPI } from 'framework/api/http'

export type DeleteClientRequest = {
  id: string
}

export const useDeleteClient = () => {
  const mutate = useMutation(({ id }: DeleteClientRequest) => {
    return deleteAPI(`/clients/${id}`)
  })

  return mutate
}
