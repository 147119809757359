import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

import { PromotionDto } from '../types'

export const useGetPromotionByCode = (
  isEnabled: boolean,
  promocode: string,
  onSuccess: (promotion: PromotionDto) => void,
  onError: (err: any) => void,
) => {
  return useQuery(
    ['promotions_payg', promocode],
    async (): Promise<PromotionDto> => {
      const res = await get(`/promotions/payg/${promocode}`)
      return res.data.promotion
    },
    {
      enabled: isEnabled && promocode != '',
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess,
      onError,
    },
  )
}
