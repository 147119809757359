export type ContentSplitterProps = {
  content: string
  textForSplit: string
}

const ContentSplitter = ({ content, textForSplit }: ContentSplitterProps) => {
  const textIndex = content.indexOf(textForSplit)
  return (
    <div>
      {textIndex !== -1 ? (
        <>
          {content.slice(0, textIndex)}
          <br />
          {content.slice(textIndex)}
        </>
      ) : (
        content
      )}
    </div>
  )
}

export default ContentSplitter
