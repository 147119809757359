import { useState } from 'react'
import ReactQuill from 'react-quill'
import CN from 'classnames'

import 'react-quill/dist/quill.snow.css'
import './TextEditor.scss'

export type TextEditorProps = {
  onContentChange?: (content: string) => void
  /** Default content in html format eg: '<p>Please type in here</p>' */
  defaultContent?: string
  readOnly?: boolean
  hideToolbar?: boolean
  value?: string
  [x: string]: any
}

export const TextEditor = ({
  className,
  onContentChange,
  defaultContent,
  readOnly,
  hideToolbar,
  value,
  ...restProps
}: TextEditorProps) => {
  const TextEditorClasses = CN(`text-editor h-full  w-full`, className)

  const [content, setContent] = useState<string | undefined>(value)

  /*
   * Quill modules to attach to editor
   * See https://quilljs.com/docs/modules/ for complete options
   */
  const modules = {
    toolbar: hideToolbar
      ? null
      : [
          [{ header: '1' }, { header: '2' }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [
            { list: 'ordered' },
            { list: 'bullet' },
            {
              background: [
                '#000000',
                '#e60000',
                '#ff9900',
                '#ffff00',
                '#008a00',
                '#0066cc',
                '#9933ff',
                '#ffffff',
                '#facccc',
                '#ffebcc',
                '#ffffcc',
                '#cce8cc',
                '#cce0f5',
                '#ebd6ff',
                '#bbbbbb',
                '#f06666',
                '#ffc266',
                '#ffff66',
                '#66b966',
                '#66a3e0',
                '#c285ff',
                '#888888',
                '#a10000',
                '#b26b00',
                '#b2b200',
                '#006100',
                '#0047b2',
                '#6b24b2',
                '#444444',
                '#5c0000',
                '#663d00',
                '#666600',
                '#003700',
                '#002966',
                '#3d1466',
              ],
            },
            {
              color: [
                '#000000',
                '#e60000',
                '#ff9900',
                '#ffff00',
                '#008a00',
                '#0066cc',
                '#9933ff',
                '#ffffff',
                '#facccc',
                '#ffebcc',
                '#ffffcc',
                '#cce8cc',
                '#cce0f5',
                '#ebd6ff',
                '#bbbbbb',
                '#f06666',
                '#ffc266',
                '#ffff66',
                '#66b966',
                '#66a3e0',
                '#c285ff',
                '#888888',
                '#a10000',
                '#b26b00',
                '#b2b200',
                '#006100',
                '#0047b2',
                '#6b24b2',
                '#444444',
                '#5c0000',
                '#663d00',
                '#666600',
                '#003700',
                '#002966',
                '#3d1466',
              ],
            },
          ],
          ['link'],
          ['clean'],
        ],

    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  }
  /*
   * Quill editor formats
   * See https://quilljs.com/docs/formats/
   */
  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'color',
    'background',
  ]

  return (
    <div className={TextEditorClasses} {...restProps}>
      <ReactQuill
        readOnly={readOnly}
        className='h-full'
        theme='snow'
        value={content}
        modules={modules}
        formats={formats}
        placeholder={defaultContent || 'Type in your custom message here...'}
        onChange={(val) => {
          setContent(val)
          if (onContentChange) {
            onContentChange(val)
          }
        }}
      />
    </div>
  )
}
