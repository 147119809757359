import { useEffect, useState } from 'react'

import Characters from 'components/molecules/AddonPreviewModal/TypedTestResultReport/Characters'
import InputHistory from 'components/molecules/AddonPreviewModal/TypedTestResultReport/InputHistory'
import ResultChart from 'components/molecules/AddonPreviewModal/TypedTestResultReport/ResultChart'
import TestStats from 'components/molecules/AddonPreviewModal/TypedTestResultReport/TestStats'
import { ChartData } from 'components/ui/reports/addonReportsPreviewModal/TypingTestAddon/TypingTestAddonPreviewReport'
import {
  CombinedReportNameHeader,
  ReportFooter,
} from 'components/ui/reports/sections'

import AntiCheatinMonitor from '../AntiCheatinMonitor'
import AssessmentCompletedSection from '../DownloadReport/AssessmentCompletedSection'

type TypingTestCombineReportPreviewSectionProps = {
  reportViewData: any
  isPreview: boolean
  position?: any
}

const TypingTestCombineReportPreviewSection = ({
  reportViewData,
  isPreview,
  position,
}: TypingTestCombineReportPreviewSectionProps) => {
  const [chartDataArray, setChartDataArray] = useState<ChartData[]>([])
  const [typedHistory, setTypedHistory] = useState<string[]>([])
  const [wordList, setWordList] = useState<string[]>([])
  const [duration, setDuration] = useState(0)

  useEffect(() => {
    const data = JSON.parse(reportViewData.jsonData)
    if (data && Object.keys(data).length > 0) {
      setWordList(data.wordList || [])
      setTypedHistory(data.typedHistory || [])
      setChartDataArray(data.chartDataArray || [])
      setDuration(data.chartDataArray?.length || 0)
    }
  }, [reportViewData])

  return (
    <>
      <div
        className='m-3'
        style={{
          pageBreakBefore: position !== 1 ? 'always' : 'avoid',
        }}>
        <div className={`${!isPreview && position !== 1 ? 'pt-8' : ''}`}>
          {position !== 1 && <CombinedReportNameHeader data={reportViewData} />}
        </div>

        <AssessmentCompletedSection reportViewData={reportViewData} />
        <div
          className={`my-4 ${!isPreview && position === 1 ? 'mt-10' : ''}`}
          style={{
            pageBreakBefore: !isPreview && position === 1 ? 'always' : 'avoid',
          }}>
          <AntiCheatinMonitor isReport={true} reportViewData={reportViewData} />
        </div>
      </div>

      <div className='w-fit h-fit'>
        <ResultChart chartDataArray={chartDataArray} isReport={true} />
        <TestStats
          chartDataArray={chartDataArray}
          duration={duration}
          typedHistory={typedHistory}
          wordList={wordList}
          isReport={true}
        />
        <div className={`${!isPreview && position !== 1 ? 'pt-8' : ''}`}>
          <Characters
            typedHistory={typedHistory}
            wordList={wordList}
            isReport={true}
          />
          <div
            className={`${!isPreview && position === 1 ? 'pt-8' : ''}`}
            style={{
              pageBreakBefore:
                !isPreview && position === 1 ? 'always' : 'avoid',
            }}>
            <InputHistory
              typedHistory={typedHistory}
              wordList={wordList}
              isReport={true}
            />
          </div>
        </div>
      </div>
      {!isPreview && <ReportFooter isFixed={true} />}
    </>
  )
}

export default TypingTestCombineReportPreviewSection
