export const NoteIcon = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <rect width='32' height='32' rx='16' fill='#881798' />
      <path
        d='M24 19L19 23.9967L9.835 24C9.61457 24.0009 9.40281 23.9142 9.24624 23.7591C9.08967 23.6039 9.0011 23.3929 9 23.1725V9.8275C9 9.37083 9.37083 9 9.8275 9H23.1725C23.6292 9 24 9.38 24 9.835V19ZM22.3333 10.6667H10.6667V22.3333H17.3333V18.1667C17.3334 17.9626 17.4083 17.7656 17.5439 17.613C17.6796 17.4605 17.8665 17.363 18.0692 17.3392L18.1667 17.3333L22.3333 17.3325V10.6667ZM21.6425 18.9992L19 19V21.6408L21.6425 18.9992Z'
        fill='white'
      />
    </svg>
  )
}
