import { useEffect, useState } from 'react'

export type RemainingCreditsBadgeProps = {
  totalQuota: number
  remainingCredit: number
}

export const RemainingCreditsBadge = ({
  remainingCredit,
  totalQuota,
}: RemainingCreditsBadgeProps) => {
  const [quotaValue, setQuotaValue] = useState(remainingCredit)

  useEffect(() => {
    setQuotaValue(
      remainingCredit > 0 ? Math.min(remainingCredit, totalQuota) : 0,
    )
  }, [totalQuota, remainingCredit])

  return (
    <>
      <span className='text-Gray-800 font-Bold text-heading-5'>
        {quotaValue}
      </span>
    </>
  )
}
