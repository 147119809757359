import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const getAssessmentSendList = async ({ queryKey }: any) => {
  const reqBody = queryKey[1]
  let URI = `assessment/send/list?CandidateId=${reqBody.candidateId}&Take=${reqBody.take}&Skip=${reqBody.skip}`
  if (reqBody.search) {
    URI += `&Search=${reqBody.search}`
  }
  if (reqBody.difficultyLevel) {
    URI += `&difficultyLevel=${reqBody.difficultyLevel}`
  }
  URI += `&Type=${reqBody.type ? reqBody.type : 'Assessment'}`

  const res = await get(URI)
  return res.data
}

export const useGetAssessmentSendList = (
  { ...reqBody },
  onSuccess: any,
  onError: any,
) => {
  return useQuery(['AssessmentSendList', reqBody], getAssessmentSendList, {
    enabled: true,
    refetchOnWindowFocus: false,
    onSuccess,
    onError,
  })
}
