import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import { Button, Radio, SimpleSelect, Toast } from '@labourhub/labour-hub-ds'
import { CandidateProfileBasicDetailsType } from 'api/candidates'
import { useGetClientLogo, useGetClientSelectOptions } from 'api/clients'
import { QuestionnaireCategoryType } from 'api/questionnaires'
import { SurveyReportPreviewData } from 'features/candidates/data'
import { useDynamicFieldMapping } from 'hooks'
import { ModalProps, SelectOptionDto } from 'types'

import { Accordion } from 'components/atoms'
import { Column } from 'components/atoms/Column'
import { Row } from 'components/atoms/Row'
import { Modal, ModalFooter } from 'components/molecules'
import { SurveyResponseReportPrintPreview } from 'components/ui/reports'

type CandidateReportDownloadModalProps = ModalProps & {
  candidateDetails: CandidateProfileBasicDetailsType
  selectedResponse: any
}

export const CandidateReportDownloadModal = ({
  isModalActive,
  setIsModalActive,
  candidateDetails,
  selectedResponse,
}: CandidateReportDownloadModalProps) => {
  const notify = (props: any) => Toast(props)

  const [logoUrl, setLogoUrl] = useState<string | undefined>(undefined)
  const [clientOptions, setClientOptions] = useState<SelectOptionDto[]>([])
  const [previewData, setPreviewData] = useState<
    SurveyReportPreviewData | undefined
  >(undefined)
  const [selectedClientOption, setSelectedClientOption] =
    useState<SelectOptionDto | null>(null)

  const { dynamicFieldMapping, changeTextSchema } = useDynamicFieldMapping({
    candidateId: candidateDetails.candidateId,
  })

  const [isPrinting, setIsPrinting] = useState(false)

  const componentRef = useRef<any>(null)
  const promiseResolveRef = useRef<any>(null)

  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      promiseResolveRef.current()
    }
  }, [isPrinting, 'isDownlaodSurveyReport'])

  const pageStyle = ` 
    @media print {
      @page {
        size: a4 !important;
        margin: 0 !important;
        padding: 0 !important;
      }

      body {
        margin: 0 !important;
        padding: 0 !important;
      }
    }
  `
  const handlePrint = useReactToPrint({
    pageStyle,
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        promiseResolveRef.current = resolve
        setIsPrinting(true)
      })
    },
    onBeforePrint: () => setIsModalActive(false),
    onAfterPrint: () => {
      promiseResolveRef.current = null
      setIsPrinting(false)
    },
  })

  const [renderKeys, setRenderKeys] = useState<any>({
    reportType: 0,
    reportSetting: 0,
  })

  const [formBody, setFormBody] = useState({
    reportType: '',
    reportSetting: 'companyBrandOnly',
  })

  const { refetch: refreshClientSelectOptions } = useGetClientSelectOptions(
    '',
    (options) => setClientOptions(options),
  )

  useGetClientLogo(selectedClientOption?.value ?? '', (data) =>
    setLogoUrl(data.logoUrl),
  )

  useEffect(() => {
    if (isModalActive) {
      refreshClientSelectOptions()
      setRenderKeys({
        ...renderKeys,
        cancellation: Math.random(),
        reason: Math.random(),
      })
    }
  }, [isModalActive])

  const closeModal = () => {
    setFormBody({
      reportType: '',
      reportSetting: 'companyBrandOnly',
    })
    setRenderKeys({
      ...renderKeys,
      reportType: Math.random(),
      reportSetting: Math.random(),
    })
    setIsModalActive(false)
  }

  const renderClientDetail = () => {
    return (
      <Column>
        <Row className='w-full space-x-4'>
          <SimpleSelect
            value={{
              label: selectedClientOption?.label,
              value: selectedClientOption?.value,
            }}
            onChange={(selectedItem: any) => {
              setSelectedClientOption(selectedItem)
              setLogoUrl(undefined)
            }}
            label='Client List'
            options={clientOptions}
            placeholder='Select a Client'
            className='min-w-[225px]'
          />
          {logoUrl && (
            <img
              src={logoUrl}
              alt=''
              className='rounded h-[60px] w-[200px] mt-1'
            />
          )}
        </Row>
        <Row className='w-full space-x-4'>
          <Link to='/settings?tab=4' className='mt-2 font-Medium text-Blue-700'>
            Add New Client
          </Link>
        </Row>
      </Column>
    )
  }

  const resetClientOption = () => {
    setSelectedClientOption(null)
    setLogoUrl(undefined)
  }

  const handleDownload = () => {
    if (
      formBody.reportSetting == 'bothBrands' ||
      formBody.reportSetting == 'clientBrandOnly'
    ) {
      if (clientOptions.length == 0) {
        notify({
          alertBody:
            'Clients details not available. Please add a new client or select a different option.',
          status: 'Warning',
        })
        return
      }

      if (!logoUrl) {
        notify({
          alertBody: 'Please select a client logo',
          status: 'Warning',
        })
        return
      }
    }
    setPreviewData({
      clientId: selectedClientOption?.value,
      clientLogoUrl: logoUrl,
      hasClientLogo: formBody.reportSetting != 'companyBrandOnly',
      hasCompanyLogo: formBody.reportSetting != 'clientBrandOnly',
    })
    handlePrint()
    resetClientOption()
  }

  useEffect(() => {
    setPreviewData({
      clientId: selectedClientOption?.value,
      clientLogoUrl: logoUrl,
      hasClientLogo: formBody.reportSetting != 'companyBrandOnly',
      hasCompanyLogo: formBody.reportSetting != 'clientBrandOnly',
    })
  }, [formBody, selectedClientOption, logoUrl])

  return (
    <>
      <Modal
        headerTitle={`Download ${
          selectedResponse?.surveyTemplateType ===
          QuestionnaireCategoryType.EmployeeSurvey
            ? 'Employee'
            : 'Candidate'
        } Report`}
        isFooterShow={true}
        isActive={isModalActive}
        className='!z-[150]'
        onHeaderCloseButtonClick={() => closeModal()}
        onOverlayClick={() => closeModal()}
        modalProps={{
          style: { width: '600px', maxHeight: '90vh' },
        }}>
        <div className='w-full p-6 overflow-y-auto'>
          <div className='flex w-full p-4 mb-2 border rounded bg-Blue-50 text-Blue-800 border-Blue-300'>
            <i className='mr-2 ri-information-fill text-Blue-600'> </i>
            <div className='flex flex-col font-Regular text-Blue-700'>
              You can customize your report's branding in the Settings Panel{' '}
              <Link to='/settings?tab=6' className='underline font-SemiBold'>
                Click Here
              </Link>
            </div>
          </div>

          <div className='w-full mt-4'>
            <Accordion
              heading='Customize this report for a client'
              initialState={false}
              description='Add your client’s branding to reports when sending feedback externally'>
              <div className='mt-5 space-y-4' key={renderKeys.reportSetting}>
                <Radio
                  id='bothBrands'
                  labelText='Include My Company Logo and Client Logo'
                  helperText=''
                  name='reportSetting'
                  onChange={(e: any) => {
                    resetClientOption()

                    setFormBody({
                      ...formBody,
                      reportSetting: e.target.id,
                    })
                  }}
                />

                {formBody.reportSetting == 'bothBrands' && renderClientDetail()}

                <Radio
                  id='clientBrandOnly'
                  labelText='Include Client Logo only (This removes your Company Logo in the report)'
                  helperText=''
                  name='reportSetting'
                  onChange={(e: any) => {
                    resetClientOption()

                    setFormBody({
                      ...formBody,
                      reportSetting: e.target.id,
                    })
                  }}
                />

                {formBody.reportSetting == 'clientBrandOnly' &&
                  renderClientDetail()}
              </div>
            </Accordion>
          </div>
        </div>

        <ModalFooter>
          <div className='flex justify-end flex-1'>
            <Button onClick={() => closeModal()} textTheme='blue' theme='white'>
              Close
            </Button>
            <Button
              onClick={() => handleDownload()}
              className='ml-2'
              theme='cobalt'>
              Download
            </Button>
          </div>
        </ModalFooter>
      </Modal>

      {previewData && (
        <SurveyResponseReportPrintPreview
          ref={componentRef}
          candidateDetails={candidateDetails}
          previewData={previewData}
          selectedResponse={selectedResponse}
          dynamicFieldMapping={dynamicFieldMapping}
          changeTextSchema={changeTextSchema}
        />
      )}
    </>
  )
}
