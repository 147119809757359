export const removeHttpsOrHttp = (url: string): string => {
  return url.replace(/^https?:\/\//, '')
}

export const addHttpsIfNeeded = (url: string): string => {
  if (!url.match(/^https:\/\//)) {
    // Check if the URL starts with 'www.' or is just the domain without any protocol
    if (url.match(/^www\./)) {
      return 'https://' + url
    } else if (url.match(/^\w+\.\w+/)) {
      // If the URL doesn't start with 'http://' or 'https://' and it's in the format 'domain.com' without 'www.'
      return 'https://' + url
    } else if (url.match(/^http:\/\//)) {
      // If the URL starts with 'http://'
      return url.replace(/^http:\/\//, 'https://')
    } else {
      // If the URL is just a domain without any protocol
      return 'https://' + url
    }
  }
  return url
}
