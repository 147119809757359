import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  breadcrumbs: [{ title: 'Dashboard', url: '/' }],
  tempBreadcrumbs: [{ title: 'Dashboard', url: '/' }],
  isBreadCrumbVisible: false,
}

const breadcrumbsReducer = createSlice({
  name: 'sideMenu',
  initialState,
  reducers: {
    resetToInitialState(state) {
      state.breadcrumbs = []
      state.tempBreadcrumbs = []
      state.isBreadCrumbVisible = false
    },

    setBreadCrumbsState(state, { payload }) {
      state.breadcrumbs = payload
    },

    setTempBreadCrumbsState(state, { payload }) {
      state.tempBreadcrumbs = payload
    },

    setIsBreadcrumbVisibleState(state, { payload }) {
      state.isBreadCrumbVisible = payload
    },
  },
})

export const {
  resetToInitialState,
  setBreadCrumbsState,
  setTempBreadCrumbsState,
  setIsBreadcrumbVisibleState,
} = breadcrumbsReducer.actions

export const resetToInitial = () => (dispatch: any) => {
  dispatch(resetToInitialState())
}

export const setBreadCrumbs = (data: any) => (dispatch: any) => {
  dispatch(setBreadCrumbsState(data))
}

export const setTempBreadCrumbs = (data: any) => (dispatch: any) => {
  dispatch(setTempBreadCrumbsState(data))
}

export const setIsBreadcrumbVisible = (data: any) => (dispatch: any) => {
  dispatch(setIsBreadcrumbVisibleState(data))
}

export default breadcrumbsReducer.reducer
