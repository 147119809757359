import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface UpdateUserPasswordInterface {
  username: string
  currentPassword: string
  newPassword: string
}

export const useUpdateUserPassword = () => {
  const mutate = useMutation(
    ({
      username,
      currentPassword,
      newPassword,
    }: UpdateUserPasswordInterface) => {
      return postAPI('/Identity/ChangePassword', {
        username: username,
        currentPassword: currentPassword,
        newPassword: newPassword,
      })
    },
  )

  return mutate
}

export default useUpdateUserPassword
