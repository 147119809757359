import { countChars } from './countChars'
import { mean, roundTo2, stdDev } from './mathFn'

export type CharCount = {
  spaces: number
  correctWordChars: number
  allCorrectChars: number
  incorrectChars: number
  extraChars: number
  missedChars: number
  correctSpaces: number
}

//calculate calculate Wpm And Raw
export function calculateWpmAndRaw({
  wordList,
  typedLIst,
  testSeconds,
}: {
  wordList: any
  typedLIst: any
  testSeconds: number
}) {
  //Until space bar is pressed no words are registerd search setChar
  const chars = countChars(typedLIst, wordList)

  const wpm = roundTo2(
    // (Note: The division by 5 assumes an average word length of 5 characters.)
    ((chars.correctWordChars + chars.correctSpaces) * (60 / testSeconds)) / 5,
  )
  const raw = roundTo2(
    ((chars.allCorrectChars +
      chars.spaces +
      chars.incorrectChars +
      chars.extraChars) *
      (60 / testSeconds)) /
      5,
  )
  return { wpm, raw }
}

export function calculateAccuracy({
  wordList,
  typedLIst,
}: {
  wordList: any
  typedLIst: any
}): number {
  const chars = countChars(typedLIst, wordList)
  // const acuuracy = accuracyData?.current.correct! / (accuracyData?.current.correct! + accuracyData?.current.incorrect!) * 100
  const acuuracy =
    (chars.allCorrectChars /
      (chars.allCorrectChars + chars.incorrectChars + chars.extraChars)) *
    100
  const roundedAccuracy = roundTo2(acuuracy)
  return roundedAccuracy
}

//wpm consistency
export function calculateConsistency({
  chartDataArray,
}: {
  chartDataArray: any
}): number {
  const wpmHistory = chartDataArray?.map((chartData: any) => chartData.wpm)
  const stddev = stdDev(wpmHistory ?? [])
  const avg = mean(wpmHistory ?? [])
  const covariance = stddev / avg
  const wpmConsistency =
    100 *
    (1 -
      Math.tanh(
        covariance + Math.pow(covariance, 3) / 3 + Math.pow(covariance, 5) / 5,
      ))
  const roundedWpmConsistency = roundTo2(wpmConsistency)
  const consistency = isNaN(roundedWpmConsistency) ? 0 : roundedWpmConsistency

  return consistency
}
