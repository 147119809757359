import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface UseForgetPasswordInterface {
  username: string
  subDomain?: string
}

export const useForgetPassword = () => {
  const mutate = useMutation(
    ({ username, subDomain }: UseForgetPasswordInterface) => {
      return postAPI('/identity/ResetToken', {
        username: username,
        subDomain,
      })
    },
  )

  return mutate
}

export default useForgetPassword
