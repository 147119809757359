import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { TextField, Toast } from '@labourhub/labour-hub-ds'
import { useCreateCandidate } from 'api/candidates'
import {
  AppSettingKey,
  AppSettingProps,
  getAgencyAppSettings,
  getSettingValue,
  INITIAL_CANDIDATE_SETTINGS_PAYLOAD,
  isSettingEnabled,
  updateSettingByKey,
} from 'features/settings'
import { ModalProps } from 'types'

import { PageLoader, PhoneInputField } from 'components/atoms'
import { RoundedModal } from 'components/ui/RoundedModal'
import { isEmail, isLetters, isPhoneNumber } from 'utils'

import { CandidateSettingsFormRef } from './CandidateSettingsForm'
import { RefereeTypeAccordionContentType } from './RequestSpecificRefereeForm'

type AddNewEmployeeModalProps = ModalProps & {
  modalTitle: string | undefined
  refreshEmployeeList: () => void
}

export const AddNewEmployeeModal = ({
  modalTitle = 'Add Employee',
  isModalActive,
  refreshEmployeeList,
  setIsModalActive,
}: AddNewEmployeeModalProps) => {
  const notify = (props: any) => Toast(props)

  const { userDetails } = useSelector((state: any) => state.user)

  const agencyAppSettings = useSelector(getAgencyAppSettings)

  const [candidateSettings, setCandidateSettings] = useState<AppSettingProps[]>(
    INITIAL_CANDIDATE_SETTINGS_PAYLOAD,
  )

  const candidateSettingsFormRef = useRef<CandidateSettingsFormRef | null>(null)

  const defaultRefType = getSettingValue(
    AppSettingKey.DefaultReferenceCheckType,
    agencyAppSettings,
  )

  const isEmailRequired = isSettingEnabled(
    AppSettingKey.IS_CANDIDATE_EMAIL_REQUIRED,
    agencyAppSettings,
  )

  const isSmsEnabled = isSettingEnabled(
    AppSettingKey.SmsFeature,
    agencyAppSettings,
  )

  /** candidate details and error fields state */
  const [candidateDetails, setCandidateDetails] = useState({
    title: '',
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    phone: '',
    countryCode: 'AU',
    dateOfBirth: '',
    gender: '',
    jobTitle: '',
    noOfReferences: 2,
    isEmployee: true,
    consultantId: userDetails?.id,
    candidateSettings: [] as any,
  })

  const [referenceTypes] = useState<RefereeTypeAccordionContentType[]>([
    {
      type: defaultRefType,
      note: '',
      specificEmployer: '',
    },
    {
      type: defaultRefType,
      note: '',
      specificEmployer: '',
    },
  ])

  const [renderKey, setRenderKey] = useState(Math.random())

  useEffect(() => {
    setCandidateSettings(agencyAppSettings)
  }, [agencyAppSettings])

  useEffect(() => {
    setCandidateDetails({
      ...candidateDetails,
      consultantId: userDetails?.id,
    })
  }, [userDetails])

  useEffect(() => {
    const updatedSettings = updateSettingByKey(
      AppSettingKey.SmsFeature,
      candidateSettings,
      {
        value: `true`,
      },
    )

    setCandidateDetails({
      ...candidateDetails,
      candidateSettings: updatedSettings,
    })
  }, [candidateSettings])

  const [isErrorField, setIsErrorField] = useState<any>({
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
    jobTitle: false,
  })

  /** drawer primary button disable state */
  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(false)

  /** APi call for the add employee */
  const { mutate: createEmployeeMutate, isLoading: createEmployeeIsLoading } =
    useCreateCandidate()

  /** Process the add new employee */
  const addNewEmployee = () => {
    createEmployeeMutate(
      {
        candidateDetails,
        referenceTypes,
      },
      {
        onSuccess: () => {
          notify({
            alertHeader: 'New employee added successfully',
            alertBody: '',
            status: 'Success',
          })
          resetInputFields()
          setIsModalActive(false)
          refreshEmployeeList()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** front end validation input fields */
  const validateInputs = () => {
    const { firstName, lastName, email, phone, jobTitle } = candidateDetails

    setIsErrorField({
      ...isErrorField,
      firstName: isLetters(firstName) && firstName?.length < 100 ? false : true,
      lastName: isLetters(lastName) && lastName?.length < 100 ? false : true,
      phone: isPhoneNumber(phone) && phone?.length < 20 ? false : true,
      jobTitle: jobTitle?.length < 100 ? false : true,
    })

    const isAllValidFields =
      isLetters(firstName) &&
      firstName?.length < 100 &&
      isLetters(lastName) &&
      lastName?.length < 100 &&
      isPhoneNumber(phone) &&
      phone?.length < 20 &&
      jobTitle?.length < 100

    if (isEmailRequired) {
      setIsErrorField({
        ...isErrorField,
        email: isEmail(email) && email?.length < 200 ? false : true,
        firstName:
          isLetters(firstName) && firstName?.length < 100 ? false : true,
        lastName: isLetters(lastName) && lastName?.length < 100 ? false : true,
        phone: isPhoneNumber(phone) && phone?.length < 20 ? false : true,
        jobTitle: jobTitle?.length < 100 ? false : true,
      })
      return isAllValidFields && email?.length < 200 && isEmail(email)
    }

    return isAllValidFields
  }

  /** handle add new candidate button */
  const onAddNewEmployee = () => {
    const valid = validateInputs()

    if (valid) {
      addNewEmployee()
    } else {
      notify({
        alertHeader: 'Invalid input type.',
        status: 'Warning',
      })
    }
  }

  /** reset input fields */
  const resetInputFields = () => {
    setCandidateDetails({
      ...candidateDetails,
      firstName: '',
      lastName: '',
      email: '',
      countryCode: 'AU',
      phone: '',
      jobTitle: '',
      noOfReferences: 0,
      isEmployee: true,
    })

    setCandidateSettings(agencyAppSettings)

    if (candidateSettingsFormRef.current) {
      candidateSettingsFormRef.current.resetForm()
    }
  }

  /** handle primary button disable */
  useEffect(() => {
    const { firstName, email, phone, jobTitle }: any = candidateDetails || {}

    if (isEmailRequired) {
      firstName && email && jobTitle
        ? setIsPrimaryButtonDisabled(false)
        : setIsPrimaryButtonDisabled(true)
    } else {
      firstName && phone && isSmsEnabled && jobTitle
        ? setIsPrimaryButtonDisabled(false)
        : setIsPrimaryButtonDisabled(true)
    }
  }, [candidateDetails])

  useEffect(() => {
    if (isModalActive) {
      setRenderKey(Math.random())
    }
  }, [isModalActive])

  const resetErrorState = () => {
    setIsErrorField({
      firstName: false,
      lastName: false,
      email: false,
      phone: false,
      jobTitle: false,
    })
  }

  const handleCloseModal = () => {
    setIsModalActive(false)
    resetInputFields()
    resetErrorState()
  }

  return (
    <RoundedModal
      isActive={isModalActive}
      headerTitle={modalTitle}
      size='md'
      className='!z-[150]'
      isHeaderShow={true}
      isFooterShow={true}
      isSeparator={true}
      primaryButtonTitle='Add Employee'
      secondaryButtonTitle='Cancel'
      isPrimaryButtonDisable={isPrimaryButtonDisabled}
      onClickPrimaryBtn={() => {
        onAddNewEmployee()
      }}
      onHeaderCloseButtonClick={() => {
        handleCloseModal()
      }}
      onClickSecondaryBtn={() => {
        handleCloseModal()
      }}>
      {createEmployeeIsLoading && <PageLoader size='xxs' />}
      <div className='w-full px-6'>
        {/* first name section */}
        <TextField
          label='First Name'
          className='mt-5'
          isRequired
          value={candidateDetails?.firstName}
          onChange={(e: any) => {
            setCandidateDetails({
              ...candidateDetails,
              firstName: e.target.value,
            })
          }}
          placeholder=''
          isErrored={isErrorField.firstName}
        />
        {/* last name section */}
        <TextField
          label='Last Name'
          isRequired
          className='mt-5 mb-4'
          value={candidateDetails?.lastName}
          onChange={(e: any) => {
            setCandidateDetails({
              ...candidateDetails,
              lastName: e.target.value,
            })
          }}
          placeholder=''
          isErrored={isErrorField.lastName}
        />

        {/* email section */}
        <TextField
          label='Email'
          isRequired={isEmailRequired}
          className='mt-4'
          value={candidateDetails?.email}
          onChange={(e: any) => {
            setCandidateDetails({
              ...candidateDetails,
              email: e.target.value,
            })
          }}
          placeholder=''
          isErrored={isEmailRequired && isErrorField.email}
        />
        {/** Phone number section */}
        <PhoneInputField
          key={renderKey}
          label='Phone'
          isRequired
          value={candidateDetails?.phone}
          onChangeWithCountry={(number: any, countryCode: string) =>
            setCandidateDetails({
              ...candidateDetails,
              phone: number,
              countryCode,
            })
          }
          className='mt-5'
          isErrored={isErrorField.phone}
        />
        {/* job title section */}
        <TextField
          label='Job Title'
          className='mt-5'
          isRequired
          value={candidateDetails?.jobTitle}
          onChange={(e: any) => {
            setCandidateDetails({
              ...candidateDetails,
              jobTitle: e.target.value,
            })
          }}
          placeholder=''
          isErrored={isErrorField.jobTitle}
        />
      </div>
    </RoundedModal>
  )
}
