import addIcon from 'assets/images/notes/add.svg'
import errorIcon from 'assets/images/notes/error.svg'
import infoIcon from 'assets/images/notes/info.svg'
import noteIcon from 'assets/images/notes/note.svg'
import sendIcon from 'assets/images/notes/send.svg'
import successIcon from 'assets/images/notes/success.svg'

import { cn } from 'utils'

import { ColorizeWordsInBrackets } from './ColorizeWordsInBrackets'

type HistoryTabProps = {
  icon?: 'Success' | 'Info' | 'Error' | 'Send' | 'Add' | 'Note'
  title: string
  date: string
  description?: string
  size?: 'sm' | 'md'
  classNames?: string
}

export const iconMappings = {
  Success: successIcon,
  Info: infoIcon,
  Error: errorIcon,
  Add: addIcon,
  Send: sendIcon,
  Note: noteIcon,
}

export const HistoryItemCard = ({
  date,
  description,
  icon = 'Note',
  title,
  size = 'sm',
  classNames,
}: HistoryTabProps) => {
  return (
    <div
      className={cn(
        'flex flex-col w-full px-2 mb-2 bg-white border rounded-lg border-Gray-200',
        {
          'pt-2 pb-1': size == 'sm',
          'pt-3 pb-2': size == 'md',
        },
        classNames,
      )}>
      <div className='flex items-start justify-start'>
        <img
          src={iconMappings[icon] || noteIcon}
          alt=''
          className={cn({
            'w-3 h-3': size == 'sm',
            'w-5 h-5': size == 'md',
          })}
        />

        {title && (
          <div
            className={cn('flex pl-2', {
              'text-[10px] leading-3': size == 'sm',
              'text-extra-small': size == 'md',
            })}>
            <ColorizeWordsInBrackets text={title} />
          </div>
        )}
      </div>
      <div
        className={cn('flex leading-3 text-Gray-700 pl-[20px] mt-[2px]', {
          'text-[10px]': size == 'sm',
          'text-extra-small': size == 'md',
        })}>
        {description}
      </div>
      <div
        className={cn('flex leading-5 text-Gray-400 pl-[20px] mt-1', {
          'text-[10px]': size == 'sm',
          'text-extra-small': size == 'md',
        })}>
        {date || ``}
      </div>
    </div>
  )
}

export const NoteItemCard = ({
  noteId,
  description,
  setIsDeleteNoteModalActive,
  setIsEditNoteModalActive,
  setSelectedNoteId,
}: {
  noteId: string
  description: string | undefined
  setIsDeleteNoteModalActive: any
  setIsEditNoteModalActive: any
  setSelectedNoteId: any
}) => {
  return (
    <div className='flex flex-row p-2 w-full mb-2 bg-white border rounded-lg border-Gray-200 hover:bg-gray-50 group'>
      <div className='flex items-start justify-start w-[90%]'>
        <img src={noteIcon} alt='' className='w-4 h-4' />

        <div className='flex text-extra-small leading-3 text-Gray-800 font-Medium pl-4 mt-[2px]'>
          {description}
        </div>
      </div>
      <div className='flex-row justify-end text-extra-small leading-3 ml-1 text-Gray-800 font-Medium mt-[2px] hidden group-hover:flex'>
        <button
          onClick={() => {
            setSelectedNoteId(noteId)
            setIsEditNoteModalActive && setIsEditNoteModalActive(true)
          }}
          className='focus:outline-none'>
          <i className='text-[12px] ri-pencil-line text-Gray-600 mr-1' />
        </button>

        <button
          onClick={() => {
            setSelectedNoteId(noteId)
            setIsDeleteNoteModalActive && setIsDeleteNoteModalActive(true)
          }}
          className='focus:outline-none'>
          <i className='text-[12px] ri-delete-bin-line text-Red-600' />
        </button>
      </div>
    </div>
  )
}
