import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@labourhub/labour-hub-ds'
import payg from 'assets/images/subscription/payg-icon.svg'
import {
  FixedSubscriptionPackageDto,
  getSubscriptionStore,
  SubscriptionMode,
} from 'features/subscriptions'
import { FixedSubscriptionPackagesModal } from 'features/subscriptions/components/FixedSubscriptionPackagesModal'
import { SubscriptionPlanPackageBadge } from 'features/subscriptions/components/SubscriptionPlanPackageBadge'
import { ModalProps } from 'types'

import { Modal } from 'components/molecules'
import { getS3File } from 'utils'

import { setPayAsYouGoCreditsModalActive } from '../store'

import { FixedSubscriptionPaymentModal } from './FixedSubscriptionPaymentModal'

type CreditAlertModalProps = ModalProps

export const CreditAlertModal = ({
  isModalActive,
  setIsModalActive,
}: CreditAlertModalProps) => {
  const [isFixedSubPackagesModalActive, setIsFixedSubPackagesModalActive] =
    useState(false)

  const [isFixedSubPaymentModalActive, setIsFixedSubPaymentModalActive] =
    useState(false)
  const [selectedPackage, setSelectedPackage] =
    useState<FixedSubscriptionPackageDto | null>(null)

  const dispatch = useDispatch()
  const {
    packages: { fixedMonthlyPackages },
  } = useSelector(getSubscriptionStore)

  const handleClose = () => {
    setIsModalActive(false)
    localStorage.setItem('isCreditLow', 'false')
  }

  const memoizedImage = useMemo(() => getS3File('credit-alert-bg.svg'), [])

  return (
    <>
      <Modal
        isActive={isModalActive}
        onHeaderCloseButtonClick={handleClose}
        onClickSecondaryBtn={handleClose}
        isHeaderShow={false}
        onOverlayClick={handleClose}
        modalProps={{
          style: { width: '92vw' },
        }}
        headerTitle='Credit Alert'>
        <div className='flex items-start justify-start w-full h-full'>
          <div className='flex items-start justify-start w-auto left-side'>
            <img
              src={memoizedImage}
              alt='credit-alert'
              className='object-fill h-full'
            />
          </div>

          <div className='flex flex-col items-start justify-start flex-1 w-full h-full overflow-y-auto'>
            <div className='flex items-end justify-end w-full px-[12px] py-3'>
              <Button
                className='w-[20px] h-[20px] flex justify-center items-center'
                size='sm'
                isIconOnly
                textTheme='black'
                theme='link'
                onClick={handleClose}
                icon={
                  <i className='cursor-pointer ri-close-fill text-Gray-800 text-[16px] leading-5'></i>
                }
              />
            </div>
            <div className='flex flex-col justify-start items-start px-[30px] w-full'>
              <div className='text-Cobalt-800 text-heading-3 font-SemiBold'>
                You only have 1 credit left!
              </div>

              <div className='flex pt-[10px] text-heading-5 text-[#757575]'>
                Explore our pricing options and pick a plan that works for you!
              </div>

              <div className='mt-5 text-Gray-600 text-heading-4 font-SemiBold'>
                Pay as you go
              </div>

              <div className='flex items-center justify-between w-full py-4 pl-3 pr-6 mt-5 border rounded border-Gray-300'>
                <div className='flex justify-start items-center'>
                  <div className='flex items-center justify-center p-5'>
                    <img src={payg} alt='pay-g' className='h-[50px] w-[50px]' />
                  </div>

                  <div>
                    <ul className='font-Medium text-small'>
                      <li className='flex items-center justify-start mb-[3px] gap-x-1'>
                        <i className='ri-check-fill'></i>
                        Unlimited Account Users
                      </li>
                      <li className='flex items-center justify-start mb-[3px] gap-x-1'>
                        <i className='ri-check-fill'></i>
                        Unlimited AI Templates
                      </li>
                    </ul>
                  </div>
                </div>

                <div className='flex flex-col items-start justify-start'>
                  <div className='flex items-end justify-start gap-x-1'>
                    <span className='text-Cobalt-800 text-heading-2 font-Medium'>
                      $12
                    </span>
                    <span className='mb-1 text-small text-Gray-500'>
                      Per Check
                    </span>
                  </div>

                  <Button
                    onClick={() => {
                      dispatch(setPayAsYouGoCreditsModalActive(true))
                      handleClose()
                    }}
                    size='sm'
                    className='w-fit mt-[6px]'
                    textTheme='black'
                    theme='white'>
                    Buy Credits
                  </Button>
                </div>
              </div>

              <div className='mt-5 text-Gray-600 text-heading-4 font-SemiBold'>
                Subscription Options
              </div>

              <div className='flex w-full mt-5 gap-x-3'>
                {fixedMonthlyPackages.map((packageInfo) => {
                  return (
                    <div
                      key={packageInfo.id}
                      className='flex flex-col w-[300px] p-4 border rounded border-Gray-300'>
                      <div className='flex items-start justify-between w-full gap-x-3'>
                        <div className='flex flex-col gap-y-[3px]'>
                          <span className='text-small text-Gray-600'>From</span>
                          <span className='text-heading-2 text-Cobalt-800 font-Medium'>
                            $
                            {packageInfo.packageAmount /
                              packageInfo.referenceCheckLimit}
                          </span>
                          <span className='text-small text-Gray-600'>
                            Per Check Inc GST
                          </span>
                        </div>

                        <SubscriptionPlanPackageBadge
                          packageName={packageInfo.name}
                          size='small'
                          isFill={true}
                        />
                      </div>

                      <div className='mt-4'>
                        <ul className='font-Medium text-small'>
                          <li className='flex items-center justify-start mb-[3px] gap-x-1'>
                            <i className='ri-check-fill'></i>
                            {packageInfo.referenceCheckLimit} Credits Per Month
                          </li>
                        </ul>
                      </div>

                      <Button
                        onClick={() => {
                          setSelectedPackage(packageInfo)
                          setIsFixedSubPaymentModalActive(true)
                        }}
                        size='sm'
                        className='mt-5 w-fit'
                        textTheme='black'
                        theme='white'>
                        Learn More
                      </Button>
                    </div>
                  )
                })}
              </div>

              <Button
                onClick={() => {
                  setIsFixedSubPackagesModalActive(true)
                  handleClose()
                }}
                size='sm'
                className='mt-5 w-fit'
                textTheme='white'
                theme='cobalt'>
                Explore Plans
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      <FixedSubscriptionPackagesModal
        isModalActive={isFixedSubPackagesModalActive}
        setIsModalActive={setIsFixedSubPackagesModalActive}
      />

      {selectedPackage && (
        <FixedSubscriptionPaymentModal
          isModalActive={isFixedSubPaymentModalActive}
          setIsModalActive={setIsFixedSubPaymentModalActive}
          setIsFixedSubscriptionPackagesModalActive={
            setIsFixedSubPackagesModalActive
          }
          subscriptionPackage={selectedPackage}
          subscriptionMode={SubscriptionMode.New}
        />
      )}
    </>
  )
}
