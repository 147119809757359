import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { Button } from '@labourhub/labour-hub-ds'
import agencyLogoDummy from 'assets/images/company-logo.svg'
import { getAgency } from 'features/agencies/store/selectors'

import { addHttpsIfNeeded, removeHttpsOrHttp } from 'utils'

import './style.scss'

type CompanyDetailsCardProps = {
  setIsEditView: any
}

export const CompanyDetailsCard = ({
  setIsEditView,
}: CompanyDetailsCardProps) => {
  const { agency }: any = useSelector(getAgency)

  const formattedWebsite = useMemo(() => {
    return agency?.website ? addHttpsIfNeeded(agency.website) : '-'
  }, [agency?.website])

  const formattedPrivacyPolicy = useMemo(() => {
    return agency?.privacyPolicy ? addHttpsIfNeeded(agency.privacyPolicy) : '-'
  }, [agency?.privacyPolicy])

  return (
    <div className='relative w-full px-3 mb-2 border-2 border-dashed rounded-3xl border-Cobalt-200'>
      <div className='absolute right-0 mt-3 mr-3 edit-button'>
        <Button
          size='sm'
          theme='white'
          className='w-[36px] h-[20px] rounded-sm '
          onClick={() => setIsEditView(true)}
          textTheme='blue'>
          Edit
        </Button>
      </div>
      <div className='flex items-center justify-center w-full h-full mt-3'>
        <div className='flex w-[110px] h-[110px] items-center justify-center rounded-full overflow-hidden border border-Gray-200'>
          {agency?.logoUrl == null ? (
            <img src={agencyLogoDummy} alt='' className='w-[80px]' />
          ) : (
            <img
              src={agency?.logoUrl}
              alt=''
              className='flex object-contain w-full h-auto rounded-full'
            />
          )}
        </div>

        <div className='flex-1 w-full'>
          <div className='flex rounded-lg ml-[18px] p-4 pl-2'>
            <table className='w-full'>
              <tr className='flex items-start w-full mb-[4px]'>
                <td className='text-extra-small text-[#2E2E2E] mr-[10px] font-Medium w-2/5 pt-[2.5px] flex justify-between items-center'>
                  <span>Name</span>
                  <span>:</span>
                </td>
                <td className='w-[270px]  text-small font-SemiBold text-Gray-800 pr-2'>
                  {agency?.name ? agency?.name : 'NA'}
                </td>
              </tr>

              <tr className='flex items-start w-full mb-[4px]'>
                <td className='text-extra-small text-[#2E2E2E] mr-[10px] font-Medium w-2/5 pt-[2.5px] flex justify-between items-center'>
                  <span>Website</span>
                  <span>:</span>
                </td>
                <td className='overflow-hidden truncate w-[275px] text-small font-SemiBold text-Gray-800'>
                  {agency?.website ? (
                    <a
                      href={formattedWebsite}
                      target='_blank'
                      className='font-SemiBold text-small text-Cobalt-600'
                      data-for='WebSite'>
                      {removeHttpsOrHttp(formattedWebsite)}
                    </a>
                  ) : (
                    <span className='text-small font-SemiBold text-Gray-800'>
                      NA
                    </span>
                  )}

                  <ReactTooltip
                    place='top'
                    id='WebSite'
                    effect='solid'
                    textColor='#FFFFFF'
                    backgroundColor='#4B5563'
                  />
                </td>
              </tr>

              <tr className='flex items-start w-full mb-[4px]'>
                <td className='text-extra-small text-[#2E2E2E] mr-[10px] font-Medium w-2/5 pt-[2.5px] flex justify-between items-center'>
                  <span>Privacy Policy</span>
                  <span>:</span>
                </td>
                <td className='overflow-hidden truncate w-[275px] text-small font-SemiBold text-Gray-800'>
                  {agency?.privacyPolicy ? (
                    <a
                      href={formattedPrivacyPolicy}
                      target='_blank'
                      className='font-SemiBold text-small text-Cobalt-600'
                      data-for='privacyPolicy'>
                      {removeHttpsOrHttp(formattedPrivacyPolicy)}
                    </a>
                  ) : (
                    <span className='text-small font-SemiBold text-Gray-800'>
                      NA
                    </span>
                  )}

                  <ReactTooltip
                    place='top'
                    id='privacyPolicy'
                    effect='solid'
                    textColor='#FFFFFF'
                    backgroundColor='#4B5563'
                  />
                </td>
              </tr>

              <tr className='flex items-start w-full mb-[4px]'>
                <td className='text-extra-small text-[#2E2E2E] mr-[10px] font-Medium w-2/5 pt-[2.5px] flex justify-between items-center'>
                  <span>Phone</span>
                  <span>:</span>
                </td>
                <td className='w-[275px] text-small font-SemiBold text-Gray-800'>
                  {agency?.phone ? agency?.phone : 'NA'}
                </td>
              </tr>

              <tr className='flex items-start w-full mb-[4px]'>
                <td className='text-extra-small text-[#2E2E2E] mr-[10px] font-Medium w-2/5 pt-[2.5px] flex justify-between items-center'>
                  <span>Email</span>
                  <span>:</span>
                </td>
                <td className='overflow-hidden truncate w-[275px] text-small font-SemiBold text-Gray-800'>
                  {agency?.email ? (
                    <a
                      href={`mailto:${agency?.email}`}
                      className='text-small text-Cobalt-500 font-SemiBold'>
                      {agency?.email}
                    </a>
                  ) : (
                    <span className='text-small font-SemiBold text-Gray-800'>
                      NA
                    </span>
                  )}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
