import { useMemo } from 'react'
import { useDroppable } from '@dnd-kit/core'
import { SortableContext } from '@dnd-kit/sortable'
import { assessmentQuestionTypes } from 'features/questionnaires/data'

import { Column } from 'components/atoms/Column'

import { DraggableQuestionType } from './DraggableQuestionType'

export const QuestionTypesColumn = () => {
  const { setNodeRef } = useDroppable({ id: 'question-types' })

  const textElements = useMemo(
    () => assessmentQuestionTypes.filter((i) => i.group == 'text'),
    [assessmentQuestionTypes],
  )

  const selectionElements = useMemo(
    () => assessmentQuestionTypes.filter((i) => i.group == 'selection'),
    [assessmentQuestionTypes],
  )

  return (
    <SortableContext id='question-types' items={assessmentQuestionTypes}>
      <div
        ref={setNodeRef}
        className='flex flex-col w-2/5 lg:w-1/3 h-fit p-5 sticky top-3 bg-white border rounded-md space-y-5 border-Gray-200'>
        <Column>
          <span className='text-heading-5 font-Bold text-Gray-800'>
            Questions Library
          </span>
          <span className='text-small font-Regular text-Gray-600'>
            Drag & drop question types to create a template.
          </span>
        </Column>
        <div className='space-y-3'>
          <span className='uppercase text-small font-Regular text-Gray-600'>
            Text Elements
          </span>
          <div className='grid grid-cols-2 gap-5'>
            {textElements.map((question) => (
              <DraggableQuestionType key={question.id} question={question} />
            ))}
          </div>
        </div>

        <div className='space-y-3'>
          <span className='uppercase text-small font-Regular text-Gray-600'>
            Selection Elements
          </span>
          <div className='grid grid-cols-2 gap-5'>
            {selectionElements.map((question) => (
              <DraggableQuestionType key={question.id} question={question} />
            ))}
          </div>
        </div>
      </div>
    </SortableContext>
  )
}
