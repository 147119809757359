import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { SubscriptionPackagesListDto } from 'features/subscriptions/types'
import { get } from 'framework/api/http'

const getSubscriptionPackagesList =
  async (): Promise<SubscriptionPackagesListDto> => {
    const res = await get(`/Subscriptions/Packages`)
    return res.data.packages
  }

export const useGetSubscriptionPackagesList = (
  onSuccess: (packages: SubscriptionPackagesListDto) => void,
) => {
  return useQuery(['subscription_packages_list'], getSubscriptionPackagesList, {
    enabled: true,
    refetchOnWindowFocus: false,
    onSuccess,
    onError: () => {
      Toast({
        alertHeader: 'Error fetching subscription packages',
        status: 'Error',
      })
    },
  })
}
