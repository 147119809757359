import { useEffect, useState } from 'react'
import enterprise from 'assets/images/subscription/enterprise.svg'
// import free from 'assets/images/subscription/free.svg'
import pack15 from 'assets/images/subscription/pack15.svg'
import pack25 from 'assets/images/subscription/pack25.svg'
import pack50 from 'assets/images/subscription/pack50.svg'
import payg from 'assets/images/subscription/payg.svg'
import CN from 'classnames'

import { SubscriptionPlanType } from '../types'

export type SubscriptionPlanPackageBadgeProps = {
  packageName: SubscriptionPlanType | string
  size?: 'small' | 'large'
  isFill?: boolean
}

const planStyles: Record<
  SubscriptionPlanType,
  {
    spanText: string
    icon: string
  }
> = {
  // Free: {
  //   spanText: 'Free',
  //   icon: free,
  // },
  PAYG: {
    spanText: 'PAYG',
    icon: payg,
  },
  FifteenPack: {
    spanText: '15 Pack',
    icon: pack15,
  },
  TwentyfivePack: {
    spanText: '25 Pack',
    icon: pack25,
  },
  FiftyPack: {
    spanText: '50 Pack',
    icon: pack50,
  },
  EnterprisePack: {
    spanText: 'Enterprise',
    icon: enterprise,
  },
}

export const SubscriptionPlanPackageBadge = ({
  packageName = SubscriptionPlanType.PAYG,
  size = 'small',
  isFill = false,
}: SubscriptionPlanPackageBadgeProps) => {
  const [styles, setStyles] = useState(planStyles[packageName])

  useEffect(() => {
    setStyles(planStyles[packageName])
  }, [packageName])

  const sizeClassName = CN('font-Bold', {
    'text-extra-small': size === 'small',
    'text-heading-4': size === 'large',
    'text-Cobalt-700':
      packageName === SubscriptionPlanType.FifteenPack ||
      packageName === SubscriptionPlanType.TwentyfivePack ||
      packageName === SubscriptionPlanType.FiftyPack ||
      packageName === SubscriptionPlanType.PAYG ||
      // packageName === SubscriptionPlanType.Free ||
      packageName === SubscriptionPlanType.EnterprisePack,
    '!text-white': !isFill,
  })

  const badgeHeightClassName = CN({
    'h-[46px] top-[-3px] -right-1.5': size === 'small',
    'h-[68px] top-[-6px] -right-3': size === 'large',
    // 'h-[80px] -right-5': packageName === SubscriptionPlanType.Free,
  })

  return (
    <div
      className={CN('flex items-center relative', {
        'h-[34px]': size == 'small',
        'h-[48px]': size == 'large',
      })}>
      <div
        className={CN(
          'flex py-1 pl-4 justify-start items-center border rounded-lg border-Gray-200 h-full',
          {
            'pr-[40px]': size == 'small',
            'pr-[57px]': size == 'large',
            // 'pr-[48px]': packageName === SubscriptionPlanType.Free,
            'bg-white': isFill,
          },
        )}>
        <div className={sizeClassName}>{styles.spanText}</div>
      </div>

      <img
        src={styles.icon}
        alt={styles.spanText}
        className={CN('absolute object-fill', badgeHeightClassName)}
      />
    </div>
  )
}
