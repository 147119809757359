import { useSelector } from 'react-redux'
import { Checkbox } from '@labourhub/labour-hub-ds'
import cancelImage from 'assets/images/subscription/cancelImage.svg'

import { Column } from 'components/atoms/Column'
import { H5 } from 'components/atoms/Headings'
import { Row, RowWithAlignment } from 'components/atoms/Row'
import { dateToReadableString2 } from 'utils'

import { getSubscriptionStore } from '../../store'

type ConfirmYourCancellationFormProps = {
  confirmCheckBox: boolean
  setConfirmCheckBox: any
}

export const ConfirmYourCancellationForm = ({
  confirmCheckBox,
  setConfirmCheckBox,
}: ConfirmYourCancellationFormProps) => {
  const {
    activeSubscriptions: { fixedSubscription },
  } = useSelector(getSubscriptionStore)

  return (
    <RowWithAlignment
      justifyAlignment='center'
      className='flex flex-col p-5 px-7'>
      <img src={cancelImage} alt={'cancel'} className='object-fill h-[125px]' />

      <Column className='mt-8 pr-14'>
        <H5 title='Are You Sure You Want to Cancel?' />
        <div className='text-small'>
          We hate to see you go, but we want to make sure you're making the best
          decision for you.
        </div>

        <Row className='pr-5 mx-3 my-5'>
          <Checkbox
            helperText=''
            labelText=''
            defaultChecked={confirmCheckBox}
            onChange={(e: any) => setConfirmCheckBox(e.target.checked)}
          />
          <span className='ml-2 text-small'>
            If you cancel your plan, any credits you've already purchased will
            remain in your account. Your next renewal is scheduled for{' '}
            {dateToReadableString2(fixedSubscription?.renewalDate)}. By
            canceling now, you won't be billed on this date and won't receive
            your credit allowance for the upcoming month.
          </span>
        </Row>
      </Column>
    </RowWithAlignment>
  )
}
