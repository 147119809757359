import { useMutation } from 'react-query'
import { postAPIwithFormData } from 'framework/api/http'

interface CreateAgencySecondaryLogoInterface {
  Id: string
  logoObject: string
}

export const useCreateAgencySecondaryLogo = () => {
  const mutate = useMutation(
    ({ Id, logoObject }: CreateAgencySecondaryLogoInterface) => {
      const bodyFormData = new FormData()
      bodyFormData.append('Logo', logoObject)

      return postAPIwithFormData(
        `/AgencyProfile/${Id}/SecondaryLogo`,
        bodyFormData,
        {},
      )
    },
  )

  return mutate
}

export default useCreateAgencySecondaryLogo
