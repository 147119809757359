/* eslint-disable prefer-const */
import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const getDashboardDetailsForTable = async ({ queryKey }: any) => {
  const {
    skip,
    take,
    sortColumnName,
    sortDirection,
    from,
    to,
    module,
    status,
  } = queryKey[1]
  if (status && module) {
    const res = await get(
      `/facade/DashboardDetails?Skip=${skip}&Take=${take}&SortColumnName=${sortColumnName}&SortDirection=${sortDirection}&From=${from}&To=${to}&Module=${module}&Status=${status}`,
    )

    return res.data.dashboardDetails
  }
}

export const useGetDashboardDetailsForTable = (
  { ...reqBody },
  onSuccess: any,
  onError: any,
) => {
  return useQuery(['dashboardDetails', reqBody], getDashboardDetailsForTable, {
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess,
    onError,
  })
}
