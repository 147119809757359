import { useState } from 'react'

import { CandidatesTable } from '../components/CandidatesTable'

export const CandidateListPage = () => {
  const [isAddCandidateDrawerOpen, setIsAddCandidateDrawerOpen] =
    useState(false)

  return (
    <CandidatesTable
      isAddCandidateDrawerOpen={isAddCandidateDrawerOpen}
      setIsAddCandidateDrawerOpen={setIsAddCandidateDrawerOpen}
    />
  )
}
