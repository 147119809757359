import { GuideStepperComponent } from 'features/onboarding/types'

import { Column } from 'components/atoms/Column'

import './GuideStepper.scss'

type GuideStepperProps = {
  stepperData: GuideStepperComponent
}
export const GuideStepper = ({ stepperData }: GuideStepperProps) => {
  // const dispatch = useDispatch()
  // const { showGuideStepper } = useSelector(getOnboardingStore)

  return (
    <Column className='mb-4'>
      <div>{stepperData.component}</div>
      {/* {showGuideStepper && stepperData.stepperButton && (
        <RowWithAlignment
          justifyAlignment='center'
          className='mt-2 gap-x-[10px]'>
          {stepperData.stepperButton.enableBackButton && (
            <Button
              className='w-fit'
              size='sm'
              textTheme='black'
              theme='white'
              iconLeft={<i className='ri-arrow-go-back-line'></i>}
              onClick={() => dispatch(setCurrentStep(stepperData.orderNo - 1))}>
              Go Back
            </Button>
          )}

          {stepperData.stepperButton.enableDot && stepperData.orderNo != 1 && (
            <div className='step-dots mt-[20px]'>
              {Array.from({ length: guideStepperComponents.length - 3 }).map(
                (_, index) => (
                  <span
                    key={index}
                    className={`focus:outline-none step-dot ${
                      index === stepperData.orderNo - 2 ? 'active' : ''
                    } `}
                  />
                ),
              )}
            </div>
          )}

          {stepperData.stepperButton.enableSkipButton && (
            <Button
              className='w-fit'
              size='sm'
              textTheme='black'
              theme='white'
              iconLeft={<i className='ri-share-forward-line'></i>}
              onClick={() => {
                dispatch(setCurrentStep(stepperData.orderNo + 1))
              }}>
              Skip
            </Button>
          )}
        </RowWithAlignment>
      )} */}
    </Column>
  )
}
