import { Badge, Button, Radio } from '@labourhub/labour-hub-ds'
import EmptySurvey from 'assets/images/emptySurvey.svg'

import { PageLoader } from 'components/atoms'
import { Column } from 'components/atoms/Column'
import { RowWithAlignment } from 'components/atoms/Row'

type SurveyRadioListProps = {
  radioKey: number
  questionnaires: any
  isLoading?: boolean
  setShowBuildTemplateModal: any
  setSelectedSurvey: any
}

export const SurveyRadioList = ({
  radioKey,
  questionnaires,
  isLoading,
  setShowBuildTemplateModal,
  setSelectedSurvey,
}: SurveyRadioListProps) => {
  if (isLoading) return <PageLoader size='xxs' />

  return (
    <Column>
      {questionnaires.length === 0 ? (
        <div className='flex flex-col items-center justify-center'>
          <img
            src={EmptySurvey}
            alt='empty-table-questionnaire'
            className='h-[40px]'
          />
          <div className='flex flex-col items-center pt-2 text-center'>
            <span className='text-heading-5 text-Gray-800 font-Medium pb-1'>
              No templates found!
            </span>
            <span className='font-Regular w-[275px] text-extra-small text-Gray-600'>
              Create your own AI tailored or custom templates
            </span>
            <Button
              className='mt-4 w-[172px] text-extra-small'
              onClick={() => setShowBuildTemplateModal(true)}
              iconLeft={<i className='ri-add-line font-Medium text-[16px]' />}>
              Build New Template
            </Button>
          </div>
        </div>
      ) : (
        <div
          key={radioKey}
          className='max-h-[225px] w-[426px] pr-1 overflow-y-auto styled-scroll'>
          {questionnaires.map((questionnaire) => (
            <RowWithAlignment
              key={questionnaire.id}
              className='bg-white w-full min-h-[45px] py-1 border border-t-0 border-Gray-200 px-4'>
              <Radio
                labelText={questionnaire.name}
                helperText=''
                className='text-Gray-700 !font-Medium'
                name='surveyList'
                onChange={() => setSelectedSurvey(questionnaire)}
              />
              <Badge
                size='large'
                isIcon={true}
                icon={
                  questionnaire.type == 'AI'
                    ? 'ri-app-store-line'
                    : 'ri-draft-line'
                }
                theme={questionnaire.type == 'AI' ? 'light-yellow' : 'purple'}>
                {questionnaire.type == 'AI' ? 'AI' : 'Custom'}
              </Badge>
            </RowWithAlignment>
          ))}
        </div>
      )}
    </Column>
  )
}
