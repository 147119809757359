/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useEffect, useMemo, useState } from 'react'
import CN from 'classnames'

export interface RateProps {
  count?: number | undefined
  className?: string | undefined
  rating?: string
  onChange?: any
  readonly?: boolean
  [x: string]: any
}

export const Rate: FC<RateProps> = ({
  count = 5,
  rating = '0',
  onRating,
  className,
  readonly,
  ...restProps
}: RateProps) => {
  const RateClasses = CN(`rate`, className)

  /** state for handle on hover */
  const [hoverRating, setHoverRating] = useState(0)

  /** state for color handle on clicked */
  const [clickedRate, setClickedRate] = useState(
    rating ? parseInt(rating, 10) : 0,
  )
  /** Synchronize state with props */
  useEffect(() => {
    setClickedRate(parseInt(rating, 10))
  }, [rating])

  /** handle on hover and click events in star icon */
  const starRating = useMemo(() => {
    return Array(Number(count))
      .fill(0)
      .map((_, i) => i + 1)
      .map((idx) => {
        return (
          <i
            key={idx}
            className={CN('cursor-pointer text-[24px] pr-3', {
              'ri-star-fill text-Yellow-300':
                hoverRating >= idx || clickedRate >= idx,
              'ri-star-line text-Gray-400':
                (hoverRating === 0 || hoverRating < idx) && clickedRate < idx,
            })}
            onClick={() => {
              if (!readonly) {
                setClickedRate(idx)
                onRating(idx)
              }
            }}
            onMouseEnter={() => !readonly && setHoverRating(idx)}
            onMouseLeave={() => !readonly && setHoverRating(0)}
          />
        )
      })
  }, [count, rating, hoverRating, clickedRate])

  return (
    <div className={RateClasses} {...restProps}>
      {starRating}
    </div>
  )
}

Rate.defaultProps = {
  count: 5,
  rating: '0',
}

export default Rate
