import { useMutation } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { postAPI } from 'framework/api/http'

interface updateRefereeStatusInterface {
  candidateId: string
  refereeId: string
  status: 'Incomplete' | 'Passed' | 'Failed'
}

export const useUpdateRefereeStatus = () => {
  return useMutation(
    ({ candidateId, refereeId, status }: updateRefereeStatusInterface) => {
      return postAPI(
        `/Candidate/${candidateId}/Referee/${refereeId}/SetStatus?Status=${status}`,
        {},
      )
    },
    {
      onError: () => {
        Toast({
          alertHeader: `Status Change failed`,
          status: 'Error',
        })
      },
    },
  )
}

export default useUpdateRefereeStatus
