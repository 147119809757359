import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

type GenerateCompetenciesListRequest = {
  jobRole: string
  industry: string
  jobDescription?: string
  typeOfReferenceCheck: string
}

export const useGenerateCompetenciesList = () => {
  const mutate = useMutation((request: GenerateCompetenciesListRequest) => {
    return postAPI('/AI/VertexAI/CompetenciesList', { request })
  })
  return mutate
}
