import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

export type CreateAccountDto = {
  firstName: string
  email: string
  phoneNumber: string
  password: string
  countryCode: string
  subDomain?: string
}

export const useOnboardingSignUp = () => {
  const mutate = useMutation((dto: CreateAccountDto) => {
    return postAPI(`/onboarding/signup`, {
      ...dto,
    })
  })

  return mutate
}
