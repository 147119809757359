import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

type GenerateReferenceSentimentRequest = {
  cId: string
  selectedReferee: string[]
}

export const useGenerateReferenceSentiment = () => {
  const mutate = useMutation((request: GenerateReferenceSentimentRequest) => {
    return postAPI('/AI/VertexAI/Candidate/ReferenceSentiment', {
      ...request,
    })
  })
  return mutate
}
