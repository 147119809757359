import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Radio, SimpleSelect, Toast } from '@labourhub/labour-hub-ds'
import { CandidateProfileBasicDetailsType } from 'api/candidates'
import { useGetClientLogo, useGetClientSelectOptions } from 'api/clients'
import { useGenerateExecutiveSummary } from 'api/executiveSummary'
import { CandidateReferee } from 'framework/api/methods'
import { ModalProps, ReportPreviewData, SelectOptionDto } from 'types'

import { Accordion, PageLoader } from 'components/atoms'
import { Column } from 'components/atoms/Column'
import { Row } from 'components/atoms/Row'
import { Checkbox } from 'components/core/Checkbox'
import { Modal, ModalFooter } from 'components/molecules'
import { ReferenceReportPreviewModal } from 'components/ui/reports/reference-report'

type RefereeReferenceReportDownloadModalProps = ModalProps & {
  candidateDetails: CandidateProfileBasicDetailsType
  referee: CandidateReferee
}

export const RefereeReferenceReportDownloadModal = ({
  isModalActive,
  setIsModalActive,
  candidateDetails,
  referee,
}: RefereeReferenceReportDownloadModalProps) => {
  const notify = (props: any) => Toast(props)

  const [showReportView, setShowReportView] = useState(false)
  const [logoUrl, setLogoUrl] = useState<string | undefined>(undefined)
  const [clientOptions, setClientOptions] = useState<SelectOptionDto[]>([])
  const [previewData, setPreviewData] = useState<ReportPreviewData | undefined>(
    undefined,
  )
  const [executiveSummaryCheckBox, setExecutiveSummaryCheckBox] =
    useState(false)
  const [selectedClientOption, setSelectedClientOption] =
    useState<SelectOptionDto | null>(null)

  const [renderKeys, setRenderKeys] = useState<any>({
    reportType: 0,
    reportSetting: 0,
  })

  const [formBody, setFormBody] = useState({
    reportType: '',
    reportSetting: 'companyBrandOnly',
  })

  const { refetch: refreshClientSelectOptions } = useGetClientSelectOptions(
    '',
    (options) => setClientOptions(options),
  )

  useGetClientLogo(selectedClientOption?.value ?? '', (data) =>
    setLogoUrl(data.logoUrl),
  )

  useEffect(() => {
    if (isModalActive) {
      refreshClientSelectOptions()
      setRenderKeys({
        ...renderKeys,
        cancellation: Math.random(),
        reason: Math.random(),
      })
      setExecutiveSummaryCheckBox(false)
    }
  }, [isModalActive])

  const closeModal = () => {
    setFormBody({
      reportType: '',
      reportSetting: 'companyBrandOnly',
    })
    setRenderKeys({
      ...renderKeys,
      reportType: Math.random(),
      reportSetting: Math.random(),
    })
    setExecutiveSummaryCheckBox(false)
    setIsModalActive(false)
    setShowReportView(false)
  }

  const renderClientDetail = () => {
    return (
      <Column>
        <Row className='w-full space-x-4'>
          <SimpleSelect
            value={{
              label: selectedClientOption?.label,
              value: selectedClientOption?.value,
            }}
            onChange={(selectedItem: any) => {
              setSelectedClientOption(selectedItem)
              setLogoUrl(undefined)
            }}
            label='Client List'
            options={clientOptions}
            placeholder='Select a Client'
            className='min-w-[225px]'
          />
          {logoUrl && (
            <img
              src={logoUrl}
              alt=''
              className='rounded h-[60px] w-[200px] mt-1'
            />
          )}
        </Row>
        <Row className='w-full space-x-4'>
          <Link to='/settings?tab=4' className='mt-2 font-Medium text-Blue-700'>
            Add New Client
          </Link>
        </Row>
      </Column>
    )
  }

  const resetClientOption = () => {
    setSelectedClientOption(null)
    setLogoUrl(undefined)
  }

  const { mutate: generateExecutiveSummary, isLoading: isSummaryGenerating } =
    useGenerateExecutiveSummary()

  const handleDownload = () => {
    if (
      formBody.reportSetting == 'bothBrands' ||
      formBody.reportSetting == 'clientBrandOnly'
    ) {
      if (clientOptions.length == 0) {
        notify({
          alertBody:
            'Clients details not available. Please add a new client or select a different option.',
          status: 'Warning',
        })
        return
      }

      if (!logoUrl) {
        notify({
          alertBody: 'Please select a client logo',
          status: 'Warning',
        })
        return
      }
    }

    if (executiveSummaryCheckBox) {
      generateExecutiveSummary(
        {
          cId: referee.candidateId,
          selectedReferee: [referee.id],
        },
        {
          onSuccess: ({ data }: any) => {
            setPreviewData({
              referees: [referee],
              executiveSummary: data?.summary,
              clientId: selectedClientOption?.value,
              clientLogoUrl: logoUrl,
              hasClientLogo: formBody.reportSetting != 'companyBrandOnly',
              hasCompanyLogo: formBody.reportSetting != 'clientBrandOnly',
            })
            setShowReportView(true)
          },
          onError: ({ response: { data: errData } }: any) => {
            notify({
              alertHeader: 'Error...!',
              alertBody: errData?.message,
              status: 'Error',
            })
          },
        },
      )
    } else {
      setPreviewData({
        referees: [referee],
        clientId: selectedClientOption?.value,
        clientLogoUrl: logoUrl,
        hasClientLogo: formBody.reportSetting != 'companyBrandOnly',
        hasCompanyLogo: formBody.reportSetting != 'clientBrandOnly',
      })
      setShowReportView(true)
    }
  }

  useEffect(() => {
    setPreviewData({
      referees: [referee],
      clientId: selectedClientOption?.value,
      clientLogoUrl: logoUrl,
      hasClientLogo: formBody.reportSetting != 'companyBrandOnly',
      hasCompanyLogo: formBody.reportSetting != 'clientBrandOnly',
    })
  }, [formBody, referee, selectedClientOption, logoUrl])

  return (
    <>
      <Modal
        headerTitle='Download Reference Report'
        isFooterShow={true}
        isActive={isModalActive}
        className='!z-[150]'
        onHeaderCloseButtonClick={() => closeModal()}
        onOverlayClick={() => closeModal()}
        modalProps={{
          style: { width: '600px', maxHeight: '90vh' },
        }}>
        {isSummaryGenerating && (
          <PageLoader
            size='xxs'
            hasBgOpacity={false}
            message='Your template is being generated...'
          />
        )}

        <div className='w-full p-6 overflow-y-auto'>
          <div>
            <Checkbox
              className='w-full'
              helperText='A concise summary of referee responses highlighting key points.'
              labelText='Generate Executive Summary'
              defaultChecked={executiveSummaryCheckBox}
              onChange={(e: any) => {
                setExecutiveSummaryCheckBox(e.target.checked)
              }}
            />
            <div className='w-full my-4 border border-Gray-100' />
          </div>

          <div className='flex w-full p-4 mb-2 border rounded bg-Blue-50 text-Blue-800 border-Blue-300'>
            <i className='mr-2 ri-information-fill text-Blue-600'> </i>
            <div className='flex flex-col font-Regular text-Blue-700'>
              You can customize your report's branding in the Settings Panel{' '}
              <Link to='/settings?tab=6' className='underline font-SemiBold'>
                Click Here
              </Link>
            </div>
          </div>

          <div className='w-full mt-4'>
            <Accordion
              heading='Customize this report for a client'
              initialState={false}
              description='Add your client’s branding to reports when sending feedback externally'>
              <div className='mt-5 space-y-4' key={renderKeys.reportSetting}>
                <Radio
                  id='bothBrands'
                  labelText='Include My Company Logo and Client Logo'
                  helperText=''
                  name='reportSetting'
                  onChange={(e: any) => {
                    resetClientOption()

                    setFormBody({
                      ...formBody,
                      reportSetting: e.target.id,
                    })
                  }}
                />

                {formBody.reportSetting == 'bothBrands' && renderClientDetail()}

                <Radio
                  id='clientBrandOnly'
                  labelText='Include Client Logo only (This removes your Company Logo in the report)'
                  helperText=''
                  name='reportSetting'
                  onChange={(e: any) => {
                    resetClientOption()

                    setFormBody({
                      ...formBody,
                      reportSetting: e.target.id,
                    })
                  }}
                />

                {formBody.reportSetting == 'clientBrandOnly' &&
                  renderClientDetail()}
              </div>
            </Accordion>
          </div>
        </div>

        <ModalFooter>
          <div className='flex justify-end flex-1'>
            <Button onClick={() => closeModal()} textTheme='blue' theme='white'>
              Close
            </Button>
            <Button
              onClick={() => handleDownload()}
              isLoading={showReportView || isSummaryGenerating}
              className='ml-2'
              theme='cobalt'>
              Download
            </Button>
          </div>
        </ModalFooter>
      </Modal>

      {previewData && (
        <ReferenceReportPreviewModal
          isModalActive={showReportView}
          setIsModalActive={setShowReportView}
          reportType='Referee Report'
          candidateId={referee.candidateId}
          candidateDetails={candidateDetails}
          stats={{
            refereesProvided:
              candidateDetails.refereeCount -
              candidateDetails.remainingRefereeCount,
            responseReceived:
              candidateDetails.responseStatsCount?.receivedCount || 0,
            declinedReference:
              candidateDetails.responseStatsCount?.declinedCount || 0,
          }}
          sentiment={{
            positiveSentiment:
              referee.refereeResponse?.aiSentimentPercentage
                ?.positiveSentiment || 0,
            negativeSentiment:
              referee.refereeResponse?.aiSentimentPercentage
                ?.negativeSentiment || 0,
            neutralSentiment:
              referee.refereeResponse?.aiSentimentPercentage
                ?.neutralSentiment || 0,
          }}
          previewData={previewData}
        />
      )}
    </>
  )
}
