import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

type UpdateSubscriptionDto = {
  id: string
  fixedQuotas?: {
    referenceCheck: number
    users: number
    sms: number
  }
  payAsYouGoQuota?: number
}

export const useUpdateSubscriptionQuota = () => {
  const mutate = useMutation((dto: UpdateSubscriptionDto) => {
    return putAPI(`/Subscriptions/${dto.id}`, dto)
  })

  return mutate
}
