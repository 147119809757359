import { useEffect, useState } from 'react'
import {
  Button,
  SimpleSelect,
  TextField,
  Toast,
} from '@labourhub/labour-hub-ds'
import { uploadTypes } from 'features/questionnaires/data/uploadTypes'
import { videoPlatformList } from 'features/questionnaires/data/videoPlatform'
import { AssessmentQuestion } from 'features/questionnaires/types'
import { ModalProps } from 'types'

import { Modal, ModalFooter } from 'components/molecules'
import { setEmbededVideoLink } from 'utils/setEmbededVideoLink'

type VideoUploadModalProps = ModalProps & {
  updateQuestion: (updatedQuestion: AssessmentQuestion) => void
  question: AssessmentQuestion
}
const VideoUploadModal = ({
  isModalActive,
  setIsModalActive,
  updateQuestion,
  question,
}: VideoUploadModalProps) => {
  const [editableQuestion, setEditableQuestion] =
    useState<AssessmentQuestion>(question)
  const handleClose = () => {
    setEditableQuestion({
      ...editableQuestion,
      videoLink: '',
      videoPlatform: null,
      uploadType: null,
    })
    setIsModalActive(false)
  }
  const [embedLink, setEmbedLink] = useState('')
  const [selectedPlatform, setSelectedPlatform] = useState<any>('')
  useEffect(() => {
    if (question?.videoLink) {
      setEmbedLink(setEmbededVideoLink(question.videoLink))
    }
  }, [question?.videoLink, question?.videoPlatform])

  useEffect(() => {
    setEditableQuestion({
      ...question,
      uploadType: uploadTypes.Video,
    })
  }, [])

  const handleCreate = () => {
    if (
      editableQuestion.uploadType === uploadTypes.Video &&
      editableQuestion.videoLink &&
      editableQuestion.videoPlatform
    ) {
      setEditableQuestion({
        ...editableQuestion,
        videoLink: editableQuestion.videoLink,
        videoPlatform: editableQuestion.videoPlatform,
        uploadType: uploadTypes.Video,
        image: null,
        imagePath: '',
        imageUrl: '',
      })
      updateQuestion({
        ...question,
        videoLink: editableQuestion.videoLink,
        videoPlatform: editableQuestion.videoPlatform,
        uploadType: uploadTypes.Video,
        image: null,
        imagePath: '',
        imageUrl: '',
      })
      handleClose()
    } else {
      Toast({
        alertHeader: `Please fill all input fields`,
        status: 'Warning',
      })
    }
  }
  const handleRemove = () => {
    setEditableQuestion({
      ...editableQuestion,
      uploadType: '',
      videoLink: '',
      videoPlatform: '',
      image: null,
      imagePath: '',
      imageUrl: '',
    })
    updateQuestion({
      ...question,
      videoLink: '',
      videoPlatform: null,
      uploadType: null,
      image: null,
      imagePath: '',
      imageUrl: '',
    })
    setIsModalActive(false)
  }

  return (
    <>
      <Modal
        isActive={isModalActive}
        onOverlayClick={handleClose}
        modalProps={{
          style: { width: '520px' },
        }}
        onHeaderCloseButtonClick={handleClose}
        primaryButtonProps={{
          style: { marginLeft: '12px' },
        }}
        secondaryButtonProps={{ textTheme: 'black' }}
        isFooterShow={false}
        headerTitle={'Add a Video Link'}
        secondaryButtonTitle='Cancel'>
        <div className='w-full px-8'>
          <div
            className={`my-3 ${
              !isModalActive && 'border-t border-t-Gray-200'
            } w-full flex flex-col gap-3`}>
            {!question?.videoLink && !question?.videoPlatform ? (
              <>
                <div className='mt-3'>
                  <SimpleSelect
                    label='Select a Platform'
                    value={selectedPlatform}
                    className='w-full'
                    onChange={(selectedItem: any) => {
                      setEditableQuestion({
                        ...editableQuestion,
                        videoPlatform: selectedItem.value,
                        uploadType: uploadTypes.Video,
                      })
                      setSelectedPlatform(selectedItem)
                    }}
                    placeholder='Youtube'
                    options={videoPlatformList}
                  />
                </div>
                <div className='mt-3'>
                  <TextField
                    value={editableQuestion?.videoLink}
                    onChange={(e: any) => {
                      setEditableQuestion({
                        ...editableQuestion,
                        videoLink: e.target.value,
                      })
                    }}
                    label='Video Link'
                    placeholder='www.youtube.com/watch?v=0TmwSE_LVYU'
                  />
                </div>
              </>
            ) : null}
            {isModalActive &&
              question?.videoLink &&
              question?.videoPlatform && (
                <>
                  <iframe
                    height={235}
                    className='w-full'
                    src={embedLink}
                    title='Video Preview'
                    frameBorder={0}
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                    allowFullScreen
                  />
                  <a
                    href={question?.videoLink}
                    target='_blank'
                    className='no-underline text-Cobalt-500 mx-auto'>
                    <span>{question?.videoLink}</span>
                  </a>
                </>
              )}
          </div>
        </div>
        <ModalFooter>
          <div className='flex justify-end flex-1'>
            <Button onClick={handleClose} textTheme='black' theme='white'>
              Cancel
            </Button>
            <Button
              onClick={question.videoLink ? handleRemove : handleCreate}
              className='ml-2'
              theme={`${question.videoLink ? 'red' : 'cobalt'}`}>
              {question.videoLink ? (
                <div className='flex gap-3 items-center'>
                  <i className='ri-delete-bin-line text-white text-heading-5'></i>
                  <span>Remove Video</span>
                </div>
              ) : (
                <>Add</>
              )}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default VideoUploadModal
