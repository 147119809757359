import { Button } from '@labourhub/labour-hub-ds'
import notSupported from 'assets/animations/not-supported-error.json'
import LogoMini from 'assets/images/refHubLogoText.svg'
import { useLottie } from 'lottie-react'

type MobileBlockedOverlayProps = {
  [x: string]: any
}

export const MobileBlockedOverlay = ({ mailto }: MobileBlockedOverlayProps) => {
  const AnimatedView = () => {
    const options = {
      animationData: notSupported,
      loop: false,
      autoplay: true,
    }

    const { View } = useLottie(options)
    return View
  }

  return (
    <div className='fixed top-0 left-0 w-full h-full z-50 bg-white'>
      <div className='w-full justify-center items-center py-4 shadow-sm'>
        <img
          src={LogoMini}
          alt=''
          className='flex w-full justify-center items-center h-[25px] px-4'
        />
      </div>

      <div className='flex flex-col w-full h-full items-center justify-start px-2 pt-8'>
        <div className='flex w-[163px] justify-center items-center'>
          <AnimatedView />
        </div>

        <span className='flex text-Gray-800 font-Medium text-heading-4 mt-6 pb-2'>
          Your device is not supported!
        </span>

        <p className='text-Gray-600 font-Regular text-center mb-8'>
          Sorry! Our system is best run with a desktop browser. Mobile devices /
          mobile browsers are not supported at the moment.
        </p>

        <Button
          isBlock
          className='mb-3'
          onClick={() => {
            window.open('https://www.refhub.com.au', '_blank')
          }}>
          Visit Our Website
        </Button>
        <Button
          isBlock
          textTheme='blue'
          theme='white'
          onClick={() =>
            (window.location.href = `mailto:${mailto}?subject=Browser Issue`)
          }>
          Email Us
        </Button>
      </div>
    </div>
  )
}
