import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

type CreateExecutiveSummaryRequest = {
  clientId?: string
  isClientLogoOnly?: boolean
  candidateId: string
  refereeIds: string[]
  type: string
  reportType: string
  summary: string
}

export const useCreateExecutiveSummary = () => {
  const mutate = useMutation((request: CreateExecutiveSummaryRequest) => {
    return postAPI('/ExecutiveSummary', { ...request })
  })
  return mutate
}
