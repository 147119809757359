import { ForwardedRef, forwardRef, useMemo } from 'react'
import { CandidateProfileBasicDetailsType } from 'api/candidates'
import {
  ReportCandidateSentimentDto,
  ReportRefereeStatsCountDto,
} from 'api/executiveSummary'
import { ReportType } from 'features/candidates/data'
import { ReportPreviewData } from 'types'

import { Column } from 'components/atoms/Column'

import {
  ReferenceReportCandidateDetails,
  ReferenceReportCandidateDetailsDto,
  ReportBanner,
  ReportExecutiveSummarySection,
  ReportFooter,
  ReportLogoHeader,
  ReportQuestionnaireSummarySection,
  ReportRefereeDetailsSection,
} from '../../sections'

import '../style.scss'

type ReferenceReportPrintPreviewProps = {
  candidateId: string
  candidateDetails: CandidateProfileBasicDetailsType
  stats: ReportRefereeStatsCountDto
  sentiment: ReportCandidateSentimentDto
  previewData: ReportPreviewData
}

export const ReferenceReportPrintPreview = forwardRef(
  (
    {
      candidateId,
      candidateDetails,
      previewData,
      stats,
      sentiment,
    }: ReferenceReportPrintPreviewProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const candidateReportDetails = useMemo(() => {
      const details: ReferenceReportCandidateDetailsDto = {
        name: candidateDetails.name,
        candidateNo: candidateDetails.candidateNo,
        email: candidateDetails.email,
        phoneNumber: candidateDetails.phone,
        avatarUrl: candidateDetails.avatarUrl,
        jobTitle: candidateDetails.jobTitle,
        consultantName: candidateDetails.consultantName,
      }
      return details
    }, [candidateDetails])

    return (
      <Column className='hidden bg-white a4-preview w-[794px] pointer-events-none'>
        <div ref={ref} className='w-full h-full p-0 m-0'>
          <div style={{ pageBreakBefore: 'always' }}>
            <div className='h-[1123px] relative'>
              <ReportLogoHeader
                clientlogo={previewData.clientLogoUrl}
                hasClientLogo={previewData.hasClientLogo}
                hasCompanyLogo={previewData.hasCompanyLogo}
              />
              <ReportBanner />
              <ReferenceReportCandidateDetails
                details={candidateReportDetails}
                stats={stats}
                sentiment={sentiment}
              />
            </div>
          </div>

          {previewData.executiveSummary && (
            <div
              className='h-[1123px] relative '
              style={{ pageBreakBefore: 'always' }}>
              <ReportExecutiveSummarySection
                executiveSummary={previewData.executiveSummary}
              />
            </div>
          )}

          <div className='relative'>
            {previewData?.referees?.map((referee, index) => (
              <div
                className='relative'
                style={{ pageBreakBefore: 'always' }}
                key={index}>
                <ReportRefereeDetailsSection
                  referee={referee}
                  refereeNumber={index + 1}
                  refereeCount={previewData?.referees?.length}
                />

                <div className='px-6'>
                  <ReportQuestionnaireSummarySection
                    candidateId={candidateId}
                    referee={referee}
                    reportType={ReportType.Reference}
                  />
                </div>
              </div>
            ))}
          </div>

          <ReportFooter isFixed={true} />
        </div>
      </Column>
    )
  },
)
