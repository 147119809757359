export const isValidJSON = (value: string) => {
  try {
    const json = JSON.parse(value)
    if (json && typeof json === 'object') {
      return true
    }
  } catch {
    return false
  }
  return false
}
