import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

type GetRealtimeAssessmentRatingProps = {
  candidateId: string
  assessmentId: string
  assessmentResponseId: string
  jsonSchema: string
  candidateStage: string
}

export const useGetRealtimeAssessmentRating = () => {
  const mutate = useMutation(
    ({
      assessmentId,
      candidateId,
      assessmentResponseId,
      jsonSchema,
      candidateStage,
    }: GetRealtimeAssessmentRatingProps) => {
      return postAPI(
        `assessment/candidates/${candidateId}/assessment/${assessmentId}/calculate-realtime`,
        {
          assessmentResponseId: assessmentResponseId,
          jsonSchema: jsonSchema,
          candidateStage: candidateStage,
        },
      )
    },
  )

  return mutate
}
