import { Dispatch, SetStateAction } from 'react'
import { Modal } from '@labourhub/labour-hub-ds'

type ConvertCandidateEmployeeModelProps = {
  setIsCandidateConvertModalOpen: Dispatch<SetStateAction<boolean>>
  isCandidateConvertModalOpen: boolean
  handleConvertCandidateEmployee: () => void
  convertEmployeeLoading: boolean
  isEmployee: boolean
}

const ConvertCandidateEmployeeModel = ({
  setIsCandidateConvertModalOpen,
  isCandidateConvertModalOpen,
  handleConvertCandidateEmployee,
  convertEmployeeLoading,
  isEmployee,
}: ConvertCandidateEmployeeModelProps) => {
  return (
    <Modal
      isActive={isCandidateConvertModalOpen}
      onClickPrimaryBtn={() => {
        handleConvertCandidateEmployee()
      }}
      onClickSecondaryBtn={() => {
        setIsCandidateConvertModalOpen(false)
      }}
      primaryButtonProps={{
        style: { width: '100%', marginRight: '12px' },
        isLoading: convertEmployeeLoading,
      }}
      secondaryButtonProps={{
        style: { width: '100%', color: '#1F2937' },
      }}
      modalProps={{
        style: { width: '466px' },
      }}
      footerProps={{
        style: {
          justifyContent: 'space-between',
          paddingLeft: '23px',
          paddingRight: '23px',
        },
      }}
      isHeaderShow={false}
      primaryButtonTitle='Yes, Sure'
      secondaryButtonTitle='Close'>
      <div className='flex flex-col items-center justify-center p-6'>
        <div className='flex items-center justify-center w-12 h-12 rounded-full bg-Cobalt-50'>
          <i className='ri-error-warning-line text-Cobalt-500 text-heading-3'></i>
        </div>

        <span className='pt-5 text-Gray-900 text-heading-5 font-Medium'>
          Convert to {!isEmployee ? 'Employee' : 'Candidate'}?
        </span>

        <span className='pt-2 text-center text-Gray-500 text-small font-Regular'>
          Are you sure you want to convert this{' '}
          {isEmployee ? 'employee ' : 'candidate '}
          to {!isEmployee ? 'an employee' : 'a candidate'}?
        </span>
      </div>
    </Modal>
  )
}

export default ConvertCandidateEmployeeModel
