/* eslint-disable no-useless-escape */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  SimpleSelect,
  TextField,
  Toast,
} from '@labourhub/labour-hub-ds'
import { useUpdateOnboardingStepper } from 'api/onboarding'
import { colleagues as colleaguesId } from 'features/onboarding/data'
import {
  setColleagues as setColleaguesStore,
  setCurrentStep,
} from 'features/onboarding/store'
import { getOnboardingStore } from 'features/onboarding/store/selectors'
import { useGetUserRoles } from 'framework/api/methods'

import { isEmail } from 'utils'

export const InviteColleagueSection = () => {
  const dispatch = useDispatch()
  const { currentStep, colleagues: colleaguesObj } =
    useSelector(getOnboardingStore)

  const [colleagues, setColleagues] = useState<any>([
    { name: '', email: '', roleName: '', roleId: '' },
    { name: '', email: '', roleName: '', roleId: '' },
    { name: '', email: '', roleName: '', roleId: '' },
  ])

  const [errorFields, setErrorFields] = useState([
    { name: false, email: false, roleName: false },
    { name: false, email: false, roleName: false },
    { name: false, email: false, roleName: false },
  ])

  const [roleList, setRoleList] = useState([])

  const [row1Valid, setRow1Valid] = useState(true)
  const [row2Valid, setRow2Valid] = useState(true)
  const [row3Valid, setRow3Valid] = useState(true)

  const { data: userGroupDetails } = useGetUserRoles()

  useEffect(() => {
    if (userGroupDetails) {
      const filteredArray: any = userGroupDetails?.roleDetails?.filter(
        (item) =>
          item.roleName !== 'SuperAdministrator' &&
          item.roleName !== 'Candidate',
      )

      const newArray = filteredArray?.map((item) => ({
        label: item.roleName,
        value: item.roleId,
      }))

      newArray.unshift({ label: 'Select Role', value: 'Select Role' })

      setRoleList(newArray)
    }
  }, [userGroupDetails])

  const handleInputChange = (index, field, value) => {
    const updatedColleagues = [...colleagues]
    updatedColleagues[index][field] = value
    setColleagues(updatedColleagues)
    validateRow(index, field, value)
  }

  const validateRow = (index, field, value) => {
    const row = colleagues[index]
    let isRowValid = Object.values(row).every((field) => field !== '')

    if (Object.values(row)?.every((field) => field === '')) {
      isRowValid = true
    }

    setErrorFields((prevErrorFields) => {
      const updatedErrorFields = [...prevErrorFields]

      const updatedColleagues = [...colleagues]

      updatedErrorFields[index] = {
        name: !isRowValid,
        email:
          field === 'email'
            ? !isEmail(value)
              ? true
              : !isRowValid
            : !isEmail(updatedColleagues[index]['email']),
        roleName: !isRowValid,
      }

      if (index === 0) {
        setRow1Valid(isRowValid)
      }

      if (index === 1) {
        setRow2Valid(isRowValid)
      }

      if (index === 2) {
        setRow3Valid(isRowValid)
      }
      return updatedErrorFields
    })
  }

  const handleNextStepClick = () => {
    companyOnboarding()
  }

  const isNextButtonEnabled = () => {
    // Enable the next button if at least one row is valid
    return row1Valid && row2Valid && row3Valid
  }

  /** APi call for the onboarding stepper */
  const {
    mutate: companyOnboardingMutate,
    isLoading: companyOnboardingIsLoading,
  } = useUpdateOnboardingStepper()

  /** Process the onboarding stepper */
  const companyOnboarding = () => {
    companyOnboardingMutate(
      {
        colleagues: {
          id: colleaguesId,
          forms: colleagues?.filter((obj) =>
            Object.values(obj).some((value) => value !== ''),
          ),
        },
      },
      {
        onSuccess: () => {
          dispatch(
            setColleaguesStore({
              id: colleaguesId,
              forms: colleagues,
            }),
          )
          dispatch(setCurrentStep(currentStep + 1))
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: errData?.messageTitle
              ? errData?.messageTitle
              : 'Error!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  useEffect(() => {
    if (colleaguesObj) {
      const updatedData = colleaguesObj.forms?.map((item) => ({
        name: item?.name || '',
        email: item?.email || '',
        roleName: item?.roleName || '',
        roleId: item?.roleId || '',
      }))

      while (updatedData.length < 3) {
        updatedData.push({ name: '', email: '', roleName: '', roleId: '' })
      }

      setColleagues(updatedData)
    }
  }, [colleaguesObj])

  return (
    <div className='flex flex-col items-center justify-center w-7/12'>
      <div className='w-full mt-5 text-left text-heading-2 font-Bold text-Gray-800'>
        Invite Colleagues
      </div>

      <div className='w-full mt-6 text-left text-small font-Medium text-Gray-800'>
        In Ref Hub, collaboration is key. Invite your colleagues to join you in
        streamlining pre-employment checks. Let's get started.
      </div>

      {colleagues?.map((colleague, index) => (
        <div
          key={index}
          className='flex flex-row items-end w-full mt-6 gap-x-5'>
          <TextField
            label='Name'
            placeholder=''
            className='w-1/4'
            value={colleague.name}
            onChange={(e) => handleInputChange(index, 'name', e.target.value)}
            isErrored={errorFields[index].name}
          />

          <TextField
            label='Email Address'
            placeholder=''
            className='w-2/4'
            value={colleague.email}
            onChange={(e) => {
              handleInputChange(index, 'email', e.target.value)
            }}
            isErrored={errorFields[index].email}
          />

          <SimpleSelect
            label='Role'
            placeholder='Select role'
            value={{
              label: colleague.roleName,
              value: colleague.roleId,
            }}
            className='w-1/4'
            onChange={(selectedItem) => {
              handleInputChange(
                index,
                'roleName',
                selectedItem?.label === 'Select Role'
                  ? ''
                  : selectedItem?.label,
              )

              handleInputChange(
                index,
                'roleId',
                selectedItem?.value === 'Select Role'
                  ? ''
                  : selectedItem?.value,
              )
            }}
            options={roleList}
            isErrored={errorFields[index].roleName}
          />
        </div>
      ))}

      {/* next button */}
      <Button
        isBlock
        onClick={handleNextStepClick}
        isLoading={companyOnboardingIsLoading}
        className='mt-6 next-btn'
        isDisabled={
          !isNextButtonEnabled() ||
          errorFields?.some((item) => item.name || item.email || item.roleName)
        }>
        Send Invite
      </Button>
    </div>
  )
}
