/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Avatar, Button, Toast } from '@labourhub/labour-hub-ds'
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr'
import LogoMini from 'assets/images/refHubLogoText.svg'
import xmasBellIcon from 'assets/images/xmas/bell.png'
import xmasBrandLogo from 'assets/images/xmas/brand-logo.png'
import CN from 'classnames'
import {
  getPaymentStore,
  PayAsYouGoSubscriptionPaymentModal,
  setCards,
  useListPaymentCards,
} from 'features/payments'
import { PlanQuotaBadgesRow } from 'features/subscriptions'
import {
  useGetNotificationList,
  useMarkAllAsRead,
  useMarkAsRead,
} from 'framework/api/methods'

import { NotificationPanel, UserDropDown } from 'components/atoms'
import { notificationBaseUrl } from 'config/framework.config'
import { isChristmas } from 'utils'

export type HeaderProps = {
  className?: string | undefined
  [x: string]: any
}

export const Header = ({ className, ...restProps }: HeaderProps) => {
  const {
    headerBarDetails,
    isHeaderBarDetailsUpdated,
    userDetails: { id },
  } = useSelector((state: any) => state.user)

  const navigate = useNavigate()

  const dispatch = useDispatch()

  // set cards
  const { isRefetchPaymentCards } = useSelector(getPaymentStore)

  const { refetch: refetchPaymentCards } = useListPaymentCards((cards) => {
    dispatch(setCards(cards))
  })

  useEffect(() => {
    refetchPaymentCards()
  }, [isRefetchPaymentCards])

  const [profileData, setProfileData] = useState<any>({
    profileAvatar: null,
    agencyLogo: null,
    userName: '',
    userRole: '',
    subscription: null,
  })

  const [showUserDropDown, setShowUserDropDown] = useState(false)

  /** update toolbar user and agency details when update */
  useEffect(() => {
    const { secondaryLogoUrl, userProfile } = headerBarDetails || {}

    setProfileData({
      ...setProfileData,
      profileAvatar: userProfile?.avatarUrl,
      agencyLogo: secondaryLogoUrl,
      userName: userProfile?.firstName + ` ` + userProfile?.lastName,
      userRole: userProfile?.roleName ? userProfile?.roleName : '',
    })
  }, [headerBarDetails, isHeaderBarDetailsUpdated])

  const [connection, setConnection] = useState<null | HubConnection>(null)
  const [isNotificationPanelOpen, setIsNotificationPanelOpen] = useState(false)
  const [isNotificationIconTrue, setIsNotificationIconTrue] = useState(false)
  const [notificationPayload, setNotificationPayload] = useState<any>()
  const [notificationList, setNotificationList] = useState<any>([])
  const [totalNotificationCount, setTotalNotificationCount] = useState(0)

  // read redux state for user details
  const notify = (props: any) => Toast(props)
  const LocalStorageToken = localStorage.getItem('token')
  const sessionStorageToken = sessionStorage.getItem('token')
  const token = LocalStorageToken || sessionStorageToken

  /** Notification Socket */
  useEffect(() => {
    const connection = new HubConnectionBuilder()
      .withUrl(`${notificationBaseUrl}/hubs/notification`, {
        skipNegotiation: true,
        transport: 1,
        accessTokenFactory: () => token || '',
      })
      .withAutomaticReconnect()
      .build()

    setConnection(connection)
  }, [notificationBaseUrl, token])

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          connection.on('BroadcastNotification', (message) => {
            setNotificationPayload(message)
          })

          connection.on('NewNotification', (message) => {
            setNotificationPayload(message)
          })
        })
        .catch((error) => console.log('>>>>', error))
    }
  }, [connection])

  useEffect(() => {
    if (notificationPayload && notificationPayload?.length > 0) {
      const noti = JSON.parse(notificationPayload)

      setIsNotificationIconTrue(true)
      getNotificationList()
      notify({
        alertHeader: noti?.Title,
        alertBody: noti?.Description,
        type: 'success',
        onClickToast: () => {
          if (
            noti.Type === 'Candidate' ||
            noti.Type === 'Referee' ||
            noti.Type === 'Phone Ref'
          ) {
            navigate(
              `/candidate-list/candidate-profile?&candidate_id=${
                noti?.UUID
              }&candidate_name=${noti?.Meta?.split('=')[1]?.replace(/'/g, '')}`,
            )
            setIsNotificationPanelOpen(false)
          }
        },
      })
    }
  }, [notificationPayload])

  /** API Call for get Notification Lost */
  const [paginationForNotification, setPaginationForNotification] = useState({
    take: 10,
    skip: 0,
  })
  const {
    refetch: getNotificationList,
    data: responseData,
    isFetching: fetchingCandidateById,
  } = useGetNotificationList({
    Take: paginationForNotification.take,
    Skip: paginationForNotification.skip,
    UserProfileId: id,
  })

  useEffect(() => {
    id && id?.length > 0 && getNotificationList()
  }, [id, paginationForNotification])

  useEffect(() => {
    const copyOfNotificationList = [...notificationList]

    if (responseData && responseData?.notifications?.length > 0) {
      responseData?.notifications?.forEach((item: any) => {
        const index = copyOfNotificationList?.findIndex(
          (notification: any) => notification.id === item.id,
        )

        if (index === -1 || index === undefined) {
          copyOfNotificationList.push(item)
        } else {
          if (item.isRead !== copyOfNotificationList[index].isRead) {
            copyOfNotificationList[index] = item
          }
        }
      })
      // sort notification list by date
      const sorted = copyOfNotificationList.sort((a: any, b: any) => {
        return new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime()
      })
      setNotificationList(sorted)
    }
    responseData && setTotalNotificationCount(responseData?.totalCount)

    const isNotificationAvailable = responseData?.notifications?.find(
      (item) => item.isRead === false,
    )

    setIsNotificationIconTrue(isNotificationAvailable ? true : false)
  }, [responseData])

  const viewMoreNotification = () => {
    setPaginationForNotification({
      ...paginationForNotification,
      take: paginationForNotification.take + 10,
    })
  }

  useEffect(() => {
    if (!isNotificationPanelOpen) {
      setNotificationList(notificationList.splice(0, 10))
      setPaginationForNotification({
        ...paginationForNotification,
        skip: 0,
      })
    }
  }, [isNotificationPanelOpen])

  /** Mark as read Notification */
  const { mutateAsync: markAsRead } = useMarkAsRead()

  /** Mark as read Notification. */
  const markThisAsRead = (notificationId: string) => {
    markAsRead({
      notificationIds: [notificationId],
    }).then(() => {
      getNotificationList()
    })
  }

  /** Mark as All read Notification */
  const { mutate: markAllAsRead } = useMarkAllAsRead()

  /** Mark All as read Notification. */
  async function markThisAllAsRead() {
    markAllAsRead(
      {
        UserProfileId: id,
      },
      {
        onSuccess: () => {
          getNotificationList()
        },

        onError: () => {
          getNotificationList()
        },
      },
    )
  }

  const HeaderClasses = CN(
    'flex justify-between items-center w-full border-b-[1px] border-[#E5E7EB] py-[10px] relative',
    className,
    {},
  )

  return (
    <div className={HeaderClasses} {...restProps}>
      <div className='flex'>
        {/** LabourHub Logo */}
        <img
          src={isChristmas() ? xmasBrandLogo : LogoMini}
          alt=''
          className={CN(
            'flex items-center justify-center h-[24px] px-4 cursor-pointer',
            {
              'h-[38px]': isChristmas(),
            },
          )}
          onClick={() => {
            navigate('/')
          }}
        />
      </div>

      <div className='flex items-center px-4 divide-x divide-Gray-200'>
        <div className='flex items-center justify-center mr-3 gap-x-5'>
          <PlanQuotaBadgesRow />

          {/** Agency Logo */}
          {profileData?.agencyLogo && (
            <img
              src={profileData?.agencyLogo}
              alt=''
              className='mr-5 h-[34px] rounded-lg border border-Gray-200'
            />
          )}
        </div>

        <div className='flex items-center gap-5'>
          <div className='relative ml-3 notification-icon'>
            {/** Notification  Icon */}
            <Button
              icon={
                isChristmas() ? (
                  <img
                    src={xmasBellIcon}
                    alt='Notification'
                    className='scale-150'
                  />
                ) : (
                  <i
                    className='ri-notification-2-line text-heading-5 text-Gray-600'
                    data-tip='Notification'
                    data-for='Notification'
                  />
                )
              }
              textTheme='blue'
              theme='link'
              isIconOnly
              id='notification-action-button'
              onClick={() => {
                setIsNotificationIconTrue(false)
                setIsNotificationPanelOpen(!isNotificationPanelOpen)
              }}
            />
            {isNotificationIconTrue && (
              <div className='w-[6px] h-[6px] bg-Red-500 rounded-full absolute top-[6px] right-[6px]'></div>
            )}
            {isNotificationPanelOpen && (
              <div className='absolute'>
                <NotificationPanel
                  list={notificationList}
                  viewMoreNotification={viewMoreNotification}
                  fetchingCandidateById={fetchingCandidateById}
                  markThisAsRead={markThisAsRead}
                  setIsNotificationPanelOpen={setIsNotificationPanelOpen}
                  markThisAllAsRead={markThisAllAsRead}
                  totalNotificationCount={totalNotificationCount}
                />
              </div>
            )}
          </div>

          {/** Profile Picture */}
          <div className='pr-4'>
            <Avatar
              isHeader={false}
              imageURL={
                profileData?.profileAvatar
                  ? profileData?.profileAvatar
                  : undefined
              }
              className='cursor-pointer'
              id='user-action-button'
              size='sm'
              title={profileData?.userName}
              onClick={() => {
                setShowUserDropDown(!showUserDropDown)
              }}
            />
            {showUserDropDown && (
              <div className='absolute top-[46px] right-0'>
                <UserDropDown
                  setShowUserDropDown={setShowUserDropDown}
                  profileData={profileData}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <PayAsYouGoSubscriptionPaymentModal />
    </div>
  )
}
