import { createSlice } from '@reduxjs/toolkit'
import { PaymentCardDto } from 'api/payments'
import { PayAsYouGoSubscriptionPackageType } from 'features/subscriptions'

export type PaymentStoreProps = {
  isPayAsYouGoPaymentModalActive: boolean
  isRefetchBillingHistory: boolean
  isRefetchPaymentCards: boolean
  payAsYouGoCredits: Record<PayAsYouGoSubscriptionPackageType, number>
  cards: PaymentCardDto[]
}

const initialState: PaymentStoreProps = {
  isPayAsYouGoPaymentModalActive: false,
  isRefetchBillingHistory: false,
  isRefetchPaymentCards: false,
  payAsYouGoCredits: {
    PoliceCheck: 0,
    VolunteerCheck: 0,
    RightToWorkCheck: 0,
    ReferenceCheck: 0,
    // AssessmentCredit: 0,
  },
  cards: [],
}

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setPayAsYouGoCreditsModalActiveState(state, { payload }) {
      state.isPayAsYouGoPaymentModalActive = payload

      // Reset the quantity to 0 for each item
      if (payload == false) {
        Object.keys(state.payAsYouGoCredits)?.forEach((key) => {
          state.payAsYouGoCredits[key] = 0
        })
      }
    },

    toggleIsRefetchBillingHistoryState(state) {
      state.isRefetchBillingHistory = !state.isRefetchBillingHistory
    },

    toggleIsRefetchPaymentCardsState(state) {
      state.isRefetchPaymentCards = !state.isRefetchPaymentCards
    },

    setPayAsYouGoCreditsState(state, { payload }) {
      const { key, quantity } = payload
      state.payAsYouGoCredits[key] = quantity
    },

    setCardsState(state, { payload }) {
      state.cards = payload
    },
  },
})

export const {
  setPayAsYouGoCreditsModalActiveState,
  setPayAsYouGoCreditsState,
  setCardsState,
  toggleIsRefetchBillingHistoryState,
  toggleIsRefetchPaymentCardsState,
} = paymentSlice.actions

export default paymentSlice.reducer
