import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

type UserProfile = {
  identityGuid: string
  roleId: string
  roleName: string
  userName: string
  email: string
  firstName: string
  lastName: string
  displayName: string | null
  jobTitle: string | null
  phoneNumber: string | null
  dob: string | null
  gender: string | null
  alternateEmail: string | null
  emailSignature: string | null
  avatarUrl: string | null
  isActive: boolean
  status: string
  passwordResetRequestedOn: string | null
  tenantId: string
  id: string
  createdOn: string
  updatedOn: string
}

type GetUserProfileListResp = {
  userProfiles: Array<UserProfile>
  totalCount: number
  take: number
  skip: number
  sortColumnName: string
  sortDirection: string
  messageTitle: string | null
  message: string
}

export const useGetUserProfileList = (
  take: string,
  skip: string,
  roleName: string | null,
  status: string,
  search: string,
  sortColumnName: string | undefined,
  sortDirection: string | undefined,
  dateJoined: string | undefined,
  onSuccess,
) => {
  return useQuery(
    [
      'GET_USER_LIST_BY_GROUP_ID',
      take,
      skip,
      search,
      sortColumnName,
      sortDirection,
    ],
    async () => {
      let URI = `UserProfile/List?Take=${take}&Skip=${skip}&RoleName=${roleName}&Search=${search}&sortColumnName=${sortColumnName}&sortDirection=${sortDirection}`
      if (dateJoined) {
        URI = URI + `&DateJoined=${dateJoined}`
      }
      if (status) {
        URI = URI + `&Status=${status}`
      }

      const res = await get(URI)
      return res.data as GetUserProfileListResp
    },
    {
      onError: () => {
        Toast({
          alertHeader: 'Error fetching user list',
          status: 'Error',
        })
      },
      onSuccess,
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )
}
