import { ReferenceCheckType } from 'types'

import { Column } from 'components/atoms/Column'
import { H5 } from 'components/atoms/Headings'

import {
  ReferenceReportCandidateDetails,
  ReportBanner,
  ReportFooter,
  ReportLogoHeader,
  ReportRefereeDetailsSection,
} from '../../sections'

import { SampleQuestionnaireSummarySection } from './SampleQuestionnaireSummarySection'

export const SampleReferenceReportPreview = () => {
  const sampleCandidateDetails = {
    name: 'BROOKYLN HECTOR',
    candidateNo: '18283901030',
    email: 'brooklyn.hector@example.com',
    phoneNumber: '+61 (209) 555-0104',
    jobTitle: 'Data Analyst-Contract',
    totalReferees: 4,
    consultantName: 'John Doe',
  }

  const sampleRefereeDetails = {
    firstName: 'Shannon',
    lastName: 'Hector',
    email: 'shannon@mailinator.com',
    phone: '+61 491 570 110',
    company: 'Woolsworth Pvt Ltd',
    jobTitle: 'Reporting Mananger',
    startDate: '2012-05-10T00:00:00',
    endDate: '2022-01-05T00:00:00',
    isCurrentlyWorking: false,
    status: 'Passed',
    referenceType: {
      type: ReferenceCheckType.Employment,
    },
    refereeResponse: {
      responseType: 'WEB_CHECK',
      updatedOn: '2024-04-04T06:19:24.9883468',
    },
  }

  return (
    <Column className='p-4 overflow-y-auto bg-white h-[842px] styled-scroll'>
      <H5 title='Preview of Sample Report' className='font-Medium' />
      <div className='w-full mb-4 border border-Gray-100' />
      <div className='relative py-2 border pointer-events-none border-Gray-200'>
        <div className='h-[1123px] relative bg-white border-b-2 border-dashed border-Gray-300'>
          <ReportLogoHeader isSampleView={true} />
          <ReportBanner />
          <ReferenceReportCandidateDetails
            isSampleView={true}
            details={sampleCandidateDetails}
            stats={{
              refereesProvided: 2,
              responseReceived: 1,
              declinedReference: 1,
            }}
            sentiment={{
              positiveSentiment: 60,
              negativeSentiment: 30,
              neutralSentiment: 10,
            }}
          />
          <ReportFooter isFixed={false} pageNumber={1} />
        </div>
        <div className='min-h-[250mm] relative'>
          <ReportRefereeDetailsSection
            referee={sampleRefereeDetails}
            refereeCount={1}
          />
          <div className='pb-[60px] px-6'>
            <SampleQuestionnaireSummarySection />
          </div>
          <ReportFooter pageNumber={2} />
        </div>
      </div>
    </Column>
  )
}
