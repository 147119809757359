import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

type UpdateCandidateNoteBody = {
  description: string
}

type UpdateCandidateNoteRequest = {
  id: string
  body: UpdateCandidateNoteBody
}

export const useUpdateCandidateNote = () => {
  const mutate = useMutation(({ id, body }: UpdateCandidateNoteRequest) => {
    return putAPI(`/Candidate/Note/${id}`, body)
  })

  return mutate
}
