/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { Tabs, Toast } from '@labourhub/labour-hub-ds'
import { useGetAgencySettings, useGetCandidateSettings } from 'api'
import { useGetCandidateBasicDetailsById } from 'api/candidates'
import { CandidateEmployeeProfileCard } from 'features/candidates/components/CandidateEmployeeProfileCard'
import { CustomSurveysTab } from 'features/candidates/components/CustomSurveysTab'
import { EligibilityTab } from 'features/candidates/components/EligibilityTab'
import { RefereesTab } from 'features/candidates/components/RefereesTab'
import { SurveyResponsesTab } from 'features/candidates/components/SurveyResponsesTab'
import {
  setAgencyAppSettings,
  setCandidateAppSettings,
} from 'features/settings'
import { useBreadcrumbs } from 'hooks'
import { setBackFunction } from 'store/reducers/layout/layoutSlice'

import { PageLoader } from 'components/atoms'

import { CandidateTab } from '../data/CandidateTab'

export const EmployeeProfilePage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()

  const notify = (props: any) => Toast(props)

  const [activeCenterTabId, setActiveCenterTabId] = useState('')
  const [activeTabId, setActiveTabId] = useState('')

  useEffect(() => {
    const tab = searchParams.get('tab')
    const centertab = searchParams.get('centertab')
    setActiveCenterTabId(centertab ? centertab : CandidateTab.OverviewTab)
    setActiveTabId(tab ? tab : CandidateTab.RefereesTab)
  }, [])

  const handleTabChange = (id: any) => {
    if (searchParams.has('centertab')) {
      searchParams.set('centertab', id)
    } else {
      searchParams.append('centertab', id)
    }

    navigate(
      {
        pathname: location.pathname,
        search: searchParams.toString(),
      },
      { replace: true },
    )
  }

  const updateUrl = (tab) => {
    if (searchParams.has('tab')) {
      searchParams.set('tab', tab)
    } else {
      searchParams.append('tab', tab)
    }

    navigate(
      {
        pathname: location.pathname,
        search: searchParams.toString(),
      },
      { replace: true },
    )
  }

  /** showing  edit candidate drawer states */

  const candidateId: string = searchParams.get('candidate_id') || ''

  // Add breadcrumbs
  useBreadcrumbs()

  useEffect(() => {
    dispatch(
      setBackFunction(() => {
        navigate('/employee-list')
      }),
    )
  }, [])

  const {
    refetch: getCandidateById,
    data: candidateDetails,
    isFetching,
    isLoading,
  } = useGetCandidateBasicDetailsById(candidateId)

  /** API call for getting candidate settings */
  const { refetch: refetchCandidateSettings } = useGetCandidateSettings(
    candidateId,
    (candidateSettings) => dispatch(setCandidateAppSettings(candidateSettings)),
  )

  const { refetch: refetchAgencySettings } = useGetAgencySettings(
    (appSettings) => dispatch(setAgencyAppSettings(appSettings)),
  )

  useEffect(() => {
    refetchAgencySettings()
    refetchCandidateSettings()
  }, [candidateDetails])

  if (isLoading || isFetching || !candidateDetails)
    return <PageLoader size='xxs' className='absolute' />

  if (!candidateDetails?.basicCandidateProfile)
    return <Navigate to='/candidate-list' />

  const tabDetails = [
    {
      id: CandidateTab.SurveyResponsesTab,
      tabButtonContent: 'Survey Responses',
      tabHeaderIconList: candidateDetails?.basicCandidateProfile
        ? [
            {
              content:
                candidateDetails?.basicCandidateProfile
                  ?.surveyResponseStatsCount?.totalCount,
              color: 'bg-Cobalt-500',
            },
          ]
        : [],
      tabContent: (
        <SurveyResponsesTab
          candidateId={candidateId}
          candidateDetails={candidateDetails}
        />
      ),
    },
    {
      id: CandidateTab.CustomSurveysTab,
      tabButtonContent: 'Send Surveys',
      tabContent: (
        <CustomSurveysTab
          candidateId={candidateId}
          candidateDetails={candidateDetails?.basicCandidateProfile}
          getCandidateById={getCandidateById}
        />
      ),
    },
    {
      id: CandidateTab.RefereesTab,
      tabButtonContent: 'Referees',
      tabHeaderIconList: candidateDetails?.basicCandidateProfile
        ? [
            {
              content:
                candidateDetails?.basicCandidateProfile?.refereeCount -
                candidateDetails?.basicCandidateProfile?.remainingRefereeCount,
              color: 'bg-Cobalt-500',
            },
          ]
        : [],

      tabContent: (
        <RefereesTab
          candidateId={candidateId}
          candidateDetails={candidateDetails}
          getCandidateById={getCandidateById}
          notify={notify}
        />
      ),
    },
    {
      id: CandidateTab.EligibilityTab,
      tabButtonContent: 'Eligibility',
      tabContent: (
        <EligibilityTab
          candidateId={candidateId}
          candidateName={candidateDetails?.basicCandidateProfile.name}
        />
      ),
    },
  ]

  return (
    <div className='flex space-x-5'>
      <div className='w-[425px]'>
        {candidateDetails?.basicCandidateProfile && (
          <CandidateEmployeeProfileCard
            candidateDetails={candidateDetails?.basicCandidateProfile}
            getCandidateById={getCandidateById}
            activeTabId={activeCenterTabId}
            onTabChange={handleTabChange}
          />
        )}
      </div>
      <div className='w-[calc(100%-440px)]'>
        <Tabs
          className='!h-fit'
          tabHeaderButtonClassName='tabs text-small py-3'
          contentClassName='h-fit pt-5 pb-10'
          defaultTabId={activeTabId}
          tabDetails={tabDetails}
          onChange={(e) => updateUrl(e)}
        />
      </div>
    </div>
  )
}
