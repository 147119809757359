import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Modal } from '@labourhub/labour-hub-ds'
import { useGetActiveSubscriptions } from 'api//subscriptions'
import CN from 'classnames'
import { setAgency } from 'features/agencies/store/actions'
import { CardBillingAddressDto, PaymentErrorType } from 'features/payments'
import { setSubscriptions } from 'features/subscriptions'
import { useGetAgencyDetails } from 'framework/api/methods'

import { RowWithAlignment } from 'components/atoms/Row'
import { TIME_FORMAT, timeToAESTWithFormat } from 'utils'

import { AmountSummary, InvoiceDto } from '../types'

import { InvoiceAmountSummary } from './InvoiceAmountSummary'
import { InvoiceTemplateModal } from './InvoiceTemplateModal'
import { PayAsYouGoInvoiceNotificationBox } from './PayAsYouGoInvoiceNotificationBox'

type PaymentInvoiceModalProps = {
  isActive: boolean
  setIsActive: any
  isSuccess?: boolean
  invoiceDownloadMode?: boolean
  cardBillingAddress?: CardBillingAddressDto
  invoice: InvoiceDto | null
  paymentError?: PaymentErrorType
  onCloseAction?: () => void
}

export const PaymentInvoiceModal = ({
  isActive,
  setIsActive,
  isSuccess = true,
  invoiceDownloadMode = false,
  cardBillingAddress,
  invoice,
  paymentError,
  onCloseAction,
}: PaymentInvoiceModalProps) => {
  const dispatch = useDispatch()
  const [invoiceTemplateModal, setInvoiceTemplateModal] = useState(false)

  const invoiceDateString = invoice?.createdOn
    ? timeToAESTWithFormat(invoice?.createdOn)
    : null
  const invoiceTimeString = invoice?.createdOn
    ? timeToAESTWithFormat(invoice?.createdOn, TIME_FORMAT)
    : null

  // fetch active subscriptions
  const { refetch: refetchSubscriptions } = useGetActiveSubscriptions(
    (subscriptions) => {
      dispatch(setSubscriptions(subscriptions))
    },
  )

  useEffect(() => {
    const onboardingToken = sessionStorage.getItem('onboardingToken')
    if (onboardingToken) {
      refetchSubscriptions()
    }
  }, [isActive])

  const amountSummary: AmountSummary = {
    subTotal: invoice?.totalSubTotalAmount ?? 0,
    promotionAmount: invoice?.totalPromotionAmount ?? 0,
    tax: invoice?.totalTaxAmount ?? 0,
    totalAmount: invoice?.totalPaymentAmount ?? 0,
  }

  const [agencyProfileDetails, setAgencyProfileDetails] = useState<any>({})

  const getAgencyDetailsOnSuccess = ({ data: successData }: any) => {
    setAgencyProfileDetails(successData?.agencyProfile)
    dispatch(setAgency(successData?.agencyProfile))
  }

  /** Get agency profile details on error */
  const getAgencyDetailsOnError = () => {
    //
  }

  /** API call for getting agency profile details */
  const { refetch: getAgencyProfileDetailsRefetch } = useGetAgencyDetails(
    {},
    getAgencyDetailsOnSuccess,
    getAgencyDetailsOnError,
  )

  useEffect(() => {
    isActive && getAgencyProfileDetailsRefetch()
  }, [isActive])

  return (
    <>
      <Modal
        isActive={isActive}
        onClickPrimaryBtn={() => {
          isSuccess && setInvoiceTemplateModal(true)
        }}
        onHeaderCloseButtonClick={() => {
          setIsActive(false)
          onCloseAction && onCloseAction()
        }}
        onClickSecondaryBtn={() => {
          setIsActive(false)
          onCloseAction && onCloseAction()
        }}
        onOverlayClick={() => {
          setIsActive(false)
          onCloseAction && onCloseAction()
        }}
        isFooterShow={true}
        modalProps={{
          style: { width: '600px' },
        }}
        primaryButtonProps={{
          theme: 'cobalt',
          iconLeft: isSuccess && <i className='ri-download-line'></i>,
        }}
        secondaryButtonProps={{
          textTheme: 'black',
        }}
        footerButtonAlignment={isSuccess ? 'left' : 'right'}
        headerTitle={isSuccess ? 'Payment Successful' : 'Payment Failed '}
        primaryButtonTitle={isSuccess ? 'Download PDF' : ''}
        secondaryButtonTitle='Close'>
        <div
          className={CN(
            'flex w-full h-full flex-col justify-start items-center',
            {
              'max-h-[70vh] min-h-[50vh] pt-6': isSuccess,
              'max-h-[80vh] min-h-[20vh] py-9 px-[80px]': !isSuccess,
            },
          )}>
          <PayAsYouGoInvoiceNotificationBox
            isSuccess={isSuccess}
            message={
              isSuccess
                ? invoiceDownloadMode
                  ? `Invoice Number: #${invoice?.invoiceNumber}`
                  : 'Thank you for your payment'
                : 'Unable to process your payment'
            }
            paymentError={paymentError}
            dateString={invoiceDateString}
            timeString={invoiceTimeString}
          />

          {invoice && invoice.items?.length > 0 && (
            <div className='flex-col flex-1 w-full px-6 pt-4 overflow-y-auto h-fit styled-scroll '>
              {invoice.items.map((item) => (
                <RowWithAlignment
                  key={item.name}
                  className='py-2 pb-3 border-b border-Gray-100'>
                  <div className='flex flex-col'>
                    <span className='text-Gray-800 font-SemiBold'>
                      {item.name} {invoice.isTrial && '(Trial)'}
                    </span>
                    {item.quantity && (
                      <span className='text-Gray-500 font-Regular'>
                        QTY X {item.quantity}
                      </span>
                    )}
                  </div>

                  <div className='flex flex-col text-right'>
                    {item.promotionAmount > 0 ? (
                      <span className='block text-base font-SemiBold'>
                        <del className='text-Red-600 text-small'>
                          ${item.subTotalAmount?.toFixed(2)}
                        </del>{' '}
                        ${item.totalAmount?.toFixed(2)}
                      </span>
                    ) : (
                      <span className='block text-base text-Gray-800 font-SemiBold'>
                        ${item.subTotalAmount?.toFixed(2)}
                      </span>
                    )}
                    {item.unitPrice && (
                      <span className='text-Gray-500 font-Regular'>
                        ${item.unitPrice?.toFixed(2)} each
                      </span>
                    )}
                  </div>
                </RowWithAlignment>
              ))}

              <InvoiceAmountSummary
                className='mt-3'
                amountSummary={amountSummary}
              />
            </div>
          )}
        </div>
      </Modal>

      <InvoiceTemplateModal
        isActive={invoiceTemplateModal}
        setClose={setInvoiceTemplateModal}
        invoice={invoice}
        cardBillingAddress={cardBillingAddress}
        amountSummary={amountSummary}
        agencyProfileDetails={agencyProfileDetails}
      />
    </>
  )
}
