/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import ReactTooltip from 'react-tooltip'
import { Badge } from '@labourhub/labour-hub-ds'
import { assessmentTypes } from 'features/candidates/data/assessmentTypes'
import {
  addonImages,
  difficultyImages,
} from 'features/candidates/data/difficultyLevelImages'
import moment from 'moment'

import { MoreMenuButton } from 'components/atoms'
import { cn, timeToAESTWithSeconds } from 'utils'

export const AssessmentResponsesListColumn = (
  setSelectedResponse: any,
  setIsReminderModalActive: any,
  setViewAssessmentModal: any,
  setViewAssessmentModalReport: any,
  setIsDownloadAssessmentReport: any, // Fixed typo in variable name
  setIsCancelRequestModalActive: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const createTooltip = (id: string) => (
    <ReactTooltip
      place='right'
      id={id}
      effect='solid'
      textColor='#FFFFFF'
      backgroundColor='#4B5563'
      delayShow={800}
    />
  )

  const getBadgeTheme = (status: string) => {
    switch (status) {
      case 'AWAITING_RESPONSE':
        return 'yellow'
      case 'REQUEST_DECLINED':
      case 'REQUEST_CANCELLED':
        return 'red'
      case 'RESPONSE_RECEVIED':
        return 'green'
      case 'RESPONSE_IN_REVIEW':
        return 'yellow'
      case 'RESPONSE_EVALUATED':
        return 'blue'
      case 'REQUEST_EXPIRED':
        return 'purple'
      default:
        return 'gray'
    }
  }

  const getResponseText = (status: string) => {
    switch (status) {
      case 'RESPONSE_RECEVIED':
        return 'Received'
      case 'AWAITING_RESPONSE':
        return 'Awaiting'
      case 'RESPONSE_IN_REVIEW':
        return 'In Review'
      case 'RESPONSE_EVALUATED':
        return 'Evaluated'
      case 'REQUEST_DECLINED':
        return 'Declined'
      case 'REQUEST_CANCELLED':
        return 'Cancelled'
      case 'REQUEST_EXPIRED':
        return 'Expired'
      default:
        return 'Not Sent'
    }
  }

  const COLUMNS = [
    {
      Header: 'ASSESSMENT NAME',
      accessor: 'AssessmentName',
      forSorting: 'AssessmentName',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'md:w-[175px] lg:w[200px] w-[200px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: ({ row: { original } }: any) => (
        <div>
          <p
            className='cursor-pointer text-Cobalt-400 flex items-center gap-0.5'
            data-tip={
              original.assessmentTemplateType !== 'AddOn'
                ? original?.assessmentName
                : `Add-on for [${original?.mainAssessmentName}]`
            }
            data-for={original?.assessmentId}
            onClick={() => {
              setSelectedResponse(original)
              if (
                [
                  'RESPONSE_RECEVIED',
                  'RESPONSE_EVALUATED',
                  'RESPONSE_IN_REVIEW',
                ].includes(original?.status)
              ) {
                setViewAssessmentModalReport(true)
              } else if (
                original?.assessmentName !== assessmentTypes.PersonalityTest
              ) {
                setViewAssessmentModal(true)
              }
            }}>
            {createTooltip(original?.assessmentId)}
            <span className='truncate max-w-[120px]'>
              {original?.assessmentName}
            </span>
            <span>
              <img
                className={cn({
                  'w-[20px]': original.assessmentTemplateType !== 'AddOn',
                  'w-[12px] h-[12px]':
                    original.assessmentTemplateType === 'AddOn',
                })}
                src={
                  original.assessmentTemplateType !== 'AddOn'
                    ? difficultyImages[original.difficultyLevel]
                    : addonImages.Addon
                }
                alt={original.difficultyLevel}
              />
            </span>
          </p>
        </div>
      ),
    },
    {
      Header: 'SENT BY',
      accessor: 'SentBy',
      forSorting: 'SentBy',
      isSortable: false,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'md:w-[130px] lg:w[150px] w-[150px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: ({ row: { original } }: any) => (
        <div
          className='truncate'
          data-tip={original?.sentBy}
          data-for={original?.assessmentId}>
          {createTooltip(original?.assessmentId)}
          {original?.sentBy}
        </div>
      ),
    },
    {
      Header: 'EMAIL',
      accessor: 'Response',
      forSorting: 'Status',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'md:w-[120px] lg:w[140px] w-[140px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: ({ row: { original } }: any) => {
        const badgeTheme = getBadgeTheme(original?.status)
        return (
          <div>
            <Badge size='large' theme={badgeTheme} isDot={true}>
              <div className='text-extra-small font-Medium leading-3'>
                {['AWAITING_RESPONSE', 'REQUEST_CANCELLED'].includes(
                  original?.status,
                )
                  ? 'Sent'
                  : 'Opened'}
              </div>
            </Badge>
          </div>
        )
      },
    },
    {
      Header: 'SENT ON',
      accessor: 'SentOn',
      forSorting: 'SentOn',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'md:w-[120px] lg:w[140px] w-[140px] pl-6',
      headerClassName: 'flex items-center justify-start',
      cellClassName: 'flex items-center justify-start',
      Cell: ({ row: { original } }: any) => {
        const createdDateStringWithSeconds = timeToAESTWithSeconds(
          original?.sentOn,
        )
        const createdDateString = moment(original?.sentOn).format('DD/MM/yyyy')
        return (
          <div
            data-tip={createdDateStringWithSeconds}
            data-for={original?.assessmentId}>
            {createTooltip(original?.assessmentId)}
            {createdDateString}
          </div>
        )
      },
    },
    {
      Header: 'COMPLETED ON',
      accessor: 'CompletedOn',
      forSorting: 'CompletedOn',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'md:w-[150px] lg:w[170px] w-[170px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: ({ row: { original } }: any) => {
        const updatedDateStringWithSecond = original?.completedOn
          ? timeToAESTWithSeconds(original?.completedOn)
          : '-'
        const updatedDateString = original?.completedOn
          ? moment(original?.completedOn).format('DD/MM/yyyy')
          : '-'
        return (
          <div
            data-tip={updatedDateStringWithSecond}
            data-for={original?.assessmentId}>
            {createTooltip(original?.assessmentId)}
            {updatedDateString}
          </div>
        )
      },
    },
    {
      Header: 'RESPONSE',
      accessor: 'Status',
      forSorting: 'Status',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'md:w-[130px] lg:w[150px] w-[150px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: ({ row: { original } }: any) => {
        const badgeTheme = getBadgeTheme(original?.status)
        return (
          <div>
            <Badge size='large' theme={badgeTheme} isDot={true}>
              <div className='text-extra-small font-Medium leading-3'>
                {getResponseText(original?.status)}
              </div>
            </Badge>
          </div>
        )
      },
    },
    {
      Header: '',
      accessor: 'action',
      forSorting: '',
      isSortable: false,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'md:w-[50px] lg:w[60px] w-[60px]',
      headerClassName: 'flex items-center justify-center',
      cellClassName: 'flex items-center justify-center',
      Cell: ({ row: { original } }: any) => {
        if (
          ['REQUEST_DECLINED', 'REQUEST_CANCELLED', 'REQUEST_EXPIRED'].includes(
            original.status,
          )
        )
          return null

        return (
          <div className='relative'>
            <MoreMenuButton
              className='ml-2'
              actionButtons={
                original.status === 'AWAITING_RESPONSE'
                  ? [
                      {
                        iconClass: 'ri-send-plane-line',
                        onClick: () => {
                          setSelectedResponse(original)
                          setIsReminderModalActive(true)
                        },
                        title: 'Send Reminder',
                        status: 'normal',
                      },
                      {
                        disabled: true,
                        iconClass: 'ri-eye-line',
                        onClick: () => {
                          // setSelectedResponse(original)
                          // setViewAssessmentModal(true)
                        },
                        title: 'View Assessment',
                        status: 'normal',
                      },
                      {
                        iconClass: 'ri-mail-close-line',
                        onClick: () => {
                          setSelectedResponse(original)
                          setIsCancelRequestModalActive(true)
                        },
                        title: 'Cancel Request',
                        status: 'danger',
                      },
                    ]
                  : original.status === 'RESPONSE_RECEVIED'
                  ? [
                      {
                        iconClass: 'ri-eye-line',
                        onClick: () => {
                          setSelectedResponse(original)
                          setViewAssessmentModalReport(true)
                        },
                        title: 'View Assessment',
                        status: 'normal',
                      },
                      {
                        iconClass: 'ri-download-2-line',
                        onClick: async () => {
                          setSelectedResponse(original)
                          setIsDownloadAssessmentReport(true)
                        },
                        title: 'Download Assessment',
                        status: 'normal',
                      },
                    ]
                  : original.status === 'RESPONSE_IN_REVIEW'
                  ? [
                      {
                        iconClass: 'ri-eye-line',
                        onClick: () => {
                          setSelectedResponse(original)
                          setViewAssessmentModalReport(true)
                        },
                        title: 'View Assessment',
                        status: 'normal',
                      },
                      {
                        iconClass: 'ri-download-2-line',
                        onClick: () => {
                          setSelectedResponse(original)
                          setIsDownloadAssessmentReport(true)
                        },
                        title: 'Download Assessment',
                        status: 'normal',
                      },
                    ]
                  : original.status === 'RESPONSE_EVALUATED'
                  ? [
                      {
                        iconClass: 'ri-eye-line',
                        onClick: () => {
                          setSelectedResponse(original)
                          setViewAssessmentModalReport(true)
                        },
                        title: 'View Assessment',
                        status: 'normal',
                      },
                      {
                        iconClass: 'ri-download-2-line',
                        onClick: () => {
                          setSelectedResponse(original)
                          setIsDownloadAssessmentReport(true)
                        },
                        title: 'Download Assessment',
                        status: 'normal',
                      },
                    ]
                  : []
              }
            />
          </div>
        )
      },
    },
  ]
  return COLUMNS
}
