import { useEffect, useMemo, useRef, useState } from 'react'
import { UniqueIdentifier } from '@dnd-kit/core'
import { Modal } from '@labourhub/labour-hub-ds'
import { useGetDynamicLibraryFormAssessment } from 'api/assessment'
import { assessmentQuestionTypes } from 'features/questionnaires/data'
import { AssessmentQuestion } from 'features/questionnaires/types'
import { ModalProps } from 'types'

import { PageLoader } from 'components/atoms'
import { updateNode } from 'utils/updateNode'

import { AssessmentPreviewQuestionCardsColumn } from '../AssessmentTable/AssessmentPreviewModal/AssessmentPreviewQuestionCardsColumn'

type AssessmentLibraryPreviewModalProps = ModalProps & {
  templateId: string
  templateName: string
}

export const AssessmentLibraryPreviewModal = ({
  isModalActive,
  setIsModalActive,
  templateId,
  templateName,
}: AssessmentLibraryPreviewModalProps) => {
  /** get dynamic form */
  const {
    data: dynamicForm,
    refetch: getDynamicForm,
    isLoading: getDynamicFormIsLoading,
  } = useGetDynamicLibraryFormAssessment(templateId)

  const previewData = useMemo(() => {
    return (
      dynamicForm?.assessment?.questions.map((question: any) => {
        const baseData = {
          id: question.id,
          isRequired: question.isRequired,
          title: question.title,
          meta: [...question.meta.enum],
          answer: question.meta.answer,
          correctAnswer: question.meta.correctAnswer,
          uploadType: question.meta.uploadType,
          imagePath: question.meta.imageUrl,
          imageUrl: question.meta.publicImageUrl,
          videoLink: question.meta.videoUrl,
          videoPlatform: question.meta.videoPlatform,
          isAIGenerated: question.meta.isAIGenerated,
        }

        switch (question.type) {
          case 'text':
            return {
              ...baseData,
              icon: 'ri-text-spacing',
              name: 'Short Answer',
              type: 'text_area',
            }
          case 'text_area':
          case 'textarea':
            return {
              ...baseData,
              icon: 'ri-text-wrap',
              name: 'Long Answer',
              type: 'text_area',
            }
          case 'select':
            return {
              ...baseData,
              icon: 'ri-star-line',
              name: 'Select Box',
              type: 'select',
            }
          case 'rating':
            return {
              ...baseData,
              icon: 'ri-star-line',
              name: 'Rating',
              type: 'rating',
            }
          case 'checkbox':
            return {
              ...baseData,
              icon: 'ri-checkbox-multiple-line',
              name: 'Multi Choice',
              type: 'checkbox',
            }
          case 'radio':
            return {
              ...baseData,
              icon: 'ri-radio-button-line',
              name: 'Yes/No',
              type: 'radio',
            }
          case 'radio_pos_detail':
            return {
              ...baseData,
              icon: 'ri-checkbox-circle-line',
              name: 'Detailed Yes',
              type: 'radio_pos_detail',
            }
          case 'radio_neg_detail':
            return {
              ...baseData,
              icon: 'ri-checkbox-circle-line',
              name: 'Detailed No',
              type: 'radio_neg_detail',
            }
          case 'heading':
            return {
              ...baseData,
              icon: 'ri-heading',
              name: 'Heading',
              type: 'heading',
            }
          case 'paragraph':
            return {
              ...baseData,
              icon: 'ri-text',
              name: 'Paragraph',
              type: 'paragraph',
            }
          default:
            return baseData
        }
      }) || []
    )
  }, [dynamicForm])

  const componentRef = useRef<HTMLDivElement>(null)

  const [itemsMap, setItemsMap] = useState<
    Map<UniqueIdentifier, AssessmentQuestion[]>
  >(
    new Map([
      ['question-types', assessmentQuestionTypes],
      ['question-cards', []],
    ]),
  )

  useEffect(() => {
    if (templateId) {
      setItemsMap((prevItemsMap) => {
        const newMap = new Map(prevItemsMap)
        newMap.set('question-cards', previewData)
        return newMap
      })
    }
  }, [previewData, templateId])

  /** call get dynamic form when template id updated */
  useEffect(() => {
    templateId && isModalActive && getDynamicForm()
  }, [isModalActive, templateId])

  const onModalClose = () => setIsModalActive(false)

  useEffect(() => {
    updateNode(componentRef)
  }, [dynamicForm])

  const questionCardItems: AssessmentQuestion[] = useMemo(
    () => itemsMap.get('question-cards') || [],
    [itemsMap],
  )

  return (
    <Modal
      headerTitle={`Preview - ${templateName}`}
      customWidth='1000px'
      className='!z-[300]'
      isActive={isModalActive}
      primaryButtonTheme={'cobalt'}
      primaryButtonTitle=''
      footerButtonAlignment='right'
      onClickSecondaryBtn={onModalClose}
      onHeaderCloseButtonClick={onModalClose}
      onOverlayClick={onModalClose}
      secondaryButtonTitle='Close'>
      {(getDynamicFormIsLoading || questionCardItems.length == 0) && (
        <PageLoader size='xxs' />
      )}

      <div
        ref={componentRef}
        className='flex flex-col w-full justify-start px-8 py-5 min-h-[30vh] max-h-[80vh] overflow-y-auto'>
        <AssessmentPreviewQuestionCardsColumn
          templateName={templateName}
          items={questionCardItems}
        />
      </div>
    </Modal>
  )
}
