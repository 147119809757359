import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  AppSettingKey,
  getAgencyAppSettings,
  isSettingEnabled,
} from 'features/settings'

const PoliceCheckLayout = ({ children }) => {
  const { userDetails }: any = useSelector<any>((state) => state.user)
  const [validatePolicyCheck, setValidatePolicyCheck] = useState(false)
  const agencyAppSettings = useSelector(getAgencyAppSettings)
  const policyCheckEnabled = isSettingEnabled(
    AppSettingKey.NccPoliceCheck,
    agencyAppSettings,
  )
  const isSuperAdmin = userDetails?.roleName === 'SuperAdministrator'
  useEffect(() => {
    if (policyCheckEnabled || isSuperAdmin) {
      setValidatePolicyCheck(true)
    } else {
      setValidatePolicyCheck(false)
    }
  }, [policyCheckEnabled])

  return <>{validatePolicyCheck && children}</>
}

export default PoliceCheckLayout
