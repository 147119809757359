import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Toast } from '@labourhub/labour-hub-ds'
import { CancelRequestDto, useCancelFixedSubscription } from 'api/subscriptions'
import { toggleIsRefetchBillingHistory } from 'features/payments'
import { setIsHeaderBarDetailsUpdated } from 'store/reducers/user/userSlice'
import { ModalProps } from 'types'

import { RowWithAlignment } from 'components/atoms/Row'
import { Modal, ModalFooter } from 'components/molecules'

import { ConfirmYourCancellationForm } from './ConfirmYourCancellationForm'
import { ConsideringACancelingForm } from './ConsideringACancelingForm'

type CancelSubscriptionModalProps = ModalProps

export const CancelSubscriptionModal = ({
  isModalActive,
  setIsModalActive,
}: CancelSubscriptionModalProps) => {
  const dispatch = useDispatch()
  const notify = (props: any) => Toast(props)

  const { isHeaderBarDetailsUpdated } = useSelector((state: any) => state.user)

  const [isContinue, setIsContinue] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [confirmCheckBox, setConfirmCheckBox] = useState(false)

  const [state, setState] = useState<CancelRequestDto>({
    isCancelImmediately: false,
    cancellationReason: '',
    comment: '',
  })

  const [renderKeys, setRenderKeys] = useState<any>({
    cancellation: 0,
    reason: 0,
  })

  useEffect(() => {
    if (isModalActive) {
      setRenderKeys({
        ...renderKeys,
        cancellation: Math.random(),
        reason: Math.random(),
      })
      setConfirmCheckBox(false)
    }
  }, [isModalActive])

  const handleClose = () => {
    setState({
      isCancelImmediately: false,
      cancellationReason: '',
      comment: '',
    })

    setRenderKeys({
      ...renderKeys,
      cancellation: Math.random(),
      reason: Math.random(),
    })

    setIsContinue(false)
    setIsModalActive(false)
  }

  const { mutate } = useCancelFixedSubscription()

  const handleCancel = () => {
    setIsLoading(true)

    if (state.cancellationReason) {
      mutate(
        {
          cancellation: {
            isCancelImmediately: false,
            cancellationReason: state.cancellationReason,
            comment: state.comment,
          },
        },
        {
          onSuccess: ({ data: successData }: any) => {
            notify({
              alertHeader: successData?.message,
              alertBody: '',
              status: 'Success',
            })
            dispatch(setIsHeaderBarDetailsUpdated(!isHeaderBarDetailsUpdated))
            dispatch(toggleIsRefetchBillingHistory())
          },
          onError: ({ response: { data: errData } }: any) => {
            notify({
              alertHeader: 'Error...!',
              alertBody: errData?.message,
              status: 'Error',
            })
          },
          onSettled: () => {
            setIsLoading(false)
            handleClose()
          },
        },
      )
    }
  }

  return (
    <Modal
      isActive={isModalActive}
      onHeaderCloseButtonClick={() => handleClose()}
      onClickSecondaryBtn={() => handleClose()}
      onOverlayClick={() => handleClose()}
      modalProps={{
        style: { width: '574px' },
      }}
      headerTitle='Cancel Subscription'
      modalContainerClassNames='relative'
      closeButtonClassName='z-[5]'>
      {isContinue ? (
        <ConsideringACancelingForm
          key={renderKeys.reason}
          state={state}
          setState={setState}
        />
      ) : (
        <ConfirmYourCancellationForm
          confirmCheckBox={confirmCheckBox}
          setConfirmCheckBox={setConfirmCheckBox}
        />
      )}

      <ModalFooter>
        <RowWithAlignment justifyAlignment='end'>
          <Button
            onClick={handleClose}
            className='mr-3'
            textTheme='black'
            theme='white'>
            {isContinue ? 'Not Now' : 'Keep Pack'}
          </Button>

          <Button
            onClick={
              isContinue ? () => handleCancel() : () => setIsContinue(true)
            }
            isLoading={isLoading}
            isDisabled={
              !confirmCheckBox ||
              (isContinue && state.cancellationReason == null) ||
              (isContinue && state.cancellationReason == '')
            }
            theme='cobalt'
            textTheme='white'>
            {isContinue ? 'Send Feedback' : 'Cancel Pack'}
          </Button>
        </RowWithAlignment>
      </ModalFooter>
    </Modal>
  )
}
