export const videoPlatformList = [
  {
    value: 'Youtube',
    label: 'YouTube',
  },
  {
    value: 'Facebook',
    label: 'FaceBook',
  },
]
