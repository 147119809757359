export const dateAndTimeToCustomFormat = (timestamp: any) => {
  const date = new Date(timestamp)

  // Get the time in 12-hour clock format
  const hours = date.getHours() % 12 || 12
  const minutes = date.getMinutes()
  const ampm = date.getHours() >= 12 ? 'PM' : 'AM'

  // Get the date components
  const day = date.getDate()
  const month = date.toLocaleString('default', { month: 'short' })
  const year = date.getFullYear()

  // Create the formatted string
  const formattedTimestamp = `${hours}:${
    minutes < 10 ? '0' : ''
  }${minutes} ${ampm}, ${day} ${month} ${year}`

  return formattedTimestamp
}
