const moment = require('moment-timezone')

export const DATE_FORMAT = 'DD/MM/YYYY'
export const TIME_FORMAT = 'h:mm A'
export const timeToAESTWithSeconds = (incomingDate: any) => {
  const localtz = Intl.DateTimeFormat().resolvedOptions().timeZone
  const dateString = moment
    .utc(incomingDate)
    .tz(localtz)
    .format('DD/MM/YYYY h:mm:ss A')

  return dateString
}

export const timeToAESTWithFormat = (
  incomingDate: any,
  format: string = DATE_FORMAT,
) => {
  const localtz = Intl.DateTimeFormat().resolvedOptions().timeZone
  return moment.utc(incomingDate).tz(localtz).format(format)
}
