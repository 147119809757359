import { getS3File } from 'utils'

import {
  CreateAccountSection,
  SelectPackage,
  TellUsAboutBusinessSection,
  TellUsAboutYouSection,
  VerifyAccountSection,
} from '../components/SignUpStepper/sections'
import { SignupStepperComponent } from '../types'

export const mandatorySignupStepperComponents: SignupStepperComponent[] = [
  {
    component: <CreateAccountSection />,
    backgroundImage: getS3File('images/sign_up.svg'),
    isActionsClickable: false,
    header: '',
    description: '',
  },
  {
    component: <VerifyAccountSection />,
    backgroundImage: getS3File('verifyAccount.svg'),
    isActionsClickable: false,
    header: 'We have sent you a SMS!',
    description:
      'Verifying your phone number confirms your identity, reduces the risk of miscommunication, and ensures that important notifications reach you promptly.',
  },
  {
    component: <SelectPackage />,
    backgroundImage: undefined,
    isActionsClickable: false,
    header: '',
    description: '',
  },
  {
    component: <TellUsAboutYouSection />,
    backgroundImage: getS3File('aboutYou.svg'),
    isActionsClickable: false,
    header: 'Set Your Profile Picture!',
    description:
      "Add a photo to personalize your profile. This picture is visible only to your colleagues, so no worries, candidates and referees won't be rating your selfie game.",
  },
  {
    component: <TellUsAboutBusinessSection />,
    backgroundImage: getS3File('aboutYourBusiness.svg'),
    isActionsClickable: false,
    header: 'About Your Sub Domain',
    description:
      "Your subdomain provides a unique URL for your company profile and for any online forms you send to candidates or referees. Think of it as your business's special address in the Ref Hub world!",
  },
]
