import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import {
  Button,
  Checkbox,
  ProgressBar,
  TextField,
  Toast,
} from '@labourhub/labour-hub-ds'
import { useOnboardingSignUp } from 'api/onboarding'
import CN from 'classnames'
import {
  setCreateAccount,
  setCurrentStep,
  setTenantId,
  setUserProfileId,
} from 'features/onboarding/store'
import { getOnboardingStore } from 'features/onboarding/store/selectors'

import { PhoneInputField } from 'components/atoms'
import { isEmail, isPhoneNumber, passwordStrengthCalculate } from 'utils'

export const CreateAccountSection = () => {
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const subDomain = searchParams.get('sub-domain') || undefined

  const [showPassword, setShowPassword] = useState(false)
  const [showProgressBar, setShowProgressBar] = useState(false)
  const [passwordStrength, setPasswordStrength] = useState(0)
  const [passwordHelperText, setPasswordHelperText] = useState('')
  const [isAgreed, setIsAgreed] = useState(false)
  /** next button disable state */
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false)

  const [userFormDetails, setUserFormDetails] = useState({
    firstName: '',
    email: '',
    phoneNumber: '',
    password: '',
    countryCode: '',
  })

  const [isErrorField, setIsErrorField] = useState<any>({
    firstName: false,
    email: false,
    phoneNumber: false,
  })

  useEffect(() => {
    localStorage.clear()
    sessionStorage.clear()
  }, [])

  const validateInputs = () => {
    const { firstName, email, phoneNumber } = userFormDetails

    setIsErrorField({
      ...isErrorField,
      firstName: firstName ? false : true,
      email: isEmail(email) ? false : true,
      phoneNumber: isPhoneNumber(phoneNumber) ? false : true,
    })

    const isAllValidFields =
      firstName && isEmail(email) && isPhoneNumber(phoneNumber)

    return isAllValidFields
  }

  const { currentStep } = useSelector(getOnboardingStore)

  const onNextStepClick = () => {
    const valid = validateInputs()

    if (valid) {
      onboardingSignUp()
    } else {
      Toast({
        alertHeader: 'Invalid input type.',
        status: 'Warning',
      })
    }
  }

  /** APi call for the onboarding stepper */
  const {
    mutate: onboardingSignUpMutate,
    isLoading: onboardingSignUpIsLoading,
  } = useOnboardingSignUp()

  /** Process the onboarding stepper */
  const onboardingSignUp = () => {
    onboardingSignUpMutate(
      {
        firstName: userFormDetails?.firstName,
        email: userFormDetails?.email,
        phoneNumber: userFormDetails?.phoneNumber,
        password: userFormDetails?.password,
        countryCode: userFormDetails?.countryCode,
        subDomain,
      },

      {
        onSuccess: ({ data: successData }: any) => {
          dispatch(
            setCreateAccount({
              firstName: userFormDetails?.firstName,
              email: userFormDetails?.email,
              phoneNumber: userFormDetails?.phoneNumber,
              password: userFormDetails?.password,
              countryCode: userFormDetails?.countryCode,
            }),
          )
          dispatch(setCurrentStep(currentStep + 1))
          dispatch(setTenantId(successData?.userProfile?.tenantId))
          dispatch(setUserProfileId(successData?.userProfile?.id))
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** handle next button disable */
  useEffect(() => {
    const { firstName, email, phoneNumber, password } = userFormDetails || {}

    firstName &&
    email &&
    phoneNumber &&
    passwordStrength > 90 &&
    password &&
    isAgreed
      ? setIsNextButtonDisabled(false)
      : setIsNextButtonDisabled(true)
  }, [userFormDetails, isAgreed])

  return (
    <div className='flex flex-col items-start justify-center w-5/12'>
      <div className='text-heading-2 font-Medium text-Gray-800'>
        Create Account
      </div>

      <TextField
        type='text'
        label='First Name'
        placeholder=''
        className='w-full pt-5'
        isErrored={isErrorField.firstName}
        value={userFormDetails?.firstName}
        onChange={(e: any) => {
          setUserFormDetails({
            ...userFormDetails,
            firstName: e.target.value,
          })
        }}
      />

      {/* email form field */}
      <TextField
        type='text'
        label='Email Address'
        placeholder='your@email.com'
        className='w-full pt-5'
        isErrored={isErrorField.email}
        value={userFormDetails?.email}
        onChange={(e: any) => {
          setUserFormDetails({
            ...userFormDetails,
            email: e.target.value,
          })
        }}
      />

      <PhoneInputField
        label='Phone Number'
        value={userFormDetails.phoneNumber}
        onChangeWithCountry={(number: any, countryCode: string) =>
          setUserFormDetails({
            ...userFormDetails,
            phoneNumber: number,
            countryCode,
          })
        }
        isErrored={isErrorField.phoneNumber}
        className='w-full pt-5'
      />

      {/* password form field */}
      <div className='flex flex-col w-full mt-5'>
        <TextField
          type={showPassword ? 'text' : 'password'}
          label='Password'
          placeholder='••••••••'
          helperText={passwordHelperText}
          inputProps={{
            autoComplete: 'new-password',
          }}
          isErrored={passwordHelperText === '' ? false : true}
          onBlur={() => {
            if (passwordStrength < 90) {
              setPasswordHelperText(
                'Password must be at least 8 characters long and include a number, a special character, a lowercase character, and an uppercase character.',
              )
            } else {
              setPasswordHelperText('')
            }
          }}
          onChange={(e: any) => {
            const value = e.target.value
            setPasswordHelperText('')

            setUserFormDetails({
              ...userFormDetails,
              password: value,
            })

            if (value !== '') {
              setShowProgressBar(true)
              setPasswordStrength(passwordStrengthCalculate(value))
            } else {
              setShowProgressBar(false)
            }
          }}
          iconAfter={
            userFormDetails?.password !== '' ? (
              <i
                className={CN('hover:cursor-pointer', {
                  'ri-eye-off-line': showPassword == false,
                  'ri-eye-line': showPassword == true,
                })}
              />
            ) : (
              <></>
            )
          }
          iconAfterOnClick={() => {
            setShowPassword(!showPassword)
          }}
        />

        {showProgressBar && (
          <div
            className={CN({
              'mt-2': passwordHelperText === '',
              'mt-0': passwordHelperText !== '',
            })}>
            <ProgressBar progressCount={passwordStrength} isForPassword />
          </div>
        )}
      </div>

      <div className='flex items-center justify-start mt-5'>
        <Checkbox
          labelText=''
          checked={isAgreed}
          labelTextClassName='font-Regular'
          checkboxClassName='ml-0'
          onChange={(e: any) => {
            setIsAgreed(e.target.checked)
          }}
        />

        <span className='ml-2 text-extra-small font-Regular tracking-[0.25px]'>
          I hereby accept and agree to the
          <a
            href='//www.refhub.com.au/privacy-policy'
            target='_blank'
            className='mx-1 text-Cobalt-600 font-Medium'>
            terms & conditions
          </a>
          of RefHub
        </span>
      </div>

      {/* next button */}
      <Button
        isBlock
        isDisabled={isNextButtonDisabled}
        isLoading={onboardingSignUpIsLoading}
        onClick={onNextStepClick}
        className='mt-5 next-btn'>
        Next
      </Button>

      <div className='flex items-center justify-center w-full mt-5 text-small font-Medium'>
        Already have an account?
        <a href='/auth/login' className='px-1 font-Bold text-Cobalt-600'>
          Log in
        </a>
        now
      </div>
    </div>
  )
}
