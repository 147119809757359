import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

type ConfirmTwoFARequest = {
  username: string
  code: string
}

export const useConfirmTwoFA = () => {
  const mutate = useMutation((dto: ConfirmTwoFARequest) => {
    return postAPI(`/identity/2fa/confirm`, dto)
  })
  return mutate
}
