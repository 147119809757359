/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Toast } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { useGetDashboardDetailsForTable } from 'framework/api/methods'

import { UITable } from 'components/common/UITable'

import { CandidateListColumn } from './CandidateListColumn'

type CandidateListProps = {
  [x: string]: any
}

export const CandidateList = ({
  isActive,
  className,
  dashboardDetailModal,
  fromDate,
  toDate,
  navigation,
  ...restProps
}: CandidateListProps) => {
  /** Table States & Variables */
  const [tableData, setTableData] = useState<any>({})
  const [paginationVariables, setPaginationVariables] = useState<any>({
    skip: tableData.skip || 0,
    take: tableData.take || 20,
    search: '',
    sortColumnName: '',
    sortDirection: '',
  })

  /** Table Filter states & Variables */
  const [pageNumber, setPageNumber] = useState(1)

  const navigate = useNavigate()
  const notify = (props: any) => Toast(props)

  /** candidate List on success */
  const onSuccess = (data: any) => {
    setTableData(data || {})
  }

  /** candidate List on error */
  const onError = ({ response: { data: errData } }: any) => {
    notify({
      alertHeader: errData.messageTitle ? errData.messageTitle : '',
      alertBody: errData.message ? errData.message : 'Error!',
      status: 'Error',
    })
  }

  /** API Handling request for Get All candidates List */
  const {
    refetch: getTableData,
    isLoading: getDashboardDetailsForTableIsLoading,
    isFetching: getDashboardDetailsForTableIsFetching,
  } = useGetDashboardDetailsForTable(
    {
      take: paginationVariables.take,
      skip: paginationVariables.skip,
      sortColumnName: paginationVariables.sortColumnName,
      sortDirection: paginationVariables.sortDirection,
      from: fromDate,
      to: toDate,
      module: dashboardDetailModal?.module,
      status: dashboardDetailModal?.status,
    },
    onSuccess,
    onError,
  )

  useEffect(() => {
    isActive && getTableData()
  }, [paginationVariables, isActive])

  /** Candidate Table Column Handling */
  const tableColumns = useMemo(() => {
    if (dashboardDetailModal?.module === 'Candidate') {
      if (
        tableData?.candidateDetails &&
        tableData?.candidateDetails?.length > 0
      ) {
        const columns: any = CandidateListColumn({ navigate, navigation })
        return columns
      }
    } else {
      if (
        tableData?.fullMetricsDetails &&
        tableData?.fullMetricsDetails?.length > 0
      ) {
        const columns: any = CandidateListColumn(navigate)
        return columns
      }
    }
    return []
  }, [tableData])

  const CandidateListClasses = CN(`candidate-list w-full h-full`, className)

  return (
    <div className={CandidateListClasses} {...restProps}>
      <div className='w-full h-[calc(90vh-80px)] relative'>
        <div
          className={CN(
            'styled-scroll overflow-auto w-full h-[calc(100%-55px)] bg-white',
          )}>
          <UITable
            data={
              tableData?.candidateDetails || tableData?.fullMetricsDetails || []
            }
            isLoading={
              getDashboardDetailsForTableIsLoading ||
              getDashboardDetailsForTableIsFetching
            }
            className=''
            columns={tableColumns}
            allowRowSelection={false}
            isSorted={true}
            hasFooter={true}
            hasCheckBox={true}
            isHeader={true}
            isFilterEnabled={false}
            paginationVariables={paginationVariables}
            setPaginationVariables={setPaginationVariables}
            totalRowCount={tableData?.totalCount || 0}
            isManualSortBy={true}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </div>
      </div>
    </div>
  )
}
