import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Checkbox, Toast } from '@labourhub/labour-hub-ds'
import {
  ReportSettingItem,
  ReportSettingItemKey,
  useDeleteReportSettingBackgroundImage,
  useGetReportSettings,
  useUpdateReportSettingBackgroundImage,
  useUpdateReportSettings,
} from 'api/settings/report'
import unionIcon from 'assets/images/union.svg'
import { INITIAL_REPORT_SETTINGS } from 'features/settings/data'
import { reportAccordionSettings } from 'features/settings/data/reportAccordionSettings'
import { ReportTypes } from 'features/settings/data/reportTypes'
import {
  setAssessmentReportSettings,
  setReportSettings,
} from 'features/settings/store'
import { ReportSettingsForm } from 'features/settings/types'

import { Accordion, PageLoader } from 'components/atoms'
import { Column } from 'components/atoms/Column'
import { ImageUpload } from 'components/ui/ImageUpload'
import { cn } from 'utils'

import { BackgroundImageRemoveModal } from './BackgroundImageRemoveModal'
import { CompanyLogoRemoveModal } from './CompanyLogoRemoveModal'
import { CustomizableEditCompanyLogoModal } from './CustomizableEditCompanyLogoModal'

export const ReportSettingsAccordionForm = ({
  type = ReportTypes.REFERENCE,
}: {
  type?: (typeof ReportTypes)[keyof typeof ReportTypes]
}) => {
  const dispatch = useDispatch()
  const [settingsId, setSettingsId] = useState<string>('')
  const [initialFormBody, setInitialFormBody] = useState<ReportSettingItem[]>(
    [],
  )
  const [formBody, setFormBody] = useState<ReportSettingItem[]>([])
  const [bgImage, setBgImage] = useState<File | null>(null)
  const [bgFile, setBgFile] = useState<any>(null)
  const [bgImageUpload, setBgImageUpload] = useState<boolean>(false)
  const [removeBgImage, setRemoveBgImage] = useState(false)
  const [removeCompanyLogo, setRemoveCompanyLogo] = useState(false)

  const [imageUrl, setImageUrl] = useState<any>('')
  const [logoFile, setLogoFile] = useState<any>(null)
  const [logoImageUpload, setLogoImageUpload] = useState<boolean>(false)
  const [showUploadLogoModal, setShowUploadLogoModal] = useState(false)

  const [
    isBackgroundImageRemoveModalActive,
    setIsBackgroundImageRemoveModalActive,
  ] = useState(false)

  const [isCompanyLogoRemoveModalActive, setIsCompanyLogoRemoveModalActive] =
    useState(false)

  const [selectedTextColor, setSelectedTextColor] = useState<string | null>(
    null,
  )

  const [selectedLineColor, setSelectedLineColor] = useState<string | null>(
    null,
  )

  const { refetch, isFetching, isLoading } = useGetReportSettings(
    { reportType: type },
    (data) => {
      setSettingsId(data.id)
      setInitialFormBody(data.reportSettings)
      setFormBody(data.reportSettings)
      updateReportSettingsState(data.reportSettings)
      setRemoveBgImage(false)
      setRemoveCompanyLogo(false)
    },
  )
  useEffect(() => {
    refetch()
  }, [type])

  useEffect(() => updateReportSettingsState(formBody), [formBody])

  const updateReportSettingsState = (data: ReportSettingItem[]) => {
    const initializeSettingsForm = (): ReportSettingsForm => ({
      ...INITIAL_REPORT_SETTINGS,
    })

    const applySettings = (
      settingsForm: ReportSettingsForm,
      setting: ReportSettingItem,
    ) => {
      switch (setting.id) {
        case ReportSettingItemKey.BackgroundImage:
          settingsForm.backgroundImage = {
            isChecked: setting.isChecked,
            value: setting.value,
          }
          break
        case ReportSettingItemKey.CompanyLogo:
          settingsForm.companyLogo = {
            isChecked: setting.isChecked,
            value: setting.value,
          }
          break
        case ReportSettingItemKey.TextColour:
          settingsForm.textColour = setting.value || '#085DA8'
          break
        case ReportSettingItemKey.LineColour:
          settingsForm.lineColour = setting.value || '#1E75C6'
          break
        case ReportSettingItemKey.PrivateReportsEmailPhone:
          settingsForm.privateReportsEmailPhone = setting.isChecked
          break
        case ReportSettingItemKey.PrivateReportsName:
          settingsForm.privateReportsName = setting.isChecked
          break
        default:
          break
      }
    }

    const reportSettingsForm = initializeSettingsForm()
    const assessmentReportSettingsForm = initializeSettingsForm()

    data?.forEach((setting) => {
      if (type === ReportTypes.ASSESSMENT) {
        applySettings(assessmentReportSettingsForm, setting)
      } else {
        applySettings(reportSettingsForm, setting)
      }
    })

    if (type === ReportTypes.ASSESSMENT) {
      dispatch(setAssessmentReportSettings(assessmentReportSettingsForm))
    } else {
      dispatch(setReportSettings(reportSettingsForm))
    }
  }

  const updateFormBody = async (id: ReportSettingItemKey, value: any) => {
    setFormBody((prevFormBody) =>
      prevFormBody?.map((setting) =>
        setting.id === id ? { ...setting, value: value } : setting,
      ),
    )
  }

  const getIsChecked = (id: ReportSettingItemKey) => {
    const setting = formBody?.find((item) => item.id === id)
    return setting ? setting.isChecked : false
  }

  const getValue = (checkboxId: ReportSettingItemKey) => {
    const setting = formBody?.find((item) => item.id === checkboxId)
    return setting ? setting.value : null
  }

  const handleIsCheckedChange = (checkboxId: ReportSettingItemKey) => {
    setFormBody((prevFormBody) => {
      const updatedFormBody = prevFormBody?.map((setting) => {
        if (setting.id === checkboxId) {
          if (
            setting.isChecked &&
            (checkboxId === ReportSettingItemKey.TextColour ||
              checkboxId === ReportSettingItemKey.LineColour)
          ) {
            if (checkboxId === ReportSettingItemKey.TextColour) {
              setSelectedTextColor(null)
            } else if (checkboxId === ReportSettingItemKey.LineColour) {
              setSelectedLineColor(null)
            }

            return {
              ...setting,
              isChecked: !setting.isChecked,
              value: !setting.isChecked ? setting.value : undefined,
            }
          }

          return {
            ...setting,
            isChecked: !setting.isChecked,
          }
        }
        return setting
      })

      // Check if there's actually a change in isChecked before updating state
      if (JSON.stringify(updatedFormBody) !== JSON.stringify(prevFormBody)) {
        updateReportSettingsState(updatedFormBody)
      }

      return updatedFormBody
    })
  }

  const onBgImageUpload = (imageFile: any) => {
    setBgImage(imageFile.preview)
    setBgFile(imageFile)
  }

  //   useEffect(() => {
  //     if (removeBgImage) {
  //       updateFormBody(ReportSettingItemKey.BackgroundImage, undefined)
  //       setBgImage(null)
  //     }
  //   }, [removeBgImage])

  //   useEffect(() => {
  //     if (removeCompanyLogo) {
  //       updateFormBody(ReportSettingItemKey.CompanyLogo, undefined)
  //       setImageUrl(null)
  //     }
  //   }, [removeCompanyLogo])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (
        getIsChecked(ReportSettingItemKey.TextColour) &&
        selectedTextColor !== null &&
        getValue(ReportSettingItemKey.TextColour) != selectedTextColor
      )
        updateFormBody(ReportSettingItemKey.TextColour, selectedTextColor)
    }, 500)
    return () => clearTimeout(timeoutId)
  }, [selectedTextColor, formBody])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (
        getIsChecked(ReportSettingItemKey.LineColour) &&
        selectedLineColor !== null &&
        getValue(ReportSettingItemKey.LineColour) != selectedLineColor
      )
        updateFormBody(ReportSettingItemKey.LineColour, selectedLineColor)
    }, 500)
    return () => clearTimeout(timeoutId)
  }, [selectedLineColor, formBody])

  const { mutate, isLoading: isSaving } = useUpdateReportSettings()
  const { mutate: uploadBgImage, isLoading: isImageUploading } =
    useUpdateReportSettingBackgroundImage()
  const {
    mutate: deleteImage,
    isLoading: isLoadingDeleteImage,
    isSuccess: isDeletingImageSuccess,
  } = useDeleteReportSettingBackgroundImage()

  const handleError = (message: string) => {
    Toast({
      alertHeader: message,
      status: 'Error',
    })
  }

  const handleImageUpload = async () => {
    uploadBgImage(
      {
        id: settingsId,
        backgroundImage: bgFile || null,
        companyLogo: logoFile || null,
        IsCompanyLogo: logoImageUpload,
      },
      {
        onSuccess: async (data) => {
          const res = data.data
          const bgImg = res.reportBackgroundURL
          const logoImg = res.reportCompanyLogoURL
          setBgImageUpload(false)
          setLogoImageUpload(false)

          if (bgImg) {
            setBgImage(bgImg)
            await updateFormBody(ReportSettingItemKey.BackgroundImage, bgImg)
          }
          if (logoImg) {
            setImageUrl(logoImg)
            await updateFormBody(ReportSettingItemKey.CompanyLogo, logoImg)
          }
        },
        onError: () =>
          handleError('Error while uploading report background image'),
      },
    )
  }

  //   const handleImageDelete = async (options: { onSuccess: () => void }) => {
  //     await deleteImage(
  //       { id: settingsId, reportType: type },
  //       {
  //         onSuccess: () => {
  //           options.onSuccess()
  //         },
  //         onError: () => handleError('Error while deleting background image'),
  //       },
  //     )
  //   }

  const handleSave = () => {
    saveReportSettings(formBody)
  }

  const imageUploadAndSave = async () => {
    await handleImageUpload()
    refetch()
  }

  useEffect(() => {
    if (bgImage && bgImageUpload && !removeBgImage) {
      imageUploadAndSave()
      //   saveReportSettings(formBody)
    }
  }, [bgImage])

  useEffect(() => {
    if (logoFile && logoImageUpload && !removeCompanyLogo) {
      imageUploadAndSave()
      //   saveReportSettings(formBody)
    }
  }, [logoFile, logoImageUpload])

  useEffect(() => {
    if (removeCompanyLogo) {
      const handleImageDelete = async () => {
        await deleteImage(
          { id: settingsId, reportType: type, IsCompanyLogo: true },
          {
            onSuccess: async () => {
              await updateFormBody(ReportSettingItemKey.CompanyLogo, undefined)
              setRemoveCompanyLogo(false)
              setLogoFile(null)
              setImageUrl(null)
              refetch()
            },
            onError: () => handleError('Error while deleting background image'),
          },
        )
      }
      handleImageDelete()
    }
  }, [removeCompanyLogo])

  useEffect(() => {
    if (removeBgImage) {
      const handleImageDelete = async () => {
        await deleteImage(
          { id: settingsId, reportType: type, IsCompanyLogo: false },
          {
            onSuccess: async () => {
              await updateFormBody(ReportSettingItemKey.CompanyLogo, undefined)
              setRemoveCompanyLogo(false)
              setBgImage(null)
              setBgFile(null)
              refetch()
            },
            onError: () => handleError('Error while deleting background image'),
          },
        )
      }
      handleImageDelete()
    }
  }, [removeBgImage])

  const saveReportSettings = (reportSettings: ReportSettingItem[]) => {
    mutate(
      { reportSettings, reportType: type },
      {
        onSuccess: () => {
          Toast({
            alertHeader: 'Report settings saved successfully!',
            status: 'Success',
          })
        },
        onError: () => {
          Toast({
            alertHeader: 'Error while saving report settings',
            status: 'Error',
          })
        },
        onSettled: () => {
          refetch()
        },
      },
    )
  }

  const handleCancel = () => {
    setFormBody(initialFormBody)
    setSelectedLineColor(null)
    setSelectedTextColor(null)
    // setBgImage(null)
    setLogoFile(null)
    setBgFile(null)
    setLogoImageUpload(false)
    setBgImageUpload(false)
    setRemoveBgImage(false)
    setRemoveCompanyLogo(false)
  }

  const filteredReportAccordionSettings =
    type === ReportTypes.ASSESSMENT
      ? reportAccordionSettings.filter(
          (item) => item.type !== 'privacy-settings',
        )
      : reportAccordionSettings

  return (
    <>
      {(isSaving ||
        isImageUploading ||
        isLoadingDeleteImage ||
        isFetching ||
        isLoading) && <PageLoader size='xxs' />}
      <Column className='space-y-5'>
        {filteredReportAccordionSettings.map((accordion, index) => (
          <div>
            <Accordion
              key={index}
              heading={accordion.heading}
              initialState={false}
              description={accordion.description}>
              <div className='w-full mt-5'>
                {accordion.checkboxes.map((checkbox) => (
                  <div key={checkbox.id} className='w-full py-3'>
                    {checkbox.id == ReportSettingItemKey.BackgroundImage ? (
                      <div
                        className={cn('w-full', {
                          'flex-col ':
                            getIsChecked(checkbox.id) && !getValue(checkbox.id),
                          'flex items-center justify-between':
                            getIsChecked(checkbox.id) && getValue(checkbox.id),
                        })}>
                        <Checkbox
                          key={checkbox.id}
                          labelText={checkbox.labelText}
                          helperText=''
                          defaultChecked={getIsChecked(checkbox.id)}
                          onChange={() => handleIsCheckedChange(checkbox.id)}
                        />
                        {getIsChecked(checkbox.id) && (
                          <>
                            {!getValue(checkbox.id) ? (
                              <ImageUpload
                                imageFile={bgImage}
                                className='h-[190px]'
                                showRemoveBtn={false}
                                setImageFile={(imageFile: any) => {
                                  setBgImageUpload(true)
                                  onBgImageUpload(imageFile)
                                  updateFormBody(
                                    ReportSettingItemKey.BackgroundImage,
                                    imageFile.preview,
                                  )
                                }}
                                dropzoneDescription='Drag and drop your custom background image here (PNG or Jpeg).
To get the best results, make sure your image width and height should be 595 * 190px'
                              />
                            ) : (
                              <Button
                                onClick={() => {
                                  setIsBackgroundImageRemoveModalActive(true)
                                }}
                                textTheme='terracotta'
                                theme='link'>
                                Remove
                              </Button>
                            )}
                          </>
                        )}
                      </div>
                    ) : checkbox.id == ReportSettingItemKey.CompanyLogo ? (
                      <div
                        className={cn('w-full', {
                          'flex-col':
                            getIsChecked(checkbox.id) && !getValue(checkbox.id),
                          'flex items-center justify-between':
                            getIsChecked(checkbox.id) && getValue(checkbox.id),
                        })}>
                        <Checkbox
                          key={checkbox.id}
                          labelText={checkbox.labelText}
                          helperText=''
                          defaultChecked={getIsChecked(checkbox.id)}
                          onChange={() => handleIsCheckedChange(checkbox.id)}
                        />
                        {getIsChecked(checkbox.id) && (
                          <>
                            {!getValue(checkbox.id) && !imageUrl ? (
                              <button
                                onClick={() => {
                                  setShowUploadLogoModal(true)
                                }}
                                className='flex flex-col justify-center items-center space-y-1 border-2 rounded-md cursor-pointer border-dashed border-Gray-300'>
                                <img
                                  src={unionIcon}
                                  className='px-[26px] py-[10px] h-[60px]'
                                  alt='drag-and-drop-area'
                                />
                                <div className='font-Regular text-extra-small text-Gray-400 text-center w-[70%]'>
                                  Click here to upload your image (PNG or Jpeg).
                                  <br></br>
                                  To get the best results make sure your image
                                  is rectangular one. Ratio for width and height
                                  should be 5:1 or similar.
                                </div>
                                <p className='flex items-center justify-center pt-3 pb-2 space-x-3 font-Medium text-Cobalt-600'>
                                  <i className='ri-upload-2-line text-heading-5'></i>
                                  <span>Upload a photo</span>
                                </p>
                              </button>
                            ) : (
                              <Button
                                onClick={() => {
                                  setIsCompanyLogoRemoveModalActive(true)
                                }}
                                textTheme='terracotta'
                                theme='link'>
                                Remove
                              </Button>
                            )}
                          </>
                        )}
                      </div>
                    ) : (
                      <div className='flex items-center justify-between'>
                        <Checkbox
                          key={checkbox.id}
                          labelText={checkbox.labelText}
                          helperText=''
                          defaultChecked={getIsChecked(checkbox.id)}
                          onChange={() => handleIsCheckedChange(checkbox.id)}
                        />
                        {getIsChecked(checkbox.id) && (
                          <>
                            {checkbox.id == ReportSettingItemKey.TextColour && (
                              <input
                                type='color'
                                className='w-[100px]'
                                value={getValue(checkbox.id) || '#085DA8'}
                                onChange={(e) => {
                                  setSelectedTextColor(e.target.value)
                                }}
                              />
                            )}

                            {checkbox.id == ReportSettingItemKey.LineColour && (
                              <input
                                type='color'
                                className='w-[100px]'
                                value={getValue(checkbox.id) || '#1E75C6'}
                                onChange={(e) => {
                                  setSelectedLineColor(e.target.value)
                                }}
                              />
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </Accordion>
          </div>
        ))}
        <div className='flex justify-start mt-5'>
          <Button
            onClick={handleCancel}
            textTheme='black'
            theme='white'
            className='mr-3 cursor-pointer'>
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            isLoading={isSaving || isImageUploading || isLoadingDeleteImage}
            className='mr-3 cursor-pointer'>
            Save
          </Button>
        </div>
      </Column>

      <CustomizableEditCompanyLogoModal
        isRectangleLogo={true}
        isActive={showUploadLogoModal}
        onHeaderCloseButtonClick={() => setShowUploadLogoModal(false)}
        onClickSecondaryBtn={() => setShowUploadLogoModal(false)}
        onOverlayClick={() => setShowUploadLogoModal(false)}
        onClickPrimaryBtn={() => {
          setLogoImageUpload(true)
          updateFormBody(ReportSettingItemKey.CompanyLogo, imageUrl)
          setShowUploadLogoModal(false)
        }}
        setLogoFile={setLogoFile}
        setImageUrl={setImageUrl}
        imageUrl={imageUrl}
        headerTitle='Crop and Upload Logo'
        headerSubTitle='Adjust your logo to fit the display area.'
      />

      <BackgroundImageRemoveModal
        isModalActive={isBackgroundImageRemoveModalActive}
        setIsModalActive={setIsBackgroundImageRemoveModalActive}
        setRemoveBgImage={setRemoveBgImage}
      />

      <CompanyLogoRemoveModal
        isModalActive={isCompanyLogoRemoveModalActive}
        setIsModalActive={setIsCompanyLogoRemoveModalActive}
        setRemoveCompanyLogo={setRemoveCompanyLogo}
      />
    </>
  )
}
