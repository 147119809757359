export const ResponseReceived = 'RESPONSE_RECEVIED'
export const AwaitingForResponse = 'AWAITING_RESPONSE'
export const Declined = 'REQUEST_DECLINED'
export const Review = 'RESPONSE_IN_REVIEW'
export const Evaluated = 'RESPONSE_EVALUATED'
export const Opened = 'Opened'
export const Sent = 'Sent'
export const assessmentResponseStatusOptions = [
  {
    label: 'Received',
    value: ResponseReceived,
  },
  {
    label: 'Awaiting',
    value: AwaitingForResponse,
  },
  {
    label: 'Declined',
    value: Declined,
  },
  {
    label: 'Review',
    value: Review,
  },
  {
    label: 'Evaluated',
    value: Evaluated,
  },
]

export const assessmentEmailStatusOptions = [
  {
    label: 'Opened',
    value: Opened,
  },
  {
    label: 'Sent',
    value: Sent,
  },
]
