import { ForwardedRef, forwardRef, useMemo, useRef } from 'react'
import { ReportAssessmentStatsCountDto } from 'api/assessment/getAssessmentCountByCandidate'
import { CandidateProfileBasicDetailsType } from 'api/candidates'
import { assessmentReportPreviewData } from 'features/candidates/data/assessmentReportPreviewData'
import { assessmentTypes } from 'features/candidates/data/assessmentTypes'
import { ReportTypes } from 'features/settings/data/reportTypes'

import { Column } from 'components/atoms/Column'
import CombineReportPrintViewSection from 'components/molecules/AssesmentPreviewModal/Sections/CombineReportPrintViewSection'
import PersonalityTestCombineReportPreviewSection from 'components/molecules/AssesmentPreviewModal/Sections/PersonalityTestCombineReportPreviewSection'
import TypingTestCombineReportPreviewSection from 'components/molecules/AssesmentPreviewModal/Sections/TypingTestCombineReportPreviewSection'

import {
  CombineReportCandidateDetails,
  CombineReportCandidateDetailsDto,
  ReportBanner,
  ReportLogoHeader,
} from '../../sections'

import './style.scss'

type CombineReportPrintPreviewProps = {
  candidateDetails: CandidateProfileBasicDetailsType
  stats: ReportAssessmentStatsCountDto
  previewData: assessmentReportPreviewData | undefined
  dynamicFieldMapping: any
  changeTextSchema: any
  isMappingVariablesLoading?: boolean
  reportViewData: any
}

// const getQuestionsPerPage = () => 2

// const chunkArray = (array: any[], size: number) => {
//   const result: any[] = []
//   for (let i = 0; i < array.length; i += size) {
//     if (i === 0) {
//       result.push([array[i]])
//     } else {
//       result.push(array.slice(i, i + size))
//     }
//   }
//   return result
// }

export const CombineReportPrintPreview = forwardRef(
  (
    {
      candidateDetails,
      stats,
      previewData,
      reportViewData,
    }: CombineReportPrintPreviewProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const candidateReportDetails = useMemo(() => {
      const {
        name,
        candidateNo,
        email,
        phone,
        avatarUrl,
        jobTitle,
        consultantName,
      } = candidateDetails
      return {
        name,
        candidateNo,
        email,
        phoneNumber: phone,
        avatarUrl,
        jobTitle,
        consultantName,
      } as CombineReportCandidateDetailsDto
    }, [candidateDetails])
    const modalRef = useRef<HTMLDivElement>(null)

    // const questionsPerPage = useMemo(getQuestionsPerPage, [])

    // const getReportChunks = useCallback(
    //   (data) => chunkArray(data.reportQuestion, questionsPerPage),
    //   [questionsPerPage],
    // )

    const getMarkingValue = (data) => ({
      correct: data.assessmentAutoMarking?.correct ?? 0,
      partiallyCorrect: data.assessmentAutoMarking?.partiallyCorrect ?? 0,
      incorrect: data.assessmentAutoMarking?.incorrect ?? 0,
      notAnswered: data.assessmentAutoMarking?.notAnswered ?? 0,
    })

    return (
      <div
        ref={modalRef}
        className='relative flex flex-col p-0 overflow-x-hidden overflow-y-auto styled-scroll bg-Gray-50'>
        <Column className='bg-Gray-50 w-[794px] pointer-events-none'>
          <div ref={ref} className='w-full h-full p-0 m-0'>
            {reportViewData.map((data, i) => (
              <div key={i} style={{ paddingBottom: '20px' }}>
                <div
                  className='h-auto relative'
                  style={{
                    pageBreakAfter:
                      i === 0 && reportViewData.length > 1 ? 'always' : 'avoid',
                  }}>
                  {i === 0 && (
                    <div>
                      <ReportLogoHeader
                        clientlogo={previewData?.clientLogoUrl}
                        hasClientLogo={previewData?.hasClientLogo}
                        hasCompanyLogo={previewData?.hasCompanyLogo}
                      />
                      <ReportBanner type={ReportTypes.ASSESSMENT} />
                      <div className='m-3'>
                        <CombineReportCandidateDetails
                          details={candidateReportDetails}
                          stats={stats}
                          data={data}
                        />
                      </div>
                    </div>
                  )}
                  {data.assessmentName !== assessmentTypes.TypingTest &&
                    data.assessmentName !== assessmentTypes.PersonalityTest && (
                      <CombineReportPrintViewSection
                        markingValue={getMarkingValue(data)}
                        reportViewData={data}
                        position={i + 1}
                        // reportChunks={chunkArray(data.reportQuestion, 2)}
                      />
                    )}
                  {data.isAddon &&
                    data.assessmentName === assessmentTypes.TypingTest && (
                      <TypingTestCombineReportPreviewSection
                        reportViewData={data}
                        isPreview={false}
                        position={i + 1}
                      />
                    )}
                  {data.isAddon &&
                    data.assessmentName === assessmentTypes.PersonalityTest && (
                      <PersonalityTestCombineReportPreviewSection
                        reportViewData={data}
                        isPreview={false}
                        position={i + 1}
                      />
                    )}
                </div>
              </div>
            ))}
          </div>
        </Column>
      </div>
    )
  },
)
