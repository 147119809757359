import { createSlice } from '@reduxjs/toolkit'

import { AgencyProfileDto } from '../types'

type AgencyStoreProps = {
  agency: AgencyProfileDto | null
}

const initialState: AgencyStoreProps = {
  agency: null,
}

const agencySlice = createSlice({
  name: 'agency',
  initialState,
  reducers: {
    setAgencyState(state, { payload }) {
      state.agency = payload
    },
  },
})

export const { setAgencyState } = agencySlice.actions

export default agencySlice.reducer
