import addon from 'assets/images/addon.svg'
import { difficultyImages } from 'features/candidates/data/difficultyLevelImages'

type CombinedReportNameHeaderProps = {
  data: {
    isAddon?: boolean
    assessmentName?: string
    mainAssessmentName?: string
    difficultyLevel?: string
  }
}

export const CombinedReportNameHeader = ({
  data,
}: CombinedReportNameHeaderProps) => {
  const isAddon = data?.isAddon
  const assessmentName = data?.assessmentName
  const mainAssessmentName = data?.mainAssessmentName
  const difficultyImage = isAddon
    ? addon
    : difficultyImages[data?.difficultyLevel as keyof typeof difficultyImages]

  return (
    <div className='bg-Cobalt-50'>
      <span className='text-[#414244] text-heading-5 font-Medium mb-4 pl-4 h-11 flex items-center text-left'>
        {isAddon
          ? assessmentName === 'Typing Test'
            ? `${assessmentName} (LowerCase Only) - Add-on for ${mainAssessmentName}`
            : `${assessmentName} - Add-on for ${mainAssessmentName}`
          : `Main Assessment - ${assessmentName}`}
        <img
          className='w-[20px] relative left-2'
          src={difficultyImage}
          alt={isAddon ? 'addon' : 'main assessment'}
        />
      </span>
    </div>
  )
}
