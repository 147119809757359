import { useMutation } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { postAPI } from 'framework/api/http'

type MarkAsReadRequestProps = {
  notificationIds: Array<string>
}

export const useMarkAsRead = () => {
  return useMutation(
    ({ notificationIds }: MarkAsReadRequestProps) => {
      return postAPI(`/Notification/MarkAsRead`, {
        notificationIds: notificationIds,
      })
    },
    {
      onError: () => {
        Toast({
          alertHeader: `Error In Marking As Read`,
          status: 'Error',
        })
      },
    },
  )
}
