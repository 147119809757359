import React from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

type TabButtonProps = {
  tabId: any
  setTabId: React.Dispatch<any>
}

const TabButton = ({ tabId, setTabId }: TabButtonProps) => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()

  const handleTabChange = (id: number) => {
    if (searchParams.has('assessment_tab')) {
      searchParams.delete('assessment_tab')
    }

    setTabId(id)
    // else {
    //   searchParams.append('assessment_tab', value)
    // }

    navigate(
      {
        pathname: location.pathname,
        search: searchParams.toString(),
      },
      { replace: true },
    )
  }
  return (
    <div className='w-[350px] flex flex-row pb-5'>
      <button
        onClick={() => {
          handleTabChange(1)
        }}
        className={`w-full flex justify-center font-Medium focus:outline-none p-1 cursor-pointer py-2 ${
          tabId === 1
            ? 'bg-Cobalt-600 text-white border-2 border-Cobalt-600 rounded-tl-md rounded-bl-md'
            : 'bg-white text-Gray-800 hover:bg-Gray-50 border-2 border-Gray-200 border-r-0 rounded-tl-md rounded-bl-md'
        }`}>
        <span className='text-center'>Custom Assessment</span>
      </button>
      <button
        onClick={() => {
          handleTabChange(2)
        }}
        className={`w-full flex justify-center font-Medium focus:outline-none p-1 cursor-pointer py-2 ${
          tabId === 2
            ? 'bg-Cobalt-600 text-white rounded-tr-md rounded-br-md'
            : 'bg-white text-Gray-800 hover:bg-Gray-50 border-2 border-Gray-200 rounded-tr-md rounded-br-md'
        }`}>
        <span>Assessment Results</span>
      </button>
    </div>
  )
}

export default TabButton
