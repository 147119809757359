import { useMemo } from 'react'

import { ChartData } from 'components/ui/reports/addonReportsPreviewModal/TypingTestAddon/TypingTestAddonPreviewReport'
import { cn } from 'utils'
import { calculateAccuracy, calculateConsistency } from 'utils/testStats'

export default function TestStats({
  chartDataArray,
  duration,
  typedHistory,
  wordList,
  isReport = false,
}: {
  typedHistory: string[]
  wordList: string[]
  isReport?: boolean
  duration: number
  chartDataArray: ChartData[]
}) {
  const testStats = useMemo(() => {
    const wpm = chartDataArray[chartDataArray.length - 1]?.wpm
    const raw = chartDataArray[chartDataArray.length - 1]?.raw

    const accuracy = calculateAccuracy({ wordList, typedLIst: typedHistory })
    const consistency = calculateConsistency({ chartDataArray })

    const testStats = [
      {
        name: 'WPM',
        value: wpm,
      },
      {
        name: 'Accuracy',
        value: `${Math.floor(accuracy)} %`,
      },
      {
        name: 'RAW',
        value: raw,
      },
      //todo  cansistancy calc returns  a percentage check
      {
        name: 'Consistency',
        value: `${Math.floor(consistency)} %`,
      },
      {
        name: 'Time',
        value: `${duration} Sec`,
      },

      // {
      //     name: 'correct words',
      //     value: result.filter((x) => x).length
      // }
      // ,
      // {
      //     name: 'incorrect words',
      //     value: result.filter((x) => !x).length
      // }
    ]
    return testStats
  }, [chartDataArray])

  return (
    <div
      className={cn('h-[93px] mx-auto flex flex-row gap-4  my-8', {
        'w-full': !isReport,
        'w-[751px]': isReport,
      })}>
      {testStats?.map((stat, i) => (
        <div
          key={i}
          className={cn(
            'block  h-[93px] py-[18px] px-5 bg-white border border-Gray-200 hover:bg-Gray-100 ',
            {
              'w-[235px]': !isReport,
              'w-[140px]': isReport,
            },
          )}>
          <p className='font-Medium   text-small text-Gray-800 mb-2'>
            {stat.name}
          </p>
          <h5 className=' text-heading-4 font-Medium  tracking-tight text-Blue-700 '>
            {stat.value}
          </h5>
        </div>
      ))}
    </div>
  )
}
