import { useMutation } from 'react-query'
import { putAPIwithFormData } from 'framework/api/http'

export type UpdateClientRequest = {
  id: string
  name: string
  email?: string
  phoneNumber?: string
  countryCode?: string
  logoFile?: File
}

export const useUpdateClient = () => {
  const mutate = useMutation(
    ({
      id,
      name,
      email,
      phoneNumber,
      countryCode,
      logoFile,
    }: UpdateClientRequest) => {
      const bodyFormData = new FormData()
      bodyFormData.append('name', name)

      if (logoFile) bodyFormData.append('logoFile', logoFile)
      if (email) bodyFormData.append('email', email)
      if (phoneNumber) bodyFormData.append('phoneNumber', phoneNumber)
      if (countryCode) bodyFormData.append('countryCode', countryCode)
      return putAPIwithFormData(`/clients/${id}`, bodyFormData, {})
    },
  )

  return mutate
}
