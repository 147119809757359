import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

export type RefereeDataProps = {
  company: string | undefined
  email: string | undefined
  firstName: string | undefined
  jobTitle: string | undefined
  lastName: string | undefined
  phone: string | undefined
  countryCode: string | undefined
  preCandidatePosition: string | undefined
  preCompanyName: string | undefined
  preJobFinishDate: Date | undefined | null
  preJobStartDate: Date | undefined | null
  refId: string | undefined
  relationshipToCandidate: string | undefined
  referenceType: string | undefined
  isCurrentlyWorking?: boolean
}

type EditRefereeProps = {
  candidateId?: string
  refereeData?: RefereeDataProps
}

export const useEditReferee = () => {
  const mutate = useMutation(
    ({ candidateId, refereeData }: EditRefereeProps) => {
      const {
        refId,
        firstName,
        lastName,
        phone,
        email,
        company,
        jobTitle,
        relationshipToCandidate,
        preCompanyName,
        preCandidatePosition,
        preJobStartDate,
        preJobFinishDate,
        referenceType,
        isCurrentlyWorking,
      } = refereeData || {}

      return putAPI(`/Candidate/${candidateId}/Referee/${refId}`, {
        firstName,
        lastName,
        phone,
        email,
        company,
        jobTitle,
        candidateRelation: relationshipToCandidate,
        candidateCompany: preCompanyName,
        candidatePosition: preCandidatePosition,
        startDate: preJobStartDate || null,
        endDate: isCurrentlyWorking ? null : preJobFinishDate || null,
        referenceType: {
          note: '',
          specificEmployer: '',
          type: referenceType,
        },
        isCurrentlyWorking,
      })
    },
  )

  return mutate
}
