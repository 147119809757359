/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import BlackFridayMonth15 from 'assets/images/blackfriday/15Month.png'
import BlackFridayYear15 from 'assets/images/blackfriday/15Year.png'
import BlackFridayMonth25 from 'assets/images/blackfriday/25Month.png'
import BlackFridayYear25 from 'assets/images/blackfriday/25Year.png'
import BlackFridayMonth50 from 'assets/images/blackfriday/50Month.png'
import BlackFridayYear50 from 'assets/images/blackfriday/50Year.png'
import Month15 from 'assets/images/subscriptionPackages/15Month.svg'
import Year15 from 'assets/images/subscriptionPackages/15Year.svg'
import Month25 from 'assets/images/subscriptionPackages/25Month.svg'
import Year25 from 'assets/images/subscriptionPackages/25Year.svg'
import Month50 from 'assets/images/subscriptionPackages/50Month.svg'
import Year50 from 'assets/images/subscriptionPackages/50Year.svg'
import {
  FixedSubscriptionPackageDto,
  FixedSubscriptionPackageType,
  SubscriptionType,
} from 'features/subscriptions'

import { isBlackFriday } from 'utils/offers'

type SubscriptionInformationProps = {
  subscriptionPackage: FixedSubscriptionPackageDto
  selectedPackage: FixedSubscriptionPackageDto
}

export const SubscriptionInformation = ({
  subscriptionPackage,
  selectedPackage,
}: SubscriptionInformationProps) => {
  const [imageSource, setImageSource] = useState<any>(undefined)

  useEffect(() => {
    if (selectedPackage.subscriptionType === SubscriptionType.Monthly) {
      setImageSource(
        isBlackFriday()
          ? subscriptionPackage?.name ===
            FixedSubscriptionPackageType.FifteenPack
            ? BlackFridayMonth15
            : subscriptionPackage?.name ===
              FixedSubscriptionPackageType.TwentyfivePack
            ? BlackFridayMonth25
            : BlackFridayMonth50
          : subscriptionPackage?.name ===
            FixedSubscriptionPackageType.FifteenPack
          ? Month15
          : subscriptionPackage?.name ===
            FixedSubscriptionPackageType.TwentyfivePack
          ? Month25
          : Month50,
      )
    } else {
      setImageSource(
        isBlackFriday()
          ? subscriptionPackage?.name ===
            FixedSubscriptionPackageType.FifteenPack
            ? BlackFridayYear15
            : subscriptionPackage?.name ===
              FixedSubscriptionPackageType.TwentyfivePack
            ? BlackFridayYear25
            : BlackFridayYear50
          : subscriptionPackage?.name ===
            FixedSubscriptionPackageType.FifteenPack
          ? Year15
          : subscriptionPackage?.name ===
            FixedSubscriptionPackageType.TwentyfivePack
          ? Year25
          : Year50,
      )
    }
  }, [selectedPackage, isBlackFriday])

  return (
    <div className='w-2/5 h-[calc(100%-60px)] flex flex-col justify-start items-start'>
      <img
        src={imageSource}
        alt='right-logo'
        className='top-0 flex justify-start object-contain w-full h-full'
      />
    </div>
  )
}
