import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { UniqueIdentifier } from '@dnd-kit/core'
import { Button, Toast } from '@labourhub/labour-hub-ds'
import { useGetAssessmentBuildById } from 'api/assessment/getAssessmentBuildById'
import { usePublishAssessment } from 'api/assessment/publishAssessment'
import { assessmentQuestionTypes } from 'features/questionnaires/data'
import { refreshAssessmentList } from 'features/questionnaires/store/actions'
import { AssessmentQuestion } from 'features/questionnaires/types'
import { ModalProps } from 'types'

import { PageLoader } from 'components/atoms'
import { Modal, ModalFooter } from 'components/molecules'

import { AssessmentPreviewQuestionCardsColumn } from './AssessmentPreviewQuestionCardsColumn'

type AssessmentPreviewModalProps = ModalProps & {
  templateId: string
  templateName: string
  isDraft: boolean
  isPreview?: boolean
}

export const AssessmentPreviewModal = ({
  isModalActive,
  setIsModalActive,
  templateId,
  templateName,
  isDraft,
  isPreview = false,
}: AssessmentPreviewModalProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const notify = (props: any) => Toast(props)
  const componentRef = useRef<HTMLDivElement>(null)

  const [aId] = useState<any>(templateId)

  const [itemsMap, setItemsMap] = useState<
    Map<UniqueIdentifier, AssessmentQuestion[]>
  >(
    new Map([
      ['question-types', assessmentQuestionTypes],
      ['question-cards', []],
    ]),
  )

  const addNewQuestion = useCallback((newQuestion: AssessmentQuestion) => {
    setItemsMap((prevItemsMap) => {
      const currentItems = prevItemsMap.get('question-cards') || []
      const updatedItems = [...currentItems, newQuestion]
      const newMap = new Map(prevItemsMap)
      newMap.set('question-cards', updatedItems)
      return newMap
    })
  }, [])

  /** get dynamic form */
  //   const { data: dynamicForm, isLoading: getDynamicFormIsLoading } =
  //     useGetDynamicFormAssessment(templateId)

  useEffect(() => {
    templateId && isModalActive && getAssessment()
  }, [isModalActive, templateId])

  const { mutate, isLoading } = usePublishAssessment()

  const publishAssessment = () => {
    mutate(templateId, {
      onSuccess: ({ data: successData }: any) => {
        notify({
          alertHeader: successData.message,
          alertBody: '',
          status: 'Success',
        })
        dispatch(refreshAssessmentList())
        setIsModalActive(false)
      },
      onError: ({ response: { data: errData } }: any) => {
        notify({
          alertHeader: 'Error...!',
          alertBody: errData?.message
            ? errData?.message
            : 'Unable to publish questionnaire',
          status: 'Error',
        })
      },
    })
  }

  //   useEffect(() => {
  //     updateNode(componentRef)
  //   }, [dynamicForm])

  const questionCardItems: AssessmentQuestion[] = useMemo(
    () => itemsMap.get('question-cards') || [],
    [itemsMap],
  )

  const { refetch: getAssessment, isLoading: isAssessmentLoading } =
    useGetAssessmentBuildById(
      {
        aId,
      },
      async (data: any) => {
        const previewData: any = []
        data?.assessment?.questions.map((question: any) => {
          switch (question.type) {
            case 'text':
              previewData.push({
                id: question.id,
                icon: 'ri-text-spacing',
                name: 'Short Answer',
                isRequired: question.isRequired,
                type: 'text_area',
                title: question.title,
                meta: [...question.meta.enum],
                answer: question.meta.answer,
                correctAnswer: question.meta.correctAnswer,
                uploadType: question.meta.uploadType,
                imagePath: question.meta.imageUrl,
                imageUrl: question.meta.publicImageUrl,
                videoLink: question.meta.videoUrl,
                videoPlatform: question.meta.videoPlatform,
                isAIGenerated: question.meta.isAIGenerated,
              })
              break
            case 'text_area':
              previewData.push({
                id: question.id,
                icon: 'ri-text-wrap',
                name: 'Long Answer',
                isRequired: question.isRequired,
                type: 'text_area',
                title: question.title,
                meta: [...question.meta.enum],
                answer: question.meta.answer,
                correctAnswer: question.meta.correctAnswer,
                uploadType: question.meta.uploadType,
                imagePath: question.meta.imageUrl,
                imageUrl: question.meta.publicImageUrl,
                videoLink: question.meta.videoUrl,
                videoPlatform: question.meta.videoPlatform,
                isAIGenerated: question.meta.isAIGenerated,
              })
              break
            case 'textarea':
              previewData.push({
                id: question.id,
                icon: 'ri-text-wrap',
                name: 'Long Answer',
                isRequired: question.isRequired,
                type: 'textarea',
                title: question.title,
                meta: [...question.meta.enum],
                answer: question.meta.answer,
                correctAnswer: question.meta.correctAnswer,
                uploadType: question.meta.uploadType,
                imagePath: question.meta.imageUrl,
                imageUrl: question.meta.publicImageUrl,
                videoLink: question.meta.videoUrl,
                videoPlatform: question.meta.videoPlatform,
                isAIGenerated: question.meta.isAIGenerated,
              })
              break
            case 'select':
              previewData.push({
                id: question.id,
                icon: 'ri-star-line',
                name: 'Select Box',
                isRequired: question.isRequired,
                type: 'select',
                title: question.title,
                meta: [...question.meta.enum],
                answer: question.meta.answer,
                correctAnswer: question.meta.correctAnswer,
                uploadType: question.meta.uploadType,
                imagePath: question.meta.imageUrl,
                imageUrl: question.meta.publicImageUrl,
                videoLink: question.meta.videoUrl,
                videoPlatform: question.meta.videoPlatform,
                isAIGenerated: question.meta.isAIGenerated,
              })
              break
            case 'rating':
              previewData.push({
                id: question.id,
                icon: 'ri-star-line',
                name: 'Rating',
                isRequired: question.isRequired,
                type: 'rating',
                title: question.title,
                meta: [...question.meta.enum],
                answer: question.meta.answer,
                correctAnswer: question.meta.correctAnswer,
                uploadType: question.meta.uploadType,
                imagePath: question.meta.imageUrl,
                imageUrl: question.meta.publicImageUrl,
                videoLink: question.meta.videoUrl,
                videoPlatform: question.meta.videoPlatform,
                isAIGenerated: question.meta.isAIGenerated,
              })
              break
            case 'checkbox':
              previewData.push({
                id: question.id,
                icon: 'ri-checkbox-multiple-line',
                name: 'Multi Choice',
                isRequired: question.isRequired,
                type: 'checkbox',
                title: question.title,
                meta: [...question.meta.enum],
                answer: question.meta.answer,
                correctAnswer: question.meta.correctAnswer,
                uploadType: question.meta.uploadType,
                imagePath: question.meta.imageUrl,
                imageUrl: question.meta.publicImageUrl,
                videoLink: question.meta.videoUrl,
                videoPlatform: question.meta.videoPlatform,
                isAIGenerated: question.meta.isAIGenerated,
              })
              break
            case 'radio':
              previewData.push({
                id: question.id,
                icon: 'ri-radio-button-line',
                name: 'Yes/No',
                isRequired: question.isRequired,
                type: 'radio',
                title: question.title,
                meta: [...question.meta.enum],
                answer: question.meta.answer,
                correctAnswer: question.meta.correctAnswer,
                uploadType: question.meta.uploadType,
                imagePath: question.meta.imageUrl,
                imageUrl: question.meta.publicImageUrl,
                videoLink: question.meta.videoUrl,
                videoPlatform: question.meta.videoPlatform,
                isAIGenerated: question.meta.isAIGenerated,
              })
              break
            case 'radio_pos_detail':
              previewData.push({
                id: question.id,
                icon: 'ri-checkbox-circle-line',
                name: 'Detailed Yes',
                isRequired: question.isRequired,
                type: 'radio_pos_detail',
                title: question.title,
                meta: [...question.meta.enum],
                answer: question.meta.answer,
                correctAnswer: question.meta.correctAnswer,
                uploadType: question.meta.uploadType,
                imagePath: question.meta.imageUrl,
                imageUrl: question.meta.publicImageUrl,
                videoLink: question.meta.videoUrl,
                videoPlatform: question.meta.videoPlatform,
                isAIGenerated: question.meta.isAIGenerated,
              })
              break
            case 'radio_neg_detail':
              previewData.push({
                id: question.id,
                icon: 'ri-checkbox-circle-line',
                name: 'Detailed No',
                isRequired: question.isRequired,
                type: 'radio_neg_detail',
                title: question.title,
                meta: [...question.meta.enum],
                answer: question.meta.answer,
                correctAnswer: question.meta.correctAnswer,
                uploadType: question.meta.uploadType,
                imagePath: question.meta.imageUrl,
                imageUrl: question.meta.publicImageUrl,
                videoLink: question.meta.videoUrl,
                videoPlatform: question.meta.videoPlatform,
                isAIGenerated: question.meta.isAIGenerated,
              })
              break
            case 'heading':
              previewData.push({
                id: question.id,
                icon: 'ri-heading',
                name: 'Heading',
                isRequired: question.isRequired,
                type: 'heading',
                title: question.title,
                meta: [...question.meta.enum],
                answer: question.meta.answer,
                correctAnswer: question.meta.correctAnswer,
                uploadType: question.meta.uploadType,
                imagePath: question.meta.imageUrl,
                imageUrl: question.meta.publicImageUrl,
                videoLink: question.meta.videoUrl,
                videoPlatform: question.meta.videoPlatform,
                isAIGenerated: question.meta.isAIGenerated,
              })
              break
            case 'paragraph':
              previewData.push({
                id: question.id,
                icon: 'ri-text',
                name: 'Paragraph',
                isRequired: question.isRequired,
                type: 'paragraph',
                title: question.title,
                meta: [...question.meta.enum],
                answer: question.meta.answer,
                correctAnswer: question.meta.correctAnswer,
                uploadType: question.meta.uploadType,
                imagePath: question.meta.imageUrl,
                imageUrl: question.meta.publicImageUrl,
                videoLink: question.meta.videoUrl,
                videoPlatform: question.meta.videoPlatform,
                isAIGenerated: question.meta.isAIGenerated,
              })
          }
        })
        await previewData.map((item: AssessmentQuestion) => {
          if (aId) {
            addNewQuestion(item)
          }
        })
      },
      () => {
        notify({
          alertHeader: 'Something went wrong',
          alertBody: '',
          status: 'Error',
        })
      },
    )

  useEffect(() => {
    const fetchData = async () => {
      if (aId) {
        await getAssessment()
      }
    }

    fetchData()
  }, [aId])

  return (
    <Modal
      headerTitle={`Preview - ${templateName}`}
      customWidth='1000px'
      className='!z-[300]'
      isActive={isModalActive}
      primaryButtonTheme={'cobalt'}
      primaryButtonTitle=''
      footerButtonAlignment='right'
      onClickSecondaryBtn={() => setIsModalActive(false)}
      onHeaderCloseButtonClick={() => setIsModalActive(false)}
      onOverlayClick={() => setIsModalActive(false)}
      secondaryButtonTitle='Close'
      isFooterShow={true}>
      <div
        ref={componentRef}
        className='flex flex-col w-full justify-start px-8 min-h-[30vh] max-h-[80vh] overflow-y-auto bg-Gray-100'>
        {(isAssessmentLoading || questionCardItems.length == 0) && (
          <PageLoader size='xxs' />
        )}
        <AssessmentPreviewQuestionCardsColumn
          templateName={templateName}
          items={questionCardItems}
        />
      </div>

      <ModalFooter>
        {!isPreview && isDraft ? (
          <div className='flex justify-end flex-1'>
            <Button
              onClick={() => navigate(`/assessments/${templateId}/edit`)}
              textTheme='black'
              theme='white'>
              Edit Template
            </Button>

            <Button
              isLoading={isLoading}
              onClick={() => publishAssessment()}
              className='ml-2'
              theme='cobalt'>
              Publish
            </Button>
          </div>
        ) : !isPreview && !isDraft ? (
          <div className='flex justify-end flex-1'>
            <Button
              onClick={() => setIsModalActive(false)}
              textTheme='black'
              theme='white'>
              Cancel
            </Button>

            <Button
              onClick={() => navigate(`/assessments/${templateId}/edit`)}
              className='ml-2'
              theme='cobalt'>
              Edit Template
            </Button>
          </div>
        ) : (
          <div className='flex justify-end flex-1'>
            <Button
              onClick={() => setIsModalActive(false)}
              textTheme='black'
              theme='white'>
              Close
            </Button>
          </div>
        )}
      </ModalFooter>
    </Modal>
  )
}
