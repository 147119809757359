import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Checkbox, TextField } from '@labourhub/labour-hub-ds'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import {
  useCreateOrUpgradeFixedSubscription,
  useDowndgradeFixedSubscription,
} from 'api/subscriptions'
import { defaultBillingAddress } from 'features/payments/data'
import { PaymentErrorSetterType } from 'features/payments/types'
import { getPromotionStore } from 'features/promotions'
import { SubscriptionMode } from 'features/subscriptions'
import { setIsHeaderBarDetailsUpdated } from 'store/reducers/user/userSlice'

import { Column } from 'components/atoms/Column'
import { RowWithAlignment } from 'components/atoms/Row'
import { BillingAddressForm } from 'components/molecules'

import {
  toggleIsRefetchBillingHistory,
  toggleIsRefetchPaymentCards,
} from '../../../store'

type NewPaymentFormProps = {
  clientSecret: string
  isCardsAvailable: boolean
  packageId: string
  setIsGoToExistingCard: any
  subscriptionMode: SubscriptionMode
  setInvoice: any
  setIsInvoiceModalActive: any
  setFixedSubscriptionPaymentModalActive: any
  setCardBillingAddress: any
  setPaymentError: PaymentErrorSetterType
  packageName: string
}

export const NewPaymentForm = ({
  clientSecret,
  isCardsAvailable,
  packageId,
  setIsGoToExistingCard,
  subscriptionMode,
  setInvoice,
  setIsInvoiceModalActive,
  setFixedSubscriptionPaymentModalActive,
  setCardBillingAddress,
  setPaymentError,
  packageName,
}: NewPaymentFormProps) => {
  const dispatch = useDispatch()

  const { fixedPromotion } = useSelector(getPromotionStore)

  const { isHeaderBarDetailsUpdated } = useSelector((state: any) => state.user)

  const [isLoading, setIsLoading] = useState(false)
  const stripe = useStripe()
  const elements = useElements()
  const [nameOnCard, setNameOnCard] = useState('')

  const [isValidBillingAddress, setIsValidBillingAddress] = useState(false)
  const [isSaveMyCardChecked, setIsSaveMyCardChecked] = useState(true)

  const [cardComplete, setCardComplete] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  })

  const [billingAddress, setBillingAddress] = useState(defaultBillingAddress)

  function handleCardElementOnChange(e: any) {
    setCardComplete({ ...cardComplete, [e.elementType]: e.complete })
  }

  const { mutate } = useCreateOrUpgradeFixedSubscription()
  const { mutate: mutateDowngrade } = useDowndgradeFixedSubscription()

  const handleSubmit = async () => {
    if (!stripe || !elements || !clientSecret) {
      return
    }

    setIsLoading(true)

    const result = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: elements.getElement(CardNumberElement)!,
        billing_details: {
          name: nameOnCard,
          address: {
            line1: billingAddress.lineOne,
            line2: billingAddress.lineTwo,
            city: billingAddress.suburb,
            postal_code: billingAddress.postCode,
            state: billingAddress.state,
            country: billingAddress.countryCode,
          },
        },
      },
    })

    if (
      result.error ||
      !result.setupIntent.payment_method ||
      result.setupIntent.status != 'succeeded'
    ) {
      setInvoice(null)
      setIsLoading(false)
      setPaymentError({
        errorCode: result.error?.code,
        declinedCode: result.error?.decline_code,
      })
      setIsInvoiceModalActive(true)
      return
    }

    if (subscriptionMode == SubscriptionMode.Downgrade) {
      mutateDowngrade(
        {
          packageId,
          promotionCode: fixedPromotion ? fixedPromotion.promotionCode : '',
          packageName: packageName,
          cardDetails: {
            paymentCardId: result.setupIntent.payment_method as string,
            isSaveMyCardChecked,
            billingAddress,
          },
        },
        {
          onSuccess: ({ data }: any) => {
            setInvoice(data.invoice)
            setCardBillingAddress(data.billingAddress)
            dispatch(setIsHeaderBarDetailsUpdated(!isHeaderBarDetailsUpdated))
            dispatch(toggleIsRefetchBillingHistory())
            dispatch(toggleIsRefetchPaymentCards())
          },
          onError: ({ response: { data: errData } }: any) => {
            setPaymentError({
              errorCode: 'downgrade',
              message: errData?.message,
            })
            setInvoice(null)
          },
          onSettled: () => {
            setIsLoading(false)
            setIsInvoiceModalActive(true)
            setFixedSubscriptionPaymentModalActive(false)
          },
        },
      )
    } else {
      mutate(
        {
          packageId,
          promotionCode: fixedPromotion ? fixedPromotion.promotionCode : '',
          packageName: packageName,
          subscriptionMode,
          cardDetails: {
            paymentCardId: result.setupIntent.payment_method as string,
            isSaveMyCardChecked,
            billingAddress,
          },
        },
        {
          onSuccess: ({ data }: any) => {
            setInvoice(data.invoice)
            setCardBillingAddress(data.billingAddress)
            dispatch(setIsHeaderBarDetailsUpdated(!isHeaderBarDetailsUpdated))
            dispatch(toggleIsRefetchBillingHistory())
            dispatch(toggleIsRefetchPaymentCards())
          },
          onError: () => {
            setPaymentError(null)
            setInvoice(null)
          },
          onSettled: () => {
            setIsLoading(false)
            setIsInvoiceModalActive(true)
            setFixedSubscriptionPaymentModalActive(false)
          },
        },
      )
    }
  }

  return (
    <Column className='mt-6'>
      <div className='flex flex-col p-6 bg-white border rounded-lg border-Gray-200'>
        <div className='text-[#414244] font-SemiBold'>Card Details</div>

        <div className='pb-1 mt-4 text-small'>
          <div className='flex text-Gray-800 font-Medium'>
            <span>Card Number</span>
            <span className='text-Red-500 pl-[2px]'>*</span>
          </div>
        </div>

        <CardNumberElement
          onChange={handleCardElementOnChange}
          options={{
            showIcon: true,
            classes: {
              base: 'p-3 border border-solid rounded-md shadow-sm border-Gray-300 text-Gray-800 focus:outline-Cobalt-400 bg-white',
              focus: 'ring-1 ring-Cobalt-400',
            },
          }}
        />

        <div className='flex items-center justify-around w-full py-5 space-x-5'>
          <div className='flex-col w-full'>
            <div className='flex mb-1 text-Gray-800 font-Medium'>
              <span>Expiry</span>
              <span className='text-Red-500 pl-[2px]'>*</span>
            </div>
            <CardExpiryElement
              onChange={handleCardElementOnChange}
              options={{
                classes: {
                  base: 'p-3 border border-solid rounded-md shadow-sm border-Gray-300 text-Gray-800 w-full bg-white',
                  focus: 'ring-1 ring-Cobalt-400',
                },
              }}
            />
          </div>
          <div className='flex-col w-full'>
            <div className='flex mb-1 text-Gray-800 font-Medium'>
              <span>CVC</span>
              <span className='text-Red-500 pl-[2px]'>*</span>
            </div>
            <CardCvcElement
              onChange={handleCardElementOnChange}
              options={{
                classes: {
                  base: 'p-3 border border-solid rounded-md shadow-sm border-Gray-300 text-Gray-800 focus:outline-Cobalt-400 w-[200px] bg-white',
                  focus: 'ring-1 ring-Cobalt-400',
                },
              }}
            />
          </div>
        </div>

        <TextField
          className='mb-4'
          value={null}
          isRequired
          onChange={(e: any) => setNameOnCard(e.target.value)}
          label='Name on the card'
          placeholder=''
        />

        <RowWithAlignment>
          <Checkbox
            labelText='Save my card for future payments'
            defaultChecked={isSaveMyCardChecked}
            onChange={(e: any) => {
              setIsSaveMyCardChecked(e.target.checked)
            }}
          />
          {isCardsAvailable && (
            <Button
              onClick={() => setIsGoToExistingCard(true)}
              theme='link'
              textTheme='blue'>
              Use existing cards
            </Button>
          )}
        </RowWithAlignment>
      </div>

      <BillingAddressForm
        setIsValidBillingAddress={setIsValidBillingAddress}
        billingAddress={billingAddress}
        setBillingAddress={setBillingAddress}
        isCheckoutModal={true}
      />

      <Button
        isLoading={isLoading}
        isDisabled={
          !nameOnCard ||
          !isValidBillingAddress ||
          !stripe ||
          !cardComplete.cardNumber ||
          !cardComplete.cardExpiry ||
          !cardComplete.cardCvc
        }
        className='w-1/2 mt-6'
        onClick={handleSubmit}
        textTheme='white'
        theme='cobalt'>
        Subscribe
      </Button>
    </Column>
  )
}
