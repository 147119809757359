import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

export type CreateQuestionnaireRequest = {
  name: string
  type: string
  questions: QuestionnaireQuestionDto[]
  isActive: boolean
  surveyType?: string
}

export type QuestionnaireQuestionDto = {
  title: string
  type: string
  isRequired: boolean
  meta: string[]
}

export const useCreateQuestionnaire = () => {
  const mutate = useMutation(
    ({
      name,
      type,
      questions,
      isActive,
      surveyType,
    }: CreateQuestionnaireRequest) => {
      return postAPI(`/facade/Questionnaire`, {
        questionnaire: { name, type, questions, isActive, surveyType },
      })
    },
  )

  return mutate
}
