/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { Button } from '@labourhub/labour-hub-ds'
import CN from 'classnames'

type SubscriptionQuotaStatus = 'empty' | 'partial' | 'full'

export type SubscriptionUpgradeBadgeBaseProps = {
  totalQuota: number
  remainingCredit: number
}

export type SubscriptionUpgradeBadgeProps =
  SubscriptionUpgradeBadgeBaseProps & {
    btnTitle?: string | undefined
    className?: string | undefined
    hasTooltip?: boolean
    icon?: string
    id?: string | undefined
    isActionBtnRequired?: boolean
    label?: string | undefined
    onClickActionBtn?: () => void
    onClickBadge?: any | undefined
    tooltipText?: string | undefined
    [x: string]: any
  }

const quotaStatusStyles: Record<
  SubscriptionQuotaStatus,
  {
    borderColor: string
  }
> = {
  full: {
    borderColor: 'outline-Gray-200',
  },
  partial: {
    borderColor: 'outline-[#eb9020]',
  },
  empty: {
    borderColor: 'outline-[#ff4f3f]',
  },
}

export const SubscriptionUpgradeBadge = ({
  btnTitle,
  className,
  hasTooltip,
  id,
  isActionBtnRequired,
  icon,
  label = 'Remaining Credits',
  onClickActionBtn,
  onClickBadge,
  remainingCredit,
  totalQuota,
  tooltipText,
  ...restProps
}: SubscriptionUpgradeBadgeProps) => {
  const [styles, setStyles] = useState(quotaStatusStyles['empty'])
  const [quotaValue, setQuotaValue] = useState(remainingCredit)

  useEffect(() => {
    const getQuotaStatus = () => {
      if (!remainingCredit || !totalQuota) {
        return 'empty'
      } else if (remainingCredit === totalQuota) {
        return 'full'
      } else if (remainingCredit > 0 && remainingCredit < totalQuota) {
        return 'partial'
      } else {
        return 'empty'
      }
    }

    const status = getQuotaStatus()
    setStyles(quotaStatusStyles[status])
    setQuotaValue(
      remainingCredit > 0 ? Math.min(remainingCredit, totalQuota) : 0,
    )
  }, [totalQuota, remainingCredit])

  const badgeClassName = CN(
    'flex items-center w-fit outline outline-[1.5px] -outline-offset-[1.5px] rounded-lg text-extra-small text-[#414244]',
    styles.borderColor,
    {
      'cursor-pointer': onClickBadge !== undefined,
    },
  )

  return (
    <div className={CN('flex items-center', className)} {...restProps}>
      <div
        className={badgeClassName}
        onClick={(e) => {
          onClickBadge && onClickBadge(e)
        }}>
        <div>
          <div
            className={CN('flex items-center w-fit py-2 gap-x-[6px]', {
              'pl-3': isActionBtnRequired,
              'px-3': !isActionBtnRequired,
            })}>
            {icon && <i className={CN('text-[18px] text-Gray-400', icon)}></i>}

            {label && (
              <span className='font-Regular text-Gray-600'>{label}</span>
            )}

            <span className='font-Bold text-center'>{quotaValue}</span>
          </div>
        </div>

        {isActionBtnRequired && (
          <Button
            className='border-[#757575] border-[1px] rounded-lg py-[8px] z-[10] ml-[6px]'
            theme='white'
            textTheme='black'
            size='sm'
            onClick={onClickActionBtn}
            {...restProps.actionBtnProps}
            data-tip={`${id}-tooltip-text`}
            data-for={`${id}-tooltip-text`}>
            {btnTitle}
          </Button>
        )}

        {isActionBtnRequired && hasTooltip && tooltipText && (
          <ReactTooltip
            id={`${id}-tooltip-text`}
            place={'bottom'}
            effect='solid'
            textColor='#FFFFFF'
            backgroundColor='#4B5563'
            className='rounded-full text-extra-small'>
            <p>
              {tooltipText.includes('\\n')
                ? tooltipText.split('\\n').map((line, index) => (
                    <span key={index}>
                      {line}
                      <br />
                    </span>
                  ))
                : tooltipText}
            </p>
          </ReactTooltip>
        )}
      </div>
    </div>
  )
}
