import { useQuery } from 'react-query'
import { get } from 'framework/api/http'
import { ReportQuestion } from 'framework/api/methods'
import { QueryParams } from 'types'

export type SurveyResponseListDto = {
  surveyResponses: SurveyResponseDto[]
  take: any
  skip: any
  totalCount: any
}

export type SurveyResponseDto = {
  surveyId: string
  surveyName: string
  surveyTemplateType: string
  candidateId: string
  candidateName: string
  sentOn: string
  completedOn?: string
  status: string
  jsonData: string
  jsonSchema: string
  jsonUiSchema: string
  reportQuestions: ReportQuestion[]
}

type SurveyResponseListQueryParams = QueryParams & {
  candidateId: string
  status: string
}

const getSurveyResponsesList = async ({ queryKey }: any) => {
  const {
    candidateId,
    search,
    skip,
    take,
    status,
    sortColumnName,
    sortDirection,
  } = queryKey[1] as SurveyResponseListQueryParams
  let URI = `/candidates/SurveyResponses?candidateId=${candidateId}&skip=${skip}&take=${take}`
  if (search) {
    URI += `&search=${search}`
  }
  if (status) {
    URI += `&status=${status}`
  }
  if (sortColumnName) {
    URI += `&sortColumnName=${sortColumnName}`
  }
  if (sortDirection) {
    URI += `&sortDirection=${sortDirection}`
  }
  const res = await get(URI)
  return res.data
}

export const useGetSurveyResponsesList = (
  { ...reqBody },
  onSuccess: any,
  onError: any,
) => {
  return useQuery(['SurveyResponses', reqBody], getSurveyResponsesList, {
    enabled: true,
    refetchOnWindowFocus: false,
    onSuccess,
    onError,
  })
}
