import { useRef, useState } from 'react'
import { Button } from '@labourhub/labour-hub-ds'

import { Card } from 'components/atoms/Card'

import AssessmentCertificateModal from './Certificates/AssessmentCertificateModal'
import Progressbar from './Certificates/Progressbar'
import SendCertificateModal from './Certificates/SendCertificateModal'

export type AutoMarkingProgressProps = {
  values: {
    correct: number
    partiallyCorrect: number
    incorrect: number
    notAnswered: number
  }
  isReport: boolean
  totalMarks: any
  template?: any
  candidateDetails?: any
}

const AutoMarkingProgress = ({
  values,
  isReport,
  totalMarks,
  template,
  candidateDetails,
}: AutoMarkingProgressProps) => {
  const [isModalActive, setIsModalActive] = useState(false)
  const [isSentModalActive, setIsSentModalActive] = useState(false)

  const issueDate = new Date()
  const certificateId = useRef(Math.random().toString(36).substring(2, 8))

  const handleSend = () => {
    setIsSentModalActive(true)
  }

  return (
    <>
      <Card className='h-[65%]'>
        <div className='flex items-center justify-between'>
          <div className='text-heading-4 font-SemiBold'>
            {isReport ? 'Average score' : 'Auto marking'}
          </div>
          {!isReport && (
            <div className='flex flex-row gap-[10px]'>
              <Button
                onClick={() => setIsModalActive(true)}
                className='text-extra-small border-0'
                textTheme='blue'
                theme='white'>
                <i className='ri-eye-line text-[16px] mr-2'></i>
                View Certificate
              </Button>
              <Button className='text-extra-small' onClick={handleSend}>
                <i className='ri-send-plane-fill text-[16px] mr-2'></i>
                Send Certificate
              </Button>
            </div>
          )}
        </div>
        <Progressbar values={values} totalMarks={totalMarks} />
      </Card>
      {!isReport && (
        <AssessmentCertificateModal
          isModalActive={isModalActive}
          setIsModalActive={setIsModalActive}
          values={values}
          totalMarks={totalMarks}
          template={template}
          candidateDetails={candidateDetails}
          certificateId={certificateId.current}
          issueDate={issueDate}
        />
      )}
      {!isReport && (
        <SendCertificateModal
          isModalActive={isSentModalActive}
          setIsModalActive={setIsSentModalActive}
          values={values}
          totalMarks={totalMarks}
          template={template}
          candidateDetails={candidateDetails}
          certificateId={certificateId.current}
          issueDate={issueDate}
        />
      )}
    </>
  )
}

export default AutoMarkingProgress
