/** questionnaires status list */
export const Published = 'Published'
export const Draft = 'Draft'

/** backend issue (variable name) :  true false values send through api for get filter result from status filtering.
 *  because of that status list value and label are different */
export const questionnairesTemplateStatusList = [
  {
    value: 'true',
    label: 'Published',
  },
  {
    value: 'false',
    label: 'Draft',
  },
]
// export const questionnairesTemplateStatusList = [
//   {
//     value: 'true',
//     label: 'Published',
//   },
//   {
//     value: 'false',
//     label: 'Draft',
//   },
// ]
