import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Toast } from '@labourhub/labour-hub-ds'
import {
  AssessmentLibraryDto,
  usePublishAssessmentLibrary,
} from 'api/assessment'
import { refreshAssessmentList } from 'features/questionnaires/store/actions'

import { Column } from 'components/atoms/Column'
import { RowWithAlignment } from 'components/atoms/Row'
import { getS3File } from 'utils'

import { AssessmentLibraryPreviewModal } from './AssessmentLibraryPreviewModal'

type AssessmentLibraryCardItemProps = {
  libraryItem: AssessmentLibraryDto
  refetchLibraryList: any
}

export const AssessmentLibraryCardItem = ({
  libraryItem,
  refetchLibraryList,
}: AssessmentLibraryCardItemProps) => {
  const notify = (props: any) => Toast(props)
  const dispatch = useDispatch()

  const { mutate } = usePublishAssessmentLibrary()
  const [isShowPreviewModal, setIsShowPreviewModal] = useState(false)

  const publishAssessmentLibrary = () => {
    mutate(
      { id: libraryItem.id, assessmentType: 'Assessment' },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData.message,
            alertBody: '',
            status: 'Success',
          })
          refetchLibraryList()
          dispatch(refreshAssessmentList())
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Unable to publish library',
            status: 'Error',
          })
        },
      },
    )
  }

  return (
    <div className='w-[380px] border border-Gray-200 shadow-sm rounded'>
      <Column className='items-center '>
        <img
          src={getS3File(libraryItem.imageUrl)}
          alt=''
          className='flex w-[145px] justify-center items-center h-[110px] mt-2'
        />

        {/* Title */}
        <span className='mt-2 text-base text-Gray-800 font-SemiBold'>
          {libraryItem.role}
        </span>

        {/* Subtitles in one line */}
        <p className='mt-1 text-small text-Gray-500 text-center'>
          {libraryItem.questionCount} Questions / {libraryItem.timeToComplete}{' '}
          Minutes to Complete
        </p>

        {/* Buttons in one line */}
        <RowWithAlignment
          className='w-full p-4 mt-4 bg-Gray-100'
          justifyAlignment='center'>
          <Button
            className='h-[32px] mx-2 w-[120px]'
            theme='white'
            textTheme='blue'
            onClick={() => setIsShowPreviewModal(true)}
            iconRight={<i className='ri-external-link-line text-heading-5' />}>
            Preview
          </Button>
          <Button
            className='h-[32px] mx-2 w-[120px]'
            textTheme='white'
            onClick={publishAssessmentLibrary}
            isDisabled={libraryItem.isPublished}
            iconRight={
              <i className='ri-share-forward-box-fill text-heading-5' />
            }>
            Publish
          </Button>
        </RowWithAlignment>
      </Column>

      {isShowPreviewModal && (
        <AssessmentLibraryPreviewModal
          isModalActive={isShowPreviewModal}
          setIsModalActive={setIsShowPreviewModal}
          templateId={libraryItem.id}
          templateName={libraryItem.role}
        />
      )}
    </div>
  )
}
