import { FixedSubscriptionPackageType, SubscriptionType } from '../types'

// Define the plan relationships in a structured way
export const planRelationships: Record<string, Record<string, string>> = {
  [`${FixedSubscriptionPackageType.FifteenPack}-${SubscriptionType.Monthly}`]: {
    [`${FixedSubscriptionPackageType.FifteenPack}-${SubscriptionType.Monthly}`]:
      'Current Pack',
    [`${FixedSubscriptionPackageType.TwentyfivePack}-${SubscriptionType.Monthly}`]:
      'Upgrade',
    [`${FixedSubscriptionPackageType.FiftyPack}-${SubscriptionType.Monthly}`]:
      'Upgrade',
    [`${FixedSubscriptionPackageType.FifteenPack}-${SubscriptionType.Yearly}`]:
      'Upgrade',
    [`${FixedSubscriptionPackageType.TwentyfivePack}-${SubscriptionType.Yearly}`]:
      'Upgrade',
    [`${FixedSubscriptionPackageType.FiftyPack}-${SubscriptionType.Yearly}`]:
      'Upgrade',
  },
  [`${FixedSubscriptionPackageType.TwentyfivePack}-${SubscriptionType.Monthly}`]:
    {
      [`${FixedSubscriptionPackageType.FifteenPack}-${SubscriptionType.Monthly}`]:
        'Downgrade',
      [`${FixedSubscriptionPackageType.TwentyfivePack}-${SubscriptionType.Monthly}`]:
        'Current Pack',
      [`${FixedSubscriptionPackageType.FiftyPack}-${SubscriptionType.Monthly}`]:
        'Upgrade',
      [`${FixedSubscriptionPackageType.FifteenPack}-${SubscriptionType.Yearly}`]:
        'Upgrade',
      [`${FixedSubscriptionPackageType.TwentyfivePack}-${SubscriptionType.Yearly}`]:
        'Upgrade',
      [`${FixedSubscriptionPackageType.FiftyPack}-${SubscriptionType.Yearly}`]:
        'Upgrade',
    },
  [`${FixedSubscriptionPackageType.FiftyPack}-${SubscriptionType.Monthly}`]: {
    [`${FixedSubscriptionPackageType.FifteenPack}-${SubscriptionType.Monthly}`]:
      'Downgrade',
    [`${FixedSubscriptionPackageType.TwentyfivePack}-${SubscriptionType.Monthly}`]:
      'Downgrade',
    [`${FixedSubscriptionPackageType.FiftyPack}-${SubscriptionType.Monthly}`]:
      'Current Pack',
    [`${FixedSubscriptionPackageType.FifteenPack}-${SubscriptionType.Yearly}`]:
      'Upgrade',
    [`${FixedSubscriptionPackageType.TwentyfivePack}-${SubscriptionType.Yearly}`]:
      'Upgrade',
    [`${FixedSubscriptionPackageType.FiftyPack}-${SubscriptionType.Yearly}`]:
      'Upgrade',
  },
  [`${FixedSubscriptionPackageType.FifteenPack}-${SubscriptionType.Yearly}`]: {
    [`${FixedSubscriptionPackageType.FifteenPack}-${SubscriptionType.Monthly}`]:
      'Downgrade',
    [`${FixedSubscriptionPackageType.TwentyfivePack}-${SubscriptionType.Monthly}`]:
      'Downgrade',
    [`${FixedSubscriptionPackageType.FiftyPack}-${SubscriptionType.Monthly}`]:
      'Downgrade',
    [`${FixedSubscriptionPackageType.FifteenPack}-${SubscriptionType.Yearly}`]:
      'Current Pack',
    [`${FixedSubscriptionPackageType.TwentyfivePack}-${SubscriptionType.Yearly}`]:
      'Upgrade',
    [`${FixedSubscriptionPackageType.FiftyPack}-${SubscriptionType.Yearly}`]:
      'Upgrade',
  },
  [`${FixedSubscriptionPackageType.TwentyfivePack}-${SubscriptionType.Yearly}`]:
    {
      [`${FixedSubscriptionPackageType.FifteenPack}-${SubscriptionType.Monthly}`]:
        'Downgrade',
      [`${FixedSubscriptionPackageType.TwentyfivePack}-${SubscriptionType.Monthly}`]:
        'Downgrade',
      [`${FixedSubscriptionPackageType.FiftyPack}-${SubscriptionType.Monthly}`]:
        'Downgrade',
      [`${FixedSubscriptionPackageType.FifteenPack}-${SubscriptionType.Yearly}`]:
        'Downgrade',
      [`${FixedSubscriptionPackageType.TwentyfivePack}-${SubscriptionType.Yearly}`]:
        'Current Pack',
      [`${FixedSubscriptionPackageType.FiftyPack}-${SubscriptionType.Yearly}`]:
        'Upgrade',
    },
  [`${FixedSubscriptionPackageType.FiftyPack}-${SubscriptionType.Yearly}`]: {
    [`${FixedSubscriptionPackageType.FifteenPack}-${SubscriptionType.Monthly}`]:
      'Downgrade',
    [`${FixedSubscriptionPackageType.TwentyfivePack}-${SubscriptionType.Monthly}`]:
      'Downgrade',
    [`${FixedSubscriptionPackageType.FiftyPack}-${SubscriptionType.Monthly}`]:
      'Downgrade',
    [`${FixedSubscriptionPackageType.FifteenPack}-${SubscriptionType.Yearly}`]:
      'Downgrade',
    [`${FixedSubscriptionPackageType.TwentyfivePack}-${SubscriptionType.Yearly}`]:
      'Downgrade',
    [`${FixedSubscriptionPackageType.FiftyPack}-${SubscriptionType.Yearly}`]:
      'Current Pack',
  },
}
