import { useMutation } from 'react-query'
import { CardDetailsDto } from 'api/subscriptions'
import {
  PayAsYouGoSubscriptionPackageType,
  SubscriptionType,
} from 'features/subscriptions'
import { putAPI } from 'framework/api/http'

export type VerifyAccountDto = {
  id: string
  verificationCode: string
}

export type PaymentDataDto = {
  id: string
  onboardingPackageType: string
  onboardingSubscriptionType: SubscriptionType
  cardDetails: CardDetailsDto
  fixedSubscriptionPackageId?: string | null
  payAsYouGoSubscription?: {
    promotionCode?: string
    credits: Record<PayAsYouGoSubscriptionPackageType, number>
  }
  fixedSubscriptionPackageInfo?: {
    promotionCode?: string
    fixedSubscriptionPackageId?: string
  }
}

export type AboutYouDto = {
  id: string
  firstName: string
  lastName: string
  role: string
  hearAboutUs: string
  // avatarUrl: string
}

export type AboutYourBusinessDto = {
  id: string
  companyName: string
  industry: string
  subDomain: string
  // referredBy?: string
}

export type BrandingLogosDto = {
  id: string
  primaryLogoUrl?: string
  secondaryLogoUrl?: string
}

export type ContactInformationDto = {
  id: string
  website: string
  contactUsUrl: string
  privacyPolicyUrl: string
}

export type CompanyLocationDto = {
  id: string
  streetName: string
  country: string
  countryCode: string
  state: string
  stateCode: string
  suburb: string
  postCode: string
}

export type ColleagueForm = {
  name: string
  email: string
  roleName: string
  roleId: string
}

export type ColleaguesDto = {
  id: string
  forms: ColleagueForm[]
}

export type CompanySettingsOneDto = {
  id: string
  isStaffRequest: boolean
  isCareerInterest: boolean
  // isSmsActivationRequired: boolean
}

export type CompanySettingsTwoDto = {
  id: string
  isAutoReferencingRequired: boolean
  isCandidateProfileImageRequired: boolean
  isACICRegLinkRequired: boolean
}

export type StepperDto = {
  onboardingStatus?: string
  paymentData?: PaymentDataDto
  aboutYou?: AboutYouDto
  aboutYourBusiness?: AboutYourBusinessDto
  brandingLogos?: BrandingLogosDto
  contactInformation?: ContactInformationDto
  companyLocation?: CompanyLocationDto
  colleagues?: ColleaguesDto
  companySettingsOne?: CompanySettingsOneDto
  companySettingsTwo?: CompanySettingsTwoDto
}

export const useUpdateOnboardingStepper = () => {
  const mutate = useMutation((dto: StepperDto) => {
    return putAPI(`/onboarding/stepper`, {
      ...dto,
    })
  })

  return mutate
}
