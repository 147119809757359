import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin'
import { $getRoot } from 'lexical'
import {
  BeautifulMentionNode,
  BeautifulMentionsPlugin,
} from 'lexical-beautiful-mentions'

import { CustomMenu } from './components/CustomMenu'
import { CustomMenuItem } from './components/CustomMenuItem'
import { InitialStatePlugin } from './plugins/InitialStatePlugin'
import { plainTextheme } from './theme'

import './styles.scss'

type LexicalPlainTextEditorProps = {
  initialState?: string
  placeholder?: string
  onChange: (value: string) => void
  mentions?: string[]
}

export const LexicalPlainTextEditor = ({
  initialState,
  placeholder = '',
  onChange,
  mentions = [],
}: LexicalPlainTextEditorProps) => {
  return (
    <LexicalComposer
      initialConfig={{
        namespace: 'LexicalPlainTextEditor',
        nodes: [BeautifulMentionNode],
        onError: (error: Error) => console.log(error),
        theme: plainTextheme,
      }}>
      <div className='editor-container'>
        <div className='rounded-md editor-inner'>
          <PlainTextPlugin
            contentEditable={
              <ContentEditable className='leading-6 min-h-[125px] w-full py-2 text-Gray-800 rounded-md border shadow-sm focus:outline-Cobalt-400 border-Gray-300 px-3' />
            }
            placeholder={
              <div className='editor-placeholder'>{placeholder}</div>
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
          <AutoFocusPlugin />
          <BeautifulMentionsPlugin
            items={{ '@': mentions }}
            menuComponent={CustomMenu}
            menuItemComponent={CustomMenuItem}
            allowSpaces={false}
          />
          <InitialStatePlugin initialState={initialState} mentions={mentions} />
          <OnChangePlugin
            onChange={(editorState, editor) => {
              onChange(
                editor
                  .parseEditorState(JSON.stringify(editorState.toJSON()))
                  .read(() => $getRoot().getTextContent()),
              )
            }}
            ignoreSelectionChange
          />
        </div>
      </div>
    </LexicalComposer>
  )
}
