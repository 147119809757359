/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from 'react'
import { Button, SimpleSelect } from '@labourhub/labour-hub-ds'
import { surveyTemplateType } from 'features/candidates/data/surveyTemplateType'
import { AnimatePresence, motion } from 'framer-motion'

import { cn } from 'utils'

type ChooseTemplateSearchHeaderProps = {
  filters: any
  setFilters: any
}

export const ChooseTemplateSearchHeader = ({
  filters,
  setFilters,
}: ChooseTemplateSearchHeaderProps) => {
  const [searchKeyword, setSearchKeyword] = useState<string>('')

  const [filterActive, setFilterActive] = useState(false)

  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false)
  const [selectedType, setSelectedType] = useState<any>({
    label: '',
    value: '',
  })

  useEffect(() => {
    setFilters({
      ...filters,
      searchKeyword,
    })
  }, [searchKeyword])

  const applyFilter = () => {
    setFilterActive(true)
    setIsFilterMenuOpen(false)
    setFilters({
      ...filters,
      type: selectedType?.value || '',
      searchKeyword,
    })
  }

  const resetFilter = () => {
    setFilterActive(false)
    setSearchKeyword('')
    setSelectedType('')
    setIsFilterMenuOpen(false)
    setFilters({
      searchKeyword: undefined,
      status: undefined,
      type: undefined,
      filteredSurveyType: undefined,
    })
  }

  return (
    <div className='flex flex-col justify-center w-[418px] min-h-[60px] px-2 mb-5 bg-white border rounded-lg outline-none border-Gray-200'>
      <div
        className={cn('flex items-center justify-between pt-[8px] pb-[8px]', {
          'border-b border-Gray-200': isFilterMenuOpen,
        })}>
        <div className='pl-[8px] w-full relative'>
          <input
            value={searchKeyword}
            onKeyUp={(e: any) => {
              if (e.keyCode == 13 || searchKeyword.length === 0) {
                setSearchKeyword(searchKeyword)
              }
            }}
            onChange={(e: any) => setSearchKeyword(e.target.value ?? '')}
            type='text'
            className='w-full font-Regular py-[4px] pl-8 text-Gray-600 active:outline-none focus:outline-none rounded'
            placeholder='Search template'
          />

          <i className='absolute left-[16px] top-[3px] ri-search-line text-heading-5 text-Gray-400'></i>

          {searchKeyword && (
            <i
              className='absolute right-[10px] top-[6px] ri-close-line text-Gray-400'
              onClick={() => setSearchKeyword('')}></i>
          )}
        </div>

        <div className='flex items-center'>
          <div className='relative'>
            <Button
              icon={
                <i className='ri-filter-3-line text-heading-5 text-Cobalt-600' />
              }
              isIconOnly
              onClick={() => setIsFilterMenuOpen(!isFilterMenuOpen)}
              theme='white'
              size='sm'
            />
            {filterActive && (
              <div className='w-[6px] h-[6px] bg-Cobalt-600 rounded-full absolute top-[-2px] right-[-2px]'></div>
            )}
          </div>
        </div>
      </div>

      {/* expanding filter panel with open close animation */}
      <AnimatePresence initial={false}>
        {isFilterMenuOpen && (
          <motion.div
            className='flex w-full bg-white'
            initial='collapsed'
            animate='open'
            exit='collapsed'
            transition={{ type: 'tween', duration: 0.3 }}
            variants={{
              open: { opacity: 1, y: 0, height: 'auto' },
              collapsed: { opacity: -1, y: -35, height: 0 },
            }}>
            <div className='flex justify-center items-end pt-3 pb-4 z-[4]'>
              <SimpleSelect
                label='Types'
                value={
                  selectedType?.value
                    ? {
                        label: selectedType?.label,
                        value: selectedType?.value,
                      }
                    : null
                }
                className='w-[180px]'
                onChange={(selectedItem: any) => setSelectedType(selectedItem)}
                placeholder='All Templates'
                options={surveyTemplateType}
              />

              <Button
                className='ml-2 h-[40px] w-[120px]'
                isDisabled={!selectedType?.value}
                onClick={() => applyFilter()}>
                Apply Filter
              </Button>
              <Button
                className='ml-2 h-[40px] w-[80px]'
                theme='white'
                textTheme='blue'
                onClick={() => resetFilter()}>
                Reset
              </Button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
