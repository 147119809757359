/* eslint-disable no-empty-pattern */
import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const headerBarDetails = async () => {
  const res = await get('/facade/AgencyProfile/GetToolBar', {})
  return res.data
}

export const useGetHeaderBarDetails = (onSuccess: any) => {
  return useQuery(['headerBarDetails'], headerBarDetails, {
    refetchOnWindowFocus: false,
    retry: 1,
    enabled: false,
    onSuccess,
  })
}

export default useGetHeaderBarDetails
