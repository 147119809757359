import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Checkbox, Toast } from '@labourhub/labour-hub-ds'
import useDeleteImage from 'api/assessment/deleteImage'
import { useImageUpload } from 'api/assessment/imageUpload'
import CN from 'classnames'
import { uploadTypes } from 'features/questionnaires/data/uploadTypes'
import { AssessmentQuestion } from 'features/questionnaires/types'
import { RootState } from 'store/reducers'
import { ModalProps } from 'types'

import { Accordion } from 'components/atoms'
import { RightSideModal } from 'components/atoms/RightSideModal'
import { LexicalPlainTextEditor } from 'components/ui/LexicalEditor'

import AddImage from './AddImage'
import AddVideoLink from './AddVideoLink'
import AnswerSection from './AnswerSection'
import { CustomBoldRadio } from './CustomBoldRadio'
import { DynamicVariablesSection } from './DynamicVariablesSection'
import { OptionSection } from './OptionSection'
import { StartCountSection } from './StartCountSection'

type EditAssessmentDrawerProps = ModalProps & {
  question: AssessmentQuestion
  updateQuestion: (updatedQuestion: AssessmentQuestion) => void
}

export const EditAssessmentDrawer = ({
  isModalActive,
  setIsModalActive,
  question,
  updateQuestion,
}: EditAssessmentDrawerProps) => {
  const initialValue: AssessmentQuestion = {
    group: question.group || null,
    id: question.id || '',
    icon: question.icon || '',
    isRequired: question.isRequired || false,
    meta: question.meta || null,
    name: question.name || '',
    title: question.title || '',
    answer: question.answer || null,
    correctAnswer: question.correctAnswer || null,
    type: question.type || null,
    uploadType: question.uploadType || null,
    image: null,
    imageUrl: question.imageUrl || '',
    imagePath: question.imagePath || '',
    videoLink: question.videoLink || '',
    videoPlatform: question.videoPlatform || null,
    isAIGenerated: question.isAIGenerated || false,
  }

  const [dynamicFieldVisible, setDynamicFieldVisible] = useState(false)

  const [editorKey, setEditorKey] = useState(Math.random())
  const [editableQuestion, setEditableQuestion] =
    useState<AssessmentQuestion>(initialValue)
  const [disabledButton, setDisabledButton] = useState<boolean>(true)
  //   const [uploadType, setUploadType] = useState(null)
  useEffect(() => {
    if (isModalActive) setEditableQuestion(question)
    setEditorKey(Math.random())
  }, [isModalActive])

  const notify = (props: any) => Toast(props)
  const { assessmentId } = useParams()
  const handleClose = () => {
    setEditableQuestion({
      group: 'text',
      id: '',
      icon: '',
      isRequired: false,
      meta: [],
      name: '',
      title: '',
      answer: null,
      correctAnswer: null,
      type: 'heading',
      uploadType: null,
      image: null,
      imageUrl: '',
      imagePath: '',
      videoLink: '',
      videoPlatform: null,
      isAIGenerated: false,
    })
    setIsModalActive(false)
  }

  const { assessmentBuilderVariables } = useSelector(
    (state: RootState) => state.global,
  )

  const { mutate: uploadImage, isLoading: uploadImageLoading } =
    useImageUpload()

  useEffect(() => {
    if (editableQuestion?.uploadType === uploadTypes.Video) {
      setEditableQuestion({
        ...editableQuestion,
        image: null,
      })
    }
    if (editableQuestion?.uploadType === uploadTypes.Image) {
      setEditableQuestion({
        ...editableQuestion,
        videoLink: '',
        videoPlatform: null,
      })
      updateQuestion({
        ...editableQuestion,
        videoLink: '',
        videoPlatform: null,
      })
    }
  }, [editableQuestion?.uploadType])
  const handleAddButtonClick = () => {
    if (
      editableQuestion.uploadType === uploadTypes.Image &&
      editableQuestion.image == null &&
      editableQuestion.imageUrl == ''
    ) {
      setEditableQuestion({
        ...editableQuestion,
        uploadType: '',
      })
      updateQuestion({
        ...editableQuestion,
        uploadType: '',
      })
    }

    if (
      editableQuestion.uploadType === uploadTypes.Video &&
      (!editableQuestion.videoLink || !editableQuestion.videoPlatform)
    ) {
      setEditableQuestion({
        ...editableQuestion,
        uploadType: '',
        videoLink: '',
        videoPlatform: '',
      })
      updateQuestion({
        ...editableQuestion,
        uploadType: '',
        videoLink: '',
        videoPlatform: '',
      })
    }

    if (
      editableQuestion.uploadType === uploadTypes.Image &&
      editableQuestion.image &&
      !editableQuestion.imageUrl
    ) {
      const assessmenttId = assessmentId ? assessmentId : ''
      const questionId = assessmentId ? editableQuestion.id.toString() : ''
      const assessmentImage = editableQuestion.image

      uploadImage(
        { assessmenttId, questionId, assessmentImage },
        {
          onSettled: async ({ data }: any) => {
            setEditableQuestion((prevEditableQuestion) => ({
              ...prevEditableQuestion,
              imageUrl: data.assessmentImageURL,
              imagePath: data.imagePath,
            }))
            updateQuestion({
              ...editableQuestion,
              imageUrl: data.assessmentImageURL,
              imagePath: data.imagePath,
            })
            !uploadImageLoading && setIsModalActive(false)
          },
          onError: () => {
            notify({
              alertHeader: 'Something went wrong',
              alertBody: '',
              status: 'Error',
            })
          },
        },
      )
    }
    updateQuestion(editableQuestion)
    handleClose()
  }

  const { mutate: deleteImageMutate } = useDeleteImage()

  const handleRemove = () => {
    if (question.imagePath) {
      deleteImageMutate(
        {
          imagePath: question.imagePath,
        },
        {
          onSuccess: ({ data: successData }: any) => {
            notify({
              alertHeader: successData.messageTitle
                ? successData.messageTitle
                : 'Image was removed!',
              alertBody: '',
              status: 'Success',
            })
            setEditableQuestion((prevEditableQuestion) => ({
              ...prevEditableQuestion,
              image: null,
              imagePath: '',
              imageUrl: '',
            }))
          },
          onError: ({ response: { data: errData } }: any) => {
            notify({
              alertHeader: errData?.messageTitle
                ? errData?.messageTitle
                : 'Error!',
              alertBody: errData?.message ? errData?.message : 'Error!',
              status: 'Error',
            })
          },
        },
      )
    } else {
      setEditableQuestion((prevEditableQuestion) => ({
        ...prevEditableQuestion,
        image: null,
        imagePath: '',
        imageUrl: '',
        uploadType: null,
      }))
    }
  }

  useEffect(() => {
    const checkDisabledButton = () => {
      if (editableQuestion.title.trim() === '') {
        return true
      }

      const starCount = Number(editableQuestion.meta[0] ?? '')
      if (
        editableQuestion.type === 'rating' &&
        (isNaN(starCount) || starCount < 1 || starCount > 10)
      ) {
        return true
      }
      if (
        editableQuestion.type !== 'heading' &&
        editableQuestion.type !== 'paragraph'
      ) {
        const correctAnswerIsEmpty =
          editableQuestion.correctAnswer === '' ||
          editableQuestion.correctAnswer === null ||
          editableQuestion.correctAnswer === '[]' ||
          editableQuestion.correctAnswer === undefined

        if (correctAnswerIsEmpty) {
          return true
        }
      }
      if (
        (editableQuestion.type === 'radio' ||
          editableQuestion.type === 'radio_neg_detail' ||
          editableQuestion.type === 'radio_pos_detail') &&
        editableQuestion.correctAnswer === 'False'
      ) {
        return true
      }
      return false
    }
    setDisabledButton(checkDisabledButton())
  }, [editableQuestion, isModalActive, disabledButton])
  return (
    <RightSideModal
      isActive={isModalActive}
      headerTitle={`Edit ${editableQuestion.name}`}
      headerSubtitle={`Change your ${
        editableQuestion.type === 'paragraph'
          ? 'paragraph'
          : editableQuestion.type === 'heading'
          ? 'heading'
          : 'question title'
      } and other properties`}
      primaryButtonTitle={'Add'}
      isPrimaryButtonDisable={disabledButton}
      onHeaderCloseButtonClick={handleClose}
      onFooterAddButtonClick={handleAddButtonClick}
      onFooterCancelButtonClick={handleClose}>
      <div className='flex flex-col w-full px-6 py-5 h-fit gap-y-5'>
        {/* title section */}
        <div className='w-full px-4 py-2 rounded-lg font-Regular text-Cobalt-800 bg-Cobalt-50'>
          <span>
            {editableQuestion.type === 'paragraph'
              ? 'Paragraph'
              : editableQuestion.type === 'heading'
              ? 'Heading'
              : 'Questions'}
          </span>
        </div>
        <div>
          <div className='flex flex-row justify-between relative'>
            <span className="text-Gray-800 font-Medium after:content-['*'] after:ml-0.5 after:text-TerraCotta-400">
              {editableQuestion.type === 'paragraph'
                ? 'Paragraph'
                : editableQuestion.type === 'heading'
                ? 'Heading'
                : 'Question Title'}
            </span>
            <button
              onMouseEnter={() => setDynamicFieldVisible(true)}
              onMouseLeave={() => setDynamicFieldVisible(false)}
              className='focus:outline-none'>
              <i
                className={CN(
                  'text-[16px] ri-question-line ',
                  dynamicFieldVisible ? 'text-[#09121F]' : 'text-Cobalt-500',
                )}
              />
            </button>
            {dynamicFieldVisible && (
              <div
                className={CN(
                  'rounded-lg mt-1 z-40 border border-Gray-200 bg-white',
                  'w-[330px] h-[170px] absolute top-10 right-0',
                )}>
                <DynamicVariablesSection
                  variables={assessmentBuilderVariables}
                />
              </div>
            )}
          </div>

          <LexicalPlainTextEditor
            key={editorKey}
            initialState={editableQuestion.title}
            onChange={(title: string) =>
              setEditableQuestion({ ...editableQuestion, title })
            }
            mentions={
              assessmentBuilderVariables?.map(
                (item: any) => item.previewData,
              ) ?? []
            }
          />
        </div>

        {/* required checkbox */}
        {editableQuestion.type != 'heading' &&
          editableQuestion.type != 'paragraph' && (
            <Checkbox
              labelText='Required'
              checked={editableQuestion.isRequired}
              labelTextClassName='font-Regular'
              checkboxClassName='ml-0'
              onChange={(e: any) =>
                setEditableQuestion({
                  ...editableQuestion,
                  isRequired: e.target.checked,
                })
              }
            />
          )}

        {(editableQuestion.type === 'checkbox' ||
          editableQuestion.type === 'select') && (
          <OptionSection
            options={
              editableQuestion.meta.length > 0
                ? editableQuestion.meta.map((value) => ({ value }))
                : [{ value: '' }]
            }
            setOptions={(updatedOptions) => {
              setEditableQuestion({
                ...editableQuestion,
                meta: updatedOptions.map((option) => option.value),
              })
            }}
            setDisabledButton={setDisabledButton}
          />
        )}

        {/* add video link accordian */}
        <div>Select 1 option to add to your question:</div>
        <Accordion
          isActive={editableQuestion?.uploadType === uploadTypes.Video}
          heading=''
          itemCenter={true}
          headerComponent={
            <CustomBoldRadio
              className='cursor-pointer select-none'
              id='video'
              onChange={(e) => {
                handleRemove()
                setEditableQuestion({
                  ...editableQuestion,
                  uploadType: e.target.id,
                  videoPlatform: 'Youtube',
                })
              }}
              name='uploadTemplate'
              helperText=''
              labelText='Add a Video Link'
              checked={editableQuestion?.uploadType === uploadTypes.Video}
              onClick={(e: any) => {
                setEditableQuestion({
                  ...editableQuestion,
                  uploadType: e.target.id,
                  videoPlatform: 'Youtube',
                })
              }}
              defaultChecked={
                editableQuestion?.uploadType === uploadTypes.Video
              }
            />
          }
          className=''
          key={'add-video-link'}
          id={'add-video-link'}>
          <AddVideoLink
            isDisabled={editableQuestion?.uploadType !== uploadTypes.Video}
            setEditableQuestion={setEditableQuestion}
            editableQuestion={editableQuestion}
            question={question}
          />
        </Accordion>

        {/* add an image accordian */}
        <Accordion
          isActive={editableQuestion?.uploadType === uploadTypes.Image}
          heading=''
          itemCenter={true}
          headerComponent={
            <CustomBoldRadio
              className='cursor-pointer select-none'
              id='image'
              onChange={(e) => {
                setEditableQuestion({
                  ...editableQuestion,
                  uploadType: e.target.id,
                  videoPlatform: null,
                })
              }}
              name='uploadTemplate'
              helperText=''
              labelText='Add an Image'
              checked={editableQuestion?.uploadType === uploadTypes.Image}
              onClick={(e: any) => {
                setEditableQuestion({
                  ...editableQuestion,
                  uploadType: e.target.id,
                  videoPlatform: null,
                })
              }}
              defaultChecked={
                editableQuestion?.uploadType === uploadTypes.Image
              }
            />
          }
          className=''
          key={'add-image'}
          id={'add-image'}>
          <AddImage
            isDisabled={editableQuestion?.uploadType !== uploadTypes.Image}
            setEditableQuestion={setEditableQuestion}
            editableQuestion={editableQuestion}
            updateQuestion={updateQuestion}
          />
        </Accordion>

        {editableQuestion.type === 'rating' && (
          <StartCountSection
            startCount={String(editableQuestion.meta.length)}
            setStarCount={(meta: string[]) =>
              setEditableQuestion({ ...editableQuestion, meta })
            }
          />
        )}
        {editableQuestion.type !== 'paragraph' &&
          editableQuestion.type !== 'heading' && (
            <AnswerSection
              setEditableQuestion={setEditableQuestion}
              editableQuestion={editableQuestion}
            />
          )}
      </div>
    </RightSideModal>
  )
}
