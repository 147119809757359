import { useMutation } from 'react-query'
import { deleteAPIWithPayload } from 'framework/api/http'

export interface DeleteImageInterface {
  imagePath?: string
}

const useDeleteImage = () => {
  const mutate = useMutation(({ imagePath }: DeleteImageInterface) => {
    return deleteAPIWithPayload(`/assessment/question/image`, { imagePath })
  })

  return mutate
}

export default useDeleteImage
