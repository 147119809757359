import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

import { SmsProviderProps } from './addSmsProvider'

type EditSmsProviderProps = {
  id: string
  provider: SmsProviderProps
}

export const useEditSmsProvider = () => {
  const mutate = useMutation(({ id, provider }: EditSmsProviderProps) => {
    return putAPI(`/SMS/Providers/${id}`, provider)
  })

  return mutate
}
