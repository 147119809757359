import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface AddSuburbProps {
  stateCode?: any
  name?: any
}

export const useAddSuburb = () => {
  const mutate = useMutation(({ stateCode, name }: AddSuburbProps) => {
    return postAPI(`/Suburb`, {
      stateCode,
      name,
    })
  })

  return mutate
}

export default useAddSuburb
