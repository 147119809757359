import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Modal, SimpleSelect, Toast } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { format, parse } from 'date-fns'
import { AnimatePresence, motion } from 'framer-motion'
import {
  useActivateDeactivateUser,
  useGetUserProfileList,
  useReinviteUser,
} from 'framework/api/methods'
import { statusList } from 'static-data/userGroups/statusList'
import { setIsBreadcrumbVisible } from 'store/reducers/breadcrumbs/breadcrumbs'

import { CalendarInput } from 'components/atoms'
import { EmptyTableType, UITable } from 'components/common/UITable'
import { InviteNewUserModal } from 'components/Drawers/InvitedNewUser/InviteNewUserModal'

import { UserListColumn } from './UserListColumn'

type UserListProps = {
  [x: string]: any
}

export const UserList = ({ className, ...restProps }: UserListProps) => {
  const [tableData, setTableData] = useState<any>({})

  const [selectedUser, setSelectedUser] = useState<any>({})
  const [isActivateDeactivateModal, setIsActivateDeactivateModal] = useState({
    enable: false,
    type: '',
  })
  const [inviteUserModalShow, setInviteUserModalShow] = useState(false)
  const [paginationVariables, setPaginationVariables] = useState<any>({
    skip: tableData.skip || 0,
    take: tableData.take || 20,
    search: '',
    sortColumnName: '',
    sortDirection: '',
    dateJoined: '',
    status: '',
  })
  const [searchKeyWord, setSearchKeyWord] = useState<string>('')
  const [isFilterActive, setIsFilterActive] = useState(false)
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState<any>('')
  const [selectedDate, setSelectedDate] = useState<string>()

  const Location: any = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const notify = (props: any) => Toast(props)

  function useQuery() {
    const { search } = useLocation()

    return React.useMemo(() => new URLSearchParams(search), [search])
  }
  const query = useQuery()

  /** Start BreadCrumbs -------------------------------------------------------------------->>>>>>>>>>>>>>> */
  const { menuItems } = useSelector((state: any) => state.sideMenuList)
  useEffect(() => {
    dispatch(setIsBreadcrumbVisible(true))

    return () => {
      dispatch(setIsBreadcrumbVisible(false))
    }
  }, [Location, menuItems])
  /** End BreadCrumbs -------------------------------------------------------------------->>>>>>>>>>>>>>> */

  /** get users List on success */
  const onSuccess = (data: any) => {
    const toBeUpdateTableData = {
      ...data,
      userProfiles: data?.userProfiles?.map((item: any) => {
        return {
          ...item,
          showMore: false,
        }
      }),
    }

    setTableData(toBeUpdateTableData || {})
  }

  // /** More button DropDown handling states */
  const { clickValue } = useSelector((state: any) => state.layout)

  useEffect(() => {
    const toBeUpdateTableData = {
      ...tableData,
      userProfiles: tableData?.userProfiles?.map((item: any) => {
        return {
          ...item,
          showMore: false,
        }
      }),
    }
    setTableData(toBeUpdateTableData)
  }, [clickValue])

  /** API Handling request for Get All Uniform List */
  const {
    refetch: refetchUserProfileListTable,
    isLoading: getUserProfileListIsLoading,
    isFetching: getUserProfileListIsFetching,
  } = useGetUserProfileList(
    paginationVariables.take,
    paginationVariables.skip,
    query.get('user_role'),
    paginationVariables.status,
    paginationVariables.search || '',
    paginationVariables.sortColumnName,
    paginationVariables.sortDirection,
    paginationVariables.dateJoined,
    onSuccess,
  )

  useEffect(() => {
    refetchUserProfileListTable()
  }, [paginationVariables])

  const applyFilter = () => {
    setPaginationVariables({
      ...paginationVariables,
      dateJoined: selectedDate,
      status: selectedStatus?.value,
    })
  }

  const clearFilter = () => {
    setSelectedDate('')
    setSelectedStatus('')
    setPaginationVariables({
      ...paginationVariables,
      dateJoined: '',
      status: '',
    })
    setIsFilterMenuOpen(false)
    setIsFilterActive(false)
  }

  /** API Handling for reinvite user */
  const { mutate: reinviteUserMutate } = useReinviteUser()

  /** Process the reinvite user */
  async function reinviteUser(userId) {
    reinviteUserMutate(
      {
        UserProfileId: userId,
      },
      {
        onSuccess: () => {
          Toast({
            alertHeader: 'Activation email was re-sent successfully',
            status: 'Success',
          })
        },
        onError: () => {
          Toast({
            alertHeader: 'Error sending activation email',
            status: 'Error',
          })
        },
      },
    )
  }

  /** User Table Column Handling */
  const tableColumns = useMemo(() => {
    if (tableData?.userProfiles && tableData?.userProfiles?.length > 0) {
      const columns: any = UserListColumn(
        tableData,
        setTableData,
        navigate,
        setSelectedUser,
        setIsActivateDeactivateModal,
        reinviteUser,
      )
      columns.splice(4, 1)
      return columns
    }
    return []
  }, [tableData])

  /** activate deactivate user api handle */
  const {
    mutate: activateDeactivateUserMutate,
    isLoading: activateDeactivateUserIsLoading,
  } = useActivateDeactivateUser()

  /** Process the activate deactivate user */
  async function activateDeactivateUser() {
    activateDeactivateUserMutate(
      {
        type: isActivateDeactivateModal?.type,
        userId: selectedUser?.id,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData?.message,
            status: 'Success',
          })
          refetchUserProfileListTable()

          setIsActivateDeactivateModal({
            ...isActivateDeactivateModal,
            enable: false,
            type: '',
          })
        },
        onError: () => {
          notify({
            alertHeader: 'Error',
            status: 'Error',
          })
          refetchUserProfileListTable()

          setIsActivateDeactivateModal({
            ...isActivateDeactivateModal,
            enable: false,
            type: '',
          })
        },
      },
    )
  }

  const UserListClasses = CN('user-list w-full h-full', className, {})

  /** on change search */
  useEffect(() => {
    const timer = setTimeout(() => {
      searchKeyWord &&
        setPaginationVariables({
          ...paginationVariables,
          skip: 0,
          search: searchKeyWord,
        })
    }, 1000)
    return () => clearTimeout(timer)
  }, [searchKeyWord])

  return (
    <div className={UserListClasses} {...restProps}>
      <span className='font-Medium text-heading-4 text-Gray-800 pb-2'>
        {Location?.state?.roleName}
      </span>
      <div className='flex items-center justify-between py-2 px-3 bg-white rounded-t-lg border border-Gray-100 outline-none'>
        <div className='pl-[13px] w-2/3 relative'>
          <input
            onKeyUp={() => {
              // if (e.keyCode == 13) {
              //   setPaginationVariables({
              //     ...paginationVariables,
              //     search: searchKeyWord,
              //   })
              // }
              if (searchKeyWord.length === 0) {
                setPaginationVariables({
                  ...paginationVariables,
                  search: searchKeyWord,
                })
              }
            }}
            onChange={(e: any) => {
              if (e.keyCode !== 13 || searchKeyWord.length !== 0) {
                setSearchKeyWord(e.target.value)
              }
            }}
            type='text'
            className='w-full font-Regular py-[6px] pl-10 text-Gray-600 active:outline-Gray-50 focus:outline-Gray-50'
            placeholder='Search user with name or email'
          />
          <i className='absolute left-[26px] top-[5px] ri-search-line text-Gray-400'></i>
        </div>

        {/* Filter and table actions */}
        <div className='flex items-center'>
          <>
            <div className='relative'>
              <Button
                icon={<i className='ri-filter-3-line' />}
                isIconOnly
                onClick={() => {
                  setIsFilterMenuOpen(!isFilterMenuOpen)
                }}
                textTheme='blue'
                theme='white'
              />

              {isFilterActive && (
                <div className='w-[6px] h-[6px] bg-Red-500 rounded-full absolute top-[-2px] right-[-2px]'></div>
              )}
            </div>

            <Button
              isDisabled={false}
              className='ml-2'
              onClick={() => setInviteUserModalShow(true)}
              size='md'
              textTheme='blue'
              iconLeft={<i className='ri-user-shared-line' />}
              theme='white'>
              Invite new user
            </Button>
          </>
        </div>
      </div>
      {/* expanding filter panel with open close animation */}
      <AnimatePresence initial={false}>
        {isFilterMenuOpen && (
          <motion.div
            className='flex flex-col justify-between bg-white w-full'
            initial='collapsed'
            animate='open'
            exit='collapsed'
            transition={{ type: 'tween', duration: 0.3 }}
            variants={{
              open: { opacity: 1, y: 0, height: 'auto' },
              collapsed: { opacity: -1, y: -35, height: 0 },
            }}>
            {/** Input field of filtering section */}

            <div className='flex items-end px-4 w-full pt-3 pb-4 z-[10]'>
              <SimpleSelect
                label='By Status'
                placeholder='All'
                value={selectedStatus}
                className='w-1/4 mr-5'
                onChange={(selectedItem: any) => {
                  setSelectedStatus(selectedItem)
                }}
                options={statusList}
              />
              <div className='flex flex-col'>
                <span className='flex text-Gray-800 font-Medium text-small pb-1'>
                  By Date Joined
                </span>
                <CalendarInput
                  defaultValue={selectedDate || ''}
                  formatDate={(date) => format(date, 'dd/MM/yyyy')}
                  parseDate={(str) => parse(str, 'dd/MM/yyyy', new Date())}
                  onChange={(date) => {
                    if (date) {
                      setSelectedDate(date)
                    }
                  }}
                />
              </div>

              <Button
                className='ml-4 mr-4 h-[38px] w-[139px]'
                isDisabled={!selectedStatus?.value && !selectedDate}
                onClick={() => {
                  setIsFilterActive(true)
                  setIsFilterMenuOpen(false)
                  applyFilter()
                }}>
                Apply Filter
              </Button>

              <Button
                className='h-[38px] w-[100px]'
                theme='white'
                textTheme='blue'
                onClick={() => {
                  clearFilter()
                }}>
                Reset
              </Button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <div className='w-full h-[calc(90vh-100px)] relative'>
        <div
          className={CN(
            'styled-scroll overflow-auto w-full h-[calc(100%-55px)] bg-white',
          )}>
          <UITable
            data={tableData?.userProfiles || []}
            isLoading={
              getUserProfileListIsLoading || getUserProfileListIsFetching
            }
            emptyTableType={EmptyTableType.Consultant}
            emptyBtnOnClick={() => setInviteUserModalShow(true)}
            className=''
            columns={tableColumns}
            allowRowSelection={false}
            isSorted={true}
            hasFooter={true}
            hasCheckBox={true}
            isHeader={true}
            isFilterEnabled={false}
            paginationVariables={paginationVariables}
            setPaginationVariables={setPaginationVariables}
            totalRowCount={tableData?.totalCount || 0}
            isManualSortBy={true}
          />
        </div>
      </div>

      {/** Invite new user drawer modal */}
      <InviteNewUserModal
        notify={notify}
        isActive={inviteUserModalShow}
        setIsActive={setInviteUserModalShow}
        headerTitle='Invite User'
        headerSubtitle='Here you can Invite new users.'
        isButtonDisabled
        buttonTitle='Invite User'
        roleId={query.get('role_id')}
        headerCloseButton={() => {
          setInviteUserModalShow(false)
        }}
        getTableData={refetchUserProfileListTable}
      />

      {/* activate/deactivate user modal */}
      <Modal
        isActive={isActivateDeactivateModal?.enable}
        onClickPrimaryBtn={() => {
          activateDeactivateUser()
        }}
        onClickSecondaryBtn={() => {
          setIsActivateDeactivateModal({
            ...isActivateDeactivateModal,
            enable: false,
            type: '',
          })
        }}
        primaryButtonProps={{
          style: { width: '100%', marginRight: '12px' },
          isLoading: activateDeactivateUserIsLoading,
          theme:
            isActivateDeactivateModal?.type === 'DEACTIVE'
              ? 'cobalt'
              : 'gradient',
        }}
        secondaryButtonProps={{
          style: { width: '100%', color: '#1F2937' },
        }}
        onOverlayClick={() => {
          setIsActivateDeactivateModal({
            ...isActivateDeactivateModal,
            enable: false,
            type: '',
          })
        }}
        modalProps={{
          style: { width: '466px' },
        }}
        footerProps={{
          style: {
            justifyContent: 'space-between',
            paddingLeft: '23px',
            paddingRight: '23px',
          },
        }}
        isHeaderShow={false}
        primaryButtonTitle='Yes, I’m Sure'
        secondaryButtonTitle='Cancel'>
        <div className='flex flex-col items-center justify-center p-6'>
          <div
            className={CN(
              'flex items-center justify-center w-12 h-12 rounded-full',
              {
                ' bg-Green-100': isActivateDeactivateModal?.type === 'DEACTIVE',
                'bg-Red-100': isActivateDeactivateModal?.type !== 'DEACTIVE',
              },
            )}>
            {isActivateDeactivateModal?.type === 'DEACTIVE' ? (
              <i className=' ri-check-line text-Green-500 text-heading-3'></i>
            ) : (
              <i className='ri-close-line text-Red-500 text-heading-3'></i>
            )}
          </div>

          <span className='pt-5 text-Gray-900 text-heading-5 font-Medium'>
            {isActivateDeactivateModal?.type === 'DEACTIVE'
              ? `Activate User?`
              : `Deactivate User?`}
          </span>

          <span className='pt-2 text-center text-Gray-500 text-small font-Regular'>
            Are you sure you want to proceed?
          </span>
        </div>
      </Modal>
    </div>
  )
}
