import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { Button, Toast } from '@labourhub/labour-hub-ds'
import {
  CandidateProfileBasicDetailsType,
  useConvertEmployee,
} from 'api/candidates'
import RequestRefereeImage from 'assets/images/candidate/requestReferee.svg'
import {
  AppSettingKey,
  getAgencyAppSettings,
  isSettingEnabled,
} from 'features/settings'
import { getSubscriptionStore } from 'features/subscriptions'

import { RefereeTypeAccordionContentType } from '../AddEditCandidateModals/RequestSpecificRefereeForm'

import ConvertCandidateEmployeeModel from './ConvertCandidateEmployee/ConvertCandidateEmployeeModel'

export const refereeModalActionTypes: {
  [key in 'Automated' | 'Request']: {
    type: 'automated' | 'request'
    title: string
    description: string
  }
} = {
  Automated: {
    type: 'automated',
    title: 'Automated Reference Check',
    description: 'Please input no of referees',
  },
  Request: {
    type: 'request',
    title: 'Request Referee Details',
    description: 'Please input no of referees',
  },
}

type CandidateRefereeEmptyStateProps = {
  candidateId: string
  candidateName: string
  candidateDetails: CandidateProfileBasicDetailsType & {
    referenceTypes: Array<RefereeTypeAccordionContentType>
  }
  setShowAddRefereeModal: any
  setRefereeModalDetails?: React.Dispatch<
    React.SetStateAction<
      (typeof refereeModalActionTypes)[keyof typeof refereeModalActionTypes]
    >
  >
  setShowRequestRefereeReminderModal?: React.Dispatch<
    React.SetStateAction<boolean>
  >
  setShowRefereeRequestModal?: React.Dispatch<React.SetStateAction<boolean>>
}

export const CandidateRefereeEmptyState = ({
  candidateDetails,
  setShowAddRefereeModal,
  candidateId,
  candidateName,
  setShowRefereeRequestModal,
  setShowRequestRefereeReminderModal,
  setRefereeModalDetails,
}: CandidateRefereeEmptyStateProps) => {
  const navigate = useNavigate()
  const [isCandidateConvertModalOpen, setIsCandidateConvertModalOpen] =
    useState(false)

  // Auto referencing
  const agencyAppSettings = useSelector(getAgencyAppSettings)
  const { userDetails }: any = useSelector<any>((state) => state.user)

  const isAutoReferencingEnabled = isSettingEnabled(
    AppSettingKey.AutoReferencing,
    agencyAppSettings,
  )

  const autoReferencingDisabledText =
    userDetails.roleName === 'Administrator' ||
    userDetails.roleName === 'SuperAdministrator'
      ? 'Auto-referencing is currently disabled. Go to Settings to enable this feature.'
      : 'Auto-referencing is currently disabled. Contact your Company Admin to enable this feature. '

  const { mutate: convertEmployeeMutate } = useConvertEmployee()

  const handleConvertCandidateEmployee = () => {
    if (candidateDetails.candidateId) {
      convertEmployeeMutate(
        {
          candidateId: candidateId,
          isEmployee: !candidateDetails.isEmployee,
        },
        {
          onSuccess: ({ data: successData }: any) => {
            Toast({
              alertHeader: successData?.message,
              alertBody: '',
              status: 'Success',
            })
            setIsCandidateConvertModalOpen(false)
            navigate(
              candidateDetails.isEmployee
                ? `/candidate-list/candidate-profile?&candidate_id=${candidateId}&candidate_name=${candidateName}`
                : `/employee-list/employee-profile?&candidate_id=${candidateId}&candidate_name=${candidateName}`,
            )
          },
          onError: ({ response: { data: errData } }: any) => {
            Toast({
              alertHeader: 'Error...!',
              alertBody: errData?.message,
              status: 'Error',
            })
            setIsCandidateConvertModalOpen(false)
          },
        },
      )
    }
  }

  const { fixedQuotas, payAsYouGoQuotas } = useSelector(getSubscriptionStore)

  const isQuotaNotAvailable =
    fixedQuotas.referenceCheck.remainingQuota +
      payAsYouGoQuotas.ReferenceCheck.remainingQuota ==
    0

  const isRefereeCountZero = candidateDetails.refereeCount === 0
  const isReferenceTypesCountZero = candidateDetails.referenceTypes.length === 0

  return (
    <>
      <div className='pt-10 empty-state flex flex-col w-full border-[1px] border-Gray-200 rounded-lg bg-white '>
        <div className='mb-2'>
          <img
            src={RequestRefereeImage}
            alt='request-referee-img'
            className='mx-auto'
          />
        </div>
        <span className='flex justify-center font-Medium text-heading-4 text-Gray-800'>
          Start Reference Check
        </span>
        {candidateDetails.isEmployee === false ? (
          <>
            <span className='flex justify-center pt-2 font-Regular text-small text-Gray-600'>
              Request referee details from candidates or add them manually
            </span>

            <div className='flex flex-wrap justify-center gap-6 gap-y-3 pt-6 pb-10'>
              {/** Request Referee Button*/}
              {(isReferenceTypesCountZero || isRefereeCountZero) && (
                <div className='w-full flex justify-center'>
                  <Button
                    data-tip={autoReferencingDisabledText}
                    data-for='automated-btn'
                    className='text-base'
                    iconLeft={<i className='ri-settings-fill' />}
                    isDisabled={
                      isQuotaNotAvailable ||
                      candidateDetails?.requestSendDate ||
                      !isAutoReferencingEnabled
                    }
                    size='lg'
                    onClick={() => {
                      setRefereeModalDetails!(refereeModalActionTypes.Automated)
                      setShowRefereeRequestModal!(true)
                    }}>
                    Start Automated Reference Check
                    {!isAutoReferencingEnabled && (
                      <ReactTooltip
                        place='right'
                        id={'automated-btn'}
                        effect='solid'
                        textColor='#FFFFFF'
                        backgroundColor='#4B5563'
                        delayShow={800}
                      />
                    )}
                  </Button>
                </div>
              )}
              <Button
                iconLeft={<i className='ri-send-plane-line' />}
                isDisabled={isQuotaNotAvailable}
                textTheme={
                  !isRefereeCountZero && !isReferenceTypesCountZero
                    ? 'white'
                    : 'blue'
                }
                theme={
                  !isRefereeCountZero && !isReferenceTypesCountZero
                    ? 'cobalt'
                    : 'white'
                }
                onClick={() => {
                  if (candidateDetails?.requestSendDate !== null)
                    return setShowRequestRefereeReminderModal!(true)
                  setRefereeModalDetails!(refereeModalActionTypes.Request)
                  setShowRefereeRequestModal!(true)
                }}>
                {candidateDetails?.requestSendDate === null
                  ? `Request Referees from Candidate `
                  : `Send Reminder (Request Referees)`}
              </Button>

              {/** Add New Referee Button */}
              <Button
                iconLeft={<i className='ri-add-fill' />}
                isDisabled={isQuotaNotAvailable}
                textTheme='blue'
                theme='white'
                onClick={() => setShowAddRefereeModal(true)}>
                Add Referee Manually
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className='flex justify-center'>
              <p className='w-[562px] text-center pt-2 font-Regular text-small text-Gray-600'>
                To initiate reference checking, click{' '}
                <span className='font-Bold'>Convert to Candidate.</span>
                <br></br>
                Their profile will move to the candidate section for you to
                conduct reference checks.
              </p>
            </div>

            <div className='flex justify-center pt-6 pb-[52px]'>
              {/* Convert to Candidate Button */}
              <Button
                className='h-[40px] w-[178px]'
                onClick={() => {
                  setIsCandidateConvertModalOpen(true)
                }}>
                Convert to Candidate
              </Button>
            </div>
          </>
        )}
      </div>

      <ConvertCandidateEmployeeModel
        setIsCandidateConvertModalOpen={setIsCandidateConvertModalOpen}
        isCandidateConvertModalOpen={isCandidateConvertModalOpen}
        handleConvertCandidateEmployee={handleConvertCandidateEmployee}
        convertEmployeeLoading={false}
        isEmployee={candidateDetails?.isEmployee}
      />
    </>
  )
}
