/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import ReactTooltip from 'react-tooltip'
import { Avatar } from '@labourhub/labour-hub-ds'
import candidateDefaultLogo from 'assets/images/Profile-Icon.png'
import { GuideTooltipModal } from 'features/onboarding'

import { timeToAESTWithoutSeconds } from 'utils'
import { dateToReadableString } from 'utils/dateToReadableString'

export const EmployeesTableColumn = (
  isEmailRequired: boolean,
  navigate: any,
  guideStepperCandidateId?: string,
) => {
  const COLUMNS = [
    {
      Header: 'NAME',
      accessor: 'firstName',
      forSorting: 'FirstName',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[250px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center w-full',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return (
          <div className='relative w-full cursor-pointer'>
            <div
              onClick={() => {
                navigate(
                  `/employee-list/employee-profile?&candidate_id=${original?.candidateId}&candidate_name=${original?.firstName} ${original?.lastName}`,
                )
              }}>
              <Avatar
                imageURL={
                  original?.avatarUrl
                    ? original?.avatarUrl
                    : candidateDefaultLogo
                }
                titleClassName='flex w-max line-clamp-1'
                size='xs'
                color='one'
                title={`${original?.firstName} ${original?.lastName}`}
                /** Showing warning and medical notification icons */
                titleIcons={
                  <div className='flex'>
                    {original.isWarning && (
                      <i className='pl-1 ri-error-warning-fill text-Red-500'></i>
                    )}

                    {original.isMedicalNotification && (
                      <i className='pl-1 ri-shield-cross-fill text-Cobalt-500'></i>
                    )}
                  </div>
                }
                tag={isEmailRequired ? original?.email : original.phone}
              />
            </div>

            {guideStepperCandidateId &&
              original?.candidateId == guideStepperCandidateId && (
                <GuideTooltipModal />
              )}
          </div>
        )
      },
    },
    {
      Header: 'SURVEYS SENT',
      accessor: 'totalSurveySent',
      forSorting: 'TotalSurveySent',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[120px] pl-6',
      headerClassName: 'flex items-center justify-start text-left',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <div>{original?.totalSurveySent}</div>
      },
    },
    {
      Header: 'COMPLETED SURVEYS',
      accessor: 'totalSurveyCompleted',
      forSorting: 'TotalSurveyCompleted',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[120px] pl-6',
      headerClassName: 'flex items-center justify-start text-left',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <div>{original?.totalSurveyCompleted}</div>
      },
    },
    {
      Header: 'LAST SURVEY SENT',
      accessor: 'lastSurveySent',
      forSorting: 'LastSurveySent',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[150px] pl-6',
      headerClassName: 'flex items-center justify-start',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        const lastSurveySentDate = timeToAESTWithoutSeconds(
          original?.lastSurveySent,
        )

        return (
          <div className='line-clamp-1' id='referee-requested-on'>
            <span
              data-tip={lastSurveySentDate}
              data-for={`${original?.lastSurveySent} {index}`}>
              {original?.lastSurveySent
                ? dateToReadableString(original?.lastSurveySent)
                : ' - '}
            </span>

            {original?.lastSurveySent && (
              <ReactTooltip
                id={`${original?.lastSurveySent} {index}`}
                place='right'
                effect='solid'
                textColor='#FFFFFF'
                backgroundColor='#4B5563'
                className='rounded-full text-extra-small'
              />
            )}
          </div>
        )
      },
    },
    {
      Header: 'MANAGING CONSULTANT',
      accessor: 'consultantName',
      forSorting: 'ConsultantName',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[167px] pl-6',
      headerClassName: 'flex items-center justify-start text-left',
      cellClassName: 'flex items-center justify-start h-auto',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <div>{original?.consultantName}</div>
      },
    },
  ]

  return COLUMNS
}
