import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  SimpleSelect,
  SimpleSelectOption,
  TextField,
  Toast,
} from '@labourhub/labour-hub-ds'
import {
  CandidateProfileBasicDetailsType,
  useUpdateCandidateReferees,
} from 'api/candidates'
import { useGetQuestionnaireTemplateList } from 'api/questionnaires'
import { setIsNavigatingFromRefCheckFlowModalState } from 'features/questionnaires/store/reducer'
import {
  AppSettingKey,
  AppSettingProps,
  getAgencyAppSettings,
  getSetting,
  getSettingValue,
  INITIAL_CANDIDATE_SETTINGS_PAYLOAD,
  isSettingEnabled,
  updateSettingByKey,
} from 'features/settings'
import { ModalProps } from 'types'

import { Modal, ModalFooter } from 'components/molecules'

import {
  RefereeTypeAccordionContentType,
  RequestSpecificRefereeForm,
} from '../AddEditCandidateModals/RequestSpecificRefereeForm'

type RequestRefereeCommonModalProps = ModalProps & {
  candidateDetails: CandidateProfileBasicDetailsType & {
    referenceTypes?: Array<RefereeTypeAccordionContentType>
  }
  getCandidateById: any
  modalDetails: {
    title: string
    description: string
    type: 'automated' | 'request'
  }
}

export const RequestRefereeCommonModal = ({
  isModalActive,
  setIsModalActive,
  getCandidateById,
  candidateDetails,
  modalDetails: { title, description: subTitle, type },
}: RequestRefereeCommonModalProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // Update candidate referees API
  const { mutate: updateCandidateReferees, isLoading } =
    useUpdateCandidateReferees()

  const [referenceTypes, setReferenceTypes] = useState<
    Array<RefereeTypeAccordionContentType>
  >([])

  const [noOfReferences, setNoOfReferences] = useState('')

  /** Template options setup and fetching candidate and agency details */

  const agencyAppSettings = useSelector(getAgencyAppSettings)

  const [candidateSettings, setCandidateSettings] = useState<AppSettingProps[]>(
    INITIAL_CANDIDATE_SETTINGS_PAYLOAD,
  )

  useEffect(() => {
    setCandidateSettings(agencyAppSettings)
  }, [agencyAppSettings])

  const [selectedTemplate, setSelectedTemplate] = useState({
    value: 'Blank',
    label: 'Blank',
  })

  // Fetching Questionnaire list
  const { data: questionnaireTemplates } = useGetQuestionnaireTemplateList(
    type === 'automated',
  )

  useEffect(() => {
    const template = questionnaireTemplates?.find(
      (q) =>
        q.id ===
        getSettingValue(
          AppSettingKey.QuestionnaireTemplateId,
          candidateSettings,
        ),
    )

    if (template) {
      setSelectedTemplate({
        value: template.id,
        label: template.name,
      })
    }
  }, [candidateSettings, questionnaireTemplates])

  const templateOptions = useMemo(
    () => [
      { value: 'Blank', label: 'Blank' },
      ...(questionnaireTemplates?.map((template) => ({
        value: template?.id,
        label: template?.name,
      })) || []),
    ],
    [questionnaireTemplates],
  )

  const handleTemplateChange = (template: SimpleSelectOption) => {
    const updatedSettings = updateSettingByKey(
      AppSettingKey.QuestionnaireTemplateId,
      candidateSettings,
      {
        value: template.value,
      },
    )
    setCandidateSettings(updatedSettings)
    setSelectedTemplate(template)
  }

  /** request referees */
  const requestReferees = () => {
    const template = getSetting(
      AppSettingKey.QuestionnaireTemplateId,
      candidateSettings,
    )
    if (type === 'automated' && template && template.value === 'Blank')
      return Toast({
        alertHeader: 'Error!',
        alertBody: 'Template cannot be Blank',
        status: 'Error',
      })

    const payload = {
      id: candidateDetails.candidateId,
      referenceDetails: {
        referenceTypes: referenceTypes.map((ref) => ({
          ...ref,
          isSubmitted: false,
        })),
        referenceCount: Number(noOfReferences),
        templateId: type !== 'automated' ? '' : template!.value!,
      },
      isAutoReferenceEnabled: type === 'automated',
    }

    updateCandidateReferees(payload, {
      onSuccess: ({ data }: any) => {
        Toast({
          alertHeader: data.message,
          alertBody: '',
          status: 'Success',
        })
        getCandidateById(candidateDetails.candidateId)
        setIsModalActive(false)
      },
      onError: ({ response: { data: errData } }: any) => {
        Toast({
          alertHeader: 'Error...!',
          alertBody: errData?.message,
          status: 'Error',
        })
      },
    })
  }

  const closeModal = () => {
    setIsModalActive(false)
    // setNoOfReferences('')
    setReferenceTypes([])
  }

  useEffect(() => {
    if (
      candidateDetails.refereeCount !== 0 &&
      candidateDetails.referenceTypes &&
      candidateDetails.referenceTypes.length === 0
    ) {
      setNoOfReferences(String(candidateDetails.refereeCount))
    }
  }, [candidateDetails.referenceTypes])

  return (
    <Modal
      subHeaderTitle={subTitle}
      headerTitle={title}
      isFooterShow={true}
      size='md'
      isActive={isModalActive}
      onHeaderCloseButtonClick={() => closeModal()}
      onOverlayClick={() => closeModal()}
      onClickPrimaryBtn={() => {
        requestReferees()
      }}
      onClickSecondaryBtn={() => closeModal()}
      modalProps={{
        style: { maxHeight: '95vh' },
      }}>
      <div className='flex flex-col w-full h-[650px] overflow-y-auto p-4 px-6 bg-Gray-50 styled-scroll'>
        {type === 'automated' && (
          <div className='p-3 bg-white rounded-md'>
            <div>
              <span>Select the template you want to send to the referees.</span>
              <SimpleSelect
                label='Choose Template'
                className='mt-1'
                options={templateOptions}
                value={selectedTemplate}
                onChange={handleTemplateChange}
                isRequired={isSettingEnabled(
                  AppSettingKey.AutoReferencing,
                  candidateSettings,
                )}
              />
              <Button
                className='mt-2'
                theme='link'
                textTheme='black'
                onClick={() => {
                  dispatch(setIsNavigatingFromRefCheckFlowModalState(true))
                  navigate(`/questionnaires?tab=1`)
                }}>
                <span className='font-Bold text-Cobalt-500 underline mr-1'>
                  Click here
                </span>
                to build a new template
              </Button>
            </div>
          </div>
        )}
        <TextField
          label='No of References (Max 5)'
          isRequired
          className='mt-5'
          type='number'
          value={noOfReferences}
          onChange={(e: any) => {
            if (e.target.value > 0 && e.target.value <= 5) {
              setNoOfReferences(e.target.value)
            }
          }}
          placeholder=''
        />
        <div className='-mx-4'>
          <RequestSpecificRefereeForm
            refereeCount={noOfReferences}
            setReferenceTypes={setReferenceTypes}
          />
        </div>
      </div>
      <ModalFooter className='border-white border-t-0 shadow-none'>
        <div className='flex flex-col flex-1 gap-y-2'>
          <Button
            isDisabled={!noOfReferences || isLoading}
            isLoading={isLoading}
            onClick={() => requestReferees()}
            theme='cobalt'>
            Next
          </Button>
          <Button
            isDisabled={isLoading}
            onClick={() => closeModal()}
            textTheme='blue'
            theme='white'>
            Cancel
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}
