import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Button } from '@labourhub/labour-hub-ds'
import thankyouCheckMark from 'assets/animations/thankyou-check-mark.json'
import {
  setGuideStepperTitle,
  setShowGuideModal,
  setShowGuideTooltipModal,
} from 'features/onboarding/store'
import { useLottie } from 'lottie-react'
import { setIsExpanded } from 'store/reducers/side-menu/mainSideMenuReducer'

import { ColumnWithAlignment } from 'components/atoms/Column'

export const Congratulations = () => {
  const dispatch = useDispatch()

  const AnimatedView = () => {
    const options = {
      animationData: thankyouCheckMark,
      loop: false,
      autoplay: true,
    }

    const { View } = useLottie(options)
    return View
  }

  useEffect(() => {
    dispatch(setGuideStepperTitle(undefined))
  }, [])

  return (
    <div className='w-full'>
      <ColumnWithAlignment justifyAlignment='center'>
        <div className='w-full p-5'>
          <ColumnWithAlignment justifyAlignment='center' itemAlignment='center'>
            <div className='w-[225px]'>
              <AnimatedView />
            </div>
            <span className='my-5 font-SemiBold text-Cobalt-600 text-heading-3'>
              Congratulations!
            </span>
            <span className='mx-12 my-5 text-center font-Regular text-Gray-400 text-small'>
              You've successfully initiated your first reference check. With our
              efficient reference checking process, you can expect timely
              feedback from the referees.
            </span>
          </ColumnWithAlignment>
        </div>
      </ColumnWithAlignment>
      <div className='flex flex-col justify-center px-5 mt-5'>
        <Button
          className='mt-5 cursor-pointer'
          onClick={() => {
            dispatch(setIsExpanded(false))
            dispatch(setShowGuideTooltipModal(true))
            dispatch(setShowGuideModal(false))
          }}>
          Explore Candidate Profile
        </Button>
      </div>
    </div>
  )
}
