import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, Toast, ToggleDetailSection } from '@labourhub/labour-hub-ds'
import { useUpdateOnboardingStepper } from 'api/onboarding'
import { companySettingsTwo } from 'features/onboarding/data'
import { setCurrentStep, setShowGuideModal } from 'features/onboarding/store'
import { getOnboardingStore } from 'features/onboarding/store/selectors'

import PoliceCheckLayout from 'components/layouts/PoliceCheckLayout/PoliceCheckLayout'

export const RefHubSettingsSectionTwo = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { companySettingsTwo: companySettingsTwoObj } =
    useSelector(getOnboardingStore)

  const [autoReferencing, setAutoReferencing] = useState(true)
  const [isCandidatePhotoUpload, setIsCandidatePhotoUploadRequired] =
    useState(false)
  const [isACICRegLink, setIsACICRegLink] = useState(false)

  useEffect(() => {
    if (companySettingsTwoObj) {
      setAutoReferencing(companySettingsTwoObj?.isAutoReferencingRequired)
      setIsCandidatePhotoUploadRequired(
        companySettingsTwoObj?.isCandidateProfileImageRequired,
      )
      setIsACICRegLink(companySettingsTwoObj?.isACICRegLinkRequired)
    }
  }, [companySettingsTwoObj])

  const handleOnboadingCloseSession = () => {
    const onboardingToken = sessionStorage.getItem('onboardingToken')
    if (onboardingToken) {
      sessionStorage.clear()
      localStorage.setItem('token', onboardingToken)
      localStorage.setItem('rememberMe', 'false')
      localStorage.setItem('isChecklist', 'true')
      navigate('/')
      dispatch(setCurrentStep(1))
      dispatch(setShowGuideModal(true))
    }
  }

  /** APi call for the onboarding stepper */
  const {
    mutate: companyOnboardingMutate,
    isLoading: companyOnboardingIsLoading,
  } = useUpdateOnboardingStepper()

  const onFinishClick = () => {
    companyOnboardingMutate(
      {
        onboardingStatus: 'Completed',
        companySettingsTwo: {
          id: companySettingsTwo,
          isAutoReferencingRequired: autoReferencing,
          isCandidateProfileImageRequired: isCandidatePhotoUpload,
          isACICRegLinkRequired: isACICRegLink,
        },
      },
      {
        onSuccess: () => {
          handleOnboadingCloseSession()
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: errData?.messageTitle
              ? errData?.messageTitle
              : 'Error!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  return (
    <div className='flex flex-col items-center justify-center w-7/12'>
      <div className='w-full mt-5 text-left text-heading-2 font-Bold text-Gray-800'>
        Ref Hub Settings
      </div>

      <div className='flex flex-col justify-start w-full'>
        <span className='mt-6 text-Gray-800 font-SemiBold'>
          Auto Referencing
        </span>

        <ToggleDetailSection
          id='auto-referencing'
          label='Turn on Auto-referencing By Default'
          description='Once candidates are added, the system promptly requests referee details and automatically sends out reference surveys upon receiving the required information. This can be toggled off for individual candidates.'
          value={autoReferencing}
          onClick={(e: any) => setAutoReferencing(e.target.checked)}
        />

        <span className='mt-6 text-Gray-800 font-SemiBold'>
          Candidate Photo
        </span>

        <ToggleDetailSection
          id='candidate-photo'
          label='Mandatory Candidate photo upload '
          description='Candidates can upload their photo when submitting their reference details. This helps referees recognize the candidate, increasing submission rates. This toggle will make the candidate photo mandatory.'
          value={isCandidatePhotoUpload}
          onClick={(e: any) =>
            setIsCandidatePhotoUploadRequired(e.target.checked)
          }
        />
        <PoliceCheckLayout>
          <span className='mt-6 text-Gray-800 font-SemiBold'>
            Police Check Registration
          </span>

          <ToggleDetailSection
            id='police-check-registration'
            label='Request ACIC Registration Link'
            description='Interested in conducting police checks through Ref Hub at a discounted rate? Request the ACIC Registration link. Please note, registration with ACIC can take between 7 to 14 days.'
            value={isACICRegLink}
            onClick={(e: any) => setIsACICRegLink(e.target.checked)}
          />
        </PoliceCheckLayout>
      </div>

      {/* next button */}
      <Button
        isBlock
        isLoading={companyOnboardingIsLoading}
        onClick={onFinishClick}
        className='mt-6 next-btn'>
        Finish
      </Button>
    </div>
  )
}
