/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState } from 'react'
import CN from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'

export const SpecificRefereeAccordion = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState<any>(undefined)

  const handleClick = (index: number, item: any) => {
    // If the clicked section is already open, close it. Otherwise, open it.
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index))
  }

  return (
    <div className='accordion w-full'>
      {items.map((item: any, index: number) => (
        <div key={index} className='pb-4'>
          <div
            onClick={() => handleClick(index, item)}
            className={CN(
              'w-full flex justify-between items-center py-2 border-b font-Medium text-[16px] cursor-pointer bg-gray-600 border-Gray-200 text-Gray-800',
            )}>
            <div className='pl-2 flex items-center'>
              <div
                className={CN('pl-2', {
                  'text-Cobalt-500': item?.title === 'Candidate Information',
                })}>
                {item?.title}
              </div>
            </div>

            <div className='w-fit mr-5 text-heading-3 text-right bg-Cobalt-20'>
              <i
                className={CN('text-[20px] text-Gray-800', {
                  'ri-arrow-down-s-line': activeIndex !== index,
                  'ri-arrow-up-s-line': activeIndex === index,
                })}
              />
            </div>
          </div>

          <AnimatePresence>
            {activeIndex === index && (
              <motion.div
                initial={{ height: 0 }}
                animate={{
                  height: 'auto',
                }}
                exit={{ height: 0, opacity: -2 }}>
                <div className='py-4'>{item.content}</div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      ))}
    </div>
  )
}
