import { Doughnut } from 'react-chartjs-2'
import { ArcElement, Chart } from 'chart.js'

Chart.register(ArcElement)

export default function DoughnutChart({
  icon,
  heading,
  value,
}: {
  icon: string
  heading: string
  value: number
}) {
  // const icon = 'ri-emotion-unhappy-line'
  // const heading = 'Neuroticism'
  // const pvalue = 20
  const data = [
    {
      value,
      color: 'rgba(113, 176, 235, 1)',
      cutout: '60%',
    },
    {
      value: 100 - value,
      color: 'rgba(249, 250, 251, 1)',
      cutout: '60%',
    },
  ]

  const options: any = {
    rotation: -90, // Start angle of the chart (90 degrees rotated upwards)
    circumference: 180,
    responsive: false,
    // maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: false,
      },
    },
    cutout: data.map((item) => item.cutout),
  }

  const finalData = {
    datasets: [
      {
        data: data.map((item) => Math.round(item.value)),
        backgroundColor: data.map((item) => item.color),
        borderColor: data.map((item) => item.color),
        borderWidth: 1,
        dataVisibility: new Array(data.length).fill(true),
      },
    ],
  }

  const plugins = [
    {
      id: 'textInsideDoughnut',
      afterDatasetsDraw(chart) {
        const { ctx } = chart
        ctx.save()
        ctx.font = 'bold 20px Arial'
        ctx.fillStyle = '#2E2E2E'
        ctx.textAlign = 'center'
        ctx.textBaseline = 'middle'

        // Calculate the center position of the doughnut
        const centerX = (chart.chartArea.left + chart.chartArea.right) / 2
        // const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2
        const centerY = chart.chartArea.bottom - 10
        ctx.fillText(`${value}%`, centerX, centerY)
        ctx.restore()
      },
    },
  ]

  return (
    <div className='bg-white w-[235px] h-[247px] rounded-md break-inside-avoid'>
      <h5 className='flex mt-5 px-5 mx-auto mb-4 h-[48px] text-base items-center font-SemiBold text-[#2E2E2E]'>
        <span className='flex justify-center items-center w-[48px] h-[48px] bg-Cobalt-50 mr-[10px]'>
          <i
            className={`${icon} text-Cobalt-500 text-[22px] leading-[22px]`}></i>
        </span>
        {heading}
      </h5>
      <div className='w-fit h-fit mx-auto relative'>
        <Doughnut
          width={180}
          plugins={plugins}
          height={90}
          data={finalData}
          options={options}
        />
      </div>
      {/* <h5 className='text-Cobalt-500 bg-Cobalt-50 px-4 pt-2 my-10 text-base w-fit mx-auto'>
        AVG-{average}%
      </h5> */}
    </div>
  )
}
