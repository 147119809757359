import { AddCandidate } from '../components/OnboardingViewModal/sections/AddCandidate'
import { ChooseReferenceCheckTemplate } from '../components/OnboardingViewModal/sections/ChooseReferenceCheckTemplate'
import { ConfirmBranding } from '../components/OnboardingViewModal/sections/ConfirmBranding/ConfirmBranding'
import { Congratulations } from '../components/OnboardingViewModal/sections/Congratulations'
import { GuideStepperIntro } from '../components/OnboardingViewModal/sections/GuideStepperIntro'
import { InitiateReferenceCheck } from '../components/OnboardingViewModal/sections/InitiateReferenceCheck'
import { PreviewReferenceCheckTemplate } from '../components/OnboardingViewModal/sections/PreviewReferenceCheckTemplate'
import { GuideStepperComponent } from '../types'

export const guideStepperComponents: GuideStepperComponent[] = [
  {
    orderNo: 1,
    component: <GuideStepperIntro />,
  },
  {
    orderNo: 2,
    title: 'Confirm Branding',
    component: <ConfirmBranding />,
    // stepperButton: {
    //   enableDot: true,
    //   enableSkipButton: true,
    // },
  },
  {
    orderNo: 3,
    title: 'What are you recruiting for? ',
    hasSubTitle: true,
    component: <ChooseReferenceCheckTemplate />,
  },
  {
    orderNo: 4,
    title: 'Preview of Template',
    component: <PreviewReferenceCheckTemplate />,
  },
  {
    orderNo: 5,
    title: 'Start your first reference check',
    component: <AddCandidate />,
  },
  {
    orderNo: 6,
    title: 'Initiate reference check',
    component: <InitiateReferenceCheck />,
    stepperButton: {
      enableBackButton: false,
      enableDot: true,
      enableSkipButton: true,
    },
  },
  {
    orderNo: 7,
    title: 'Finish',
    component: <Congratulations />,
  },
]
