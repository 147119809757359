import { useMutation } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { deleteAPI } from 'framework/api/http'

export const useDeleteCandidateReferee = (
  candidateId: string,
  refereeId: string,
  refereeIndex: number,
) => {
  return useMutation(
    () => {
      return deleteAPI(`/Candidate/${candidateId}/Referee/${refereeId}`)
    },
    {
      onSuccess: () => {
        Toast({
          alertHeader: `Referee ${refereeIndex} deleted successfully`,
          status: 'Success',
        })
      },
      onError: () => {
        Toast({
          alertHeader: `Referee ${refereeIndex} deletion failed`,
          status: 'Error',
        })
      },
    },
  )
}

export default useDeleteCandidateReferee
