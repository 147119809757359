import { useEffect, useState } from 'react'

export type BrowserInfo = {
  isChrome: boolean
  isFirefox: boolean
  isSafari: boolean
  isEdge: boolean
  isOpera: boolean
}

export const useBrowserDetection = (): BrowserInfo => {
  const [browserInfo, setBrowserInfo] = useState<BrowserInfo>({
    isChrome: false,
    isFirefox: false,
    isSafari: false,
    isEdge: false,
    isOpera: false,
  })

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase()
    setBrowserInfo({
      isChrome:
        userAgent.includes('chrome') &&
        !userAgent.includes('edg') &&
        !userAgent.includes('opr'),
      isFirefox: userAgent.includes('firefox'),
      isSafari: userAgent.includes('safari') && !userAgent.includes('chrome'),
      isEdge: userAgent.includes('edg'),
      isOpera: userAgent.includes('opr'),
    })
  }, [])

  return browserInfo
}
