/** questionnaires status list */
export const Custom = 'Custom'
export const AI = 'AI'

/** backend issue (variable name) :  true false values send through api for get filter result from status filtering.
 *  because of that status list value and label are different */
export const surveyTemplateType = [
  {
    value: 'Custom',
    label: 'Custom',
  },
  {
    value: 'AI',
    label: 'AI',
  },
]
