import { useMutation } from 'react-query'
import { deleteAPI } from 'framework/api/http'

interface DeleteUserProfilePictureInterface {
  Id?: string
}

export const useDeleteUserProfilePicture = () => {
  const mutate = useMutation(({ Id }: DeleteUserProfilePictureInterface) => {
    return deleteAPI(`/UserProfile/${Id}/Avatar`)
  })

  return mutate
}

export default useDeleteUserProfilePicture
