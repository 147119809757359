/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, ReactNode, useEffect, useRef } from 'react'
import { Button } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { motion } from 'framer-motion'

interface RightSideModalProps {
  children?: ReactNode | string | number | undefined
  className?: string | undefined
  footerLeftButtonIcon?: ReactNode | string | undefined
  footerLeftButtonTitle?: string
  footerLeftButton?: ReactNode
  headerSubtitle?: string
  headerTitle?: string
  isActive?: boolean | undefined
  isCustomFooterLeftButton?: boolean
  isCustomStyles?: boolean
  isFooterShow?: boolean
  isHeaderShow?: boolean
  isPrimaryButtonDisable?: boolean
  onFooterAddButtonClick?: () => void | undefined
  onFooterCancelButtonClick?: () => void | undefined
  onFooterLeftButtonClick?: () => void | undefined
  onHeaderCloseButtonClick?: () => void | undefined
  onOverlayClick?: () => void | undefined
  primaryButtonTitle?: string
  secondaryButtonTitle?: string
  width?: string | number | undefined
  [x: string]: any
}

export const RightSideModal: FC<RightSideModalProps> = ({
  children,
  className,
  footerLeftButtonIcon,
  footerLeftButtonTitle,
  footerLeftButton,
  headerSubtitle,
  headerTitle,
  isActive,
  isCustomFooterLeftButton,
  isCustomStyles,
  isFooterShow,
  isHeaderShow,
  isPrimaryButtonDisable,
  onFooterAddButtonClick,
  onFooterCancelButtonClick,
  onFooterLeftButtonClick,
  onHeaderCloseButtonClick,
  onOverlayClick,
  primaryButtonTitle,
  secondaryButtonTitle,
  width,
  ...restProps
}: RightSideModalProps) => {
  const containerRef = useRef<HTMLDivElement>(null)

  /** Overlay styles */
  const OverlayClassName = !isCustomStyles
    ? CN(
        'modal-overlay fixed top-0 bottom-0 left-0 right-0 z-50 flex justify-end bg-black bg-opacity-20',
        className,
        {
          hidden: !isActive,
        },
      )
    : className

  /** main modal container styles */
  const ModalContainerClassName = CN(
    'modal-container flex flex-col justify-start items-center bg-white shadow-lg rounded-sm h-full',
  )
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0
    }
  }, [])

  return (
    <div
      className={OverlayClassName}
      onClick={(e: any) => {
        e.stopPropagation()
        e.preventDefault()
        onOverlayClick && onOverlayClick()
      }}
      {...restProps.overlayProps}>
      {/* Modal main container  */}

      <motion.div
        animate={isActive ? 'open' : 'closed'}
        variants={{
          open: { opacity: 1, x: '0', transition: { duration: 0.1 } },
          closed: { opacity: 1, x: '100%' },
        }}
        className={ModalContainerClassName}
        style={{ width: width }}
        {...restProps.modalProps}
        onClick={(e: any) => e.stopPropagation()}>
        {isHeaderShow && (
          /* Modal Header  */
          <div
            className='flex w-full justify-between border-b-[1px] border-Gray-200 py-5 px-6'
            {...restProps.headerProps}>
            <div className='flex flex-col items-start'>
              <span className='header-title text-heading-4 font-Medium text-Gray-800 line-clamp-1'>
                {headerTitle}
              </span>

              <span className='header-subtitle text-small font-Regular text-Gray-600'>
                {headerSubtitle}
              </span>
            </div>

            <div className='flex items-center justify-center header-close-button'>
              <Button
                icon={<i className='ri-close-fill'></i>}
                isIconOnly
                onClick={() => {
                  onHeaderCloseButtonClick && onHeaderCloseButtonClick()
                }}
                textTheme='blue'
                theme='white'></Button>
            </div>
          </div>
        )}

        {/* Modal body */}
        <div
          className='flex w-full h-full max-h-full overflow-y-auto styled-scroll'
          ref={containerRef}>
          {children}
        </div>

        {/* Modal footer */}
        {isFooterShow && (
          <>
            <div
              //className='flex justify-end items-center w-full border-t-[1px] border-Gray-200 py-5 pr-6 gap-3'
              className={CN(
                'flex justify-between items-center w-full border-t-[1px] border-Gray-200 py-5 pr-6 gap-3',
                {
                  'justify-between': isCustomFooterLeftButton,
                  'justify-end': !isCustomFooterLeftButton,
                },
              )}
              {...restProps.footerProps}>
              {isCustomFooterLeftButton && (
                <div className='pl-6'>
                  <Button
                    onClick={() => {
                      onFooterLeftButtonClick && onFooterLeftButtonClick()
                    }}
                    textTheme='terracotta'
                    theme='white'
                    iconLeft={footerLeftButtonIcon}
                    {...restProps.footerCustomButtonProps}>
                    {footerLeftButtonTitle}
                  </Button>
                </div>
              )}
              <div className='pl-6'>{footerLeftButton}</div>
              <div className='flex gap-3'>
                {secondaryButtonTitle !== '' && (
                  /*footer secondary button*/
                  <Button
                    onClick={() => {
                      onFooterCancelButtonClick && onFooterCancelButtonClick()
                    }}
                    textTheme='blue'
                    theme='white'>
                    {secondaryButtonTitle}
                  </Button>
                )}

                {primaryButtonTitle !== '' && (
                  /*footer primary button*/
                  <Button
                    onClick={() => {
                      onFooterAddButtonClick && onFooterAddButtonClick()
                    }}
                    // textTheme='white'
                    // theme='gradient'
                    isDisabled={isPrimaryButtonDisable}
                    {...restProps.primaryButtonProps}>
                    {primaryButtonTitle}
                  </Button>
                )}
              </div>
            </div>
          </>
        )}
      </motion.div>
    </div>
  )
}

export default RightSideModal

RightSideModal.defaultProps = {
  children: undefined,
  className: undefined,
  footerLeftButtonIcon: undefined,
  footerLeftButtonTitle: 'Delete',
  footerLeftButton: undefined,
  headerSubtitle: 'Here you can search and add internal users.',
  headerTitle: 'Add users',
  isActive: true,
  isCustomFooterLeftButton: false,
  isCustomStyles: false,
  isFooterShow: true,
  isHeaderShow: true,
  isPrimaryButtonDisable: true,
  onFooterAddButtonClick: undefined,
  onFooterCancelButtonClick: undefined,
  onFooterLeftButtonClick: undefined,
  onHeaderCloseButtonClick: undefined,
  onOverlayClick: undefined,
  primaryButtonTitle: 'Submit',
  secondaryButtonTitle: 'Cancel',
  width: 500,
}
