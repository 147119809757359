import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Toast } from '@labourhub/labour-hub-ds'
import { useCreateCandidateAvatar } from 'api/candidates'
import { setIsHeaderBarDetailsUpdated } from 'store/reducers/user/userSlice'

import { ProfileAvatar } from 'components/atoms'
import { CustomizableEditProfilePictureModal } from 'components/molecules'

import { DeleteProfilePictureModal } from './DeleteProfilePictureModal'

type CandidateAvatarSectionProps = {
  candidateId: string
  avatarUrl?: string
  getCandidateById: any
}

export const CandidateAvatarSection = ({
  candidateId,
  avatarUrl,
  getCandidateById,
}: CandidateAvatarSectionProps) => {
  const notify = (props: any) => Toast(props)
  const dispatch = useDispatch()

  const [files, setFiles] = useState<any>()
  const [imageUrl, setImageUrl] = useState<any>('')
  const [showEditProfilePictureModal, setShowEditProfilePictureModal] =
    useState(false)
  const [showDeleteProfilePictureModal, setShowDeleteProfilePictureModal] =
    useState(false)
  const { userDetails, isHeaderBarDetailsUpdated }: any = useSelector<any>(
    (state) => state.user,
  )

  /** API Call for upload profile picture using react-query */
  const handleUploadProfileAvatar = (files: any) => {
    UploadProfilePicture(userDetails.id, files)
  }

  /** upload profile picture api */
  const {
    mutate: UploadProfilePictureMutate,
    isLoading: uploadProfilePictureIsLoading,
  } = useCreateCandidateAvatar()

  /** Process the upload profile picture */
  async function UploadProfilePicture(Id: any, avatarObject: any) {
    UploadProfilePictureMutate(
      {
        Id: candidateId,
        avatarObject,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData.messageTitle
              ? successData.messageTitle
              : 'Profile photo updated!',
            alertBody: successData?.message
              ? successData.message
              : `We've uploaded your profile photo. It may take a few minutes to display everywhere.`,
            status: 'Success',
          })
          setShowEditProfilePictureModal(false)

          /** updated header bar details */
          dispatch(setIsHeaderBarDetailsUpdated(!isHeaderBarDetailsUpdated))
          getCandidateById()
        },
        onError: ({ response: { data: errData } }: any) => {
          setShowEditProfilePictureModal(false)
          notify({
            alertHeader: errData?.messageTitle
              ? errData?.messageTitle
              : 'Error!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  return (
    <>
      <div className='w-[132px]'>
        <ProfileAvatar
          isProfilePictureAvailable={avatarUrl ? true : false}
          imageSource={avatarUrl || undefined}
          imageContainerClassName='w-[132px] h-[132px]'
          onAddClick={() => setShowEditProfilePictureModal(true)}
          onDeleteClick={() => setShowDeleteProfilePictureModal(true)}
          onEditClick={() => setShowEditProfilePictureModal(true)}
          isStatusDisable={false}
        />
      </div>

      <DeleteProfilePictureModal
        isModalActive={showDeleteProfilePictureModal}
        setIsModalActive={setShowDeleteProfilePictureModal}
        candidateId={candidateId}
        getCandidateById={getCandidateById}
      />

      <CustomizableEditProfilePictureModal
        isActive={showEditProfilePictureModal}
        onHeaderCloseButtonClick={() => {
          setShowEditProfilePictureModal(false)
        }}
        onClickSecondaryBtn={() => {
          setShowEditProfilePictureModal(false)
        }}
        onOverlayClick={() => {
          setShowEditProfilePictureModal(false)
        }}
        onClickPrimaryBtn={() => {
          handleUploadProfileAvatar(files)
        }}
        imageUrl={imageUrl}
        setImageUrl={setImageUrl}
        setFiles={setFiles}
        headerTitle={avatarUrl ? 'Change profile photo' : 'Add profile photo'}
        headerSubTitle='Update your profile photo here.'
        primaryButtonIsLoading={uploadProfilePictureIsLoading}
      />
    </>
  )
}
