import { useEffect, useState } from 'react'
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from 'react-query'
import { Button } from '@labourhub/labour-hub-ds'
import { ModalProps } from 'types'

import { Modal, ModalFooter } from 'components/molecules'
import Characters from 'components/molecules/AddonPreviewModal/TypedTestResultReport/Characters'
import InputHistory from 'components/molecules/AddonPreviewModal/TypedTestResultReport/InputHistory'
import ResultChart from 'components/molecules/AddonPreviewModal/TypedTestResultReport/ResultChart'
import TestStats from 'components/molecules/AddonPreviewModal/TypedTestResultReport/TestStats'
import { AssessmentDownloadModal } from 'components/molecules/AssesmentPreviewModal/DownloadReport/AssessmentDownloadModal'

export type ChartData = {
  id: number
  time: number
  wpm: number
  raw: number
  error: number | null
}

type TypingTestAddonPreviewReportProps = ModalProps & {
  candidateDetails: any
  templateId: any
  template: any
  responseId: string
  reportViewData: any
  selectedResponse: any
  refreshAssessmentList: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<any, unknown>>
}

export const TypingTestAddonPreviewReport = ({
  isModalActive,
  setIsModalActive,
  template,
  candidateDetails,
  reportViewData,
  selectedResponse,
}: TypingTestAddonPreviewReportProps) => {
  const [chartDataArray, setChartDataArray] = useState<ChartData[]>([])
  const [typedHistory, setTypedHistory] = useState<string[]>([])
  const [wordList, setWordList] = useState<string[]>([])
  const [duration, setDuration] = useState(0)
  const [isOpenTypeTestResultModal, setIsOpenTypeTestResultModal] =
    useState(false)

  const markingValue = {
    correct: 0,
    partiallyCorrect: 0,
    incorrect: 0,
    notAnswered: 0,
  }

  useEffect(() => {
    const data = JSON.parse(template.jsonData)
    if (Object.keys(data).length > 0) {
      setWordList(data.wordList)
      setTypedHistory(data.typedHistory)
      setChartDataArray(data.chartDataArray)
      setDuration(data.chartDataArray.length)
    }
  }, [template.jsonData])

  const handleCloseModals = () => {
    setIsModalActive(false)
    setIsOpenTypeTestResultModal(false)
  }

  return (
    <>
      <Modal
        headerTitle={`Typing Test Lowercase`}
        customWidth='1125px'
        isActive={isModalActive}
        isFooterShow={false}
        size='lg'
        onClickSecondaryBtn={handleCloseModals}
        onHeaderCloseButtonClick={handleCloseModals}
        onOverlayClick={handleCloseModals}
        secondaryButtonTitle='Close'>
        <div className='w-full p-6 styled-scroll overflow-y-auto'>
          <ResultChart chartDataArray={chartDataArray} />
          <TestStats
            chartDataArray={chartDataArray}
            duration={duration}
            typedHistory={typedHistory}
            wordList={wordList}
          />
          <Characters typedHistory={typedHistory} wordList={wordList} />
          <InputHistory typedHistory={typedHistory} wordList={wordList} />
        </div>

        <ModalFooter>
          <div className='flex justify-end flex-1'>
            <Button onClick={handleCloseModals} textTheme='blue' theme='white'>
              Cancel
            </Button>
            <Button
              onClick={() => setIsOpenTypeTestResultModal(true)}
              className='ml-2'
              theme='cobalt'>
              Download
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      {/* {isOpenTypeTestResultModal && (
        <TypeTestResultModal
          isModalActive={isOpenTypeTestResultModal}
          setIsModalActive={setIsOpenTypeTestResultModal}
          reportViewData={reportViewData}
          candidateDetails={candidateDetails}
          handleCloseModals={handleCloseModals}
        />
      )} */}
      {isOpenTypeTestResultModal && (
        <AssessmentDownloadModal
          markingValue={markingValue}
          reportViewData={reportViewData}
          isModalActive={isOpenTypeTestResultModal}
          setIsModalActive={setIsOpenTypeTestResultModal}
          handleCloseModals={handleCloseModals}
          candidateDetails={candidateDetails}
          isLoading={false}
          selectedResponse={selectedResponse}
        />
      )}
    </>
  )
}
