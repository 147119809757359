/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { AppSettingProps, ReportSettingsForm } from '../types'

import {
  setAgencyAppSettingsState,
  setAssessmentReportSettingsState,
  setCandidateAppSettingsState,
  setReportSettingsState,
} from './reducer'

export const setAgencyAppSettings =
  (value: AppSettingProps[], cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setAgencyAppSettingsState(value))
  }

export const setCandidateAppSettings =
  (value: AppSettingProps[], cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setCandidateAppSettingsState(value))
  }

export const setReportSettings =
  (value: ReportSettingsForm, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setReportSettingsState(value))
  }

export const setAssessmentReportSettings =
  (value: ReportSettingsForm, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setAssessmentReportSettingsState(value))
  }
