import CN from 'classnames'

type HeadingProps = {
  title: string
  fontWeight?: string
  className?: string
}

export const H1 = ({ title, fontWeight = 'font-Bold' }: HeadingProps) => {
  return (
    <h1 className={CN('pt-1 text-heading-1 text-Gray-800', fontWeight)}>
      {title}
    </h1>
  )
}

export const H2 = ({ title, fontWeight = 'font-Bold' }: HeadingProps) => {
  return (
    <h2 className={CN('pt-2 text-heading-2 text-Gray-800', fontWeight)}>
      {title}
    </h2>
  )
}

export const H3 = ({ title, fontWeight = 'font-Bold' }: HeadingProps) => {
  return (
    <h3 className={CN('pt-3 pb-1 text-heading-3 text-Gray-800', fontWeight)}>
      {title}
    </h3>
  )
}

export const H4 = ({
  title,
  fontWeight = 'font-Bold',
  className,
}: HeadingProps) => {
  return (
    <h4
      className={CN(
        'pt-4 pb-2 text-heading-4 text-Gray-800',
        fontWeight,
        className,
      )}>
      {title}
    </h4>
  )
}

export const H5 = ({
  title,
  fontWeight = 'font-Bold',
  className,
}: HeadingProps) => {
  return (
    <h4
      className={CN(
        'pb-1.5 text-heading-5 text-Gray-800',
        fontWeight,
        className,
      )}>
      {title}
    </h4>
  )
}

export const H6 = ({
  title,
  fontWeight = 'font-Bold',
  className,
}: HeadingProps) => {
  return (
    <h4 className={CN('pb-1.5  text-Gray-800', fontWeight, className)}>
      {title}
    </h4>
  )
}
