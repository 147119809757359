import { useEffect, useState } from 'react'
import { Checkbox } from '@labourhub/labour-hub-ds'
import { AssessmentResponseDto } from 'api/assessment/getAssessmentResponsesList'
import addon from 'assets/images/addon.svg'
import { difficultyImages } from 'features/candidates/data/difficultyLevelImages'

import { cn } from 'utils'

type AssessmentSelectionListProps = {
  assessmentResponse?: AssessmentResponseDto[]
  setSelectedAssessment: React.Dispatch<
    React.SetStateAction<AssessmentResponseDto[]>
  >
}

export const AssessmentSelectionList = ({
  assessmentResponse,
  setSelectedAssessment,
}: AssessmentSelectionListProps) => {
  const [checkedState, setCheckedState] = useState(
    new Array(assessmentResponse?.length || 0).fill(false),
  )

  const handleCheckboxChange = (idx: number) => {
    const newCheckedState = [...checkedState]
    newCheckedState[idx] = !newCheckedState[idx]
    setCheckedState(newCheckedState)
  }

  useEffect(() => {
    const selectedAssessment = assessmentResponse
      ? assessmentResponse.filter(
          (_referee: any, idx: number) => checkedState[idx],
        )
      : []
    setSelectedAssessment(selectedAssessment)
  }, [checkedState, assessmentResponse])

  return (
    <div className='recipients-card-container flex flex-col gap-y-2 max-h-[200px] overflow-y-auto styled-scroll mt-4'>
      {assessmentResponse?.map(
        (assessment: AssessmentResponseDto, idx: number) => (
          <div
            className={cn(
              'flex recipients-card rounded-lg py-2 px-3 mr-2 relative ',
              {
                'bg-white border border-Gray-100': !checkedState[idx],
                'bg-Cobalt-50 border border-Cobalt-100': checkedState[idx],
              },
            )}
            key={idx}>
            <Checkbox
              key={idx}
              id={`custom-checkbox-${idx}`}
              checked={checkedState[idx]}
              onChange={() => handleCheckboxChange(idx)}
              labelText={`${assessment.assessmentName}`}
              labelTextClassName='font-Regular line-clamp-1'
              checkboxClassName='ml-0'
            />

            <div className='flex ml-3 items-center text-extra-small font-Regular text-Gray-500'>
              {assessment.assessmentTemplateType === 'Assessment' ? (
                <img
                  className='w-[15px] relative -top-0.5'
                  src={difficultyImages[assessment.difficultyLevel]}
                  alt={assessment.assessmentTemplateType}
                />
              ) : (
                <img
                  className='w-[15px] relative -top-0.5'
                  src={addon}
                  alt={assessment.assessmentTemplateType}
                />
              )}
            </div>
          </div>
        ),
      )}
    </div>
  )
}
