import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

type CancelAssessmentRequest = {
  assessmentId: string
  cId: string
  responseId: string
}

export const useCancelAssessmentRequest = () => {
  const mutate = useMutation(
    ({ assessmentId, cId, responseId }: CancelAssessmentRequest) => {
      return postAPI(
        `assessment/candidates/${cId}/assessment/${assessmentId}/cancel`,
        { assessmentResponseId: responseId },
      )
    },
  )

  return mutate
}
