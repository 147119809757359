import { useMutation } from 'react-query'
import { ReportTypes } from 'features/settings/data/reportTypes'
import { putAPI } from 'framework/api/http'

import { ReportSettingItem } from './getReportSettings'

export type UpdateReportSettingsRequest = {
  reportType: (typeof ReportTypes)[keyof typeof ReportTypes]
  reportSettings: ReportSettingItem[]
}

export const useUpdateReportSettings = () => {
  const mutate = useMutation(
    ({
      reportSettings,
      reportType = ReportTypes.REFERENCE,
    }: UpdateReportSettingsRequest) => {
      return putAPI(`/reports`, { reportSettings, reportType })
    },
  )

  return mutate
}
