import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Toast } from '@labourhub/labour-hub-ds'
import {
  useCreateAgencyProfileLogo,
  useCreateAgencySecondaryLogo,
  useGetAgencyDetails,
} from 'framework/api/methods'
import { setIsHeaderBarDetailsUpdated } from 'store/reducers/user/userSlice'

import { PageLoader } from 'components/atoms'
import { CustomizableEditProfilePictureModal } from 'components/molecules'

import { AgencyDetailProgressCard } from '../components/AgencyDetailProgressCard'
import { AgencyProfileAboutCard } from '../components/AgencyProfileAboutCard'
import { AgencyProfileCard } from '../components/AgencyProfileCard'
import { EditAgencyProfileInfoModal } from '../components/EditAgencyProfileInfoModal'
import { setAgency } from '../store/actions'

type AgencyProfilePageProps = {
  [x: string]: any
}

export const AgencyProfilePage = ({ ...restProps }: AgencyProfilePageProps) => {
  const notify = (props: any) => Toast(props)
  const dispatch = useDispatch()
  /** profile picture states */
  const { isHeaderBarDetailsUpdated, headerBarDetails }: any = useSelector<any>(
    (state) => state.user,
  )

  const [files, setFiles] = useState<any>()
  const [imageUrl, setImageUrl] = useState<any>('')
  const [isSecondaryLogoModalOpen, setIsSecondaryLogoModalOpen] = useState(true)
  const [showChangeAgencyProfileModal, setShowChangeAgencyProfileModal] =
    useState(false)

  /** agency profile info states */
  const [showEditAgencyBasicInfoDrawer, setShowEditAgencyBasicInfoDrawer] =
    useState(false)

  const [agencyProfileDetails, setAgencyProfileDetails] = useState<any>({})

  /** Get agency profile details on success */
  const getAgencyDetailsOnSuccess = ({ data: successData }: any) => {
    setAgencyProfileDetails(successData?.agencyProfile)
    dispatch(setAgency(successData?.agencyProfile))
  }

  /** Get agency profile details on error */
  const getAgencyDetailsOnError = ({ response: { data: errData } }: any) => {
    notify({
      alertHeader: errData.messageTitle ? errData.messageTitle : '',
      alertBody: errData.message ? errData.message : 'Error!',
      status: 'Error',
    })
  }

  /** API call for getting agency profile details */
  const {
    refetch: getAgencyProfileDetailsRefetch,
    isFetching: getAgencyProfileDetailsIsFetching,
  } = useGetAgencyDetails(
    {},
    getAgencyDetailsOnSuccess,
    getAgencyDetailsOnError,
  )

  useEffect(() => {
    getAgencyProfileDetailsRefetch()
  }, [])

  /** upload agency profile logo api */
  const {
    mutate: UploadAgencyProfileLogoMutate,
    isLoading: uploadAgencyProfileLogoIsLoading,
  } = useCreateAgencyProfileLogo()

  /** Process the upload profile picture */
  async function uploadAgencyProfileLogo(Id: any, logoObject: any) {
    UploadAgencyProfileLogoMutate(
      {
        Id,
        logoObject,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData.messageTitle
              ? successData.messageTitle
              : 'Primary company logo uploaded successfully.',
            alertBody: '',
            status: 'Success',
          })
          setImageUrl('')
          setShowChangeAgencyProfileModal(false)
          getAgencyProfileDetailsRefetch()
        },
        onError: ({ response: { data: errData } }: any) => {
          setImageUrl('')
          setShowChangeAgencyProfileModal(false)
          notify({
            alertHeader: errData?.messageTitle
              ? errData?.messageTitle
              : 'Error!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** upload agency secondary logo api */
  const {
    mutate: createAgencySecondaryLogoMutate,
    isLoading: createAgencySecondaryLogoIsLoading,
  } = useCreateAgencySecondaryLogo()

  /** Process the upload secondary picture */
  async function createAgencySecondaryLogo(Id: any, logoObject: any) {
    createAgencySecondaryLogoMutate(
      {
        Id,
        logoObject,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData.messageTitle
              ? successData.messageTitle
              : 'Secondary company logo uploaded successfully.',
            alertBody: '',
            status: 'Success',
          })
          setImageUrl('')
          setShowChangeAgencyProfileModal(false)
          getAgencyProfileDetailsRefetch()

          /** updated header bar details */
          dispatch(setIsHeaderBarDetailsUpdated(!isHeaderBarDetailsUpdated))
        },
        onError: ({ response: { data: errData } }: any) => {
          setImageUrl('')
          setShowChangeAgencyProfileModal(false)
          notify({
            alertHeader: errData?.messageTitle
              ? errData?.messageTitle
              : 'Error!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  return (
    <div className='flex flex-col w-full gap-6 agency-profile' {...restProps}>
      {getAgencyProfileDetailsIsFetching && <PageLoader size='xxs' />}

      {/** agency detail progress card */}
      <AgencyDetailProgressCard agencyProfileDetails={agencyProfileDetails} />

      {/** Agency Profile Card */}
      <div className='w-full'>
        <AgencyProfileCard
          agencyProfileDetails={agencyProfileDetails}
          onBasicInfoEditClick={() => {
            setShowEditAgencyBasicInfoDrawer(true)
          }}
          onChangePhotoClick={() => {
            setShowChangeAgencyProfileModal(true)
          }}
          logoUrl={agencyProfileDetails.logoUrl}
          isImage={agencyProfileDetails.logoUrl ? true : false}
          isSecondaryImage={headerBarDetails?.secondaryLogoUrl ? true : false}
          setIsSecondaryLogoModalOpen={setIsSecondaryLogoModalOpen}
        />
        {/** Edit Agency Profile Basic Info Modal */}
        <EditAgencyProfileInfoModal
          isActive={showEditAgencyBasicInfoDrawer}
          agencyProfileDetails={agencyProfileDetails}
          buttonTitle='Update'
          headerSubtitle={'Edit basic information here'}
          headerTitle={'Edit Company’s Basic Information'}
          notify={notify}
          setIsActive={setShowEditAgencyBasicInfoDrawer}
          getAgencyProfileDetailsRefetch={getAgencyProfileDetailsRefetch}
        />
        {/** Agency Profile about card */}
        <AgencyProfileAboutCard about={agencyProfileDetails?.about} />

        {/** Edit Agency Logo Modal */}
        <CustomizableEditProfilePictureModal
          isRectangleLogo={isSecondaryLogoModalOpen}
          isActive={showChangeAgencyProfileModal}
          onHeaderCloseButtonClick={() => {
            setImageUrl('')
            setShowChangeAgencyProfileModal(false)
          }}
          onClickSecondaryBtn={() => {
            setImageUrl('')
            setShowChangeAgencyProfileModal(false)
          }}
          onOverlayClick={() => {
            setImageUrl('')
            setShowChangeAgencyProfileModal(false)
          }}
          onClickPrimaryBtn={() => {
            if (!isSecondaryLogoModalOpen) {
              /** for primary logo */
              uploadAgencyProfileLogo(agencyProfileDetails.id, files)
            } else {
              /** for secondary logo */
              createAgencySecondaryLogo(agencyProfileDetails.id, files)
            }
          }}
          setImageUrl={setImageUrl}
          imageUrl={imageUrl}
          setFiles={setFiles}
          files={files}
          headerTitle={`${
            isSecondaryLogoModalOpen
              ? headerBarDetails?.secondaryLogoUrl
                ? 'Update secondary'
                : 'Add secondary'
              : agencyProfileDetails?.logoUrl
              ? 'Update primary'
              : 'Add primary'
          } company logo`}
          headerSubTitle={
            isSecondaryLogoModalOpen
              ? headerBarDetails?.secondaryLogoUrl
                ? 'This is the company logo displayed in the header bar'
                : 'This is the company logo displayed in the header bar'
              : agencyProfileDetails?.logoUrl
              ? 'This is the logo your candidates and referees will see'
              : 'This is the logo your candidates and referees will see'
          }
          primaryButtonIsLoading={
            uploadAgencyProfileLogoIsLoading ||
            createAgencySecondaryLogoIsLoading
          }
        />
      </div>
    </div>
  )
}
