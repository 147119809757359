import CN from 'classnames'

import './ModalFooter.scss'

type ModalFooterProps = {
  children?: any
  className?: string | undefined
  isCustomStyles?: boolean
}

export const ModalFooter = ({
  children,
  className,
  isCustomStyles,
  ...restProps
}: ModalFooterProps) => {
  const ModalFooterClasses = !isCustomStyles
    ? CN('modal__footer border-t-[1px] border-[#E5E7EB]', className, {})
    : className

  return (
    <div className={ModalFooterClasses} {...restProps}>
      {children}
    </div>
  )
}
