import React from 'react'
import { Button } from '@labourhub/labour-hub-ds'

import { Modal, ModalFooter } from 'components/molecules/Modal'

import './styles.scss'

type AlertModalProps = {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  handlePrimaryAction: () => void
  handleSecondaryAction?: () => void
  primaryButtonTitle?: string
  secondaryButtonTitle?: string
  description: string
  title: string
}

export const AlertModal = ({
  isOpen,
  title,
  description,
  primaryButtonTitle = 'Ok',
  secondaryButtonTitle = 'Close',

  setIsOpen,
  handlePrimaryAction,
  handleSecondaryAction,
}: AlertModalProps) => {
  const handleSecondaryButtonClick = () => {
    if (handleSecondaryAction) {
      handleSecondaryAction()
    } else {
      setIsOpen(false)
    }
  }

  return (
    <Modal
      isActive={isOpen}
      headerTitle={''}
      size='md'
      className='!z-[150]'
      isFooterShow={true}
      isPrimaryButtonDisable={false}
      onHeaderCloseButtonClick={() => {
        setIsOpen(false)
      }}>
      <div className='flex flex-col items-center justify-center p-6 pt-0'>
        <div className='flex items-center justify-center w-12 h-12 rounded-full bg-[#FFF6EC]'>
          <i className='ri-error-warning-line text-[#DD7F0B] text-heading-3'></i>
        </div>
        <span className='pt-5 text-Gray-900 text-heading-5 font-Medium'>
          {title}
        </span>
        <span className='pt-2 text-center text-Gray-500 text-small font-Regular'>
          {description}
        </span>
      </div>
      <ModalFooter className='border-t-0 shadow-none bg-Gray-50'>
        <div className='flex flex-1 gap-2'>
          <Button
            onClick={() => handleSecondaryButtonClick()}
            textTheme='blue'
            className='w-1/2'
            theme='white'>
            {secondaryButtonTitle}
          </Button>
          <Button
            onClick={handlePrimaryAction}
            className='w-1/2'
            theme='cobalt'>
            {primaryButtonTitle}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}
