import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

import { ReportSettingItem } from './getReportSettings'

export type AllReportSettingsDto = {
  referenceReportSettings: ReportSettingItem[]
  assessmentReportSettings: ReportSettingItem[]
}

const getAllReportSettings = async () => {
  const res = await get(`/all-reports`)
  return res.data
}

export const useGetAllReportSettings = (
  onSuccess: (data: AllReportSettingsDto) => void,
) => {
  return useQuery(['all_report_settings'], getAllReportSettings, {
    enabled: true,
    refetchOnWindowFocus: false,
    onSuccess,
    onError: () => {
      Toast({
        alertHeader: 'Error fetching report settings',
        status: 'Error',
      })
    },
  })
}
