/** candidate completion status */
export const Pending = 'Pending'
export const Passed = 'Passed'
export const Failed = 'Failed'

export const completionList = [
  {
    value: 'Pending',
    label: 'Pending',
  },
  {
    value: 'PassedCompleted',
    label: 'Passed & Completed',
  },
  {
    value: 'FailedCompleted',
    label: 'Failed & Completed',
  },
]
