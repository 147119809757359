import React, { FC } from 'react'

export const CallIcon: FC = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <rect width='40' height='40' rx='20' fill='#075BA9' />
      <g clip-path='url(#clip0_9763_61751)'>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M27.8793 14.9019L28.212 15.0392C28.4458 15.1358 28.6723 15.2197 28.9005 15.3043C29.7084 15.6036 30.5374 15.9108 31.7871 16.8125L31.9812 16.9525L31.9941 17.1881C32.0833 18.8122 31.2222 23.4479 27.2401 27.3435C23.2579 31.239 18.5193 32.0814 16.859 31.9942L16.6182 31.9815L16.4751 31.7917C15.5534 30.5691 15.2393 29.7581 14.9333 28.9678C14.8469 28.7446 14.7611 28.523 14.6624 28.2943L14.5425 28.0164L14.7379 27.7831C15.241 27.1822 16.1217 26.5924 16.9717 26.1304C17.8289 25.6643 18.736 25.2848 19.3425 25.1351L19.6306 25.064L21.0753 26.5368C21.7844 26.3165 22.9891 25.8578 24.3552 24.5213C25.7241 23.1822 26.1611 22.0291 26.3784 21.3404L24.9032 19.8823L24.9876 19.5997C25.4752 17.9673 26.6382 16.2322 27.6358 15.1628L27.8793 14.9019ZM28.1572 16.0971C27.3532 17.025 26.4934 18.3534 26.0586 19.6028L27.5326 21.0597L27.4446 21.3446C27.4354 21.3742 27.4259 21.4053 27.4159 21.4381C27.2001 22.1455 26.7622 23.581 25.0781 25.2285C23.36 26.9092 21.8349 27.3587 21.1223 27.5687C21.1051 27.5738 21.0884 27.5787 21.0721 27.5835L20.7723 27.6722L19.3137 26.1852C18.8235 26.3435 18.1446 26.6367 17.4682 27.0044C16.772 27.3829 16.1361 27.8095 15.7288 28.2036C15.7916 28.3585 15.8491 28.5067 15.9053 28.6515C16.1852 29.3724 16.433 30.0109 17.1512 31.0001C18.7553 30.9839 22.9444 30.1315 26.5172 26.6363C30.0901 23.1412 30.9614 19.0431 30.9779 17.4739C29.9668 16.7713 29.3141 16.5289 28.5771 16.2551C28.441 16.2045 28.3019 16.1529 28.1572 16.0971Z'
          fill='#F2F9FF'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M22.8 19.5H17.1778V18.5H22.8V19.5Z'
          fill='#F2F9FF'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M22.8 21.5H17.1778V20.5H22.8V21.5Z'
          fill='#F2F9FF'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M20.7556 23.5H17.1778V22.5H20.7556V23.5Z'
          fill='#F2F9FF'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M22.8 11H17.1778V10H22.8V11Z'
          fill='#F2F9FF'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M22.8 13H17.1778V12H22.8V13Z'
          fill='#F2F9FF'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M20.7556 15H17.1778V14H20.7556V15Z'
          fill='#F2F9FF'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M13.7497 22.8536L10.683 19.8536L11.4059 19.1465L14.4725 22.1465L13.7497 22.8536Z'
          fill='#F2F9FF'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M10.683 22.1465L13.7497 19.1465L14.4725 19.8536L11.4059 22.8536L10.683 22.1465Z'
          fill='#F2F9FF'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M9 17.5H16.1556V24.5H9V17.5ZM10.0222 18.5V23.5H15.1333V18.5H10.0222Z'
          fill='#F2F9FF'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M12.0667 13.0986L13.6859 10.7227L14.5364 11.2774L12.0667 14.9014L10.6192 12.7774L11.4697 12.2227L12.0667 13.0986Z'
          fill='#F2F9FF'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M9 9H16.1556V16H9V9ZM10.0222 10V15H15.1333V10H10.0222Z'
          fill='#F2F9FF'
        />
      </g>
      <defs>
        <clipPath id='clip0_9763_61751'>
          <rect
            width='23'
            height='23'
            fill='white'
            transform='translate(9 9)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CallIcon
