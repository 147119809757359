import { PaymentCardDto } from 'api/payments'
import ellipseIcon from 'assets/images/payment/ellipse.svg'
import CN from 'classnames'

import { creditCardvariants } from '../../data'
import { CreditCardVariant } from '../../types'
import { isCreditCardVariant } from '../../utils'

type CreditCardProps = {
  paymentCard?: PaymentCardDto
  setIsPaymentCardElementModalActive?: any
  handleOnEditCard?: (selectedPaymentMethod: PaymentCardDto) => void
}

export const CreditCard = ({
  paymentCard,
  setIsPaymentCardElementModalActive,
  handleOnEditCard,
}: CreditCardProps) => {
  const variant = paymentCard?.cardType
    ? isCreditCardVariant(paymentCard?.cardType)
      ? (paymentCard?.cardType as CreditCardVariant)
      : 'other'
    : 'blank'

  const variantData = creditCardvariants[variant]

  return (
    <div
      className={CN(
        'relative overflow-hidden bg-gradient-to-b rounded-lg shadow w-[236px] h-[136px]',
        variantData.gradient,
      )}>
      {/* Quarter circle - top right */}
      {variant != 'blank' && paymentCard && (
        <div className='absolute transform translate-x-1/2 -translate-y-1/2 right-4 top-4'>
          <div className='relative w-[95px] h-[95px] bg-[#D9D9D91F] rounded-full'>
            <button
              type='button'
              onClick={() => handleOnEditCard && handleOnEditCard(paymentCard)}>
              <i className='absolute w-10 h-10 text-white bottom-[0.59rem] ri-edit-2-fill right-9 cursor-pointer' />
            </button>
          </div>
        </div>
      )}
      {/* Quarter circle - bottom left */}
      <div className='absolute left-8 transform -translate-x-[40%] translate-y-1/2 bottom-8'>
        <img src={ellipseIcon} alt='' className='w-[100px] h-[135px]' />
      </div>
      {!paymentCard ? (
        <button onClick={() => setIsPaymentCardElementModalActive(true)}>
          <div className='absolute text-white transform -translate-x-[30%] -translate-y-[40%] top-1/2 left-[40%]'>
            <div className='flex items-center justify-center'>
              <i className='mr-2 ri-add-box-fill' />
              Click here to Add
            </div>
          </div>
        </button>
      ) : (
        <>
          <div className='px-4 pt-4 text-white text-extra-small'>
            {paymentCard.isDefaultCard ? 'Default' : 'Verified'}
          </div>

          <div className='px-4 pt-[1.75rem]'>
            <div className='text-white text-extra-small'>
              **** **** **** {paymentCard.last4Digits}
            </div>
            <div className='pt-1.5 text-white text-extra-small'>
              {paymentCard.expMonth} / {paymentCard.expYear}
            </div>
          </div>

          {variantData.icon && (
            <div className={CN('absolute', variantData.position)}>
              <img
                src={variantData.icon}
                alt=''
                className={CN('w-[50px] h-[20px]', variantData.iconClass)}
              />
            </div>
          )}
        </>
      )}
    </div>
  )
}
