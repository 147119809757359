import { memo, useEffect, useState } from 'react'
import labourhubLoading from 'assets/animations/refhub-loading.json'
import { useLottie } from 'lottie-react'

// Memoized component for the animated view
const AnimatedView = memo(() => {
  const options = {
    animationData: labourhubLoading,
    loop: true,
    autoplay: true,
    speed: 1,
  }

  const { View } = useLottie(options)

  return View
})

export const PercentageLoader = ({
  isComplete,
  handleLoadingComplete,
  delay = 15000, // Default delay of 15 seconds
  message = 'Loading',
}: {
  isComplete: boolean
  handleLoadingComplete: () => void
  delay?: number
  message?: string
}) => {
  const [percentage, setPercentage] = useState(0)

  useEffect(() => {
    if (isComplete) {
      setPercentage(100)
      return
    }

    const totalIncrements = 98 / 2 // Percentage stops at 98%, incrementing by 2% each time
    const intervalDelay = delay / totalIncrements // Delay divided by number of increments

    const interval = setInterval(() => {
      setPercentage((prev: number) => {
        if (prev >= 98) return prev
        return prev + 2
      })
    }, intervalDelay)

    return () => clearInterval(interval)
  }, [isComplete, delay])

  useEffect(() => {
    if (isComplete && percentage === 100) {
      setTimeout(handleLoadingComplete, 500)
    }
  }, [percentage, isComplete, handleLoadingComplete])

  return (
    <div className='z-[99] absolute w-full h-full bg-white flex items-center justify-center'>
      <div className='w-[400px] relative mx-auto'>
        <div className='w-[150px] mx-auto'>
          <AnimatedView />
        </div>
        <div className='text-center mt-2'>
          <span className='text-small font-bold'>
            {message}...{' '}
            <span className='ml-0.5 font-SemiBold text-small'>
              {percentage}%
            </span>
          </span>
        </div>
      </div>
    </div>
  )
}
