import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

export type ReportAssessmentStatsCountDto = {
  assessmentTotalCount: {
    completed: number
    pending: number
    cancelled: number
    assessmentCount: number
  }
}

type GetAssessmentCountByCandidateQueryParams = {
  candidateId: string
}

const getAssessmentCountByCandidate = async ({ queryKey }: any) => {
  const { candidateId } =
    queryKey[1] as GetAssessmentCountByCandidateQueryParams
  const URI = `/assessment/candidate/${candidateId}/assessmentcount`

  const res = await get(URI)
  return res.data as ReportAssessmentStatsCountDto
}

export const useGetAssessmentCountByCandidate = (
  queryParams: GetAssessmentCountByCandidateQueryParams,
  onSuccess: (data: ReportAssessmentStatsCountDto) => void,
) => {
  return useQuery(
    ['AssessmentCountByCandidate', queryParams],
    getAssessmentCountByCandidate,
    {
      enabled: false, // Disable automatic query execution
      refetchOnWindowFocus: false,
      onSuccess,
      onError: () => {
        Toast({
          alertHeader: 'Error fetching assessment count by candidate',
          status: 'Error',
        })
      },
    },
  )
}
