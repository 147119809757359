import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, TextField, Toast } from '@labourhub/labour-hub-ds'
import { useUpdateAgencyWithPrimaryLogo } from 'api/agencies/updateAgencyWithPrimaryLogo'
import { setAgency } from 'features/agencies/store/actions'
import { getAgency } from 'features/agencies/store/selectors'

import { PhoneInputField } from 'components/atoms'
import { isAlphaNumeric, isEmail, isPhoneNumber } from 'utils'

import { CompanyProfilePicture } from './CompanyProfilePicture'

type EditCompanyProfileProps = {
  setIsEditView: any
}

export const EditCompanyProfile = ({
  setIsEditView,
}: EditCompanyProfileProps) => {
  const dispatch = useDispatch()

  const notify = (props: any) => Toast(props)
  const { agency }: any = useSelector(getAgency)

  const [updatedAgencyDetails, setUpdatedAgencyDetails] = useState({
    name: '',
    email: '',
    phone: '',
    website: '',
    privacyPolicy: '',
  })

  const [primaryLogoFile, setPrimaryLogoFile] = useState<any>(null)

  useEffect(() => {
    const { name, email, website, phone, privacyPolicy } = agency || {}

    setUpdatedAgencyDetails({
      ...updatedAgencyDetails,
      name: name || '',
      email: email || '',
      phone: phone || '',
      website: website || '',
      privacyPolicy: privacyPolicy || '',
    })
  }, [agency])

  const [isErrorField, setIsErrorField] = useState<any>({
    name: false,
    phone: false,
    email: false,
  })

  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(false)

  /** front end validation for all input fields */
  const validateInputs = () => {
    const { name, phone, email } = updatedAgencyDetails

    setIsErrorField({
      ...isErrorField,
      name: isAlphaNumeric(name) && name?.length < 100 ? false : true,
      email: isEmail(email) && email?.length < 200 ? false : true,
      phone: isPhoneNumber(phone) && phone?.length < 20 ? false : true,
    })

    const isAllValidFields =
      isAlphaNumeric(name) &&
      name?.length < 100 &&
      isPhoneNumber(phone) &&
      phone?.length < 20

    return isAllValidFields
  }

  useEffect(() => {
    const { name, phone, email } = updatedAgencyDetails || {}

    ;(name && phone && email ? false : true)
      ? setIsPrimaryButtonDisabled(true)
      : setIsPrimaryButtonDisabled(false)
  }, [updatedAgencyDetails])

  /** handle edit candidate button */
  const onEditAgencyDetails = () => {
    const valid = validateInputs()

    if (valid) {
      updateProfile()
    } else {
      notify({
        alertHeader: 'Invalid input',
        alertBody: 'Please check your input fields and try again.',
        status: 'Warning',
      })
    }
  }

  const { mutate, isLoading } = useUpdateAgencyWithPrimaryLogo()

  const updateProfile = () => {
    mutate(
      {
        id: agency.id,
        ...updatedAgencyDetails,
        primaryLogo: primaryLogoFile,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData.messageTitle
              ? successData.messageTitle
              : 'Company profile updated successfully.',
            alertBody: '',
            status: 'Success',
          })
          dispatch(setAgency(successData?.agencyProfile))
          setIsEditView(false)
          setPrimaryLogoFile(null)
        },
        onError: ({ response: { data: errData } }: any) => {
          setPrimaryLogoFile(null)
          notify({
            alertHeader: errData?.messageTitle
              ? errData?.messageTitle
              : 'Error!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  return (
    <div className='w-full'>
      <div className='w-full max-h-[70vh] px-6 overflow-y-auto styled-scroll'>
        <CompanyProfilePicture
          imageFile={primaryLogoFile}
          setImageFile={setPrimaryLogoFile}
        />
        <TextField
          className='pt-5'
          value={updatedAgencyDetails?.name}
          onChange={(e: any) => {
            setUpdatedAgencyDetails({
              ...updatedAgencyDetails,
              name: e.target.value,
            })
          }}
          label='Company Name'
          placeholder=''
          isErrored={isErrorField?.name}
        />

        <TextField
          className='pt-5 company-email'
          isDisabled
          value={updatedAgencyDetails?.email}
          onChange={(e: any) => {
            setUpdatedAgencyDetails({
              ...updatedAgencyDetails,
              email: e.target.value,
            })
          }}
          label='Company Email address'
          placeholder=''
          isErrored={isErrorField?.email}
        />

        <PhoneInputField
          label='Phone Number'
          isRequired
          value={updatedAgencyDetails?.phone}
          onChange={(number: any) =>
            setUpdatedAgencyDetails({
              ...updatedAgencyDetails,
              phone: number,
            })
          }
          className='mt-5'
          isErrored={isErrorField?.phone}
        />

        <TextField
          className='pt-5 website'
          value={updatedAgencyDetails?.website}
          onChange={(e: any) => {
            setUpdatedAgencyDetails({
              ...updatedAgencyDetails,
              website: e.target.value,
            })
          }}
          label='Website'
          placeholder=''
        />

        <TextField
          className='pt-5'
          value={updatedAgencyDetails?.privacyPolicy}
          onChange={(e: any) => {
            setUpdatedAgencyDetails({
              ...updatedAgencyDetails,
              privacyPolicy: e.target.value,
            })
          }}
          label='Privacy Policy URL'
          placeholder=''
        />
      </div>
      <div className='flex flex-col justify-center px-5 mt-5 space-y-3'>
        <Button
          onClick={() => {
            onEditAgencyDetails()
          }}
          isDisabled={isPrimaryButtonDisabled}
          isLoading={isLoading}
          className='cursor-pointer'>
          Update
        </Button>
        <Button
          onClick={() => {
            setIsEditView(false)
          }}
          textTheme='black'
          theme='white'
          className='cursor-pointer'>
          Cancel
        </Button>
      </div>
    </div>
  )
}
