import { useEffect } from 'react'
import { Modal } from '@labourhub/labour-hub-ds'
import { useGetDynamicLibraryForm } from 'api/questionnaires'
import { ModalProps } from 'types'

import { PageLoader } from 'components/atoms'
import { FormBuilder } from 'components/molecules'

type CustomSurveyPreviewModalProps = ModalProps & {
  templateId: string
  templateName: string
}

export const CustomSurveyPreviewModal = ({
  isModalActive,
  setIsModalActive,
  templateId,
  templateName,
}: CustomSurveyPreviewModalProps) => {
  /** get dynamic form */
  const {
    data: dynamicForm,
    refetch: getDynamicForm,
    isLoading: getDynamicFormIsLoading,
  } = useGetDynamicLibraryForm(templateId)
  /** call get dynamic form when template id updated */
  useEffect(() => {
    templateId && isModalActive && getDynamicForm()
  }, [isModalActive, templateId])
  const onModalClose = () => setIsModalActive(false)
  return (
    <Modal
      headerTitle={`Preview - ${templateName}`}
      customWidth='1000px'
      isActive={isModalActive}
      primaryButtonTheme={'cobalt'}
      primaryButtonTitle=''
      footerButtonAlignment='right'
      onClickSecondaryBtn={onModalClose}
      onHeaderCloseButtonClick={onModalClose}
      onOverlayClick={onModalClose}
      secondaryButtonTitle='Close'>
      {getDynamicFormIsLoading && <PageLoader size='xxs' />}
      <div className='flex flex-col w-full justify-start px-8 py-5 min-h-[30vh] max-h-[80vh] overflow-y-auto'>
        {/* form builder */}
        {dynamicForm && (
          <FormBuilder
            schema={JSON.parse(dynamicForm.jsonSchema)}
            uiSchema={JSON.parse(dynamicForm.jsonUiSchema)}
            readonly={true}
          />
        )}
      </div>
    </Modal>
  )
}
