import { useQuery } from 'react-query'
import { get } from 'framework/api/http'
import { ReportQuestion } from 'framework/api/methods'
import { QueryParams } from 'types'

export type AssessmentResponseListDto = {
  assessmentResponses: AssessmentResponseDto[]
  take: any
  skip: any
  totalCount: any
  message: string
}

export type AssessmentResponseDto = {
  assessmentId: string
  assessmentName: string
  assessmentTemplateType: string
  candidateId: string
  //   candidateName: string
  completedOn?: string
  difficultyLevel: string
  id: string
  jsonData: string
  jsonSchema: string
  jsonUiSchema: string
  reminderLastSentOn?: Date
  sentBy: string
  sentOn: string
  status: string
  reportQuestions: ReportQuestion[]
}

type AssessmentResponseListQueryParams = QueryParams & {
  candidateId: string
  status: string
  emailStatus: string
  ManagingConsultantId: string
}

const getAssessmentResponsesList = async ({ queryKey }: any) => {
  const {
    candidateId,
    search,
    skip,
    take,
    status,
    emailStatus,
    sortColumnName,
    sortDirection,
    ManagingConsultantId,
  } = queryKey[1] as AssessmentResponseListQueryParams
  let URI = `/assessment/assessment/responses?candidateId=${candidateId}&skip=${skip}&take=${take}`
  if (search) {
    URI += `&search=${search}`
  }
  if (ManagingConsultantId) {
    URI += `&ManagingConsultantId=${ManagingConsultantId}`
  }
  if (status) {
    URI += `&status=${status}`
  }
  if (emailStatus) {
    URI += `&EmailStatus=${emailStatus}`
  }
  if (sortColumnName) {
    URI += `&sortColumnName=${sortColumnName}`
  }
  if (sortDirection) {
    URI += `&sortDirection=${sortDirection}`
  }
  const res = await get(URI)
  return res.data
}

export const useGetAssessmentResponsesList = (
  { ...reqBody },
  onSuccess: any,
  onError: any,
) => {
  return useQuery(
    ['AssessmentResponses', reqBody],
    getAssessmentResponsesList,
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess,
      onError,
    },
  )
}
