import { Modal, Toast } from '@labourhub/labour-hub-ds'
import { useDeleteCandidateAvatar } from 'api/candidates'
import { ModalProps } from 'types'

type DeleteProfilePictureModalProps = ModalProps & {
  candidateId: string
  getCandidateById: any
}

export const DeleteProfilePictureModal = ({
  isModalActive,
  setIsModalActive,
  candidateId,
  getCandidateById,
}: DeleteProfilePictureModalProps) => {
  const notify = (props: any) => Toast(props)

  /** API Call for delete candidate photo using react-query */
  const {
    mutate: deleteCandidateAvatarMutate,
    isLoading: deleteCandidateAvatarIsLoading,
  } = useDeleteCandidateAvatar()

  /** Process the Delete candidate photo */
  const deleteCandidatePhoto = () => {
    deleteCandidateAvatarMutate(
      {
        Id: candidateId,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData.messageTitle
              ? successData.messageTitle
              : 'Photo was removed!',
            alertBody: '',
            status: 'Success',
          })
          getCandidateById()
          setIsModalActive(false)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: errData?.messageTitle
              ? errData?.messageTitle
              : 'Error!',
            alertBody: errData?.message ? errData?.message : 'Error!',
            status: 'Error',
          })
        },
      },
    )
  }

  return (
    <Modal
      isActive={isModalActive}
      onClickPrimaryBtn={() => deleteCandidatePhoto()}
      onClickSecondaryBtn={() => setIsModalActive(false)}
      primaryButtonProps={{
        style: { width: '100%', marginRight: '12px' },
        isLoading: deleteCandidateAvatarIsLoading,
      }}
      secondaryButtonProps={{
        style: { width: '100%', color: '#1F2937' },
      }}
      onOverlayClick={() => setIsModalActive(false)}
      modalProps={{
        style: { width: '466px' },
      }}
      footerProps={{
        style: {
          justifyContent: 'space-between',
          paddingLeft: '23px',
          paddingRight: '23px',
        },
      }}
      isHeaderShow={false}
      primaryButtonTitle='Yes, I’m Sure'
      secondaryButtonTitle='Cancel'>
      <div className='flex flex-col items-center justify-center p-6'>
        <div className='flex items-center justify-center w-12 h-12 rounded-full bg-Red-100'>
          <i className='ri-delete-bin-6-line text-Red-500 text-heading-3'></i>
        </div>

        <span className='pt-5 text-Gray-900 text-heading-5 font-Medium'>
          Delete Candidate Profile Picture?
        </span>

        <span className='pt-2 text-center text-Gray-500 text-small font-Regular'>
          Are you sure you want to proceed?
        </span>
      </div>
    </Modal>
  )
}
