import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'
import { QueryParams } from 'types/queryParams'

const getSmsMessageTrackList = async ({ queryKey }: any) => {
  const { take, skip, search, isActive } = queryKey[1] as QueryParams

  let URI = `/SMS/MessageTracks?take=${take}&skip=${skip}`

  if (search) {
    URI += `&search=${search}`
  }
  if (isActive == true || isActive == false) {
    URI += `&isActive=${isActive}`
  }

  const res = await get(URI)
  return res.data
}

export const useGetSmsMessageTrackList = (
  queryParams: QueryParams,
  onSuccess: (data: any) => void,
) => {
  return useQuery(['SmsMessageTracks', queryParams], getSmsMessageTrackList, {
    enabled: true,
    refetchOnWindowFocus: false,
    onSuccess,
    onError: () => {
      Toast({
        alertHeader: 'Error fetching sms message track list',
        status: 'Error',
      })
    },
  })
}
