import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'

interface TabDetails {
  id: number
  tabButtonContent: string
  tabContent: React.ReactNode
}

interface FilledTabsProps {
  tabDetails: TabDetails[]
  defaultTabId?: number
  openTabId?: number
  onHeaderBtnClick?: () => void
  contentClassName?: string
}

export interface FilledTabsRef {
  setActiveTab: (id: number) => void
}

export const FilledTabs = forwardRef<FilledTabsRef, FilledTabsProps>(
  (
    { tabDetails, defaultTabId, openTabId, onHeaderBtnClick, contentClassName },
    ref,
  ) => {
    const [activeTab, setActiveTab] = useState<number>(
      defaultTabId || tabDetails[0].id,
    )

    useImperativeHandle(ref, () => ({
      setActiveTab: (id: number) => setActiveTab(id),
    }))

    useEffect(() => {
      if (openTabId !== undefined) {
        setActiveTab(openTabId)
      }
    }, [openTabId])

    const handleTabClick = (id: number) => {
      setActiveTab(id)
      if (onHeaderBtnClick) {
        onHeaderBtnClick()
      }
    }

    const activeTabContent = tabDetails.find(
      (tab) => tab.id === activeTab,
    )?.tabContent

    return (
      <div>
        <div className='w-[350px] flex flex-row pb-5'>
          {tabDetails.map(({ id, tabButtonContent }) => (
            <button
              key={id}
              onClick={() => handleTabClick(id)}
              className={`w-full flex justify-center font-Medium focus:outline-none p-1 cursor-pointer py-2 ${
                activeTab === id
                  ? 'bg-Cobalt-600 text-white border-2 border-Cobalt-600' +
                    (id === 1
                      ? ' rounded-tl-md rounded-bl-md'
                      : ' rounded-tr-md rounded-br-md')
                  : 'bg-white text-Gray-800 hover:bg-Gray-50 border-2 border-Gray-200' +
                    (id === 1
                      ? ' border-r-0 rounded-tl-md rounded-bl-md'
                      : ' rounded-tr-md rounded-br-md')
              }`}>
              <span className='text-center'>{tabButtonContent}</span>
            </button>
          ))}
        </div>

        <div className={contentClassName}>{activeTabContent}</div>
      </div>
    )
  },
)
