import CN from 'classnames'
import { ReferenceCheckType } from 'types'

type ReferenceTypeLabelProps = {
  className?: string | undefined
  type?:
    | ReferenceCheckType.Employment
    | ReferenceCheckType.FriendFamily
    | ReferenceCheckType.Professional
  [x: string]: any
}

export const ReferenceTypeLabel = ({
  className,
  type,
  ...restProps
}: ReferenceTypeLabelProps) => {
  /** button main styles*/
  const BadgeClassName = CN(
    'flex justify-center items-center w-fit py-[2px] pr-[10px] pl-[8px] rounded-[10px] font-Medium text-extra-small',
    {
      'bg-[#C0D5FA8F] text-[#4285F4]': type === ReferenceCheckType.Employment,
      'bg-[#E3C3E894] text-[#881798]': type === ReferenceCheckType.Professional,
      'bg-[#FADA8033] text-[#EA4335A8]':
        type === ReferenceCheckType.FriendFamily,
    },
    className,
  )

  return (
    <div className={BadgeClassName} {...restProps}>
      <i
        className={CN('text-[12px] mr-[6px]', {
          'ri-community-line': type === ReferenceCheckType.Employment,
          'ri-briefcase-fill': type === ReferenceCheckType.Professional,
          'ri-account-pin-circle-line':
            type === ReferenceCheckType.FriendFamily,
        })}
      />
      {type === ReferenceCheckType.FriendFamily ? 'Friends & Family' : type}
    </div>
  )
}
