import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'
import { QueryParams } from 'types/queryParams'

const getContactList = async ({ queryKey }: any) => {
  const { take, skip, search } = queryKey[1] as QueryParams

  let URI = `/contacts?take=${take}&skip=${skip}`

  if (search) {
    URI += `&search=${search}`
  }

  const res = await get(URI)
  return res.data
}

export const useGetContactList = (
  queryParams: QueryParams,
  onSuccess: (data: any) => void,
) => {
  return useQuery(['contact_list', queryParams], getContactList, {
    enabled: true,
    refetchOnWindowFocus: false,
    onSuccess,
    onError: () => {
      Toast({
        alertHeader: 'Error fetching contact list',
        status: 'Error',
      })
    },
  })
}
