import { useMutation } from 'react-query'
import { deleteAPI } from 'framework/api/http'

interface DeleteQuestionaryInterface {
  Id?: string
}

export const useDeleteQuestionary = () => {
  const mutate = useMutation(({ Id }: DeleteQuestionaryInterface) => {
    return deleteAPI(`/Questionnaire/${Id}`)
  })

  return mutate
}

export default useDeleteQuestionary
