import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

export type SendCustomTemplateDto = {
  templateName: string
  isEmailTest: boolean
  email?: string
  countryCode?: string
  phoneNumber?: string
}

export const useSendCustomTemplate = () => {
  const mutate = useMutation((request: SendCustomTemplateDto) => {
    return postAPI(`/templates/custom/test`, request)
  })

  return mutate
}
