export enum AppSettingKey {
  AutoReferencing = 'AUTO_REFERENCING',
  IS_SURVEY_ENABLED = 'IS_SURVEY_ENABLED',
  IS_ASSESSMENT_ENABLED = 'IS_ASSESSMENT_ENABLED',
  IS_PHONE_REF_CHECK_HIDE = 'IS_PHONE_REF_CHECK_HIDE',
  RequestRefereeDetailsProcess = 'REQUEST_REFEREE_DETAILS_PROCESS',
  QuestionnaireTemplateId = 'QUESTIONNAIRE_TEMPLATE_ID',
  LeadGenStaffRequest = 'LEAD_GEN_STAFF_REQUEST',
  LeadGenCareerInterest = 'LEAD_GEN_CAREER_INTEREST',
  SmsFeature = 'SMS_FEATURE',
  NccPoliceCheck = 'NCC_POLICE_CHECK',
  NccPoliceCheckVolunteer = 'NCC_POLICE_CHECK_VOLUNTEER',
  NccRightToWorkCheck = 'NCC_RIGHT_TO_WORK_CHECK',
  IS_CANDIDATE_PROFILE_IMAGE_REQUIRED = 'IS_CANDIDATE_PROFILE_IMAGE_REQUIRED',
  IS_CANDIDATE_EMAIL_REQUIRED = 'IS_CANDIDATE_EMAIL_REQUIRED',
  IsRefereeEmailRequired = 'IS_REFEREE_EMAIL_REQUIRED',
  DefaultReferenceCheckType = 'DEFAULT_REFERENCE_CHECK_TYPE',
}
