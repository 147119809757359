/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useCallback, useEffect, useRef, useState } from 'react'
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
} from '@lexical/list'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $createHeadingNode, HeadingTagType } from '@lexical/rich-text'
import {
  $getSelectionStyleValueForProperty,
  $patchStyleText,
  $setBlocksType,
} from '@lexical/selection'
import { mergeRegister } from '@lexical/utils'
import { motion } from 'framer-motion'
import {
  $createParagraphNode,
  $getRoot,
  $getSelection,
  $isParagraphNode,
  $isRangeSelection,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  CLEAR_EDITOR_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND,
} from 'lexical'

import { ColorPicker } from 'components/core/ColorPicker'
import { cn } from 'utils'

const LowPriority = 1

function Divider() {
  return <div className='divider' />
}

const blockTypeToBlockName = {
  bullet: 'Bulleted List',
  number: 'Numbered List',
  paragraph: 'Normal',
  h1: 'Heading 1',
  h2: 'Heading 2',
  h3: 'Heading 3',
  h4: 'Heading 4',
  h5: 'Heading 5',
  h6: 'Heading 6',
}

export const ToolbarPlugin = () => {
  const [editor] = useLexicalComposerContext()
  const toolbarRef = useRef(null)
  const [canUndo, setCanUndo] = useState(false)
  const [canRedo, setCanRedo] = useState(false)
  const [isBold, setIsBold] = useState(false)
  const [fontColor, setFontColor] = useState('#000000')
  const [isItalic, setIsItalic] = useState(false)
  const [isUnderline, setIsUnderline] = useState(false)
  const [isStrikethrough, setIsStrikethrough] = useState(false)
  const [isEditorEmpty, setIsEditorEmpty] = useState(true)

  const [isToggleColor, setIsToggleColor] = useState(false)
  const [isToggleHeading, setIsToggleHeading] = useState(false)

  const toggleHeadingMenu = () => {
    setIsToggleColor(false)
    setIsToggleHeading(!isToggleHeading)
  }

  const toggleColorMenu = () => {
    setIsToggleColor(!isToggleColor)
    setIsToggleHeading(false)
  }

  const subMenuAnimate = {
    enter: {
      opacity: 1,
      rotateX: 0,
      transition: {
        duration: 0.25,
      },
      display: 'block',
    },
    exit: {
      opacity: 0,
      rotateX: -15,
      transition: {
        duration: 0.25,
        delay: 0.15,
      },
      transitionEnd: {
        display: 'none',
      },
    },
  }

  const [blockType, setBlockType] =
    useState<keyof typeof blockTypeToBlockName>('paragraph')

  const formatList = (listType: keyof typeof blockTypeToBlockName) => {
    if (listType === 'number' && blockType !== 'number') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined)
      setBlockType('number')
    } else if (listType === 'bullet' && blockType !== 'bullet') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined)
      setBlockType('bullet')
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
      setBlockType('paragraph')
    }
  }

  const applyStyleText = useCallback(
    (styles: Record<string, string>) => {
      editor.update(() => {
        const selection = $getSelection()
        if (selection !== null) {
          $patchStyleText(selection, styles)
        }
      })
    },
    [editor],
  )

  const onFontColorSelect = useCallback(
    (value: string) => applyStyleText({ color: value }),
    [applyStyleText],
  )

  const formatParagraph = () => {
    editor.update(() => {
      const selection = $getSelection()
      $setBlocksType(selection, () => $createParagraphNode())
    })
    setBlockType('paragraph')
  }

  const formatHeading = (headingSize: HeadingTagType) => {
    if (blockType !== headingSize) {
      editor.update(() => {
        const selection = $getSelection()
        $setBlocksType(selection, () => $createHeadingNode(headingSize))
        setBlockType(headingSize)
      })
    } else {
      formatParagraph()
    }
  }

  const updateToolbar = useCallback(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      setIsBold(selection.hasFormat('bold'))
      setIsItalic(selection.hasFormat('italic'))
      setIsUnderline(selection.hasFormat('underline'))
      setIsStrikethrough(selection.hasFormat('strikethrough'))
      setFontColor(
        $getSelectionStyleValueForProperty(selection, 'color', '#000'),
      )
    }
  }, [])

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar()

          const root = $getRoot()
          const children = root.getChildren()

          if (children.length > 1) {
            setIsEditorEmpty(false)
          } else {
            if ($isParagraphNode(children[0])) {
              const paragraphChildren = children[0].getChildren()
              setIsEditorEmpty(paragraphChildren.length === 0)
            } else {
              setIsEditorEmpty(false)
            }
          }
        })
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateToolbar()
          return false
        },
        LowPriority,
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload: boolean) => {
          setCanUndo(payload)
          return false
        },
        LowPriority,
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload: boolean) => {
          setCanRedo(payload)
          return false
        },
        LowPriority,
      ),
    )
  }, [editor, updateToolbar])

  return (
    <div className='border toolbar border-Gray-300' ref={toolbarRef}>
      <button
        disabled={!canUndo}
        onClick={() => {
          editor.dispatchCommand(UNDO_COMMAND, undefined)
        }}
        className='toolbar-item spaced'
        aria-label='Undo'>
        <i className='text-[18px] format ri-arrow-go-back-line' />
      </button>
      <button
        disabled={!canRedo}
        onClick={() => {
          editor.dispatchCommand(REDO_COMMAND, undefined)
        }}
        className='toolbar-item'
        aria-label='Redo'>
        <i className='text-[18px] format ri-arrow-go-forward-line' />
      </button>
      <Divider />

      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')
        }}
        className={'toolbar-item spaced ' + (isBold ? 'active' : '')}
        aria-label='Format Bold'>
        <i className='text-[18px] format ri-bold' />
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')
        }}
        className={'toolbar-item spaced ' + (isItalic ? 'active' : '')}
        aria-label='Format Italics'>
        <i className='text-[18px] format ri-italic' />
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')
        }}
        className={'toolbar-item spaced ' + (isUnderline ? 'active' : '')}
        aria-label='Format Underline'>
        <i className='text-[18px] format ri-underline' />
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough')
        }}
        className={'toolbar-item spaced ' + (isStrikethrough ? 'active' : '')}
        aria-label='Format Strikethrough'>
        <i className='text-[18px] format ri-strikethrough' />
      </button>
      <Divider />

      <div>
        <button
          onClick={toggleHeadingMenu}
          className='toolbar-item'
          aria-label='Numbered List'>
          <i className='text-[18px] format ri-font-size-2' />
          <i className='ri-arrow-drop-down-line format'></i>
        </button>
        <motion.div
          className='menu-item'
          initial='exit'
          animate={isToggleHeading ? 'enter' : 'exit'}
          variants={subMenuAnimate}
          onMouseLeave={toggleHeadingMenu}>
          <ul className='bg-Cobalt-50'>
            <li
              onClick={formatParagraph}
              className={cn(
                'flex items-center justify-start px-4 py-2 bg-white border-[0.5px] cursor-pointer border-Gray-200 hover:bg-Cobalt-50',
                {
                  'bg-Cobalt-50 ': blockType == 'paragraph',
                },
              )}>
              <i className='ri-text' />
              <span className='ml-2 text-small'>Normal</span>
            </li>
            <li
              onClick={() => formatHeading('h1')}
              className={cn(
                'flex items-center justify-start px-4 py-2 bg-white border-[0.5px] border-t-0 cursor-pointer border-Gray-200 hover:bg-Cobalt-50',
                {
                  'bg-Cobalt-50': blockType == 'h1',
                },
              )}>
              <i className='ri-h-1' />
              <span className='ml-2 text-small '>Heading 1</span>
            </li>
            <li
              onClick={() => formatHeading('h2')}
              className={cn(
                'flex items-center justify-start px-4 py-2 bg-white border-[0.5px] border-t-0 cursor-pointer border-Gray-200 hover:bg-Cobalt-50',
                {
                  'bg-Cobalt-50': blockType == 'h2',
                },
              )}>
              <i className='ri-h-2' />
              <span className='ml-2 text-small '>Heading 2</span>
            </li>
            <li
              onClick={() => formatHeading('h3')}
              className={cn(
                'flex items-center justify-start px-4 py-2 bg-white border-[0.5px] border-t-0 cursor-pointer border-Gray-200 hover:bg-Cobalt-50',
                {
                  'bg-Cobalt-50': blockType == 'h3',
                },
              )}>
              <i className='ri-h-3' />
              <span className='ml-2 text-small '>Heading 3</span>
            </li>
            <li
              onClick={() => formatHeading('h4')}
              className={cn(
                'flex items-center justify-start px-4 py-2 bg-white border-[0.5px] border-t-0 cursor-pointer border-Gray-200 hover:bg-Cobalt-50',
                {
                  'bg-Cobalt-50': blockType == 'h4',
                },
              )}>
              <i className='ri-h-4' />
              <span className='ml-2 text-small '>Heading 4</span>
            </li>
            <li
              onClick={() => formatHeading('h5')}
              className={cn(
                'flex items-center justify-start px-4 py-2 bg-white border-[0.5px] border-t-0 cursor-pointer border-Gray-200 hover:bg-Cobalt-50',
                {
                  'bg-Cobalt-50': blockType == 'h5',
                },
              )}>
              <i className='ri-h-5' />
              <span className='ml-2 text-small '>Heading 5</span>
            </li>
            <li
              onClick={() => formatHeading('h6')}
              className={cn(
                'flex items-center justify-start px-4 py-2 bg-white border-[0.5px] border-t-0 cursor-pointer border-Gray-200 hover:bg-Cobalt-50',
                {
                  'bg-Cobalt-50': blockType == 'h6',
                },
              )}>
              <i className='ri-h-6' />
              <span className='ml-2 text-small '>Heading 6</span>
            </li>
          </ul>
        </motion.div>
      </div>

      <div>
        <button
          onClick={toggleColorMenu}
          className='toolbar-item'
          aria-label='Numbered List'>
          <i className='text-[18px] format ri-font-color' />
          <i className='ri-arrow-drop-down-line format'></i>
        </button>
        <motion.div
          className='menu-item'
          initial='exit'
          animate={isToggleColor ? 'enter' : 'exit'}
          variants={subMenuAnimate}
          onMouseLeave={toggleColorMenu}>
          <ColorPicker color={fontColor} onChange={onFontColorSelect} />
        </motion.div>
      </div>

      <Divider />
      <button
        onClick={() => formatList('bullet')}
        className='toolbar-item spaced'
        aria-label='Bullet List'>
        <i className='text-[18px] format ri-list-unordered' />
      </button>
      <button
        onClick={() => formatList('number')}
        className='toolbar-item spaced'
        aria-label='Numbered List'>
        <i className='text-[18px] format ri-list-ordered' />
      </button>
      <Divider />
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left')
        }}
        className='toolbar-item spaced'
        aria-label='Left Align'>
        <i className='text-[18px] format ri-align-left' />
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center')
        }}
        className='toolbar-item spaced'
        aria-label='Center Align'>
        <i className='text-[18px] format ri-align-center' />
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right')
        }}
        className='toolbar-item spaced'
        aria-label='Right Align'>
        <i className='text-[18px] format ri-align-right' />
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify')
        }}
        className='toolbar-item'
        aria-label='Justify Align'>
        <i className='text-[18px] format ri-align-justify' />
      </button>
      <Divider />
      <button
        disabled={isEditorEmpty}
        onClick={() => {
          editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined)
        }}
        className='toolbar-item'
        aria-label='Clear'>
        <i className='text-[18px] format ri-format-clear' />
      </button>
    </div>
  )
}
