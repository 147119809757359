import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

type AddRightToWorkCheckProps = {
  tenantId: string
  credentials: NccCredentialProps
}

export type NccCredentialProps = {
  apiKey: string
  secretKey: string
  webhookToken: string
}

export const useAddEditNccCredentials = () => {
  const mutate = useMutation((body: AddRightToWorkCheckProps) => {
    return postAPI(`/NCC/Credentials`, body)
  })

  return mutate
}
