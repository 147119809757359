import { createSlice } from '@reduxjs/toolkit'
import { QuestionnaireCategoryType } from 'api/questionnaires'

type QuestionnaireStore = {
  totalQuestionnaires: any
  totalAssessments: any
  isRefreshList: boolean
  isAssessmentRefreshList: boolean
  aiQuestionData: any
  aiAssessmentQuestionData: any
  filterVariables: {
    searchKeyword: string
    status: string
    type: string
    filteredSurveyType: string
  }
  assessmentFilterVariables: {
    searchKeyword: string
    status: string
    type: string
    filteredSurveyType: string
    difficultyLevel: string
  }
  surveyType: QuestionnaireCategoryType
  isNewAssessmentModalOpen: boolean
  isNavigatingFromNewAssessmentModal: boolean
  isNewRefCheckModalOpen: boolean
  isNavigatingFromRefCheckFlowModal: boolean
}

const initialState: QuestionnaireStore = {
  totalQuestionnaires: null,
  totalAssessments: null,
  isRefreshList: false,
  isAssessmentRefreshList: false,
  aiQuestionData: null,
  aiAssessmentQuestionData: null,
  filterVariables: {
    searchKeyword: '',
    status: '',
    type: '',
    filteredSurveyType: '',
  },
  assessmentFilterVariables: {
    searchKeyword: '',
    status: '',
    type: '',
    filteredSurveyType: '',
    difficultyLevel: '',
  },
  surveyType: QuestionnaireCategoryType.ReferenceCheck,
  isNewAssessmentModalOpen: false,
  isNavigatingFromNewAssessmentModal: false,
  isNewRefCheckModalOpen: false,
  isNavigatingFromRefCheckFlowModal: false,
}

const questionnaire = createSlice({
  name: 'questionnaire',
  initialState,
  reducers: {
    setTotalQuestionnairesState(state, { payload }) {
      state.totalQuestionnaires = payload
    },
    setTotalAssessmentsState(state, { payload }) {
      state.totalAssessments = payload
    },
    setFilterVariablesState(state, { payload }) {
      state.filterVariables = payload
    },
    setIsRefreshListState(state) {
      state.isRefreshList = !state.isRefreshList
    },
    setAIQuestionDataState(state, { payload }) {
      state.aiQuestionData = payload
    },
    setSurveyTypeState(state, { payload }) {
      state.surveyType = payload
    },
    setIsAssessmentRefreshListState(state) {
      state.isAssessmentRefreshList = !state.isAssessmentRefreshList
    },
    setAssessmentFilterVariablesState(state, { payload }) {
      state.assessmentFilterVariables = payload
    },
    setAIAssessmentQuestionDataState(state, { payload }) {
      state.aiAssessmentQuestionData = payload
    },
    setIsNewAssessmentModalOpenState(state, { payload }) {
      state.isNewAssessmentModalOpen = payload
    },
    setIsNavigatingFromNewAssessmentModalState(state, { payload }) {
      state.isNavigatingFromNewAssessmentModal = payload
    },
    setIsNewRefCheckModalOpenState(state, { payload }) {
      state.isNewRefCheckModalOpen = payload
    },
    setIsNavigatingFromRefCheckFlowModalState(state, { payload }) {
      state.isNavigatingFromRefCheckFlowModal = payload
    },
  },
})

export const {
  setTotalQuestionnairesState,
  setTotalAssessmentsState,
  setIsRefreshListState,
  setAIQuestionDataState,
  setFilterVariablesState,
  setSurveyTypeState,
  setIsAssessmentRefreshListState,
  setAssessmentFilterVariablesState,
  setAIAssessmentQuestionDataState,
  setIsNavigatingFromNewAssessmentModalState,
  setIsNewAssessmentModalOpenState,
  setIsNewRefCheckModalOpenState,
  setIsNavigatingFromRefCheckFlowModalState,
} = questionnaire.actions

export default questionnaire.reducer
