import { useRef } from 'react'
import { Button } from '@labourhub/labour-hub-ds'
import { AssessmentQuestion } from 'features/questionnaires/types'
import { ModalProps } from 'types'

import { PageLoader } from 'components/atoms'
import { Modal, ModalFooter } from 'components/molecules'

import { AssessmentPreviewQuestionCardsColumn } from '../AssessmentTab/AssessmentTable/AssessmentPreviewModal/AssessmentPreviewQuestionCardsColumn'

type AssessmentEditPreviewModalProps = ModalProps & {
  templateName: string
  questionCardItems: AssessmentQuestion[]
}

export const AssessmentEditPreviewModal = ({
  isModalActive,
  setIsModalActive,
  templateName,
  questionCardItems,
}: AssessmentEditPreviewModalProps) => {
  const componentRef = useRef<HTMLDivElement>(null)
  return (
    <Modal
      headerTitle={`Preview - ${templateName}`}
      customWidth='1000px'
      isActive={isModalActive}
      primaryButtonTheme={'cobalt'}
      primaryButtonTitle=''
      footerButtonAlignment='right'
      onClickSecondaryBtn={() => setIsModalActive(false)}
      onHeaderCloseButtonClick={() => setIsModalActive(false)}
      onOverlayClick={() => setIsModalActive(false)}
      secondaryButtonTitle='Close'
      isFooterShow={true}>
      <div
        ref={componentRef}
        className='flex flex-col w-full justify-start px-8 min-h-[30vh] max-h-[80vh] overflow-y-auto'>
        {questionCardItems.length == 0 && <PageLoader size='xxs' />}
        <AssessmentPreviewQuestionCardsColumn items={questionCardItems} />
      </div>

      <ModalFooter>
        <div className='flex justify-end flex-1'>
          <Button
            onClick={() => setIsModalActive(false)}
            textTheme='black'
            theme='white'>
            Close
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}
