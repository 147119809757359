import { TemplateVariablesDto } from 'api/templates'

type DynamicVariablesSectionProps = {
  variables: TemplateVariablesDto[]
}

export const DynamicVariablesSection = ({
  variables = [],
}: DynamicVariablesSectionProps) => {
  return (
    <div className='px-4 pt-3 pb-6'>
      <div className='text-small font-SemiBold text-Gray-800 mb-3'>
        Dynamic Fields
      </div>
      <div className='w-[297px] h-[56px] px-4 py-2 mb-3 text-[10px] rounded-[4px] font-Regular text-Blue-700 bg-Blue-50'>
        <span>
          You can include these dynamic fields by using the @ symbol and
          selecting from the list of variables below.
        </span>
      </div>

      <div className='px-2 space-y-3 text-[10px] font-Regular'>
        <div className='flex flex-col items-start justify-start space-y-3'>
          {variables.map((variable) => (
            <span key={variable.variable}>
              <span className='w-fit'>@{variable.previewData}</span>
              {variable.description && `= ${variable.description}`}
            </span>
          ))}
        </div>
      </div>
    </div>
  )
}
