import { useMutation } from 'react-query'
import { ReportTypes } from 'features/settings/data/reportTypes'
import { deleteAPI } from 'framework/api/http'

export type DeleteReportSettingBackgroundImageRequest = {
  id: string
  reportType: (typeof ReportTypes)[keyof typeof ReportTypes]
  IsCompanyLogo?: boolean
}

export const useDeleteReportSettingBackgroundImage = () => {
  const mutate = useMutation(
    ({
      id,
      reportType = ReportTypes.REFERENCE,
      IsCompanyLogo = false,
    }: DeleteReportSettingBackgroundImageRequest) => {
      return deleteAPI(
        `/reports/${id}/type/${reportType}/iscompanylogo/${IsCompanyLogo}/background-image`,
      )
    },
  )

  return mutate
}
