import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

interface AssessmentLibraryPublishInterface {
  id?: string
  assessmentType: string
}

export const usePublishAssessmentLibrary = () => {
  const mutate = useMutation(
    ({ id, assessmentType }: AssessmentLibraryPublishInterface) => {
      return putAPI(`/assessment/library/${id}/publish`, { assessmentType })
    },
  )

  return mutate
}
