import { ForwardedRef, forwardRef, useMemo, useRef, useState } from 'react'
import { Button } from '@labourhub/labour-hub-ds'
import { ReportAssessmentStatsCountDto } from 'api/assessment/getAssessmentCountByCandidate'
import { CandidateProfileBasicDetailsType } from 'api/candidates'
import { assessmentReportPreviewData } from 'features/candidates/data/assessmentReportPreviewData'
import { assessmentTypes } from 'features/candidates/data/assessmentTypes'
import { ReportTypes } from 'features/settings/data/reportTypes'
import { ModalProps } from 'types'

import { PageLoader } from 'components/atoms'
import { Column } from 'components/atoms/Column'
import { Modal, ModalFooter } from 'components/molecules'
import AssessmentReportPreviewSection from 'components/molecules/AssesmentPreviewModal/Sections/AssessmentReportPreviewSection'
import PersonalityTestCombineReportPreviewSection from 'components/molecules/AssesmentPreviewModal/Sections/PersonalityTestCombineReportPreviewSection'
import TypingTestCombineReportPreviewSection from 'components/molecules/AssesmentPreviewModal/Sections/TypingTestCombineReportPreviewSection'

import { ReportBanner, ReportLogoHeader } from '../../sections'
import {
  CombineReportCandidateDetails,
  CombineReportCandidateDetailsDto,
} from '../../sections/ReportCandidateDetails/CombineReportCandidateDetails'

import { AssessmentSnapsPreview } from './AssessmentSnapsPreview'

import './style.scss'

type CombineReportPreviewModalProps = ModalProps & {
  candidateDetails: CandidateProfileBasicDetailsType
  stats: ReportAssessmentStatsCountDto
  previewData: assessmentReportPreviewData | undefined
  dynamicFieldMapping: any
  changeTextSchema: any
  isMappingVariablesLoading?: boolean
  reportViewData: any
  handlePrint: () => void
  resetClientOption: () => void
  //   setIsDownloadBtnClicked: React.Dispatch<React.SetStateAction<boolean>>
  isLoading: boolean
}

export const CombineReportPreviewModal = forwardRef(
  (
    {
      candidateDetails,
      stats,
      previewData,
      reportViewData,
      setIsModalActive,
      isModalActive,
      handlePrint,
      resetClientOption,
      //   setIsDownloadBtnClicked,
      isLoading,
    }: CombineReportPreviewModalProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const [isWebcamSnapsView, setIsWebcamSnapsView] = useState(false)
    const [webcamSnapsViewImages, setWebcamSnapsViewImages] = useState([])

    const modalRef = useRef<HTMLDivElement>(null)

    const candidateReportDetails = useMemo(
      () =>
        ({
          name: candidateDetails.name,
          candidateNo: candidateDetails.candidateNo,
          email: candidateDetails.email,
          phoneNumber: candidateDetails.phone,
          avatarUrl: candidateDetails.avatarUrl,
          jobTitle: candidateDetails.jobTitle,
          consultantName: candidateDetails.consultantName,
        } as CombineReportCandidateDetailsDto),
      [candidateDetails],
    )

    const getMarkingValue = (data) => ({
      correct: data.assessmentAutoMarking?.correct ?? 0,
      partiallyCorrect: data.assessmentAutoMarking?.partiallyCorrect ?? 0,
      incorrect: data.assessmentAutoMarking?.incorrect ?? 0,
      notAnswered: data.assessmentAutoMarking?.notAnswered ?? 0,
    })

    return (
      <>
        <Modal
          headerTitle='Combine Report Preview'
          isFooterShow={true}
          isActive={isModalActive}
          className='!z-[150]'
          onHeaderCloseButtonClick={() => setIsModalActive(false)}
          onOverlayClick={() => setIsModalActive(false)}
          primaryButtonProps={{ style: { marginRight: '12px' } }}
          secondaryButtonProps={{ style: { color: '#1F2937' } }}
          modalProps={{ style: { width: '800px', height: '95vh' } }}
          footerProps={{ style: { justifyContent: 'end', padding: '0 23px' } }}>
          {isLoading ? (
            <PageLoader size='xxs' />
          ) : (
            <>
              <div
                ref={modalRef}
                className='relative flex flex-col p-0 overflow-x-hidden overflow-y-auto styled-scroll bg-Gray-50'>
                <Column className='bg-Gray-50 w-[794px] pointer-events-none'>
                  <div ref={ref} className='w-full h-full p-0 m-0'>
                    {reportViewData.map((data, i) => (
                      <div key={i} className='h-auto relative'>
                        {i === 0 && (
                          <>
                            <ReportLogoHeader
                              clientlogo={previewData?.clientLogoUrl}
                              hasClientLogo={previewData?.hasClientLogo}
                              hasCompanyLogo={previewData?.hasCompanyLogo}
                            />
                            <ReportBanner type={ReportTypes.ASSESSMENT} />
                            <div className='m-3'>
                              <CombineReportCandidateDetails
                                details={candidateReportDetails}
                                stats={stats}
                                data={data}
                              />
                            </div>
                          </>
                        )}
                        {data.assessmentName !== assessmentTypes.TypingTest &&
                          data.assessmentName !==
                            assessmentTypes.PersonalityTest && (
                            <AssessmentReportPreviewSection
                              markingValue={getMarkingValue(data)}
                              reportViewData={data}
                              setIsWebcamSnapsView={setIsWebcamSnapsView}
                              setWebcamSnapsViewImages={
                                setWebcamSnapsViewImages
                              }
                              position={i + 1}
                            />
                          )}
                        {data.isAddon &&
                          data.assessmentName ===
                            assessmentTypes.TypingTest && (
                            <TypingTestCombineReportPreviewSection
                              reportViewData={data}
                              isPreview={true}
                              position={i + 1}
                            />
                          )}
                        {data.isAddon &&
                          data.assessmentName ===
                            assessmentTypes.PersonalityTest && (
                            <PersonalityTestCombineReportPreviewSection
                              reportViewData={data}
                              isPreview={true}
                              position={i + 1}
                            />
                          )}
                      </div>
                    ))}
                  </div>
                </Column>
              </div>
              <ModalFooter className='py-4 bg-Gray-50'>
                <div className='flex justify-end flex-1'>
                  <Button
                    onClick={() => setIsModalActive(false)}
                    textTheme='blue'
                    theme='white'>
                    Close
                  </Button>

                  <Button
                    onClick={() => {
                      //   setIsDownloadBtnClicked(true)
                      handlePrint()
                      resetClientOption()
                    }}
                    iconLeft={
                      <i className='flex items-center ri-download-line text-small' />
                    }
                    className='ml-2'
                    theme='cobalt'>
                    Download
                  </Button>
                </div>
              </ModalFooter>
            </>
          )}
        </Modal>

        {isWebcamSnapsView && webcamSnapsViewImages.length > 0 && (
          <AssessmentSnapsPreview
            isModalActive={isWebcamSnapsView}
            setIsModalActive={setIsWebcamSnapsView}
            candidateDetails={candidateDetails}
            previewData={previewData}
            images={webcamSnapsViewImages}
          />
        )}
      </>
    )
  },
)
