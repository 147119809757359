import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Modal, Toast } from '@labourhub/labour-hub-ds'
import useDeleteImage from 'api/assessment/deleteImage'
import { useImageUpload } from 'api/assessment/imageUpload'
import { uploadTypes } from 'features/questionnaires/data/uploadTypes'
import { AssessmentQuestion } from 'features/questionnaires/types'
import { ModalProps } from 'types'

import { CustomizableImageUpload } from './CustomizableImageUpload'

type ImageUploadModalProps = ModalProps & {
  updateQuestion: (updatedQuestion: AssessmentQuestion) => void
  question: AssessmentQuestion
}
const ImageUploadModal = ({
  isModalActive,
  setIsModalActive,
  updateQuestion,
  question,
}: ImageUploadModalProps) => {
  const notify = (props: any) => Toast(props)
  const { assessmentId } = useParams()
  const [editableQuestion, setEditableQuestion] =
    useState<AssessmentQuestion>(question)

  const [imageUrl, setImageUrl] = useState<string | null>(null)
  const handleClose = () => {
    setImageUrl(null)
    setEditableQuestion({
      ...editableQuestion,
      image: null,
      imagePath: '',
      imageUrl: '',
      uploadType: null,
    })
    setIsModalActive(false)
  }
  const fileInputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    setEditableQuestion({
      ...question,
      uploadType: uploadTypes.Image,
    })
  }, [])

  const { mutate: uploadImage, isLoading: uploadImageLoading } =
    useImageUpload()

  const { mutate: deleteImageMutate, isLoading: deleteImageIsLoading } =
    useDeleteImage()
  const handleCreate = () => {
    if (
      editableQuestion.uploadType === uploadTypes.Image &&
      editableQuestion.image
    ) {
      const assessmenttId = assessmentId ? assessmentId : ''
      const questionId = assessmentId ? editableQuestion.id.toString() : ''
      const assessmentImage = editableQuestion.image

      uploadImage(
        { assessmenttId, questionId, assessmentImage },
        {
          onSettled: async ({ data }: any) => {
            updateQuestion({
              ...question,
              imageUrl: data.assessmentImageURL,
              imagePath: data.imagePath,
              uploadType: uploadTypes.Image,
              videoLink: '',
              videoPlatform: '',
            })
            !uploadImageLoading && handleClose()
          },
          onError: () => {
            notify({
              alertHeader: 'Something went wrong',
              alertBody: '',
              status: 'Error',
            })
          },
        },
      )
    } else {
      Toast({
        alertHeader: `Please fill all input fields`,
        status: 'Warning',
      })
    }
  }

  const updateEditedQuestions = () => {
    setImageUrl(null)
    setEditableQuestion({
      ...editableQuestion,
      image: null,
      imagePath: '',
      imageUrl: '',
      uploadType: null,
      videoLink: '',
      videoPlatform: '',
    })
    updateQuestion({
      ...editableQuestion,
      image: null,
      imagePath: '',
      imageUrl: '',
      uploadType: null,
      videoLink: '',
      videoPlatform: '',
    })
  }

  const handleRemove = () => {
    if (question.imagePath) {
      deleteImageMutate(
        {
          imagePath: question.imagePath,
        },
        {
          onSuccess: ({ data: successData }: any) => {
            notify({
              alertHeader: successData.messageTitle
                ? successData.messageTitle
                : 'Image was removed!',
              alertBody: '',
              status: 'Success',
            })
            updateQuestion({
              ...question,
              imageUrl: successData.assessmentImageURL,
              imagePath: successData.imagePath,
              uploadType: uploadTypes.Image,
              videoLink: '',
              videoPlatform: '',
            })
            !deleteImageIsLoading && handleClose()
          },
          onError: ({ response: { data: errData } }: any) => {
            notify({
              alertHeader: errData?.messageTitle
                ? errData?.messageTitle
                : 'Error!',
              alertBody: errData?.message ? errData?.message : 'Error!',
              status: 'Error',
            })
          },
        },
      )
    } else handleClose()
  }

  const handleReplace = () => {
    if (fileInputRef.current) {
      if (question.imagePath) {
        deleteImageMutate(
          {
            imagePath: question.imagePath,
          },
          {
            onSuccess: ({ data: successData }: any) => {
              notify({
                alertHeader: successData.messageTitle
                  ? successData.messageTitle
                  : 'Image was removed!',
                alertBody: '',
                status: 'Success',
              })

              updateQuestion({
                ...question,
                imageUrl: successData.assessmentImageURL,
                imagePath: successData.imagePath,
                uploadType: uploadTypes.Image,
                videoLink: '',
                videoPlatform: '',
              })
            },
            onError: ({ response: { data: errData } }: any) => {
              notify({
                alertHeader: errData?.messageTitle
                  ? errData?.messageTitle
                  : 'Error!',
                alertBody: errData?.message ? errData?.message : 'Error!',
                status: 'Error',
              })
            },
          },
        )
      } else {
        updateEditedQuestions()
      }
      fileInputRef.current.click()
    }
  }
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
      setImageUrl(URL.createObjectURL(file))
      setEditableQuestion({
        ...editableQuestion,
        image: file,
        uploadType: uploadTypes.Image,
      })
      updateQuestion({
        ...question,
        image: file,
        uploadType: uploadTypes.Image,
      })

      // Reset file input value to allow re-selecting the same file
      event.target.value = ''
    }
  }
  return (
    <>
      <Modal
        isActive={isModalActive}
        onOverlayClick={handleClose}
        modalProps={{
          style: { width: '520px' },
        }}
        onClickPrimaryBtn={question.imageUrl ? handleRemove : handleCreate}
        onClickSecondaryBtn={handleClose}
        onHeaderCloseButtonClick={handleClose}
        primaryButtonProps={{
          style: { marginLeft: '12px' },
          isLoading: uploadImageLoading || deleteImageIsLoading,
          iconLeft: question.imageUrl ? (
            <i className='ri-delete-bin-line text-white'></i>
          ) : undefined,
        }}
        secondaryButtonProps={{ textTheme: 'black' }}
        footerProps={{
          style: {
            justifyContent: 'flex-start',
            flexDirection: 'row-reverse',
          },
        }}
        headerTitle={`${question.imageUrl ? 'Edit an Image' : 'Add an Image'}`}
        primaryButtonTitle={`${question.imageUrl ? 'Remove Image' : 'Add'}`}
        primaryButtonTheme={`${question.imageUrl ? 'red' : 'cobalt'}`}
        secondaryButtonTitle='Cancel'>
        <div
          className={`w-full ${
            editableQuestion.image ? 'h-[300px]' : 'h-[220px]'
          } px-6 pt-3 pb-5`}>
          <span className='font-Medium text-Gray-800 text-base'>Image</span>

          <CustomizableImageUpload
            isRectangleLogo={true}
            setImageUrl={setImageUrl}
            imageUrl={imageUrl}
            dropzoneDescription='Drag and drop your image here (PNG or Jpeg)
To get the best results, make sure your image width and height should be 595 * 190px'
            imageLink={question?.imageUrl}
            imageFile={editableQuestion.image}
            setImageFile={(imageFile: any) => {
              setEditableQuestion({
                ...editableQuestion,
                image: imageFile,
                uploadType: uploadTypes.Image,
              })
            }}
          />
          {(imageUrl || question.imageUrl) && (
            <div className='flex justify-start w-full'>
              <button
                className='text-[#4191DC] flex gap-2 focus:border-0 outline-none'
                onClick={handleReplace}>
                <i className='ri-refresh-line'></i>
                <span>Replace Image</span>
              </button>
            </div>
          )}
          <input
            type='file'
            ref={fileInputRef}
            accept='image/jpeg,image/png,image/jpg'
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
        </div>
      </Modal>
    </>
  )
}

export default ImageUploadModal
