import { useEffect, useRef, useState } from 'react'
import { Badge } from '@labourhub/labour-hub-ds'
import CN from 'classnames'

import { Card } from 'components/atoms/Card'

type CandidateStageProps = {
  reportViewData: any
  onChange: (stage: string) => void
}

const CandidateStage = ({ reportViewData, onChange }: CandidateStageProps) => {
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const status = reportViewData.candidateStage

  // Determine badge text based on candidate stage
  const badgeTextMap: Record<string, string> = {
    RESPONSE_RECEVIED: 'Received',
    AWAITING_RESPONSE: 'Awaiting',
    RESPONSE_IN_REVIEW: 'In Review',
    RESPONSE_EVALUATED: 'Evaluated',
    REQUEST_DECLINED: 'Declined',
  }
  const [badgeText, setBadgeText] = useState(badgeTextMap[status] || 'Not Sent')

  // Determine badge theme based on candidate stage
  const badgeThemeMap: Record<string, string> = {
    RESPONSE_RECEVIED: 'green',
    AWAITING_RESPONSE: 'yellow',
    RESPONSE_IN_REVIEW: 'yellow',
    RESPONSE_EVALUATED: 'blue',
    REQUEST_DECLINED: 'red',
  }
  const [badgeTheme, setBadgeTheme] = useState(badgeThemeMap[status] || 'gray')

  const handleButtonClick = (text: string, stage: string) => {
    setBadgeText(text)
    setBadgeTheme(badgeThemeMap[stage] || 'gray')
    setDropdownVisible(false)
    onChange(stage)
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target as Node)
    ) {
      setDropdownVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <Card className='relative h-[35%]'>
      <div className='flex items-center justify-between'>
        <div className='text-heading-4 font-SemiBold'>Candidate Stage</div>
        <div className='flex gap-3 relative'>
          <Badge size='large' theme={badgeTheme} className='w-20'>
            <div className='text-extra-small font-Medium leading-3'>
              {badgeText}
            </div>
          </Badge>
          <div>
            <button
              ref={buttonRef}
              onClick={() => setDropdownVisible(!dropdownVisible)}
              className='focus:outline-none'>
              <i
                className={CN(
                  'text-[16px] ri-pencil-line mr-1',
                  dropdownVisible ? 'text-[#09121F]' : 'text-Cobalt-500',
                )}
              />
            </button>
            {dropdownVisible && (
              <div
                ref={dropdownRef}
                className={CN(
                  'absolute z-40 bg-white shadow-md border-[2px] border-Gray-100 rounded-md mt-1 select-none',
                  'w-[120px]',
                  'left-0',
                )}
                style={{ top: buttonRef.current?.offsetHeight || 0 }}>
                {Object.entries(badgeTextMap)
                  .filter(([key]) =>
                    [
                      'RESPONSE_RECEVIED',
                      'RESPONSE_IN_REVIEW',
                      'RESPONSE_EVALUATED',
                    ].includes(key),
                  ) // Filter for specific keys
                  .map(([key, text]) => (
                    <button
                      key={key}
                      className='block py-2 px-3 cursor-pointer select-none text-black hover:bg-Gray-100 w-full text-left'
                      onClick={() => handleButtonClick(text, key)}>
                      {text}
                    </button>
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </Card>
  )
}

export default CandidateStage
