import { useEffect, useState } from 'react'
import { Checkbox } from '@labourhub/labour-hub-ds'
import { ReferenceCheckType } from 'types'

import { ReferenceTypeLabel } from 'components/atoms'
import { cn } from 'utils'

type RefereeSelectionListProps = {
  referees: any
  setSelectedReferees: any
}

export const RefereeSelectionList = ({
  referees,
  setSelectedReferees,
}: RefereeSelectionListProps) => {
  const [checkedState, setCheckedState] = useState(
    new Array(referees.length).fill(false),
  )

  const handleCheckboxChange = (idx: number) => {
    const newCheckedState = [...checkedState]
    newCheckedState[idx] = !newCheckedState[idx]
    setCheckedState(newCheckedState)
  }

  useEffect(() => {
    const selectedReferees = referees.filter(
      (_referee: any, idx: number) => checkedState[idx],
    )
    setSelectedReferees(selectedReferees)
  }, [checkedState, referees])

  return (
    <div className='recipients-card-container flex flex-col gap-y-2 max-h-[330px] overflow-y-auto styled-scroll mt-4'>
      {referees?.map((referee: any, idx: number) => (
        <div
          className={cn('recipients-card rounded-lg py-2 px-3 relative', {
            'bg-white border border-Gray-100': !referee.checked,
            'bg-Cobalt-50 border border-Cobalt-100': referee.checked,
          })}
          key={idx}>
          <Checkbox
            key={idx}
            id={`custom-checkbox-${idx}`}
            checked={checkedState[idx]}
            onChange={() => handleCheckboxChange(idx)}
            labelText={`${referee?.firstName} ${referee?.lastName}`}
            labelTextClassName='font-Regular line-clamp-1'
            checkboxClassName='ml-0'
          />

          <div className='flex flex-col space-y-1 ml-7 text-extra-small font-Regular text-Gray-500'>
            <ReferenceTypeLabel type={referee?.referenceType?.type} />

            <span className='leading-[18px] line-clamp-1 break-all'>
              {referee?.email}
            </span>

            {referee?.referenceType?.type !=
              ReferenceCheckType.FriendFamily && (
              <span className='break-all line-clamp-1'>{referee?.company}</span>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}
