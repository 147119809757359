import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import {
  Alerts,
  Button,
  ProgressBar,
  TextField,
} from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { useGetInvitedUserDetails, useSignUpUser } from 'framework/api/methods'

import { PhoneInputField } from 'components/atoms'
import { isAlphaNumeric, isPhoneNumber, passwordStrengthCalculate } from 'utils'

export const OnboardingUser = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [showPasswordForm, setShowPasswordForm] = useState(false)

  const [alertDetails, setAlertDetails] = useState({
    isActive: false,
    messageHeader: '',
    messageBody: '',
  })

  const [routeData, setRouteData] = useState<any>({
    userName: '',
    emailToken: '',
  })

  /** state for sign up form */
  const [isSubmitButtonDisable, seIsSubmitButtonDisable] = useState(false)
  const [addUserDetails, setAddUserDetails] = useState<any>({
    fullName: '',
    displayName: '',
    jobTitle: '',
    phoneNumber: '',
  })
  const [isErrorField, setIsErrorField] = useState<any>({
    fullName: false,
    displayName: false,
    jobTitle: false,
    phoneNumber: false,
  })

  /** state for password form */
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [showProgressBar, setShowProgressBar] = useState(false)
  const [passwordStrength, setPasswordStrength] = useState(0)
  const [passwordDetails, setPasswordDetails] = useState<any>({
    newPassword: '',
    confirmPassword: '',
  })
  const [isErrorPasswordField, setIsErrorPasswordField] = useState<any>({
    newPassword: false,
    confirmPassword: false,
  })
  const [showConfirmPasswordToolTip, setShowConfirmPasswordToolTip] =
    useState(false)

  /** get email token and user name from route params */
  useEffect(() => {
    const userName: any = searchParams.get('userName')
    const emailToken: any = searchParams.get('emailToken')
    if (
      userName == null ||
      userName == '' ||
      emailToken == null ||
      emailToken == ''
    ) {
      navigate('/')
    }
    setRouteData({
      ...routeData,
      userName: userName,
      emailToken: emailToken,
    })
    /** get invited user details */
    getInvitedUserDetails(userName, emailToken)
  }, [searchParams])

  /** Get invited user details API Call ------> Mutate */
  const { mutate: getInvitedUserDetailsMutate } = useGetInvitedUserDetails()

  /** Process the get invited user details */
  async function getInvitedUserDetails(userName: any, emailToken: any) {
    getInvitedUserDetailsMutate(
      {
        userName,
        emailToken,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          setAddUserDetails({
            ...addUserDetails,
            fullName: successData?.onBoardUser?.fullName,
            displayName: successData?.onBoardUser?.displayName,
            jobTitle: successData?.onBoardUser?.jobTitle,
            phoneNumber: successData?.onBoardUser?.phoneNumber,
          })
        },
        onError: ({ response: { data: errData } }: any) => {
          setAlertDetails({
            ...alertDetails,
            isActive: true,
            messageHeader: errData?.messageTitle
              ? errData?.messageTitle
              : 'Error!',
            messageBody: errData?.message,
          })
          seIsSubmitButtonDisable(true)
        },
      },
    )
  }

  /** validate input fields */
  const validateSignUpFormInputs = () => {
    const { fullName, displayName, jobTitle, phoneNumber } = addUserDetails

    setIsErrorField({
      ...isErrorField,
      fullName: !isAlphaNumeric(fullName),
      displayName: !displayName,
      jobTitle: !jobTitle,
      phoneNumber: !isPhoneNumber(phoneNumber),
    })

    const isAllValidFields =
      isAlphaNumeric(fullName) &&
      displayName &&
      jobTitle &&
      isPhoneNumber(phoneNumber)

    return isAllValidFields
  }

  /** handle sign up form submit button */
  const handleSignUpUserSubmit = () => {
    const valid = validateSignUpFormInputs()
    /** check valid field */
    if (valid) {
      setShowPasswordForm(true)
    } else {
      /** set error messages according to invalid fields */
      setAlertDetails({ ...alertDetails, isActive: true })
      if (
        !isAlphaNumeric(addUserDetails.fullName) &&
        addUserDetails.fullName !== ''
      ) {
        setAlertDetails({
          ...alertDetails,
          messageHeader: 'Invalid input',
          messageBody: 'The field contains invalid input',
        })
      } else {
        setAlertDetails({
          ...alertDetails,
          messageHeader: 'Empty field',
          messageBody: 'You cannot leave any fields empty.',
        })
      }
    }
  }

  /** validate password fields */
  const validatePasswordInputs = () => {
    const { newPassword, confirmPassword } = passwordDetails

    setIsErrorPasswordField({
      ...isErrorPasswordField,
      newPassword: newPassword ? false : true,
      confirmPassword: confirmPassword ? false : true,
    })
    const isAllValidFields = newPassword && confirmPassword

    return isAllValidFields
  }

  /** handle password form submit button */
  const handlePasswordFormSubmit = () => {
    const valid = validatePasswordInputs()
    if (valid) {
      if (passwordDetails.newPassword === passwordDetails.confirmPassword) {
        const addNewUserObject = {
          userName: routeData.userName,
          emailToken: routeData.emailToken,
          displayName: addUserDetails.displayName,
          fullName: addUserDetails.fullName,
          jobTitle: addUserDetails.jobTitle,
          phoneNumber: addUserDetails.phoneNumber,
          password: passwordDetails.newPassword,
        }
        signUpUser(addNewUserObject)
      } else {
        setShowConfirmPasswordToolTip(true)
        setAlertDetails({
          ...alertDetails,
          isActive: true,
          messageHeader: 'Password mismatch',
          messageBody:
            'Please make sure you have entered the same password in both textboxes.',
        })
      }
    } else {
      setAlertDetails({
        ...alertDetails,
        isActive: true,
        messageHeader: 'Invalid password',
        messageBody: 'Password doesn’t meet all needed requirements.',
      })
    }
  }

  /** Sign Up User API Call ------> Mutate */
  const { mutate: signUpMutate, isLoading: signUpIsLoading } = useSignUpUser()

  /** Process the sign up user submission */
  async function signUpUser(newUserObject: any) {
    signUpMutate(newUserObject, {
      onSuccess: () => {
        navigate('/auth/login', { replace: true })
      },
      onError: ({ response: { data: errData } }: any) => {
        setAlertDetails({
          ...alertDetails,
          isActive: true,
          messageHeader: errData?.messageTitle
            ? errData?.messageTitle
            : 'Error!',
          messageBody: errData?.message,
        })
      },
    })
  }

  return (
    <div className='flex flex-col w-[380px] mt-12 pb-8'>
      <div className='sign-up-form w-full justify-center items-center relative'>
        <span className='flex justify-center items-center text-heading-2 font-Medium text-Gray-800'>
          {!showPasswordForm
            ? `Confirm your details`
            : `Please create a password`}
        </span>

        <span className='flex justify-center items-center text-center text-small font-Regular text-Gray-700 pt-3'>
          {!showPasswordForm
            ? `These details were given by the host who invited you. Please make any
            changes if necessary.`
            : `Include at least 8 characters in your password to keep your account secured.`}
        </span>
        {!showPasswordForm ? (
          <div className='sign-up-form-container flex flex-col w-full'>
            {/* full name input field */}
            <TextField
              label='Full name'
              value={addUserDetails.fullName}
              onChange={(e: any) => {
                setAlertDetails({
                  ...alertDetails,
                  isActive: false,
                })
                setAddUserDetails({
                  ...addUserDetails,
                  fullName: e.target.value,
                })
              }}
              isErrored={isErrorField.fullName}
              className='pt-10'
              placeholder='Jane Doe'
            />

            {/* display name input field with toot tip */}
            <div className='w-full relative'>
              <TextField
                label='Display name'
                value={addUserDetails.displayName}
                onChange={(e: any) => {
                  setAlertDetails({
                    ...alertDetails,
                    isActive: false,
                  })
                  setAddUserDetails({
                    ...addUserDetails,
                    displayName: e.target.value,
                  })
                }}
                isErrored={isErrorField.displayName}
                className='pt-6'
                placeholder='Jane'
              />

              <i
                className='ri-question-line text-[22px] absolute bottom-[5px] -right-[35px] text-Gray-400'
                data-tip='This is how other users would identify you.'>
                <ReactTooltip
                  place='right'
                  effect='solid'
                  textColor='#E5E7EB'
                  backgroundColor='#1F2937'
                />
              </i>
            </div>

            {/* job title input field */}
            <TextField
              label='Job title'
              value={addUserDetails.jobTitle}
              onChange={(e: any) => {
                setAlertDetails({
                  ...alertDetails,
                  isActive: false,
                })
                setAddUserDetails({
                  ...addUserDetails,
                  jobTitle: e.target.value,
                })
              }}
              isErrored={isErrorField.jobTitle}
              className='pt-6'
              placeholder={`Sales & Marketing`}
            />

            {/* international flag phone number  */}
            <PhoneInputField
              label='Phone number'
              value={addUserDetails.phoneNumber}
              onChange={(number: any) => {
                setAlertDetails({
                  ...alertDetails,
                  isActive: false,
                })
                setAddUserDetails({
                  ...addUserDetails,
                  phoneNumber: number,
                })
              }}
              isErrored={isErrorField.phoneNumber}
              className='pt-6'
            />

            {/* sign up form submit button */}
            <Button
              isBlock
              onClick={handleSignUpUserSubmit}
              isDisabled={isSubmitButtonDisable}
              className='mt-6'
              size='md'>
              Confirm
            </Button>
          </div>
        ) : (
          <div className='password-form-container flex flex-col w-full pt-10'>
            {/* new password field */}
            <div
              className={CN('w-full relative', {
                'pb-6': passwordDetails.newPassword !== '',
                'pb-[14px]': passwordDetails.newPassword === '',
              })}>
              {/* text field */}
              <TextField
                type={showNewPassword ? 'text' : 'password'}
                label='New password'
                placeholder='••••••••'
                className='pb-[10px]'
                value={passwordDetails.newPassword}
                isErrored={isErrorPasswordField.newPassword}
                iconAfter={
                  passwordDetails.newPassword !== '' ? (
                    <i
                      className={CN('hover:cursor-pointer', {
                        'ri-eye-off-line': showNewPassword == false,
                        'ri-eye-line': showNewPassword == true,
                      })}
                    />
                  ) : (
                    <></>
                  )
                }
                iconAfterOnClick={() => {
                  setShowNewPassword(!showNewPassword)
                }}
                onChange={(e: any) => {
                  setAlertDetails({
                    ...alertDetails,
                    isActive: false,
                  })
                  const value = e.target.value
                  setPasswordDetails({ ...passwordDetails, newPassword: value })

                  /** Set password strength bar visibility according to new password field value. */
                  if (value !== '') {
                    setShowProgressBar(true)
                    setPasswordStrength(passwordStrengthCalculate(value))
                  } else {
                    setShowProgressBar(false)
                  }
                }}
              />

              {/* password strength bar */}
              {showProgressBar && (
                <ProgressBar progressCount={passwordStrength} isForPassword />
              )}

              {/* tool tip */}
              <i
                className='ri-question-line text-[22px] absolute top-[26px] -right-[32px] text-Gray-400'
                data-tip='toot-tip'
                data-for='new-password-tooltip'>
                <ReactTooltip
                  id='new-password-tooltip'
                  place='right'
                  effect='solid'
                  textColor='#E5E7EB'
                  backgroundColor='#1F2937'>
                  <div className='w-[380px] text-small'>
                    <span className='font-SemiBold'>
                      Choose a strong password to keep your account secured
                    </span>
                    <ul className='mt-4 list-disc'>
                      <li>
                        It should be mix of capital and simple letters, numbers
                        and special characters.
                      </li>
                      <li className='mt-[10px]'>
                        It should be at least 8 characters long.
                      </li>
                      <li className='mt-[10px]'>
                        Please make sure not to provide your name or phone
                        number as password.
                      </li>
                    </ul>
                  </div>
                </ReactTooltip>
              </i>
            </div>

            {/* Confirm password form field */}
            <div className='w-full relative'>
              <TextField
                type={showConfirmPassword ? 'text' : 'password'}
                label='Confirm password'
                placeholder='••••••••'
                value={passwordDetails.confirmPassword}
                isErrored={isErrorPasswordField.confirmPassword}
                iconAfter={
                  passwordDetails.confirmPassword !== '' ? (
                    <i
                      className={CN('hover:cursor-pointer', {
                        'ri-eye-off-line': showConfirmPassword == false,
                        'ri-eye-line': showConfirmPassword == true,
                      })}
                    />
                  ) : (
                    <></>
                  )
                }
                iconAfterOnClick={() => {
                  setShowConfirmPassword(!showConfirmPassword)
                }}
                onChange={(e: any) => {
                  setAlertDetails({
                    ...alertDetails,
                    isActive: false,
                  })
                  setShowConfirmPasswordToolTip(false)
                  setPasswordDetails({
                    ...passwordDetails,
                    confirmPassword: e.target.value,
                  })
                }}
              />

              {/* tool tip */}
              {showConfirmPasswordToolTip && (
                <i
                  className='ri-error-warning-line text-[22px] absolute top-[26px] -right-[32px] text-Red-500'
                  data-tip='tool-tip-2'
                  data-for='confirm-password-tooltip'>
                  <ReactTooltip
                    id='confirm-password-tooltip'
                    place='right'
                    effect='solid'
                    textColor='#E5E7EB'
                    backgroundColor='#1F2937'>
                    <div className='flex flex-col font-Medium text-small'>
                      <span>
                        Password does not match with the previous entry.
                      </span>
                      <span>Please try again.</span>
                    </div>
                  </ReactTooltip>
                </i>
              )}
            </div>

            {/* password form sign up button */}
            <Button
              isBlock
              isDisabled={
                passwordStrength < 81 ||
                passwordDetails.newPassword === routeData.fullName ||
                passwordDetails.newPassword === addUserDetails.phoneNumber
              }
              onClick={handlePasswordFormSubmit}
              className='mt-6'
              size='md'
              isLoading={signUpIsLoading}>
              Confirm
            </Button>
          </div>
        )}

        {/* error alert */}
        {alertDetails.isActive && (
          <Alerts
            alertBody={alertDetails.messageBody}
            alertHeader={alertDetails.messageHeader}
            className={CN('absolute', {
              'bottom-[-100px]': !showPasswordForm,
              'bottom-[-150px]': showPasswordForm,
            })}
            onCloseIconClick={() => {
              setAlertDetails({
                ...alertDetails,
                isActive: false,
              })
            }}
            status='Error'
          />
        )}
      </div>
    </div>
  )
}
