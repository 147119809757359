/* eslint-disable @typescript-eslint/no-unused-vars */

import { PaymentCardDto } from 'api/payments'

import { QuantityDataType } from '../types'

import {
  setCardsState,
  setPayAsYouGoCreditsModalActiveState,
  setPayAsYouGoCreditsState,
  toggleIsRefetchBillingHistoryState,
  toggleIsRefetchPaymentCardsState,
} from './reducer'

export const resetPayAsYouGoCredits = () => (dispatch: any) => {
  dispatch(setPayAsYouGoCreditsModalActiveState(false))
}

export const setPayAsYouGoCreditsModalActive =
  (value: boolean, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setPayAsYouGoCreditsModalActiveState(value))
  }

export const toggleIsRefetchBillingHistory = () => (dispatch: any) => {
  dispatch(toggleIsRefetchBillingHistoryState())
}

export const toggleIsRefetchPaymentCards = () => (dispatch: any) => {
  dispatch(toggleIsRefetchPaymentCardsState())
}

export const setPayAsYouGoCredits =
  (value: QuantityDataType, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setPayAsYouGoCreditsState(value))
  }

export const setCards =
  (value: PaymentCardDto[], cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setCardsState(value))
  }
