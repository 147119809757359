import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

type GetAssessmentCandidateProps = {
  assessmentId: string
  search: string
  status: string
  consultant: string
}

const getAssessmentCandidates = async ({ queryKey }: any) => {
  const { assessmentId, search, status, consultant } =
    queryKey[1] as GetAssessmentCandidateProps

  let URI = `/assessment/candidate-list-bulk-send?AssessmentId=${assessmentId}&Take=${1000}&Skip=${0}`
  if (search) {
    URI += `&Search=${search}`
  }
  if (consultant) {
    URI += `&ConsultantId=${consultant}`
  }
  if (status) {
    URI += `&Status=${status}`
  }

  const res = await get(URI)
  return res.data
}

export const useGetAssessmentCandidates = (
  { ...reqBody },
  onSuccess: any,
  onError: any,
) => {
  return useQuery(['AssessmentCandidates', reqBody], getAssessmentCandidates, {
    enabled: true,
    refetchOnWindowFocus: false,
    onSuccess,
    onError,
  })
}
