import { forwardRef, useMemo } from 'react'
import { useSelector } from 'react-redux'
// import { Badge } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { bonusCreditMap } from 'features/onboarding/data'
import {
  FixedSubscriptionPackageDto,
  getSubscriptionPackages,
  SubscriptionPlanType,
  SubscriptionType,
} from 'features/subscriptions'
import { SubscriptionPlanPackageBadge } from 'features/subscriptions/components/SubscriptionPlanPackageBadge'

import { formatCurrency } from 'utils'
import { isBlackFriday } from 'utils/offers'

import './PackageCard.scss'

type PackageCardProps = {
  id?: string | undefined
  defaultChecked?: boolean
  onRadioClick?: () => void
  onChange?: any | undefined
  name?: string | undefined
  isSelected: boolean
  packageName: string | undefined
  packagePeriod: SubscriptionType
}

export const PackageCard = forwardRef<HTMLInputElement, PackageCardProps>(
  (
    {
      id,
      onRadioClick,
      defaultChecked,
      onChange,
      name,
      isSelected = false,
      packageName,
      packagePeriod,
      ...restProps
    }: PackageCardProps,
    ref,
  ) => {
    const { fixedMonthlyPackages, fixedYearlyPackages } = useSelector(
      getSubscriptionPackages,
    )

    const packageItem = useMemo(() => {
      const packageDto =
        packagePeriod === SubscriptionType.Monthly
          ? fixedMonthlyPackages?.find(
              (item: FixedSubscriptionPackageDto) =>
                item.subscriptionType === SubscriptionType.Monthly &&
                packageName == item.name,
            )
          : fixedYearlyPackages?.find(
              (item: FixedSubscriptionPackageDto) =>
                item.subscriptionType === SubscriptionType.Yearly &&
                packageName == item.name,
            )

      const packageDiscount = packageDto?.promotionDiscountRate ?? 0
      const baseAmount = packageDto?.packageAmount ?? 0
      const discountedAmount =
        baseAmount > 0 && packageDiscount > 0
          ? baseAmount * (1 - packageDiscount)
          : baseAmount

      const unitAmount = packageDto
        ? discountedAmount /
          (packageDto.subscriptionType === SubscriptionType.Yearly
            ? packageDto.referenceCheckLimit * 12
            : packageDto.referenceCheckLimit)
        : 0

      const refCheckLimit = packageDto?.referenceCheckLimit ?? 0

      const bonusCredit = packageDto
        ? bonusCreditMap[packageDto?.name][packageDto?.subscriptionType]
        : 3

      return {
        packageDiscount,
        baseAmount,
        unitAmount,
        discountedAmount,
        refCheckLimit,
        hasDiscount: packageDiscount > 0,
        bonusCredit,
      }
    }, [packagePeriod, packageName, fixedMonthlyPackages, fixedYearlyPackages])

    const PackageTypeCardClassName = CN(
      'flex border-[2px] cursor-pointer p-4 rounded-lg w-full justify-between',
      {
        'bg-Cobalt-700 border-Cobalt-700': isSelected,
        'bg-white border-Gray-300': !isSelected,
      },
    )

    const RadioClassName = CN(
      'form-check-input appearance-none rounded-full h-4 w-4  bg-white border border-Gray-300 checked:bg-Cobalt-600 checked:border-white checked:border-[5px] active:bg-Gray-400 focus:outline-none focus:ring focus:ring-Cobalt-300 transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer',
      {
        'hover:bg-Gray-300 ': !isSelected,
      },
    )

    return (
      <div className='relative flex w-full'>
        {packageName != SubscriptionPlanType.PAYG && packageItem.hasDiscount ? (
          <div
            className={CN('onboadring-ribbon pl-20', {
              'ribbon-yellow': packagePeriod == SubscriptionType.Yearly,
              'ribbon-orange': packagePeriod == SubscriptionType.Monthly,
            })}>
            <span>{packageItem.packageDiscount * 100}% OFF</span>
          </div>
        ) : isBlackFriday() && packageName == SubscriptionPlanType.PAYG ? (
          <div className={CN('onboadring-ribbon pl-20 ribbon-orange')}>
            <span>20 % OFF</span>
          </div>
        ) : (
          <></>
        )}
        <label htmlFor={id} className={PackageTypeCardClassName}>
          <div
            className={CN('flex right-side', {
              'w-[63%]': packageItem.hasDiscount,
              'w-[67%]': !packageItem.hasDiscount,
            })}>
            <input
              id={id}
              onChange={onChange}
              ref={ref}
              type='radio'
              onClick={onRadioClick}
              className={RadioClassName}
              defaultChecked={defaultChecked}
              name={name}
              {...restProps}
            />

            <div
              className={CN('ml-3 flex flex-col', {
                'text-white': isSelected,
                'text-Gray-900': !isSelected,
              })}>
              <div className='flex items-center justify-start w-fit'>
                <SubscriptionPlanPackageBadge
                  packageName={
                    packageName === SubscriptionPlanType.PAYG
                      ? SubscriptionPlanType.PAYG
                      : packageName === SubscriptionPlanType.TwentyfivePack
                      ? SubscriptionPlanType.TwentyfivePack
                      : packageName === SubscriptionPlanType.FifteenPack
                      ? SubscriptionPlanType.FifteenPack
                      : SubscriptionPlanType.FiftyPack
                  }
                  size='small'
                  isFill={true}
                />
              </div>

              <ul className='mt-2 font-Medium text-small'>
                {packageName == SubscriptionPlanType.PAYG ? (
                  <>
                    <li className='flex items-center justify-start mb-[3px] gap-x-1 font-Regular'>
                      <i className='ri-check-fill'></i>
                      <span>Unlimited users</span>
                    </li>
                    <li className='flex items-center justify-start mb-[3px] gap-x-1 font-Regular'>
                      <i className='ri-check-fill'></i>
                      <span>Unlimited AI templates</span>
                    </li>
                    <li className='flex items-center justify-start mb-[3px] gap-x-1 font-Regular'>
                      <i className='ri-check-fill'></i>
                      <span>
                        <b>3</b> Bonus Credits for new users
                      </span>
                    </li>
                  </>
                ) : (
                  <>
                    <li className='flex items-center justify-start mb-[3px] gap-x-1 font-Regular'>
                      <i className='ri-check-fill'></i>
                      <span>
                        <b>
                          {packageItem.hasDiscount ? (
                            <>
                              <del className='text-Red-600'>
                                {formatCurrency(packageItem.baseAmount)}
                              </del>{' '}
                              <span>
                                {formatCurrency(packageItem.discountedAmount)}
                              </span>{' '}
                            </>
                          ) : (
                            <span>
                              {formatCurrency(packageItem.baseAmount)}
                            </span>
                          )}

                          {packagePeriod === SubscriptionType.Monthly
                            ? ` Per Month`
                            : ` Per Year`}
                        </b>{' '}
                        Ex Gst
                      </span>{' '}
                    </li>
                    <li className='flex items-center justify-start mb-[3px] gap-x-1 font-Regular'>
                      <i className='ri-check-fill'></i>
                      <span>
                        <b>{packageItem.refCheckLimit}</b> Credits Per Month
                      </span>{' '}
                    </li>
                    <li className='flex items-center justify-start mb-[3px] gap-x-1 font-Regular'>
                      <i className='ri-check-fill'></i>
                      <span>
                        <b>{packageItem.bonusCredit}</b> Bonus Credits for new
                        users
                      </span>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>

          <div
            className={CN(
              'flex flex-col',
              packageItem.hasDiscount ? 'w-[37%]' : 'w-[33%]',
            )}>
            {packageItem.unitAmount > 0 && (
              <div
                className={CN(
                  'flex flex-col justify-start items-start w-fit ',
                  {
                    'text-white': isSelected,
                    'text-[#105DA4]': !isSelected,
                  },
                )}>
                <div className='font-SemiBold text-heading-1'>
                  ${' '}
                  {packageItem.unitAmount.toFixed(
                    packagePeriod === SubscriptionType.Monthly
                      ? packageItem.hasDiscount
                        ? 1
                        : 0
                      : 2,
                  )}
                </div>
                <div className='font-Regular text-small'>Per Credit</div>
              </div>
            )}
          </div>
        </label>
      </div>
    )
  },
)
