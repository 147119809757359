import { FC } from 'react'
import LogoMini from 'assets/images/refHubLogoText.svg'

import { Footer } from 'components/common/Footer'

export type VerifyLayoutProps = {
  className?: string | undefined
  [x: string]: any
}

export const VerifyLayout: FC<VerifyLayoutProps> = ({
  children,
}: VerifyLayoutProps) => {
  return (
    <div className='fixed top-0 left-0 w-full h-full z-50 bg-white'>
      <div className='w-full justify-center items-center py-4 shadow-sm md:mb-[120px] mb-[86px]'>
        <img
          src={LogoMini}
          alt=''
          className='flex w-full justify-center items-center h-[25px] px-4'
        />
      </div>

      {children}

      <Footer />
    </div>
  )
}

VerifyLayout.defaultProps = {
  className: undefined,
  isFooterShown: true,
}

export default VerifyLayout
