import { ReactNode } from 'react'
import CN from 'classnames'

type ColumnProps = {
  children: ReactNode
  className?: string
  itemAlignment?: 'start' | 'end' | 'center'
  justifyAlignment?:
    | 'start'
    | 'end'
    | 'center'
    | 'between'
    | 'around'
    | 'evenly'
}

export const Column = ({ children, className }: Partial<ColumnProps>) => {
  return <div className={CN('flex flex-col', className)}>{children}</div>
}

export const ColumnWithAlignment = ({
  children,
  className,
  itemAlignment = 'center',
  justifyAlignment = 'between',
}: ColumnProps) => {
  return (
    <div
      className={CN(
        'flex flex-col',
        `items-${itemAlignment}`,
        `justify-${justifyAlignment}`,
        className,
      )}>
      {children}
    </div>
  )
}
