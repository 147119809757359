/* eslint-disable no-empty-pattern */
import { useQuery } from 'react-query'
import { postAPI } from 'framework/api/http'

const userDetails = async () => {
  const res = await postAPI('/identity/token_info', {})
  return res.data
}

export const useUserDetails = ({}, onSuccess: any, onError: any) => {
  return useQuery(['userData'], userDetails, {
    refetchOnWindowFocus: false,
    retry: 1,
    enabled: false,
    onSuccess,
    onError,
  })
}

export default useUserDetails
