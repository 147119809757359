/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FC } from 'react'
import {
  CallIcon,
  CandidateIcon,
  FeedBackIcon,
  NoteIcon,
  PhoneRefIcon,
  RefereeIcon,
} from 'assets/customIcons'
import CN from 'classnames'

import { timeToLocalTimeZoneWithISOFormat } from 'utils'
import { timeDifference } from 'utils/timeDifference'

type NotificationCardProps = {
  type?:
    | 'Basic'
    | 'Candidate'
    | 'Basic3'
    | 'Phone Ref'
    | 'Referee'
    | 'CandidateEligibility'
    | 'Note'
  isRead?: boolean
  title?: string
  description?: string
  createdOn?: string
  onClick?: () => void
  [x: string]: any
}

export const NotificationCard: FC<NotificationCardProps> = ({
  className,
  type,
  isRead,
  title,
  description,
  createdOn,
  onClick,
  ...restProps
}: NotificationCardProps) => {
  const notificationCardMainClassName = CN(
    'flex w-full pl-4 pr-[48px] py-3 bg-white hover:bg-Gray-100 cursor-pointer relative',
    className,
  )

  return (
    <div
      onClick={() => {
        onClick && onClick()
      }}
      className={notificationCardMainClassName}
      {...restProps}>
      <div className='flex justify-center pr-3'>
        {type === 'Basic' && <CallIcon />}
        {type === 'Candidate' && <CandidateIcon />}
        {type === 'CandidateEligibility' && <CandidateIcon />}
        {type === 'Basic3' && <FeedBackIcon />}
        {type === 'Phone Ref' && <PhoneRefIcon />}
        {type === 'Referee' && <RefereeIcon />}
        {type === 'Note' && <NoteIcon />}
      </div>
      <div>
        {title && (
          <p className='text-small font-Medium text-Gray-800'>
            {title.replaceAll('(', '').replaceAll(')', '')}
          </p>
        )}
        {description && (
          <p className='pt-1 text-small font-Regular text-Gray-600'>
            {description}
          </p>
        )}
        {createdOn && (
          <p className='pt-2 text-extra-small font-Regular text-Gray-500'>
            {timeDifference(
              new Date(),

              timeToLocalTimeZoneWithISOFormat(createdOn),
            )}
          </p>
        )}
      </div>
      {!isRead && (
        <div className='absolute top-1/2 right-4 rounded-full w-3 h-3 bg-[#4191DC]'></div>
      )}
    </div>
  )
}

export default NotificationCard

NotificationCard.defaultProps = {
  className: undefined,
  isRead: true,
}
