import { ListItemNode, ListNode } from '@lexical/list'
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { HeadingNode } from '@lexical/rich-text'
import { LexicalEditor, TextNode } from 'lexical'
import {
  BeautifulMentionNode,
  BeautifulMentionsPlugin,
} from 'lexical-beautiful-mentions'

import { CustomMenu } from './components/CustomMenu'
import { CustomMenuItem } from './components/CustomMenuItem'
import { ExtentedTextNode } from './nodes/ExtentedTextNode'
import { InitialStatePlugin } from './plugins/InitialStatePlugin'
import { ToolbarPlugin } from './plugins/ToolbarPlugin'
import { emailTextTheme } from './theme'

import './styles.scss'

type LexicalEmailEditorProps = {
  editorRef: React.MutableRefObject<LexicalEditor | null>
  initialState?: string
  placeholder?: string
  mentions?: string[]
  hasToolbar?: boolean
}

export const LexicalEmailEditor = ({
  editorRef,
  initialState,
  placeholder = '',
  mentions = [],
  hasToolbar = true,
}: LexicalEmailEditorProps) => {
  return (
    <LexicalComposer
      initialConfig={{
        namespace: 'LexicalEmailEditor',
        nodes: [
          BeautifulMentionNode,
          HeadingNode,
          ListNode,
          ListItemNode,
          ExtentedTextNode,
          {
            replace: TextNode,
            with: (node: TextNode) =>
              new ExtentedTextNode(node.__text, node.__key),
          },
        ],
        onError: (error: Error) => console.log(error),
        theme: emailTextTheme,
      }}>
      <div className='editor-container'>
        <div className='rounded-t-md editor-inner'>
          <RichTextPlugin
            contentEditable={
              <ContentEditable className='min-h-[150px] w-full py-2 text-Gray-800 rounded-t-md border border-b-0 shadow-sm focus:outline-none border-Gray-300 px-3' />
            }
            placeholder={
              <div className='editor-placeholder'>{placeholder}</div>
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
          <HistoryPlugin />
          <ListPlugin />
          <ClearEditorPlugin />
          <BeautifulMentionsPlugin
            items={{ '@': mentions }}
            menuComponent={CustomMenu}
            menuItemComponent={CustomMenuItem}
            allowSpaces={false}
            menuItemLimit={15}
          />
          <InitialStatePlugin
            initialState={initialState}
            mentions={mentions}
            isHtml={true}
          />
          <OnChangePlugin
            onChange={(_, editor) => (editorRef.current = editor)}
            ignoreSelectionChange
          />
        </div>
        {hasToolbar && <ToolbarPlugin />}
      </div>
    </LexicalComposer>
  )
}
