import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

type CancelFixedSubscriptionDto = {
  cancellation: CancelRequestDto
}

export type CancelRequestDto = {
  isCancelImmediately: boolean
  cancellationReason: string
  comment: string
}

export const useCancelFixedSubscription = () => {
  const mutate = useMutation((dto: CancelFixedSubscriptionDto) => {
    return putAPI(`/subscriptions/fixed/cancel`, dto)
  })

  return mutate
}
