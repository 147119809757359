type QuantitySelectorProps = {
  quantity: number
  setQuantity: (newQuantity: number) => void
  allowNegative?: boolean
  isStopLimit?: boolean
}

export const QuantitySelector = ({
  quantity,
  setQuantity,
  allowNegative = false,
  isStopLimit = false,
}: QuantitySelectorProps) => {
  const increaseQuantity = () => {
    setQuantity(quantity + 1)
  }

  const decreaseQuantity = () => {
    if (allowNegative || quantity > 0) {
      if (!isStopLimit) {
        setQuantity(quantity - 1)
      }
    }
  }

  return (
    <div className='grid grid-cols-3 gap-0 w-[140px]'>
      <button
        onClick={decreaseQuantity}
        className='border rounded-l border-Gray-300 focus:outline-cobalt-400 w-full h-[35px]'>
        <i className='ri-subtract-fill w-[20px] h-[20px] text-Gray-900 font-semibold' />
      </button>
      <span className='flex items-center justify-center font-bold border border-x-0 border-Gray-300 w-full h-[35px]'>
        {quantity}
      </span>
      <button
        onClick={increaseQuantity}
        className='border rounded-r border-Gray-300 focus:outline-cobalt-400 w-full h-[35px]'>
        <i className='ri-add-line w-[20px] h-[20px] text-Gray-900 font-semibold' />
      </button>
    </div>
  )
}
