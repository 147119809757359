/* eslint-disable no-empty-pattern */

import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const getAgencyProfileDetails = async ({}: any) => {
  const res = await get(`/AgencyProfile`, {})
  return res
}

export const useGetAgencyDetails = (
  { ...reqBody },
  onSuccess: any,
  onError: any,
) => {
  return useQuery(['AgencyProfile', reqBody], getAgencyProfileDetails, {
    enabled: false,
    retry: 2,
    refetchOnWindowFocus: false,
    onSuccess,
    onError,
  })
}
