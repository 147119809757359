import { useEffect, useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { SetupIntentDto, useGetSetupIntent } from 'api/payments'
import { PaymentErrorSetterType } from 'features/payments/types'
import { ModalProps } from 'types'

import { stripePubKey } from 'config/framework.config'

import { SaveAndPayCardModal } from './SaveAndPayCardModal'
import { SaveCardModal } from './SaveCardModal'

type PaymentCardElementModalProps = ModalProps & {
  modalMode: 'save' | 'pay'
  setInvoice?: any
  setIsInvoiceModalActive?: any
  setCardBillingAddress?: any
  setIsPayNowLoading?: any
  setPaymentError?: PaymentErrorSetterType
}

export const PaymentCardElementModal = ({
  isModalActive,
  setIsModalActive,
  modalMode,
  setInvoice,
  setIsInvoiceModalActive,
  setCardBillingAddress,
  setIsPayNowLoading,
  setPaymentError,
}: PaymentCardElementModalProps) => {
  const [stripePromise, setStripePromise] = useState<any>(null)

  const [setupIntent, setSetupIntent] = useState<SetupIntentDto | undefined>(
    undefined,
  )

  useEffect(() => {
    if (stripePubKey) {
      setStripePromise(loadStripe(stripePubKey))
    }
  }, [stripePubKey])

  useGetSetupIntent(isModalActive, (data) => setSetupIntent(data))

  if (isModalActive && setupIntent && stripePromise) {
    return (
      <Elements
        stripe={stripePromise}
        options={{
          clientSecret: setupIntent.clientSecret,
        }}>
        {modalMode == 'save' ? (
          <SaveCardModal
            isModalActive={isModalActive}
            setIsModalActive={setIsModalActive}
            clientSecret={setupIntent.clientSecret}
          />
        ) : (
          <SaveAndPayCardModal
            isModalActive={isModalActive}
            setIsModalActive={setIsModalActive}
            clientSecret={setupIntent.clientSecret}
            setInvoice={setInvoice}
            setCardBillingAddress={setCardBillingAddress}
            setIsInvoiceModalActive={setIsInvoiceModalActive}
            setIsPayNowLoading={setIsPayNowLoading}
            setPaymentError={setPaymentError}
          />
        )}
      </Elements>
    )
  }

  return null
}
