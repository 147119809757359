export const candidateCSV = [
  [
    'FirstName',
    'LastName',
    'Email',
    'Phone',
    'Role applying for',
    'ReferenceCount',
  ],
]

export const employeeCSV = [
  ['FirstName', 'LastName', 'Email', 'Phone', 'Job Title'],
]
