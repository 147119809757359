import { useMutation } from 'react-query'
import { deleteAPI } from 'framework/api/http'

export interface DeleteQuestionaryInterface {
  Id?: string
}

const useDeleteAssessment = () => {
  const mutate = useMutation(({ Id }: DeleteQuestionaryInterface) => {
    return deleteAPI(`/assessment/${Id}`)
  })

  return mutate
}

export default useDeleteAssessment
