import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface useReinviteUserInterface {
  UserProfileId?: string
}

export const useReinviteUser = () => {
  const mutate = useMutation(({ UserProfileId }: useReinviteUserInterface) => {
    return get(`/UserProfile/${UserProfileId}/ReInvite`)
  })

  return mutate
}

export default useReinviteUser
