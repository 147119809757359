import { createSlice } from '@reduxjs/toolkit'

import { PromotionDto } from '../types'

export type PromotionStoreProps = {
  promotion: PromotionDto | null
  fixedPromotion: PromotionDto | null
  promotionMessage: string
  isRedeemSuccessModalOpen: boolean
}

const initialState: PromotionStoreProps = {
  promotion: null,
  fixedPromotion: null,
  promotionMessage: 'Code Invalid',
  isRedeemSuccessModalOpen: false,
}

const promotionSlice = createSlice({
  name: 'promotion',
  initialState,
  reducers: {
    setPromotionState(state, { payload }) {
      state.promotion = payload
    },
    setFixedPromotionState(state, { payload }) {
      state.fixedPromotion = payload
    },
    setPromotionMessageState(state, { payload }) {
      state.promotionMessage = payload
    },
    setIsRedeemSuccessModalOpenState(state, { payload }) {
      state.isRedeemSuccessModalOpen = payload
    },
  },
})

export const {
  setPromotionState,
  setPromotionMessageState,
  setFixedPromotionState,
  setIsRedeemSuccessModalOpenState,
} = promotionSlice.actions

export default promotionSlice.reducer
