import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { TeamCard } from '@labourhub/labour-hub-ds'
import { useGetUserRoles } from 'framework/api/methods'

import { QLD_CLIENT } from 'utils/QLD-Client'
import useAgencySubdomainValidator from 'utils/useAgencySubdomainValidator'

type UserGroupsProps = {
  [x: string]: any
}

export const UserGroups = ({ ...restProps }: UserGroupsProps) => {
  const navigate = useNavigate()

  /**Fetch logged user details */
  const { data: userGroupDetails } = useGetUserRoles()
  const check_QLD_Subdomains = useAgencySubdomainValidator({
    subdomainList: QLD_CLIENT.subdomainList,
  })

  const { userDetails } = useSelector((state: any) => state.user)

  return (
    <div className='user-groups' {...restProps}>
      {/** Team cards */}
      <div className='flex gap-x-5'>
        {userGroupDetails?.roleDetails?.map((itm: any, idx: any) => {
          const roleName =
            itm.roleName === QLD_CLIENT.previousTitle && check_QLD_Subdomains
              ? QLD_CLIENT.newTitle
              : itm.roleName

          const showRole =
            (userDetails?.roleName === 'SuperAdministrator' &&
              itm.roleName === 'SuperAdministrator') ||
            itm.roleName === 'Administrator' ||
            itm.roleName === 'Consultant'

          return (
            showRole && (
              <div
                id={`${idx}-${roleName}s`}
                key={idx}
                className='flex w-1/4 mb-6'>
                {/** Team Card Component */}
                <TeamCard
                  avatarList={itm.userList.map((user: any) => {
                    return {
                      ...user,
                      title: `${user.firstName} ${user.lastName}`,
                      imageUrl: user?.avatar ? user?.avatar : undefined,
                    }
                  })}
                  memberTitle={`${roleName}s`}
                  memberCount={itm.userCount}
                  avatarRestProps={{ isIcon: false }}
                  text={` You can see all ${roleName} members here. `}
                  onViewAllClick={() => {
                    navigate(
                      `/user-groups/user-list?user_role=${itm.roleName}&role_id=${itm.roleId}`,
                    )
                  }}
                  style={{
                    backgroundColor: '#ffffff',
                    width: 'auto',
                  }}
                />
              </div>
            )
          )
        })}
      </div>
    </div>
  )
}
