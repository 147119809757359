import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { SetupIntentDto, useGetSetupIntent } from 'api/payments'
import { getPaymentStore } from 'features/payments/store'
import { PaymentErrorSetterType } from 'features/payments/types'
import {
  FixedSubscriptionPackageDto,
  SubscriptionMode,
} from 'features/subscriptions'

import { PageLoader } from 'components/atoms'
import { stripePubKey } from 'config/framework.config'

import { ExisitngPaymentForm } from './ExisitngPaymentForm'
import { NewPaymentForm } from './NewPaymentForm'

type PaymentCardElementFormProps = {
  isSubscriptionPaymentModalActive: boolean
  subscriptionPackage: FixedSubscriptionPackageDto
  subscriptionMode: SubscriptionMode
  setInvoice: any
  setIsInvoiceModalActive: any
  setCardBillingAddress: any
  setFixedSubscriptionPaymentModalActive: any
  setPaymentError: PaymentErrorSetterType
}

export const PaymentCardElementForm = ({
  isSubscriptionPaymentModalActive,
  subscriptionPackage,
  subscriptionMode,
  setInvoice,
  setCardBillingAddress,
  setIsInvoiceModalActive,
  setFixedSubscriptionPaymentModalActive,
  setPaymentError,
}: PaymentCardElementFormProps) => {
  const { cards } = useSelector(getPaymentStore)

  const [stripePromise, setStripePromise] = useState<any>(null)
  const [isGoToExistingCard, setIsGoToExistingCard] = useState<boolean>(
    cards && cards.length > 0,
  )

  const [setupIntent, setSetupIntent] = useState<SetupIntentDto | undefined>(
    undefined,
  )

  useGetSetupIntent(
    isSubscriptionPaymentModalActive && !isGoToExistingCard,
    (data) => setSetupIntent(data),
  )

  useEffect(() => {
    if (stripePubKey) {
      setStripePromise(loadStripe(stripePubKey))
    }
  }, [stripePubKey])

  useEffect(() => {
    setIsGoToExistingCard(cards && cards.length > 0)
  }, [isSubscriptionPaymentModalActive])

  if (isSubscriptionPaymentModalActive) {
    if (isGoToExistingCard) {
      return (
        <ExisitngPaymentForm
          subscriptionMode={subscriptionMode}
          packageAmount={subscriptionPackage.packageAmount}
          packageId={subscriptionPackage.id}
          packageName={subscriptionPackage.name}
          setIsGoToExistingCard={setIsGoToExistingCard}
          setInvoice={setInvoice}
          setIsInvoiceModalActive={setIsInvoiceModalActive}
          setCardBillingAddress={setCardBillingAddress}
          setFixedSubscriptionPaymentModalActive={
            setFixedSubscriptionPaymentModalActive
          }
          setPaymentError={setPaymentError}
        />
      )
    } else {
      if (!setupIntent || !stripePromise) {
        return (
          <div className='w-full h-full'>
            <PageLoader size='xxs' className='bg-opacity-100' />
          </div>
        )
      } else {
        return (
          <Elements
            stripe={stripePromise}
            options={{
              clientSecret: setupIntent.clientSecret,
            }}>
            <NewPaymentForm
              clientSecret={setupIntent.clientSecret}
              isCardsAvailable={cards && cards.length > 0}
              packageId={subscriptionPackage.id}
              packageName={subscriptionPackage.name}
              setIsGoToExistingCard={setIsGoToExistingCard}
              subscriptionMode={subscriptionMode}
              setInvoice={setInvoice}
              setCardBillingAddress={setCardBillingAddress}
              setIsInvoiceModalActive={setIsInvoiceModalActive}
              setFixedSubscriptionPaymentModalActive={
                setFixedSubscriptionPaymentModalActive
              }
              setPaymentError={setPaymentError}
            />
          </Elements>
        )
      }
    }
  }

  return null
}
