import CN from 'classnames'

import { AmountSummary } from '../types'

type InvoiceAmountSummaryProps = {
  amountSummary: AmountSummary
  className?: string
}

export const InvoiceAmountSummary = ({
  amountSummary,
  className,
}: InvoiceAmountSummaryProps) => {
  return (
    <div className={CN('space-y-2', className)}>
      <div className='flex items-center justify-between text-Gray-500'>
        <label htmlFor='subtotal'>Subtotal</label>
        <span className='font-SemiBold'>
          ${amountSummary.subTotal.toFixed(2)}
        </span>
      </div>

      {amountSummary.subTotal > 0 && amountSummary.promotionAmount > 0 && (
        <div className='flex items-center justify-between text-Gray-500'>
          <label htmlFor='gst'>Discount</label>
          <span className='font-SemiBold'>
            (- $
            {amountSummary.subTotal > 0
              ? amountSummary.promotionAmount.toFixed(2)
              : (0).toFixed(2)}
            )
          </span>
        </div>
      )}
      <div className='flex items-center justify-between text-Gray-500'>
        <label htmlFor='gst'>GST (10%)</label>
        <span className='font-SemiBold'>
          $
          {amountSummary.tax > 0
            ? amountSummary.tax.toFixed(2)
            : (0).toFixed(2)}
        </span>
      </div>
      <div className='flex items-center justify-between pb-20 text-black font-Medium text-heading-3'>
        <label htmlFor='total'>Total</label>
        <span className='font-SemiBold '>
          $
          {amountSummary.totalAmount > 0
            ? amountSummary.totalAmount.toFixed(2)
            : (0).toFixed(2)}
        </span>
      </div>
    </div>
  )
}
