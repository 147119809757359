import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { TextField, Toast } from '@labourhub/labour-hub-ds'
import { useEditCandidateDetails } from 'api/candidates'
import {
  AppSettingKey,
  AppSettingProps,
  getAppSettings,
  isSettingEnabled,
} from 'features/settings'
import { ModalProps } from 'types'

import { PageLoader, PhoneInputField } from 'components/atoms'
import { RoundedModal } from 'components/ui/RoundedModal'
import { isEmail, isLetters, isPhoneNumber } from 'utils'

type EditEmployeeModalProps = ModalProps & {
  candidateDetails: any
  getCandidateById: any
}

export const EditEmployeeModal = ({
  isModalActive,
  setIsModalActive,
  candidateDetails,
  getCandidateById,
}: EditEmployeeModalProps) => {
  const { agencyAppSettings, candidateAppSettings } =
    useSelector(getAppSettings)

  const [candidateSettings, setCandidateSettings] =
    useState<AppSettingProps[]>(candidateAppSettings)

  useEffect(() => {
    setCandidateSettings(candidateAppSettings)
  }, [candidateAppSettings])

  const isEmailRequired = isSettingEnabled(
    AppSettingKey.IS_CANDIDATE_EMAIL_REQUIRED,
    agencyAppSettings,
  )

  const isSmsEnabled = isSettingEnabled(
    AppSettingKey.SmsFeature,
    agencyAppSettings,
  )

  const [isErrorField, setIsErrorField] = useState<any>({
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
    jobTitle: false,
  })

  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(false)

  const [updatedEmployeeDetails, setUpdatedEmployeeDetails] = useState({
    title: '',
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    phone: '',
    countryCode: '',
    jobTitle: '',
    isEmployee: true,
    referenceCount: '0',
    gender: '',
    dateOfBirth: '',
    candidateSettings,
    referenceTypes: [],
  })

  const [renderKey, setRenderKey] = useState(Math.random())

  useEffect(() => {
    setUpdatedEmployeeDetails({
      ...updatedEmployeeDetails,
      candidateSettings,
    })
  }, [candidateSettings])

  /** front end validation for all input fields */
  const validateInputs = () => {
    const { firstName, lastName, email, phone, jobTitle } =
      updatedEmployeeDetails

    setIsErrorField({
      ...isErrorField,
      firstName: isLetters(firstName) && firstName?.length < 100 ? false : true,
      lastName: isLetters(lastName) && lastName?.length < 100 ? false : true,
      phone: isPhoneNumber(phone) && phone?.length < 20 ? false : true,
      jobTitle: jobTitle?.length < 100 ? false : true,
    })

    const isAllValidFields =
      isLetters(firstName) &&
      firstName?.length < 100 &&
      isLetters(lastName) &&
      lastName?.length < 100 &&
      isPhoneNumber(phone) &&
      phone?.length < 20 &&
      jobTitle?.length < 100

    if (isEmailRequired) {
      setIsErrorField({
        ...isErrorField,
        email: isEmail(email) && email?.length < 200 ? false : true,
        firstName:
          isLetters(firstName) && firstName?.length < 100 ? false : true,
        lastName: isLetters(lastName) && lastName?.length < 100 ? false : true,
        phone: isPhoneNumber(phone) && phone?.length < 20 ? false : true,
        jobTitle: jobTitle?.length < 100 ? false : true,
      })
      return isAllValidFields && email?.length < 200 && isEmail(email)
    }

    return isAllValidFields
  }

  useEffect(() => {
    const {
      title,
      firstName,
      middleName,
      lastName,
      email,
      phone,
      countryCode,
      jobTitle,
      isEmployee,
      refereeCount,
      gender,
      dateOfBirth,
      referenceTypes,
    } = candidateDetails || {}

    setUpdatedEmployeeDetails({
      ...updatedEmployeeDetails,
      title: title || '',
      firstName: firstName || '',
      middleName: middleName || '',
      lastName: lastName || '',
      dateOfBirth: dateOfBirth,
      gender: gender ? gender : '',
      email: email || '',
      countryCode: countryCode || '',
      phone: phone || setRenderKey(Math.random()),
      jobTitle: jobTitle || '',
      isEmployee: isEmployee || true,
      referenceCount: refereeCount || '0',
      referenceTypes,
    })
  }, [candidateDetails, isModalActive])

  /** APi call for the edit candidate */
  const {
    mutate: editEmployeeDetailsMutate,
    isLoading: editEmployeeDetailsIsLoading,
  } = useEditCandidateDetails()

  /** Process the edit employee */
  const editEmployeeDetails = () => {
    editEmployeeDetailsMutate(
      {
        id: candidateDetails?.candidateId,
        updatedCandidateDetails: updatedEmployeeDetails,
      },
      {
        onSuccess: () => {
          Toast({
            alertHeader: 'Employee details updated successfully',
            alertBody: '',
            status: 'Success',
          })
          getCandidateById(candidateDetails?.candidateId)
          setIsModalActive(false)
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  useEffect(() => {
    const { firstName, email, phone, jobTitle } = updatedEmployeeDetails || {}
    if (isEmailRequired) {
      firstName && email && jobTitle
        ? setIsPrimaryButtonDisabled(false)
        : setIsPrimaryButtonDisabled(true)
    } else {
      firstName && phone && isSmsEnabled && jobTitle
        ? setIsPrimaryButtonDisabled(false)
        : setIsPrimaryButtonDisabled(true)
    }
  }, [updatedEmployeeDetails])

  /** handle edit candidate button */
  const onEditCandidateDetails = () => {
    const valid = validateInputs()

    if (valid) {
      editEmployeeDetails()
    } else {
      Toast({
        alertHeader: 'Invalid input type.',
        status: 'Warning',
      })
    }
  }

  useEffect(() => {
    if (isModalActive) {
      setRenderKey(Math.random())
    }
  }, [isModalActive])

  const resetErrorState = () => {
    setIsErrorField({
      firstName: false,
      lastName: false,
      email: false,
      phone: false,
      referenceCount: false,
      jobTitle: false,
    })
  }

  const handleCloseModal = () => {
    setCandidateSettings(candidateAppSettings)
    setIsModalActive(false)
    resetErrorState()
  }

  return (
    <RoundedModal
      isActive={isModalActive}
      className='w-full'
      headerTitle='Edit Employee'
      headerSubtitle='Edit employee details here'
      isHeaderShow={true}
      isFooterShow={true}
      isSeparator={true}
      primaryButtonTitle='Save'
      secondaryButtonTitle='Cancel'
      isPrimaryButtonDisable={isPrimaryButtonDisabled}
      onHeaderCloseButtonClick={() => {
        handleCloseModal()
      }}
      onClickPrimaryBtn={() => {
        onEditCandidateDetails()
      }}
      onClickSecondaryBtn={() => {
        handleCloseModal()
      }}>
      {editEmployeeDetailsIsLoading && <PageLoader size='xxs' />}

      {/** Body section of the drawer modal */}
      {
        <div className='w-full px-6 pb-10'>
          <TextField
            className='mt-5'
            label='First Name'
            isRequired
            value={updatedEmployeeDetails?.firstName}
            onChange={(e: any) => {
              setUpdatedEmployeeDetails({
                ...updatedEmployeeDetails,
                firstName: e.target.value,
              })
            }}
            placeholder=''
            isErrored={isErrorField?.firstName}
          />

          <TextField
            className='mt-5 mb-4'
            label='Last Name'
            value={updatedEmployeeDetails?.lastName}
            onChange={(e: any) =>
              setUpdatedEmployeeDetails({
                ...updatedEmployeeDetails,
                lastName: e.target.value,
              })
            }
            placeholder=''
            isRequired
            isErrored={isErrorField?.lastName}
          />

          {isEmailRequired && (
            <TextField
              className='mt-4'
              isDisabled
              label='Email'
              isRequired
              value={updatedEmployeeDetails?.email}
              onChange={(e: any) => {
                setUpdatedEmployeeDetails({
                  ...updatedEmployeeDetails,
                  email: e.target.value,
                })
              }}
              placeholder=''
              isErrored={isErrorField.email}
            />
          )}

          <PhoneInputField
            key={renderKey}
            className='mt-5'
            isDisabled={!isEmailRequired && candidateDetails?.phone != ''}
            isRequired
            label='Phone'
            value={updatedEmployeeDetails?.phone}
            onChangeWithCountry={(number: any, countryCode: string) =>
              setUpdatedEmployeeDetails({
                ...updatedEmployeeDetails,
                phone: number,
                countryCode,
              })
            }
            isErrored={isErrorField?.phone}
          />
          <TextField
            className='mt-5'
            label='Job Title'
            isRequired
            value={updatedEmployeeDetails?.jobTitle}
            onChange={(e: any) =>
              setUpdatedEmployeeDetails({
                ...updatedEmployeeDetails,
                jobTitle: e.target.value,
              })
            }
            placeholder=''
            isErrored={isErrorField?.jobTitle === '' ? true : false}
          />
        </div>
      }
    </RoundedModal>
  )
}
