import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

import { RefereeDataProps } from './useEditReferee'

interface AddRefereeProps {
  refereeData?: RefereeDataProps
  candidateId?: string
}

export const useAddReferee = () => {
  const mutate = useMutation(
    ({ refereeData, candidateId }: AddRefereeProps) => {
      const {
        firstName,
        lastName,
        phone,
        email,
        company,
        jobTitle,
        countryCode,
        relationshipToCandidate,
        preCompanyName,
        preCandidatePosition,
        preJobStartDate,
        preJobFinishDate,
        referenceType,
        isCurrentlyWorking,
      } = refereeData || {}

      return postAPI(`/Candidate/${candidateId}/Referee`, {
        firstName,
        lastName,
        phone,
        email,
        company,
        jobTitle,
        countryCode,
        candidateRelation: relationshipToCandidate,
        candidateCompany: preCompanyName,
        candidatePosition: preCandidatePosition,
        startDate: preJobStartDate ? preJobStartDate : new Date(),
        endDate: preJobFinishDate ? preJobFinishDate : new Date(),
        referenceType: {
          note: '',
          specificEmployer: '',
          type: referenceType,
        },
        isCurrentlyWorking,
      })
    },
  )

  return mutate
}

export default useAddReferee
