import React from 'react'

import { cn } from 'utils'

type Props = {
  children: React.ReactNode
  className?: string
}

export const Card = ({ children, className }: Props) => {
  return (
    <div
      className={cn(
        'flex flex-col w-full h-full p-4 bg-white border rounded-md border-Gray-200',
        className,
      )}>
      {children}
    </div>
  )
}
