import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface Questionnaire {
  title?: string
  type?: string
  isRequired?: boolean
}

interface QuestionnaireBuildInterface extends Questionnaire {
  name?: string
  type?: string
  questions?: Questionnaire
  isActive?: boolean
  surveyType?: string
}

export const useQuestionnaireBuild = () => {
  const mutate = useMutation(
    ({
      name,
      type,
      questions,
      isActive,
      surveyType,
    }: QuestionnaireBuildInterface) => {
      return postAPI(`/facade/Questionnaire`, {
        questionnaire: { name, type, questions, isActive, surveyType },
      })
    },
  )

  return mutate
}

export default useQuestionnaireBuild
