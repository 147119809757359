import { forwardRef } from 'react'
import LogoMini from 'assets/images/refHubLogoText.svg'
import { assessmentReportPreviewData } from 'features/candidates/data/assessmentReportPreviewData'

import { ReportLogoHeader } from 'components/ui/reports/sections'

import { AutoMarkingProgressProps } from '../AutoMarkingProgress'

import Progressbar from './Progressbar'

type CombinedProps = Partial<AutoMarkingProgressProps> & {
  hasCompanyLogo?: boolean
  issueDate: Date
  certificateId: string
  previewData?: assessmentReportPreviewData | undefined
  isSampleView?: boolean
}
const AssessmentDetailedCertificate = forwardRef<HTMLDivElement, CombinedProps>(
  (
    {
      values,
      totalMarks,
      template,
      candidateDetails,
      issueDate,
      certificateId,
      previewData,
      hasCompanyLogo,
      isSampleView,
    },
    certificateRef,
  ) => {
    return (
      <div
        ref={certificateRef}
        className=' w-[1056px] h-[816px] flex items-center justify-center  '>
        <div className='w-[987px] h-[746px] border-4 border-Blue-500  flex flex-col'>
          <div className='flex flex-row items-center  justify-between px-8 py-8'>
            <ReportLogoHeader
              clientlogo={previewData?.clientLogoUrl}
              hasClientLogo={previewData?.hasClientLogo}
              hasCompanyLogo={previewData?.hasCompanyLogo || hasCompanyLogo}
              isSampleView={isSampleView}
              logoReverse={true}
            />
          </div>

          <h1 className='bg-Blue-500 text-white w-full text-heading-1 font-Medium flex items-center justify-center h-[60px]'>
            Certificate of Completion
          </h1>
          <div className=' flex flex-col justify-center mx-auto items-center mt-[47px] w-[594px] h-[245px]'>
            <p className='text-small font-Regular'>This acknowledges that</p>
            <h1 className='text-[51px] font-Regular'>
              {candidateDetails?.name}
            </h1>
            <p className='text-small font-Regular'>
              has successfully completed the{' '}
              <span className='font-Bold'>{template?.assessmentName}</span> for
            </p>
            <h1 className='text-heading-1 font-Regular italic'>
              {candidateDetails?.jobTitle}
            </h1>
            <Progressbar values={values} totalMarks={totalMarks} />
          </div>
          <p className='w-fit mx-auto mt-5'>Completed On</p>
          <p className='w-fit mx-auto'>
            {new Date(template?.completedOn).toLocaleDateString('en-GB', {
              day: '2-digit',
              month: '2-digit',
              year: '2-digit',
            })}
          </p>
          <div className='mt-auto ml-8 mb-6 flex flex-row items-center  justify-between h-fit'>
            <div className='w-[231px] h-[64px] flex flex-col'>
              <p className='text-extra-small'>
                Issue Date{' '}
                {issueDate.toLocaleDateString('en-GB').replace(/\//g, '/')}
              </p>
              <p className='text-extra-small'>
                Certificate ID: {certificateId}
              </p>
              <p className='text-extra-small'>
                ©2024 Refhub. All rights reserved.
              </p>
              <div className='flex flex-row gap-[10px]'>
                {/* todo remove pointer-events-none and add a valid link to a tag */}
                <a
                  className='text-[8px] pr-2 text-Blue-400 pointer-events-none'
                  href='/'>
                  {' '}
                  About the Certificate
                </a>
                <a
                  className='text-[8px] px-2 text-Blue-400 border-l-2 border-Gray-300'
                  href='https://www.refhub.com.au/privacy-policy'>
                  Terms and Conditions
                </a>
              </div>
            </div>
            <div className='mt-auto flex flex-row mr-8'>
              <img src={LogoMini} alt='Logo' />
            </div>
          </div>
        </div>
      </div>
    )
  },
)

export default AssessmentDetailedCertificate
