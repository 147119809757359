import { ReactNode } from 'react'

import { ColumnWithAlignment } from 'components/atoms/Column'
import { cn } from 'utils'

export type Details = {
  label: string
  value?: string
  component?: ReactNode
  type: 'text' | 'link' | 'component'
}

type DetailColumnProps = {
  details: Details[]
  type: 'left' | 'right'
}

export const DetailColumn = ({ details, type }: DetailColumnProps) => (
  <ColumnWithAlignment
    itemAlignment='start'
    justifyAlignment='start'
    className={cn('w-1/2 px-4 py-2.5', {
      'pl-0': type === 'left',
    })}>
    {details.map((detail, index) => (
      <DetailItem key={index} detail={detail} index={index} />
    ))}
  </ColumnWithAlignment>
)

const DetailItem = ({ detail, index }: { detail: Details; index: number }) => (
  <div className='pb-4'>
    <label
      htmlFor={`detail-row-${index}`}
      className='font-Medium text-extra-small text-Gray-400'>
      {detail.label}
    </label>
    {detail.type === 'component' ? (
      <>{detail.component}</>
    ) : (
      <p
        className={cn('font-Medium text-small', {
          'text-black': detail.type === 'text',
          'text-Cobalt-500': detail.type === 'link',
        })}>
        {detail.value || 'NA'}
      </p>
    )}
  </div>
)
