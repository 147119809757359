import { useMutation } from 'react-query'
import { deleteAPI } from 'framework/api/http'

type DeleteCandidateNoteRequest = {
  noteId: string
}

export const useDeleteCandidateNote = () => {
  const mutate = useMutation(({ noteId }: DeleteCandidateNoteRequest) => {
    return deleteAPI(`/Candidate/Note/${noteId}`)
  })

  return mutate
}
