import React, { FC, useState } from 'react'
import Slider from 'react-slick'
import { Button } from '@labourhub/labour-hub-ds'
import CN from 'classnames'

export type SimpleSliderProps = {
  autoplaySpeed?: number | undefined
  cssEase?: string | undefined
  contentClassName?: string | undefined
  initialSlide?: number | undefined
  isArrows?: boolean | undefined
  isAutoplay?: boolean | undefined
  isCustomArrows?: boolean | undefined
  isDots?: boolean | undefined
  isFade?: boolean | undefined
  isInfinite?: boolean | undefined
  onNextClick?: any | undefined
  onPrevClick?: any | undefined
  sliderData?: { id: any; sliderContent: any }[] | []
  slidesToScroll?: number | undefined
  slidesToShow?: number | undefined
  setSelectedSlideIndex?: any | undefined
  [x: string]: any
}

export const SimpleSlider: FC<SimpleSliderProps> = ({
  autoplaySpeed,
  className,
  contentClassName,
  cssEase,
  initialSlide,
  isArrows,
  isAutoplay,
  isCustomArrows,
  isDots,
  isFade,
  isInfinite,
  onNextClick,
  onPrevClick,
  sliderData,
  slidesToScroll,
  slidesToShow,
  setSelectedSlideIndex,
}: SimpleSliderProps) => {
  //creating the ref
  const customSlider: any = React.createRef()

  /** current slide count state*/
  const [slideNumber, setSlideNumber] = useState<any>(1)

  /** slider main style class */
  const SliderClasses = CN(`slider w-[calc(100%-60px)] w-full`, className)

  /** slider settings */
  const settings = {
    dots: isDots,
    arrows: isArrows,
    fade: isFade,
    infinite: isInfinite,
    initialSlide: initialSlide,
    speed: 200,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    autoplay: isAutoplay,
    autoplaySpeed: autoplaySpeed,
    cssEase: cssEase,
    adaptiveHeight: false,
    afterChange: function (index) {
      setSlideNumber(index + 1)
      setSelectedSlideIndex && setSelectedSlideIndex(index)
    },
  }

  /** handle next button */
  const next = () => {
    customSlider.current.slickNext()
    onNextClick && onNextClick()
  }

  /** handle previous button */
  const previous = () => {
    customSlider.current.slickPrev()
    onPrevClick && onPrevClick()
  }

  return (
    <div className={SliderClasses}>
      {/* slider */}
      <Slider ref={customSlider} {...settings} className='h-auto'>
        {sliderData?.map((slide, index) => (
          <div className={contentClassName} key={index}>
            {slide?.sliderContent}
          </div>
        ))}
      </Slider>

      {/* custom buttons */}
      {isCustomArrows && sliderData?.length && sliderData?.length !== 1 ? (
        <div className='flex w-full justify-center items-center gap-[37px] mt-8 pb-5'>
          <Button
            icon={<i className='ri-arrow-left-s-line text-heading-4' />}
            isIconOnly
            onClick={() => {
              previous()
            }}
            size='sm'
            textTheme='blue'
            isDisabled={sliderData?.length === 0}
            theme='white'
          />

          <div className='py-1 px-4 bg-Gray-100 rounded-full'>
            {slideNumber}/{sliderData?.length || 0}
          </div>

          <Button
            icon={<i className='ri-arrow-right-s-line text-heading-4' />}
            isIconOnly
            onClick={() => {
              next()
            }}
            size='sm'
            isDisabled={sliderData?.length === 0}
            textTheme='blue'
            theme='white'
          />
        </div>
      ) : null}
    </div>
  )
}

SimpleSlider.defaultProps = {
  autoplaySpeed: 2000,
  contentClassName: undefined,
  cssEase: 'linear',
  initialSlide: 0,
  isArrows: false,
  isAutoplay: false,
  isCustomArrows: true,
  isDots: false,
  isFade: false,
  isInfinite: true,
  onNextClick: undefined,
  onPrevClick: undefined,
  sliderData: [],
  slidesToScroll: 1,
  slidesToShow: 1,
  setSelectedSlideIndex: undefined,
}

export default SimpleSlider
