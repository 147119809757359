import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { AppSettingProps } from 'features/settings'
import { get } from 'framework/api/http'

export const useGetCandidateSettings = (
  candidateId: string,
  onSuccess: (candidateSettings: AppSettingProps[]) => void,
) => {
  return useQuery(
    ['APPSETTINGS_CANDIDATES', candidateId],
    async () => {
      const res = await get(`/AppSettings/Candidates/${candidateId}`)
      return res.data.candidateSettings as AppSettingProps
    },
    {
      enabled: candidateId != '',
      refetchOnWindowFocus: false,
      onSuccess,
      onError: () => {
        Toast({
          alertHeader: 'Error',
          alertBody: 'Unable to fetch agency candidate settings',
          status: 'Error',
        })
      },
    },
  )
}
