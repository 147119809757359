import { Badge } from '@labourhub/labour-hub-ds'

type CreditListItemProps = {
  title: string
  data?: any
  iconClass?: string
}

export const CreditListItem = ({
  title,
  data,
  iconClass,
}: CreditListItemProps) => {
  // Determine the badge theme based on the data value
  const badgeTheme = (() => {
    switch (data) {
      case 'RESPONSE_RECEVIED':
      case true:
        return 'green'
      case 'AWAITING_RESPONSE':
      case 'RESPONSE_IN_REVIEW':
        return 'yellow'
      case 'RESPONSE_EVALUATED':
        return 'blue'
      case 'REQUEST_DECLINED':
      case false:
        return 'red'
      default:
        return 'gray'
    }
  })()

  return (
    <div className={`flex items-center ${!iconClass ? 'py-2' : ''}`}>
      <div className='flex flex-row items-center gap-2'>
        {iconClass && (
          <i className={`${iconClass} text-Gray-500 text-[20px]`} />
        )}
        <h3 className='text-Gray-500 text-small'>{title}</h3>
      </div>
      <div className='ml-auto text-Gray-900 text-small font-Medium'>
        {data === true ||
        data === false ||
        [
          'RESPONSE_RECEVIED',
          'AWAITING_RESPONSE',
          'RESPONSE_IN_REVIEW',
          'RESPONSE_EVALUATED',
          'REQUEST_DECLINED',
        ].includes(data) ? (
          <Badge
            size='large'
            theme={badgeTheme}
            isDot={true}
            isCustomStyles={true}
            className='flex items-center justify-center w-fit py-[2px] font-Medium bg-none'>
            <div className='text-extra-small font-Medium leading-3'>
              {data === true
                ? 'Yes'
                : data === false
                ? 'No'
                : data === 'RESPONSE_RECEVIED'
                ? 'Received'
                : data === 'AWAITING_RESPONSE'
                ? 'Awaiting'
                : data === 'REQUEST_DECLINED'
                ? 'Declined'
                : data === 'RESPONSE_EVALUATED'
                ? 'Evaluated'
                : data === 'RESPONSE_IN_REVIEW'
                ? 'Review'
                : 'Not Selected'}
            </div>
          </Badge>
        ) : (
          data
        )}
      </div>
    </div>
  )
}
