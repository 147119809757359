import { PoliceCheckType } from 'api/ncc'

export const checkTypeList = [
  { label: 'EMPLOYMENT', value: PoliceCheckType.EMPLOYMENT },
  { label: 'EMPLOYMENT_HEALTH', value: PoliceCheckType.EMPLOYMENT_HEALTH },
  { label: 'VOLUNTEER', value: PoliceCheckType.VOLUNTEER },
  { label: 'VOLUNTEER_HEALTH', value: PoliceCheckType.VOLUNTEER_HEALTH },
  { label: 'WORK_EXP', value: PoliceCheckType.WORK_EXP },
  { label: 'WORK_EXP_HEALTH', value: PoliceCheckType.WORK_EXP_HEALTH },
  { label: 'ACCREDITATION', value: PoliceCheckType.ACCREDITATION },
]
export const checkHealthcareListForNonHealth = [
  { label: 'NONE', value: 'NONE' },
]

export const checkHealthcareListForHealth = [
  { label: 'SUPERVISED', value: 'SUPERVISED' },
  { label: 'UNSUPERVISED', value: 'UNSUPERVISED' },
]
