const moment = require('moment-timezone')

export const timeToAESTWithoutSeconds = (incomingDate: any) => {
  const localtz = Intl.DateTimeFormat().resolvedOptions().timeZone
  const dateString = moment
    .utc(incomingDate)
    .tz(localtz)
    .format('DD/MM/YYYY h:mm a')

  return dateString
}
