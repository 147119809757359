import { ColumnWithAlignment } from 'components/atoms/Column'

type CookieDetectionAlertProps = {
  link: string
}

export const CookieDetectionAlert = ({ link }: CookieDetectionAlertProps) => {
  return (
    <div className='fixed top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center bg-black border-none bg-opacity-40 '>
      <div className='w-[575px] h-[284px]  bg-white p-5 z-10'>
        <ColumnWithAlignment justifyAlignment='center'>
          <div className='text-[18px] font-SemiBold text-[#171923] mt-5'>
            <i className='px-2 py-3 rounded-full ri-delete-bin-line text-heading-3 bg-Yellow-50 text-Yellow-400' />
          </div>
          <div className='text-[18px] font-SemiBold text-[#171923] mt-8'>
            Enable Cookies
          </div>
          <div className='text-[#718096] text-center p-4'>
            It seems that cookies are disabled in your browser. In order to use
            our website effectively, please{' '}
            {link != '' && (
              <a
                href={link}
                target='_blank'
                rel='noopener noreferrer'
                className='text-Cobalt-500 font-SemiBold'>
                click here
              </a>
            )}{' '}
            to enable cookies in your browser settings.
          </div>
        </ColumnWithAlignment>
      </div>
    </div>
  )
}
