import { Button, Toast } from '@labourhub/labour-hub-ds'
import { useSendCustomAssessmentReminder } from 'api/assessment/sendCustomAssessmentReminder'
import { ModalProps } from 'types'

import { Modal, ModalFooter } from 'components/molecules'
import { timeToAESTWithSeconds } from 'utils'

type SendAssessmentReminderModalProps = ModalProps & {
  candidateId: string
  selectedResponse: any
  refreshAssessmentList: () => void
  responseId: string
}
export const SendAssessmentReminderModal = ({
  isModalActive,
  setIsModalActive,
  candidateId,
  selectedResponse,
  refreshAssessmentList,
  responseId,
}: SendAssessmentReminderModalProps) => {
  const {
    mutate: sendCustomAssessmentReminderMutate,
    isLoading: resendCustomTemplateIsLoading,
  } = useSendCustomAssessmentReminder()

  const handleSend = () => {
    sendCustomAssessmentReminderMutate(
      {
        cId: candidateId,
        assessmentId: selectedResponse.assessmentId,
        responseId,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          Toast({
            alertHeader: successData?.messageTitle,
            status: 'Success',
          })
          setIsModalActive(false)
          refreshAssessmentList()
        },
        onError: ({ response: { data: errData } }: any) => {
          const alertHeader = errData?.messageTitle || 'Error...!'
          const alertBody = errData?.message
          const status = alertHeader.includes('Test SMS count Exceeded')
            ? 'Warning'
            : 'Error'
          Toast({ alertHeader, alertBody, status })
        },
      },
    )
  }

  const closeModal = () => setIsModalActive(false)

  return (
    <Modal
      headerTitle='Send Reminder'
      subHeaderTitle='Remind to complete assessment'
      isFooterShow
      size='md'
      isActive={isModalActive}
      onHeaderCloseButtonClick={closeModal}
      onOverlayClick={closeModal}>
      <div className='flex flex-col p-6 w-full'>
        <span className='text-small text-Gray-500 mb-5'>
          Request sent on:{' '}
          <span className='font-SemiBold'>
            {selectedResponse?.sentOn
              ? timeToAESTWithSeconds(selectedResponse?.sentOn)
              : 'Never'}
          </span>
        </span>
        <span className='text-small text-Gray-500 mb-5'>
          Reminder last sent on:{' '}
          <span className='font-SemiBold'>
            {selectedResponse?.reminderLastSentOn
              ? timeToAESTWithSeconds(selectedResponse?.reminderLastSentOn)
              : 'Never'}
          </span>
        </span>
        <span className='text-small font-Regular text-Gray-800'>
          by clicking on Send Reminder, you will send a new reminder to their
          inbox.
        </span>
      </div>
      <ModalFooter>
        <div className='flex justify-end flex-1'>
          <Button
            className='mr-2'
            theme='cobalt'
            isLoading={resendCustomTemplateIsLoading}
            onClick={handleSend}>
            Send Reminder
          </Button>
          <Button onClick={closeModal} textTheme='blue' theme='white'>
            Cancel
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}
