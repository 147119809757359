import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'
import { TenantIdProp } from 'types'
import { QueryParams } from 'types/queryParams'

type BillingQueryParams = QueryParams & TenantIdProp

const getPaymentBillingHistoryList = async ({ queryKey }: any) => {
  const { take, skip, tenantId } = queryKey[1] as BillingQueryParams
  const res = await get(
    `/Payments/BillingHistory?take=${take}&skip=${skip}&tenantId=${tenantId}`,
  )
  return res.data
}

export const useGetPaymentBillingHistoryList = (
  queryParams: BillingQueryParams,
  onSuccess: (data: any) => void,
) => {
  return useQuery(
    ['subscriptions_billinghistory', queryParams],
    getPaymentBillingHistoryList,
    {
      enabled: true,
      refetchOnWindowFocus: false,
      onSuccess,
      onError: () => {
        Toast({
          alertHeader: 'Error fetching subscription list',
          status: 'Error',
        })
      },
    },
  )
}
