export enum CandidateTab {
  RefereesTab = 'referee',
  EligibilityTab = 'eligibility',
  CustomSurveysTab = 'customsurveys',
  SurveyResponsesTab = 'surveyresponses',
  DownloadableReportsTab = 'downloadablereports',
  AssessmentTab = 'assessment',
  OverviewTab = 'overview',
  HistoryTab = 'history',
}
