/* eslint-disable no-empty-pattern */
import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const verifyAccount = async ({ queryKey }: any) => {
  const reqBody = queryKey[1]
  const res = await get(
    `/facade/verifyAccount?UserName=${reqBody?.userName}&EmailToken=${reqBody?.emailToken}`,
  )
  return res
}

export const useVerifyAccount = (
  { ...reqBody },
  onSuccess: any,
  onError: any,
) => {
  return useQuery(['ClientDetails', reqBody], verifyAccount, {
    enabled: true,
    refetchOnWindowFocus: false,
    retry: 2,
    onSuccess,
    onError,
  })
}

export default useVerifyAccount
