const getWordCount = (text: string): number => {
  // Remove leading/trailing whitespace and multiple consecutive spaces
  const cleanedText = text.trim().replace(/\s+/g, ' ')

  // Split the text into words using spaces as delimiters
  const words = cleanedText.split(' ')

  // Remove empty strings from the resulting array
  const filteredWords = words.filter((word) => word !== '')

  // Return the word count
  return filteredWords.length
}

export default getWordCount
