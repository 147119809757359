import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

export type SendCustomSurveyReminderDto = {
  id: string
  surveyLibraryId?: string
  surveyId?: string
  templateName?: string
}
type SendCustomSurveyReminder = {
  cId: string
  requestData: SendCustomSurveyReminderDto
}

export const useSendCustomSurveyReminder = () => {
  const mutate = useMutation(
    ({ requestData, cId }: SendCustomSurveyReminder) => {
      const { id, surveyLibraryId, surveyId, templateName } = requestData || {}
      return postAPI(`Candidates/${cId}/SendCustomSurveysReminder`, {
        id,
        surveyLibraryId,
        surveyId,
        templateName,
      })
    },
  )

  return mutate
}
