export const isPhoneNumber = (value: any) => {
  if (!value || value === null) return false
  const splitPhoneNumber = value?.split(' ')

  splitPhoneNumber?.shift()
  const countryCodeRemovedNumber = splitPhoneNumber?.join('')

  const phoneNumberRegex = /^(?:\D*\d){8}/

  return phoneNumberRegex.test(countryCodeRemovedNumber)
}
