/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { ActiveSubscriptionDto, SubscriptionPackagesListDto } from '../types'

import {
  clearSubscription,
  setSubscriptionPackagesState,
  setSubscriptionsState,
} from './reducer'

export const setSubscriptions =
  (value: ActiveSubscriptionDto, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setSubscriptionsState(value))
  }

export const setSubscriptionPackages =
  (value: SubscriptionPackagesListDto, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setSubscriptionPackagesState(value))
  }

export const resetSubscriptions =
  (cb = () => {}) =>
  (dispatch: any) => {
    dispatch(clearSubscription())
  }
