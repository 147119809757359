import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Button } from '@labourhub/labour-hub-ds'
import { PaymentCardDto } from 'api/payments'
import CN from 'classnames'
import { getPaymentStore } from 'features/payments/store'

import { Card } from 'components/atoms/Card'
import { H5 } from 'components/atoms/Headings'

import { PaymentCardElementModal } from '../PayAsYouGoSubscriptionPaymentModal'

import { CardSettingsModal } from './CardSettingsModal'
import { CreditCard } from './CreditCard'
import { RemoveCardModal } from './RemoveCardModal'

import './styles.scss'

export const PaymentMethodCard = () => {
  const { cards } = useSelector(getPaymentStore)

  const [isPaymentCardElementModalActive, setIsPaymentCardElementModalActive] =
    useState(false)
  const [isRemoveCardModalActive, setIsRemoveCardModalActive] = useState(false)
  const [isCardSettingsModalActive, setIsCardSettingsModalActive] =
    useState(false)

  const [selectedCard, setSelectedCard] = useState<PaymentCardDto | undefined>(
    undefined,
  )

  const handleOnEditCard = (selectedPaymentMethod: PaymentCardDto) => {
    setSelectedCard(selectedPaymentMethod)
    setIsCardSettingsModalActive(true)
  }

  return (
    <>
      <Card className='mr-6'>
        <H5 title='Payment Method' />

        {!cards || cards?.length == 0 ? (
          <div className='mt-2'>
            <CreditCard
              setIsPaymentCardElementModalActive={
                setIsPaymentCardElementModalActive
              }
            />
          </div>
        ) : (
          <div>
            <div className='mt-2 overflow-y-auto custom-scroll'>
              <div className='payment-row'>
                <div
                  className={CN('flex w-full space-x-5 min-w-max', {
                    'pt-6 mb-6': cards.length > 2,
                    'my-6': cards.length <= 2,
                  })}>
                  {cards.map((paymentCard) => (
                    <CreditCard
                      key={paymentCard.id}
                      paymentCard={paymentCard}
                      setIsPaymentCardElementModalActive={
                        setIsPaymentCardElementModalActive
                      }
                      handleOnEditCard={handleOnEditCard}
                    />
                  ))}
                </div>
                <div className='w-[200px]'>
                  <Button
                    onClick={() => setIsPaymentCardElementModalActive(true)}
                    iconLeft={<i className='ri-add-line text-[18px]' />}
                    className='w-auto card-edit-button'>
                    Add Another Card
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Card>

      <PaymentCardElementModal
        isModalActive={isPaymentCardElementModalActive}
        setIsModalActive={setIsPaymentCardElementModalActive}
        modalMode='save'
      />

      {selectedCard && (
        <CardSettingsModal
          paymentCard={selectedCard}
          isCardSettingsModalActive={isCardSettingsModalActive}
          setIsCardSettingsModalActive={setIsCardSettingsModalActive}
          setIsRemoveCardModalActive={setIsRemoveCardModalActive}
        />
      )}

      {selectedCard && (
        <RemoveCardModal
          isPrimaryCard={selectedCard.isDefaultCard}
          paymentCardId={selectedCard.id}
          isRemoveCardModalActive={isRemoveCardModalActive}
          setIsRemoveCardModalActive={setIsRemoveCardModalActive}
        />
      )}
    </>
  )
}
