import { Column } from 'components/atoms/Column'
import { AssessmentReportCandidateDetails } from 'components/ui/reports/sections'
import { ReportDetailColumns } from 'components/ui/reports/sections/ReportDetailColumns'

export function UserDetails({
  candidateDetails,
  reportViewData,
}: {
  candidateDetails: any
  reportViewData?: any
}) {
  return (
    <Column>
      <AssessmentReportCandidateDetails
        details={candidateDetails}
        reportViewData={reportViewData}
      />

      <div className='pt-2 mt-2 mx-4'>
        <ReportDetailColumns
          title='Candidate Details'
          leftDetails={[
            {
              label: 'ID No',
              value: candidateDetails?.candidateNo,
              type: 'text',
            },
            {
              label: 'Phone',
              value: candidateDetails?.phone,
              type: 'link',
            },
          ]}
          rightDetails={[
            {
              label: 'Email',
              value: candidateDetails?.email,
              type: 'link',
            },
            {
              label: 'Managing Consultant',
              value: candidateDetails?.consultantName,
              type: 'text',
            },
          ]}
        />
      </div>
    </Column>
  )
}
