import { useDispatch } from 'react-redux'
import { Modal, Toast } from '@labourhub/labour-hub-ds'

import { useDeleteCustomerCard } from '../../../../api/payments'
import { toggleIsRefetchPaymentCards } from '../../store'

type RemoveCardModalProps = {
  isPrimaryCard?: boolean
  paymentCardId: string
  isRemoveCardModalActive: boolean
  setIsRemoveCardModalActive: any
}

export const RemoveCardModal = ({
  isPrimaryCard = false,
  paymentCardId,
  isRemoveCardModalActive,
  setIsRemoveCardModalActive,
}: RemoveCardModalProps) => {
  const dispatch = useDispatch()

  const notify = (props: any) => Toast(props)

  const { mutate } = useDeleteCustomerCard()

  const handleDelete = () => {
    mutate(
      {
        paymentCardId,
      },
      {
        onSuccess: () => {
          notify({
            alertHeader: isPrimaryCard
              ? 'Primary Card Removed'
              : 'Card Removed',
            alertBody: isPrimaryCard
              ? 'Your primary card has been successfully deleted from your account. Ensure you have an alternative payment method set up for uninterrupted service.'
              : 'Your card has been successfully deleted from your account. Ensure you have an alternative payment method set up for uninterrupted service.',
            status: 'Warning',
          })
          dispatch(toggleIsRefetchPaymentCards())
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
        onSettled: () => setIsRemoveCardModalActive(false),
      },
    )
  }

  return (
    <Modal
      isActive={isRemoveCardModalActive}
      className='!z-[150] py-10 w-full'
      modalProps={{ style: { width: '550px', height: 'auto' } }}
      isSeparator={true}
      isHeaderShow={true}
      headerTitle={
        isPrimaryCard
          ? 'Warning: Remove Primary Card?'
          : 'Warning: Remove Card?'
      }
      headerCloseButtonProps={{
        style: {
          fontSize: '22px',
        },
      }}
      onHeaderCloseButtonClick={() => setIsRemoveCardModalActive(false)}
      isFooterShow={true}
      footerProps={{
        style: {
          flexDirection: 'row-reverse',
        },
      }}
      primaryButtonTitle='Delete Card'
      primaryButtonProps={{
        theme: 'terracotta',
        textTheme: 'black',
        iconLeft: (
          <i className='text-[20px] ri-delete-bin-line text-TerraCotta-400' />
        ),
        style: {
          marginLeft: '12px',
        },
      }}
      onClickPrimaryBtn={handleDelete}
      secondaryButtonTitle='Cancel'
      secondaryButtonProps={{
        theme: 'white',
        textTheme: 'black',
      }}
      onClickSecondaryBtn={() => setIsRemoveCardModalActive(false)}>
      <div className='flex flex-col items-center justify-center w-full h-full p-2space-x-5'>
        <i className='ri-error-warning-fill text-Red-500 text-[84px]' />
        <div className='px-3 pb-5 '>
          {isPrimaryCard ? (
            <p className='text-center w-[550px]'>
              <strong> This is your primary payment method </strong>. Deleting
              it may disrupt any active subscriptions or services associated
              with this card.
              <br />
              <br />
              If you have ongoing subscriptions or credits, please ensure you've
              added an alternative payment method to avoid any service
              interruptions.
            </p>
          ) : (
            <p className='text-center w-[425px]'>
              Are you sure you want to delete this card from your account? Any
              scheduled transactions associated with this card will be
              cancelled.
            </p>
          )}
        </div>
      </div>
    </Modal>
  )
}
