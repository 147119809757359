import { PaymentMethodType } from '../types'

export const paymentMethodTypeSelectOptions = [
  { label: PaymentMethodType.None, value: PaymentMethodType.None },
  { label: PaymentMethodType.Card, value: PaymentMethodType.Card },
  { label: PaymentMethodType.Cash, value: PaymentMethodType.Cash },
  { label: PaymentMethodType.Cheque, value: PaymentMethodType.Cheque },
  { label: 'Bank Transfer', value: PaymentMethodType.BankTransfer },
  { label: PaymentMethodType.Other, value: PaymentMethodType.Other },
]
