import { useState } from 'react'
import { Avatar, Button } from '@labourhub/labour-hub-ds'
import { CandidateProfileBasicDetailsType } from 'api/candidates'
import CN from 'classnames'
import {
  CandidateReferee,
  useDeleteCandidateReferee,
} from 'framework/api/methods'

import { CustomAccordion, PageLoader } from 'components/atoms'
import { ColumnWithAlignment } from 'components/atoms/Column'
import { Row, RowWithAlignment } from 'components/atoms/Row'
import {
  CandidateRefereeStatusCard,
  PhoneRefCheck,
  SendWebRefCheckReminderModal,
} from 'components/molecules'

import DeleteRefereeModal from '../DeleteRefereeModal'

import AccordionHeading from './AccordionHeading'
import RefereeCardDetails from './RefereeCardDetails'

type CandidateRefereeCardProps = {
  candidate: CandidateProfileBasicDetailsType
  getCandidateRefereesList: () => void
  onCallRecordingsClick?: () => void | undefined
  onClickWebRefCheck?: () => void
  onEditClick?: () => void | undefined
  onViewReportClick: () => void
  referee: CandidateReferee
  refereeIndex: number
  setShowDownlaodReferenceReportModal: any
  [x: string]: any
}

export const CandidateRefereeCard = ({
  candidate,
  className,
  getCandidateRefereesList,
  onCallRecordingsClick,
  onClickWebRefCheck,
  onEditClick,
  refereeCount,
  onViewReportClick,
  referee,
  refereeIndex,
  setSelectedRefereeDetails,
  getCandidateById,
  setShowDownlaodReferenceReportModal,
}: CandidateRefereeCardProps) => {
  const RefereeCardClasses = CN(
    `flex flex-col bg-white border border-Gray-200 rounded-lg p-5 pt-4 w-full`,
    className,
  )

  const [isOpenPhoneRefCheck, setIsOpenPhoneRefCheck] = useState(false)
  const [isOpenRefereeDelete, setIsOpenRefereeDelete] = useState(false)
  const [
    isSendWebRefCheckReminderModalOpen,
    setIsSendWebRefCheckReminderModalOpen,
  ] = useState(false)

  const {
    mutateAsync: deleteCandidateReferee,
    isLoading: isLoadingDeleteCandidateReferee,
  } = useDeleteCandidateReferee(candidate.candidateId, referee.id, refereeIndex)

  return (
    <>
      <CustomAccordion
        heading=''
        itemCenter={true}
        headerComponent={
          <AccordionHeading
            refereeIndex={refereeIndex}
            referee={referee}
            setSelectedRefereeDetails={setSelectedRefereeDetails}
            onViewReportClick={onViewReportClick}
            refereeCount={refereeCount}
            onEditClick={onEditClick}
            setIsSendWebRefCheckReminderModalOpen={
              setIsSendWebRefCheckReminderModalOpen
            }
            onCallRecordingsClick={onCallRecordingsClick}
            setIsOpenRefereeDelete={setIsOpenRefereeDelete}
          />
        }
        className={RefereeCardClasses}
        key={refereeIndex}
        id={refereeIndex}>
        {isLoadingDeleteCandidateReferee && <PageLoader size='xxs' />}

        <ColumnWithAlignment className='w-full'>
          <div className='flex justify-between w-full my-2 border-t border-t-Gray-200'>
            <div className='flex items-center gap-4 w-full h-16'>
              <Avatar
                isHeader={false}
                title={referee.firstName + ' ' + referee.lastName}
                color={'four'}
              />
              {/* Name with form type status */}
              <div className='flex items-start flex-col'>
                <span className='mr-2 text-small text-Gray-800 font-Medium'>
                  {referee.firstName + ' ' + referee.lastName}
                </span>

                {/* Status either web or phone ref check sent */}
                {referee.refereeResponse?.responseType === 'PHONE_CHECK' && (
                  <span className='flex items-center py-[2px] rounded-[10px] h-[20px] gap-[6px]'>
                    <i className='flex items-center ri-phone-line text-extra-small text-Cobalt-500' />
                    <span className='text-[10px] leading-4 font-Medium text-Cobalt-500'>
                      PHONE
                    </span>
                  </span>
                )}
                {referee.refereeResponse?.responseType === 'WEB_CHECK' && (
                  <span className='flex items-center py-[2px] rounded-[10px] h-[20px] gap-[6px]'>
                    <i className='flex items-center ri-global-line text-[10px] leading-4 text-Cobalt-500' />
                    <span className='text-[10px] leading-4 font-Medium text-Cobalt-500'>
                      WEB FORM
                    </span>
                  </span>
                )}
              </div>
            </div>
            <RowWithAlignment justifyAlignment='end' itemAlignment='center'>
              <Row className='justify-end'>
                <Button
                  textTheme='blue'
                  size='sm'
                  theme='white'
                  isDisabled={referee?.state !== 'NOT_SENT'}
                  className='mr-2'
                  iconLeft={
                    <i className='flex items-center ri-global-line text-small' />
                  }
                  onClick={() => {
                    onClickWebRefCheck && onClickWebRefCheck()
                  }}>
                  <span className='text-small font-Medium'>Web Ref Check</span>
                </Button>
                <Button
                  textTheme='blue'
                  size='sm'
                  theme='white'
                  isDisabled={
                    referee?.state == 'REQUEST_DECLINED' ||
                    referee?.state == 'RESPONSE_RECEVIED'
                  }
                  iconLeft={<i className='ri-phone-line text-small' />}
                  onClick={() => {
                    setIsOpenPhoneRefCheck(true)
                  }}>
                  <span className='text-small font-Medium'>
                    Phone Ref Check
                  </span>
                </Button>
              </Row>
            </RowWithAlignment>
          </div>
          {/* detail card */}
          <RefereeCardDetails referee={referee} />
          {/* status */}
          <div className='flex-auto items-end w-full mt-4 mb-2'>
            <CandidateRefereeStatusCard
              onClickViewReport={() => {
                setSelectedRefereeDetails(referee)
                setShowDownlaodReferenceReportModal(true)
              }}
              status={referee.status}
              state={referee.state}
              sentDate={referee?.requestSendDate}
              updatedDate={
                referee.status === 'Failed'
                  ? referee.updatedOn
                  : referee?.refereeResponse?.updatedOn
              }
              lastSendRequestDate={referee?.lastSendRequest}
            />
          </div>
        </ColumnWithAlignment>
      </CustomAccordion>
      {/* Phone Ref Check */}
      <PhoneRefCheck
        referee={referee}
        candidate={candidate}
        isOpen={isOpenPhoneRefCheck}
        hideModal={() => setIsOpenPhoneRefCheck(false)}
        getCandidateRefereesList={getCandidateRefereesList}
        refreshCandidateProfileData={getCandidateById}
      />
      {/* send reminder */}
      <SendWebRefCheckReminderModal
        referee={referee}
        refereeIndex={refereeIndex}
        isActive={isSendWebRefCheckReminderModalOpen}
        setIsActive={setIsSendWebRefCheckReminderModalOpen}
        getCandidateRefereesList={getCandidateRefereesList}
      />
      {/* Delete Referee modal */}
      <DeleteRefereeModal
        isOpenRefereeDelete={isOpenRefereeDelete}
        deleteCandidateReferee={deleteCandidateReferee}
        setIsOpenRefereeDelete={setIsOpenRefereeDelete}
        getCandidateRefereesList={getCandidateRefereesList}
        getCandidateById={getCandidateById}
      />
    </>
  )
}
