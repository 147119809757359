import { useEffect, useRef, useState } from 'react'
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { Button, Toast } from '@labourhub/labour-hub-ds'
import { useGetAssessmentReportView } from 'api/assessment/getAssessmentReportView'
import { useGetRealtimeAssessmentRating } from 'api/assessment/getRealtimeAssessmentRating'
import { useUpdateAssessmentComment } from 'api/assessment/updateAssessmentComment'
import { ModalProps } from 'types'

import { updateNode } from 'utils/updateNode'

import { Modal, ModalFooter } from '../Modal'

import { AssessmentDownloadModal } from './DownloadReport/AssessmentDownloadModal'
import RatingTable from './RatingTable/RatingTable'
import AntiCheatinMonitor from './AntiCheatinMonitor'
import AssessmentCompletedDetails from './AssessmentCompletedDetails'
import AutoMarkingProgress from './AutoMarkingProgress'
import CandidateStage from './CandidateStage'

type AssesmentPreviewModalReportProps = ModalProps & {
  candidateDetails: any
  templateId: any
  template: any
  responseId: string
  refreshAssessmentList: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<any, unknown>>
}

export const AssesmentPreviewModalReport = ({
  isModalActive,
  setIsModalActive,
  templateId,
  template,
  candidateDetails,
  refreshAssessmentList,
  responseId,
}: AssesmentPreviewModalReportProps) => {
  const notify = (props: any) => Toast(props)
  const [reportViewData, setReportViewData] = useState<any>(null)
  const [jsonSchema, setJsonSchema] = useState<any>()
  const [totalMarks, setTotalMarks] = useState<number | undefined>(0)
  const [markingValue, setMarkingValue] = useState({
    correct: 0,
    partiallyCorrect: 0,
    incorrect: 0,
    notAnswered: 0,
  })

  const [searchParams] = useSearchParams()
  const candidateId: string = searchParams.get('candidate_id') || ''
  const [
    showDownloadAssessmentReportModal,
    setShowDownloadAssessmentReportModal,
  ] = useState(false)

  const {
    mutate: getAssessmentReportViewMutate,
    isLoading: isReportViewLoading,
  } = useGetAssessmentReportView(
    async (data: any) => {
      const assessmentReport = data?.assessmentReport[0]
      setReportViewData(assessmentReport)
      setJsonSchema(assessmentReport?.jsonSchema)
      setMarkingValue({
        correct: assessmentReport?.assessmentAutoMarking?.correct || 0,
        partiallyCorrect:
          assessmentReport?.assessmentAutoMarking?.partiallyCorrect || 0,
        incorrect: assessmentReport?.assessmentAutoMarking?.incorrect || 0,
        notAnswered: assessmentReport?.assessmentAutoMarking?.notAnswered || 0,
      })
      setTotalMarks(assessmentReport?.assessmentAutoMarking?.totalMarks)
    },
    () => {
      notify({
        alertHeader: 'Something went wrong',
        alertBody: '',
        status: 'Error',
      })
    },
  )

  const componentRef = useRef<HTMLDivElement>(null)
  const [candidateStage, setCandidateStage] = useState('')

  useEffect(() => {
    if (templateId && responseId && isModalActive) {
      const requestData = {
        candidateId,
        assessmentData: [
          { assessmentId: templateId, assessmentResponseId: responseId },
        ],
      }
      getAssessmentReportViewMutate(requestData)
    }
  }, [
    isModalActive,
    templateId,
    responseId,
    candidateId,
    getAssessmentReportViewMutate,
  ])

  //   const handleDownloadReport = async () => {
  //     if (templateId && responseId) {
  //       const requestData = {
  //         candidateId: candidateId,
  //         assessmentData: [
  //           {
  //             assessmentId: templateId,
  //             assessmentResponseId: responseId,
  //           },
  //         ],
  //       }
  //       await getAssessmentReportViewMutate(requestData)
  //     }
  //   }

  useEffect(() => {
    updateNode(componentRef)
  }, [isModalActive, templateId])

  const handleCloseModals = () => {
    setIsModalActive(false)
    setShowDownloadAssessmentReportModal(false)
  }

  const { mutate: updateAssessmentComment, isLoading: questionsUpdateLoading } =
    useUpdateAssessmentComment()

  const handleUpdate = () => {
    if (reportViewData?.assessmentId) {
      updateAssessmentComment(
        {
          AssessmentId: reportViewData.assessmentId,
          assessmentResponseId: reportViewData.assessmentResponseId,
          CandidateId: candidateId,
          jsonSchema,
          candidateStage: candidateStage || reportViewData.candidateStage,
        },
        {
          onSettled: () => {
            Toast({
              alertHeader: 'Comment updated successfully',
              alertBody: '',
              status: 'Success',
            })
            refreshAssessmentList()
            if (templateId && responseId) {
              const requestData = {
                candidateId,
                assessmentData: [
                  {
                    assessmentId: templateId,
                    assessmentResponseId: responseId,
                  },
                ],
              }
              getAssessmentReportViewMutate(requestData)
            }
            setIsModalActive(false)
          },
          onError: () => {
            notify({
              alertHeader: 'Something went wrong',
              alertBody: '',
              status: 'Error',
            })
          },
        },
      )
    }
  }

  const {
    mutate: getRealtimeAssessmentRating,
    isLoading: isRealtimeAssessmentRatingLoading,
  } = useGetRealtimeAssessmentRating()

  const fetchRealtimeRatingData = async (updatedJsonSchema: any) => {
    await getRealtimeAssessmentRating(
      {
        candidateId,
        assessmentId: templateId,
        jsonSchema: updatedJsonSchema,
        assessmentResponseId: reportViewData?.assessmentResponseId,
        candidateStage,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          setMarkingValue({
            correct: successData?.assessmentAutoMarkingData?.correct || 0,
            partiallyCorrect:
              successData?.assessmentAutoMarkingData?.partiallyCorrect || 0,
            incorrect: successData?.assessmentAutoMarkingData?.incorrect || 0,
            notAnswered:
              successData?.assessmentAutoMarkingData?.notAnswered || 0,
          })
          setTotalMarks(successData?.assessmentAutoMarkingData?.totalMarks)
        },
        onError: ({ response: { data: errData } }: any) => {
          const messageTitle = errData?.messageTitle as string
          const message = errData?.message
          if (messageTitle.includes('Test SMS count Exceeded')) {
            Toast({
              alertHeader: messageTitle,
              alertBody: message,
              status: 'Warning',
            })
          } else {
            Toast({
              alertHeader: 'Error...!',
              alertBody: message,
              status: 'Error',
            })
          }
        },
      },
    )
  }

  return (
    <>
      <Modal
        headerTitle={`Assessment`}
        customWidth='1125px'
        isActive={isModalActive}
        isFooterShow={false}
        size='lg'
        onClickSecondaryBtn={handleCloseModals}
        onHeaderCloseButtonClick={handleCloseModals}
        onOverlayClick={handleCloseModals}
        secondaryButtonTitle='Close'>
        {isReportViewLoading && <div className='h-[400px] w-full'></div>}

        {reportViewData && (
          <div className='flex flex-col w-full justify-start max-h-[720px] overflow-y-auto bg-[#F9FAFB]'>
            <div className='p-8 w-full flex justify-between gap-4 '>
              <div className='w-[45%]'>
                <AssessmentCompletedDetails
                  setShowDownlaodAssessmentReportModal={
                    setShowDownloadAssessmentReportModal
                  }
                  isReport={false}
                  reportViewData={reportViewData}
                />
              </div>
              <div className='w-[55%] gap-4 flex flex-col'>
                <AutoMarkingProgress
                  values={markingValue}
                  isReport={false}
                  totalMarks={totalMarks}
                  template={template}
                  candidateDetails={candidateDetails}
                />
                <CandidateStage
                  reportViewData={reportViewData}
                  onChange={setCandidateStage}
                />
              </div>
            </div>

            <div className='p-8 pt-0 w-full flex justify-between gap-4 bg-[#F9FAFB]'>
              <div className='w-[45%] h-full'>
                <AntiCheatinMonitor
                  reportViewData={reportViewData}
                  isReport={false}
                />
              </div>
              <div className='w-[55%] gap-4 flex flex-col'>
                <RatingTable
                  reportViewData={reportViewData}
                  setJsonSchema={setJsonSchema}
                  jsonSchema={jsonSchema}
                  fetchRealtimeRatingData={fetchRealtimeRatingData}
                  isRealtimeAssessmentRatingLoading={
                    isRealtimeAssessmentRatingLoading
                  }
                />
              </div>
            </div>
          </div>
        )}

        <ModalFooter>
          <div className='flex justify-end flex-1'>
            <Button onClick={handleCloseModals} textTheme='blue' theme='white'>
              Cancel
            </Button>
            <Button
              onClick={handleUpdate}
              isLoading={questionsUpdateLoading}
              isDisabled={questionsUpdateLoading}
              className='ml-2'
              theme='cobalt'>
              Save
            </Button>
          </div>
        </ModalFooter>
      </Modal>

      {showDownloadAssessmentReportModal && (
        <AssessmentDownloadModal
          markingValue={markingValue}
          reportViewData={reportViewData}
          isModalActive={showDownloadAssessmentReportModal}
          setIsModalActive={setShowDownloadAssessmentReportModal}
          handleCloseModals={handleCloseModals}
          candidateDetails={candidateDetails}
          //   handleDownloadReport={async () => {}}
          isLoading={false}
          selectedResponse={template}
        />
      )}
    </>
  )
}
