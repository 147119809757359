import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetStateListByCountryCodeProps {
  countryCode?: string
}

export const useGetStateListByCountryCode = () => {
  const mutate = useMutation(
    ({ countryCode }: GetStateListByCountryCodeProps) => {
      return get(`/State?Search=${countryCode}`, {})
    },
  )

  return mutate
}

export default useGetStateListByCountryCode
