import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

export type VerifyMaintenanceStateResponse = {
  isFeatureEnabled: boolean
}

const verifyMaintenanceState = async () => {
  const res = await get('/featureFlags/verify-maintenance-state')
  return res.data
}

export const useVerifyMaintenanceState = (
  onSuccess: (data: VerifyMaintenanceStateResponse) => void,
  onError: () => void,
) => {
  return useQuery(['verifyMaintenanceState'], verifyMaintenanceState, {
    enabled: true,
    retry: 1,
    refetchOnWindowFocus: false,
    onSuccess,
    onError,
  })
}
