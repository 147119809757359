import { Modal } from 'components/molecules'

import { CandidateList } from './CandidateList'
import { RefereeList } from './RefereeList'

type DashboardDetailModalProps = {
  isActive: boolean
  navigation?: string
  [x: string]: any
}

export const DashboardDetailModal = ({
  isActive,
  dashboardDetailModal,
  setDashboardDetailModal,
  fromDate,
  toDate,
  navigation,
}: DashboardDetailModalProps) => {
  return (
    <Modal
      headerTitle={dashboardDetailModal?.title}
      isFooterShow={true}
      isActive={isActive}
      modalProps={{
        style: { width: '90vw' },
      }}
      onHeaderCloseButtonClick={() => {
        setDashboardDetailModal({
          ...dashboardDetailModal,
          isActive: false,
          module: '',
          status: '',
          title: '',
        })
      }}>
      <div className='flex flex-col w-full overflow-auto styled-scroll'>
        {dashboardDetailModal?.module === 'Candidate' ||
        dashboardDetailModal?.module === 'Profile' ? (
          <CandidateList
            isActive={isActive}
            dashboardDetailModal={dashboardDetailModal}
            fromDate={fromDate}
            toDate={toDate}
            navigation={navigation}
          />
        ) : (
          <RefereeList
            isActive={isActive}
            dashboardDetailModal={dashboardDetailModal}
            fromDate={fromDate}
            toDate={toDate}
          />
        )}
      </div>
    </Modal>
  )
}
