import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import oneGoldCoins from 'assets/images/coins/1-gold-coin.svg'
import oneSilverCoins from 'assets/images/coins/1-silver-coin.svg'
import twoGoldCoins from 'assets/images/coins/2-gold-coins.svg'
import twoSilverCoins from 'assets/images/coins/2-silver-coins.svg'
import threeGoldCoins from 'assets/images/coins/3-gold-coins.svg'
import threeSilverCoins from 'assets/images/coins/3-silver-coins.svg'
import fourGoldCoins from 'assets/images/coins/4-gold-coins.svg'
import fourSilverCoins from 'assets/images/coins/4-silver-coins.svg'
import moreGoldCoins from 'assets/images/coins/more-gold-coins.svg'
import moreSilverCoins from 'assets/images/coins/more-silver-coins.svg'
import CN from 'classnames'
import {
  applyPromotionOperator,
  getPromotionStore,
  PromotionCriteriaType,
  PromotionType,
} from 'features/promotions'
import { PayAsYouGoSubscriptionPackageType } from 'features/subscriptions'

import { QuantitySelector } from 'components/atoms/QuantitySelector'

type QuantityControlRowProps = {
  id: PayAsYouGoSubscriptionPackageType
  label: string
  type?: 'gold' | 'silver'
  labelTooltip?: string
  unitPrice: number
  quantity: number
  setQuantity: (value: number) => void
  isFullWidth?: boolean
}

export const QuantityControlRow = ({
  id,
  label,
  labelTooltip,
  unitPrice,
  type = 'gold',
  quantity = 0,
  setQuantity,
  isFullWidth = false,
}: QuantityControlRowProps) => {
  const { promotion } = useSelector(getPromotionStore)

  const [isPromotionAvailable, setIsPromotionAvailable] = useState(false)
  const [promotionAmount, setPromotionAmount] = useState(0)
  const [promotionUnitAmount, setPromotionUnitAmount] = useState(0)

  useEffect(() => {
    if (
      promotion?.promotionCriteria == PromotionCriteriaType.Individual &&
      promotion.individualCriteria?.includes(id)
    ) {
      if (promotion?.promotionType == PromotionType.Percentage) {
        setPromotionUnitAmount(promotion.amount / 100)
      } else {
        setPromotionUnitAmount(promotion.amount)
      }
    } else {
      setPromotionUnitAmount(0)
    }
  }, [unitPrice, promotion, id])

  const totalAmount = useMemo(() => unitPrice * quantity, [unitPrice, quantity])

  useEffect(() => {
    if (
      promotion &&
      promotion.promotionCriteria == PromotionCriteriaType.Individual &&
      promotion.individualCriteria &&
      promotion.individualCriteria.length > 0
    ) {
      const isValid = promotion.individualCriteria.includes(id)
      setIsPromotionAvailable(isValid)
      if (isValid) {
        const promoAmount = applyPromotionOperator(promotion, totalAmount)
        setPromotionAmount(promoAmount)
      }
    } else {
      setIsPromotionAvailable(false)
    }
  }, [promotion, totalAmount])

  const finalAmount = useMemo(
    () =>
      promotion
        ? totalAmount - promotionAmount > 0
          ? totalAmount - promotionAmount
          : 0
        : totalAmount,
    [promotion, totalAmount, promotionAmount],
  )

  return (
    <tr
      className={CN('flex items-center py-1.5 px-3', {
        'justify-evenly': !isFullWidth,
        'w-full justify-between': isFullWidth,
      })}>
      <td
        className={CN('text-start ', {
          'w-[150px]': !isFullWidth,
          'w-[170px]': isFullWidth,
        })}>
        <span className='font-bold'>{label}</span>
        {labelTooltip && (
          <>
            <i
              className='ri-error-warning-line text-Cobalt-600 ml-2 text-[14px] relative'
              data-tip={`${label}-tooltip`}
              data-for={`${label}-tooltip`}
            />
            <ReactTooltip
              id={`${label}-tooltip`}
              place='bottom'
              effect='solid'
              textColor='#FFFFFF'
              backgroundColor='#4B5563'
              className='rounded-full text-extra-small'>
              <p>{labelTooltip}</p>
            </ReactTooltip>
          </>
        )}
      </td>
      <td className='text-end w-[200px]'>
        <QuantitySelector
          quantity={quantity}
          setQuantity={(newQty: number) => setQuantity(newQty)}
        />
      </td>
      <td className='text-end w-[70px]'>
        <div>
          {type == 'gold' && quantity == 1 && (
            <img src={oneGoldCoins} alt='' className='ml-1 w-[42.5px]' />
          )}
          {type == 'gold' && quantity == 2 && (
            <img src={twoGoldCoins} alt='' className='ml-1 w-[45px]' />
          )}
          {type == 'gold' && quantity == 3 && (
            <img src={threeGoldCoins} alt='' className='ml-1 w-[45px]' />
          )}
          {type == 'gold' && quantity == 4 && (
            <img src={fourGoldCoins} alt='' className='ml-1 w-[45px]' />
          )}
          {type == 'gold' && quantity >= 5 && (
            <img src={moreGoldCoins} alt='' className='h-[45px]' />
          )}
          {type == 'silver' && quantity == 1 && (
            <img src={oneSilverCoins} alt='' className='ml-1.5 w-[40px]' />
          )}
          {type == 'silver' && quantity == 2 && (
            <img src={twoSilverCoins} alt='' className='ml-0.5 w-[52px]' />
          )}
          {type == 'silver' && quantity == 3 && (
            <img src={threeSilverCoins} alt='' className='ml-0.5 w-[52px]' />
          )}
          {type == 'silver' && quantity == 4 && (
            <img src={fourSilverCoins} alt='' className='ml-0.5 w-[52px]' />
          )}
          {type == 'silver' && quantity >= 5 && (
            <img src={moreSilverCoins} alt='' className='w-[55px]' />
          )}
        </div>
      </td>
      <td className='text-end w-[120px]'>
        {isPromotionAvailable && finalAmount != totalAmount ? (
          <span className='block text-base font-SemiBold'>
            {finalAmount != 0 && (
              <del className='text-Red-600 text-small'>${totalAmount}</del>
            )}{' '}
            ${finalAmount}
          </span>
        ) : (
          <span className='block text-base font-SemiBold'>${totalAmount}</span>
        )}

        <small className='text-Gray-800'>
          Unit Price:
          {promotionUnitAmount > 0 ? (
            <span>
              <del className='mx-1 text-Red-600 '>${unitPrice}</del>$
              {(unitPrice * (1 - promotionUnitAmount)).toFixed(1)}
            </span>
          ) : (
            <span>${unitPrice}</span>
          )}
        </small>
      </td>
    </tr>
  )
}
