import { createSlice } from '@reduxjs/toolkit'
import { CardBillingAddressDto } from 'features/payments'

import {
  AboutYouDto,
  AboutYourBusinessDto,
  BrandingLogosDto,
  ColleaguesDto,
  CompanyLocationDto,
  CompanySettingsOneDto,
  CompanySettingsTwoDto,
  ContactInformationDto,
  CreateAccountDto,
  VerifyAccountDto,
} from '../../../api/onboarding'

export type OnboadingPaymentProps = {
  fixedSubscriptionPackageId?: string
  billingAddress: CardBillingAddressDto
}

export type OnboardingStoreProps = {
  showGuideModal: boolean
  showGuideTooltipModal: boolean
  showGuideStepper: boolean
  guideStepperTitle?: string
  guideStepperSubTitle?: string
  guideStepperPreviewTemplate?: {
    id: string
    name: string
  }
  guideStepperCandidate?: {
    id?: string
    title: string
    firstName: string
    middleName: string
    lastName: string
    email: string
    requestSendDate: string
    phone: string
    countryCode: string
    dateOfBirth: string
    gender: string
    jobTitle: string
    noOfReferences: string
    consultantId: string
    candidateSettings: any
  }
  currentStep: number
  tenantId: string | null
  userProfileId: string | null
  token: string | null
  createAccount: CreateAccountDto | null
  verifyAccount: VerifyAccountDto | null
  paymentData: OnboadingPaymentProps
  aboutYou: AboutYouDto | null
  aboutYourBusiness: AboutYourBusinessDto | null
  brandingLogos: BrandingLogosDto | null
  contactInformation: ContactInformationDto | null
  companyLocation: CompanyLocationDto | null
  colleagues: ColleaguesDto | null
  companySettingsOne: CompanySettingsOneDto | null
  companySettingsTwo: CompanySettingsTwoDto | null
}

const initialState: OnboardingStoreProps = {
  showGuideModal: false,
  showGuideTooltipModal: false,
  showGuideStepper: false,
  guideStepperTitle: undefined,
  guideStepperSubTitle: undefined,
  guideStepperPreviewTemplate: undefined,
  guideStepperCandidate: undefined,
  currentStep: 1,
  userProfileId: null,
  token: null,
  tenantId: null,
  createAccount: null,
  verifyAccount: null,
  aboutYou: null,
  aboutYourBusiness: null,
  brandingLogos: null,
  contactInformation: null,
  companyLocation: null,
  colleagues: null,
  companySettingsOne: null,
  companySettingsTwo: null,
  paymentData: {
    fixedSubscriptionPackageId: undefined,
    billingAddress: {
      firstName: '',
      lastName: '',
      lineOne: '',
      lineTwo: '',
      suburb: '',
      state: '',
      stateCode: '',
      country: 'Australia',
      countryCode: 'AU',
      postCode: '',
    },
  },
}

const onBoardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setCurrentStepState(state, { payload }) {
      state.currentStep = payload
    },
    setGuideStepperTitleState(state, { payload }) {
      state.guideStepperTitle = payload
    },
    setGuideStepperSubTitleState(state, { payload }) {
      state.guideStepperSubTitle = payload
    },
    setGuideStepperPreviewTemplateState(state, { payload }) {
      state.guideStepperPreviewTemplate = payload
    },
    setGuideStepperCandidateState(state, { payload }) {
      state.guideStepperCandidate = payload
    },
    setShowGuideModalState(state, { payload }) {
      state.showGuideModal = payload
    },
    setShowGuideTooltipModalState(state, { payload }) {
      state.showGuideTooltipModal = payload
    },
    setShowGuideStepperState(state, { payload }) {
      state.showGuideStepper = payload
    },
    setTenantIdState(state, { payload }) {
      state.tenantId = payload
    },
    setUserProfileIdState(state, { payload }) {
      state.userProfileId = payload
    },
    setTokenState(state, { payload }) {
      state.token = payload
    },
    setCreateAccountState(state, { payload }) {
      state.createAccount = payload
    },
    setPaymentDataState(state, { payload }) {
      state.paymentData = payload
    },
    setVerifyAccountState(state, { payload }) {
      state.verifyAccount = payload
    },
    setAboutYouState(state, { payload }) {
      state.aboutYou = payload
    },
    setAboutYourBusinessState(state, { payload }) {
      state.aboutYourBusiness = payload
    },
    setBrandingLogosState(state, { payload }) {
      state.brandingLogos = payload
    },
    setContactInformationState(state, { payload }) {
      state.contactInformation = payload
    },
    setCompanyLocationState(state, { payload }) {
      state.companyLocation = payload
    },
    setColleaguesState(state, { payload }) {
      state.colleagues = payload
    },
    setCompanySettingsOneState(state, { payload }) {
      state.companySettingsOne = payload
    },
    setCompanySettingsTwoState(state, { payload }) {
      state.companySettingsTwo = payload
    },
    setOnboardingFixedSubscriptionPackageIdState(state, { payload }) {
      state.paymentData = {
        ...state.paymentData,
        fixedSubscriptionPackageId: payload,
      }
    },
    setOnboardingPaymentBillingAddressState(state, { payload }) {
      state.paymentData = {
        ...state.paymentData,
        billingAddress: payload,
      }
    },
  },
})

export const {
  setAboutYourBusinessState,
  setAboutYouState,
  setBrandingLogosState,
  setColleaguesState,
  setCompanyLocationState,
  setCompanySettingsOneState,
  setCompanySettingsTwoState,
  setContactInformationState,
  setCreateAccountState,
  setCurrentStepState,
  setGuideStepperCandidateState,
  setGuideStepperPreviewTemplateState,
  setGuideStepperSubTitleState,
  setGuideStepperTitleState,
  setOnboardingFixedSubscriptionPackageIdState,
  setOnboardingPaymentBillingAddressState,
  setPaymentDataState,
  setShowGuideModalState,
  setShowGuideStepperState,
  setShowGuideTooltipModalState,
  setTenantIdState,
  setTokenState,
  setUserProfileIdState,
  setVerifyAccountState,
} = onBoardingSlice.actions

export default onBoardingSlice.reducer
