/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import ReactTooltip from 'react-tooltip'
import { Badge, Button } from '@labourhub/labour-hub-ds'
import CN from 'classnames'

import { Rate } from 'components/atoms'
import { getQuestionType, QuestionType } from 'utils/getQuestionType'

export const RatingTableColumn = (
  setIsShowRatingModal,
  setSelectedTemplate,
) => {
  const COLUMNS = [
    {
      Header: 'QUESTION NO',
      accessor: 'QuestionNo',
      forSorting: 'QuestionNo',
      isSortable: false,
      hasFilter: false,
      isVisible: true,
      commonClassName: 'w-full',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center justify-start h-full',
      Cell: ({ row: { original } }) => (
        <div className='max-w-[280px]'>
          <div
            className='truncate flex gap-2 items-center'
            data-tip={
              original?.isAiGenerated ? 'Auto Generated' : 'Manually Updated'
            }
            data-for={original?.questionID}>
            <ReactTooltip
              place='right'
              id={original?.questionID}
              effect='solid'
              textColor='#FFFFFF'
              backgroundColor='#4B5563'
              delayShow={800}
            />
            <span>
              {`No ${
                original.questionNumber < 10
                  ? `0${original.questionNumber}`
                  : original.questionNumber
              }`}
            </span>
            {original?.isAiGenerated ? (
              <i className='ri-reddit-line text-[#4191DC] text-[20px]' />
            ) : (
              <i className='ri-compasses-line text-[#4191DC] text-[20px]' />
            )}
          </div>
        </div>
      ),
    },
    {
      Header: 'TYPE',
      accessor: 'type',
      forSorting: 'type',
      isSortable: false,
      hasFilter: false,
      isVisible: true,
      commonClassName: 'w-full',
      headerClassName: 'flex items-center justify-start',
      cellClassName: 'flex items-center justify-start',
      Cell: ({ row: { original } }) => (
        <div className='flex items-center justify-center px-2 py-1 rounded-full text-[#757575] font-Medium text-extra-small bg-[#EDEFF1] group-hover:bg-Cobalt-400'>
          <i
            className={CN('text-[14px] mr-2 text-[#757575]', {
              'ri-text-spacing': original.questionType === QuestionType.Text,
              'ri-text-wrap': [
                QuestionType.TextArea,
                QuestionType.TextArea2,
              ].includes(original.questionType),
              'ri-star-line': original.questionType === QuestionType.Rating,
              'ri-checkbox-multiple-line':
                original.questionType === QuestionType.CheckBox,
              'ri-radio-button-line':
                original.questionType === QuestionType.Radio,
              'ri-arrow-down-s-line':
                original.questionType === QuestionType.Select,
              'ri-checkbox-circle-line':
                original.questionType === QuestionType.RadioPositiveDetail,
              'ri-close-circle-line':
                original.questionType === QuestionType.RadioNegativeDetail,
              'ri-text': original.questionType === QuestionType.Paragraph,
              'ri-heading': original.questionType === QuestionType.Heading,
            })}
          />
          {getQuestionType(original.questionType)}
        </div>
      ),
    },
    {
      Header: 'ANSWER RATING',
      accessor: 'answerRating',
      forSorting: 'answerRating',
      isSortable: false,
      hasFilter: false,
      isVisible: true,
      commonClassName: 'w-full',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: ({ row: { original } }) => (
        <div>
          {['Incorrect', 'NotAnswered'].includes(original.answerRating) ? (
            <Badge
              theme={
                original?.answerRating == 'Incorrect'
                  ? 'red'
                  : original?.answerRating == 'NotAnswered'
                  ? 'yellow'
                  : ''
              }>
              {original.answerRating === 'Incorrect'
                ? 'Incorrect'
                : 'Not Answered'}
            </Badge>
          ) : (
            <Rate rating={original.answerRating} count={5} readonly />
          )}
        </div>
      ),
    },
    {
      Header: '',
      accessor: 'action',
      forSorting: '',
      isSortable: false,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full',
      headerClassName: 'flex items-center justify-center',
      cellClassName: 'flex items-center justify-center',
      Cell: ({ row: { original } }) => (
        <Button
          icon={<i className='ri-pencil-line text-heading-5 text-[#4191DC]' />}
          isIconOnly
          onClick={() => {
            setSelectedTemplate(original)
            setIsShowRatingModal(true)
          }}
          className='w-8 h-8 border-none'
          textTheme='red'
          theme='white'
        />
      ),
    },
  ]

  return COLUMNS
}
