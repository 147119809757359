import { useEffect, useState } from 'react'
import {
  SimpleSelect,
  TextArea,
  TextField,
  Toast,
} from '@labourhub/labour-hub-ds'
import { useCreateContact } from 'api/contacts'

import { PageLoader, PhoneInputField } from 'components/atoms'
import { RoundedModal } from 'components/ui/RoundedModal'
import { isEmail, isLetters, isPhoneNumber } from 'utils'

export const RequestEnterprisePlanModal = ({
  isModalActive,
  setIsModalActive,
}) => {
  const [formData, setFormData] = useState<any>({
    name: '',
    email: '',
    phoneNumber: '+61',
    hiresPerYear: '',
    message: '',
  })

  const [isErrorField, setIsErrorField] = useState<any>({
    name: false,
    email: false,
    phoneNumber: false,
    // company: false,
    hiresPerYear: false,
    message: false,
  })

  /** submit button disable state */
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false)

  const resetForm = () => {
    setFormData({
      name: '',
      email: '',
      phoneNumber: '+61',
      hiresPerYear: '',
      message: '',
    })
  }

  const validateInputs = () => {
    const { name, email, phoneNumber } = formData

    setIsErrorField({
      ...isErrorField,
      name: isLetters(name) ? false : true,
      email: isEmail(email) ? false : true,
      phoneNumber: isPhoneNumber(phoneNumber) ? false : true,
    })

    const isAllValidFields =
      isLetters(name) && isEmail(email) && isPhoneNumber(phoneNumber)

    return isAllValidFields
  }

  const { mutate, isLoading } = useCreateContact()

  const handleSubmit = () => {
    const valid = validateInputs()

    if (valid) {
      mutate(
        {
          ...formData,
          isRequestEmail: false,
          isRequestCall: false,
          contactType: 'Enterprise',
        },
        {
          onSuccess: ({ data: successData }: any) => {
            Toast({
              alertHeader: successData?.message,
              alertBody: '',
              status: 'Success',
            })
            setIsModalActive(false)
            resetForm()
          },
          onError: ({ response: { data: errData } }: any) => {
            Toast({
              alertHeader: 'Error...!',
              alertBody: errData?.message,
              status: 'Error',
            })
          },
        },
      )
    } else {
      Toast({
        alertHeader: 'Invalid input type.',
        status: 'Warning',
      })
    }
  }

  useEffect(() => {
    const { name, email, phoneNumber, hiresPerYear } = formData

    name && email && phoneNumber && hiresPerYear
      ? setIsSubmitButtonDisabled(false)
      : setIsSubmitButtonDisabled(true)
  }, [formData])

  const hiresPerYearList = [
    { label: '1-10 employees', value: '1-10 employees' },
    { label: '11-25 employees', value: '11-25 employees' },
    { label: '26-50 employees', value: '26-50 employees' },
    { label: '51-100 employees', value: '51-100 employees' },
    { label: '101-250 employees', value: '101-250 employees' },
    { label: '251-500 employees', value: '251-500 employees' },
    { label: '501-1,000 employees', value: '501-1,000 employees' },
    { label: '1,001-5,000 employees', value: '1,001-5,000 employees' },
    { label: 'above 5000 employees', value: 'above 5000 employees' },
  ]

  // Add a function to reset error state
  const resetErrorState = () => {
    setIsErrorField({
      name: false,
      email: false,
      phoneNumber: false,
      hiresPerYear: false,
      message: false,
    })
  }

  const handleCloseModal = () => {
    resetForm()
    setIsModalActive(false)
    resetErrorState()
  }

  return (
    <RoundedModal
      isActive={isModalActive}
      headerTitle={'Request Enterprise Plan'}
      headerSubtitle={'Add details for enterprise plan.'}
      isHeaderShow={true}
      isFooterShow={true}
      isSeparator={true}
      className='!z-[150]'
      isPrimaryButtonDisable={isSubmitButtonDisabled}
      primaryButtonTitle='Submit'
      secondaryButtonTitle='Cancel'
      onHeaderCloseButtonClick={() => {
        handleCloseModal()
      }}
      onClickPrimaryBtn={() => {
        handleSubmit()
      }}
      onClickSecondaryBtn={() => {
        handleCloseModal()
      }}>
      {isLoading && <PageLoader size='xxs' />}

      {/* main containers */}
      <div className='flex flex-col w-full px-6 pt-5 gap-y-5'>
        <TextField
          value={formData?.name}
          onChange={(e: any) => {
            setFormData({
              ...formData,
              name: e.target.value,
            })
          }}
          isRequired
          label='Full name'
          placeholder=''
          isErrored={isErrorField?.name}
        />

        <TextField
          value={formData?.email}
          onChange={(e: any) => {
            setFormData({
              ...formData,
              email: e.target.value,
            })
          }}
          isRequired
          label='Email address'
          placeholder=''
          isErrored={isErrorField?.email}
        />

        <PhoneInputField
          label='Phone'
          isRequired
          value={formData?.phoneNumber}
          onChange={(number: any) => {
            setFormData({
              ...formData,
              phoneNumber: number,
            })
          }}
          isErrored={isErrorField?.phoneNumber}
        />
        {/* 
        <TextField
          value={formData?.company}
          onChange={(e: any) => {
            setFormData({
              ...formData,
              company: e.target.value,
            })
          }}
          label='Company'
          placeholder=''
          isErrored={isErrorField?.company}
        /> */}

        <SimpleSelect
          value={{
            label: formData?.hiresPerYear,
            value: formData?.hiresPerYear,
          }}
          onChange={(selectedItem: any) => {
            setFormData({
              ...formData,
              hiresPerYear: selectedItem.value,
            })
          }}
          label='Hires per year'
          options={hiresPerYearList}
          placeholder='placeholder'
          isRequired
          isErrored={isErrorField?.hiresPerYear}
        />

        <TextArea
          value={formData?.message}
          onChange={(e: any) => {
            setFormData({
              ...formData,
              message: e.target.value,
            })
          }}
          label='Message'
          placeholder='Please provide any further details or question you may have'
          rows={4}
          isErrored={isErrorField?.message}
        />
      </div>
    </RoundedModal>
  )
}
