import americanExpressIcon from 'assets/images/payment/icons/amex.svg'
import mastercardIcon from 'assets/images/payment/icons/mastercard.svg'
import uniounPayIcon from 'assets/images/payment/icons/union-pay.svg'
import visaIcon from 'assets/images/payment/icons/visa.svg'

export const paymentIcons: Record<string, any> = {
  'visa': visaIcon,
  'mastercard': mastercardIcon,
  'amex': americanExpressIcon,
  'union-pay': uniounPayIcon,
}
