import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

interface Questionnaire {
  title?: string
  type?: string
  isRequired?: boolean
}

interface QuestionnaireBuildInterface extends Questionnaire {
  QId?: string
  name?: string
  type?: string
  questions?: Questionnaire
  isActive?: boolean
}

export const useQuestionnaireBuilderUpdate = () => {
  const mutate = useMutation(
    ({ QId, name, type, questions, isActive }: QuestionnaireBuildInterface) => {
      return putAPI(`/facade/Questionnaire/${QId}`, {
        name,
        type,
        questions,
        isActive,
      })
    },
  )

  return mutate
}

export default useQuestionnaireBuilderUpdate
