/* eslint-disable @typescript-eslint/no-empty-interface */
import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

type GetUserRolesResponse = {
  message: string
  messageTitle: string | null
  roleDetails: userRoles[]
}

type userRoles = {
  roleId: string
  roleName: string
  userCount: number
  userList: usersList[]
}

type usersList = {
  avatar: string | null
  firstName: string
  lastName: string | null
  userId: string | null
}

export const useGetUserRoles = () => {
  return useQuery(
    ['user_groups'],

    async () => {
      const data = await get(`/facade/listRole`)
      return (data?.data as GetUserRolesResponse) || []
    },

    {
      onError: () => {
        Toast({
          alertHeader: 'Error fetching user groups',
          status: 'Error',
        })
      },
    },
  )
}
