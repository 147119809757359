import { useEffect, useState } from 'react'
import { ActionMeta } from 'react-select'
import { SimpleSelect, TextArea, TextField } from '@labourhub/labour-hub-ds'
import {
  useAddSuburb,
  useEditAgencyDetails,
  useGetCountryList,
  useGetStateListByCountryCode,
  useGetSuburbsByStateCode,
} from 'framework/api/methods'

import {
  CreatableInputSelect,
  PageLoader,
  PhoneInputField,
} from 'components/atoms'
import { RoundedModal } from 'components/ui/RoundedModal'
import { removeHttpsOrHttp } from 'utils'

type EditAgencyProfileInfoModalProps = {
  buttonTitle: string
  headerSubtitle: string
  headerTitle: string
  [x: string]: any
}

/** company size list */
const companySizeList = [
  {
    value: 'Large',
    label: 'Large',
  },
  {
    value: 'Medium',
    label: 'Medium',
  },
  {
    value: 'Small',
    label: 'Small',
  },
  {
    value: 'Multinational',
    label: 'Multinational',
  },
]

export const EditAgencyProfileInfoModal = ({
  isActive,
  setIsActive,
  agencyProfileDetails,
  buttonTitle,
  getAgencyProfileDetailsRefetch,
  headerSubtitle,
  headerTitle,
  isLoading,
  notify,
  ...restProps
}: EditAgencyProfileInfoModalProps) => {
  /** drop down list */
  const [stateList, setStateList] = useState([])
  const [suburbList, setSuburbList] = useState([])
  const [countryList, setCountryItemList] = useState([])

  const [isErrorField, setIsErrorField] = useState<any>({
    name: false,
    abn: false,
    email: false,
    website: false,
    lineOne: false,
    suburb: false,
    postCode: false,
    about: false,
    contactUsUrl: false,
  })

  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(false)

  const [updatedAgencyDetails, setUpdatedAgencyDetails] = useState({
    name: '',
    abn: '',
    email: '',
    industry: '',
    website: '',
    phone: '',
    lineOne: '',
    suburb: '',
    state: '',
    stateCode: '',
    country: 'Australia',
    countryCode: 'AU',
    postCode: '',
    about: '',
    size: '',
    privacyPolicy: '',
    contactUsUrl: '',
  })

  /** front end validation for all input fields */
  const validateInputs = () => {
    const { name, abn, about, email } = updatedAgencyDetails

    setIsErrorField({
      ...isErrorField,
      name: name ? false : true,
      abn: abn ? false : true,
      email: email ? false : true,
      about: about ? false : true,
    })

    const isAllValidFields = name && abn && email && about

    return isAllValidFields
  }

  useEffect(() => {
    const {
      name,
      abn,
      email,
      industry,
      website,
      phone,
      address,
      about,
      size,
      privacyPolicy,
      contactUsUrl,
    } = agencyProfileDetails || {}

    setUpdatedAgencyDetails({
      ...updatedAgencyDetails,
      name: name || '',
      abn: abn || '',
      email: email || '',
      phone: phone || '',
      industry: industry || '',
      size: size || '',
      lineOne: address?.lineOne || '',
      state: address?.state || '',
      suburb: address?.suburb || '',
      postCode: address?.postCode || '',
      country: address?.country || 'Australia',
      countryCode: address?.countryCode || 'AU',
      about: about || '',
      website: website ? removeHttpsOrHttp(website) : '',
      privacyPolicy: privacyPolicy ? removeHttpsOrHttp(privacyPolicy) : '',
      contactUsUrl: contactUsUrl ? removeHttpsOrHttp(contactUsUrl) : '',
    })

    getSuburbsByStateCode(address?.stateCode)
  }, [agencyProfileDetails, isActive])

  /** APi call for the add new suburb */
  const { mutate: addSuburbMutate } = useAddSuburb()

  /** Process the add new suburb */
  const addSuburb = (stateCode, name) => {
    addSuburbMutate(
      {
        stateCode,
        name,
      },
      {
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** Get state list by country code */
  const {
    mutate: getStateListByCountyCodeMutate,
    isLoading: getStateListByCountyCodeIsLoading,
  } = useGetStateListByCountryCode()

  /** Process the state list by country code*/
  async function getStateListByCountryCode(countryCode: string) {
    getStateListByCountyCodeMutate(
      { countryCode },
      {
        onSuccess: ({ data: successData }: any) => {
          const tempStateList = successData?.states?.map((item: any) => {
            return {
              ...item,
              value: item.stateCode,
              label: item.name,
            }
          })
          setStateList(tempStateList)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting state list!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** Get suburb list by state code */
  const { mutate: getSuburbsByStateCodeMutate } = useGetSuburbsByStateCode()

  /** Process the get suburb list by state code*/
  async function getSuburbsByStateCode(stateCode: string) {
    getSuburbsByStateCodeMutate(
      { stateCode },
      {
        onSuccess: ({ data: successData }: any) => {
          const tempSuburbList = successData?.suburbs?.map((item: any) => {
            return {
              ...item,
              value: item.name,
              label: item.name,
            }
          })
          setSuburbList(tempSuburbList)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting suburb list!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** Get country list */
  const { mutate: getCountryListMutate, isLoading: getCountryListIsLoading } =
    useGetCountryList()

  /** Process the get country list */
  async function getCountryList() {
    getCountryListMutate(
      {},
      {
        onSuccess: ({ data: successData }: any) => {
          const tempCountryList = successData?.countries?.map((item: any) => {
            return {
              ...item,
              value: item.code,
              label: item.name,
            }
          })
          setCountryItemList(tempCountryList)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting country list!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** APi call for the edit candidate */
  const {
    mutate: editAgencyDetailsMutate,
    isLoading: editAgencyDetailsIsLoading,
  } = useEditAgencyDetails()

  /** Process the edit candidate */
  const editAgencyDetails = () => {
    editAgencyDetailsMutate(
      {
        updatedAgencyDetails,
        Id: agencyProfileDetails?.id,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData?.message,
            alertBody: '',
            status: 'Success',
          })

          getAgencyProfileDetailsRefetch(agencyProfileDetails?.id)
          setIsActive(false)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** get country list when tab active */
  useEffect(() => {
    getCountryList()
    getStateListByCountryCode('AU')
  }, [])

  useEffect(() => {
    const { name, abn, website, email, about, contactUsUrl } =
      updatedAgencyDetails || {}
    ;(name && abn && website && email && about && contactUsUrl ? false : true)
      ? setIsPrimaryButtonDisabled(true)
      : setIsPrimaryButtonDisabled(false)
  }, [updatedAgencyDetails])

  /** handle suburb on Change */
  const handleSuburbChange = (newValue: any, actionMeta: ActionMeta<any>) => {
    if (actionMeta.action == 'create-option') {
      addSuburb(updatedAgencyDetails.stateCode, actionMeta?.option?.label)
    }

    setUpdatedAgencyDetails({
      ...updatedAgencyDetails,
      suburb: newValue?.label,
    })
  }

  /** handle edit candidate button */
  const onEditAgencyDetails = () => {
    const valid = validateInputs()

    if (valid) {
      editAgencyDetails()
    } else {
      notify({
        alertHeader: 'Invalid input type.',
        status: 'Warning',
      })
    }
  }

  // Add a function to reset error state
  const resetErrorState = () => {
    setIsErrorField({
      name: false,
      abn: false,
      email: false,
      about: false,
    })
  }

  const handleCloseModal = () => {
    setIsActive(false)
    resetErrorState()
  }

  return (
    <RoundedModal
      isActive={isActive}
      className='!z-[150]'
      isHeaderShow={true}
      isFooterShow={true}
      isSeparator={true}
      headerTitle={headerTitle}
      headerSubtitle={headerSubtitle}
      isPrimaryButtonDisable={isPrimaryButtonDisabled}
      primaryButtonTitle={buttonTitle}
      secondaryButtonTitle='Cancel'
      onHeaderCloseButtonClick={() => {
        handleCloseModal()
      }}
      onClickPrimaryBtn={() => {
        onEditAgencyDetails()
      }}
      onClickSecondaryBtn={() => {
        handleCloseModal()
      }}
      {...restProps}>
      {(getCountryListIsLoading ||
        getStateListByCountyCodeIsLoading ||
        editAgencyDetailsIsLoading) && <PageLoader size='xxs' />}
      {/** Body section of the drawer modal */}
      {
        <div className='w-full px-6 agency-name'>
          {isLoading && <PageLoader size='xxs' />}
          <div className='pt-5'>
            <div className='py-2 pl-4 rounded-lg bg-Cobalt-50'>
              <span className='font-SemiBold text-small text-Cobalt-800'>
                Company Basic Details
              </span>
            </div>
          </div>

          <TextField
            className='pt-5'
            isRequired
            value={updatedAgencyDetails?.name}
            onChange={(e: any) => {
              setUpdatedAgencyDetails({
                ...updatedAgencyDetails,
                name: e.target.value,
              })
            }}
            label='Company Name'
            placeholder=''
            isErrored={isErrorField?.name === '' ? true : false}
          />

          <TextField
            className='pt-5 abn'
            isRequired
            value={updatedAgencyDetails?.abn}
            onChange={(e: any) => {
              setUpdatedAgencyDetails({
                ...updatedAgencyDetails,
                abn: e.target.value,
              })
            }}
            label='ABN'
            placeholder=''
            isErrored={isErrorField?.abn === '' ? true : false}
          />

          <TextField
            className='pt-5 company-email'
            isDisabled
            value={updatedAgencyDetails?.email}
            onChange={(e: any) => {
              setUpdatedAgencyDetails({
                ...updatedAgencyDetails,
                email: e.target.value,
              })
            }}
            label='Company email address'
            placeholder=''
            isErrored={isErrorField?.email === '' ? true : false}
          />

          <PhoneInputField
            label='Phone No'
            value={updatedAgencyDetails?.phone}
            onChange={(number: any) =>
              setUpdatedAgencyDetails({
                ...updatedAgencyDetails,
                phone: number,
              })
            }
            className='mt-5'
          />

          <SimpleSelect
            className='w-full pt-5 company-size'
            value={{
              label: updatedAgencyDetails?.size,
              value: updatedAgencyDetails?.size,
            }}
            onChange={(selectedItem: any) => {
              setUpdatedAgencyDetails({
                ...updatedAgencyDetails,
                size: selectedItem?.value,
              })
            }}
            label='Company Size'
            options={companySizeList}
            placeholder='Select company size'
          />

          <TextField
            className='pt-5 website'
            isRequired
            value={updatedAgencyDetails?.website}
            onChange={(e: any) => {
              setUpdatedAgencyDetails({
                ...updatedAgencyDetails,
                website: e.target.value,
              })
            }}
            label='Website'
            placeholder=''
            isErrored={isErrorField?.website === '' ? true : false}
          />

          <TextField
            className='pt-5'
            value={updatedAgencyDetails?.contactUsUrl}
            onChange={(e: any) => {
              setUpdatedAgencyDetails({
                ...updatedAgencyDetails,
                contactUsUrl: e.target.value,
              })
            }}
            label='Contact Us URL'
            placeholder=''
            isRequired
          />

          <TextField
            className='pt-5 privacy-policy'
            value={updatedAgencyDetails?.privacyPolicy}
            onChange={(e: any) => {
              setUpdatedAgencyDetails({
                ...updatedAgencyDetails,
                privacyPolicy: e.target.value,
              })
            }}
            label='Privacy Policy URL'
            placeholder=''
          />

          <div className='pt-5'>
            <div className='py-2 pl-4 rounded-lg bg-Cobalt-50'>
              <span className='font-SemiBold text-small text-Cobalt-800'>
                Company Address
              </span>
            </div>
          </div>

          <SimpleSelect
            label='Country'
            placeholder='Select Country'
            value={{
              label: updatedAgencyDetails?.country,
              value: updatedAgencyDetails?.countryCode,
            }}
            className='w-full pt-5'
            onChange={(selectedItem: any) => {
              if (updatedAgencyDetails?.countryCode !== selectedItem?.value) {
                setUpdatedAgencyDetails({
                  ...updatedAgencyDetails,
                  country: selectedItem?.label,
                  countryCode: selectedItem?.value,
                  state: '',
                  stateCode: '',
                  suburb: '',
                })

                getStateListByCountryCode(selectedItem.value)
              }
            }}
            options={countryList}
          />

          <TextField
            className='pt-5'
            value={updatedAgencyDetails.lineOne}
            onChange={(e: any) => {
              setUpdatedAgencyDetails({
                ...updatedAgencyDetails,
                lineOne: e.target.value,
              })
            }}
            label='Street Name'
            placeholder=''
            isErrored={isErrorField?.lineOne === '' ? true : false}
          />

          <SimpleSelect
            label='State'
            placeholder='Select State'
            value={{
              label: updatedAgencyDetails?.state,
              value: updatedAgencyDetails?.stateCode,
            }}
            className='w-full py-5'
            onChange={(selectedItem: any) => {
              if (updatedAgencyDetails?.stateCode !== selectedItem?.value) {
                setUpdatedAgencyDetails({
                  ...updatedAgencyDetails,
                  state: selectedItem?.label,
                  stateCode: selectedItem?.value,
                  suburb: '',
                })
                getSuburbsByStateCode(selectedItem.value)
              }
            }}
            options={stateList}
          />

          <div className='flex gap-5'>
            <CreatableInputSelect
              label='Suburb'
              className='w-2/3'
              value={{
                label: updatedAgencyDetails?.suburb,
                value: updatedAgencyDetails?.suburb,
              }}
              options={suburbList}
              onChange={handleSuburbChange}
              isDisabled={!updatedAgencyDetails?.stateCode}
              isErrored={isErrorField?.suburb === '' ? true : false}
            />

            <TextField
              className='w-[148px]'
              value={updatedAgencyDetails?.postCode}
              onChange={(e: any) => {
                setUpdatedAgencyDetails({
                  ...updatedAgencyDetails,
                  postCode: e.target.value,
                })
              }}
              label='Post Code'
              placeholder=''
              isErrored={isErrorField?.postCode === '' ? true : false}
            />
          </div>

          <div className='pt-5'>
            <div className='py-2 pl-4 rounded-lg bg-Cobalt-50'>
              <span className='font-SemiBold text-small text-Cobalt-800'>
                About Company
              </span>
            </div>
          </div>

          <TextArea
            className='pt-5 pb-10 about'
            isRequired
            value={updatedAgencyDetails?.about}
            onChange={(e: any) => {
              setUpdatedAgencyDetails({
                ...updatedAgencyDetails,
                about: e.target.value,
              })
            }}
            label='About'
            placeholder=''
            isErrored={isErrorField?.about === '' ? true : false}
            rows={5}
          />
        </div>
      }
    </RoundedModal>
  )
}
