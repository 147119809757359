import { Button, Toast } from '@labourhub/labour-hub-ds'
import { useSendCustomSurveyReminder } from 'api/candidates/survey-results/sendCustomSurveyReminder'
import { ModalProps } from 'types'

import { Modal, ModalFooter } from 'components/molecules'
import { timeToAESTWithSeconds } from 'utils'

type SendReminderModalProps = ModalProps & {
  candidateId: string
  selectedResponse: any
  refreshSurveyList: any
}
export const SendReminderModal = ({
  isModalActive,
  setIsModalActive,
  candidateId,
  selectedResponse,
  refreshSurveyList,
}: SendReminderModalProps) => {
  const {
    mutate: sendCustomSurveyReminderMutate,
    isLoading: resendCustomTemplateIsLoading,
  } = useSendCustomSurveyReminder()

  const handleSend = () => {
    sendCustomSurveyReminderMutate(
      {
        cId: candidateId,
        requestData: {
          id: selectedResponse.id,
          surveyId: selectedResponse.surveyId,
          templateName: selectedResponse.surveyName,
        },
      },
      {
        onSuccess: ({ data: successData }: any) => {
          Toast({
            alertBody: successData?.message,
            alertHeader: successData?.messageTitle,
            status: 'Success',
          })
          setIsModalActive(false)
          refreshSurveyList()
        },
        onError: ({ response: { data: errData } }: any) => {
          if (
            errData &&
            (errData?.messageTitle as string).includes(
              'Test SMS count Exceeded',
            )
          ) {
            Toast({
              alertHeader: errData?.messageTitle,
              alertBody: errData?.message,
              status: 'Warning',
            })
          } else {
            Toast({
              alertHeader: 'Error...!',
              alertBody: errData?.message,
              status: 'Error',
            })
          }
        },
      },
    )
  }

  return (
    <Modal
      headerTitle='Send Reminder'
      subHeaderTitle='Remind to complete survey'
      isFooterShow={true}
      size='md'
      isActive={isModalActive}
      onHeaderCloseButtonClick={() => {
        setIsModalActive(false)
      }}
      onOverlayClick={() => {
        setIsModalActive(false)
      }}>
      <div className='flex flex-col p-6 w-full'>
        <span className='text-small text-Gray-500 mb-5'>
          Request sent on:{' '}
          <span className='font-SemiBold'>
            {selectedResponse?.sentOn
              ? timeToAESTWithSeconds(selectedResponse?.sentOn)
              : 'Never'}
          </span>
        </span>
        <span className='text-small text-Gray-500 mb-5'>
          Reminder last sent on:{' '}
          <span className='font-SemiBold'>
            {selectedResponse?.reminderLastSentOn
              ? timeToAESTWithSeconds(selectedResponse?.reminderLastSentOn)
              : 'Never'}
          </span>
        </span>
        <span className='text-small font-Regular text-Gray-800'>
          by clicking on Send Reminder, you will send a new reminder to their
          inbox.
        </span>
      </div>
      <ModalFooter>
        <div className='flex justify-end flex-1'>
          <Button
            className='mr-2'
            theme='cobalt'
            isLoading={resendCustomTemplateIsLoading}
            onClick={handleSend}>
            Send Reminder
          </Button>
          <Button
            onClick={() => {
              setIsModalActive(false)
            }}
            textTheme='blue'
            theme='white'>
            Cancel
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}
