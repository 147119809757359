import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import reportBgPlaceholder from 'assets/images/report-settings-bg.svg'
import assessmentReportBgPlaceholder from 'assets/images/report-settings-bg-assessment-placeholder.svg'
import {
  getAssessmentReportSettings,
  getReportSettings,
} from 'features/settings'
import { ReportTypes } from 'features/settings/data/reportTypes'

import { Column } from 'components/atoms/Column'

import { ReportDividerBar } from './ReportDividerBar'

export const ReportBanner = ({
  type = ReportTypes.REFERENCE,
}: {
  type?: (typeof ReportTypes)[keyof typeof ReportTypes]
}) => {
  const referenceSettings = useSelector(getReportSettings)
  const assessmentSettings = useSelector(getAssessmentReportSettings)
  const { backgroundImage, lineColour, placeholder } = useMemo(() => {
    const isAssessment = type === ReportTypes.ASSESSMENT
    const settings = isAssessment ? assessmentSettings : referenceSettings

    return {
      backgroundImage: settings.backgroundImage,
      lineColour: settings.lineColour,
      placeholder: isAssessment
        ? assessmentReportBgPlaceholder
        : reportBgPlaceholder,
    }
  }, [type, referenceSettings, assessmentSettings])

  return (
    <Column>
      {backgroundImage?.isChecked && (
        <div className='flex items-center justify-center w-full h-[225px]'>
          <img
            src={backgroundImage.value || placeholder}
            alt=''
            className='object-cover w-full h-full'
          />
        </div>
      )}

      <ReportDividerBar size='sm' color={lineColour} />
    </Column>
  )
}
