import { forwardRef } from 'react'
import { QuestionnaireLibraryDto } from 'api/questionnaires'
import CN from 'classnames'

import { getS3File } from 'utils'

type TemplateLibraryRadioCardProps = {
  libraryItem: QuestionnaireLibraryDto
  isSelected: boolean
  setSelectedTemplateType: any
}

export const TemplateLibraryRadioCard = forwardRef<
  HTMLInputElement,
  TemplateLibraryRadioCardProps
>(
  (
    {
      libraryItem,
      isSelected = false,
      setSelectedTemplateType,
    }: TemplateLibraryRadioCardProps,
    ref,
  ) => {
    const RefereeTypeCardClassName = CN(
      'flex border-[2px] cursor-pointer p-[10px] rounded-lg h-[110px]',
      {
        'bg-Cobalt-50 border-Cobalt-500': isSelected,
        'bg-white border-Gray-300': !isSelected,
      },
    )

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <div
        className={RefereeTypeCardClassName}
        role='button'
        tabIndex={0}
        onClick={() =>
          setSelectedTemplateType({
            id: libraryItem.id,
            name: libraryItem.role,
          })
        }>
        <div className='flex flex-col items-start justify-start w-full'>
          <img
            src={getS3File(libraryItem.imageUrl)}
            alt={libraryItem.role}
            className={CN(
              'text-[40px] leading-none font-semibold w-[60px] h-[40px] ',
              {
                'text-Cobalt-600': isSelected,
                'text-Gray-400': !isSelected,
              },
            )}
          />
          <span className='font-Medium text-[14px] w-full mt-1'>
            {libraryItem.role}
          </span>
        </div>
        <div className='right-side'>
          <input
            id={libraryItem.id}
            ref={ref}
            type='radio'
            onClick={() =>
              setSelectedTemplateType({
                id: libraryItem.id,
                name: libraryItem.role,
              })
            }
            checked={isSelected}
            className='form-check-input appearance-none rounded-full h-4 w-4  bg-white border border-Gray-300 checked:bg-white checked:border-Cobalt-600 checked:border-[5px] hover:bg-Gray-300 active:bg-Gray-400 focus:outline-none focus:ring focus:ring-Cobalt-300 transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer'
            name='libraryItem'
          />
        </div>
      </div>
    )
  },
)
