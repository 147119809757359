import {
  FixedSubscriptionPackageType,
  SubscriptionType,
} from 'features/subscriptions'

export const bonusCreditMap = {
  [FixedSubscriptionPackageType.FifteenPack]: {
    [SubscriptionType.Monthly]: 8,
    [SubscriptionType.Yearly]: 10,
  },
  [FixedSubscriptionPackageType.TwentyfivePack]: {
    [SubscriptionType.Monthly]: 12,
    [SubscriptionType.Yearly]: 14,
  },
  [FixedSubscriptionPackageType.FiftyPack]: {
    [SubscriptionType.Monthly]: 15,
    [SubscriptionType.Yearly]: 17,
  },
}
