import { useState } from 'react'

import { EmployeesTable } from '../components/EmployeesTable'

export const EmployeeListPage = () => {
  const [isAddEmployeeDrawerOpen, setIsAddEmployeeDrawerOpen] = useState(false)

  return (
    <EmployeesTable
      isAddEmployeeDrawerOpen={isAddEmployeeDrawerOpen}
      setIsAddEmployeeDrawerOpen={setIsAddEmployeeDrawerOpen}
    />
  )
}
