import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

type EditCandidateDetailsProps = {
  id?: string
  updatedCandidateDetails?: any
}

export const useEditCandidateDetails = () => {
  const mutate = useMutation(
    ({ updatedCandidateDetails, id }: EditCandidateDetailsProps) => {
      return putAPI(`/Candidate/${id}`, {
        title: updatedCandidateDetails?.title,
        firstName: updatedCandidateDetails?.firstName,
        middleName: updatedCandidateDetails?.middleName,
        lastName: updatedCandidateDetails?.lastName,
        email: updatedCandidateDetails?.email,
        phone: updatedCandidateDetails?.phone,
        countryCode: updatedCandidateDetails?.countryCode,
        jobTitle: updatedCandidateDetails?.jobTitle,
        gender: updatedCandidateDetails?.gender,
        dateOfBirth: updatedCandidateDetails?.dateOfBirth || null,
        referenceCount: updatedCandidateDetails.referenceCount,
        candidateSettings: updatedCandidateDetails.candidateSettings,
        referenceTypes: updatedCandidateDetails.referenceTypes,
      })
    },
  )

  return mutate
}
