import React, { useEffect, useMemo, useRef, useState } from 'react'
import { UniqueIdentifier, useDroppable } from '@dnd-kit/core'
import {
  SortableContext,
  SortableData,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { Modal } from '@labourhub/labour-hub-ds'
import { AssessmentQuestion } from 'features/questionnaires/types'
import { motion } from 'framer-motion'

import { cn } from 'utils'

import { DraggableQuestionCard, DragOption } from './DraggableQuestionCard'

type QuestionCardsColumnProps = {
  items: AssessmentQuestion[]
  removeQuestion: (id: UniqueIdentifier) => void
  isScrollToBottom: boolean
  setShowEditDrawer: any
  setSelectedQuestion: any
  updateQuestion: (updatedQuestion: AssessmentQuestion) => void
}

const PlaceHolder = () => {
  return (
    <div className='flex flex-col items-center justify-center py-5 border rounded-lg gap-y-2 border-Gray-200 bg-Gray-50'>
      <span className='text-Gray-800 font-Medium text-[16px]'>Drop Zone</span>
      <span className='text-Gray-500 text-[12px] font-Regular'>
        Drag and drop form elements to create a template
      </span>
    </div>
  )
}

export const QuestionCardsColumn = ({
  items,
  removeQuestion,
  isScrollToBottom,
  setShowEditDrawer,
  setSelectedQuestion,
  updateQuestion,
}: QuestionCardsColumnProps) => {
  const { setNodeRef, isOver, active, over } = useDroppable({
    id: 'question-cards',
  })
  const [dragOptions, setDragOptions] = useState<DragOption>({
    isDragging: false,
    activeIndex: undefined,
    overIndex: undefined,
  })
  const [showPlaceholder, setShowPlaceholder] = useState(false)

  const bottomRef = useRef<HTMLDivElement>(null)

  const isQuestionTypeDrag = useMemo(
    () =>
      (active?.data.current as SortableData)?.sortable.containerId ==
      'question-types',
    [active],
  )

  const filteredItems = useMemo(
    () =>
      items.filter(
        (item) => item.type !== 'heading' && item.type !== 'paragraph',
      ),
    [items],
  )

  const placeHolderIndex = useMemo(() => {
    const overIndex = (over?.data.current as SortableData)?.sortable.index
    return isQuestionTypeDrag ? overIndex : null
  }, [isQuestionTypeDrag, over])

  useEffect(() => {
    setShowPlaceholder(isQuestionTypeDrag ? isOver : false)
  }, [isQuestionTypeDrag, isOver])

  useEffect(() => {
    if (isScrollToBottom) {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [items])

  const [showDeleteQuestionCardModal, setShowDeleteQuestionCardModal] =
    useState(false)
  const [questionToDelete, setQuestionToDelete] =
    useState<AssessmentQuestion | null>(null)

  const handleDeleteButtonClick = (question: AssessmentQuestion) => {
    setQuestionToDelete(question)
    setShowDeleteQuestionCardModal(true)
  }

  const handleConfirmDelete = () => {
    if (questionToDelete) {
      removeQuestion(questionToDelete.id)
    }
    setShowDeleteQuestionCardModal(false)
    setQuestionToDelete(null)
  }

  const handleCancelDelete = () => {
    setShowDeleteQuestionCardModal(false)
    setQuestionToDelete(null)
  }

  return (
    <SortableContext
      id='question-cards'
      items={items}
      strategy={verticalListSortingStrategy}>
      <motion.div
        ref={setNodeRef}
        className={cn(
          'flex flex-col w-3/5 lg:w-2/3 flex-shrink flex-grow bg-Gray-100 rounded-md space-y-3 transition-colors duration-300',
          { 'pb-[60px]': isOver },
        )}
        layoutId='question-cards'
        transition={{
          duration: !isOver ? 0.25 : 0,
          easings: {
            type: 'spring',
          },
          zIndex: {
            delay: isOver ? 0 : 0.25,
          },
        }}>
        {items.length === 0 && !showPlaceholder ? (
          <PlaceHolder />
        ) : (
          <>
            {items.map((item, index) => {
              const filteredIndex = filteredItems.findIndex(
                (fi) => fi.id === item.id,
              )
              const key = `question-card-${item.id}`

              const isDragEnabled =
                dragOptions.isDragging &&
                dragOptions.activeIndex !== index &&
                item.id ===
                  (dragOptions.overIndex
                    ? items[dragOptions.overIndex]?.id
                    : '')

              return (
                <React.Fragment key={key}>
                  {(placeHolderIndex === index || isDragEnabled) && (
                    <div className='h-[60px] w-full' />
                  )}
                  <DraggableQuestionCard
                    question={item}
                    index={index}
                    filteredIndex={filteredIndex}
                    onClickDeleteButton={() => handleDeleteButtonClick(item)}
                    onClickEditButton={() => {
                      setShowEditDrawer(true)
                      setSelectedQuestion(item)
                    }}
                    dragOptions={dragOptions}
                    setDragOptions={setDragOptions}
                    updateQuestion={updateQuestion}
                  />
                </React.Fragment>
              )
            })}
          </>
        )}

        <div ref={bottomRef} />
      </motion.div>

      {/* Delete Question Card Modal */}
      <Modal
        isActive={showDeleteQuestionCardModal}
        onClickPrimaryBtn={handleConfirmDelete}
        onClickSecondaryBtn={handleCancelDelete}
        primaryButtonProps={{
          style: { width: '100%', marginRight: '12px' },
        }}
        secondaryButtonProps={{
          style: { width: '100%', color: '#1F2937' },
        }}
        onOverlayClick={() => {
          setShowDeleteQuestionCardModal(false)
        }}
        modalProps={{
          style: { width: '466px' },
        }}
        footerProps={{
          style: {
            justifyContent: 'space-between',
            paddingLeft: '23px',
            paddingRight: '23px',
          },
        }}
        isHeaderShow={false}
        primaryButtonTitle='Yes, I’m Sure'
        secondaryButtonTitle='Cancel'>
        <div className='flex flex-col items-center justify-center p-6'>
          <div className='flex items-center justify-center w-12 h-12 rounded-full bg-Red-100'>
            <i className='ri-delete-bin-6-line text-Red-500 text-heading-3'></i>
          </div>
          <span className='pt-5 text-Gray-900 text-heading-5 font-Medium'>
            {`Delete ${questionToDelete?.name}?`}
          </span>
          <span className='pt-2 text-center text-Gray-500 text-small font-Regular'>
            {`Are you sure you want to proceed?`}
          </span>
        </div>
      </Modal>
    </SortableContext>
  )
}
