import PersonalityTestResult from 'components/molecules/AddonPreviewModal/PersonalityTestResultReport/PersonalityTestResult'
import { ReportFooter } from 'components/ui/reports/sections'

import AntiCheatinMonitor from '../AntiCheatinMonitor'
import AssessmentCompletedSection from '../DownloadReport/AssessmentCompletedSection'

type PersonalityTestReportPreviewSectionProps = {
  reportViewData: any
  isPrinting: boolean
}

const PersonalityTestReportPreviewSection = ({
  reportViewData,
  isPrinting,
}: PersonalityTestReportPreviewSectionProps) => {
  return (
    <div className='m-3'>
      <AssessmentCompletedSection reportViewData={reportViewData} />
      <div className='my-4' style={{ pageBreakBefore: 'always' }}>
        <AntiCheatinMonitor isReport reportViewData={reportViewData} />
      </div>
      <PersonalityTestResult
        reportViewData={reportViewData}
        isReport
        position={1}
      />

      {isPrinting && <ReportFooter isFixed />}
    </div>
  )
}

export default PersonalityTestReportPreviewSection
