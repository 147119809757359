import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ActionMeta } from 'react-select'
import {
  Button,
  SimpleSelect,
  TextField,
  Toast,
  Toggle,
} from '@labourhub/labour-hub-ds'
import { useUpdateOnboardingStepper } from 'api/onboarding'
import { companyLocation } from 'features/onboarding/data'
import { setCompanyLocation, setCurrentStep } from 'features/onboarding/store'
import { getOnboardingStore } from 'features/onboarding/store/selectors'
import {
  useAddSuburb,
  useGetCountryList,
  useGetStateListByCountryCode,
  useGetSuburbsByStateCode,
} from 'framework/api/methods'

import { CreatableInputSelect } from 'components/atoms'

export const CompanyLocationSection = () => {
  const dispatch = useDispatch()
  const {
    currentStep,
    tenantId,
    companyLocation: companyLocationObj,
    paymentData,
  } = useSelector(getOnboardingStore)

  const [isSameAddress, setIsSameAddress] = useState(true)

  const [agencyDetails, setAgencyDetails] = useState({
    lineOne: '',
    suburb: '',
    state: '',
    stateCode: '',
    country: 'Australia',
    countryCode: 'AU',
    postCode: '',
  })

  useEffect(() => {
    if (paymentData?.billingAddress && isSameAddress) {
      setAgencyDetails({
        ...agencyDetails,
        ...paymentData?.billingAddress,
      })
    } else {
      setAgencyDetails({
        lineOne: '',
        suburb: '',
        state: '',
        stateCode: '',
        country: 'Australia',
        countryCode: 'AU',
        postCode: '',
      })
    }
  }, [paymentData, isSameAddress])

  const [stateList, setStateList] = useState([])
  const [suburbList, setSuburbList] = useState([])
  const [countryList, setCountryItemList] = useState([])

  /** get country list when tab active */
  useEffect(() => {
    getCountryList()
    getStateListByCountryCode('AU')
  }, [])

  /** Get state list by country code */
  const { mutate: getStateListByCountyCodeMutate } =
    useGetStateListByCountryCode()

  /** Process the state list by country code*/
  async function getStateListByCountryCode(countryCode: string) {
    getStateListByCountyCodeMutate(
      { countryCode },
      {
        onSuccess: ({ data: successData }: any) => {
          const tempStateList = successData?.states?.map((item: any) => {
            return {
              ...item,
              value: item.stateCode,
              label: item.name,
            }
          })
          setStateList(tempStateList)
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting state list!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** Get suburb list by state code */
  const { mutate: getSuburbsByStateCodeMutate } = useGetSuburbsByStateCode()

  /** Process the get suburb list by state code*/
  async function getSuburbsByStateCode(stateCode: string) {
    getSuburbsByStateCodeMutate(
      { stateCode },
      {
        onSuccess: ({ data: successData }: any) => {
          const tempSuburbList = successData?.suburbs?.map((item: any) => {
            return {
              ...item,
              value: item.name,
              label: item.name,
            }
          })
          setSuburbList(tempSuburbList)
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting suburb list!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** Get country list */
  const { mutate: getCountryListMutate } = useGetCountryList()

  /** Process the get country list */
  async function getCountryList() {
    getCountryListMutate(
      {},
      {
        onSuccess: ({ data: successData }: any) => {
          const tempCountryList = successData?.countries?.map((item: any) => {
            return {
              ...item,
              value: item.code,
              label: item.name,
            }
          })
          setCountryItemList(tempCountryList)
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting country list!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** APi call for the add new suburb */
  const { mutate: addSuburbMutate } = useAddSuburb()

  /** Process the add new suburb */
  const addSuburb = (stateCode, name) => {
    addSuburbMutate(
      {
        stateCode,
        name,
      },
      {
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** handle suburb on Change */
  const handleSuburbChange = (newValue: any, actionMeta: ActionMeta<any>) => {
    if (actionMeta.action == 'create-option') {
      addSuburb(agencyDetails.stateCode, actionMeta?.option?.label)
    }

    setAgencyDetails({
      ...agencyDetails,
      suburb: newValue?.label,
    })
  }

  /** APi call for the onboarding stepper */
  const {
    mutate: companyOnboardingMutate,
    isLoading: companyOnboardingIsLoading,
  } = useUpdateOnboardingStepper()

  /** Process the onboarding stepper */
  const companyOnboarding = () => {
    companyOnboardingMutate(
      {
        companyLocation: {
          id: companyLocation,
          streetName: agencyDetails?.lineOne,
          country: agencyDetails?.country,
          countryCode: agencyDetails?.countryCode,
          state: agencyDetails?.state,
          stateCode: agencyDetails?.stateCode,
          suburb: agencyDetails?.suburb,
          postCode: agencyDetails?.postCode,
        },
      },
      {
        onSuccess: () => {
          dispatch(
            setCompanyLocation({
              id: companyLocation,
              streetName: agencyDetails?.lineOne,
              country: agencyDetails?.country,
              countryCode: agencyDetails?.countryCode,
              state: agencyDetails?.state,
              stateCode: agencyDetails?.stateCode,
              suburb: agencyDetails?.suburb,
              postCode: agencyDetails?.postCode,
              tenantId: tenantId,
              status: 'Completed',
            }),
          )
          dispatch(setCurrentStep(currentStep + 1))
        },
        onError: () => {
          dispatch(
            setCompanyLocation({
              id: companyLocation,
              streetName: agencyDetails?.lineOne,
              country: agencyDetails?.country,
              countryCode: agencyDetails?.countryCode,
              state: agencyDetails?.state,
              stateCode: agencyDetails?.stateCode,
              suburb: agencyDetails?.suburb,
              postCode: agencyDetails?.postCode,
              tenantId: tenantId,
              status: 'Completed',
            }),
          )
          dispatch(setCurrentStep(currentStep + 1))
        },
      },
    )
  }

  useEffect(() => {
    if (companyLocationObj) {
      setAgencyDetails({
        lineOne: companyLocationObj?.streetName,
        suburb: companyLocationObj?.suburb,
        state: companyLocationObj?.state,
        stateCode: companyLocationObj?.stateCode,
        country: companyLocationObj?.country,
        countryCode: companyLocationObj?.countryCode,
        postCode: companyLocationObj?.postCode,
      })
      getStateListByCountryCode(companyLocationObj?.countryCode)
      getSuburbsByStateCode(companyLocationObj?.stateCode)
    }
  }, [companyLocationObj])

  const onNextStepClick = () => {
    companyOnboarding()
  }

  return (
    <div className='flex flex-col justify-center items-center w-[55%]'>
      <div className='w-full mt-5 text-left text-heading-2 font-Bold text-Gray-800'>
        Company Profile Setup
      </div>

      <div className='w-full mt-6 text-left text-small font-Medium text-Gray-800'>
        Where's your head office located? This information is accessible to your
        candidates and referees.
      </div>

      {paymentData && paymentData?.billingAddress?.firstName && (
        <div className='flex items-center justify-start w-full mt-6'>
          <Toggle
            className=''
            setValue={isSameAddress}
            onToggleClick={(e: any) => setIsSameAddress(e.target.checked)}
          />

          <span className='flex ml-2 text-Gray-800 text-small font-Medium'>
            Same as Billing Address
          </span>
        </div>
      )}
      <TextField
        className='w-full pt-6'
        value={agencyDetails?.lineOne}
        onChange={(e: any) => {
          setAgencyDetails({
            ...agencyDetails,
            lineOne: e.target.value,
          })
        }}
        label='Street Name'
        placeholder=''
        // isErrored={isErrorField?.lineOne === '' ? true : false}
      />

      <SimpleSelect
        label='Country'
        placeholder='Select Country'
        value={{
          label: agencyDetails?.country,
          value: agencyDetails?.countryCode,
        }}
        className='w-full pt-5'
        onChange={(selectedItem: any) => {
          if (agencyDetails?.countryCode !== selectedItem?.value) {
            setAgencyDetails({
              ...agencyDetails,
              country: selectedItem?.label,
              countryCode: selectedItem?.value,
              state: '',
              stateCode: '',
              suburb: '',
            })

            getStateListByCountryCode(selectedItem.value)
          }
        }}
        options={countryList}
      />

      <SimpleSelect
        label='State'
        placeholder='Select State'
        value={{
          label: agencyDetails?.state,
          value: agencyDetails?.stateCode,
        }}
        className='w-full pt-6'
        onChange={(selectedItem: any) => {
          if (agencyDetails?.stateCode !== selectedItem?.value) {
            setAgencyDetails({
              ...agencyDetails,
              state: selectedItem?.label,
              stateCode: selectedItem?.value,
              suburb: '',
            })
            getSuburbsByStateCode(selectedItem.value)
          }
        }}
        options={stateList}
      />

      <div className='flex w-full pt-6 gap-x-5'>
        <CreatableInputSelect
          label='Suburb'
          className='w-2/3'
          value={{
            label: agencyDetails?.suburb,
            value: agencyDetails?.suburb,
          }}
          options={suburbList}
          onChange={handleSuburbChange}
          isDisabled={!agencyDetails?.stateCode}
          // isErrored={isErrorField?.suburb === '' ? true : false}
        />

        <TextField
          className='w-auto'
          value={agencyDetails?.postCode}
          onChange={(e: any) => {
            setAgencyDetails({
              ...agencyDetails,
              postCode: e.target.value,
            })
          }}
          label='Post Code'
          placeholder=''
          // isErrored={isErrorField?.postCode === '' ? true : false}
        />
      </div>

      {/* next button */}
      <Button
        isBlock
        onClick={onNextStepClick}
        isLoading={companyOnboardingIsLoading}
        className='mt-6 next-btn'>
        Next
      </Button>
    </div>
  )
}
