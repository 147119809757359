import { useMutation } from 'react-query'
import { postAPIwithFormData } from 'framework/api/http'

interface AddAudioClipInterface {
  refereeId: string
  audioFile: any
}

export const useAddAudioClip = () => {
  const mutate = useMutation(
    ({ refereeId, audioFile }: AddAudioClipInterface) => {
      const bodyFormData = new FormData()

      bodyFormData.append('Audio', audioFile)

      return postAPIwithFormData(
        `/referee/${refereeId}/uploadAudio`,
        bodyFormData,
        {},
      )
    },
  )

  return mutate
}

export default useAddAudioClip
