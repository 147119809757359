import { useEffect, useState } from 'react'
import { Button } from '@labourhub/labour-hub-ds'
import profileCompletion from 'assets/animations/agency-profile-completion.json'
import completedAward from 'assets/images/completedAward.svg'
import CN from 'classnames'
import { useLottie } from 'lottie-react'

type AgencyDetailProgressCardProps = {
  [x: string]: any
}

export const AgencyDetailProgressCard = ({
  agencyProfileDetails,
  ...restProps
}: AgencyDetailProgressCardProps) => {
  /** data completion lottie */
  const AnimatedView = () => {
    const options = {
      animationData: profileCompletion,
      loop: false,
      autoplay: true,
    }

    const { View } = useLottie(options)
    return View
  }

  /** completed data are storing in an array */

  const [completedDetailsArrayLength, setCompletedDetailsArrayLength] =
    useState(-1)

  useEffect(() => {
    if (
      agencyProfileDetails &&
      Object.keys(agencyProfileDetails).length !== 0
    ) {
      const completedDetails: string[] = []
      if (agencyProfileDetails?.name) {
        completedDetails.push(agencyProfileDetails?.name)
      }

      if (agencyProfileDetails?.abn) {
        completedDetails.push(agencyProfileDetails?.abn)
      }

      if (agencyProfileDetails?.website) {
        completedDetails.push(agencyProfileDetails?.website)
      }

      if (agencyProfileDetails?.size) {
        completedDetails.push(agencyProfileDetails?.size)
      }

      if (agencyProfileDetails?.address) {
        completedDetails.push(agencyProfileDetails?.address)
      }

      if (agencyProfileDetails?.phone) {
        completedDetails.push(agencyProfileDetails?.phone)
      }

      if (agencyProfileDetails?.email) {
        completedDetails.push(agencyProfileDetails?.email)
      }

      if (agencyProfileDetails?.logoUrl) {
        completedDetails.push(agencyProfileDetails?.logoUrl)
      }

      if (agencyProfileDetails?.privacyPolicy) {
        completedDetails.push(agencyProfileDetails?.privacyPolicy)
      }
      setCompletedDetailsArrayLength(completedDetails?.length)
    }
  }, [agencyProfileDetails])

  /** storing a variable inside local storage for showing the completion card */
  const [showCompletedCard, setShowCompletedCard] = useState<any>(
    localStorage.getItem('showCompletedCard'),
  )

  useEffect(() => {
    if (
      completedDetailsArrayLength !== 9 &&
      completedDetailsArrayLength !== -1
    ) {
      localStorage.setItem('showCompletedCard', 'true')
      setShowCompletedCard('true')
    }
  }, [completedDetailsArrayLength])

  return showCompletedCard !== 'false' ? (
    /** progress section of the agency profile details*/
    <div
      className='agencyProfile-about flex justify-between w-full h-auto overflow-y-auto border-[1px] border-Gray-200 rounded-md bg-white pb-4'
      {...restProps}>
      <div className='flex flex-col mt-5 ml-5'>
        <div className='flex justify-between w-[753px]'>
          <div className='flex flex-col'>
            {completedDetailsArrayLength === 9 ? (
              <span className='font-Regular text-Gray-800'>
                Yay! You have completed your company profile
              </span>
            ) : (
              <span className='font-SemiBold text-Gray-800'>
                {`You have completed ${completedDetailsArrayLength}/9 sections in your company profile so far`}
              </span>
            )}

            {completedDetailsArrayLength === 9 ? (
              <span className='font-Regular text-small text-Gray-500'>
                You may edit your profile details at any time in the edit view
              </span>
            ) : (
              <span className='font-Regular text-small text-Gray-500 pt-1'>
                Go to company profile edit view and fill out the rest of the
                information
              </span>
            )}
          </div>

          {completedDetailsArrayLength === 9 ? (
            <div className='w-12'>
              <AnimatedView />
            </div>
          ) : (
            <div>
              <img src={completedAward} alt='' className='' />
            </div>
          )}
        </div>

        {/** Progress bar section */}
        <div className='w-full h-1 rounded bg-Gray-100 mt-5'>
          <div
            className={CN('h-1 rounded bg-Green-500', {
              'w-[11.1%]': completedDetailsArrayLength === 1,
              'w-[22.2%]': completedDetailsArrayLength === 2,
              'w-[33.3%]': completedDetailsArrayLength === 3,
              'w-[44.4%]': completedDetailsArrayLength === 4,
              'w-[55.5%]': completedDetailsArrayLength === 5,
              'w-[66.6%]': completedDetailsArrayLength === 6,
              'w-[77.7%]': completedDetailsArrayLength === 7,
              'w-[88.8%]': completedDetailsArrayLength === 8,
              'w-full': completedDetailsArrayLength === 9,
            })}></div>
        </div>
      </div>

      {/** Close button */}
      {completedDetailsArrayLength === 9 && (
        <div className='mt-5 mr-5'>
          <Button
            size='sm'
            theme='white'
            onClick={() => {
              setShowCompletedCard('false')
              localStorage.setItem('showCompletedCard', 'false')
            }}
            textTheme='blue'>
            Close
          </Button>
        </div>
      )}
    </div>
  ) : (
    <div></div>
  )
}
