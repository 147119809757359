import {
  FixedSubscriptionPackageType,
  PayAsYouGoSubscriptionPackageType,
} from '../types'

export const fixedPackageSelectOptions = [
  {
    label: '15 Pack',
    value: FixedSubscriptionPackageType.FifteenPack,
  },
  {
    label: '25 Pack',
    value: FixedSubscriptionPackageType.TwentyfivePack,
  },
  {
    label: '50 Pack',
    value: FixedSubscriptionPackageType.FiftyPack,
  },
  {
    label: 'Enterprise Pack',
    value: FixedSubscriptionPackageType.EnterprisePack,
  },
]

export const payAsYouGoPackageSelectOptions = [
  {
    label: 'Police Check',
    value: PayAsYouGoSubscriptionPackageType.PoliceCheck,
  },
  {
    label: 'Volunteer Check',
    value: PayAsYouGoSubscriptionPackageType.VolunteerCheck,
  },
  {
    label: 'Right To Work Check',
    value: PayAsYouGoSubscriptionPackageType.RightToWorkCheck,
  },
  {
    label: 'Reference Check',
    value: PayAsYouGoSubscriptionPackageType.ReferenceCheck,
  },
]
