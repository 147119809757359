import * as React from 'react'
import { BeautifulMentionsMenuItemProps } from 'lexical-beautiful-mentions'

import { cn } from 'utils'

export const CustomMenuItem = React.forwardRef<
  HTMLLIElement,
  BeautifulMentionsMenuItemProps
>(({ selected, ...props }, ref) => (
  <li
    className={cn('flex px-4 py-2 border cursor-pointer border-Gray-200', {
      'bg-Cobalt-50 ': selected,
      'bg-white ': !selected,
    })}
    {...props}
    ref={ref}
  />
))
