import { DEFAULT_SETTINGS } from '../data'
import { AppSettingKey, AppSettingProps } from '../types'

// Check if the settings array exists and has elements
export const isSettingsExist = (settings: AppSettingProps[]): boolean => {
  return Array.isArray(settings) && settings.length > 0
}

// Get the setting of a specific setting key
export const getSetting = (
  key: AppSettingKey,
  settings: AppSettingProps[],
): AppSettingProps | undefined => {
  return settings.find((setting) => setting.key === key) ?? undefined
}

// Get the value of a specific setting key, or return the default value if not found
export const getSettingValue = (
  key: AppSettingKey,
  settings: AppSettingProps[],
): string => {
  const setting = settings.find((setting) => setting.key === key)
  return setting?.value ?? DEFAULT_SETTINGS[key]
}

// Check if a specific setting key is enabled (value is 'true')
export const isSettingEnabled = (
  key: AppSettingKey,
  settings: AppSettingProps[],
): boolean => {
  // if (!isSettingsExist(settings)) return false
  const setting = settings.find((setting) => setting.key === key)
  return setting?.value === 'true'
}

// Check if a specific setting key has the default value
export const isDefaultSetting = (
  key: AppSettingKey,
  settings: AppSettingProps[],
): boolean => {
  const setting = settings.find((setting) => setting.key === key)
  return setting?.value === DEFAULT_SETTINGS[key]
}

// Update the setting value based on a specific key
export const updateSettingByKey = (
  keyToUpdate: AppSettingKey,
  appSettings: AppSettingProps[],
  updatedProps: Partial<AppSettingProps>,
): AppSettingProps[] => {
  return appSettings.map((item) => {
    if (item.key === keyToUpdate) {
      return { ...item, ...updatedProps }
    }
    return item
  })
}
