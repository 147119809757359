import { useEffect } from 'react'

/** Close Notification Panel with Outside Click */
export const useOutsideClickIdentify = (
  identifier: string,
  ref: any,
  cd: any,
) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (identifier !== event.target.id) {
        if (ref.current && !ref.current.contains(event.target)) {
          cd()
        }
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}
