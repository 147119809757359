import { PaymentErrorType } from '../types'

export const isCreditCardVariant = (value?: string): boolean => {
  if (!value) return false

  return [
    'visa',
    'mastercard',
    'nab',
    'anz',
    'amex',
    'common-wealth',
    'union-pay',
    'westpac',
    'bank-of-melbourne',
    'blank',
  ].includes(value)
}

export const getPaymentErrorMessage = (paymentError?: PaymentErrorType) => {
  let errorMessage: string

  if (paymentError?.errorCode === 'card_declined') {
    if (paymentError?.declinedCode === 'generic_decline') {
      errorMessage =
        'We were unable to process your payment due to a generic decline. Please use an alternative payment method and try again.'
    } else if (paymentError?.declinedCode === 'insufficient_funds') {
      errorMessage =
        'We were unable to process your payment due to insufficient funds. Please use an alternative payment method and try again.'
    } else if (paymentError?.declinedCode === 'lost_card') {
      errorMessage =
        'We were unable to process your payment because your card has been reported as lost. Please use an alternative payment method and try again.'
    } else if (paymentError?.declinedCode === 'stolen_card') {
      errorMessage =
        'We were unable to process your payment because your card has been reported as stolen. Please use an alternative payment method and try again.'
    } else if (paymentError?.declinedCode === 'card_velocity_exceeded') {
      errorMessage =
        'We were unable to process your payment due to exceeding the card velocity limit. Please try again later.'
    } else {
      errorMessage =
        'We were unable to process your payment. Please use an alternative payment method and try again.'
    }
  } else if (paymentError?.errorCode === 'expired_card') {
    errorMessage =
      'We were unable to process your payment due to an expired card. Please use an alternative payment method and try again.'
  } else if (paymentError?.errorCode === 'incorrect_cvc') {
    errorMessage =
      'We were unable to process your payment due to an incorrect CVC (Card Verification Code). Please check and try again.'
  } else if (paymentError?.errorCode === 'processing_error') {
    errorMessage =
      'We were unable to process your payment due to a processing error. Please try again later.'
  } else if (paymentError?.errorCode === 'incorrect_number') {
    errorMessage =
      'We were unable to process your payment due to an incorrect card number. Please double-check and try again.'
  } else if (paymentError?.errorCode === 'downgrade') {
    errorMessage =
      paymentError?.message ??
      'We were unable to process your payment. Please use an alternative payment method and try again.'
  } else if (paymentError?.errorCode === 'upgrade') {
    errorMessage =
      paymentError?.message ??
      'We were unable to process your payment. Please use an alternative payment method and try again.'
  } else {
    errorMessage =
      'We were unable to process your payment. Please use an alternative payment method and try again.'
  }

  return errorMessage
}
