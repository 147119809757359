import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Modal, Toast, Toggle } from '@labourhub/labour-hub-ds'
import { PaymentCardDto, useMarkDefaultCustomerCard } from 'api/payments'

import { toggleIsRefetchPaymentCards } from '../../store'

import { CreditCard } from './CreditCard'

type CardSettingsModalProps = {
  paymentCard: PaymentCardDto
  isCardSettingsModalActive: boolean
  setIsCardSettingsModalActive: any
  setIsRemoveCardModalActive: any
}

export const CardSettingsModal = ({
  paymentCard,
  isCardSettingsModalActive,
  setIsCardSettingsModalActive,
  setIsRemoveCardModalActive,
}: CardSettingsModalProps) => {
  const dispatch = useDispatch()
  const notify = (props: any) => Toast(props)

  const [isDefaultCard, setIsDefaultCard] = useState(paymentCard.isDefaultCard)

  useEffect(() => {
    setIsDefaultCard(paymentCard.isDefaultCard)
  }, [paymentCard])

  const { mutate } = useMarkDefaultCustomerCard()

  const handleToggle = (e: any) => {
    const isDefaultCard = e.target.checked
    setIsDefaultCard(isDefaultCard)
    mutate(
      {
        paymentCardId: paymentCard.id,
        isDefaultCard,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData.message,
            alertBody: '',
            status: 'Success',
          })
          setIsDefaultCard(true)
        },
        onError: ({ response: { data: errData } }: any) => {
          setIsDefaultCard(!isDefaultCard)
          notify({
            alertHeader:
              errData?.errorCode === 409
                ? 'Cannot Remove Default Setting'
                : 'Error...!',
            alertBody:
              errData?.errorCode === 409
                ? 'Please set another card as the default for any payments before disabling this option.'
                : errData?.message,
            status: 'Error',
          })
          setIsDefaultCard(paymentCard.isDefaultCard)
        },
        onSettled: () => {
          dispatch(toggleIsRefetchPaymentCards())
        },
      },
    )
  }

  return (
    <Modal
      isActive={isCardSettingsModalActive}
      className='!z-[150] py-10 w-full'
      modalProps={{ style: { width: 'auto', height: 'auto' } }}
      isSeparator={true}
      isHeaderShow={true}
      headerTitle='Card Settings'
      headerCloseButtonProps={{
        style: {
          fontSize: '22px',
        },
      }}
      onHeaderCloseButtonClick={() => setIsCardSettingsModalActive(false)}
      isFooterShow={true}
      footerProps={{
        style: {
          flexDirection: 'row-reverse',
        },
      }}
      primaryButtonTitle='Delete Card'
      primaryButtonProps={{
        theme: 'terracotta',
        textTheme: 'black',
        iconLeft: (
          <i className='text-[20px] ri-delete-bin-line text-TerraCotta-400' />
        ),
        style: {
          marginLeft: '12px',
        },
      }}
      onClickPrimaryBtn={() => {
        setIsCardSettingsModalActive(false)
        setIsRemoveCardModalActive(true)
      }}
      secondaryButtonTitle='Close'
      secondaryButtonProps={{
        theme: 'white',
        textTheme: 'black',
      }}
      onClickSecondaryBtn={() => setIsCardSettingsModalActive(false)}>
      <div className='flex items-start justify-between w-full h-full p-5 space-x-5'>
        <div className='pointer-events-none'>
          <CreditCard paymentCard={paymentCard} />
        </div>

        <div className='mt-3'>
          <Toggle
            label='Default for Any Payments'
            setValue={isDefaultCard}
            onToggleClick={(e: any) => handleToggle(e)}
          />
        </div>
      </div>
    </Modal>
  )
}
