import { useState } from 'react'
import { Button } from '@labourhub/labour-hub-ds'
import { CandidateProfileBasicDetailsType } from 'api/candidates'

import { MoreMenuButton } from 'components/atoms'
import { RowWithAlignment } from 'components/atoms/Row'

import { DeleteCandidateEmployeeModal } from './DeleteCandidateEmployeeModal'
import { EditCandidateModal } from './EditCandidateModal'
import { EditEmployeeModal } from './EditEmployeeModal'

type CandidateMenuSectionProps = {
  candidateDetails: CandidateProfileBasicDetailsType
  getCandidateById: any
}

export const CandidateMenuSection = ({
  candidateDetails,
  getCandidateById,
}: CandidateMenuSectionProps) => {
  const [showEditCandidateModal, setShowEditCandidateModal] = useState(false)
  const [showEditEmployeeModal, setShowEditEmployeeModal] = useState(false)

  const [showDeleteCandidateModal, setShowDeleteCandidateModal] =
    useState(false)

  return (
    <>
      <RowWithAlignment
        itemAlignment='center'
        justifyAlignment='between'
        className='h-[25px]'>
        {candidateDetails.isEmployee == false ? (
          <Button
            textTheme='blue'
            theme='link'
            className='focus:outline-none'
            onClick={() => setShowEditCandidateModal(true)}>
            <i className='ri-pencil-line mr-2' />
            <span className='text-[10px]'>Edit Profile</span>
          </Button>
        ) : (
          <Button
            textTheme='blue'
            theme='link'
            className='focus:outline-none'
            onClick={() => setShowEditEmployeeModal(true)}>
            <i className='ri-pencil-line mr-2' />
            <span className='text-[10px]'>Edit Employee</span>
          </Button>
        )}
        <MoreMenuButton
          isBaseIconVertical={false}
          actionButtons={[
            {
              onClick: () => setShowDeleteCandidateModal(true),
              title: 'Delete',
              status: 'danger',
            },
          ]}
        />
      </RowWithAlignment>

      {candidateDetails.isEmployee == false ? (
        <EditCandidateModal
          isModalActive={showEditCandidateModal}
          setIsModalActive={setShowEditCandidateModal}
          candidateDetails={candidateDetails}
          getCandidateById={getCandidateById}
        />
      ) : (
        <EditEmployeeModal
          isModalActive={showEditEmployeeModal}
          setIsModalActive={setShowEditEmployeeModal}
          candidateDetails={candidateDetails}
          getCandidateById={getCandidateById}
        />
      )}

      <DeleteCandidateEmployeeModal
        isModalActive={showDeleteCandidateModal}
        candidateDetails={candidateDetails}
        setIsModalActive={setShowDeleteCandidateModal}
        candidateId={candidateDetails?.candidateId}
      />
    </>
  )
}
