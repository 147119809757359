export const QLD_CLIENT = {
  subdomainList: [
    'dfsdscs-Townsville',
    'dfsdscs-Rockhampton',
    'dfsdscs-Maryborough',
    'dfsdscs-SunshineCoast',
    'dfsdscs-Redcliffe',
    'dfsdscs-Nundah',
    'dfsdscs-Oxley',
    'dfsdscs-Loganlea',
    'dfsdscs-GoldCoast',
    'dfsdscs-Ipswich',
    'dfsdscs-Toowoomba',
    'dfsdscs-CBD',
  ],
  previousTitle: 'Consultant',
  newTitle: 'Selection Panel Member',
}
