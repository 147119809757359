import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'
import { QueryParams } from 'types/queryParams'

export type SmsProviderQueryParams = QueryParams & {
  providerName?: string | undefined
}

const getSmsProviderList = async ({ queryKey }: any) => {
  const { take, skip, search, isActive, providerName } =
    queryKey[1] as SmsProviderQueryParams

  let URI = `/SMS/Providers?take=${take}&skip=${skip}`

  if (search) {
    URI += `&search=${search}`
  }

  if (isActive == true || isActive == false) {
    URI += `&isActive=${isActive}`
  }

  if (providerName) {
    URI += `&providerName=${providerName}`
  }

  const res = await get(URI)
  return res.data
}

export const useGetSmsProviderList = (
  queryParams: SmsProviderQueryParams,
  onSuccess: (data: any) => void,
) => {
  return useQuery(['SmsProviders', queryParams], getSmsProviderList, {
    enabled: true,
    refetchOnWindowFocus: false,
    onSuccess,
    onError: () => {
      Toast({
        alertHeader: 'Error fetching sms providers list',
        status: 'Error',
      })
    },
  })
}
