export const customSurveyList = [
  {
    id: '52f3e804-a08d-4818-a82a-1b0d49151faf',
    imagePath: 'images/candidates/custom-surveys/CSPerfomanceReviewSurvey.svg',
    title: 'Performance Review Survey',
    description: 'Review employee performance',
    isCustom: false,
  },
  {
    id: '52f3e804-a08d-4818-a82a-1b0d49152faf',
    imagePath: 'images/candidates/custom-surveys/CSInterviewSurvey.svg',
    title: 'Interview Survey',
    description: 'Assess candidate suitability',
    isCustom: false,
  },
  {
    id: '52f3e804-a08d-4818-a82a-1b0d49153faf',
    imagePath: 'images/candidates/custom-surveys/CSEmployeeExitSurvey.svg',
    title: 'Employee Exit Survey',
    description: 'Gather feedback for improving employee experience',
    isCustom: false,
  },
  {
    id: '52f3e804-a08d-4818-a82a-1b0d49154faf',
    imagePath: 'images/candidates/custom-surveys/CSPre-screeningSurvey.svg',
    title: 'Pre-screening Survey',
    description: 'Assess candidate qualifications',
    isCustom: false,
  },
  {
    id: '52f3e804-a08d-4818-a82a-1b0d49155faf',
    imagePath:
      'images/candidates/custom-surveys/CSEmployeeSatisfactionSurvey.svg',
    title: 'Employee Satisfaction Survey',
    description: 'Gauge employee satisfaction',
    isCustom: false,
  },
]
