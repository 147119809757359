import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import CN from 'classnames'
import { InvoiceDto, PaymentInvoiceModal } from 'features/invoices'
import { FixedSubscriptionPaymentInvoiceModal } from 'features/invoices/components/FixedSubscriptionPaymentInvoiceModal'
import {
  CardBillingAddressDto,
  getPaymentStore,
  useGetPaymentBillingHistoryList,
} from 'features/payments'
import { SubscriptionPackageType } from 'features/subscriptions'
import { TenantIdProp } from 'types'
import { QueryParams } from 'types/queryParams'

import { EmptyTableType, UITable } from 'components/common/UITable'

import BillingHistoryTableColumn from './BillingHistoryTableColumn'

export const BillingHistoryTable = ({ tenantId }: TenantIdProp) => {
  const [tableData, setTableData] = useState<any>({})

  const [invoice, setInvoice] = useState<InvoiceDto | null>(null)
  const [cardBillingAddress, setCardBillingAddress] = useState<
    CardBillingAddressDto | undefined
  >(undefined)
  const [isInvoiceModalActive, setIsInvoiceModalActive] = useState(false)

  const { isRefetchBillingHistory } = useSelector(getPaymentStore)

  const [paginationVariables, setPaginationVariables] = useState<QueryParams>({
    skip: tableData.skip || 0,
    take: tableData.take || 20,
  })

  /** get subscriptions List on success */
  const onSuccess = (data: any) => {
    const toBeUpdateTableData = {
      ...data,
      subscriptions: data?.subscriptions?.map((item: any) => {
        return {
          ...item,
          showMore: false,
        }
      }),
    }

    setTableData(toBeUpdateTableData || {})
  }

  /** API Handling request for Get All Uniform List */
  const {
    refetch: refetchSubscriptionListTable,
    isLoading: getSubscriptionListIsLoading,
    isFetching: getSubscriptionListIsFetching,
  } = useGetPaymentBillingHistoryList(
    {
      take: paginationVariables.take,
      skip: paginationVariables.skip,
      tenantId,
    },
    onSuccess,
  )

  useEffect(() => {
    refetchSubscriptionListTable()
  }, [paginationVariables, isRefetchBillingHistory])

  /** Billing Table Column Handling */
  const tableColumns = useMemo(() => {
    if (tableData?.payments && tableData?.payments?.length > 0) {
      const columns: any = BillingHistoryTableColumn(
        setInvoice,
        setIsInvoiceModalActive,
        setCardBillingAddress,
      )
      columns.splice(9, 1)
      return columns
    }
    return []
  }, [tableData])

  return (
    <div className='w-full h-full'>
      <div className='w-full h-[calc(90vh-100px)] relative'>
        <div
          className={CN(
            'styled-scroll overflow-auto w-full h-[calc(100%-55px)] bg-white',
          )}>
          <UITable
            data={tableData?.payments || []}
            isLoading={
              getSubscriptionListIsLoading || getSubscriptionListIsFetching
            }
            emptyTableType={EmptyTableType.Bill}
            className=''
            columns={tableColumns}
            allowRowSelection={false}
            isSorted={true}
            hasFooter={true}
            hasCheckBox={false}
            isHeader={true}
            isFilterEnabled={false}
            paginationVariables={paginationVariables}
            setPaginationVariables={setPaginationVariables}
            totalRowCount={tableData?.totalCount || 0}
            isManualSortBy={true}
          />
        </div>
      </div>
      {invoice?.subscription?.packageType == SubscriptionPackageType.Fixed ? (
        <FixedSubscriptionPaymentInvoiceModal
          isActive={isInvoiceModalActive}
          setIsActive={setIsInvoiceModalActive}
          invoice={invoice}
          cardBillingAddress={cardBillingAddress}
          isSuccess={invoice != null}
        />
      ) : (
        <PaymentInvoiceModal
          isActive={isInvoiceModalActive}
          setIsActive={setIsInvoiceModalActive}
          invoice={invoice}
          cardBillingAddress={cardBillingAddress}
          invoiceDownloadMode={true}
          isSuccess={invoice != null}
        />
      )}
    </div>
  )
}
