import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

export type PayloadCandidateType = {
  candidateId: string
  refereeIds: Array<string>
}

interface createWebRefEmailInterface {
  candidates: Array<PayloadCandidateType>
  customMessage?: string
  templateId?: string
  isStaffRequest?: boolean
  isCareerInterest?: boolean
}

export const useCreateWebRefEmail = () => {
  const mutate = useMutation(
    ({
      candidates,
      customMessage,
      templateId,
      isStaffRequest,
      isCareerInterest,
    }: createWebRefEmailInterface) => {
      return postAPI(`/facade/candidate/referee/sendWebRefEmail`, {
        candidates,
        templateId,
        customMessage,
        isStaffRequest,
        isCareerInterest,
      })
    },
  )

  return mutate
}

export default useCreateWebRefEmail
