import { ReactNode } from 'react'
import CN from 'classnames'

type PositionedIconWrapperProps = {
  showIcon?: boolean
  icon: ReactNode
  position: 'left' | 'center' | 'right'
  children: ReactNode
}

export const PositionedIconWrapper = ({
  showIcon = true,
  icon,
  position,
  children,
}: PositionedIconWrapperProps) => {
  return (
    <div
      className={`relative flex ${
        position === 'center' ? 'justify-center' : `justify-${position}`
      }`}>
      {showIcon && (
        <div
          className={CN('absolute w-6 h-8', {
            '-left-1': position === 'left',
            '-right-4': position === 'right',
            '-top-[0.6rem]': position === 'center',
          })}>
          {icon}
        </div>
      )}
      <div
        className={CN({
          'ml-2': showIcon && (position === 'right' || position === 'left'),
        })}>
        {children}
      </div>
    </div>
  )
}
