import React from 'react'

import { getS3File } from 'utils/getS3File'

export const VideoBackground = () => {
  return (
    <div className='video-background'>
      <video autoPlay muted loop disablePictureInPicture>
        <source src={getS3File('images/xmas/snow.mp4')} type='video/mp4' />
        Your browser does not support the video tag.
      </video>
    </div>
  )
}
