import { Modal, Toast } from '@labourhub/labour-hub-ds'
import { useDeleteCandidateNote } from 'api/candidates'
import { ModalProps } from 'types'

type DeleteNoteModalProps = ModalProps & {
  noteId: string
  getCandidateById: any
}

export const DeleteNoteModal = ({
  isModalActive,
  setIsModalActive,
  noteId,
  getCandidateById,
}: DeleteNoteModalProps) => {
  const {
    mutateAsync: deleteCandidateNote,
    isLoading: isLoadingDeleteCandidateNote,
  } = useDeleteCandidateNote()

  return (
    <Modal
      isActive={isModalActive}
      onClickPrimaryBtn={() => {
        deleteCandidateNote({ noteId }).then(() => {
          getCandidateById()
          setIsModalActive(false)
          Toast({
            alertHeader: `Note deleted successfully`,
            status: 'Success',
          })
        })
      }}
      onClickSecondaryBtn={() => setIsModalActive(false)}
      primaryButtonProps={{
        isLoading: isLoadingDeleteCandidateNote,
        style: { width: '100%', marginRight: '12px' },
      }}
      secondaryButtonProps={{
        style: { width: '100%', color: '#1F2937' },
      }}
      onOverlayClick={() => {
        setIsModalActive(false)
      }}
      modalProps={{
        style: { width: '466px' },
      }}
      footerProps={{
        style: {
          justifyContent: 'space-between',
          paddingLeft: '23px',
          paddingRight: '23px',
        },
      }}
      isHeaderShow={false}
      primaryButtonTitle='Yes, I’m Sure'
      secondaryButtonTitle='Close'>
      <div className='flex flex-col items-center justify-center p-6'>
        <div className='flex items-center justify-center w-12 h-12 rounded-full bg-Red-100'>
          <i className='ri-delete-bin-6-line text-Red-500 text-heading-3'></i>
        </div>
        <>
          <span className='pt-5 text-Gray-900 text-heading-5 font-Medium'>
            Delete Note?
          </span>

          <span className='pt-2 text-center text-Gray-500 text-small font-Regular'>
            Are you sure you want to delete note?
          </span>
        </>
      </div>
    </Modal>
  )
}
