import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

type GetRefereesByCandidateResponse = {
  message: string
  messageTitle: string | null
  refereeStatus: string | null
  referees: CandidateReferee[]
}

export type ReportQuestion = {
  questionID: string
  questionType: string
  index: number
  questionNumber: number
  question: string
  answer: any
  answerDetails?: string
  ratingCount: number
}

export type CandidateReferee = {
  candidateCompany: string
  candidateId: string
  candidatePosition: string
  candidateRelation: string
  requestSendDate: string
  company: string
  createdOn: string
  email: string
  endDate: string
  firstName: string
  id: string
  jobTitle: string
  lastName: string
  phone: string
  responseDate: string | null
  lastSendRequest: string | null
  sendDate: string | null
  startDate: 'string'
  state:
    | 'RESPONSE_RECEVIED'
    | 'AWAITING_RESPONSE'
    | 'REQUEST_PHONE_REF'
    | 'REQUEST_DECLINED'
    | 'NOT_SENT'
    | null
  status: null | 'Failed' | 'Incomplete' | 'Passed'
  templateId: string | null
  tenantId: string
  updatedOn: string
  refereeResponse: {
    responseType: 'PHONE_CHECK' | 'WEB_CHECK'
    fraudDetectedDate: string | null
    htmlResponse: string
    jsonSchema: null | string
    jsonUiSchema: null | string
    data: null | object
    jsonData: null | string
    ipAddress: string
    city: string
    region: string
    country: string
    id: string
    createdOn: string
    updatedOn: string
    reportQuestions: ReportQuestion[]
    aiSentimentPercentage: {
      positiveSentiment: number
      negativeSentiment: number
      neutralSentiment: number
    }
  }
  referenceDoneBy?: string | null
  referenceDoneDate?: string | null
  referenceType?: any
  isCurrentlyWorking?: boolean
}

export const useGetRefereesByCandidate = (candidateId: string) => {
  return useQuery(
    ['GET_REFEREES_BY_CANDIDATE', candidateId],
    async () => {
      if (candidateId !== '') {
        const data = await get(`/Candidate/${candidateId}/Referee`)
        return (data?.data as GetRefereesByCandidateResponse) || []
      }
    },
    {
      enabled: true,
      onError: () => {
        Toast({
          alertHeader: 'Error fetching referee list',
          status: 'Error',
        })
      },
      refetchOnWindowFocus: false,
      retry: 1,
    },
  )
}
