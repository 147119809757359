import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface addRightToWorkCheckInterface {
  CandidateId?: string
}

export const useAddRightToWorkCheck = () => {
  const mutate = useMutation(
    ({ CandidateId }: addRightToWorkCheckInterface) => {
      return postAPI(`/NCC/RtwChecks`, {
        CandidateId,
      })
    },
  )

  return mutate
}

export default useAddRightToWorkCheck
