import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Button } from '@labourhub/labour-hub-ds'
import upgradeImage from 'assets/images/subscription/upgradeImage.svg'
import { ModalProps } from 'types'

import { Modal } from 'components/molecules'
import { dateToReadableString, formatCurrency } from 'utils'

import { getSubscriptionStore } from '../store'
import {
  FixedSubscriptionPackageDto,
  SubscriptionMode,
  SubscriptionPlanType,
  SubscriptionType,
} from '../types'

import { SubscriptionPlanPackageBadge } from './SubscriptionPlanPackageBadge'

type SubscriptionUpgradeModalProps = ModalProps & {
  setIsSubscriptionPaymentModalActive?: any
  subscriptionPackage: FixedSubscriptionPackageDto
  setSubscriptionMode: any
}

export const SubscriptionUpgradeModal = ({
  isModalActive,
  setIsModalActive,
  setIsSubscriptionPaymentModalActive,
  subscriptionPackage,
  setSubscriptionMode,
}: SubscriptionUpgradeModalProps) => {
  const {
    activeSubscriptions: { fixedSubscription },
  } = useSelector(getSubscriptionStore)

  const displayAmount = useMemo(() => {
    const packageDiscount = subscriptionPackage.promotionDiscountRate ?? 0

    const amount =
      packageDiscount > 0
        ? subscriptionPackage.packageAmount * (1 - packageDiscount)
        : subscriptionPackage.packageAmount

    const formattedOriginalAmount = formatCurrency(
      subscriptionPackage.packageAmount,
    )
    const formattedAmount = formatCurrency(amount)

    return (
      <span>
        {packageDiscount > 0 ? (
          <>
            <small>
              <del className='text-Red-600'>{formattedOriginalAmount}</del>{' '}
            </small>
            <b>{formattedAmount}</b>{' '}
          </>
        ) : (
          <b>{formattedOriginalAmount}</b>
        )}
      </span>
    )
  }, [subscriptionPackage])

  return (
    <Modal
      isActive={isModalActive}
      onHeaderCloseButtonClick={() => {
        setIsModalActive(false)
      }}
      onClickSecondaryBtn={() => {
        setIsModalActive(false)
      }}
      onOverlayClick={() => {
        setIsModalActive(false)
      }}
      modalProps={{
        style: { width: '600px' },
      }}
      headerTitle='Upgrade Plan'>
      <div className='flex flex-col items-center justify-center w-full px-6 pt-6 pb-10 border-b border-b-Gray-200'>
        <img
          src={upgradeImage}
          alt={'upgrade'}
          className='object-fill h-[132px]'
        />

        <div className='mt-8 text-center text-small font-Medium'>
          When you upgrade to a new plan, the change takes effect as of the
          upgrade date. You will be billed monthly from this date onward. Rest
          assured, any credits from your previous plan will still be available
          to you.
        </div>
      </div>

      <div className='flex w-full'>
        <div className='flex flex-col justify-center w-1/2 p-4 border-r border-r-Gray-200'>
          <div className='flex items-center justify-between w-full'>
            <SubscriptionPlanPackageBadge
              packageName={
                fixedSubscription?.package?.name ===
                SubscriptionPlanType.TwentyfivePack
                  ? SubscriptionPlanType.TwentyfivePack
                  : fixedSubscription?.package?.name ===
                    SubscriptionPlanType.FifteenPack
                  ? SubscriptionPlanType.FifteenPack
                  : SubscriptionPlanType.FiftyPack
              }
              size='small'
              isFill={true}
            />

            <div className='w-fit h-fit text-extra-small font-Medium px-[10px] py-[2px] text-[#5B21B6] bg-[#EDE9FE] rounded-[10px] mt-1'>
              Current Pack
            </div>
          </div>

          <div className='flex flex-col mt-5'>
            <div className='flex items-end justify-start h-fit'>
              <div className='text-[32px] leading-none font-SemiBold'>
                ${fixedSubscription?.invoiceAmountWithoutTax}
              </div>
              <div className='text-[12px] leading-none font-Medium text-[#9CA3AF] pl-[5px] pb-[3px]'>
                {fixedSubscription?.package?.subscriptionType}
              </div>
            </div>
            <div className='text-small text-Gray-400'>
              {fixedSubscription?.isCanceled ? 'Expries on' : 'Renews on:'}{' '}
              {dateToReadableString(
                fixedSubscription?.isCanceled
                  ? fixedSubscription?.endDate
                  : fixedSubscription?.renewalDate,
              )}
            </div>

            <Button
              textTheme={'black'}
              theme={'white'}
              className='mt-5 w-fit'
              onClick={() => {
                setIsModalActive(false)
              }}>
              Keep plan
            </Button>
          </div>
        </div>

        <div className='flex flex-col justify-center w-1/2 p-4'>
          <div className='flex items-center justify-between w-full'>
            <SubscriptionPlanPackageBadge
              packageName={
                subscriptionPackage?.name ===
                SubscriptionPlanType.TwentyfivePack
                  ? SubscriptionPlanType.TwentyfivePack
                  : subscriptionPackage?.name ===
                    SubscriptionPlanType.FifteenPack
                  ? SubscriptionPlanType.FifteenPack
                  : SubscriptionPlanType.FiftyPack
              }
              size='small'
              isFill={true}
            />

            <div className='w-fit h-fit text-extra-small font-Medium px-[10px] py-[2px] text-[#5B21B6] bg-[#EDE9FE] rounded-[10px] mt-1'>
              New Pack
            </div>
          </div>

          <div className='flex flex-col mt-5'>
            <div className='flex items-end justify-start h-fit'>
              <div className='text-[32px] leading-none'>{displayAmount}</div>
              <div className='text-[12px] leading-none font-Medium text-[#9CA3AF] pl-[5px] pb-[3px]'>
                {subscriptionPackage?.subscriptionType}
              </div>
            </div>
            <div className='text-small text-Gray-400'>
              Per{' '}
              {subscriptionPackage.subscriptionType == SubscriptionType.Monthly
                ? 'Month'
                : 'Year'}{' '}
              Ex GST (billed{' '}
              {subscriptionPackage.subscriptionType == SubscriptionType.Monthly
                ? 'Monthly'
                : 'Yearly'}
              )
            </div>

            <Button
              textTheme={'white'}
              theme={'cobalt'}
              className='mt-5 w-fit'
              onClick={() => {
                setIsModalActive(false)
                setIsSubscriptionPaymentModalActive(true)
                setSubscriptionMode(SubscriptionMode.Upgrade)
              }}>
              Upgrade Pack
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
