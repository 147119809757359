import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, TextField, Toast } from '@labourhub/labour-hub-ds'
import {
  getOnboardingStore,
  setCurrentStep,
  setGuideStepperCandidate,
} from 'features/onboarding/store'

import { PhoneInputField } from 'components/atoms'
import { isEmail, isLetters, isNumber, isPhoneNumber } from 'utils'

export const AddCandidate = () => {
  const dispatch = useDispatch()
  const notify = (props: any) => Toast(props)

  const { userDetails } = useSelector((state: any) => state.user)

  const { currentStep } = useSelector(getOnboardingStore)

  const [candidateDetails, setCandidateDetails] = useState({
    title: '',
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    phone: '',
    countryCode: 'AU',
    dateOfBirth: '',
    gender: '',
    jobTitle: '',
    noOfReferences: '',
    consultantId: userDetails?.id,
    candidateSettings: [] as any,
  })

  const [isErrorField, setIsErrorField] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
    noOfReferences: false,
    jobTitle: false,
  })

  const validateInputs = () => {
    const { firstName, lastName, email, phone, noOfReferences, jobTitle } =
      candidateDetails

    setIsErrorField({
      ...isErrorField,
      firstName: isLetters(firstName) && firstName?.length < 100 ? false : true,
      lastName: isLetters(lastName) && lastName?.length < 100 ? false : true,
      email: isEmail(email) && email?.length < 200 ? false : true,
      phone: isPhoneNumber(phone) && phone?.length < 20 ? false : true,
      noOfReferences:
        isNumber(noOfReferences) && noOfReferences?.length < 100 ? false : true,
      jobTitle: isLetters(jobTitle) && jobTitle?.length < 100 ? false : true,
    })

    const isAllValidFields =
      isLetters(firstName) &&
      firstName?.length < 100 &&
      isLetters(lastName) &&
      lastName?.length < 100 &&
      isEmail(email) &&
      email?.length < 200 &&
      isPhoneNumber(phone) &&
      phone?.length < 20 &&
      isNumber(noOfReferences) &&
      noOfReferences?.length < 100 &&
      jobTitle?.length < 100 &&
      isLetters(jobTitle)

    return isAllValidFields
  }

  const onAddNewCandidate = () => {
    const isValid = validateInputs()

    if (isValid) {
      dispatch(setGuideStepperCandidate(candidateDetails))
      dispatch(setCurrentStep(currentStep + 1))
    } else {
      notify({
        alertHeader: 'Invalid input',
        alertBody: 'Please check your input fields and try again.',
        status: 'Warning',
      })
    }
  }

  return (
    <div className='px-5 pt-3 pb-2'>
      <TextField
        label='First Name'
        isRequired
        value={candidateDetails?.firstName}
        onChange={(e: any) => {
          setCandidateDetails({
            ...candidateDetails,
            firstName: e.target.value,
          })
        }}
        placeholder=''
        isErrored={isErrorField.firstName}
      />
      <TextField
        label='Last Name'
        isRequired
        className='mt-5'
        value={candidateDetails?.lastName}
        onChange={(e: any) => {
          setCandidateDetails({
            ...candidateDetails,
            lastName: e.target.value,
          })
        }}
        placeholder=''
        isErrored={isErrorField.lastName}
      />
      <TextField
        label='Email'
        isRequired
        className='mt-5'
        value={candidateDetails?.email}
        onChange={(e: any) => {
          setCandidateDetails({
            ...candidateDetails,
            email: e.target.value,
          })
        }}
        placeholder=''
        isErrored={isErrorField.email}
      />
      <PhoneInputField
        label='Phone'
        isRequired
        value={candidateDetails?.phone}
        onChangeWithCountry={(number: any, countryCode: string) =>
          setCandidateDetails({
            ...candidateDetails,
            phone: number,
            countryCode,
          })
        }
        className='mt-5'
        isErrored={isErrorField.phone}
      />

      <TextField
        label='Role candidate is applying for'
        isRequired
        className='mt-5'
        value={candidateDetails?.jobTitle}
        onChange={(e: any) => {
          setCandidateDetails({
            ...candidateDetails,
            jobTitle: e.target.value,
          })
        }}
        placeholder=''
        isErrored={isErrorField.jobTitle}
      />
      <TextField
        label='No of Reference'
        className='mt-5'
        isRequired
        type='number'
        min={1}
        value={candidateDetails?.noOfReferences}
        onChange={(e: any) => {
          if (e.target.value > 0 && e.target.value <= 5) {
            setCandidateDetails({
              ...candidateDetails,
              noOfReferences: e.target.value,
            })
          }
        }}
        placeholder=''
        isErrored={isErrorField.noOfReferences}
      />

      <div className='flex flex-col justify-center mt-8'>
        <Button onClick={onAddNewCandidate} className='cursor-pointer'>
          Next
        </Button>
      </div>
    </div>
  )
}
