import { useMemo, useState } from 'react'
import { Button } from '@labourhub/labour-hub-ds'
import { CandidateProfileBasicDetailsType } from 'api/candidates'
import {
  NoteHistory,
  useListCandidateNoteHistory,
} from 'api/candidates/notes/listCandidateNoteHistory'
import EmptyNote from 'assets/images/emptyNote.svg'
import CN from 'classnames'

import { PageLoader } from 'components/atoms'
import { timeToAESTWithFormat } from 'utils'

import { AddHistoryRecordModal } from './AddHistoryRecordModal'
import { DeleteNoteModal } from './DeleteNoteModal'
import { EditNoteModal } from './EditNoteModal'
import { HistoryItemCard, NoteItemCard } from './HistoryItemCard'

type HistoryTabProps = {
  candidateId: string
  candidateDetails: CandidateProfileBasicDetailsType
  getCandidateById: any
}

export const HistoryTab = ({
  candidateId,
  candidateDetails,
  getCandidateById,
}: HistoryTabProps) => {
  const [isAddHistoryModalOpen, setIsAddHistoryModalOpen] = useState(false)

  const [historyList, setHistoryList] = useState<NoteHistory[]>([])

  const [isEditNoteModalActive, setIsEditNoteModalActive] = useState(false)

  const [isDeleteNoteModalActive, setIsDeleteNoteModalActive] = useState(false)

  const [selectedNoteId, setSelectedNoteId] = useState<string>('')

  const notes = useMemo(
    () => candidateDetails.notes.filter((note) => note.description) || [],
    [candidateDetails.notes],
  )

  /** API Handling request for Get All Uniform List */
  const {
    refetch: refetchListCandidateHistory,
    isFetching,
    isLoading,
  } = useListCandidateNoteHistory(
    {
      candidateId,
    },
    (data: NoteHistory[]) => setHistoryList(data?.reverse()),
  )

  if (isLoading || isFetching)
    return <PageLoader size='xxs' className='absolute' />

  return (
    <div className='flex flex-col items-center justify-between w-full'>
      <div className='h-[237px] w-[380px] styled-scroll overflow-y-auto pr-2'>
        {historyList &&
          historyList?.map?.((item: NoteHistory, idx: any) => (
            <div key={idx} className='relative flex w-full '>
              {/* detail card */}
              <div className='absolute flex flex-col items-center h-full'>
                <div className='bg-Cobalt-600 w-[10px] h-[10px] rounded-full' />
                <div
                  className={CN('bg-Cobalt-600 w-[2px] h-full', {
                    '!h-[calc(100%-22.5px)]': idx + 1 === historyList?.length,
                    'h-full': idx + 1 !== historyList?.length,
                  })}
                />
              </div>
              <div className='w-full ml-5 '>
                <HistoryItemCard
                  icon={item?.notes.icon}
                  description={
                    item.previousDescription || item?.notes.description
                  }
                  date={
                    item?.updatedOn
                      ? timeToAESTWithFormat(
                          item?.updatedOn,
                          'h:mm A, DD MMM YYYY',
                        )
                      : ''
                  }
                  title={item?.message}
                />
              </div>
            </div>
          ))}
      </div>

      {notes.length > 0 ? (
        <>
          <div className='flex flex-row justify-between w-full mt-5 mb-5'>
            <span className='flex items-center text-small font-Medium'>
              Note
            </span>
            <Button
              className='w[100px] h-[30px]'
              onClick={() => {
                setIsAddHistoryModalOpen(true)
              }}>
              <span className='flex items-center text-extra-small font-Medium'>
                <i className='flex items-center ri-add-fill text-base text-white mr-2' />
                Add Note
              </span>
            </Button>
          </div>
          <div className='h-[103px] w-[365px] pr-1 overflow-y-auto styled-scroll'>
            {notes.map((note) => {
              return note?.description && note?.description != '' ? (
                <NoteItemCard
                  key={note.id}
                  noteId={note.id}
                  description={note?.description}
                  setIsEditNoteModalActive={setIsEditNoteModalActive}
                  setIsDeleteNoteModalActive={setIsDeleteNoteModalActive}
                  setSelectedNoteId={setSelectedNoteId}
                />
              ) : null
            })}
          </div>
        </>
      ) : (
        <>
          <div className=' w-full mt-5 mb-1 text-small font-Medium'>Note</div>
          <div className='border border-Gray-100 rounded-md h-[130px] w-[370px] flex flex-col items-center justify-center'>
            <img
              src={EmptyNote}
              alt='empty-note'
              className='h-[32px] text-Gray-500'
            />

            <div className='flex flex-col items-center mt-[6px]'>
              <span className='font-Regular text-extra-small text-Gray-600'>
                We’re sorry, we could not find any note for you.
              </span>
              <Button
                className='w[100px] h-[30px] mt-2'
                onClick={() => {
                  setIsAddHistoryModalOpen(true)
                }}>
                <span className='flex items-center text-extra-small font-Medium'>
                  <i className='flex items-center ri-add-fill text-base text-white pr-2' />
                  Add Note
                </span>
              </Button>
            </div>
          </div>
        </>
      )}

      <DeleteNoteModal
        isModalActive={isDeleteNoteModalActive}
        setIsModalActive={setIsDeleteNoteModalActive}
        noteId={selectedNoteId}
        getCandidateById={getCandidateById}
      />

      <EditNoteModal
        isModalActive={isEditNoteModalActive}
        setIsModalActive={setIsEditNoteModalActive}
        noteId={selectedNoteId}
        getCandidateById={getCandidateById}
        description={
          notes.find((note) => note.id === selectedNoteId)?.description || ''
        }
      />

      <AddHistoryRecordModal
        isModalActive={isAddHistoryModalOpen}
        setIsModalActive={setIsAddHistoryModalOpen}
        candidateId={candidateId}
        getListData={refetchListCandidateHistory}
        getCandidateById={getCandidateById}
      />
    </div>
  )
}
