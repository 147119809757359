type FooterProps = {
  [x: string]: any
}

export const Footer = ({ ...restProps }: FooterProps) => {
  return (
    <div
      className='absolute bottom-0 flex items-center justify-between w-full px-6 pb-8 main-layout__Footer'
      {...restProps}>
      {/* footer left */}
      <div className='flex flex-col text-extra-small'>
        <p className='text-Gray-500'>
          © {new Date().getFullYear()} Refhub. All rights reserved.
        </p>

        <div className='flex pt-2 divide-x divide-Gray-200'>
          <a
            className='pr-2 text-Cobalt-500 active:outline-none focus:outline-none'
            href='https://www.refhub.com.au/privacy-policy'
            target='_blank'>
            Terms and conditions
          </a>

          <a
            className='pl-2 text-Cobalt-500 active:outline-none focus:outline-none'
            href='https://craft.me/s/1E9yDjlzX5Ax2A'
            target='_blank'>
            Help
          </a>
        </div>
      </div>
    </div>
  )
}
