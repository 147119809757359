import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/reducers'
import { setIsBreadcrumbVisible } from 'store/reducers/breadcrumbs/breadcrumbs'

export const useBreadcrumbs = () => {
  const { menuItems } = useSelector((state: RootState) => state.sideMenuList)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setIsBreadcrumbVisible(true))

    return () => {
      dispatch(setIsBreadcrumbVisible(false))
    }
  }, [Location, menuItems])
}
