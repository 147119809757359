import { createSlice } from '@reduxjs/toolkit'

export type CandidateStoreProps = {
  candidates: any
  isRefreshCandidateTable: boolean
  isNavigatingFromNewAssessmentModal: boolean
}

const initialState: CandidateStoreProps = {
  candidates: null,
  isRefreshCandidateTable: false,
  isNavigatingFromNewAssessmentModal: false,
}

const candidateSlice = createSlice({
  name: 'candidate',
  initialState,
  reducers: {
    setCandidatesState(state, { payload }) {
      state.candidates = payload
    },
    setIsRefreshCandidateTableState(state) {
      state.isRefreshCandidateTable = !state.isRefreshCandidateTable
    },
    setIsNavigatingFromNewAssessmentModalState(state, { payload }) {
      state.isNavigatingFromNewAssessmentModal = payload
    },
  },
})

export const {
  setCandidatesState,
  setIsRefreshCandidateTableState,
  setIsNavigatingFromNewAssessmentModalState,
} = candidateSlice.actions

export default candidateSlice.reducer
