import { useSelector } from 'react-redux'
import cn from 'classnames'
import { getIsNewUser } from 'features/subscriptions'

export const LegacyPromotion = ({ isSmall = false }: { isSmall?: boolean }) => {
  const isNewUser = useSelector(getIsNewUser)

  const isSmallClasses = {
    title: 'text-base',
    paragraph: 'text-extra-small',
    accent: 'text-small',
  }

  if (!isNewUser)
    return (
      <div className={cn('w-full my-1', isSmall && 'my-3')}>
        <div
          className={cn(
            'bg-Cobalt-100 py-3 px-6 rounded-lg max-w-[900px] mx-auto',
          )}>
          <h3
            className={cn(
              'text-Cobalt-600 text-heading-5 font-SemiBold mb-1',
              isSmall && isSmallClasses.title,
            )}>
            Enjoy Legacy Pricing for the next 6 Months!
          </h3>
          <p
            className={cn(
              'text-Gray-700 text-small',
              isSmall && isSmallClasses.paragraph,
            )}>
            As a valued client, you can continue to enjoy the original pricing
            for the next 6 months.
          </p>
          <p
            className={cn(
              'text-Gray-700 text-small',
              isSmall && isSmallClasses.paragraph,
            )}>
            Use code{' '}
            <span
              className={cn(
                'text-[#ff6f25] font-SemiBold',
                isSmall ? isSmallClasses.accent : 'text-base',
              )}>
              GRACE
            </span>{' '}
            at checkout to lock in your legacy pricing until{' '}
            <span
              className={cn(
                'text-[#ff6f25] font-SemiBold',
                isSmall ? isSmallClasses.accent : 'text-base',
              )}>
              March 18, 2025.
            </span>
          </p>
        </div>
      </div>
    )
  return null
}
