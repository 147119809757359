import { Modal, Toast } from '@labourhub/labour-hub-ds'
import { useDeleteExecutiveSummary } from 'api/executiveSummary'
import { ModalProps } from 'types'

type DeleteReportModalProps = ModalProps & {
  selectedReportId: string
  refetchRepotTable: any
}

export const DeleteReportModal = ({
  isModalActive,
  setIsModalActive,
  selectedReportId,
  refetchRepotTable,
}: DeleteReportModalProps) => {
  const notify = (props: any) => Toast(props)

  const { mutate, isLoading } = useDeleteExecutiveSummary()

  const onClickDelete = () => {
    mutate(
      { id: selectedReportId },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData.messageTitle
              ? successData.messageTitle
              : 'Executive summary template deleted successfully',
            alertBody: '',
            status: 'Success',
          })
          refetchRepotTable()
          setIsModalActive(false)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: errData?.messageTitle
              ? errData?.messageTitle
              : 'Error!',
            alertBody: errData?.message ? errData?.message : 'Error!',
            status: 'Error',
          })
        },
      },
    )
  }

  return (
    <Modal
      isActive={isModalActive}
      onClickPrimaryBtn={onClickDelete}
      className='!z-[150]'
      onClickSecondaryBtn={() => setIsModalActive(false)}
      primaryButtonProps={{
        isLoading,
        style: { width: '100%', marginRight: '12px' },
      }}
      secondaryButtonProps={{
        style: { width: '100%', color: '#1F2937' },
      }}
      modalProps={{
        style: { width: '480px' },
      }}
      footerProps={{
        style: {
          justifyContent: 'space-between',
          paddingLeft: '23px',
          paddingRight: '23px',
        },
      }}
      isHeaderShow={false}
      primaryButtonTitle="Yes, I'm Sure"
      secondaryButtonTitle='Close'>
      <div className='flex flex-col items-center justify-center p-6'>
        <div className='flex items-center justify-center w-12 h-12 rounded-full bg-Red-100'>
          <i className='ri-delete-bin-6-line text-Red-500 text-heading-3'></i>
        </div>

        <span className='pt-5 text-center text-Gray-900 text-heading-5 font-Medium'>
          Delete Executive Summary Report?
        </span>

        <p className='pt-2 text-center text-Gray-500 text-small font-Regular'>
          <span> Are you sure you want to proceed?</span>
        </p>
      </div>
    </Modal>
  )
}
