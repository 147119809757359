import PersonalityTestResult from 'components/molecules/AddonPreviewModal/PersonalityTestResultReport/PersonalityTestResult'
import {
  CombinedReportNameHeader,
  ReportFooter,
} from 'components/ui/reports/sections'

import AntiCheatinMonitor from '../AntiCheatinMonitor'
import AssessmentCompletedSection from '../DownloadReport/AssessmentCompletedSection'

type PersonalityTestCombineReportPreviewSectionProps = {
  reportViewData: any
  isPreview: boolean
  position?: number
}

const PersonalityTestCombineReportPreviewSection = ({
  reportViewData,
  isPreview,
  position = 0,
}: PersonalityTestCombineReportPreviewSectionProps) => {
  const pageBreakStyle: React.CSSProperties = {
    pageBreakBefore:
      position !== 1 ? 'always' : ('avoid' as 'always' | 'avoid'),
  }
  const antiCheatinMonitorStyle: React.CSSProperties = {
    pageBreakBefore:
      position === 1 ? 'always' : ('avoid' as 'always' | 'avoid'),
  }
  const mt8Class = position !== 1 && !isPreview ? 'mt-8' : ''

  return (
    <>
      <div className='m-3' style={pageBreakStyle}>
        <div className={mt8Class}>
          {position !== 1 && <CombinedReportNameHeader data={reportViewData} />}
        </div>

        <AssessmentCompletedSection reportViewData={reportViewData} />
        <div
          className={`my-4 ${!isPreview && position === 1 ? 'mt-8' : ''}`}
          style={antiCheatinMonitorStyle}>
          <AntiCheatinMonitor isReport reportViewData={reportViewData} />
        </div>
        <PersonalityTestResult
          reportViewData={reportViewData}
          isReport
          isPrint={!isPreview}
          position={position}
        />
      </div>
      {!isPreview && <ReportFooter isFixed={true} />}
    </>
  )
}

export default PersonalityTestCombineReportPreviewSection
