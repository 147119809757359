import { useEffect, useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print'
import { Button } from '@labourhub/labour-hub-ds'
import { assessmentReportPreviewData } from 'features/candidates/data/assessmentReportPreviewData'
import { ReportTypes } from 'features/settings/data/reportTypes'
import { ModalProps } from 'types'

import { PageLoader } from 'components/atoms'
import { Column } from 'components/atoms/Column'
import { Modal, ModalFooter } from 'components/molecules'
import { UserDetails } from 'components/molecules/AddonPreviewModal/TypedTestResultReport/UserDetails'
import TypingTestReportPreviewSection from 'components/molecules/AssesmentPreviewModal/Sections/TypingTestReportPreviewSection'
import { ReportBanner, ReportLogoHeader } from 'components/ui/reports/sections'

type TypeTestResultModalType = {
  candidateDetails: any
  reportViewData: any
  handleCloseModals: () => void
  isLoading?: boolean
  previewData: assessmentReportPreviewData | undefined
}

export default function TypeTestResultModal({
  isModalActive,
  setIsModalActive,
  candidateDetails,
  reportViewData,
  handleCloseModals,
  isLoading = false,
  previewData,
}: TypeTestResultModalType & ModalProps) {
  const [isPrinting, setIsPrinting] = useState(false)
  const componentRef = useRef<HTMLDivElement>(null)
  const promiseResolveRef = useRef<any>(null)

  const pageStyle = `
  @media print {
    @page {
      size: a4 !important;
      margin: 5mm 0 !important;
      padding: 0 !important;
    }
    ::-webkit-scrollbar {
      display: none;
    }
    body {
      margin: 0 !important;
      padding: 0 !important;
      scrollbar-width: none; 
      -ms-overflow-style: none; 
    }
  }
`

  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      promiseResolveRef.current()
    }
  }, [isPrinting])

  const handlePrint = useReactToPrint({
    pageStyle,
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        promiseResolveRef.current = resolve
        setIsPrinting(true)
      })
    },
    onBeforePrint: () => setIsModalActive(true),
    onAfterPrint: () => {
      promiseResolveRef.current = null
      setIsPrinting(false)
      handleCloseModals()
    },
  })

  const handleClose = () => {
    // setIsPrinting(false)
    setIsModalActive(false)
  }

  return (
    <Modal
      headerTitle={`Type Test LowerCase`}
      isSeparator={true}
      customWidth='794px'
      modalContainerClassNames='max-w-[794px]'
      isActive={isModalActive}
      modalProps={{
        style: { width: '800px', height: '95vh' },
      }}
      isFooterShow={false}
      size='lg'
      className='!z-[300]'
      onClickSecondaryBtn={handleClose}
      onHeaderCloseButtonClick={handleClose}
      onOverlayClick={handleClose}
      secondaryButtonTitle='Close'>
      {isLoading ? (
        <PageLoader size='xxs' />
      ) : (
        <>
          <div className='relative flex flex-col p-0 overflow-x-hidden overflow-y-auto styled-scroll bg-Gray-50'>
            <Column className='bg-Gray-50 w-[794px] pointer-events-none'>
              <div
                ref={componentRef}
                className='a4-preview w-full h-full p-0 m-0'>
                <div className='h-auto relative'>
                  <ReportLogoHeader
                    clientlogo={previewData?.clientLogoUrl}
                    hasClientLogo={previewData?.hasClientLogo}
                    hasCompanyLogo={previewData?.hasCompanyLogo}
                  />
                  <ReportBanner type={ReportTypes.ASSESSMENT} />
                  <div className='m-3'>
                    <UserDetails
                      candidateDetails={candidateDetails}
                      reportViewData={reportViewData}
                    />
                  </div>
                  <TypingTestReportPreviewSection
                    reportViewData={reportViewData}
                    isPrinting={isPrinting}
                  />
                </div>
              </div>
            </Column>
          </div>
          <ModalFooter>
            <div className='flex justify-end flex-1'>
              <Button onClick={handleClose} textTheme='blue' theme='white'>
                Cancel
              </Button>
              <Button
                onClick={handlePrint}
                // isLoading={questionsUpdateLoading}
                // isDisabled={questionsUpdateLoading}
                className='ml-2'
                theme='cobalt'>
                Download
              </Button>
            </div>
          </ModalFooter>
        </>
      )}
    </Modal>
  )
}
