import { ForwardedRef, forwardRef, useMemo, useRef, useState } from 'react'
import { Button } from '@labourhub/labour-hub-ds'
import { CandidateProfileBasicDetailsType } from 'api/candidates'
import { assessmentReportPreviewData } from 'features/candidates/data/assessmentReportPreviewData'
import { ReportTypes } from 'features/settings/data/reportTypes'
import { ModalProps } from 'types'

import { PageLoader } from 'components/atoms'
import { Column } from 'components/atoms/Column'
import { Modal, ModalFooter } from 'components/molecules'
import AssessmentReportPreviewSection from 'components/molecules/AssesmentPreviewModal/Sections/AssessmentReportPreviewSection'

import { ReportBanner, ReportLogoHeader } from '../../sections'
import { AssessmentReportCandidateDetails } from '../../sections/ReportCandidateDetails/AssessmentReportCandidateDetails'

import { AssessmentSnapsPreview } from './AssessmentSnapsPreview'

import './style.scss'

type AssessmentReportPreviewModalProps = ModalProps & {
  candidateDetails: CandidateProfileBasicDetailsType
  previewData: assessmentReportPreviewData | undefined
  dynamicFieldMapping: any
  changeTextSchema: any
  isMappingVariablesLoading?: boolean
  reportViewData: any
  markingValue: any
  handlePrint: () => void
  resetClientOption: () => void
  setIsDownloadBtnClicked: React.Dispatch<React.SetStateAction<boolean>>
  isLoading: boolean
}

export const AssessmentReportPreviewModal = forwardRef(
  (
    {
      candidateDetails,
      previewData,
      reportViewData,
      markingValue,
      setIsModalActive,
      isModalActive,
      handlePrint,
      resetClientOption,
      setIsDownloadBtnClicked,
      isLoading,
    }: AssessmentReportPreviewModalProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const [isWebcamSnapsView, setIsWebcamSnapsView] = useState(false)
    const modalRef = useRef<HTMLDivElement>(null)

    const candidateReportDetails = useMemo(
      () => ({
        name: candidateDetails.name,
        candidateNo: candidateDetails.candidateNo,
        email: candidateDetails.email,
        phoneNumber: candidateDetails.phone,
        avatarUrl: candidateDetails.avatarUrl,
        jobTitle: candidateDetails.jobTitle,
        consultantName: candidateDetails.consultantName,
      }),
      [candidateDetails],
    )

    const handleCloseModal = () => setIsModalActive(false)
    const handleDownload = () => {
      setIsDownloadBtnClicked(true)
      handlePrint()
      resetClientOption()
    }

    return (
      <>
        <Modal
          headerTitle='Assessment Report Preview'
          isFooterShow={true}
          isActive={isModalActive}
          className='!z-[150]'
          onHeaderCloseButtonClick={handleCloseModal}
          onOverlayClick={handleCloseModal}
          primaryButtonProps={{ style: { marginRight: '12px' } }}
          secondaryButtonProps={{ style: { color: '#1F2937' } }}
          modalProps={{ style: { width: '800px', height: '95vh' } }}
          footerProps={{ style: { justifyContent: 'end', padding: '0 23px' } }}>
          {isLoading ? (
            <PageLoader size='xxs' />
          ) : (
            <>
              <div
                ref={modalRef}
                className='relative flex flex-col p-0 overflow-x-hidden overflow-y-auto styled-scroll bg-Gray-50'>
                <Column className='bg-Gray-50 w-[794px] pointer-events-none'>
                  <div ref={ref} className='w-full h-full p-0 m-0'>
                    <div className='h-auto relative'>
                      <ReportLogoHeader
                        clientlogo={previewData?.clientLogoUrl}
                        hasClientLogo={previewData?.hasClientLogo}
                        hasCompanyLogo={previewData?.hasCompanyLogo}
                        type={ReportTypes.ASSESSMENT}
                      />
                      <ReportBanner type={ReportTypes.ASSESSMENT} />
                      <div className='m-3'>
                        <AssessmentReportCandidateDetails
                          details={candidateReportDetails}
                          reportViewData={reportViewData}
                        />
                      </div>
                      <AssessmentReportPreviewSection
                        markingValue={markingValue}
                        reportViewData={reportViewData}
                        setIsWebcamSnapsView={setIsWebcamSnapsView}
                        position={1}
                      />
                    </div>
                  </div>
                </Column>
              </div>

              <ModalFooter className='py-4 bg-Gray-50'>
                <div className='flex justify-end flex-1'>
                  <Button
                    onClick={handleCloseModal}
                    textTheme='blue'
                    theme='white'>
                    Close
                  </Button>
                  <Button
                    onClick={handleDownload}
                    iconLeft={
                      <i className='flex items-center ri-download-line text-small' />
                    }
                    className='ml-2'
                    theme='cobalt'>
                    Download
                  </Button>
                </div>
              </ModalFooter>
            </>
          )}
        </Modal>

        {isWebcamSnapsView && (
          <AssessmentSnapsPreview
            isModalActive={isWebcamSnapsView}
            setIsModalActive={setIsWebcamSnapsView}
            candidateDetails={candidateDetails}
            previewData={previewData}
            images={reportViewData.antiCheatingImageUrl}
          />
        )}
      </>
    )
  },
)
