/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Toast } from '@labourhub/labour-hub-ds'
import { useUpdateOnboardingStepper } from 'api/onboarding'
import { brandingLogos } from 'features/onboarding/data'
import { setBrandingLogos, setCurrentStep } from 'features/onboarding/store'
import { getOnboardingStore } from 'features/onboarding/store/selectors'
import {
  useCreateAgencyProfileLogo,
  useCreateAgencySecondaryLogo,
} from 'framework/api/methods'

import { LogoUpload } from './LogoUpload'

export const CompanyBrandingLogoSection = () => {
  const dispatch = useDispatch()
  const { currentStep, brandingLogos: brandingLogosObj } =
    useSelector(getOnboardingStore)
  const [primaryImageUrl, setPrimaryImageUrl] = useState<any>('')
  const [uploadedPrimaryImageUrl, setUploadedPrimaryImageUrl] = useState<any>(
    brandingLogosObj?.primaryLogoUrl || '',
  )
  const [secondaryImageUrl, setSecondaryImageUrl] = useState<any>('')
  const [uploadedSecondaryImageUrl, setUploadedSecondaryImageUrl] =
    useState<any>(brandingLogosObj?.secondaryLogoUrl || '')
  const [showAgencyPrimaryProfileModal, setShowAgencyPrimaryProfileModal] =
    useState(false)
  const [showAgencySecondaryProfileModal, setShowAgencySecondaryProfileModal] =
    useState(false)

  /** APi call for the onboarding stepper */
  const {
    mutate: companyOnboardingMutate,
    isLoading: companyOnboardingIsLoading,
  } = useUpdateOnboardingStepper()

  /** Process the onboarding stepper */
  const companyOnboarding = (
    primaryLogoUrl?: string,
    secondaryLogoUrl?: string,
  ) => {
    companyOnboardingMutate(
      {
        brandingLogos: {
          id: brandingLogos,
          primaryLogoUrl: primaryLogoUrl,
          secondaryLogoUrl: secondaryLogoUrl,
        },
      },
      {
        onSuccess: () => {
          dispatch(
            setBrandingLogos({
              id: brandingLogos,
              primaryLogoUrl: primaryLogoUrl,
              secondaryLogoUrl: secondaryLogoUrl,
            }),
          )
          dispatch(setCurrentStep(currentStep + 1))
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  const onNextStepClick = () => {
    if (
      (brandingLogosObj && brandingLogosObj?.primaryLogoUrl !== '') ||
      (brandingLogosObj && brandingLogosObj?.secondaryLogoUrl !== '')
    ) {
      companyOnboarding(
        brandingLogosObj?.primaryLogoUrl,
        brandingLogosObj?.secondaryLogoUrl,
      )
    } else {
      Toast({
        alertHeader: `Please choose to 'Upload Image' or skip this step`,
        alertBody: '',
        status: 'Warning',
      })
    }
  }

  /** upload agency profile logo api */
  const {
    mutate: UploadAgencyProfileLogoMutate,
    isLoading: uploadAgencyProfileLogoIsLoading,
  } = useCreateAgencyProfileLogo()

  /** Process the upload profile picture */
  async function uploadAgencyProfileLogo(Id: any, logoObject: any) {
    UploadAgencyProfileLogoMutate(
      {
        Id,
        logoObject,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          Toast({
            alertHeader: successData.messageTitle
              ? successData.messageTitle
              : 'Primary company logo uploaded successfully.',
            alertBody: '',
            status: 'Success',
          })
          setUploadedPrimaryImageUrl(successData?.agencyProfile?.logoUrl)
          dispatch(
            setBrandingLogos({
              primaryLogoUrl: successData?.agencyProfile?.logoUrl,
              secondaryLogoUrl: brandingLogosObj?.secondaryLogoUrl,
            }),
          )
          setShowAgencyPrimaryProfileModal(false)
          setPrimaryImageUrl('')
        },
        onError: ({ response: { data: errData } }: any) => {
          setPrimaryImageUrl('')
          setShowAgencyPrimaryProfileModal(false)
          Toast({
            alertHeader: errData?.messageTitle
              ? errData?.messageTitle
              : 'Error!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** upload agency secondary logo api */
  const {
    mutate: createAgencySecondaryLogoMutate,
    isLoading: createAgencySecondaryLogoIsLoading,
  } = useCreateAgencySecondaryLogo()

  /** Process the upload secondary picture */
  async function createAgencySecondaryLogo(Id: any, logoObject: any) {
    createAgencySecondaryLogoMutate(
      {
        Id,
        logoObject,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          Toast({
            alertHeader: successData.messageTitle
              ? successData.messageTitle
              : 'Secondary company logo uploaded successfully.',
            alertBody: '',
            status: 'Success',
          })
          setUploadedSecondaryImageUrl(
            successData?.agencyProfile?.secondaryLogoUrl,
          )
          dispatch(
            setBrandingLogos({
              primaryLogoUrl: brandingLogosObj?.primaryLogoUrl,
              secondaryLogoUrl: successData?.agencyProfile?.secondaryLogoUrl,
            }),
          )
          setSecondaryImageUrl('')
          setShowAgencySecondaryProfileModal(false)
        },
        onError: ({ response: { data: errData } }: any) => {
          setUploadedSecondaryImageUrl('')
          setShowAgencySecondaryProfileModal(false)
          Toast({
            alertHeader: errData?.messageTitle
              ? errData?.messageTitle
              : 'Error!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  return (
    <div className='flex flex-col items-center justify-center w-7/12'>
      <div className='w-full mt-5 text-left text-heading-2 font-Bold text-Gray-800'>
        Company Profile Setup
      </div>

      <div className='w-full mt-6 text-left text-small font-Medium text-Gray-800'>
        Let's get your profile set up so you can start sending branded reference
        requests! If you'd like, you can skip this step and set up your branding
        later.
      </div>

      <div className='w-full h-full flex flex-row gap-x-9 px-[15px] mt-[25px]'>
        <div className='w-2/5'>
          <LogoUpload
            isRectangle={false}
            title='Upload Primary Logo'
            subText='Your primary logo will appear on all external communications. This is
        the logo your candidates and other external parties will see.'
            imageUrl={primaryImageUrl}
            setImageUrl={setPrimaryImageUrl}
            showAgencyProfileModal={showAgencyPrimaryProfileModal}
            setShowAgencyProfileModal={setShowAgencyPrimaryProfileModal}
            uploadAgencyProfileLogo={uploadAgencyProfileLogo}
            uploadBtnIsLoading={uploadAgencyProfileLogoIsLoading}
            uploadedPrimaryImageUrl={uploadedPrimaryImageUrl}
          />
        </div>

        <div className='w-3/5'>
          <LogoUpload
            isRectangle={true}
            title='Upload Secondary'
            subText='Your secondary logo is for internal use. Ideally, this should be a landscape version of your logo for optimal display.'
            imageUrl={secondaryImageUrl}
            setImageUrl={setSecondaryImageUrl}
            showAgencyProfileModal={showAgencySecondaryProfileModal}
            setShowAgencyProfileModal={setShowAgencySecondaryProfileModal}
            createAgencySecondaryLogo={createAgencySecondaryLogo}
            uploadBtnIsLoading={createAgencySecondaryLogoIsLoading}
            uploadedPrimaryImageUrl={uploadedPrimaryImageUrl}
            uploadedSecondaryImageUrl={uploadedSecondaryImageUrl}
          />
        </div>
      </div>

      {/* next button */}
      <Button
        isBlock
        isLoading={companyOnboardingIsLoading}
        onClick={onNextStepClick}
        className='mt-9 next-btn'>
        Next
      </Button>
    </div>
  )
}
