/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */

import { StepperDto } from '../../../api/onboarding'

import {
  setAboutYourBusinessState,
  setAboutYouState,
  setBrandingLogosState,
  setColleaguesState,
  setCompanyLocationState,
  setCompanySettingsOneState,
  setCompanySettingsTwoState,
  setContactInformationState,
  setCreateAccountState,
  setCurrentStepState,
  setGuideStepperCandidateState,
  setGuideStepperPreviewTemplateState,
  setGuideStepperSubTitleState,
  setGuideStepperTitleState,
  setOnboardingFixedSubscriptionPackageIdState,
  setOnboardingPaymentBillingAddressState,
  setPaymentDataState,
  setShowGuideModalState,
  setShowGuideStepperState,
  setShowGuideTooltipModalState,
  setTenantIdState,
  setTokenState,
  setUserProfileIdState,
  setVerifyAccountState,
} from './reducer'

export const setCurrentStep =
  (value, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setCurrentStepState(value))
  }

export const setGuideStepperTitle =
  (value?: string, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setGuideStepperTitleState(value))
  }

export const setGuideStepperSubTitle =
  (value?: string, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setGuideStepperSubTitleState(value))
  }

export const setShowGuideStepper =
  (value: boolean, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setShowGuideStepperState(value))
  }

export const setShowGuideModal =
  (value: boolean, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setShowGuideModalState(value))
  }

export const setShowGuideTooltipModal =
  (value: boolean, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setShowGuideTooltipModalState(value))
  }

export const setGuideStepperPreviewTemplate =
  (
    value?: {
      id: string
      name: string
    },
    cb = () => {},
  ) =>
  (dispatch: any) => {
    dispatch(setGuideStepperPreviewTemplateState(value))
  }

export const setGuideStepperCandidate =
  (value?: any, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setGuideStepperCandidateState(value))
  }

export const setAboutYourBusiness =
  (value, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setAboutYourBusinessState(value))
  }

export const setAboutYou =
  (value, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setAboutYouState(value))
  }

export const setBrandingLogos =
  (value, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setBrandingLogosState(value))
  }

export const setColleagues =
  (value, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setColleaguesState(value))
  }

export const setCompanyLocation =
  (value, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setCompanyLocationState(value))
  }
export const setCompanySettingsOne =
  (value, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setCompanySettingsOneState(value))
  }

export const setCompanySettingsTwo =
  (value, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setCompanySettingsTwoState(value))
  }

export const setContactInformation =
  (value, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setContactInformationState(value))
  }

export const setCreateAccount =
  (value, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setCreateAccountState(value))
  }

export const setVerifyAccount =
  (value, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setVerifyAccountState(value))
  }

export const setTenantId =
  (value, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setTenantIdState(value))
  }

export const setToken =
  (value, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setTokenState(value))
  }

export const setUserProfileId =
  (value, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setUserProfileIdState(value))
  }

export const setOnboardingData =
  (dto: StepperDto, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setPaymentDataState(dto.paymentData))
    dispatch(setColleaguesState(dto.colleagues))
    dispatch(setCompanyLocationState(dto.companyLocation))
    dispatch(setContactInformationState(dto.contactInformation))
    dispatch(setCompanySettingsOneState(dto.companySettingsOne))
    dispatch(setCompanySettingsTwoState(dto.companySettingsTwo))
  }

export const setOnboardingFixedSubscriptionPackageId =
  (value: any, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setOnboardingFixedSubscriptionPackageIdState(value))
  }

export const setOnboardingPaymentBillingAddress =
  (value: any, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setOnboardingPaymentBillingAddressState(value))
  }
