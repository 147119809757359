import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, TextField, Toast } from '@labourhub/labour-hub-ds'
import { useUpdateOnboardingStepper } from 'api/onboarding'
import { contactInformation } from 'features/onboarding/data'
import {
  setContactInformation,
  setCurrentStep,
} from 'features/onboarding/store'
import { getOnboardingStore } from 'features/onboarding/store/selectors'

import { addHttpsIfNeeded } from 'utils'

export const CompanyContactInformationSection = () => {
  const dispatch = useDispatch()
  const { currentStep, contactInformation: contactInformationObj } =
    useSelector(getOnboardingStore)

  const [formDetails, setFormDetails] = useState({
    website: '',
    contactUsUrl: '',
    privacyPolicyUrl: '',
  })

  const onNextStepClick = () => {
    companyOnboarding()
  }

  /** APi call for the onboarding stepper */
  const {
    mutate: companyOnboardingMutate,
    isLoading: companyOnboardingIsLoading,
  } = useUpdateOnboardingStepper()

  /** Process the onboarding stepper */
  const companyOnboarding = () => {
    companyOnboardingMutate(
      {
        contactInformation: {
          id: contactInformation,
          website: formDetails?.website
            ? addHttpsIfNeeded(formDetails?.website)
            : '',
          contactUsUrl: formDetails?.contactUsUrl
            ? addHttpsIfNeeded(formDetails?.contactUsUrl)
            : '',
          privacyPolicyUrl: formDetails?.privacyPolicyUrl
            ? addHttpsIfNeeded(formDetails?.privacyPolicyUrl)
            : '',
        },
      },
      {
        onSuccess: () => {
          dispatch(
            setContactInformation({
              id: contactInformation,
              website: formDetails?.website,
              contactUsUrl: formDetails?.contactUsUrl,
              privacyPolicyUrl: formDetails?.privacyPolicyUrl,
            }),
          )
          dispatch(setCurrentStep(currentStep + 1))
        },
        onError: ({ response: { data: errData } }: any) => {
          // this use temporary until api works
          Toast({
            alertHeader: errData?.messageTitle
              ? errData?.messageTitle
              : 'Error!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  useEffect(() => {
    contactInformationObj &&
      setFormDetails({
        website: contactInformationObj?.website,
        contactUsUrl: contactInformationObj?.contactUsUrl,
        privacyPolicyUrl: contactInformationObj?.privacyPolicyUrl,
      })
  }, [contactInformationObj])

  return (
    <div className='flex flex-col justify-center items-center w-[52%]'>
      <div className='w-full mt-5 text-left text-heading-2 font-Bold text-Gray-800'>
        Company Profile Setup
      </div>

      <div className='w-full mt-6 text-left text-small font-Medium text-Gray-800'>
        Ref Hub prominently displays your contact information to both candidates
        and referees. Let's ensure your links are set up correctly so they can
        reach out effortlessly.
      </div>

      <TextField
        className='w-full pt-6 website'
        value={formDetails?.website}
        onChange={(e: any) => {
          setFormDetails({
            ...formDetails,
            website: e.target.value,
          })
        }}
        label='Website'
        placeholder=''
      />

      <TextField
        className='w-full pt-6 contact-us'
        value={formDetails?.contactUsUrl}
        onChange={(e: any) => {
          setFormDetails({
            ...formDetails,
            contactUsUrl: e.target.value,
          })
        }}
        label='Contact Us URL'
        placeholder=''
      />

      <TextField
        className='w-full pt-6 privacy-policy'
        value={formDetails?.privacyPolicyUrl}
        onChange={(e: any) => {
          setFormDetails({
            ...formDetails,
            privacyPolicyUrl: e.target.value,
          })
        }}
        label='Privacy Policy URL'
        placeholder=''
      />

      {/* next button */}
      <Button
        isBlock
        isLoading={companyOnboardingIsLoading}
        onClick={onNextStepClick}
        className='mt-11 next-btn'>
        Next
      </Button>
    </div>
  )
}
