export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL
export const notificationBaseUrl = process.env.REACT_APP_API_BASE_URL

export const buyCreditPoliceCheck =
  process.env.REACT_APP_BUY_CREDIT_POLICE_CHECK

export const buyCreditPoliceCheckVolunteer =
  process.env.REACT_APP_BUY_CREDIT_POLICE_CHECK_VOLUNTEER

export const buyCreditRightToWorkCheck =
  process.env.REACT_APP_BUY_CREDIT_RIGHT_TO_WORK

export const buyCreditRegistration =
  process.env.REACT_APP_BUY_CREDIT_REGISTER_NOW

export const stripePubKey = process.env.REACT_APP_STRIPE_PUB_KEY

export const s3Url = process.env.REACT_APP_S3_URL

export const envConfig = {
  jobadderUrl: process.env.REACT_APP_JOBADDER_URL,
  execSummaryCharLimit: process.env.REACT_APP_EXEC_SUMMARY_CHAR_LIMIT,
  reportCandidateDetailHideTenantId:
    process.env.REACT_APP_REPORT_CANDIDATE_DETAIL_HIDE_TENANT_ID,
}
