import { ReactNode, useEffect, useState } from 'react'
import CN from 'classnames'

type CenteredTabsProps = {
  children?: ReactNode | string | number | undefined
  className?: string
  contentClassName?: string
  tabHeaderClassName?: string
  tabHeaderButtonClassName?: string
  defaultTabId?: any
  tabDetails?:
    | {
        id: any
        tabButtonContent: string | ReactNode
        tabButtonFirstIcon?: string | ReactNode
        tabButtonSecondIcon?: string | ReactNode
        tabHeaderIconList?: [{ icon: any; color: any }] | any | undefined
        tabContent: any
      }[]
    | undefined
  onChange?: (id: any) => void
  onHeaderBtnClick?: () => void
  openTabId?: number | undefined
  isHeaderIcon?: boolean | undefined
}

export const CenteredTabs = ({
  className,
  defaultTabId,
  tabDetails,
  onChange,
  contentClassName,
  tabHeaderClassName,
  tabHeaderButtonClassName,
  onHeaderBtnClick,
  openTabId,
}: CenteredTabsProps) => {
  const CenteredTabsClasses = CN(`tabs h-full`, className)
  const [activeTab, setActiveTab] = useState<any>(defaultTabId)

  /** handle on tab change method */
  useEffect(() => {
    if (openTabId) {
      onChange && onChange(openTabId)
      setActiveTab(openTabId)
    } else {
      onChange && onChange(activeTab)
    }
  }, [activeTab, openTabId])

  /** set active tab */
  useEffect(() => {
    setActiveTab(defaultTabId)
  }, [defaultTabId])

  return (
    <div className={CenteredTabsClasses}>
      {/* tab header */}
      <div
        className={CN(
          'tabs-button flex w-full border-b border-b-Gray-200',
          tabHeaderClassName,
        )}>
        {tabDetails?.map((tabDetail, idx) => (
          <div
            className={CN(
              'flex px-6 pb-2 pt-3 font-Medium w-full items-center justify-center',
              tabHeaderButtonClassName,
              {
                'text-Cobalt-600 border-b-2': activeTab === tabDetail.id,
                'text-Gray-500 hover:text-Cobalt-400 ':
                  activeTab !== tabDetail.id,
              },
            )}>
            <button
              key={tabDetail.id || idx}
              onClick={() => {
                setActiveTab(tabDetail.id)
                onHeaderBtnClick && onHeaderBtnClick()
              }}
              className=''>
              {tabDetail.tabButtonContent}
            </button>

            {/** tab header icon */}
            {tabDetail?.tabHeaderIconList?.map((item: any, index: any) => {
              return item?.icon ? (
                <div className='flex gap-[7px]' key={index}>
                  <i
                    className={CN(
                      item?.icon,
                      activeTab === tabDetail.id
                        ? item?.color
                        : 'text-Gray-500',
                      'text-small pl-[5px] pt-[3px]',
                    )}></i>
                </div>
              ) : null
            })}

            {tabDetail?.tabHeaderIconList?.map((item: any, index: any) => {
              return item?.content ? (
                <div className='flex items-center justify-center pl-1'>
                  <div
                    className={CN(
                      'w-[14px] h-[14px] flex items-center justify-center rounded-full text-[9px] text-white',
                      activeTab === tabDetail.id ? item?.color : 'bg-Gray-500',
                    )}
                    key={index}>
                    {item?.content}
                  </div>
                </div>
              ) : null
            })}
          </div>
        ))}
      </div>

      {/* tab content */}
      <div className={CN('tabs-content ', contentClassName)}>
        {
          tabDetails?.find((tabDetail) => tabDetail.id === activeTab)
            ?.tabContent
        }
      </div>
    </div>
  )
}

CenteredTabs.defaultProps = {
  children: undefined,
  className: undefined,
  tabHeaderButtonClassName: undefined,
  tabHeaderClassName: undefined,
  defaultTabId: undefined,
  tabDetails: undefined,
  onChange: undefined,
  onHeaderBtnClick: undefined,
  openTabId: undefined,
  isHeaderIcon: false,
}

export default CenteredTabs
