import clientLogoPlaceholder from 'assets/images/client-logo.png'
import companyLogoPlaceholder from 'assets/images/company-logo.svg'
import reportBgPlaceholder from 'assets/images/report-settings-bg.svg'

import { Column } from 'components/atoms/Column'
import { Row } from 'components/atoms/Row'

import { ReportDividerBar } from '../sections'

type MiniReferenceReportPreviewProps = {
  lineColour: string
  textColour: string
}
export const MiniReferenceReportPreview = ({
  lineColour,
  textColour,
}: MiniReferenceReportPreviewProps) => {
  const content = {
    name: 'BROOKYLN HECTOR',
    roleName: 'Role Name',
    totalReferees: 2,
    refereesProvided: 2,
    responseReceived: 2,
    declinedReference: 0,
    referenceInfo: {
      consultantName: 'John Doe',
      dateTime: '12/01/2023 12:23:33 am',
    },
  }

  return (
    <Column className='max-w-[250px] h-full border border-Gray-200'>
      <Row className='items-center justify-between'>
        <img src={clientLogoPlaceholder} alt='' className='w-[60px] h-[23px]' />
        <img
          src={companyLogoPlaceholder}
          alt=''
          className='w-[60px] h-[16px]'
        />
      </Row>
      <div className='flex items-center justify-center w-full h-[72px]'>
        <img
          src={reportBgPlaceholder}
          alt=''
          className='object-cover w-full h-full'
        />
      </div>

      <ReportDividerBar size='xs' color={lineColour} />

      <div className='p-2'>
        <span className='flex text-[10px] font-SemiBold'>
          REFERENCE CHECK REPORT FOR
        </span>
        <span
          className='flex text-[10px] font-SemiBold'
          style={{ color: textColour }}>
          {content.name}
        </span>
        <span className='flex text-[7px] font-Medium text-[#2E2E2E]'>
          Applying for: {content.roleName}
        </span>
      </div>
    </Column>
  )
}
