import { useSortable } from '@dnd-kit/sortable'
import { AssessmentQuestion } from 'features/questionnaires/types'

import { cn } from 'utils'

type DraggableQuestionTypeProps = {
  question: AssessmentQuestion
  onDoubleClick?: () => void
}

export const DraggableQuestionType = ({
  question,
  onDoubleClick,
}: DraggableQuestionTypeProps) => {
  const { attributes, listeners, setNodeRef } = useSortable({
    id: question.id,
  })

  return (
    <div
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      className='flex justify-start items-center rounded-lg border-[1.5px] border-dashed border-Gray-300 bg-Gray-50 pl-4 py-[18px] active:bg-Cobalt-50 active:border-Cobalt-200 cursor-pointer select-none'
      onDoubleClick={onDoubleClick}>
      <i className={cn(question.icon, 'text-[18px] text-Gray-500')} />
      <span className='font-Medium text-Gray-800 ml-2'>{question.name}</span>
    </div>
  )
}
