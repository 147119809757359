import React from 'react'
import { useSelector } from 'react-redux'
import { getReportSettings } from 'features/settings'

import { Column } from 'components/atoms/Column'
import { Row } from 'components/atoms/Row'

type AssessmentReportCandidateDetailsProps = {
  isSampleView?: boolean
  details: AssessmentReportCandidateDetailsDto
  testName?: string
  reportViewData?: any
}

export type AssessmentReportCandidateDetailsDto = {
  name: string
  candidateNo: string
  email: string
  phoneNumber: string
  avatarUrl?: string
  jobTitle?: string
  consultantName: string
}

export const AssessmentReportCandidateDetails = React.memo(
  ({
    details: { avatarUrl, jobTitle, name },
    testName = '',
    reportViewData,
  }: AssessmentReportCandidateDetailsProps) => {
    const { textColour } = useSelector(getReportSettings)

    const renderAvatar = avatarUrl && (
      <img
        src={avatarUrl}
        alt='Candidate Avatar'
        className='object-fill rounded-full w-[62px] h-[62px] overflow-hidden'
      />
    )

    const renderJobTitle = jobTitle && (
      <p className='text-base font-Medium text-Gray-600'>
        Applying for role of {jobTitle}
      </p>
    )

    return (
      <Column className='px-6 py-4 mt-4'>
        <Row className='w-full h-auto space-x-4'>
          {renderAvatar}
          <div className='max-w-[650px]'>
            {reportViewData && (
              <p
                className='text-small font-Bold mb-5'
                style={{ color: textColour }}>
                {reportViewData?.isAddon === false ? (
                  <div className='flex flex-row'>
                    {reportViewData?.assessmentName?.toUpperCase()}
                  </div>
                ) : reportViewData?.assessmentName === 'Typing Test' ? (
                  <div className='flex flex-row'>
                    {`${reportViewData?.assessmentName.toUpperCase()} (LOWERCASE ONLY)`}
                  </div>
                ) : (
                  reportViewData?.assessmentName.toUpperCase()
                )}
              </p>
            )}
            <p className='text-small text-Gray-500 font-Bold'>
              {testName ? testName.toUpperCase() : 'ASSESSMENT REPORT'}
            </p>
            <p
              className='leading-6 uppercase text-heading-3 font-Bold'
              style={{ color: textColour }}>
              {name}
            </p>
            {renderJobTitle}
          </div>
        </Row>
      </Column>
    )
  },
)
