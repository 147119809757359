import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const verifyAgencySubDomainAvailability = async ({ queryKey }: any) => {
  const reqBody = queryKey[1]
  const res = await get(
    `/AgencyProfile/Verify/SubDomain?SubDomain=${reqBody.subDomain}`,
  )
  return res.data
}

export const useVerifyAgencySubDomainAvailability = (
  { ...reqBody },
  onSuccess,
  onError,
) => {
  return useQuery(
    ['VERIFY_AGENCY_SUBDOMAIN', reqBody],
    verifyAgencySubDomainAvailability,
    {
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess,
      onError,
    },
  )
}
