export const ResponseReceived = 'ResponseReceived'
export const AwaitingForResponse = 'AwaitingForResponse'
export const surveyResponseStatusOptions = [
  {
    label: 'Response Received',
    value: ResponseReceived,
  },
  {
    label: 'Awaiting for Response',
    value: AwaitingForResponse,
  },
]
