import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

type TReferenceType = {
  type: string
  specificEmployer: string
  note: string
  isSubmitted: boolean
}

type UpdateRefereeDetailProps = {
  id: string
  referenceDetails: {
    referenceCount: number
    origin?: string
    referenceTypes: Array<TReferenceType>
    templateId: string
  }
  isAutoReferenceEnabled: boolean
}

export const useUpdateCandidateReferees = () => {
  const mutate = useMutation(
    ({
      referenceDetails,
      id,
      isAutoReferenceEnabled = false,
    }: UpdateRefereeDetailProps) => {
      return putAPI(`/Candidate/${id}/update-referee-types`, {
        id,
        candidateModel: {
          ...referenceDetails,
          origin: referenceDetails.origin
            ? referenceDetails.origin
            : 'refHub-web',
        },
        isAutoReferenceEnabled,
      })
    },
  )

  return mutate
}
