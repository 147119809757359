import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'
import { SelectOptionDto } from 'types'

type ClientSelectOptionDto = SelectOptionDto & {
  logoUrl: string
}

const getClientSelectOptions = async ({ queryKey }: any) => {
  const search = queryKey[1]

  let URI = `/clients/options`

  if (search) {
    URI += `&search=${search}`
  }

  const res = await get(URI)
  return res.data.options as ClientSelectOptionDto[]
}

export const useGetClientSelectOptions = (
  search: string,
  onSuccess: (data: ClientSelectOptionDto[]) => void,
) => {
  return useQuery(['client_list_options', search], getClientSelectOptions, {
    enabled: true,
    refetchOnWindowFocus: false,
    onSuccess,
    onError: () => {
      Toast({
        alertHeader: 'Error fetching client select options',
        status: 'Error',
      })
    },
  })
}
