import { useMutation } from 'react-query'
import { postAPIwithFormData } from 'framework/api/http'

interface CreateCandidateAvatarInterface {
  Id: string
  avatarObject: string
}

export const useCreateCandidateAvatar = () => {
  const mutate = useMutation(
    ({ Id, avatarObject }: CreateCandidateAvatarInterface) => {
      const bodyFormData = new FormData()
      bodyFormData.append('Avatar', avatarObject)

      return postAPIwithFormData(`/Candidate/${Id}/Avatar`, bodyFormData, {})
    },
  )

  return mutate
}

export default useCreateCandidateAvatar
