import EmptAddOnsIcon from 'assets/images/assessment/EmptAddOnsIcon.svg'

const EmptyAddOns = () => {
  return (
    <div className='px-8 bg-Gray-100 overflow-y-auto flex flex-col w-full items-center justify-center py-8 h-auto rounded-md'>
      <div className='w-[220px] justify-center items-center py-4 gap-3'>
        <img
          src={EmptAddOnsIcon}
          alt='EmptAddOnsIcon'
          className='flex w-full justify-center items-center h-[35px] px-4'
        />
        <div className='text-center text-extra-small mt-2'>
          Select assessments from Popular Assessments to add
        </div>
      </div>
    </div>
  )
}

export default EmptyAddOns
