import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

import { addHttpsIfNeeded } from 'utils'

interface EditAgencyDetailsProps {
  Id?: string
  updatedAgencyDetails?: any
}

export const useEditAgencyDetails = () => {
  const mutate = useMutation(
    ({ updatedAgencyDetails, Id }: EditAgencyDetailsProps) => {
      return putAPI(`/AgencyProfile/${Id}`, {
        name: updatedAgencyDetails?.name,
        abn: updatedAgencyDetails?.abn,
        industry: updatedAgencyDetails?.industry,
        website: updatedAgencyDetails?.website
          ? addHttpsIfNeeded(updatedAgencyDetails?.website)
          : '',
        privacyPolicy: updatedAgencyDetails?.privacyPolicy
          ? addHttpsIfNeeded(updatedAgencyDetails?.privacyPolicy)
          : undefined,
        contactUsUrl: updatedAgencyDetails?.contactUsUrl
          ? addHttpsIfNeeded(updatedAgencyDetails?.contactUsUrl)
          : undefined,
        phone: updatedAgencyDetails?.phone,
        email: updatedAgencyDetails?.email,
        address: {
          lineOne: updatedAgencyDetails?.lineOne,
          lineTwo: '',
          city: '',
          suburb: updatedAgencyDetails?.suburb,
          state: updatedAgencyDetails?.state,
          stateCode: updatedAgencyDetails?.stateCode,
          country: updatedAgencyDetails?.country,
          countryCode: updatedAgencyDetails?.countryCode,
          postCode: updatedAgencyDetails?.postCode,
          latitude:
            updatedAgencyDetails?.latitude !== ''
              ? updatedAgencyDetails?.latitude
              : 0,
          longitude:
            updatedAgencyDetails?.longitude !== ''
              ? updatedAgencyDetails?.longitude
              : 0,
        },
        about: updatedAgencyDetails?.about,
        size: updatedAgencyDetails.size,
      })
    },
  )

  return mutate
}

export default useEditAgencyDetails
