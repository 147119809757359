/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-interface */

import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface useGetCSVListProps {
  Search?: string
  IsEmployee: boolean
}

export const useGetCSVList = () => {
  const mutate = useMutation(({ Search, IsEmployee }: useGetCSVListProps) => {
    return get(
      `/Candidate/CSVExport?Search=${Search}&IsEmployee=${IsEmployee}`,
      {},
    )
  })

  return mutate
}

export default useGetCSVList
