import React, { forwardRef, MutableRefObject, useEffect, useRef } from 'react'
import CN from 'classnames'

import './checkbox.scss'

type CheckboxProps = {
  className?: string | undefined
  labelTextClassName?: string | undefined
  checkboxClassName?: string | undefined
  defaultChecked?: boolean
  helperText?: string | undefined
  id?: string | undefined
  indeterminate?: boolean
  isCustomStyles?: boolean
  isDisabled?: boolean
  labelText?: string | undefined
  onChange?: (e: any) => void
  theme?: 'gray' | 'red' | 'yellow' | 'green' | 'blue'
  [x: string]: any
}

const useCombinedRefs = (...refs: any[]): MutableRefObject<any> => {
  const targetRef = useRef()

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return

      if (typeof ref === 'function') {
        ref(targetRef.current)
      } else {
        ref.current = targetRef.current
      }
    })
  }, [refs])

  return targetRef
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      className,
      labelTextClassName,
      checkboxClassName,
      defaultChecked,
      helperText,
      id,
      indeterminate,
      isCustomStyles,
      isDisabled,
      labelText,
      onChange,
      theme,
      ...restProps
    }: CheckboxProps,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    /** check box main class name*/
    const checkBoxMainClass = !isCustomStyles
      ? CN('flex w-fit items-start cursor-pointer', className)
      : className

    /**set checkbox style class name*/
    const CheckboxClasses = CN('checkbox m-1', checkboxClassName, {
      'checkbox--is-indeterminate': indeterminate,
    })

    /**set checkbox colors class name*/
    const CheckboxSpanClass = CN({
      'checkbox--checkmark-gray': theme === 'gray',
      'checkbox--checkmark-red': theme === 'red',
      'checkbox--checkmark-yellow': theme === 'yellow',
      'checkbox--checkmark-green': theme === 'green',
      'checkbox--checkmark-blue': theme === 'blue',
    })

    const defaultRef = React.useRef(null)
    const combinedRef = useCombinedRefs(ref, defaultRef)

    useEffect(() => {
      if (combinedRef?.current) {
        combinedRef.current.indeterminate = indeterminate || false
      }
    }, [combinedRef, indeterminate])

    return (
      <label className={checkBoxMainClass} htmlFor={id}>
        {/* input container */}
        <div className={CheckboxClasses}>
          <input
            disabled={isDisabled}
            id={id}
            type='checkbox'
            ref={combinedRef}
            onChange={onChange}
            checked={defaultChecked}
            {...restProps}
          />
          <span className={CheckboxSpanClass} />
        </div>

        {/* label and helper text container */}
        {labelText && (
          <div className='flex flex-col ml-2 checkbox__label LabelTextCon'>
            {/* label text */}
            <span
              className={CN(
                'LabelText flex text-Gray-800 font-Medium',
                labelTextClassName,
              )}>
              {labelText}
            </span>

            {/* helper text */}
            {helperText && (
              <span className='flex w-fill text-small text-Gray-500'>
                {helperText}
              </span>
            )}
          </div>
        )}
      </label>
    )
  },
)
export default Checkbox

Checkbox.defaultProps = {
  className: undefined,
  labelTextClassName: undefined,
  checkboxClassName: undefined,
  defaultChecked: undefined,
  helperText: undefined,
  id: undefined,
  indeterminate: false,
  isCustomStyles: false,
  isDisabled: false,
  labelText: undefined,
  onChange: undefined,
  theme: 'blue',
}
