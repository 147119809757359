/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { Avatar } from '@labourhub/labour-hub-ds'
import candidateDefaultLogo from 'assets/images/Profile-Icon.png'

export const CandidateListColumn = ({ navigate, navigation }: any) => {
  const COLUMNS = [
    {
      Header: 'CANDIDATE NAME',
      accessor: 'firstName',
      forSorting: 'FirstName',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[212px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center w-full',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        return (
          <div
            className='w-full cursor-pointer'
            onClick={() => {
              navigate(
                navigation === 'assessment'
                  ? `/candidate-list/candidate-profile?&candidate_id=${original.id}&candidate_name=${original.firstName} ${original.lastName}&tab=${navigation}&assessment_tab=results`
                  : `/candidate-list/candidate-profile?&candidate_id=${original.id}&candidate_name=${original.firstName} ${original.lastName}`,
              )
            }}>
            <Avatar
              imageURL={
                original?.avatarUrl ? original?.avatarUrl : candidateDefaultLogo
              }
              titleClassName='flex w-max line-clamp-1'
              size='xs'
              color='one'
              title={`${original?.firstName} ${original?.lastName}`}
              /** Showing warning and medical notification icons */
              titleIcons={
                <div className='flex'>
                  {original.isWarning && (
                    <i className='pl-1 ri-error-warning-fill text-Red-500'></i>
                  )}

                  {original.isMedicalNotification && (
                    <i className='pl-1 ri-shield-cross-fill text-Cobalt-500'></i>
                  )}
                </div>
              }
              tag={original?.email}
            />
          </div>
        )
      },
    },
    {
      Header: 'CANDIDATE NO',
      accessor: 'candidateNo',
      forSorting: 'candidateNo',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[167px] pl-6',
      headerClassName: 'flex items-center justify-start text-left',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <div>{original?.candidateNo}</div>
      },
    },
    {
      Header: 'PHONE',
      accessor: 'phone',
      forSorting: 'phone',
      isSortable: false,
      hasFilter: false,
      isVisible: true,
      commonClassName: 'w-full min-w-[167px] pl-6',
      headerClassName: 'flex items-center justify-start text-left',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <div>{original?.phone}</div>
      },
    },
    {
      Header: 'MANAGING CONSULTANT',
      accessor: 'consultantName',
      forSorting: 'ConsultantName',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[167px] pl-6',
      headerClassName: 'flex items-center justify-start text-left',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <div>{original?.consultantName}</div>
      },
    },
  ]

  return COLUMNS
}

export default CandidateListColumn
