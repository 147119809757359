import { useQuery } from 'react-query'
import { AppSettingProps } from 'features/settings'
import { get } from 'framework/api/http'

export const useGetAgencySettings = (
  onSuccess: (agencySettings: AppSettingProps[]) => void,
) => {
  return useQuery(
    ['APPSETTINGS_AGENCIES'],
    async () => {
      const res = await get(`/AppSettings/Agencies`)
      return res.data.agencySettings as AppSettingProps[]
    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
      onSuccess,
      // onError: () => {
      //   Toast({
      //     alertHeader: 'Error',
      //     alertBody: 'Unable to fetch agency settings',
      //     status: 'Error',
      //   })
      // },
    },
  )
}
