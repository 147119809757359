import { ReactNode } from 'react'

type CardDetailProp = {
  label: string
  data: any
  isIcon?: boolean
  icon?: string
  dataPrefix?: ReactNode
}

const CardDetail = ({
  label,
  data,
  isIcon = false,
  icon,
  dataPrefix,
}: CardDetailProp) => {
  return (
    <div className='flex items-start'>
      {isIcon && (
        <i className={`items-center mr-3 text-small text-[#09121F] ${icon}`} />
      )}
      <span className='text-extra-small leading-5 min-w-[100px] lg:min-w-[150px] text-Gray-500 mr-1 flex justify-start w-[150px] font-Regular'>
        {label} <span>:</span>
      </span>
      <div className='flex items-center  min-w-[210px]  break-words text-small font-Medium text-Gray-600 ml-4 space-x-1'>
        {dataPrefix}
        <span>{data}</span>
      </div>
    </div>
  )
}

export default CardDetail
