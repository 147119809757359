import { forwardRef } from 'react'
import CN from 'classnames'

import { cn } from 'utils'

interface CustomBoldRadioProps {
  className?: string | undefined
  id?: string | undefined
  checked?: boolean
  isCustomStyles?: boolean
  defaultChecked?: boolean
  helperText?: string | undefined
  labelText?: string | undefined
  onClick?: (e: any) => void
  onChange?: any | undefined
  name?: string | undefined
}

export const CustomBoldRadio = forwardRef<
  HTMLInputElement,
  CustomBoldRadioProps
>(
  (
    {
      className,
      id,
      isCustomStyles,
      helperText,
      labelText,
      onClick,
      defaultChecked,
      onChange,
      name,
      checked,
      ...restProps
    }: CustomBoldRadioProps,
    ref,
  ) => {
    const MainClassName = !isCustomStyles
      ? CN('ParentClass flex', className)
      : className

    const ButtonClassName = CN(
      'form-check-input appearance-none rounded-full h-4 w-4 border border-Gray-300 bg-white checked:bg-white checked:border-Cobalt-600 checked:border-[5px] hover:bg-Gray-300 active:bg-Gray-400 focus:outline-none focus:ring focus:ring-Cobalt-300 transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer',
    )

    return (
      <label className={MainClassName} htmlFor={id}>
        <div className='left-side'>
          <input
            id={id}
            onChange={onChange}
            ref={ref}
            type='radio'
            checked={checked}
            onClick={onClick}
            className={ButtonClassName}
            defaultChecked={defaultChecked}
            name={name}
            {...restProps}
          />
        </div>
        <div className='right-side flex flex-col justify-center pl-2'>
          {labelText && (
            <span
              className={cn(' text-Gray-700', {
                'font-Regular': !checked,
                'font-Bold': checked,
              })}>
              {labelText}
            </span>
          )}
          {helperText && (
            <span className='font-Regular text-small text-Gray-500'>
              {helperText}
            </span>
          )}
        </div>
      </label>
    )
  },
)

CustomBoldRadio.defaultProps = {
  className: undefined,
  id: undefined,
  isCustomStyles: undefined,
  helperText: ' Vestibulum ex ipsum, laoreet sit amet luctus ac,',
  labelText: 'Lorem Ipsum',
  onClick: undefined,
  defaultChecked: undefined,
  onChange: undefined,
  name: undefined,
}
