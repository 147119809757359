import { useEffect, useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print'
import { Button } from '@labourhub/labour-hub-ds'
import { assessmentReportPreviewData } from 'features/candidates/data/assessmentReportPreviewData'
import { ReportTypes } from 'features/settings/data/reportTypes'
import { ModalProps } from 'types'

import { PageLoader } from 'components/atoms'
import { Column } from 'components/atoms/Column'
import { Modal, ModalFooter } from 'components/molecules'
import { UserDetails } from 'components/molecules/AddonPreviewModal/TypedTestResultReport/UserDetails'
import PersonalityTestReportPreviewSection from 'components/molecules/AssesmentPreviewModal/Sections/PersonalityTestReportPreviewSection'
import { ReportBanner, ReportLogoHeader } from 'components/ui/reports/sections'

type PersonalityTestResultModalProps = {
  candidateDetails: any
  reportViewData: any
  handleCloseModals: () => void
  isLoading?: boolean
  previewData: assessmentReportPreviewData | undefined
} & ModalProps

export default function PersonalityTestResultModal({
  isModalActive,
  setIsModalActive,
  candidateDetails,
  reportViewData,
  handleCloseModals,
  isLoading = false,
  previewData,
}: PersonalityTestResultModalProps) {
  const [isPrinting, setIsPrinting] = useState(false)
  const componentRef = useRef<HTMLDivElement>(null)
  const promiseResolveRef = useRef<(() => void) | null>(null)

  const pageStyle = `
  @media print {
    @page {
      size: a4 !important;
      margin: 5mm 0 !important;
      padding: 0 !important;
    }
    ::-webkit-scrollbar {
      display: none;
    }
    body {
      margin: 0 !important;
      padding: 0 !important;
      scrollbar-width: none; 
      -ms-overflow-style: none; 
    }
  }
`

  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      promiseResolveRef.current()
    }
  }, [isPrinting])

  const handlePrint = useReactToPrint({
    pageStyle,
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      return new Promise<void>((resolve) => {
        promiseResolveRef.current = resolve
        setIsPrinting(true)
      })
    },
    onBeforePrint: () => setIsModalActive(true),
    onAfterPrint: () => {
      promiseResolveRef.current = null
      setIsPrinting(false)
      handleCloseModals()
    },
  })

  const handleClose = () => {
    setIsModalActive(false)
    setIsPrinting(false)
  }

  const renderContent = () => {
    if (isLoading) {
      return <PageLoader size='xxs' />
    }

    return (
      <>
        <div className='w-full overflow-y-scroll styled-scroll space-y-10 bg-Gray-50'>
          <Column className='bg-Gray-50 w-full pointer-events-none'>
            <div
              ref={componentRef}
              className='a4-preview w-full h-full p-0 m-0'>
              <ReportLogoHeader
                clientlogo={previewData?.clientLogoUrl}
                hasClientLogo={previewData?.hasClientLogo}
                hasCompanyLogo={previewData?.hasCompanyLogo}
              />
              <ReportBanner type={ReportTypes.ASSESSMENT} />
              <div className='m-3'>
                <UserDetails
                  candidateDetails={candidateDetails}
                  reportViewData={reportViewData}
                />
              </div>
              <PersonalityTestReportPreviewSection
                reportViewData={reportViewData}
                isPrinting={isPrinting}
              />
            </div>
          </Column>
        </div>
        <ModalFooter>
          <div className='flex justify-end flex-1'>
            <Button onClick={handleClose} textTheme='blue' theme='white'>
              Cancel
            </Button>
            <Button
              onClick={handlePrint}
              // isLoading={questionsUpdateLoading}
              // isDisabled={questionsUpdateLoading}
              className='ml-2'
              theme='cobalt'>
              Download
            </Button>
          </div>
        </ModalFooter>
      </>
    )
  }

  return (
    <Modal
      headerTitle='Personality Test'
      isSeparator
      customWidth='794px'
      className='!z-[300]'
      modalContainerClassNames='max-w-[794px]'
      isActive={isModalActive}
      modalProps={{
        style: { width: '800px', height: '95vh' },
      }}
      isFooterShow={false}
      size='lg'
      onClickSecondaryBtn={handleClose}
      onHeaderCloseButtonClick={handleClose}
      onOverlayClick={handleClose}
      secondaryButtonTitle='Close'>
      {renderContent()}
    </Modal>
  )
}
