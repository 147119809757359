import { Button, Toast } from '@labourhub/labour-hub-ds'
import { useRequestRefereeReminder } from 'framework/api/methods'
import moment from 'moment'
import { ModalProps } from 'types'

import { Modal, ModalFooter } from 'components/molecules'

type RequestRefereeReminderModalProps = ModalProps & {
  candidateId: string
  requestSendDate?: string
  lastSendRequest?: string
  getCandidateById: any
}

export const RequestRefereeReminderModal = ({
  isModalActive,
  setIsModalActive,
  candidateId,
  requestSendDate,
  lastSendRequest,
  getCandidateById,
}: RequestRefereeReminderModalProps) => {
  const {
    mutate: requestRefereesReminderMutate,
    isLoading: requestRefereeReminderIsLoading,
  } = useRequestRefereeReminder()

  const requestRefereeReminder = () => {
    requestRefereesReminderMutate(candidateId, {
      onSuccess: () => {
        Toast({
          alertBody: 'Request referee reminder successfully sent to candidate',
          status: 'Success',
        })
        setIsModalActive(false)
        getCandidateById()
      },
      onError: ({ response: { data: errData } }: any) => {
        Toast({
          alertHeader: 'Error...!',
          alertBody: errData?.message
            ? errData?.message
            : 'Error requesting referee reminder!',
          status: 'Error',
        })
        setIsModalActive(false)
      },
    })
  }

  return (
    <Modal
      subHeaderTitle='Remind candidate to send referee information'
      headerTitle='Send Request Referee Reminder'
      isFooterShow={true}
      size='md'
      isActive={isModalActive}
      onHeaderCloseButtonClick={() => setIsModalActive(false)}
      onOverlayClick={() => setIsModalActive(false)}
      primaryButtonTitle='Download'
      secondaryButtonTitle='Cancel'>
      <div className='flex flex-col p-6 w-full'>
        <div className='flex flex-col w-full mb-6'>
          <div className='flex text-small text-Gray-500'>
            <span className=' mb-3 w-1/3'>Request sent on:</span>
            <span className='font-SemiBold w-2/3'>
              {requestSendDate
                ? moment(requestSendDate).format('DD/MM/yyyy')
                : 'Never'}
            </span>
          </div>
          <div className='flex text-small text-Gray-500'>
            <span className='w-1/3'>Reminder last sent on:</span>
            <span className='font-SemiBold w-2/3'>
              {lastSendRequest
                ? moment(lastSendRequest).format('DD/MM/yyyy')
                : 'Never'}
            </span>
          </div>
        </div>
        <span className='text-small text-Gray-800'>
          by clicking on Send Reminder, you will send a new reminder to
          candidate’s inbox.
        </span>
      </div>
      <ModalFooter>
        <div className='flex justify-end flex-1'>
          <Button
            isLoading={requestRefereeReminderIsLoading}
            onClick={() => requestRefereeReminder()}
            className='mr-2'
            theme='cobalt'>
            Send Reminder
          </Button>
          <Button
            onClick={() => setIsModalActive(false)}
            textTheme='blue'
            theme='white'>
            Cancel
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}
