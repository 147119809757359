const isDateWithinRange = (
  currentDate: Date,
  startDate: Date,
  endDate: Date,
  setHoursToMidnight = true,
) => {
  if (setHoursToMidnight) {
    startDate.setHours(0, 0, 0, 0)
    endDate.setHours(0, 0, 0, 0)
    currentDate.setHours(0, 0, 0, 0)
  }

  return currentDate >= startDate && currentDate <= endDate
}

export const isBlackFriday = () => {
  const currentDate = new Date()
  const startDate = new Date('2023-11-24')
  const endDate = new Date('2023-11-27')
  return isDateWithinRange(currentDate, startDate, endDate)
}

export const isChristmas = () => {
  const currentDate = new Date()
  const startDate = new Date('2023-12-13')
  const endDate = new Date('2024-01-08')
  return isDateWithinRange(currentDate, startDate, endDate)
}
