import { ReferenceCheckType } from 'types'

/* eslint-disable no-useless-escape */
export const referenceTypeToText = (string: any) => {
  if (string === ReferenceCheckType?.Employment) {
    return 'Employment'
  } else if (string === ReferenceCheckType?.Professional) {
    return 'Professional'
  } else if (string === ReferenceCheckType?.FriendFamily) {
    return 'Friends & Family'
  } else {
    return ''
  }
}
