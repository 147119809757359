import { AmountSummary, InvoiceAmountSummary } from 'features/invoices'
import { ApplyPromotionRow } from 'features/promotions'

import { QuantityControlTable } from './QuantityControlTable'

type QuantityControlSectionProps = {
  isModalActive: boolean
  amountSummary: AmountSummary
  isFullWidth?: boolean
}

export const QuantityControlSection = ({
  isModalActive,
  amountSummary,
  isFullWidth = false,
}: QuantityControlSectionProps) => {
  return (
    <section className='w-full px-2 pt-1 border p-5 border-Gray-200 rounded-lg'>
      <QuantityControlTable isFullWidth={isFullWidth} />
      <ApplyPromotionRow isModalActive={isModalActive} />
      <hr className='m-3 mt-2 text-Gray-300' />
      <InvoiceAmountSummary className='mx-3' amountSummary={amountSummary} />
    </section>
  )
}
