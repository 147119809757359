/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect } from 'react'
import { Button } from '@labourhub/labour-hub-ds'

import { CombineReportDownloadModal } from './CombineReportDownloadModal'

export type SearchHeaderProps = {
  candidateDetails: any
  isSearchEnable?: boolean | undefined
  searchFieldPlaceholder?: string | undefined
  isCombineReportModalActive: boolean
  setIsCombineReportModalActive: React.Dispatch<React.SetStateAction<boolean>>
  isCombineReportButtonDisabled: boolean
  [x: string]: any
}

export const SearchHeader = ({
  candidateDetails,
  paginationVariables,
  searchFieldPlaceholder,
  searchKeyWord,
  setSearchKeyWord,
  setPaginationVariables,
  isSearchEnable = true,
  isCombineReportButtonDisabled,
  isCombineReportModalActive,
  setIsCombineReportModalActive,
}: SearchHeaderProps) => {
  /** on change search */
  useEffect(() => {
    const timer = setTimeout(() => {
      searchKeyWord &&
        setPaginationVariables({
          ...paginationVariables,
          skip: 0,
          //take: 10,
          search: searchKeyWord,
        })
    }, 1000)
    return () => clearTimeout(timer)
  }, [searchKeyWord])

  return (
    <>
      <div className='flex items-center justify-between pt-[9px] pb-[13px]'>
        {isSearchEnable && (
          <div className='pl-[13px] w-3/4 relative'>
            <input
              value={searchKeyWord}
              autoComplete='off'
              onKeyUp={(e: any) => {
                if (e.keyCode == 13) {
                  setPaginationVariables({
                    ...paginationVariables,
                    search: searchKeyWord,
                  })
                }

                if (searchKeyWord.length === 0) {
                  setPaginationVariables({
                    ...paginationVariables,
                    search: searchKeyWord,
                  })
                }
              }}
              onChange={(e: any) => {
                setSearchKeyWord(e.target.value ?? '')
              }}
              type='text'
              className='w-full text-base font-Regular py-[6px] pl-10 pr-[30px] text-Gray-600 active:outline-Gray-50 focus:outline-Gray-50 rounded'
              placeholder={searchFieldPlaceholder}
            />

            <i className='absolute left-[26px] top-[5px] ri-search-line text-Gray-400'></i>

            {searchKeyWord && (
              <i
                className='absolute right-[10px] top-[6px] ri-close-line text-Gray-400'
                onClick={() => {
                  setSearchKeyWord('')
                  setPaginationVariables({
                    ...paginationVariables,
                    search: '',
                  })
                }}></i>
            )}
          </div>
        )}

        <div className='flex items-center'>
          <Button
            textTheme='blue'
            isDisabled={isCombineReportButtonDisabled}
            theme='white'
            className=''
            onClick={() => setIsCombineReportModalActive(true)}>
            <span className='flex items-center text-small font-Medium'>
              <i className='flex items-center ri-folder-add-line text-[20px] mr-3' />
              Combine Reports
            </span>
          </Button>
        </div>
      </div>
      {isCombineReportModalActive && (
        <CombineReportDownloadModal
          isModalActive={isCombineReportModalActive}
          setIsModalActive={setIsCombineReportModalActive}
          candidateDetails={candidateDetails}
        />
      )}
    </>
  )
}
