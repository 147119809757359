import CN from 'classnames'
import moment from 'moment'

export enum RefCheckStateToTextEnum {
  RESPONSE_RECEVIED = 'Response Received',
  AWAITING_RESPONSE = 'Awaiting for Response',
  REQUEST_PHONE_REF = 'Requested Phone Ref Check',
  REQUEST_DECLINED = 'Request Declined',
  NOT_SENT = 'Not Sent',
}

interface CandidateRefereeStatusCardProps {
  onClickViewReport: () => void
  sentDate?: string | null
  updatedDate?: string | null
  lastSendRequestDate?: string | null
  status: 'Failed' | 'Incomplete' | 'Passed' | 'NotSent' | null
  state:
    | 'RESPONSE_RECEVIED'
    | 'AWAITING_RESPONSE'
    | 'REQUEST_PHONE_REF'
    | 'REQUEST_DECLINED'
    | 'NOT_SENT'
    | null
}

export const CandidateRefereeStatusCard = ({
  status,
  state,
  sentDate,
  updatedDate,
  lastSendRequestDate,
  onClickViewReport,
}: CandidateRefereeStatusCardProps) => {
  return (
    <button
      onClick={state === 'RESPONSE_RECEVIED' ? onClickViewReport : undefined}
      className={CN(
        'rounded-[8px] p-2 h-[37px] ml-auto border-[1px] focus:outline-none flex items-center w-full gap-14',
        {
          'bg-Green-50 border-Green-200 cursor-pointer focus:outline-1':
            state === 'RESPONSE_RECEVIED',
          'bg-Red-50 border-Red-200 cursor-default':
            state === 'REQUEST_DECLINED',
          'bg-Yellow-50 border-Yellow-200 cursor-default':
            state === 'AWAITING_RESPONSE',
          'bg-Blue-50 border-Blue-200 cursor-default':
            state === 'REQUEST_PHONE_REF',
          'bg-Gray-50 border-Gray-200 cursor-default':
            status === null || status == 'NotSent',
        },
      )}>
      <span
        className={CN(
          'flex w-max flex-row items-center justify-center px-2.5 ',
        )}>
        <span
          className={CN('w-[8px] h-[8px] rounded-full mr-2', {
            'bg-Green-600': state === 'RESPONSE_RECEVIED',
            'bg-Red-500': state === 'REQUEST_DECLINED',
            'bg-Yellow-500': state === 'AWAITING_RESPONSE',
            'bg-[#027EAB]': state === 'REQUEST_PHONE_REF',
            'bg-[#2E2E2E]': state === null || state === 'NOT_SENT',
          })}
        />
        <span
          className={CN('text-small leading-3 font-Medium', {
            'text-Green-600': state === 'RESPONSE_RECEVIED',
            'text-Yellow-500': state === 'AWAITING_RESPONSE',
            'text-[#027EAB]': state === 'REQUEST_PHONE_REF',
            'text-[#FF4F3F]': state === 'REQUEST_DECLINED',
            'text-[#2E2E2E]': state === null || state === 'NOT_SENT',
          })}>
          {state === null || status == 'NotSent'
            ? 'Not Sent'
            : RefCheckStateToTextEnum[state]}
        </span>
      </span>
      {state !== 'NOT_SENT' ? (
        <>
          <div className='flex flex-row items-center text-left min-w-max gap-3'>
            <span className='leading-3 text-[12px] text-Gray-500'>Sent:</span>
            <span className='text-left text-small leading-3 font-SemiBold min-w-[84px]'>
              {sentDate ? moment(sentDate).format('DD MMM YYYY') : '-'}
            </span>
          </div>
          <div className='flex flex-row items-center gap-3'>
            <span className='leading-3 text-left text-[12px] text-Gray-500'>
              {state === 'AWAITING_RESPONSE'
                ? 'Referee Reminder:'
                : 'Completed:'}
            </span>
            <span className='text-left text-small leading-3 font-SemiBold text-Gray-800'>
              {state === 'AWAITING_RESPONSE'
                ? lastSendRequestDate
                  ? moment(lastSendRequestDate).format('DD MMM YYYY')
                  : 'Never'
                : updatedDate
                ? moment(updatedDate).format('DD MMM YYYY')
                : 'Pending'}
            </span>
          </div>
        </>
      ) : null}
    </button>
  )
}
