import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Radio, SimpleSelect, Toast } from '@labourhub/labour-hub-ds'
import { useSendAssessmentCertificate } from 'api/assessment/certificates/sendAssessmentCertificate'
import { useGetClientLogo, useGetClientSelectOptions } from 'api/clients'
import { assessmentReportPreviewData } from 'features/candidates/data/assessmentReportPreviewData'
// eslint-disable-next-line import/no-extraneous-dependencies
import { toCanvas } from 'html-to-image'
// eslint-disable-next-line import/no-extraneous-dependencies
import { jsPDF } from 'jspdf'
import { ModalProps, SelectOptionDto } from 'types'

import { Accordion } from 'components/atoms'
import { Column } from 'components/atoms/Column'
import { Row } from 'components/atoms/Row'
import { Modal, ModalFooter } from 'components/molecules/Modal'

import { AutoMarkingProgressProps } from '../AutoMarkingProgress'

import AssessmentDetailedCertificate from './AssessmentDetailedCertificate'

const SendCertificateModal = ({
  isModalActive,
  setIsModalActive,
  values,
  totalMarks,
  template,
  candidateDetails,
  certificateId,
  issueDate,
}: Partial<AutoMarkingProgressProps> &
  ModalProps & { certificateId: string; issueDate: Date }) => {
  const notify = (props: any) => Toast(props)

  const certificateRef = useRef<HTMLDivElement>(null)

  const [isPreparingCertificate, setIsPreparingCertificate] = useState(false)
  const [renderKeys, setRenderKeys] = useState<any>({
    reportType: 0,
    reportSetting: 0,
  })
  const [formBody, setFormBody] = useState({
    reportType: '',
    reportSetting: 'companyBrandOnly',
  })
  const [selectedClientOption, setSelectedClientOption] =
    useState<SelectOptionDto | null>(null)
  const [logoUrl, setLogoUrl] = useState<string | undefined>(undefined)
  const [clientOptions, setClientOptions] = useState<SelectOptionDto[]>([])
  const [previewData, setPreviewData] = useState<
    assessmentReportPreviewData | undefined
  >(undefined)
  // const [isOpenPreviewModal, setIsOpenPreviewModal] = useState(false)

  const {
    mutate: mutateSendAssessmentCertificate,
    isLoading: isSendingAssessmentCertificate,
  } = useSendAssessmentCertificate()
  const { refetch: refreshClientSelectOptions } = useGetClientSelectOptions(
    '',
    (options) => setClientOptions(options),
  )
  useGetClientLogo(selectedClientOption?.value ?? '', (data) =>
    setLogoUrl(data.logoUrl),
  )

  useEffect(() => {
    if (isModalActive) {
      refreshClientSelectOptions()
      setRenderKeys({
        ...renderKeys,
        cancellation: Math.random(),
        reason: Math.random(),
      })
    }
  }, [isModalActive])

  useEffect(() => {
    setPreviewData({
      clientId: selectedClientOption?.value,
      clientLogoUrl: logoUrl,
      hasClientLogo: formBody.reportSetting != 'companyBrandOnly',
      hasCompanyLogo: formBody.reportSetting != 'clientBrandOnly',
    })
  }, [formBody, selectedClientOption, logoUrl])

  const closeModal = () => {
    setFormBody({
      reportType: '',
      reportSetting: 'companyBrandOnly',
    })
    setRenderKeys({
      ...renderKeys,
      reportType: Math.random(),
      reportSetting: Math.random(),
    })
    setIsModalActive(false)
  }
  const resetClientOption = () => {
    setSelectedClientOption(null)
    setLogoUrl(undefined)
  }

  function convertBlobToBase64(blob) {
    return new Promise<string | null>((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result as string)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    })
  }

  const waitForImages = async () => {
    const images = Array.from(document.images)
    const targetImageIds = [
      'clientLogo',
      'companyLogoPrimary',
      'companyLogoSecondary',
    ]
    const logoImages = images.filter((img) => targetImageIds.includes(img.id))
    // const loadingImages = images.map((img) => {
    //   if (img.complete) {
    //     return Promise.resolve()
    //   }
    //   return new Promise((resolve) => {
    //     img.onload = resolve
    //     img.onerror = resolve // Also resolve if there's an error loading an image
    //   })
    // })
    // return Promise.all(loadingImages)

    // Replace each selected image with a base64 to bypass the cache
    const loadingImages = logoImages.map(async (img) => {
      try {
        const response = await fetch(img.src, { cache: 'no-store' })
        const blob = await response.blob()

        const blobUrl = await convertBlobToBase64(blob)

        if (blobUrl) {
          img.src = blobUrl
        }

        return new Promise((resolve) => {
          img.onload = resolve
          img.onerror = resolve // Also resolve if there's an error loading an image
        })
      } catch (error) {
        return Promise.resolve()
      }
    })

    // Wait for all selected images to finish loading
    return Promise.all(loadingImages)
  }

  const handleSend = async () => {
    setIsPreparingCertificate(true)
    if (
      formBody.reportSetting == 'bothBrands' ||
      formBody.reportSetting == 'clientBrandOnly'
    ) {
      if (clientOptions.length == 0) {
        notify({
          alertBody:
            'Clients details not available. Please add a new client or select a different option.',
          status: 'Warning',
        })
        setIsPreparingCertificate(false)
        return
      }

      if (!logoUrl) {
        notify({
          alertBody: 'Please select a client logo',
          status: 'Warning',
        })
        setIsPreparingCertificate(false)
        return
      }
    }
    try {
      await waitForImages()
      // await new Promise((resolve) => setTimeout(resolve, 5000))
      await document.fonts.ready
      if (certificateRef?.current) {
        const imageData = await toCanvas(certificateRef?.current, {
          // imagePlaceholder='image'
          backgroundColor: 'white',
          width: 1056,
          height: 816,
          // pixelRatio:2
        })

        const pdf = new jsPDF({
          orientation: 'l',
          unit: 'in',
          format: [11, 8.5],
          // putOnlyUsedFonts: true,
          // floatPrecision: 16,
        })
        const imgWidth = pdf.internal.pageSize.getWidth()
        const imgHeight = (imageData.height * imgWidth) / imageData.width
        pdf.addImage(imageData, 'PNG', 0, 0, imgWidth, imgHeight)

        // Convert the response to a Blob
        const pdfBlob = pdf.output('blob')
        const certificate = new File([pdfBlob], 'certificate.pdf', {
          type: 'application/pdf',
        })
        setIsPreparingCertificate(false)
        closeModal()
        mutateSendAssessmentCertificate({
          candidateId: candidateDetails.candidateId,
          assessmentId: template.assessmentId,
          certificate: certificate,
          certificateId: certificateId,
          issueDate: issueDate,
          assessmentResponseId: template.id,
        })
      }
    } catch (error) {
      setIsPreparingCertificate(false)
      closeModal()

      notify({
        alertBody: "Certificate wasn't sent.",
        status: 'Warning',
      })
    }
  }

  const renderClientDetail = () => {
    return (
      <Column>
        <Row className='w-full space-x-4'>
          <SimpleSelect
            value={{
              label: selectedClientOption?.label,
              value: selectedClientOption?.value,
            }}
            onChange={(selectedItem: any) => {
              setSelectedClientOption(selectedItem)
              setLogoUrl(undefined)
            }}
            label='Client List'
            options={clientOptions}
            placeholder='Select a Client'
            className='min-w-[225px]'
          />
          {logoUrl && (
            <img
              src={logoUrl}
              alt=''
              className='rounded h-[60px] w-[200px] mt-1'
            />
          )}
        </Row>
        <Row className='w-full space-x-4'>
          <Link to='/settings?tab=4' className='mt-2 font-Medium text-Blue-700'>
            Add New Client
          </Link>
        </Row>
      </Column>
    )
  }

  return (
    <Modal
      headerTitle={`Send Certificate`}
      customWidth='600px'
      isActive={isModalActive}
      isFooterShow={true}
      size='lg'
      onClickSecondaryBtn={closeModal}
      onHeaderCloseButtonClick={closeModal}
      onOverlayClick={closeModal}
      secondaryButtonTitle='Close'>
      <div className='w-full p-6 overflow-y-auto'>
        <p className='mb-3 text-small text-Gray-800'>
          Please select an option before sending the assessment report
        </p>
        <div className='w-full mt-4'>
          <Accordion
            heading='Customize this report for a client'
            initialState={false}
            description='Add your client’s branding to reports when sending feedback externally'>
            <div className='mt-5 space-y-4' key={renderKeys.reportSetting}>
              <Radio
                id='bothBrands'
                labelText='Include My Company Logo and Client Logo'
                helperText=''
                name='reportSetting'
                onChange={(e: any) => {
                  resetClientOption()

                  setFormBody({
                    ...formBody,
                    reportSetting: e.target.id,
                  })
                }}
              />

              {formBody.reportSetting == 'bothBrands' && renderClientDetail()}

              <Radio
                id='clientBrandOnly'
                labelText='Include Client Logo only (This removes your Company Logo in the report)'
                helperText=''
                name='reportSetting'
                onChange={(e: any) => {
                  resetClientOption()

                  setFormBody({
                    ...formBody,
                    reportSetting: e.target.id,
                  })
                }}
              />

              {formBody.reportSetting == 'clientBrandOnly' &&
                renderClientDetail()}
            </div>
          </Accordion>
        </div>
        <div>
          <h1 className='text-base font-Regular'>Preview</h1>
          <div
            className='w-[370px] h-[286px]'
            style={{
              transform: 'scale(0.35)',
              transformOrigin: 'top left',
            }}>
            <AssessmentDetailedCertificate
              ref={certificateRef}
              previewData={previewData}
              certificateId={certificateId}
              issueDate={issueDate}
              values={values}
              totalMarks={totalMarks}
              template={template}
              candidateDetails={candidateDetails}
            />
          </div>
        </div>
      </div>

      <ModalFooter>
        <div className='flex justify-end flex-1 bg-Gray-50'>
          <Button
            onClick={() => setIsModalActive(false)}
            textTheme='blue'
            theme='white'>
            Cancel
          </Button>
          <Button
            onClick={() => handleSend()}
            isLoading={isSendingAssessmentCertificate || isPreparingCertificate}
            isDisabled={
              isSendingAssessmentCertificate || isPreparingCertificate
            }
            className='ml-2 '
            theme='cobalt'>
            Send
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default SendCertificateModal
