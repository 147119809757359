import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const getAssessmentsList = async ({ queryKey }: any) => {
  const {
    take,
    skip,
    sortDirection,
    search,
    status,
    sortColumnName,
    category,
    difficultyLevel,
  } = queryKey[1]
  let URI = `Assessment?AssessmentType=assessment&Take=${take}&Skip=${skip}&sortDirection=${sortDirection}`
  if (search) {
    URI += `&Search=${search}`
  }
  if (status) {
    URI += `&IsActive=${status}`
  }
  if (sortColumnName) {
    URI += `&sortColumnName=${sortColumnName}`
  }
  if (category) {
    URI += `&Category=${category}`
  }
  if (difficultyLevel) {
    URI += `&difficultyLevel=${difficultyLevel}`
  }

  const res = await get(URI)
  return res.data
}

export const useGetAssessmentsList = (
  { ...reqBody },
  onSuccess: any,
  onError: any,
) => {
  return useQuery(['Assessment', reqBody], getAssessmentsList, {
    enabled: true,
    refetchOnWindowFocus: false,
    onSuccess,
    onError,
  })
}
