export const setEmbededVideoLink = (link: string) => {
  //   const platformLowercase = platform.toLowerCase()

  try {
    let embedUrl = ''
    if (link.includes('youtube.com') || link.includes('youtu.be')) {
      const videoId =
        link.split('v=')[1]?.split('&')[0] || link.split('youtu.be/')[1]
      embedUrl = `https://www.youtube.com/embed/${videoId}`
    } else if (link.includes('facebook.com')) {
      // const videoId = link.split('videos/')[1]?.split('/')[0]
      embedUrl = `https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(
        link,
      )}&show_text=0&width=560`
    } else if (link.includes('vimeo.com')) {
      const videoId = link.split('vimeo.com/')[1]
      embedUrl = `https://player.vimeo.com/video/${videoId}`
    } else {
      //   return link
      embedUrl = link
    }
    return embedUrl
  } catch (error) {
    return link
  }
}
