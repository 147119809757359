import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Button } from '@labourhub/labour-hub-ds'
import { ModalProps } from 'types'

import { Modal } from 'components/molecules'
import { getS3File } from 'utils'

import { setPayAsYouGoCreditsModalActive } from '../store'

type ZeroCreditAlertModalProps = ModalProps

export const ZeroCreditAlertModal = ({
  isModalActive,
  setIsModalActive,
}: ZeroCreditAlertModalProps) => {
  const dispatch = useDispatch()

  const handleClose = () => {
    setIsModalActive(false)
    localStorage.setItem('isZeroCreditLow', 'false')
  }

  const memoizedImage = useMemo(() => getS3File('zero-credit-alert-bg.svg'), [])

  return (
    <>
      <Modal
        isActive={isModalActive}
        onHeaderCloseButtonClick={handleClose}
        onClickSecondaryBtn={handleClose}
        isHeaderShow={false}
        onOverlayClick={handleClose}
        modalProps={{
          style: { width: '860px', height: '477px' },
        }}
        headerTitle='Zero Credit Alert'>
        <div className='flex w-full h-full'>
          <div className='flex items-start justify-start w-[428px]'>
            <img
              src={memoizedImage}
              alt='zero-credit-alert'
              className='h-full'
            />
          </div>

          <div className='flex flex-col items-start justify-start flex-1 w-full h-full overflow-y-auto'>
            <div className='flex items-end justify-end w-full px-[12px] pt-3'>
              <Button
                className='w-[20px] h-[20px] flex justify-center items-center'
                isIconOnly
                textTheme='black'
                theme='link'
                onClick={handleClose}
                icon={
                  <i className='cursor-pointer ri-close-circle-line text-[#09121F] text-heading-5'></i>
                }
              />
            </div>

            <div className='flex flex-col justify-start items-start mt-5 pl-8 w-full'>
              <div className='text-Cobalt-800 text-heading-3 font-SemiBold'>
                No Credit
              </div>

              <div className='flex pt-[45px] text-small font-Regular text-[#2E2E2E] leading-tight'>
                You currently have no credits available. To send a
                <br />
                request, please purchase additional credits.
              </div>
              <div className='text-[#4B5563] mt-6'>
                <ul className='space-y-2 text-left'>
                  <li className='flex items-start space-x-3'>
                    <svg
                      className='flex-shrink-0 w-3.5 h-2.5 mt-[5px]'
                      aria-hidden='true'
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 16 12'>
                      <path
                        stroke='currentColor'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        stroke-width='2'
                        d='M1 5.917 5.724 10.5 15 1.5'
                      />
                    </svg>
                    <span className='leading-tight'>
                      Easily send reference checks or assessment <br />
                      requests to candidates
                    </span>
                  </li>
                  <li className='flex items-start space-x-3'>
                    <svg
                      className='flex-shrink-0 w-3.5 h-2.5 mt-[5px]'
                      aria-hidden='true'
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 16 12'>
                      <path
                        stroke='currentColor'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        stroke-width='2'
                        d='M1 5.917 5.724 10.5 15 1.5'
                      />
                    </svg>
                    <span className='leading-tight items-start'>
                      Ensure standardized distribution and <br />
                      data collection
                    </span>
                  </li>
                  <li className='flex items-start space-x-3'>
                    <svg
                      className='flex-shrink-0 w-3.5 h-2.5 mt-[5px]'
                      aria-hidden='true'
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 16 12'>
                      <path
                        stroke='currentColor'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        stroke-width='2'
                        d='M1 5.917 5.724 10.5 15 1.5'
                      />
                    </svg>
                    <span>Speed up your hiring process</span>
                  </li>
                </ul>
              </div>

              <Button
                onClick={() => {
                  dispatch(setPayAsYouGoCreditsModalActive(true))
                  handleClose()
                }}
                className='mt-[70px] h-[30px] text-extra-small font-Medium'>
                Buy Credits
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
