import { useDispatch, useSelector } from 'react-redux'
import awardImage from 'assets/images/awardImage.svg'
import { setIsRedeemSuccessModalOpen } from 'features/promotions'
import { isRedeemSuccessModalOpen } from 'features/promotions/store/selectors'

import { ColumnWithAlignment } from 'components/atoms/Column'
import { Modal } from 'components/molecules'

export const PromoCreditRedeemSuccessModal = () => {
  const dispatch = useDispatch()
  const isModalOpen = useSelector(isRedeemSuccessModalOpen)

  return (
    <Modal
      isActive={isModalOpen}
      headerTitle={''}
      size='md'
      className='!z-[150]'
      isFooterShow={true}
      isSeparator={true}
      primaryButtonTitle={'Send'}
      secondaryButtonTitle='Cancel'
      isPrimaryButtonDisable={false}
      modalProps={{
        style: { maxHeight: '95vh' },
      }}
      onHeaderCloseButtonClick={() => {
        dispatch(setIsRedeemSuccessModalOpen(false))
      }}>
      <ColumnWithAlignment justifyAlignment='center' className='w-full h-full'>
        <div className='flex w-full'>
          <div className='pb-10 w-full'>
            <div className='flex flex-col items-center h-full text-center w-full'>
              <span className='text-heading-4 font-Bold text-Gray-800'>
                Congratulations!
              </span>
              <span className='text-heading-4 font-Bold text-Gray-800'>
                You've just received a special gift!
              </span>
              <img src={awardImage} alt='' className='h-[90px] my-3' />
              <span className='text-base text-center font-Medium'>
                Enjoy 10 FREE Credits on us.
              </span>
              <span className='text-[16px] leading-5 text-center font-Regular text-[#757575]'>
                Happy Hiring!
              </span>
            </div>
          </div>
        </div>
        {/* <div className='flex flex-col justify-center w-full px-36'>
          <Button onClick={() => {}}>
            <span className='flex items-center justify-center py-0.5'>
              Let's Go
              <i className='ri-arrow-right-s-line text-[20px] ml-1'></i>
            </span>
          </Button>
        </div> */}
      </ColumnWithAlignment>
      {/* <ModalFooter>
        <div className='flex justify-end flex-1'>
          <Button
            isLoading={false}
            onClick={() => {
              dispatch(setIsRedeemSuccessModalOpen(false))
            }}
            className='mr-2'
            isDisabled={false}
            theme='cobalt'>
            Close
          </Button>
          <Button
            isDisabled={isLoading}
            onClick={() => closeModal()}
            textTheme='blue'
            theme='white'>
            Cancel
          </Button>
        </div>
      </ModalFooter> */}
    </Modal>
  )
}
