import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

export type SetupTwoFAResponse = {
  isTwoFAEnabled: boolean
  secretKey: string
  authenticatorUri: string
}

export const useSetupTwoFA = (
  username: string,
  onSuccess: (data: SetupTwoFAResponse) => void,
) => {
  return useQuery(
    ['identity_2fa', username],
    async () => {
      const res = await get(`/identity/2fa/setup?username=${username}`)
      return res.data
    },
    {
      enabled: username != '',
      refetchOnWindowFocus: false,
      onSuccess,
    },
  )
}
