import { useMutation } from 'react-query'
import { postAPIwithFormData } from 'framework/api/http'

export type CreateClientRequest = {
  name: string
  email?: string
  phoneNumber?: string
  countryCode?: string
  logoFile: File
}

export const useCreateClient = () => {
  const mutate = useMutation(
    ({
      name,
      email,
      phoneNumber,
      countryCode,
      logoFile,
    }: CreateClientRequest) => {
      const bodyFormData = new FormData()
      bodyFormData.append('name', name)
      bodyFormData.append('logoFile', logoFile)
      if (email) bodyFormData.append('email', email)
      if (phoneNumber) bodyFormData.append('phoneNumber', phoneNumber)
      if (countryCode) bodyFormData.append('countryCode', countryCode)

      return postAPIwithFormData(`/clients`, bodyFormData, {})
    },
  )

  return mutate
}
