import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

import { FixedSubscriptionDto } from '../../features/subscriptions/types'

type GetActiveSubscriptionByTenantIdDto = {
  tenantId?: string
  onSuccess: (subscription: FixedSubscriptionDto) => void
}

export const useGetActiveSubscriptionByTenantId = ({
  tenantId,
  onSuccess,
}: GetActiveSubscriptionByTenantIdDto) => {
  return useQuery(
    ['subscriptions_active', tenantId],
    async (): Promise<FixedSubscriptionDto> => {
      const res = await get(`/Subscriptions/Active/${tenantId}`)
      return res.data
    },
    {
      enabled: !!tenantId,
      refetchOnWindowFocus: false,
      onSuccess,
      onError: () => {
        Toast({
          alertHeader: 'Error fetching active subscription',
          status: 'Error',
        })
      },
    },
  )
}
