import { ReactNode } from 'react'
import CN from 'classnames'

type RowProps = {
  children: ReactNode
  className?: string
  itemAlignment?: 'start' | 'end' | 'center'
  justifyAlignment?:
    | 'start'
    | 'end'
    | 'center'
    | 'between'
    | 'around'
    | 'evenly'
}

export const Row = ({ children, className }: Partial<RowProps>) => {
  return <div className={CN('flex w-full', className)}>{children}</div>
}

export const RowWithAlignment = ({
  children,
  className,
  itemAlignment = 'center',
  justifyAlignment = 'between',
}: RowProps) => {
  return (
    <div
      className={CN(
        'flex w-full',
        `items-${itemAlignment}`,
        `justify-${justifyAlignment}`,
        className,
      )}>
      {children}
    </div>
  )
}
