import { FilledTabs } from 'components/common/FilledTabs'

import { DownloadableAssessmentsReportTable } from './DownloadableAssessmentsReportTable'
import { DownloadableRefCheckReportsTable } from './DownloadableRefCheckReportsTable'

type DownloadableReportsTabProps = {
  candidateId: string
  candidateDetails: any
}
export const DownloadableReportsTab = ({
  candidateId,
  candidateDetails,
}: DownloadableReportsTabProps) => {
  const tabDetails = [
    {
      id: 1,
      tabButtonContent: 'Ref Check Reports',
      tabContent: (
        <DownloadableRefCheckReportsTable
          candidateId={candidateId}
          candidateDetails={candidateDetails}
        />
      ),
    },
    {
      id: 2,
      tabButtonContent: 'Assessment Reports',
      tabContent: (
        <DownloadableAssessmentsReportTable
          candidateId={candidateId}
          candidateDetails={candidateDetails}
        />
      ),
    },
  ]
  return (
    <>
      <FilledTabs defaultTabId={1} tabDetails={tabDetails} />
    </>
  )
}
