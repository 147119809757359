import { useMutation } from 'react-query'
import { RefereeTypeAccordionContentType } from 'features/candidates/components/AddEditCandidateModals/RequestSpecificRefereeForm'
import { postAPI } from 'framework/api/http'

type createCandidateProps = {
  candidateDetails?: any
  referenceTypes?: RefereeTypeAccordionContentType[]
  isAssessmentCandidate?: boolean
}

export const useCreateCandidate = () => {
  const mutate = useMutation(
    ({
      candidateDetails,
      referenceTypes,
      isAssessmentCandidate = false,
    }: createCandidateProps) => {
      /** Backend Issue : sending non usable attribute line two and suburb. refuse to remove . told send empty string to lineTwo
       * and city.
       * and told mainClientId send as null for now. because value will assign in future.
       */

      return postAPI(`/Candidate`, {
        countryCode: candidateDetails.countryCode,
        title: candidateDetails.title,
        firstName: candidateDetails.firstName,
        lastName: candidateDetails.lastName,
        middleName: candidateDetails.middleName,
        email: candidateDetails.email,
        phone: candidateDetails.phone,
        jobTitle: candidateDetails.jobTitle,
        referenceCount: candidateDetails.noOfReferences,
        gender: candidateDetails.gender,
        dateOfBirth: candidateDetails.dateOfBirth || null,
        consultantId: candidateDetails.consultantId,
        candidateSettings: candidateDetails.candidateSettings,
        referenceTypes: referenceTypes,
        isEmployee: candidateDetails.isEmployee,
        ...(isAssessmentCandidate && { isAssessmentCandidate: true }),
      })
    },
  )

  return mutate
}
