import { useDispatch } from 'react-redux'
import { Button } from '@labourhub/labour-hub-ds'

import { RowWithAlignment } from 'components/atoms/Row'

import { mandatorySignupStepperComponents } from '../../data'
import { setCurrentStep } from '../../store'
import { SignupStepperComponent } from '../../types'

import './Stepper.scss'

type SignUpStepperProps = {
  stepperComponents: SignupStepperComponent[]
  currentStep: number
}

export const SignUpStepper = ({
  stepperComponents = [...mandatorySignupStepperComponents],
  currentStep = 1,
}: SignUpStepperProps) => {
  const dispatch = useDispatch()
  return (
    <RowWithAlignment justifyAlignment='center' className='mt-5 gap-x-[10px]'>
      {currentStep >= 7 && (
        <Button
          className='w-fit'
          size='sm'
          textTheme='black'
          theme='white'
          iconLeft={<i className='ri-arrow-go-back-line'></i>}
          onClick={() => {
            dispatch(setCurrentStep(currentStep - 1))
          }}>
          Go Back
        </Button>
      )}
      <div className='step-dots mt-[20px]'>
        {stepperComponents?.map((step, index: number) => (
          <button
            key={index}
            className={`focus:outline-none step-dot ${
              index === currentStep - 1 ? 'active' : ''
            } `}
          />
        ))}
      </div>

      {currentStep >= 6 && currentStep < stepperComponents.length && (
        <Button
          className='w-fit'
          size='sm'
          textTheme='black'
          theme='white'
          iconLeft={<i className='ri-share-forward-line'></i>}
          onClick={() => {
            dispatch(setCurrentStep(currentStep + 1))
          }}>
          Skip
        </Button>
      )}
    </RowWithAlignment>
  )
}
