import { Navigate, useLocation } from 'react-router-dom'

type AuthRequiredProps = {
  [x: string]: any
}

export const AuthRequired = ({ children }: AuthRequiredProps) => {
  const token = localStorage.getItem('token')
  const location = useLocation()
  const returnUrl = location.search
    ? encodeURIComponent(location.pathname + location.search)
    : null

  return token ? (
    children
  ) : (
    <Navigate to={`/auth/login${returnUrl ? `?returnUrl=${returnUrl}` : ''}`} />
  )
}
