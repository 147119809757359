import { PayAsYouGoSubscriptionPackageType } from 'features/subscriptions'

import {
  PromotionCriteriaType,
  PromotionDto,
  PromotionOperatorType,
  PromotionType,
} from '../types'

export const calculatePromotionAmount = (
  promotion: PromotionDto | null,
  payAsYouGoCredits: Record<PayAsYouGoSubscriptionPackageType, number>,
  payAsYouGoUnitPrices: Record<PayAsYouGoSubscriptionPackageType, number>,
) => {
  const subTotal = Object.keys(payAsYouGoCredits).reduce(
    (total, key) => total + payAsYouGoCredits[key] * payAsYouGoUnitPrices[key],
    0,
  )

  let promotionAmount = 0

  if (!promotion)
    return {
      subTotal,
      promotionAmount,
    }

  if (promotion.promotionCriteria == PromotionCriteriaType.SubTotal) {
    promotionAmount = applyPromotionOperator(promotion, subTotal)
  }

  if (promotion.promotionCriteria == PromotionCriteriaType.Individual) {
    promotionAmount = Object.keys(payAsYouGoCredits).reduce((acc, key) => {
      let total = 0
      if (
        promotion.individualCriteria &&
        promotion.individualCriteria.length > 0 &&
        promotion.individualCriteria.includes(key)
      ) {
        total = payAsYouGoCredits[key] * payAsYouGoUnitPrices[key]
      }
      return acc + applyPromotionOperator(promotion, total)
    }, 0)
  }

  return {
    subTotal,
    promotionAmount,
  }
}

export const applyPromotionOperator = (
  promotion: PromotionDto | undefined,
  totalAmount: number,
): number => {
  const promotionAmount = getPromotionAmount(promotion, totalAmount)

  if (!promotion) {
    return 0
  }

  switch (promotion.promotionOperator) {
    case PromotionOperatorType.GreaterThan:
      return totalAmount > promotion.promotionOperatorValue
        ? promotionAmount
        : 0
    case PromotionOperatorType.EqualTo:
      return totalAmount === promotion.promotionOperatorValue
        ? promotionAmount
        : 0
    case PromotionOperatorType.LessThan:
      return totalAmount < promotion.promotionOperatorValue
        ? promotionAmount
        : 0
    case PromotionOperatorType.GreaterThanOrEqualTo:
      return totalAmount >= promotion.promotionOperatorValue
        ? promotionAmount
        : 0
    case PromotionOperatorType.LessThanOrEqualTo:
      return totalAmount <= promotion.promotionOperatorValue
        ? promotionAmount
        : 0
    default:
      return totalAmount - promotionAmount > 0 ? promotionAmount : 0
  }
}

export const getPromotionAmount = (
  promotion: PromotionDto | undefined,
  totalAmount: number,
): number => {
  if (!promotion) return 0

  return promotion.promotionType == PromotionType.Fixed
    ? promotion.amount
    : (totalAmount * promotion.amount) / 100
}
