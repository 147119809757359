import { useState } from 'react'
import { Button, SimpleSelect } from '@labourhub/labour-hub-ds'
import { difficultyList } from 'features/questionnaires/data/assessmentDifficultyLevels'
import { questionnairesTemplateType } from 'features/questionnaires/data/questionnairesTemplateType'
import { AnimatePresence, motion } from 'framer-motion'

const difficultyListWithAll = [
  { label: 'All', value: 'All' },
  ...difficultyList,
]

const templateTypesWithAll = [
  { label: 'All', value: 'All' },
  ...questionnairesTemplateType,
]

type SearchHeaderProps = {
  setPaginationVariables: React.Dispatch<React.SetStateAction<any>>
  isFilterMenuOpen: boolean
}

export const SearchHeader = ({
  setPaginationVariables,
  isFilterMenuOpen,
}: SearchHeaderProps) => {
  /** Filter states & Variables */
  const [selectedType, setSelectedType] = useState('All')

  const [difficulty, setDifficulty] = useState('All')

  const applyFilter = () => {
    setPaginationVariables((prev) => ({
      ...prev,
      difficultyLevel: difficulty !== 'All' ? difficulty : '',
      type: selectedType !== 'All' ? selectedType : '',
    }))
  }

  const resetFilter = () => {
    setSelectedType('All')
    setDifficulty('All')
    setPaginationVariables((prev) => ({
      ...prev,
      difficultyLevel: '',
      type: '',
    }))
  }

  return (
    <div className='w-full h-full questionnaires-list'>
      {/* expanding filter panel with open close animation */}
      <AnimatePresence initial={false}>
        {isFilterMenuOpen && (
          <motion.div
            className='flex w-full bg-white px-4'
            initial='collapsed'
            animate='open'
            exit='collapsed'
            transition={{ type: 'tween', duration: 0.3 }}
            variants={{
              open: { opacity: 1, y: 0, height: 'auto' },
              collapsed: { opacity: -1, y: -35, height: 0 },
            }}>
            <div className='flex justify-center items-end pt-3 pb-4 z-[4]'>
              <SimpleSelect
                label='Difficulty'
                value={difficultyListWithAll?.filter(
                  (item: any) => item?.value === difficulty,
                )}
                className='w-[190px]'
                onChange={(selectedItem: any) =>
                  setDifficulty(selectedItem.value)
                }
                options={difficultyListWithAll}
              />

              <SimpleSelect
                label='Type'
                value={templateTypesWithAll?.filter(
                  (item: any) => item?.value === selectedType,
                )}
                className='ml-5 w-[190px]'
                onChange={(selectedItem: any) =>
                  setSelectedType(selectedItem.value)
                }
                placeholder='Placeholder'
                options={templateTypesWithAll}
              />

              <Button
                className='ml-4 h-[38px] w-[140px]'
                isDisabled={selectedType === 'All' && difficulty === 'All'}
                onClick={() => applyFilter()}>
                Apply Filter
              </Button>
              <Button
                className='ml-2 h-[38px] w-[100px]'
                theme='white'
                textTheme='blue'
                onClick={() => resetFilter()}>
                Reset
              </Button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
