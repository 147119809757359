import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@labourhub/labour-hub-ds'
import { setPayAsYouGoCreditsModalActive } from 'features/payments'
import { AssessmentLibraryPreviewModal } from 'features/questionnaires/components/AssessmentTab/AssessmentLibraryCardOverview'
import { AssessmentPreviewModal } from 'features/questionnaires/components/AssessmentTab/AssessmentTable'
import { setIsNewAssessmentModalOpen } from 'features/questionnaires/store/actions'
import { getSubscriptionStore } from 'features/subscriptions'

import { AlertModal } from 'components/common/AlertModal'
import { Modal, ModalFooter } from 'components/molecules/Modal'

import DeleteAddonModal from './AddOns/DeleteAddonModal'
import { AssessmentData } from './CustomAssessmentCardItem'
import SendAssessmentConfirmationModel from './SendAssessmentConfirmationModel'
import SendAssessmentModal, { Addon } from './SendAssessmentModal'

type AssessmentAddons = {
  assessmentId: string
  assessmentName: string
  duration: string
  credits?: number
  isAddOn: boolean
  mainAssessmentId: string
  imageUrl: string
}

type AssessmentDetailProp = {
  isOpen: boolean
  assessment:
    | {
        assessmentAddons: AssessmentAddons[]
        name: string
        sentOn: string
        sentBy: string
        dueDate: string
      }
    | any
  selectedAddons: Addon[]
  sendAssessmentData: AssessmentData
  sendCustomTemplateIsLoading: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleSecondaryAction?: () => void
  setSendAssessmentData: Dispatch<SetStateAction<AssessmentData>>
  setSelectedAddons: React.Dispatch<React.SetStateAction<Addon[]>>
  handleSend: () => void
}

const AssessmentDetail = ({
  label,
  value,
}: {
  label: string
  value: string
}) => (
  <div className='flex justify-between mb-2 w-full'>
    <span className='text-Gray-600'>{label}</span>
    <span className='text-Gray-800 font-SemiBold'>{value}</span>
  </div>
)

export const ResendAssessmentConfirmationModal = ({
  isOpen,
  assessment,
  selectedAddons,
  sendAssessmentData,
  sendCustomTemplateIsLoading,
  setIsOpen,
  handleSecondaryAction,
  setSendAssessmentData,
  setSelectedAddons,
  handleSend,
}: AssessmentDetailProp) => {
  const [isPreviewModalActive, setIsPreviewModalActive] = useState(false)

  const [isOpenAddonDelete, setIsOpenAddonDelete] = useState(false)
  const [isSendConfirmationModalOpen, setIsSendConfirmationModalOpen] =
    useState(false)

  const [totalCredits, setTotalCredits] = useState(1)
  const [isOpenSendAssessment, setIsOpenSendAssessment] = useState(false)
  const [addonMarkedForDelete, setAddonMarkedForDelete] = useState<Addon>()

  const dispatch = useDispatch()
  const { remainingCredit } = useSelector(getSubscriptionStore)

  const [isNoCreditsModalOpen, setIsNoCreditsModalOpen] = useState(false)

  //hard coded credit values
  const credits = 1

  useEffect(() => {
    const credits = selectedAddons?.reduce((total, addon) => {
      return total + (addon?.credits || 1)
    }, 1)
    if (credits) {
      setTotalCredits(credits)
    }

    const selectedAddonsIds = selectedAddons?.map((addon) => addon.assessmentId)

    setSendAssessmentData((prev) => ({ ...prev, addonIds: selectedAddonsIds }))
  }, [selectedAddons.length])

  useEffect(() => {
    const existingAssessmentsAddons: any = assessment?.assessmentAddons?.map(
      (addon) => ({
        assessmentId: addon.assessmentId,
        assessmentName: addon.assessmentName,
        credits: 1,
        duration: parseInt(addon.duration),
        imageUrl: addon.imageUrl,
      }),
    )

    setSelectedAddons(existingAssessmentsAddons)
  }, [])

  const handleSecondaryButtonClick = () => {
    if (handleSecondaryAction) {
      handleSecondaryAction()
    } else {
      setIsOpen(false)
    }
  }

  const handlePrimaryAction = () => {
    if (
      !Number(remainingCredit) ||
      Number(remainingCredit) === 0 ||
      Number(remainingCredit) < totalCredits
    ) {
      setIsNoCreditsModalOpen(true)
    } else {
      setIsSendConfirmationModalOpen(true)
    }
  }

  const handleRemoveAssessment = (addon: Addon) => {
    setSelectedAddons((prev) =>
      prev?.filter((prevAddon) => prevAddon.assessmentId != addon.assessmentId),
    )
  }
  const handleDeleteConfirm = () => {
    handleRemoveAssessment(addonMarkedForDelete!)
  }

  const handleAddNewAssessment = () => {
    setIsOpenSendAssessment(true)
  }

  return (
    <>
      <Modal
        isActive={isOpen}
        onOverlayClick={() => setIsOpen(false)}
        headerTitle={''}
        size='md'
        className='!z-[50]'
        modalProps={{
          style: {
            width: '600px',
            position: 'relative',
            minHeight: '498px ',
            height: 'fit',
          },
        }}
        isFooterShow={true}
        isPrimaryButtonDisable={false}
        onHeaderCloseButtonClick={() => {
          setIsOpen(false)
        }}>
        <div className='flex flex-col items-center justify-center p-6 pt-0'>
          <div className='flex items-center justify-center w-12 h-12 rounded-full bg-[#FFF6EC]'>
            <i className='ri-error-warning-line text-[#DD7F0B] text-heading-3'></i>
          </div>
          <span className='pt-5 text-Gray-900 text-heading-5 font-Medium'>
            Resend Assessment Request
          </span>
          <span className='pt-2 text-center text-Gray-500 text-small font-Regular'>
            This assessment request has already been sent. Resending it will use{' '}
            <span className='font-Medium'> {totalCredits}</span>{' '}
            {totalCredits > 1 ? ' credits' : ' credit'}. Would you like to
            proceed?
          </span>

          <div className=' mt-[18px]  grid grid-cols-6 gap-2 w-full'>
            <div className='text-extra-small col-span-2 font-Regular text-Gray-600 '>
              Assessment Name
            </div>
            <div className='flex flex-col col-span-1 '>
              <div className='text-extra-small font-Medium text-Gray-600 '>
                <i className='ri-coins-line text-small align-middle' />
                {/* {assessment.credits}
              {assessment.credits > 1 ? ' credits' : ' credit'} */}
                {/* todo get credit cost  */}1 credit
              </div>
            </div>

            <div className='flex flex-col col-span-3 w-full '>
              <div className='text-[13px] relative  ml-auto leading-5  font-Medium text-Gray-800 flex flex-row gap-2 '>
                {assessment.name}
              </div>
            </div>
            {selectedAddons.length > 0 && (
              <div className='text-extra-small col-span-2 font-Regular text-Gray-600 '>
                Add-ons
              </div>
            )}
            <div className='flex flex-col col-span-1 '>
              {selectedAddons?.map((addon, i) => (
                <div
                  key={`${addon.assessmentId}-${i}`}
                  className='text-extra-small font-Medium text-Gray-600 '>
                  <i className='ri-coins-line text-small align-middle' />
                  {credits} {credits > 1 ? ' credits' : ' credit'}
                </div>
              ))}
            </div>

            <div className='flex flex-col col-span-3 '>
              {selectedAddons?.map((addon, i) => (
                <div
                  key={`${addon.assessmentId}--${i}`}
                  className='text-[13px] group leading-5 ml-auto font-Medium  text-Gray-800  flex flex-row gap-2'>
                  <h1 className='line-clamp-1'>{addon.assessmentName}</h1>
                  <button
                    onClick={() => {
                      setAddonMarkedForDelete(addon)
                      setIsOpenAddonDelete(true)
                    }}
                    className='ri-delete-bin-line hidden group-hover:flex text-Red-500 cursor-pointer align-middle'
                  />
                </div>
              ))}
            </div>
          </div>
          <div className='space-y-2 mt-[18px] w-full '>
            <div className='space-y-2'>
              <div className='flex items-end justify-between gap-4'>
                <div className='text-base font-bold text-gray-900 ' />
                <button
                  onClick={handleAddNewAssessment}
                  className='flex items-center justify-center text-extra-small text-Blue-500 font-bold text-gray-900'>
                  <span>
                    <i className='ri-add-line text-Blue-500 w-4 h-4 text-base' />
                  </span>
                  <span className='ml-2'> Add new Assessment</span>
                </button>
              </div>

              <AssessmentDetail label='Sent on' value={assessment.sentOn} />
              <AssessmentDetail label='Sent by' value={assessment.sentBy} />
              <AssessmentDetail label='Due Date' value={assessment.dueDate} />
            </div>
          </div>
        </div>

        <ModalFooter className='border-t-0 shadow-none bg-Gray-50'>
          <div className='flex flex-1 gap-2 justify-end'>
            <Button
              onClick={() => handleSecondaryButtonClick()}
              textTheme='blue'
              className='w-1/3'
              theme='white'>
              Close
            </Button>
            <Button
              onClick={handlePrimaryAction}
              className='w-1/3'
              theme='cobalt'>
              I&apos;m Sure
            </Button>
          </div>
        </ModalFooter>

        {isOpenAddonDelete && (
          <DeleteAddonModal
            handleDeleteConfirm={handleDeleteConfirm}
            isOpenAddonDelete={isOpenAddonDelete}
            setIsOpenAddonDelete={setIsOpenAddonDelete}
          />
        )}
      </Modal>
      {/* {isOpenSendAssessment && (
        <ResendAddonsModal
          isModalActive={isOpenSendAssessment}
          setIsModalActive={setIsOpenSendAssessment}
          selectedAddons={selectedAddons}
          selectedAssessment={assessment}
          setSelectedAddons={setSelectedAddons}
          totalCredits={totalCredits}
        />
      )} */}
      {isOpenSendAssessment && (
        <SendAssessmentModal
          handleSend={() => {}}
          sendAssessmentData={sendAssessmentData}
          isModalActive={isOpenSendAssessment}
          sendCustomTemplateIsLoading={sendCustomTemplateIsLoading}
          setIsModalActive={setIsOpenSendAssessment}
          setSendAssessmentData={setSendAssessmentData}
          selectedAddons={selectedAddons}
          assessment={assessment}
          setSelectedAddons={setSelectedAddons}
          setIsPreviewModalActive={setIsPreviewModalActive}
          isResendAssessment={true}
        />
      )}

      {isSendConfirmationModalOpen && (
        <SendAssessmentConfirmationModel
          isModalActive={isSendConfirmationModalOpen}
          setIsModalActive={setIsSendConfirmationModalOpen}
          handleSend={handleSend}
          sendAssessmentData={sendAssessmentData}
          setSendAssessmentData={setSendAssessmentData}
          sendCustomTemplateIsLoading={sendCustomTemplateIsLoading}
          totalCredits={totalCredits}
        />
      )}
      <AlertModal
        title='No Credits'
        description={`No credits are available. Please purchase more credits to send an assessment request.`}
        handlePrimaryAction={() => {
          dispatch(setIsNewAssessmentModalOpen(false))
          setIsNoCreditsModalOpen(false)
          dispatch(setPayAsYouGoCreditsModalActive(true))
        }}
        primaryButtonTitle='Buy Credits'
        isOpen={isNoCreditsModalOpen}
        setIsOpen={setIsNoCreditsModalOpen}
      />
      {isPreviewModalActive && assessment?.assessmentLibraryId && (
        <AssessmentLibraryPreviewModal
          isModalActive={isPreviewModalActive}
          setIsModalActive={setIsPreviewModalActive}
          templateId={assessment?.assessmentLibraryId}
          templateName={assessment?.name}
        />
      )}
      {isPreviewModalActive && assessment?.assessmentId && (
        <AssessmentPreviewModal
          isDraft={false}
          isModalActive={isPreviewModalActive}
          setIsModalActive={setIsPreviewModalActive}
          templateId={assessment?.assessmentId}
          templateName={assessment?.name}
        />
      )}
    </>
  )
}
