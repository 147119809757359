/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import ReactTooltip from 'react-tooltip'
import { Badge, Button } from '@labourhub/labour-hub-ds'

import { timeToAESTWithSeconds } from 'utils'

export const AssessmentListColumn = (
  tableData,
  setTableData,
  setIsShowPreviewModal,
  setSelectedTemplate,
  setShowDeleteQuestionaryModal,
) => {
  const COLUMNS = [
    {
      Header: 'ASSESSMENT',
      accessor: 'name',
      forSorting: 'Name',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center justify-start h-full',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return (
          <div className={`max-w-[280px]`}>
            <p
              className='truncate cursor-pointer text-Cobalt-400'
              onClick={() => {
                setIsShowPreviewModal(true)
                setSelectedTemplate({
                  value: original.id,
                  label: original.name,
                  isDraft: !original.isActive,
                })
              }}
              data-tip={original?.name}
              data-for={original?.id}>
              <ReactTooltip
                place='right'
                id={original?.id}
                effect='solid'
                textColor='#FFFFFF'
                backgroundColor='#4B5563'
                delayShow={800}
              />
              {original?.name}
            </p>
          </div>
        )
      },
    },

    {
      Header: 'STATUS',
      accessor: 'isActive',
      forSorting: 'IsActive',
      isSortable: true,
      hasFilter: false,
      isVisible: true,
      commonClassName: 'w-full',
      headerClassName: 'flex items-center justify-start',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return (
          <div>
            <Badge size='large' theme={original?.isActive ? 'primary' : 'gray'}>
              {original?.isActive ? 'Published' : 'Draft'}
            </Badge>
          </div>
        )
      },
    },
    {
      Header: 'QUESTIONS',
      accessor: 'questionCount',
      forSorting: 'questionCount',
      isSortable: false,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        return <div>{original?.questionCount}</div>
      },
    },

    {
      Header: 'LAST EDITED ON',
      accessor: 'updateon',
      forSorting: 'updateon',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[205px]',
      headerClassName: 'flex items-center justify-start',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        const createdDateString = timeToAESTWithSeconds(original?.updatedOn)

        return <div>{createdDateString}</div>
      },
    },
    {
      Header: 'TYPE',
      accessor: 'type',
      forSorting: 'type',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return (
          <div>
            <Badge
              size='large'
              isIcon={true}
              icon={
                original?.type == 'Library'
                  ? 'ri-file-add-line'
                  : original?.type == 'AI'
                  ? 'ri-app-store-line'
                  : 'ri-draft-line'
              }
              theme={
                original?.type == 'Library'
                  ? 'light-green'
                  : original?.type == 'AI'
                  ? 'light-yellow'
                  : 'purple'
              }>
              {original?.type == 'Library'
                ? 'Library'
                : original?.type == 'AI'
                ? 'AI'
                : 'Custom'}
            </Badge>
          </div>
        )
      },
    },
    {
      Header: '',
      accessor: 'action',
      forSorting: '',
      isSortable: false,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full',
      headerClassName: 'flex items-center justify-center',
      cellClassName: 'flex items-center justify-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        const toggleShowMore = (rowData: any) => {
          const toBeUpdateTableData = {
            ...tableData,
            assessment: tableData?.assessment?.map((item: any) => {
              if (item?.id === rowData?.id) {
                return {
                  ...item,
                  showMore: !item?.showMore,
                }
              } else {
                return {
                  ...item,
                  showMore: false,
                }
              }
            }),
          }
          setTableData(toBeUpdateTableData)
        }

        return (
          <Button
            icon={
              <i className='ri-delete-bin-line text-Red-500 text-heading-5' />
            }
            isIconOnly
            onClick={() => {
              setSelectedTemplate({
                value: original.id,
                label: original.name,
              })
              setShowDeleteQuestionaryModal(true)
              toggleShowMore(original)
            }}
            className='w-8 h-8 border-none'
            textTheme='red'
            theme='white'
          />
        )
      },
    },
  ]

  return COLUMNS
}
