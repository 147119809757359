import React from 'react'
import CN from 'classnames'

/* Table Body TD Cell */
export const UITableTDCell = (cell: any) => {
  return (
    <div
      className={CN(
        'h-[44px] px-[12px] flex items-center text-small text-Gray-900 font-Regular group-last:border-b-0',
        cell?.column?.commonClassName,
        cell?.column?.cellClassName,
        {
          'text-right justify-end': cell?.column?.align === 'right',
          'text-left': cell?.column?.align === 'left' || !cell?.column?.align,
        },
      )}>
      {cell?.render('Cell')}
    </div>
  )
}

/* Table Head TH Cell */
export const UITableTHCell = ({
  className,
  icon,
  children,
  isSorted,
  headerClassName,
}: any) => {
  return (
    <div
      className={CN(
        'ui-table-th-cell h-[40px] px-[12px] text-Gray-500 font-Medium text-extra-small flex items-center',
        className,
        headerClassName,
        {
          'bg-N-200': isSorted,
        },
      )}>
      <span className='ui-table-th-cell__label'>{children}</span>
      <span className='ml-[4px] h-[24px] ui-table-th-cell__icon inline-flex items-center'>
        <i className={icon} />
      </span>
    </div>
  )
}
