import { CandidateProfileBasicDetailsType } from 'api/candidates'

import { Column } from 'components/atoms/Column'

type SurveyResponseCountSectionProps = {
  stats: CandidateProfileBasicDetailsType
}

export const SurveyResponseCountSection = ({
  stats,
}: SurveyResponseCountSectionProps) => {
  return (
    <div className='pb-4'>
      {/* Card Section */}
      <Column>
        <div className='w-full p-4 bg-white border rounded-lg border-Gray-200'>
          <div className='flex items-start justify-between mb-4'>
            <p className='mb-2 text-base text-left text-Gray-800 font-Medium'>
              Total Surveys Sent
            </p>
            <p className='text-right text-heading-3 font-Bold'>
              {stats.surveyResponseStatsCount?.totalCount}
            </p>
          </div>

          <div className='mb-4 border border-Gray-200' />

          <div className='flex flex-row border border-b-0 rounded-t border-Cobalt-100'>
            <div className='flex flex-1 flex-col text-extra-small  font-Regular text-center text-[#6C6D6F] p-4'>
              Response Received
            </div>
            <div className='flex flex-1 flex-col border-x border-Cobalt-100 text-extra-small font-Regular text-center text-[#6C6D6F] p-4'>
              Awaiting for Response
            </div>
            <div className='flex flex-1 flex-col text-extra-small font-Regular text-center text-[#6C6D6F] p-4'>
              Declined
            </div>
          </div>
          <div className='flex flex-row border rounded-b border-Cobalt-100'>
            <div className='flex flex-1 flex-col text-heading-5 p-2 font-Medium text-center bg-[#F2F9FF]'>
              {stats.surveyResponseStatsCount?.receivedCount < 10
                ? `0${stats.surveyResponseStatsCount?.receivedCount}`
                : stats.surveyResponseStatsCount?.receivedCount}
            </div>
            <div className='flex flex-1 flex-col border-x border-Cobalt-100 text-base p-2 font-Medium text-center bg-[#F2F9FF]'>
              {stats.surveyResponseStatsCount?.awaitingCount < 10
                ? `0${stats.surveyResponseStatsCount?.awaitingCount}`
                : stats.surveyResponseStatsCount?.awaitingCount}
            </div>
            <div className='flex flex-1 flex-col text-base p-2 font-Medium text-center bg-[#F2F9FF]'>
              {stats.surveyResponseStatsCount?.declinedCount < 10
                ? `0${stats.surveyResponseStatsCount?.declinedCount}`
                : stats.surveyResponseStatsCount?.declinedCount}
            </div>
          </div>
        </div>
      </Column>
    </div>
  )
}
