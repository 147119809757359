import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

import { DynamicFormType } from '../getDynamicForm'

export const useGetDynamicLibraryForm = (id: string) => {
  return useQuery(
    ['dynamic_library_form', id],
    async () => {
      if (id && id !== '') {
        const res = await get(`/questionnaires/library/${id}/dynamic-form`)
        return res.data as DynamicFormType
      }
    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
      onError: () => {
        Toast({
          alertHeader: 'Error fetching dynamic library form',
          status: 'Error',
        })
      },
    },
  )
}
