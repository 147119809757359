import React, { ChangeEvent } from 'react'

import { cn } from 'utils'

type RadioOption = {
  label: string
  value: string
  disabled?: boolean
}

type RadioGroupProps = {
  options: RadioOption[]
  name: string
  selectedValue: string
  onChange: (value: string) => void
  direction?: 'vertical' | 'horizontal'
}

export const RadioGroup: React.FC<RadioGroupProps> = ({
  options,
  name,
  selectedValue,
  onChange,
  direction = 'vertical',
}) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value)
  }

  // Determine the layout classes based on direction prop
  const layoutClass =
    direction === 'horizontal' ? 'flex space-x-6' : 'space-y-4'

  return (
    <div className={layoutClass}>
      {options.map((option) => (
        <label key={option.value} className='flex items-center space-x-2'>
          <input
            type='radio'
            name={name}
            value={option.value}
            checked={selectedValue === option.value}
            onChange={handleChange}
            disabled={option.disabled}
            className={cn(
              'form-check-input mb-[1px] appearance-none rounded-full h-4 w-4 border border-Gray-300 bg-white checked:bg-white checked:border-Cobalt-600 checked:border-[5px] hover:bg-Gray-200 active:bg-Gray-400 focus:outline-none focus:ring focus:ring-Cobalt-300 transition duration-200 bg-no-repeat bg-center bg-contain float-left cursor-pointer',
              {
                'bg-Gray-300 cursor-not-allowed': option.disabled,
              },
            )}
          />
          <span
            className={cn('text-small text-Gray-700 cursor-pointer', {
              'cursor-not-allowed': option.disabled,
            })}>
            {option.label}
          </span>
        </label>
      ))}
    </div>
  )
}
