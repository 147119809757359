import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Toast } from '@labourhub/labour-hub-ds'
import { setPayAsYouGoCreditsModalActive } from 'features/payments'
import { setIsNewAssessmentModalOpen } from 'features/questionnaires/store/actions'
import { getSubscriptionStore } from 'features/subscriptions'
import { ModalProps } from 'types'

import { AlertModal } from 'components/common/AlertModal'

import AddonCard from './AddOns/AddonCard'
import EmptyAddOns from './AddOns/EmptyAddOns'
import PopularAddons from './AddOns/PopularAddons'
import AssessmentDetails from './AssessmentDetails'
import { AssessmentData } from './CustomAssessmentCardItem'
import SendAssessmentConfirmationModel from './SendAssessmentConfirmationModel'

export type Addon = {
  assessmentId: string
  assessmentName: string
  credits?: number
  duration: string
  imageUrl: string
  questionCount: string
}

type AddNewAssessmentModalProps = ModalProps & {
  setSendAssessmentData: React.Dispatch<React.SetStateAction<AssessmentData>>
  sendAssessmentData: AssessmentData
  selectedAddons: Addon[]
  setSelectedAddons: React.Dispatch<React.SetStateAction<Addon[]>>
  handleSend: () => void
  sendCustomTemplateIsLoading: boolean
  setIsPreviewModalActive: React.Dispatch<React.SetStateAction<boolean>>
  assessment: any
  isResendAssessment?: boolean
}

const SendAssessmentModal = ({
  isModalActive,
  sendAssessmentData,
  selectedAddons,
  sendCustomTemplateIsLoading,
  assessment,
  setIsModalActive,
  setSendAssessmentData,
  handleSend,
  setSelectedAddons,
  setIsPreviewModalActive,
  isResendAssessment = false,
}: AddNewAssessmentModalProps) => {
  const dispatch = useDispatch()
  const { remainingCredit } = useSelector(getSubscriptionStore)

  const [isNoCreditsModalOpen, setIsNoCreditsModalOpen] = useState(false)

  const [isSendConfirmationModalOpen, setIsSendConfirmationModalOpen] =
    useState(false)

  // todo initial asssement credit is hardcoded as 1
  const [totalCredits, setTotalCredits] = useState<number>(1)
  //   const [totalMinutes, setTotalMinutes] = useState(assessment.duration)

  useEffect(() => {
    const credits = selectedAddons?.reduce((total, addon) => {
      return total + (addon?.credits || 1)
    }, 1)
    // const minutes = selectedAddons?.reduce((total, addon) => {
    //   return total + addon.duration
    // }, assessment.duration)

    if (credits) {
      setTotalCredits(credits)
    }
    // if (minutes) {
    //   setTotalMinutes(minutes)
    // }

    const selectedAddonsIds = selectedAddons?.map((addon) => addon.assessmentId)

    setSendAssessmentData((prev) => ({ ...prev, addonIds: selectedAddonsIds }))
  }, [selectedAddons?.length])

  const handleAddAssessment = (addon: Addon) => {
    if (
      !Number(remainingCredit) ||
      Number(remainingCredit) === 0 ||
      Number(remainingCredit) < totalCredits + (addon?.credits || 1)
    ) {
      setIsNoCreditsModalOpen(true)
    } else {
      setSelectedAddons((prev) => {
        if (prev) {
          const isAlreadyAdded = prev.some(
            (prevAddon) => prevAddon.assessmentId === addon.assessmentId,
          )
          return isAlreadyAdded ? prev : [...prev, addon]
        }
        return [addon]
      })
      Toast({
        alertBody: '',
        alertHeader: `${addon.assessmentName} added successfully.`,
        status: 'Success',
      })
    }
  }

  const handleRemoveAssessment = (addon: Addon) => {
    setSelectedAddons((prev) =>
      prev?.filter((prevAddon) => prevAddon.assessmentId != addon.assessmentId),
    )
  }
  const handleClose = () => {
    setSelectedAddons([])
    setIsModalActive(false)
  }
  const setResendAssessment = () => {
    setSelectedAddons((prev) => [...prev])
    setIsModalActive(false)
  }

  return (
    <>
      <Modal
        headerTitle='Add New Assessment'
        onOverlayClick={handleClose}
        modalProps={{
          style: { width: '1261px', height: '756px' },
        }}
        className='!z-[100]'
        isActive={isModalActive}
        onClickPrimaryBtn={() =>
          isResendAssessment
            ? setResendAssessment()
            : setIsSendConfirmationModalOpen(true)
        }
        onClickSecondaryBtn={handleClose}
        onHeaderCloseButtonClick={handleClose}
        primaryButtonProps={{
          style: { marginLeft: '12px' },
          isLoading: sendCustomTemplateIsLoading,
          // isDisabled: !sendAssessmentData.assessmentDate,
        }}
        secondaryButtonProps={{ textTheme: 'black' }}
        footerProps={{
          style: {
            justifyContent: 'flex-start',
            flexDirection: 'row-reverse',
          },
        }}
        primaryButtonTitle='Confirm'
        secondaryButtonTitle='Cancel'>
        <div className='px-6 bg-Gray-100 cursor-auto overflow-y-auto pr-2 flex flex-row w-[1261px] h-[750px] justify-between py-6 gap-6 '>
          <div className='space-y-2 bg-white   w-[592px] p-6 rounded-md'>
            <AssessmentDetails
              selectedAddons={selectedAddons}
              assessment={assessment}
              setIsPreviewModalActive={setIsPreviewModalActive}
            />

            <div>
              <h1 className='font-SemiBold text-heading-5 pb-2 '>Add-Ons</h1>
              <>
                {selectedAddons?.length > 0 ? (
                  <div className='styled-scroll overflow-y-auto pr-2 -mr-2  h-[180px] py-4 flex flex-col gap-5 overflow-x-hidden scroll-m-0 scroll-p-0'>
                    {selectedAddons?.map((addon, i) => (
                      <AddonCard
                        key={`${addon.assessmentName}--${i}`}
                        isSelected={true}
                        handleAddAssessment={handleAddAssessment}
                        handleRemoveAssessment={handleRemoveAssessment}
                        addon={addon}
                        isDisabled={selectedAddons?.some(
                          (selected) =>
                            selected.assessmentId === addon.assessmentId,
                        )}
                      />
                    ))}
                  </div>
                ) : (
                  <EmptyAddOns />
                )}
              </>
            </div>
          </div>
          {/* popular addon section */}
          <PopularAddons
            handleAddAssessment={handleAddAssessment}
            handleRemoveAssessment={handleRemoveAssessment}
            selectedAddons={selectedAddons}
          />
        </div>
      </Modal>
      <SendAssessmentConfirmationModel
        isModalActive={isSendConfirmationModalOpen}
        setIsModalActive={setIsSendConfirmationModalOpen}
        handleSend={handleSend}
        sendAssessmentData={sendAssessmentData}
        setSendAssessmentData={setSendAssessmentData}
        sendCustomTemplateIsLoading={sendCustomTemplateIsLoading}
        totalCredits={totalCredits}
      />

      <AlertModal
        title='No Credits'
        description={`No credits are available. Please purchase more credits to send an assessment request.`}
        handlePrimaryAction={() => {
          dispatch(setIsNewAssessmentModalOpen(false))
          setIsNoCreditsModalOpen(false)
          dispatch(setPayAsYouGoCreditsModalActive(true))
        }}
        primaryButtonTitle='Buy Credits'
        isOpen={isNoCreditsModalOpen}
        setIsOpen={setIsNoCreditsModalOpen}
      />
    </>
  )
}
export default SendAssessmentModal
