import { createSlice } from '@reduxjs/toolkit'

import {
  INITIAL_AGENCY_SETTINGS_PAYLOAD,
  INITIAL_CANDIDATE_SETTINGS_PAYLOAD,
  INITIAL_REPORT_SETTINGS,
} from '../data'
import { AppSettingProps, ReportSettingsForm } from '../types'

export type SettingsStoreProps = {
  agencyAppSettings: AppSettingProps[]
  candidateAppSettings: AppSettingProps[]
  reportSettings: ReportSettingsForm
  assessmentReportSettings: ReportSettingsForm
}

const initialState: SettingsStoreProps = {
  agencyAppSettings: [...INITIAL_AGENCY_SETTINGS_PAYLOAD],
  candidateAppSettings: [...INITIAL_CANDIDATE_SETTINGS_PAYLOAD],
  reportSettings: INITIAL_REPORT_SETTINGS,
  assessmentReportSettings: INITIAL_REPORT_SETTINGS,
}

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setAgencyAppSettingsState(state, { payload }) {
      state.agencyAppSettings = payload
    },
    setCandidateAppSettingsState(state, { payload }) {
      state.candidateAppSettings = payload
    },
    setReportSettingsState(state, { payload }) {
      state.reportSettings = payload
    },
    setAssessmentReportSettingsState(state, { payload }) {
      state.assessmentReportSettings = payload
    },
  },
})

export const {
  setAgencyAppSettingsState,
  setCandidateAppSettingsState,
  setReportSettingsState,
  setAssessmentReportSettingsState,
} = settingsSlice.actions

export default settingsSlice.reducer
