import { useMutation } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { putAPI } from 'framework/api/http'

export const useDeleteCandidate = (
  candidateId: string | null,
  flag: boolean,
) => {
  return useMutation(
    () => {
      return putAPI(`/Candidate/${candidateId}/Archive/${flag}`, null)
    },
    {
      onSuccess: () => {
        Toast({
          alertHeader: `Candidate deleted successfully`,
          status: 'Success',
        })
      },
      onError: () => {
        Toast({
          alertHeader: `Candidate deletion failed`,
          status: 'Error',
        })
      },
    },
  )
}

export default useDeleteCandidate
