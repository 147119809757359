import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

import { EmailTemplateDto, SmsTemplateDto } from './getCustomTemplateByName'

export type UpdateCustomTemplateRequest = {
  id: string
  emailTemplate?: EmailTemplateDto
  smsTemplate?: SmsTemplateDto
}

export const useUpdateCustomTemplate = () => {
  const mutate = useMutation((request: UpdateCustomTemplateRequest) => {
    return putAPI(`/templates/custom/${request.id}`, request)
  })

  return mutate
}
