import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

type MarkAllAsReadRequestProps = {
  UserProfileId: string
}

export const useMarkAllAsRead = () => {
  const mutate = useMutation(({ UserProfileId }: MarkAllAsReadRequestProps) => {
    return postAPI(`/facade/notifications/${UserProfileId}/readall`, {})
  })

  return mutate
}
