import { useMutation } from 'react-query'
import { PayAsYouGoSubscriptionPackageType } from 'features/subscriptions'
import { postAPI } from 'framework/api/http'

type CreatePayAsYouGoSubscriptionDto = {
  paymentCardId: string
  promotionCode?: string
  credits: Record<PayAsYouGoSubscriptionPackageType, number>
  isSaveMyCardChecked?: boolean
}

export const useCreatePayAsYouGoSubscription = () => {
  const mutate = useMutation((dto: CreatePayAsYouGoSubscriptionDto) => {
    return postAPI(`/subscriptions/payasyougo`, dto)
  })

  return mutate
}
