import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

type useUpdateOnboardingUserProfileRequest = {
  userId: string
  firstName: string
  lastName: string
  jobTitle: string
}

export const useUpdateOnboardingUserProfile = () => {
  const mutate = useMutation(
    ({
      userId,
      firstName,
      lastName,
      jobTitle,
    }: useUpdateOnboardingUserProfileRequest) => {
      return putAPI(`/UserProfile/${userId}`, {
        firstName,
        lastName,
        jobTitle,
      })
    },
  )

  return mutate
}
