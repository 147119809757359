import { ReferenceCheckType } from 'types'

import { AppSettingKey, AppSettingProps, ReportSettingsForm } from '../types'

export const DEFAULT_SETTINGS: Record<AppSettingKey, string> = {
  AUTO_REFERENCING: 'true',
  IS_SURVEY_ENABLED: 'true',
  IS_ASSESSMENT_ENABLED: 'true',
  IS_PHONE_REF_CHECK_HIDE: 'false',
  LEAD_GEN_CAREER_INTEREST: 'false',
  LEAD_GEN_STAFF_REQUEST: 'false',
  NCC_POLICE_CHECK: 'false',
  NCC_POLICE_CHECK_VOLUNTEER: 'false',
  NCC_RIGHT_TO_WORK_CHECK: 'false',
  QUESTIONNAIRE_TEMPLATE_ID: 'Blank',
  REQUEST_REFEREE_DETAILS_PROCESS: 'true',
  SMS_FEATURE: 'false',
  IS_CANDIDATE_PROFILE_IMAGE_REQUIRED: 'true',
  IS_CANDIDATE_EMAIL_REQUIRED: 'true',
  IS_REFEREE_EMAIL_REQUIRED: 'true',
  DEFAULT_REFERENCE_CHECK_TYPE: ReferenceCheckType?.Employment,
}

export const INITIAL_AGENCY_SETTINGS_PAYLOAD: AppSettingProps[] = [
  {
    key: 'AUTO_REFERENCING',
    value: 'true',
  },
  {
    key: 'IS_PHONE_REF_CHECK_HIDE',
    value: 'false',
  },
  {
    key: 'REQUEST_REFEREE_DETAILS_PROCESS',
    value: 'true',
  },
  {
    key: 'QUESTIONNAIRE_TEMPLATE_ID',
    value: 'Blank',
  },
  {
    key: 'LEAD_GEN_CAREER_INTEREST',
    value: 'false',
  },
  {
    key: 'LEAD_GEN_STAFF_REQUEST',
    value: 'false',
  },
  {
    key: 'SMS_FEATURE',
    value: 'false',
  },
  {
    key: 'NCC_POLICE_CHECK',
    value: 'false',
  },
  {
    key: 'NCC_POLICE_CHECK_VOLUNTEER',
    value: 'false',
  },
  {
    key: 'NCC_RIGHT_TO_WORK_CHECK',
    value: 'false',
  },
  {
    key: 'IS_CANDIDATE_PROFILE_IMAGE_REQUIRED',
    value: 'true',
  },
  {
    key: 'IS_CANDIDATE_EMAIL_REQUIRED',
    value: 'true',
  },
  {
    key: 'IS_REFEREE_EMAIL_REQUIRED',
    value: 'true',
  },
  {
    key: 'DEFAULT_REFERENCE_CHECK_TYPE',
    value: ReferenceCheckType?.Employment,
  },
]

export const INITIAL_CANDIDATE_SETTINGS_PAYLOAD: AppSettingProps[] = [
  {
    key: 'AUTO_REFERENCING',
    value: 'false',
  },
  {
    key: 'REQUEST_REFEREE_DETAILS_PROCESS',
    value: 'false',
  },
  {
    key: 'QUESTIONNAIRE_TEMPLATE_ID',
    value: 'Blank',
  },
  {
    key: 'LEAD_GEN_CAREER_INTEREST',
    value: 'false',
  },
  {
    key: 'LEAD_GEN_STAFF_REQUEST',
    value: 'false',
  },
  {
    key: 'SMS_FEATURE',
    value: 'false',
  },
]

export const INITIAL_REPORT_SETTINGS: ReportSettingsForm = {
  backgroundImage: {
    isChecked: true,
    value: undefined,
  },
  companyLogo: {
    isChecked: true,
    value: undefined,
  },
  textColour: '#085DA8',
  lineColour: '#1E75C6',
  privateReportsEmailPhone: false,
  privateReportsName: false,
}
