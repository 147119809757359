import { Button } from '@labourhub/labour-hub-ds'
import notSupported from 'assets/animations/not-supported-error.json'
import LogoMini from 'assets/images/refHubLogoText.svg'
import { useLottie } from 'lottie-react'

type BrowserBlockedPageProps = {
  mailto: any
}

export const BrowserBlockedPage = ({ mailto }: BrowserBlockedPageProps) => {
  const AnimatedView = () => {
    const options = {
      animationData: notSupported,
      loop: false,
      autoplay: true,
    }

    const { View } = useLottie(options)
    return View
  }

  return (
    <div className='fixed top-0 left-0 w-full h-full z-50 bg-white'>
      <div className='w-full justify-center items-center py-4 shadow-sm'>
        <img
          src={LogoMini}
          alt=''
          className='flex w-full justify-center items-center h-[25px] px-4'
        />
      </div>

      <div className='flex flex-col w-full h-full items-center justify-start px-2 pt-8'>
        <div className='flex w-[163px] justify-center items-center'>
          <AnimatedView />
        </div>

        <span className='flex text-Gray-800 font-Medium text-heading-4 mt-6 pb-2'>
          Your browser is not supported!
        </span>

        <p className='text-Gray-600 font-Regular text-center mb-8'>
          Sorry! Our system is best run with browsers like Chrome, Edge, Opera
          and Safari. Your browser is not supported at the moment.
        </p>

        <Button
          className='w-1/4 mb-3'
          onClick={() => {
            window.open('https://www.refhub.com.au', '_blank')
          }}>
          Visit Our Website
        </Button>
        <Button
          className='w-1/5 mt-3'
          textTheme='blue'
          theme='white'
          onClick={() =>
            (window.location.href = `mailto:${mailto}?subject=Browser Issue`)
          }>
          Email Us
        </Button>
      </div>
    </div>
  )
}
