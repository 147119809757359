import { useEffect, useState } from 'react'
import { ActionMeta } from 'react-select'
import { SimpleSelect, TextField, Toast } from '@labourhub/labour-hub-ds'
import { CardBillingAddressDto } from 'api/payments'
import CN from 'classnames'
import {
  useAddSuburb,
  useGetCountryList,
  useGetStateListByCountryCode,
  useGetSuburbsByStateCode,
} from 'framework/api/methods'

import { CreatableInputSelect } from 'components/atoms'

type BillingAddressFormProps = {
  setIsValidBillingAddress: any
  billingAddress: CardBillingAddressDto
  setBillingAddress: any
  isCheckoutModal?: boolean
}

export const BillingAddressForm = ({
  setIsValidBillingAddress,
  billingAddress,
  setBillingAddress,
  isCheckoutModal = false,
}: BillingAddressFormProps) => {
  const [isErrorField, setIsErrorField] = useState<any>({
    firstName: false,
    lastName: false,
    lineOne: false,
    state: false,
    suburb: false,
    postCode: false,
  })

  const [stateList, setStateList] = useState([])
  const [suburbList, setSuburbList] = useState([])
  const [countryList, setCountryItemList] = useState([])

  useEffect(() => {
    const { firstName, lastName, lineOne, state, postCode } = billingAddress

    setIsErrorField({
      ...isErrorField,
      firstName: firstName ? false : true,
      lastName: lastName ? false : true,
      lineOne: lineOne ? false : true,
      state: state ? false : true,
      postCode: postCode ? false : true,
    })

    const isValid =
      firstName && lastName && lineOne && state && postCode ? true : false
    setIsValidBillingAddress(isValid)
  }, [billingAddress])

  /** get country list when tab active */
  useEffect(() => {
    getCountryList()
    getStateListByCountryCode('AU')
  }, [])

  /** Get state list by country code */
  const { mutate: getStateListByCountyCodeMutate } =
    useGetStateListByCountryCode()

  /** Process the state list by country code*/
  async function getStateListByCountryCode(countryCode: string) {
    getStateListByCountyCodeMutate(
      { countryCode },
      {
        onSuccess: ({ data: successData }: any) => {
          const tempStateList = successData?.states?.map((item: any) => {
            return {
              ...item,
              value: item.stateCode,
              label: item.name,
            }
          })
          setStateList(tempStateList)
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting state list!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** Get suburb list by state code */
  const { mutate: getSuburbsByStateCodeMutate } = useGetSuburbsByStateCode()

  /** Process the get suburb list by state code*/
  async function getSuburbsByStateCode(stateCode: string) {
    getSuburbsByStateCodeMutate(
      { stateCode },
      {
        onSuccess: ({ data: successData }: any) => {
          const tempSuburbList = successData?.suburbs?.map((item: any) => {
            return {
              ...item,
              value: item.name,
              label: item.name,
            }
          })
          setSuburbList(tempSuburbList)
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting suburb list!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** Get country list */
  const { mutate: getCountryListMutate } = useGetCountryList()

  /** Process the get country list */
  async function getCountryList() {
    getCountryListMutate(
      {},
      {
        onSuccess: ({ data: successData }: any) => {
          const tempCountryList = successData?.countries?.map((item: any) => {
            return {
              ...item,
              value: item.code,
              label: item.name,
            }
          })
          setCountryItemList(tempCountryList)
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error getting country list!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** APi call for the add new suburb */
  const { mutate: addSuburbMutate } = useAddSuburb()

  /** Process the add new suburb */
  const addSuburb = (stateCode, name) => {
    addSuburbMutate(
      {
        stateCode,
        name,
      },
      {
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** handle suburb on Change */
  const handleSuburbChange = (newValue: any, actionMeta: ActionMeta<any>) => {
    if (actionMeta.action == 'create-option') {
      addSuburb(billingAddress.stateCode, actionMeta?.option?.label)
    }

    setBillingAddress({
      ...billingAddress,
      suburb: newValue?.label,
    })
  }

  return (
    <div
      className={CN(
        'flex flex-col w-full p-6 mt-6 border rounded-lg border-Gray-200',
        {
          'bg-white': isCheckoutModal,
        },
      )}>
      <div className='pb-4 text-base font-SemiBold'>Billing Address</div>

      <div className='grid w-full grid-cols-2 gap-x-[30px] gap-y-4'>
        <TextField
          className='w-full'
          value={billingAddress?.firstName}
          onChange={(e: any) => {
            setBillingAddress({
              ...billingAddress,
              firstName: e.target.value,
            })
          }}
          label='First Name'
          placeholder=''
          isRequired
          isErrored={isErrorField?.firstName === '' ? true : false}
        />

        <TextField
          className='w-full'
          value={billingAddress?.lastName}
          onChange={(e: any) => {
            setBillingAddress({
              ...billingAddress,
              lastName: e.target.value,
            })
          }}
          label='Last Name'
          placeholder=''
          isRequired
          isErrored={isErrorField?.lastName === '' ? true : false}
        />

        <TextField
          className='w-full'
          value={billingAddress?.lineOne}
          onChange={(e: any) => {
            setBillingAddress({
              ...billingAddress,
              lineOne: e.target.value,
            })
          }}
          label='Address Line 1'
          placeholder=''
          isRequired
          isErrored={isErrorField?.lineOne === '' ? true : false}
        />

        <TextField
          className='w-full'
          value={billingAddress?.lineTwo}
          onChange={(e: any) => {
            setBillingAddress({
              ...billingAddress,
              lineTwo: e.target.value,
            })
          }}
          label='Address Line 2'
          placeholder=''
        />

        <SimpleSelect
          label='Country'
          placeholder='Select Country'
          value={{
            label: billingAddress?.country,
            value: billingAddress?.countryCode,
          }}
          className='w-full'
          onChange={(selectedItem: any) => {
            if (billingAddress?.countryCode !== selectedItem?.value) {
              setBillingAddress({
                ...billingAddress,
                country: selectedItem?.label,
                countryCode: selectedItem?.value,
                state: '',
                stateCode: '',
                suburb: '',
              })

              getStateListByCountryCode(selectedItem.value)
            }
          }}
          options={countryList}
        />

        <SimpleSelect
          label='State'
          placeholder='Select State'
          value={{
            label: billingAddress?.state,
            value: billingAddress?.stateCode,
          }}
          className='w-full'
          onChange={(selectedItem: any) => {
            if (billingAddress?.stateCode !== selectedItem?.value) {
              setBillingAddress({
                ...billingAddress,
                state: selectedItem?.label,
                stateCode: selectedItem?.value,
                suburb: '',
              })
              getSuburbsByStateCode(selectedItem.value)
            }
          }}
          options={stateList}
          isRequired
          isErrored={isErrorField?.state === '' ? true : false}
        />

        <CreatableInputSelect
          label='Suburb'
          className='w-full'
          value={{
            label: billingAddress?.suburb,
            value: billingAddress?.suburb,
          }}
          options={suburbList}
          onChange={handleSuburbChange}
          isDisabled={!billingAddress?.stateCode}
          // isErrored={isErrorField?.suburb === '' ? true : false}
        />

        <TextField
          className='w-full'
          value={billingAddress?.postCode}
          onChange={(e: any) => {
            setBillingAddress({
              ...billingAddress,
              postCode: e.target.value,
            })
          }}
          label='Post Code'
          placeholder=''
          isRequired
          isErrored={isErrorField?.postCode === '' ? true : false}
        />
      </div>
    </div>
  )
}
