import cn from 'classnames'

type ReportDividerBarProps = {
  size?: 'xs' | 'sm' | 'md'
  color?: string
  text?: string
}

export const ReportDividerBar = ({
  size = 'md',
  color = '#1E75C6',
  text,
}: ReportDividerBarProps) => {
  return text ? (
    <div style={{ backgroundColor: color }} className='w-full h-[28px]'>
      <span className='px-4 text-base leading-7 text-white font-Medium'>
        {text}
      </span>
    </div>
  ) : (
    <div
      style={{ backgroundColor: color }}
      className={cn('w-full px-4', {
        'h-[5px]': size == 'xs',
        'h-[10px]': size == 'sm',
        'h-[14px]': size == 'md',
      })}
    />
  )
}
