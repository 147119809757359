import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

import { AssessmentQuestionDto } from './createAssessment'

export type UpdateAssessmentRequest = {
  AId: string
  name: string
  type: string
  duration: number
  questions: AssessmentQuestionDto[]
  isActive: boolean
  difficultyLevel: string
}
export const useUpdateAssessment = () => {
  const mutate = useMutation(
    ({
      AId,
      name,
      type,
      duration,
      questions,
      isActive,
      difficultyLevel,
    }: UpdateAssessmentRequest) => {
      return putAPI(`/assessment/${AId}/update`, {
        name,
        type,
        duration,
        questions,
        isActive,
        difficultyLevel,
      })
    },
  )

  return mutate
}
