import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'
import { QueryParams } from 'types/queryParams'

import { AgencyProfileDto } from '../../features/agencies/types'

export type AgencyListQueryParams = QueryParams & {
  fromDate: string
  toDate: string
  subscriptionPlanType?: string
  isFilterApplied: boolean
}

export type AgencyDashboardResponse = {
  agencyProfiles: AgencyProfileDto[]
  stats: SuAdminDashboardStatsDto
}

export type SuAdminDashboardStatsDto = {
  totalClients: TotalClientsStatsDto
  totalIncome: TotalIncomeStatsDto
  completedChecksCount: CompletedChecksStatsDto
}

export type CompletedChecksStatsDto = {
  referenceChecks: number
  rightToWorkChecks: number
  policeChecks: number
  volunteerChecks: number
}

export type TotalClientsStatsDto = {
  payg: number
  fifiteenPack: number
  twentyfivePack: number
  fiftyPack: number
  enterprisePack: number
}

export type TotalIncomeStatsDto = {
  monthly: number
  annually: number
}

const getAgencyDashboard = async ({ queryKey }: any) => {
  const {
    take,
    skip,
    search,
    fromDate,
    toDate,
    isFilterApplied,
    sortColumnName,
    sortDirection,
    subscriptionPlanType,
  } = queryKey[1] as AgencyListQueryParams
  let URI = `/facade/AgencyProfile/SuperAdminDashboard?take=${take}&skip=${skip}`

  if (search) {
    URI += `&search=${search}`
  }

  if (isFilterApplied && fromDate) {
    URI += `&fromDate=${fromDate}`
  }

  if (isFilterApplied && toDate) {
    URI += `&toDate=${toDate}`
  }

  if (sortColumnName) {
    URI += `&sortColumnName=${sortColumnName}`
  }

  if (sortDirection) {
    URI += `&sortDirection=${sortDirection}`
  }

  if (subscriptionPlanType) {
    URI += `&subscriptionPlanType=${subscriptionPlanType}`
  }

  const res = await get(URI)
  return res.data
}

export const useGetAgencyDashboard = (
  queryParams: AgencyListQueryParams,
  onSuccess: (data: AgencyDashboardResponse) => void,
) => {
  return useQuery(['agencyDashboard', queryParams], getAgencyDashboard, {
    enabled: true,
    refetchOnWindowFocus: false,
    onSuccess,
    onError: () => {
      Toast({
        alertHeader: 'Error fetching agency dashboard',
        status: 'Error',
      })
    },
  })
}
