import { useMemo } from 'react'
import bonus8Image from 'assets/images/bonus/bonus8.svg'
import bonus10Image from 'assets/images/bonus/bonus10.svg'
import bonus12Image from 'assets/images/bonus/bonus12.svg'
import bonus14Image from 'assets/images/bonus/bonus14.svg'
import bonus15Image from 'assets/images/bonus/bonus15.svg'
import bonus17Image from 'assets/images/bonus/bonus17.svg'
import {
  FixedSubscriptionPackageType,
  SubscriptionType,
} from 'features/subscriptions'

type BonusCreditBannerProps = {
  packagename: string
  subscriptionType: string
}

export const BonusCreditBanner = ({
  packagename,
  subscriptionType,
}: BonusCreditBannerProps) => {
  const bonusCreditImageMap = {
    [FixedSubscriptionPackageType.FifteenPack]: {
      [SubscriptionType.Monthly]: bonus8Image,
      [SubscriptionType.Yearly]: bonus10Image,
    },
    [FixedSubscriptionPackageType.TwentyfivePack]: {
      [SubscriptionType.Monthly]: bonus12Image,
      [SubscriptionType.Yearly]: bonus14Image,
    },
    [FixedSubscriptionPackageType.FiftyPack]: {
      [SubscriptionType.Monthly]: bonus15Image,
      [SubscriptionType.Yearly]: bonus17Image,
    },
  }

  const banner = useMemo(
    () => bonusCreditImageMap[packagename][subscriptionType],
    [packagename, subscriptionType],
  )

  return banner ? (
    <img src={banner} alt='' className='object-fill w-full pb-5' />
  ) : (
    <></>
  )
}
