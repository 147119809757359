import { useEffect, useState } from 'react'
import { SimpleSelect, TextField } from '@labourhub/labour-hub-ds'
import { uploadTypes } from 'features/questionnaires/data/uploadTypes'
import { videoPlatformList } from 'features/questionnaires/data/videoPlatform'
import { AssessmentQuestion } from 'features/questionnaires/types'

import { setEmbededVideoLink } from 'utils/setEmbededVideoLink'

type AddVideoLinkProps = {
  isDisabled: boolean
  setEditableQuestion: React.Dispatch<React.SetStateAction<AssessmentQuestion>>
  editableQuestion: AssessmentQuestion
  question?: AssessmentQuestion
  isModalActive?: boolean
}

const AddVideoLink = ({
  isDisabled = true,
  setEditableQuestion,
  editableQuestion,
  question,
  isModalActive = false,
}: AddVideoLinkProps) => {
  const [embedLink, setEmbedLink] = useState('')
  const [selectedPlatform, setSelectedPlatform] = useState<any>('')
  const [, setHasError] = useState(false)
  useEffect(() => {
    if (question?.videoLink) {
      setEmbedLink(setEmbededVideoLink(question.videoLink))
    }
  }, [question?.videoLink, question?.videoPlatform])
  useEffect(() => {
    if (!isModalActive) {
      setSelectedPlatform('')
    }
  }, [isModalActive])
  useEffect(() => {
    setSelectedPlatform(
      videoPlatformList.find(
        (item) => item.value === editableQuestion?.videoPlatform,
      ),
    )
  }, [question?.videoPlatform])

  return (
    <div
      className={`my-3 ${
        !isModalActive && 'border-t border-t-Gray-200'
      } w-full flex flex-col gap-3 ${isDisabled && 'pointer-events-none'}`}>
      {(!question?.videoLink && !question?.videoPlatform) || !isModalActive ? (
        <>
          <div className='mt-3'>
            <SimpleSelect
              label='Select a Platform'
              value={selectedPlatform}
              className='w-full'
              onChange={(selectedItem: any) => {
                setEditableQuestion({
                  ...editableQuestion,
                  videoPlatform: selectedItem.value,
                  uploadType: uploadTypes.Video,
                })
                setSelectedPlatform(selectedItem)
              }}
              placeholder='Youtube'
              options={videoPlatformList}
            />
          </div>
          <div className='mt-3'>
            <TextField
              value={editableQuestion?.videoLink}
              onChange={(e: any) => {
                setEditableQuestion({
                  ...editableQuestion,
                  videoLink: e.target.value,
                })
              }}
              label='Link'
              placeholder='www.youtube.com/watch?v=0TmwSE_LVYU'
            />
          </div>
        </>
      ) : null}
      {isModalActive && question?.videoLink && question?.videoPlatform && (
        <>
          <iframe
            // width={500}
            height={235}
            className='w-full'
            src={embedLink}
            title='Video Preview'
            frameBorder={0}
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
            onError={() => setHasError(true)}
          />
          <a
            href={question?.videoLink}
            target='_blank'
            className='no-underline text-Cobalt-500 mx-auto'>
            <span>{question?.videoLink}</span>
          </a>
        </>
      )}
    </div>
  )
}

export default AddVideoLink
