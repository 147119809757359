import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Checkbox, Modal, TextField } from '@labourhub/labour-hub-ds'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { useCreatePayAsYouGoSubscription } from 'api/subscriptions'
import { PaymentErrorSetterType } from 'features/payments/types'
import { getPromotionStore } from 'features/promotions'
import { setIsHeaderBarDetailsUpdated } from 'store/reducers/user/userSlice'
import { ModalProps } from 'types'

import {
  getPaymentStore,
  setPayAsYouGoCreditsModalActive,
  toggleIsRefetchBillingHistory,
  toggleIsRefetchPaymentCards,
} from '../../../store'

type SaveAndPayCardModalProps = ModalProps & {
  clientSecret: string
  setInvoice: any
  setIsInvoiceModalActive: any
  setIsPayNowLoading: any
  setCardBillingAddress: any
  setPaymentError?: PaymentErrorSetterType
}

export const SaveAndPayCardModal = ({
  isModalActive,
  setIsModalActive,
  clientSecret,
  setInvoice,
  setIsInvoiceModalActive,
  setCardBillingAddress,
  setIsPayNowLoading,
  setPaymentError,
}: SaveAndPayCardModalProps) => {
  const dispatch = useDispatch()

  const { isHeaderBarDetailsUpdated } = useSelector((state: any) => state.user)
  const { payAsYouGoCredits } = useSelector(getPaymentStore)
  const { promotion } = useSelector(getPromotionStore)

  const [isLoading, setIsLoading] = useState(false)
  const stripe = useStripe()
  const elements = useElements()

  const [isSaveMyCardChecked, setIsSaveMyCardChecked] = useState(true)
  const [isClicked, setIsClicked] = useState(false)
  const [nameOnCard, setNameOnCard] = useState('')

  const [cardComplete, setCardComplete] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  })

  const handleCardElementOnChange = (e: any) => {
    setCardComplete({ ...cardComplete, [e.elementType]: e.complete })
  }

  const { mutate } = useCreatePayAsYouGoSubscription()

  const handleSubmit = async () => {
    if (!stripe || !elements || !clientSecret) {
      return
    }

    setIsLoading(true)
    setIsClicked(true)

    const result = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: elements.getElement(CardNumberElement)!,
        billing_details: {
          name: nameOnCard,
        },
      },
    })

    if (
      result.error ||
      !result.setupIntent.payment_method ||
      result.setupIntent.status != 'succeeded'
    ) {
      setInvoice(null)
      setPaymentError &&
        setPaymentError({
          errorCode: result.error?.code,
          declinedCode: result.error?.decline_code,
        })
      setIsLoading(false)
      setIsInvoiceModalActive(true)
      setIsModalActive(false)
      setIsPayNowLoading(false)
      setIsClicked(false)
      return
    }

    mutate(
      {
        paymentCardId: result.setupIntent.payment_method as string,
        promotionCode: promotion ? promotion.promotionCode : undefined,
        credits: payAsYouGoCredits,
        isSaveMyCardChecked,
      },
      {
        onSuccess: ({ data }: any) => {
          setInvoice(data.invoice)
          setCardBillingAddress(data.billingAddress)
          dispatch(setPayAsYouGoCreditsModalActive(false))
          dispatch(toggleIsRefetchBillingHistory())
          dispatch(toggleIsRefetchPaymentCards())
          dispatch(setIsHeaderBarDetailsUpdated(!isHeaderBarDetailsUpdated))
        },
        onError: () => {
          setInvoice(null)
        },
        onSettled: () => {
          setIsLoading(false)
          setIsModalActive(false)
          setIsInvoiceModalActive(true)
          setIsClicked(false)
        },
      },
    )

    return
  }

  return (
    <Modal
      isActive={isModalActive}
      className='!z-[150] py-10 w-full'
      modalProps={{ style: { width: '500px', height: 'auto' } }}
      isSeparator={true}
      isHeaderShow={true}
      headerTitle='Add Card Details'
      headerCloseButtonProps={{
        style: {
          fontSize: '22px',
        },
      }}
      onHeaderCloseButtonClick={() => {
        setIsModalActive(false)
        setIsPayNowLoading(false)
      }}
      isFooterShow={true}
      footerProps={{
        style: {
          flexDirection: 'row-reverse',
        },
      }}
      primaryButtonTitle={'Pay'}
      primaryButtonProps={{
        isLoading: isLoading,
        isDisabled:
          !stripe ||
          !nameOnCard ||
          isClicked ||
          !cardComplete.cardNumber ||
          !cardComplete.cardExpiry ||
          !cardComplete.cardCvc,
        style: {
          marginLeft: '12px',
          paddingLeft: '30px',
          paddingRight: '30px',
        },
      }}
      onClickPrimaryBtn={handleSubmit}
      secondaryButtonTitle='Cancel'
      secondaryButtonProps={{
        theme: 'white',
        textTheme: 'black',
      }}
      onClickSecondaryBtn={() => {
        setIsModalActive(false)
        setIsPayNowLoading(false)
      }}>
      <div className='w-full h-full p-5'>
        <div className='pb-1 text-small'>
          <div className='flex text-Gray-800 font-Medium'>
            <span>Card Number</span>
            {<span className='text-Red-500 pl-[2px]'>*</span>}
          </div>
        </div>

        <CardNumberElement
          onChange={handleCardElementOnChange}
          options={{
            showIcon: true,
            classes: {
              base: 'p-3 border border-solid rounded-md shadow-sm border-Gray-300 text-Gray-800 focus:outline-Cobalt-400',
              focus: 'ring-1 ring-Cobalt-400',
            },
          }}
        />

        <div className='flex items-center justify-around w-full py-5 space-x-5'>
          <div className='flex-col w-full'>
            <div className='flex mb-1 text-Gray-800 font-Medium'>
              <span>Expiry</span>
              <span className='text-Red-500 pl-[2px]'>*</span>
            </div>
            <CardExpiryElement
              onChange={handleCardElementOnChange}
              options={{
                classes: {
                  base: 'p-3 border border-solid rounded-md shadow-sm border-Gray-300 text-Gray-800 w-full bg-white',
                  focus: 'ring-1 ring-Cobalt-400',
                },
              }}
            />
          </div>
          <div className='flex-col w-full'>
            <div className='flex mb-1 text-Gray-800 font-Medium'>
              <span>CVC</span>
              <span className='text-Red-500 pl-[2px]'>*</span>
            </div>
            <CardCvcElement
              onChange={handleCardElementOnChange}
              options={{
                classes: {
                  base: 'p-3 border border-solid rounded-md shadow-sm border-Gray-300 text-Gray-800 focus:outline-Cobalt-400 w-[200px] bg-white',
                  focus: 'ring-1 ring-Cobalt-400',
                },
              }}
            />
          </div>
        </div>

        <TextField
          className='mb-4'
          value={null}
          isRequired
          onChange={(e: any) => setNameOnCard(e.target.value)}
          label='Name on the card'
          placeholder=''
        />

        <Checkbox
          labelText='Save my card for future payments'
          defaultChecked={isSaveMyCardChecked}
          onChange={(e: any) => {
            setIsSaveMyCardChecked(e.target.checked)
          }}
        />
      </div>
    </Modal>
  )
}

// const IframeComponent = () => {
//   return (
//     <div>
//       <h1>Example Iframe</h1>
//       <iframe
//         title='Example Iframe'
//         src='https://hooks.stripe.com/redirect/authenticate/src_1NoQONAqJO3i8Lhle1ln2SEA?client_secret=src_client_secret_LHzjCWN0sVoH15c4j4OXyRv5&source_redirect_slug=test_YWNjdF8xTmNjclRBcUpPM2k4TGhsLF9PYmRmVG1pcFdsWmtlSWNRbElxVWJ2SzA5WXBPVkk401005SSHKWrm'
//         width='600'
//         height='400'
//         allowFullScreen></iframe>
//     </div>
//   )
// }
