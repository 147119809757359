import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { AutoLinkNode } from '@lexical/link'
import { ListItemNode, ListNode } from '@lexical/list'
import {
  AutoLinkPlugin,
  createLinkMatcherWithRegExp,
} from '@lexical/react/LexicalAutoLinkPlugin'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { HeadingNode } from '@lexical/rich-text'
import { TextNode } from 'lexical'
import {
  BeautifulMentionNode,
  BeautifulMentionsPlugin,
} from 'lexical-beautiful-mentions'

import { cn } from 'utils'

import { CustomMenu } from './components/CustomMenu'
import { CustomMenuItem } from './components/CustomMenuItem'
import { ExtentedTextNode } from './nodes/ExtentedTextNode'
import { InitialStatePlugin } from './plugins/InitialStatePlugin'

import './styles.scss'

const URL_REGEX =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/

const EMAIL_REGEX =
  /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/

const WORD_LINK_REGEX = /\b(?:here|click\s?here)\b/gi

const MATCHERS = [
  createLinkMatcherWithRegExp(URL_REGEX, (text) => {
    return text
  }),
  createLinkMatcherWithRegExp(WORD_LINK_REGEX, (text) => {
    return text
  }),
  createLinkMatcherWithRegExp(EMAIL_REGEX, (text) => {
    return `mailto:${text}`
  }),
]

type LexicalEditorPreviewProps = {
  value: string
  theme: any
  className?: string
  isHtml?: boolean
}

export const LexicalEditorPreview = ({
  value,
  className,
  isHtml = false,
  theme,
}: LexicalEditorPreviewProps) => {
  const { templateVariables } = useSelector((state: any) => state.global)

  const mentions = useMemo(
    () => templateVariables?.map((item: any) => item.previewData) ?? [],
    [templateVariables],
  )

  return (
    <LexicalComposer
      initialConfig={{
        editable: false,
        namespace: 'LexicalEditorPreview',
        nodes: [
          BeautifulMentionNode,
          HeadingNode,
          ListNode,
          ListItemNode,
          AutoLinkNode,
          ExtentedTextNode,
          {
            replace: TextNode,
            with: (node: TextNode) =>
              new ExtentedTextNode(node.__text, node.__key),
          },
        ],
        onError: (error: Error) => console.log(error),
        theme,
      }}>
      <div className='editor-container'>
        <div className='editor-inner'>
          <RichTextPlugin
            contentEditable={
              <ContentEditable
                className={cn('w-full focus:outline-none', className)}
              />
            }
            placeholder={<></>}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <HistoryPlugin />
          <ListPlugin />
          <AutoLinkPlugin matchers={MATCHERS} />
          <BeautifulMentionsPlugin
            items={{ '@': mentions }}
            menuComponent={CustomMenu}
            menuItemComponent={CustomMenuItem}
            allowSpaces={false}
            menuItemLimit={15}
          />
          <InitialStatePlugin
            initialState={value}
            mentions={mentions}
            isHtml={isHtml}
          />
        </div>
      </div>
    </LexicalComposer>
  )
}
