import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

export const useWebRefSendReminder = () => {
  const mutate = useMutation((id: string | null) => {
    return postAPI(`/Referee/${id}/Reminder`, {})
  })

  return mutate
}

export default useWebRefSendReminder
