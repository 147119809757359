import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'
import { QueryParams } from 'types/queryParams'

export type ClientDto = {
  id: string
  name: string
  logoUrl: string
  email?: string
  phoneNumber?: string
  countryCode?: string
}

const getClientList = async ({ queryKey }: any) => {
  const { take, skip, search } = queryKey[1] as QueryParams

  let URI = `/clients?take=${take}&skip=${skip}`

  if (search) {
    URI += `&search=${search}`
  }

  const res = await get(URI)
  return res.data
}

export const useGetClientList = (
  queryParams: QueryParams,
  onSuccess: (data: any) => void,
) => {
  return useQuery(['client_list', queryParams], getClientList, {
    enabled: true,
    refetchOnWindowFocus: false,
    onSuccess,
    onError: () => {
      Toast({
        alertHeader: 'Error fetching client list',
        status: 'Error',
      })
    },
  })
}
