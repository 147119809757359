import { createSlice } from '@reduxjs/toolkit'

export type AuthStoreProps = {
  twoFA: {
    email: string
    password: string
  } | null
}

const initialState: AuthStoreProps = {
  twoFA: null,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setTwoFAState(state, { payload }) {
      state.twoFA = payload
    },
  },
})

export const { setTwoFAState } = authSlice.actions

export default authSlice.reducer
