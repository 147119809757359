/* eslint-disable no-debugger */
import { useEffect, useMemo, useState } from 'react'
import { Toast } from '@labourhub/labour-hub-ds'
import { useGetAssessmentReportView } from 'api/assessment/getAssessmentReportView'
import {
  AssessmentResponseListDto,
  useGetAssessmentResponsesList,
} from 'api/assessment/getAssessmentResponsesList'
import CN from 'classnames'

import { UITable } from 'components/common/UITable'
import { AssessmentDownloadModal } from 'components/molecules/AssesmentPreviewModal/DownloadReport/AssessmentDownloadModal'

import { SearchHeader } from '../SearchHeader'

import { AssessmentResponsesListColumn } from './columns'

type AssessmentResultProps = {
  candidateId: string
  candidateDetails: any
}

export const DownloadableAssessmentsReportTable = ({
  candidateId,
  candidateDetails,
}: AssessmentResultProps) => {
  const notify = (props: any) => Toast(props)
  const [markingValue, setMarkingValue] = useState({
    correct: 0,
    partiallyCorrect: 0,
    incorrect: 0,
    notAnswered: 0,
  })
  const [reportViewData, setReportViewData] = useState<any>(null)

  // Table States & Variables
  const [tableData, setTableData] = useState<AssessmentResponseListDto>({
    skip: 0,
    take: 0,
    assessmentResponses: [],
    totalCount: 0,
    message: '-',
  })
  const [paginationVariables, setPaginationVariables] = useState({
    skip: tableData?.skip || 0,
    take: tableData?.take || 20,
    search: '',
    sortColumnName: '',
    sortDirection: '',
  })

  const [pageNumber, setPageNumber] = useState(1)
  const [selectedResponse, setSelectedResponse] = useState<any>()
  const [isDownloadAssessmentReport, setIsDownloadAssessmentReport] =
    useState(false)
  const [isCombineReportModalActive, setIsCombineReportModalActive] =
    useState(false)
  const [searchKeyWord, setSearchKeyWord] = useState<string>('')

  /** on change search */
  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchKeyWord) {
        setPaginationVariables((prev) => ({
          ...prev,
          skip: 0,
          search: searchKeyWord,
        }))
      }
    }, 1000)
    return () => clearTimeout(timer)
  }, [searchKeyWord])

  const {
    mutate: getAssessmentReportViewMutate,
    isLoading: isGetAssessmentReportViewLoading,
  } = useGetAssessmentReportView(
    async (data: any) => {
      const report = data?.assessmentReport[0]
      setReportViewData(report)
      setMarkingValue({
        correct: report?.assessmentAutoMarking?.correct || 0,
        partiallyCorrect: report?.assessmentAutoMarking?.partiallyCorrect || 0,
        incorrect: report?.assessmentAutoMarking?.incorrect || 0,
        notAnswered: report?.assessmentAutoMarking?.notAnswered || 0,
      })
    },
    () => {
      notify({
        alertHeader: 'Something went wrong',
        alertBody: '',
        status: 'Error',
      })
    },
  )

  // Fetch assessment list
  const {
    refetch: refreshAssessmentList,
    isLoading: getAssessmentListIsLoading,
    isFetching: getAssessmentListIsFetching,
  } = useGetAssessmentResponsesList(
    {
      candidateId,
      take: paginationVariables.take,
      skip: paginationVariables.skip,
      search: paginationVariables.search,
      status: 'RESPONSE_RECEVIED',
      emailStatus: '',
      managingConsultant: '',
      sortColumnName: paginationVariables.sortColumnName,
      sortDirection: paginationVariables.sortDirection,
      ManagingConsultantId: '',
    },
    (data: any) => {
      setTableData(data || {})
      setIsDownloadAssessmentReport(false)
    },
    ({ response: { data: errData } }: any) => {
      notify({
        alertHeader: errData.messageTitle || '',
        alertBody: errData.message || 'Error!',
        status: 'Error',
      })
    },
  )

  useEffect(() => {
    refreshAssessmentList()
  }, [paginationVariables])

  const combineReportLoading = useMemo(
    () => isGetAssessmentReportViewLoading,
    [isGetAssessmentReportViewLoading],
  )

  //   useEffect(() => {
  //     // Only trigger if selectedResponse is not null and has assessmentId
  //     if (
  //       selectedResponse &&
  //       selectedResponse.assessmentId &&
  //       isDownlaodAssessmentReport
  //     ) {
  //       const requestData = {
  //         candidateId: candidateId,
  //         assessmentData: [
  //           {
  //             assessmentId: selectedResponse.assessmentId,
  //             assessmentResponseId: selectedResponse.id,
  //           },
  //         ],
  //       }
  //       getAssessmentReportViewMutate(requestData)
  //     }
  //   }, [selectedResponse, isDownlaodAssessmentReport])

  const handleDownloadReport = async () => {
    setReportViewData(null)
    if (selectedResponse) {
      const requestData = {
        candidateId,
        assessmentData: [
          {
            assessmentId: selectedResponse.assessmentId,
            assessmentResponseId: selectedResponse.id,
          },
        ],
      }
      await getAssessmentReportViewMutate(requestData)
    }
  }

  const tableColumns = useMemo(() => {
    return tableData?.assessmentResponses?.length > 0
      ? AssessmentResponsesListColumn(
          setSelectedResponse,
          setIsDownloadAssessmentReport,
        )
      : []
  }, [tableData])

  const handleCloseModals = () => {
    setIsDownloadAssessmentReport(false)
  }
  useEffect(() => {
    const downloadReport = async () => {
      if (isDownloadAssessmentReport) await handleDownloadReport()
    }
    downloadReport()
  }, [isDownloadAssessmentReport])

  return (
    <div>
      <div className='flex flex-col px-4 w-full bg-white border rounded-t-lg outline-none border-Gray-100'>
        <SearchHeader
          candidateDetails={candidateDetails}
          searchKeyWord={searchKeyWord}
          isCombineReportButtonDisabled={
            tableData?.assessmentResponses.length === 0
          }
          isCombineReportModalActive={isCombineReportModalActive}
          setIsCombineReportModalActive={setIsCombineReportModalActive}
          setSearchKeyWord={setSearchKeyWord}
          paginationVariables={paginationVariables}
          setPaginationVariables={setPaginationVariables}
          searchFieldPlaceholder={'Search assessment name'}
        />
      </div>
      <div className='w-full h-[535px] relative'>
        <div
          className={CN(
            'styled-scroll overflow-auto w-full h-[calc(100%-55px)] bg-white',
          )}>
          <UITable
            data={tableData?.assessmentResponses || []}
            isLoading={
              getAssessmentListIsLoading || getAssessmentListIsFetching
            }
            columns={tableColumns}
            allowRowSelection={false}
            isSorted={true}
            isManualSortBy={true}
            hasFooter={true}
            hasCheckBox={true}
            isHeader={true}
            isFilterEnabled={false}
            paginationVariables={paginationVariables}
            setPaginationVariables={setPaginationVariables}
            totalRowCount={tableData?.assessmentResponses.length || 0}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </div>
      </div>
      {isDownloadAssessmentReport && (
        <AssessmentDownloadModal
          markingValue={markingValue}
          reportViewData={reportViewData}
          isModalActive={isDownloadAssessmentReport}
          setIsModalActive={setIsDownloadAssessmentReport}
          handleCloseModals={handleCloseModals}
          candidateDetails={candidateDetails}
          isLoading={combineReportLoading}
          //   handleDownloadReport={handleDownloadReport}
          selectedResponse={selectedResponse}
        />
      )}
    </div>
  )
}
