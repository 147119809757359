// eslint-disable-next-line import/no-extraneous-dependencies
import { Line } from 'react-chartjs-2'

import { cn } from 'utils'

export default function LineChart({
  chartData,
  isReport = false,
}: {
  chartData: any
  isReport?: boolean
}) {
  const time = chartData.labels.length
  return (
    <div
      className={cn('h-[297px] max-h-[297px] mx-auto break-inside-avoid', {
        'w-full': !isReport,
        'w-[751px]': isReport,
      })}>
      <Line
        data={chartData}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            // title: {
            //   display: true,
            //   text: "Typing test stats"
            // },
            tooltip: {
              enabled: true,
              animation: { duration: 250 },
              mode: 'index',
              intersect: false,
              // callbacks: {
              //   afterLabel: function (ti): string {
              //     if (prevTi === ti) return "";
              //     prevTi = ti;
              //     try {
              //       const keypressIndex = Math.round(parseFloat(ti.label)) - 1;
              //       const wordsToHighlight =
              //         TestInput.errorHistory[keypressIndex]?.words;

              //       const unique = [...new Set(wordsToHighlight)];
              //       const firstHighlightWordIndex = unique[0];
              //       const lastHighlightWordIndex =
              //         Arrays.lastElementFromArray(unique);
              //       if (
              //         firstHighlightWordIndex === undefined ||
              //         lastHighlightWordIndex === undefined
              //       ) {
              //         return "";
              //       }
              //       void ResultWordHighlight.highlightWordsInRange(
              //         firstHighlightWordIndex,
              //         lastHighlightWordIndex
              //       );
              //     } catch {}
              //     return "";
              //   },
              // },
              backgroundColor: '#FFFFFF', // Tooltip background color
              titleFont: {
                size: 12, // Title font size
                weight: 'normal', // Title font weight
                family: 'Inter', // Title font family
              },
              titleColor: '#1F2937', // Title font color
              bodyFont: {
                size: 14, // Body font size
                family: 'Inter', // Body font family
              },
              bodyColor: '#1F2937', // Body font color
              // borderColor: 'rgba(255, 255, 255, 0.8)', // Border color
              // borderWidth: 2, // Border width
              padding: 20, // Padding inside tooltip
              cornerRadius: 6, // Rounded corners
              // displayColors: false, // Remove color box next to the label in tooltip
              callbacks: {
                label: function (context) {
                  return `${' '}${context.dataset.label}: ${context.raw}` // Customize tooltip content
                },
              },
            },

            legend: {
              display: true,
              align: 'start',
              labels: {
                boxWidth: 14,
                boxHeight: 14,
                borderRadius: 0,
              },
            },
          },
          scales: {
            x: {
              axis: 'x',
              ticks: {
                autoSkip: true,
                autoSkipPadding: 20,
              },
              display: true,
              title: {
                display: true,
                text: `Time in ${time} Seconds`,
              },
              grid: {
                drawOnChartArea: false, // only want the grid lines for one axis to show up
              },
            },
            y: {
              axis: 'y',
              display: true,
              title: {
                display: true,
                text: 'Words per Minute',
              },
              type: 'linear',
              position: 'left',
              beginAtZero: false,
              min: 0,
              ticks: {
                autoSkip: true,
                autoSkipPadding: 20,
              },
              grid: {
                drawOnChartArea: false, // only want the grid lines for one axis to show up
                drawTicks: true,
              },
            },
            y1: {
              type: 'linear',
              display: true,
              position: 'right',
              title: {
                display: true,
                text: 'Errors',
              },
              beginAtZero: true,
              ticks: {
                precision: 0,
                autoSkip: true,
                autoSkipPadding: 20,
              },

              // grid line settings
              grid: {
                drawOnChartArea: false, // only want the grid lines for one axis to show up
              },
            },
          },
        }}
      />
    </div>
  )
}
