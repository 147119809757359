import addonIconImage from 'assets/images/addon/addonIconImage.svg'
import advanceDifficultyImage from 'assets/images/assessment/difficulty/advance.png'
import beginnerDifficultyImage from 'assets/images/assessment/difficulty/beginner.png'
import intermediateDifficultyImage from 'assets/images/assessment/difficulty/intermediate.png'

export const difficultyImages = {
  Beginner: beginnerDifficultyImage,
  Intermediate: intermediateDifficultyImage,
  Advanced: advanceDifficultyImage,
}

export const addonImages = {
  Addon: addonIconImage,
}
