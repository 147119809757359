import { useLocation, useNavigate } from 'react-router-dom'
import { Modal } from '@labourhub/labour-hub-ds'
import { useDeleteCandidate } from 'api/candidates'
import { ModalProps } from 'types'

type DeleteCandidateEmployeeModalProps = ModalProps & {
  candidateId: string
  candidateDetails: any
}

export const DeleteCandidateEmployeeModal = ({
  isModalActive,
  setIsModalActive,
  candidateId,
  candidateDetails,
}: DeleteCandidateEmployeeModalProps) => {
  const location = useLocation()
  const pathnameSegment = location.pathname.split('/')[1]
  const navigate = useNavigate()

  const { mutateAsync: deleteCandidate, isLoading: isLoadingDeleteCandidate } =
    useDeleteCandidate(candidateId, true)

  return (
    <Modal
      isActive={isModalActive}
      onClickPrimaryBtn={() => {
        deleteCandidate().then(() => {
          setIsModalActive(false)
          navigate(`/${pathnameSegment}`)
        })
      }}
      onClickSecondaryBtn={() => setIsModalActive(false)}
      primaryButtonProps={{
        isLoading: isLoadingDeleteCandidate,
        style: { width: '100%', marginRight: '12px' },
      }}
      secondaryButtonProps={{
        style: { width: '100%', color: '#1F2937' },
      }}
      onOverlayClick={() => {
        setIsModalActive(false)
      }}
      modalProps={{
        style: { width: '466px' },
      }}
      footerProps={{
        style: {
          justifyContent: 'space-between',
          paddingLeft: '23px',
          paddingRight: '23px',
        },
      }}
      isHeaderShow={false}
      primaryButtonTitle='Yes, I’m Sure'
      secondaryButtonTitle='Close'>
      <div className='flex flex-col items-center justify-center p-6'>
        <div className='flex items-center justify-center w-12 h-12 rounded-full bg-Red-100'>
          <i className='ri-delete-bin-6-line text-Red-500 text-heading-3'></i>
        </div>
        {candidateDetails.isEmployee == false ? (
          <>
            <span className='pt-5 text-Gray-900 text-heading-5 font-Medium'>
              Delete Candidate?
            </span>

            <span className='pt-2 text-center text-Gray-500 text-small font-Regular'>
              Are you sure you want to delete candidate?
            </span>
          </>
        ) : (
          <>
            <span className='pt-5 text-Gray-900 text-heading-5 font-Medium'>
              Delete Employee?
            </span>
            <span className='pt-2 text-center text-Gray-500 text-small font-Regular'>
              Are you sure you want to delete employee?
            </span>
          </>
        )}
      </div>
    </Modal>
  )
}
