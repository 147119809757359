/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FC, ReactNode, useEffect } from 'react'
import { Button } from '@labourhub/labour-hub-ds'
import CN from 'classnames'

export interface TableHeaderProps {
  children?: ReactNode
  isFilterEnable?: boolean | undefined
  isSearchEnable?: boolean | undefined
  searchFieldPlaceholder?: string | undefined
  [x: string]: any
}

export const TableHeader: FC<TableHeaderProps> = ({
  children,
  filterActive,
  isFilterEnable,
  isFilterMenuOpen,
  paginationVariables,
  searchFieldPlaceholder,
  searchKeyWord,
  setSearchKeyWord,
  setIsFilterMenuOpen,
  setPaginationVariables,
  isSearchEnable,
}: TableHeaderProps) => {
  /** on change search */
  useEffect(() => {
    const timer = setTimeout(() => {
      searchKeyWord &&
        setPaginationVariables({
          ...paginationVariables,
          skip: 0,
          //take: 10,
          search: searchKeyWord,
        })
    }, 1000)
    return () => clearTimeout(timer)
  }, [searchKeyWord])

  return (
    <>
      <div
        className={CN('flex items-center justify-between pt-[9px] pb-[13px]', {
          'border-b border-Gray-200': isFilterMenuOpen,
        })}>
        {isSearchEnable && (
          <div className='pl-[13px] w-2/3 relative'>
            <input
              value={searchKeyWord}
              autoComplete='off'
              onKeyUp={(e: any) => {
                if (e.keyCode == 13) {
                  setPaginationVariables({
                    ...paginationVariables,
                    search: searchKeyWord,
                  })
                }

                if (searchKeyWord.length === 0) {
                  setPaginationVariables({
                    ...paginationVariables,
                    search: searchKeyWord,
                  })
                }
              }}
              onChange={(e: any) => {
                setSearchKeyWord(e.target.value ?? '')
              }}
              type='text'
              className='w-full font-Regular py-[6px] pl-10 pr-[30px] text-Gray-600 active:outline-Gray-50 focus:outline-Gray-50 rounded'
              placeholder={searchFieldPlaceholder}
            />

            <i className='absolute left-[26px] top-[5px] ri-search-line text-Gray-400'></i>

            {searchKeyWord && (
              <i
                className='absolute right-[10px] top-[6px] ri-close-line text-Gray-400'
                onClick={() => {
                  setSearchKeyWord('')
                  setPaginationVariables({
                    ...paginationVariables,
                    search: '',
                  })
                }}></i>
            )}
          </div>
        )}

        <div className='flex items-center'>
          <>
            {isFilterEnable && (
              <div className='relative'>
                <Button
                  icon={<i className='ri-filter-3-line' />}
                  isIconOnly
                  onClick={() => {
                    setIsFilterMenuOpen(!isFilterMenuOpen)
                  }}
                  textTheme='blue'
                  theme='white'
                />

                {filterActive && (
                  <div className='w-[6px] h-[6px] bg-Cobalt-600 rounded-full absolute top-[-2px] right-[-2px]'></div>
                )}
              </div>
            )}

            {/* header optional buttons as children */}
            {children}
          </>
        </div>
      </div>
    </>
  )
}

TableHeader.defaultProps = {
  children: undefined,
  isFilterEnable: false,
  searchFieldPlaceholder: undefined,
  isSearchEnable: true,
}

export default TableHeader
