import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

type UpdateRefereeCountProps = {
  candidateId: string
  existingCount: number
  additionalCount: number
}

export const useUpdateRefereeCount = () => {
  const mutate = useMutation(
    ({
      candidateId,
      existingCount,
      additionalCount,
    }: UpdateRefereeCountProps) => {
      return putAPI(`/candidates/${candidateId}/refereeCount`, {
        additionalCount,
        existingCount,
      })
    },
  )

  return mutate
}
