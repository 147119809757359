/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import ReactTooltip from 'react-tooltip'
import { Badge } from '@labourhub/labour-hub-ds'
import moment from 'moment'

import { MoreMenuButton } from 'components/atoms'
import { timeToAESTWithSeconds } from 'utils'

export const SurveyResponsesListColumn = (
  setSelectedResponse: any,
  setIsReminderModalActive: any,
  setShowSurveyResponseDetailsModal: any,
  setIsDownlaodSurveyReport: any,
) => {
  const COLUMNS = [
    {
      Header: 'SURVEY NAME',
      accessor: 'surveyName',
      forSorting: 'SurveyName',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-[200px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        return (
          <div
            className='truncate'
            data-tip={original?.surveyName}
            data-for={original?.surveyId}>
            <ReactTooltip
              place='right'
              id={original?.surveyId}
              effect='solid'
              textColor='#FFFFFF'
              backgroundColor='#4B5563'
              delayShow={800}
            />
            {original?.surveyName}
          </div>
        )
      },
    },
    {
      Header: 'SENT BY',
      accessor: 'sentBy',
      forSorting: 'sentBy',
      isSortable: false,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-[130px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        return <div>{original?.sentBy}</div>
      },
    },
    {
      Header: 'SENT ON',
      accessor: 'sentOn',
      forSorting: 'SentOn',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-[140px] pl-6',
      headerClassName: 'flex items-center justify-start',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        const createdDateStringWithSeconds = timeToAESTWithSeconds(
          original?.sentOn,
        )
        const createdDateString = moment(original?.sentOn).format('DD/MM/yyyy')
        return (
          <div
            data-tip={createdDateStringWithSeconds}
            data-for={original?.surveyId}>
            <ReactTooltip
              place='right'
              id={original?.surveyId}
              effect='solid'
              textColor='#FFFFFF'
              backgroundColor='#4B5563'
              delayShow={800}
            />
            {createdDateString}
          </div>
        )
      },
    },
    {
      Header: 'COMPLETED ON',
      accessor: 'completedOn',
      forSorting: 'CompletedOn',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-[150px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        const updatedDateStringWithSecond =
          original?.completedOn !== null
            ? timeToAESTWithSeconds(original?.completedOn)
            : '-'
        const updatedDateString =
          original?.completedOn !== null
            ? moment(original?.completedOn).format('DD/MM/yyyy')
            : '-'
        return (
          <div
            data-tip={updatedDateStringWithSecond}
            data-for={original?.surveyId}>
            <ReactTooltip
              place='right'
              id={original?.surveyId}
              effect='solid'
              textColor='#FFFFFF'
              backgroundColor='#4B5563'
              delayShow={800}
            />
            {updatedDateString}
          </div>
        )
      },
    },
    {
      Header: 'STATUS',
      accessor: 'status',
      forSorting: 'Status',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-[210px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        const badgeTheme =
          original?.status === 'Response Received'
            ? 'green'
            : original?.status === 'Awaiting for Response'
            ? 'yellow'
            : 'red'
        return (
          <div>
            <Badge size='large' theme={badgeTheme} isDot={true}>
              <div className='text-extra-small font-Medium leading-3'>
                {original?.status}
              </div>
            </Badge>
          </div>
        )
      },
    },
    {
      Header: '',
      accessor: 'action',
      forSorting: '',
      isSortable: false,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[50px]',
      headerClassName: 'flex items-center justify-center',
      cellClassName: 'flex items-center justify-center',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        return (
          <div className='relative'>
            {original.status === 'Request Declined' ? null : (
              <MoreMenuButton
                className='ml-2'
                actionButtons={
                  original.status === 'Awaiting for Response'
                    ? [
                        {
                          iconClass: 'ri-send-plane-line',
                          onClick: () => {
                            setSelectedResponse(original)
                            setIsReminderModalActive(true)
                          },
                          title: 'Send Reminder',
                          status: 'normal',
                        },
                      ]
                    : original.status === 'Response Received'
                    ? [
                        {
                          iconClass: 'ri-eye-line',
                          onClick: () => {
                            setSelectedResponse(original)
                            setShowSurveyResponseDetailsModal(true)
                          },
                          title: 'Preview Report',
                          status: 'normal',
                        },
                        {
                          iconClass: 'ri-download-2-line',
                          onClick: () => {
                            setSelectedResponse(original)
                            setIsDownlaodSurveyReport(true)
                          },
                          title: 'Download Result',
                          status: 'normal',
                        },
                      ]
                    : []
                }
              />
            )}
          </div>
        )
      },
    },
  ]
  return COLUMNS
}
