import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

export type UpdateAssessmentCommentRequest = {
  AssessmentId: string
  CandidateId: string
  assessmentResponseId: string
  jsonSchema: string
  candidateStage: string
}
export const useUpdateAssessmentComment = () => {
  const mutate = useMutation(
    ({
      AssessmentId,
      CandidateId,
      assessmentResponseId,
      jsonSchema,
      candidateStage,
    }: UpdateAssessmentCommentRequest) => {
      return putAPI(
        `/assessment/candidates/${CandidateId}/assessment/${AssessmentId}/update-comment`,
        {
          assessmentResponseId,
          jsonSchema,
          candidateStage,
        },
      )
    },
  )

  return mutate
}
