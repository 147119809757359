import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Toast, ToggleDetailSection } from '@labourhub/labour-hub-ds'
import { useUpdateOnboardingStepper } from 'api/onboarding'
import popoverCareerForm from 'assets/images/popover_career_form.svg'
import popoverStaffForm from 'assets/images/popover_staff_form.svg'
import { companySettingsOne } from 'features/onboarding/data'
import {
  setCompanySettingsOne,
  setCurrentStep,
} from 'features/onboarding/store'
import { getOnboardingStore } from 'features/onboarding/store/selectors'

export const RefHubSettingsSectionOne = () => {
  const dispatch = useDispatch()
  const {
    currentStep,
    tenantId,
    companySettingsOne: companySettingsOneObj,
  } = useSelector(getOnboardingStore)

  const [isStaffRequest, setIsStaffRequest] = useState(false)
  const [isCareerInterest, setIsCareerInterest] = useState(false)
  // const [isSMSActivation, setIsSMSActivation] = useState(false)

  const onNextStepClick = () => {
    companyOnboarding()
  }

  /** APi call for the onboarding stepper */
  const {
    mutate: companyOnboardingMutate,
    isLoading: companyOnboardingIsLoading,
  } = useUpdateOnboardingStepper()

  /** Process the onboarding stepper */
  const companyOnboarding = () => {
    companyOnboardingMutate(
      {
        companySettingsOne: {
          id: companySettingsOne,
          isStaffRequest: isStaffRequest,
          isCareerInterest: isCareerInterest,
          // isSmsActivationRequired: isSMSActivation,
        },
      },

      {
        onSuccess: () => {
          dispatch(
            setCompanySettingsOne({
              id: companySettingsOne,
              isStaffRequest: isStaffRequest,
              isCareerInterest: isCareerInterest,
              tenantId: tenantId,
              status: 'Completed',
            }),
          )
          dispatch(setCurrentStep(currentStep + 1))
        },
        onError: ({ response: { data: errData } }: any) => {
          // this use temporary until api works
          Toast({
            alertHeader: errData?.messageTitle
              ? errData?.messageTitle
              : 'Error!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  useEffect(() => {
    if (companySettingsOneObj) {
      setIsStaffRequest(companySettingsOneObj?.isStaffRequest)
      setIsCareerInterest(companySettingsOneObj?.isCareerInterest)
      // setIsSMSActivation(companySettingsOneObj?.isSmsActivationRequired)
    }
  }, [companySettingsOneObj])

  return (
    <div className='flex flex-col items-center justify-center w-7/12'>
      <div className='w-full mt-5 text-left text-heading-2 font-Bold text-Gray-800'>
        Ref Hub Settings
      </div>

      <div className='w-full mt-6 text-left text-small font-Medium text-Gray-800'>
        In Ref Hub, you have several default options to choose from for
        conducting reference checks and police checks. Let's get these set up.
      </div>

      <div className='flex flex-col justify-start w-full'>
        <span className='mt-6 text-Gray-800 font-SemiBold'>
          Lead Generation
        </span>

        <ToggleDetailSection
          id='staff-request-form'
          label='Attach staff request form'
          description='This will be in the thank you screen for the referee'
          hasTooltip={true}
          tooltipImage={popoverStaffForm}
          value={isStaffRequest}
          onClick={(e: any) => setIsStaffRequest(e.target.checked)}
        />

        <ToggleDetailSection
          id='career-interest-form'
          label='Attach career interest form'
          description='This will be in the thank you screen for the referee'
          hasTooltip={true}
          tooltipImage={popoverCareerForm}
          value={isCareerInterest}
          onClick={(e: any) => setIsCareerInterest(e.target.checked)}
        />

        {/* <span className='mt-6 text-Gray-800 font-SemiBold'>
          Request SMS Activation
        </span>

        <ToggleDetailSection
          id='request-sms-activation '
          label='Request SMS Activation '
          description='Send referee details and reference requests via SMS. Please note this feature is only available on paid plans and activation may take up to 12 hours.'
          value={isSMSActivation}
          onClick={(e: any) => setIsSMSActivation(e.target.checked)}
        /> */}
      </div>

      {/* next button */}
      <Button
        isBlock
        isLoading={companyOnboardingIsLoading}
        onClick={onNextStepClick}
        className='mt-[163px] next-btn'>
        Next
      </Button>
    </div>
  )
}
