import { useEffect, useMemo, useRef, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import { Button, Radio, SimpleSelect, Toast } from '@labourhub/labour-hub-ds'
import {
  ReportAssessmentStatsCountDto,
  useGetAssessmentCountByCandidate,
} from 'api/assessment/getAssessmentCountByCandidate'
import { useGetAssessmentReportView } from 'api/assessment/getAssessmentReportView'
import {
  AssessmentResponseDto,
  useGetAssessmentResponsesList,
} from 'api/assessment/getAssessmentResponsesList'
import { CandidateProfileBasicDetailsType } from 'api/candidates'
import { useGetClientLogo, useGetClientSelectOptions } from 'api/clients'
import { assessmentReportPreviewData } from 'features/candidates/data/assessmentReportPreviewData'
import { useDynamicFieldMapping } from 'hooks'
import { ModalProps, SelectOptionDto } from 'types'

import { Accordion, PageLoader } from 'components/atoms'
import { Column } from 'components/atoms/Column'
import { Row } from 'components/atoms/Row'
import { Modal, ModalFooter } from 'components/molecules'
import {
  CombineReportPreviewModal,
  CombineReportPrintPreview,
} from 'components/ui/reports'

import { AssessmentSelectionList } from './AssessmentSelectionList'

type CombineReportDownloadModalProps = ModalProps & {
  candidateDetails: CandidateProfileBasicDetailsType
}

export const CombineReportDownloadModal = ({
  isModalActive,
  setIsModalActive,
  candidateDetails,
}: CombineReportDownloadModalProps) => {
  const notify = (props: any) => Toast(props)

  const [searchParams] = useSearchParams()
  const candidateId: string = searchParams.get('candidate_id') || ''

  const [clientOptions, setClientOptions] = useState<SelectOptionDto[]>([])
  const [assessmentResponse, setAssessmentResponse] = useState<
    AssessmentResponseDto[]
  >([])
  const [logoUrl, setLogoUrl] = useState<string | undefined>(undefined)
  const [previewData, setPreviewData] = useState<
    assessmentReportPreviewData | undefined
  >(undefined)
  const [selectedAssessment, setSelectedAssessment] = useState<
    AssessmentResponseDto[]
  >([])
  const [reportViewData, setReportViewData] = useState<any[]>([])
  const [assessmentStats, setAssessmentStats] =
    useState<ReportAssessmentStatsCountDto | null>(null)
  const [selectedClientOption, setSelectedClientOption] =
    useState<SelectOptionDto | null>(null)

  // Fetch Assessment Count Stats
  const {
    refetch: getAssessmentCountByCandidate,
    isLoading: isGetAssessmentCountLoading,
  } = useGetAssessmentCountByCandidate({ candidateId }, setAssessmentStats)

  const { dynamicFieldMapping, changeTextSchema } = useDynamicFieldMapping({
    candidateId: candidateDetails.candidateId,
  })
  const [isPrinting, setIsPrinting] = useState(false)
  const componentRef = useRef<any>(null)
  const promiseResolveRef = useRef<any>(null)

  // Fetch assessment list
  const {
    refetch: refreshAssessmentList,
    isLoading: getAssessmentListIsLoading,
    isFetching: getAssessmentListIsFetching,
  } = useGetAssessmentResponsesList(
    {
      candidateId,
      take: 10000,
      skip: 0,
      status: 'RESPONSE_RECEVIED',
    },
    (data: any) => setAssessmentResponse(data.assessmentResponses),
    ({ response: { data: errData } }: any) =>
      notify({
        alertHeader: errData.messageTitle || '',
        alertBody: errData.message || 'Error!',
        status: 'Error',
      }),
  )

  const loading = useMemo(
    () => getAssessmentListIsLoading || getAssessmentListIsFetching,
    [getAssessmentListIsLoading, getAssessmentListIsFetching],
  )

  useEffect(() => {
    if (isModalActive && candidateId) refreshAssessmentList()
  }, [isModalActive, candidateId])

  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      promiseResolveRef.current()
    }
  }, [isPrinting])

  const pageStyle = ` 
    @media print {
      @page {
        size: a4 !important;
        margin: 0 !important;
        padding: 0 !important;
      }

      body {
        margin: 0 !important;
        padding: 0 !important;
      }
    }
  `

  const handlePrint = useReactToPrint({
    pageStyle,
    content: () => componentRef.current,
    onBeforeGetContent: () =>
      new Promise((resolve) => {
        promiseResolveRef.current = resolve
        setIsPrinting(true)
      }),
    onBeforePrint: () => setIsModalActive(false),
    onAfterPrint: () => {
      promiseResolveRef.current = null
      setIsPrinting(false)
    },
  })

  const [isOpenPreviewModal, setIsOpenPreviewModal] = useState(false)

  const [renderKeys, setRenderKeys] = useState<any>({
    reportType: 0,
    reportSetting: 0,
  })

  const [formBody, setFormBody] = useState({
    reportSetting: 'companyBrandOnly',
  })

  const { refetch: refreshClientSelectOptions } = useGetClientSelectOptions(
    '',
    (options) => setClientOptions(options),
  )

  useGetClientLogo(selectedClientOption?.value ?? '', (data) =>
    setLogoUrl(data.logoUrl),
  )

  useEffect(() => {
    if (isModalActive) {
      refreshClientSelectOptions()
      setRenderKeys((prev) => ({
        ...prev,
        cancellation: Math.random(),
        reason: Math.random(),
      }))
    }
  }, [isModalActive])

  const closeModal = () => {
    setFormBody({ reportSetting: 'companyBrandOnly' })
    setRenderKeys((prev) => ({
      ...prev,
      reportType: Math.random(),
      reportSetting: Math.random(),
    }))
    setIsModalActive(false)
  }

  const {
    mutate: getAssessmentReportViewMutate,
    isLoading: isGetAssessmentReportViewLoading,
  } = useGetAssessmentReportView(
    async (data: any) => {
      setReportViewData(data.assessmentReport)
    },
    () => {
      notify({
        alertHeader: 'Something went wrong',
        alertBody: '',
        status: 'Error',
      })
    },
  )

  const combineReportLoading = useMemo(
    () => isGetAssessmentCountLoading || isGetAssessmentReportViewLoading,
    [isGetAssessmentCountLoading, isGetAssessmentReportViewLoading],
  )

  const renderClientDetail = () => (
    <Column>
      <Row className='w-full space-x-4'>
        <SimpleSelect
          value={{
            label: selectedClientOption?.label,
            value: selectedClientOption?.value,
          }}
          onChange={(selectedItem: any) => {
            setSelectedClientOption(selectedItem)
            setLogoUrl(undefined)
          }}
          label='Client List'
          options={clientOptions}
          placeholder='Select a Client'
          className='min-w-[225px]'
        />
        {logoUrl && (
          <img
            src={logoUrl}
            alt=''
            className='rounded h-[60px] w-[200px] mt-1'
          />
        )}
      </Row>
      <Row className='w-full space-x-4'>
        <Link to='/settings?tab=4' className='mt-2 font-Medium text-Blue-700'>
          Add New Client
        </Link>
      </Row>
    </Column>
  )

  const resetClientOption = () => {
    setSelectedClientOption(null)
    setLogoUrl(undefined)
  }

  const handleDownload = async () => {
    if (selectedAssessment.length === 0) {
      notify({
        alertBody: 'Please select at least one assessment',
        status: 'Warning',
      })
      return
    }

    if (['bothBrands', 'clientBrandOnly'].includes(formBody.reportSetting)) {
      if (clientOptions.length === 0) {
        notify({
          alertBody:
            'Clients details not available. Please add a new client or select a different option.',
          status: 'Warning',
        })
        return
      }

      if (!logoUrl) {
        notify({
          alertBody: 'Please select a client logo',
          status: 'Warning',
        })
        return
      }
    }

    setIsOpenPreviewModal(true)
    setPreviewData({
      clientId: selectedClientOption?.value,
      clientLogoUrl: logoUrl,
      hasClientLogo: formBody.reportSetting !== 'companyBrandOnly',
      hasCompanyLogo: formBody.reportSetting !== 'clientBrandOnly',
    })

    if (selectedAssessment.length > 0) {
      const requestData = {
        candidateId,
        assessmentData: selectedAssessment.map(({ assessmentId, id }) => ({
          assessmentId,
          assessmentResponseId: id,
        })),
      }
      await getAssessmentReportViewMutate(requestData)
      await getAssessmentCountByCandidate()
    }
  }

  useEffect(() => {
    setPreviewData({
      clientId: selectedClientOption?.value,
      clientLogoUrl: logoUrl,
      hasClientLogo: formBody.reportSetting !== 'companyBrandOnly',
      hasCompanyLogo: formBody.reportSetting !== 'clientBrandOnly',
    })
  }, [formBody, selectedClientOption, logoUrl])

  return (
    <>
      <Modal
        headerTitle='Combine Reports'
        isFooterShow={true}
        isActive={isModalActive}
        className='!z-[150]'
        onHeaderCloseButtonClick={closeModal}
        onOverlayClick={closeModal}
        modalProps={{
          style: { width: '600px', maxHeight: '95vh' },
        }}>
        <div className='w-full p-6 overflow-y-auto'>
          <div>
            <p className='text-small text-Gray-800'>
              Please select reports you need to combine:
            </p>
            {loading ? (
              <PageLoader size='xxs' />
            ) : (
              <Column>
                <AssessmentSelectionList
                  assessmentResponse={assessmentResponse}
                  setSelectedAssessment={setSelectedAssessment}
                />
              </Column>
            )}
          </div>

          <div className='w-full my-4 border border-Gray-200' />

          <div className='flex w-full p-4 mb-2 border rounded bg-Blue-50 text-Blue-800 border-Blue-300'>
            <i className='mr-2 ri-information-fill text-Blue-600'> </i>
            <div className='flex flex-col font-Regular text-Blue-700'>
              You can customize your report's branding in the Settings Panel{' '}
              <Link to='/settings?tab=6' className='underline font-SemiBold'>
                Click Here
              </Link>
            </div>
          </div>

          <div className='w-full mt-4'>
            <Accordion
              heading='Customize this report for a client'
              initialState={false}
              description={`Add your client's branding to reports when sending feedback externally`}>
              <div className='mt-5 space-y-4' key={renderKeys.reportSetting}>
                <Radio
                  id='bothBrands'
                  labelText='Include My Company Logo and Client Logo'
                  name='reportSetting'
                  onChange={(e: any) => {
                    resetClientOption()
                    setFormBody((prev) => ({
                      ...prev,
                      reportSetting: e.target.id,
                    }))
                  }}
                />
                {formBody.reportSetting === 'bothBrands' &&
                  renderClientDetail()}
                <Radio
                  id='clientBrandOnly'
                  labelText='Include Client Logo only (This removes your Company Logo in the report)'
                  name='reportSetting'
                  onChange={(e: any) => {
                    resetClientOption()
                    setFormBody((prev) => ({
                      ...prev,
                      reportSetting: e.target.id,
                    }))
                  }}
                />
                {formBody.reportSetting === 'clientBrandOnly' &&
                  renderClientDetail()}
              </div>
            </Accordion>
          </div>
        </div>

        <ModalFooter>
          <div className='flex justify-end flex-1'>
            <Button onClick={closeModal} textTheme='blue' theme='white'>
              Close
            </Button>
            <Button onClick={handleDownload} className='ml-2' theme='cobalt'>
              Download
            </Button>
          </div>
        </ModalFooter>
      </Modal>

      {assessmentStats && (
        <CombineReportPrintPreview
          stats={assessmentStats}
          ref={componentRef}
          reportViewData={reportViewData}
          candidateDetails={candidateDetails}
          previewData={previewData}
          dynamicFieldMapping={dynamicFieldMapping}
          changeTextSchema={changeTextSchema}
        />
      )}
      {isOpenPreviewModal && assessmentStats && (
        <CombineReportPreviewModal
          stats={assessmentStats}
          reportViewData={reportViewData}
          isModalActive={isOpenPreviewModal}
          setIsModalActive={setIsOpenPreviewModal}
          candidateDetails={candidateDetails}
          previewData={previewData}
          dynamicFieldMapping={dynamicFieldMapping}
          changeTextSchema={changeTextSchema}
          handlePrint={handlePrint}
          resetClientOption={resetClientOption}
          isLoading={combineReportLoading}
        />
      )}
    </>
  )
}
