/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Modal, Toast } from '@labourhub/labour-hub-ds'
import { useGetAssessmentsList } from 'api/assessment'
import useDeleteAssessment from 'api/assessment/deleteAssessment'
import CN from 'classnames'
import { RootState } from 'store/reducers'

import { EmptyTableType, UITable } from 'components/common/UITable'

import { SelectTemplateModal } from '../AssessmentSearchHeader/SelectTemplateModal'

import { AssessmentPreviewModal } from './AssessmentPreviewModal'
import { AssessmentListColumn } from './columns'

export const AssessmentTable = () => {
  const notify = (props: any) => Toast(props)

  /** table states & variables */
  const [tableData, setTableData] = useState<any>({})
  const [pageNumber, setPageNumber] = useState(1)

  const { assessmentFilterVariables, isAssessmentRefreshList } = useSelector(
    (state: RootState) => state.questionnaire,
  )

  const [paginationVariables, setPaginationVariables] = useState<any>({
    skip: tableData.skip || 0,
    take: tableData.take || 20,
    search: '',
    sortColumnName: '',
    sortDirection: '',
  })

  const [selectedTemplate, setSelectedTemplate] = useState<any>()

  const [showDeleteAssessmentModal, setShowDeleteAssessmentModal] =
    useState(false)

  const [isShowPreviewModal, setIsShowPreviewModal] = useState(false)

  const [isSelectTemplateModelOpen, setIsSelectTemplateModelOpen] =
    useState(false)

  const { clickValue } = useSelector((state: any) => state.layout)

  useEffect(() => {
    const toBeUpdateTableData = {
      ...tableData,
      assessmentDetails: tableData?.assessmentDetails?.map((item: any) => {
        return {
          ...item,
          showMore: false,
        }
      }),
    }
    setTableData(toBeUpdateTableData)
  }, [clickValue])

  const onSuccess = (data: any) => {
    const toBeUpdateTableData = {
      ...data,
      documentDetails: data?.documentDetails?.map((item: any) => {
        return {
          ...item,
          showMore: false,
        }
      }),
    }

    setTableData(toBeUpdateTableData || {})
  }

  const onError = ({ response: { data: errData } }: any) => {
    notify({
      alertHeader: errData.messageTitle ? errData.messageTitle : '',
      alertBody: errData.message ? errData.message : 'Error!',
      status: 'Error',
    })
  }

  const {
    refetch: refetchAssessmentTable,
    isLoading: getAssessmentTableIsLoading,
    isFetching: getAssessmentTableIsFetching,
  } = useGetAssessmentsList(
    {
      take: paginationVariables.take,
      skip: paginationVariables.skip,
      status: assessmentFilterVariables?.status || '',
      category: assessmentFilterVariables?.type || '',
      search: assessmentFilterVariables?.searchKeyword || '',
      sortColumnName: paginationVariables.sortColumnName,
      sortDirection: paginationVariables.sortDirection,
    },
    onSuccess,
    onError,
  )

  useEffect(() => {
    refetchAssessmentTable()
  }, [isAssessmentRefreshList, assessmentFilterVariables])

  const {
    mutate: deleteAssessmentMutate,
    isLoading: deleteAssessmentIsLoading,
  } = useDeleteAssessment()

  async function deleteAssessment(Id: string) {
    deleteAssessmentMutate(
      { Id },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData.messageTitle
              ? successData.messageTitle
              : 'Assessment template deleted successfully',
            alertBody: '',
            status: 'Success',
          })
          refetchAssessmentTable()
          setShowDeleteAssessmentModal(false)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: errData?.messageTitle
              ? errData?.messageTitle
              : 'Error!',
            alertBody: errData?.message ? errData?.message : 'Error!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** Assessment Table Column Handling */
  const tableColumns = useMemo(() => {
    if (tableData?.assessment && tableData?.assessment?.length > 0) {
      const columns: any = AssessmentListColumn(
        tableData,
        setTableData,
        setIsShowPreviewModal,
        setSelectedTemplate,
        setShowDeleteAssessmentModal,
      )
      return columns
    }
    return []
  }, [tableData])

  return (
    <div className='w-full h-full questionnaires-list'>
      <div className='w-full h-[calc(90vh-80px)] relative'>
        <div
          className={CN(
            'styled-scroll overflow-auto w-full h-[calc(100%-52px)] bg-white rounded-t-lg border border-Gray-200',
          )}>
          <UITable
            data={tableData?.assessment || []}
            isLoading={
              getAssessmentTableIsLoading || getAssessmentTableIsFetching
            }
            emptyTableType={EmptyTableType.Questionnaire}
            emptyBtnOnClick={() => {
              setIsSelectTemplateModelOpen(true)
            }}
            columns={tableColumns}
            allowRowSelection={false}
            isSorted={true}
            isManualSortBy={true}
            hasFooter={true}
            hasCheckBox={true}
            isHeader={true}
            isFilterEnabled={false}
            paginationVariables={paginationVariables}
            setPaginationVariables={setPaginationVariables}
            totalRowCount={tableData?.totalCount || 0}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </div>
      </div>

      {isShowPreviewModal && (
        <AssessmentPreviewModal
          isModalActive={isShowPreviewModal}
          setIsModalActive={setIsShowPreviewModal}
          templateId={selectedTemplate?.value}
          templateName={selectedTemplate?.label}
          isDraft={selectedTemplate?.isDraft}
        />
      )}

      <Modal
        isActive={showDeleteAssessmentModal}
        onClickPrimaryBtn={() => {
          deleteAssessment(selectedTemplate.value)
        }}
        onClickSecondaryBtn={() => {
          setShowDeleteAssessmentModal(false)
        }}
        primaryButtonProps={{
          style: { width: '100%', marginRight: '12px' },
          isLoading: deleteAssessmentIsLoading,
        }}
        secondaryButtonProps={{
          style: { width: '100%', color: '#1F2937' },
        }}
        onOverlayClick={() => {
          setShowDeleteAssessmentModal(false)
        }}
        modalProps={{
          style: { width: '466px' },
        }}
        footerProps={{
          style: {
            justifyContent: 'space-between',
            paddingLeft: '23px',
            paddingRight: '23px',
          },
        }}
        isHeaderShow={false}
        primaryButtonTitle='Yes, I’m Sure'
        secondaryButtonTitle='Cancel'>
        <div className='flex flex-col items-center justify-center p-6'>
          <div className='flex items-center justify-center w-12 h-12 rounded-full bg-Red-100'>
            <i className='ri-delete-bin-6-line text-Red-500 text-heading-3'></i>
          </div>

          <span className='pt-5 text-center text-Gray-900 text-heading-5 font-Medium'>
            {`Delete ${selectedTemplate?.label}?`}
          </span>

          <span className='pt-2 text-center text-Gray-500 text-small font-Regular'>
            {`Are you sure you want to proceed?`}
          </span>
        </div>
      </Modal>

      <SelectTemplateModal
        isModalActive={isSelectTemplateModelOpen}
        setIsModalActive={setIsSelectTemplateModelOpen}
      />
    </div>
  )
}
