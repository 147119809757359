import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

export type GenerateAssessmenTemplateRequest = {
  assessmentName: string
  noOfQuestions: number
  assessmentPurpose: string
  difficultyLevel: string
  industry: string
}

export const useGenerateAssessmenTemplate = () => {
  const mutate = useMutation((request: GenerateAssessmenTemplateRequest) => {
    return postAPI('/AI/VertexAI/GenerateAssessment', { request })
  })
  return mutate
}
