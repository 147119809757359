import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

export type PaymentCardDto = {
  id: string
  cardType: string
  expMonth: number
  expYear: number
  last4Digits: string
  isDefaultCard: boolean
  billingAddress?: CardBillingAddressDto
}

export type CardBillingAddressDto = {
  firstName: string
  lastName: string
  lineOne: string
  lineTwo?: string
  suburb?: string
  state: string
  stateCode: string
  country: string
  countryCode: string
  postCode: string
}

export const useListPaymentCards = (
  onSuccess: (cards: PaymentCardDto[]) => void,
) => {
  return useQuery(
    ['payments_cards'],
    async () => {
      const res = await get(`/payments/cards`)
      return res.data.cards
    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
      retry: 0,
      onSuccess,
    },
  )
}
