import { useEffect, useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { SetupIntentDto, useGetSetupIntent } from 'features/payments'

import { PageLoader } from 'components/atoms'
import { stripePubKey } from 'config/framework.config'

import { CheckoutForm } from './CheckoutForm'

export const CardDetailsSection = ({ subscriptionPlanType, packagePeriod }) => {
  const [stripePromise, setStripePromise] = useState<any>(null)

  const [setupIntent, setSetupIntent] = useState<SetupIntentDto | undefined>(
    undefined,
  )

  useEffect(() => {
    if (stripePubKey) {
      setStripePromise(loadStripe(stripePubKey))
    }
  }, [stripePubKey])

  useGetSetupIntent(true, (data) => setSetupIntent(data))

  if (setupIntent && stripePromise) {
    return (
      <Elements
        stripe={stripePromise}
        options={{
          clientSecret: setupIntent.clientSecret,
        }}>
        <CheckoutForm
          clientSecret={setupIntent.clientSecret}
          subscriptionPlanType={subscriptionPlanType}
          subscriptionType={packagePeriod}
        />
      </Elements>
    )
  }

  return <PageLoader />
}
