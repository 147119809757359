import { useEffect } from 'react'
import { Modal } from '@labourhub/labour-hub-ds'
import { ModalProps } from 'types'

type RemoveImageModalProps = ModalProps & {
  setRemoveBgImage: any
}

export const BackgroundImageRemoveModal = ({
  isModalActive,
  setIsModalActive,
  setRemoveBgImage,
}: RemoveImageModalProps) => {
  useEffect(() => {
    if (isModalActive) setRemoveBgImage(false)
  }, [isModalActive])

  const onRemoveClick = () => {
    setRemoveBgImage(true)
    setIsModalActive(false)
  }

  return (
    <Modal
      isActive={isModalActive}
      onClickPrimaryBtn={() => onRemoveClick()}
      className='!z-[150]'
      onClickSecondaryBtn={() => setIsModalActive(false)}
      primaryButtonProps={{
        style: { width: '100%', marginRight: '12px' },
      }}
      secondaryButtonProps={{
        style: { width: '100%', color: '#1F2937' },
      }}
      modalProps={{
        style: { width: '480px' },
      }}
      footerProps={{
        style: {
          justifyContent: 'space-between',
          paddingLeft: '23px',
          paddingRight: '23px',
        },
      }}
      isHeaderShow={false}
      primaryButtonTitle="Yes, I'm Sure"
      secondaryButtonTitle='Close'>
      <div className='flex flex-col items-center justify-center p-6'>
        <div className='flex items-center justify-center w-12 h-12 rounded-full bg-Red-100'>
          <i className='ri-delete-bin-6-line text-Red-500 text-heading-3'></i>
        </div>

        <span className='pt-5 text-center text-Gray-900 text-heading-5 font-Medium'>
          Remove Background Image?
        </span>

        <span className='pt-2 text-center text-Gray-500 text-small font-Regular'>
          Are you sure you want to proceed?
        </span>
      </div>
    </Modal>
  )
}
