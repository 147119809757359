import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@labourhub/labour-hub-ds'
import creditRemainingBg from 'assets/images/creditRemainingBg.svg'
import { setPayAsYouGoCreditsModalActive } from 'features/payments'
import { getSubscriptionStore } from 'features/subscriptions'

import { Card } from 'components/atoms/Card'

import { RemainingCreditsBadge } from './RemainingCreditsBadge'

export const QuotaCreditsCard = () => {
  const dispatch = useDispatch()

  const { fixedQuotas, payAsYouGoQuotas, remainingCredit } =
    useSelector(getSubscriptionStore)

  return (
    <Card>
      <div className='flex items-center justify-center w-full h-[220px] border-b border-Gray-200'>
        <img src={creditRemainingBg} alt='' className='w-[170px]'></img>
      </div>

      <div className='flex items-center justify-between pt-5'>
        <div className='text-Gray-800 font-Medium text-heading-5'>
          Remaining Credits:{' '}
          <RemainingCreditsBadge
            totalQuota={
              fixedQuotas.referenceCheck.purchasedQuota +
              payAsYouGoQuotas.ReferenceCheck.purchasedQuota
            }
            remainingCredit={remainingCredit || 0}
          />
        </div>
        <Button
          theme='white'
          textTheme='blue'
          className='h-8 text-extra-small'
          onClick={() => dispatch(setPayAsYouGoCreditsModalActive(true))}>
          Buy More
        </Button>
      </div>

      {/* <div className='mt-3'>
        <PoliceCheckLayout>
          <CreditListItem
            quota={payAsYouGoQuotas.PoliceCheck.remainingQuota}
            icon='ri-police-car-line'
            title='Police Check Credits'
            exprireDate={fixedSubscription?.deactivatedDate}
          />
          <CreditListItem
            quota={payAsYouGoQuotas.RightToWorkCheck.remainingQuota}
            icon='ri-suitcase-line'
            title='Right To Work Credits'
            exprireDate={fixedSubscription?.deactivatedDate}
          />
          <CreditListItem
            quota={payAsYouGoQuotas.VolunteerCheck.remainingQuota}
            icon='ri-hand-heart-line'
            title='Volunteer Check Credits'
            exprireDate={fixedSubscription?.deactivatedDate}
          />
        </PoliceCheckLayout>

        <CreditListItem
          quota={payAsYouGoQuotas.ReferenceCheck.remainingQuota}
          icon='ri-ball-pen-line'
          title='Single Purchase Credits'
          exprireDate={fixedSubscription?.deactivatedDate}
        />
        <CreditListItem
          quota={fixedQuotas.referenceCheck.remainingQuota}
          icon='ri-ball-pen-line'
          title='Subscription Bonus Credits'
          exprireDate={fixedSubscription?.endDate}
        />

        {isAssessmentEnabled && (
          <CreditListItem
            quota={payAsYouGoQuotas.ReferenceCheck.remainingQuota}
            icon='ri-file-list-2-line'
            title='Assessment Credits'
            exprireDate={fixedSubscription?.deactivatedDate}
          />
        )}
      </div> */}
    </Card>
  )
}
