import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

export type AssessmentLibraryDto = {
  id: string
  role: string
  imageUrl: string
  timeToComplete: string
  questionCount: string
  isPublished: boolean
  isDescription: boolean
  description: string
  isSentToAssessmentResponse: boolean
}

type AssessmentLibraryQueryParams = {
  search: string
  type: string
  cId?: string
}

const getAssessmentLibraryList = async ({ queryKey }: any) => {
  const { search, type, cId } = queryKey[1] as AssessmentLibraryQueryParams

  let URI = `/assessment/library?type=${type}`
  if (search) {
    URI += `&search=${search}`
  }
  if (cId) {
    URI += `&CId=${cId}`
  }

  const res = await get(URI)
  return res.data.assessmentLibrary
}

export const useGetAssessmentLibraryList = (
  queryParams: AssessmentLibraryQueryParams,
  onSuccess: (data: AssessmentLibraryDto[]) => void,
) => {
  return useQuery(
    ['assessment_library_list', queryParams],
    getAssessmentLibraryList,
    {
      enabled: true,
      refetchOnWindowFocus: false,
      onSuccess,
      onError: () => {
        Toast({
          alertHeader: 'Error fetching assessment library list',
          status: 'Error',
        })
      },
    },
  )
}
