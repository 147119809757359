import { lazyImport } from 'utils/lazyImport'

const { DashboardPage } = lazyImport(
  () => import('../pages/DashboardPage'),
  'DashboardPage',
)

const { ReportingDashboard } = lazyImport(
  () => import('../pages/ReportingDashboard'),
  'ReportingDashboard',
)

export { DashboardPage, ReportingDashboard }
