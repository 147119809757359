import { FC } from 'react'
import CN from 'classnames'

export interface LoaderProps {
  className?: string | undefined
  isCustomStyles?: boolean | undefined
  appearance?: 'default' | 'green' | 'yellow' | 'red' | 'Cobalt' | undefined
  [x: string]: any
}

export const Loader: FC<LoaderProps> = ({
  className,
  isCustomStyles,
  appearance,
}: LoaderProps) => {
  /** loader styles */
  const LoaderClasses = !isCustomStyles
    ? CN('h-2 w-2 rounded-sm', className, {
        'Loader bg-white': appearance === 'default',
        'Loader bg-Green-700': appearance === 'green',
        'Loader bg-Yellow-700': appearance === 'yellow',
        'Loader bg-Red-700': appearance === 'red',
        'Loader bg-Cobalt-700': appearance === 'Cobalt',
      })
    : className

  return (
    <div className='flex items-center justify-center z-20'>
      <div className={`${LoaderClasses} mr-1 animate-pulse200`}></div>
      <div className={`${LoaderClasses} mr-1 animate-pulse400`}></div>
      <div className={`${LoaderClasses} animate-pulse300`}></div>
    </div>
  )
}

Loader.defaultProps = {
  className: undefined,
  appearance: 'default',
}

export default Loader
