import { AssessmentQuestion } from '../types'

export const assessmentQuestionTypes: AssessmentQuestion[] = [
  {
    id: 'heading',
    icon: 'ri-heading',
    name: 'Heading',
    isRequired: false,
    type: 'heading',
    title: 'Heading',
    meta: [],
    group: 'text',
  },
  {
    id: 'paragraph',
    icon: 'ri-text',
    name: 'Paragraph',
    isRequired: false,
    type: 'paragraph',
    title: 'Paragraph',
    meta: [],
    group: 'text',
  },
  {
    id: 'short-answer',
    icon: 'ri-text-spacing',
    name: 'Short Answer',
    isRequired: true,
    type: 'text',
    title: 'Question Title',
    meta: [],
    group: 'text',
  },
  {
    id: 'long-answer',
    icon: 'ri-text-wrap',
    name: 'Long Answer',
    isRequired: true,
    type: 'text_area',
    title: 'Question Title',
    meta: [],
    group: 'text',
  },
  //   {
  //     id: 'rating',
  //     icon: 'ri-star-line',
  //     name: 'Rating',
  //     isRequired: true,
  //     type: 'rating',
  //     title: 'Question Title',
  //     meta: ['1', '2', '3', '4', '5'],
  //     group: 'selection',
  //   },
  {
    id: 'multi-choice',
    icon: 'ri-checkbox-multiple-line',
    name: 'Multi Choice',
    isRequired: true,
    type: 'checkbox',
    title: 'Question Title',
    meta: ['Option 1'],
    group: 'selection',
  },
  {
    id: 'yes-no',
    icon: 'ri-radio-button-line',
    name: 'Yes/No',
    isRequired: true,
    type: 'radio',
    title: 'Question Title',
    meta: ['Yes', 'No'],
    group: 'selection',
  },
  {
    id: 'select-box',
    icon: 'ri-arrow-down-s-line',
    name: 'Select Box',
    isRequired: true,
    type: 'select',
    title: 'Question Title',
    meta: ['Option 1'],
    group: 'selection',
  },
  //   {
  //     id: 'detailed-yes',
  //     icon: 'ri-checkbox-circle-line',
  //     name: 'Detailed Yes',
  //     isRequired: true,
  //     type: 'radio_pos_detail',
  //     title: 'Question Title',
  //     meta: ['Yes', 'No'],
  //     group: 'selection',
  //   },
  //   {
  //     id: 'detailed-no',
  //     icon: 'ri-close-circle-line',
  //     name: 'Detailed No',
  //     isRequired: true,
  //     type: 'radio_neg_detail',
  //     title: 'Question Title',
  //     meta: ['Yes', 'No'],
  //     group: 'selection',
  //   },
]
