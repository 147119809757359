import { useMutation } from 'react-query'
import { deleteAPI } from 'framework/api/http'

interface DeleteCandidateAvatarInterface {
  Id?: string
}

export const useDeleteCandidateAvatar = () => {
  const mutate = useMutation(({ Id }: DeleteCandidateAvatarInterface) => {
    return deleteAPI(`/Candidate/${Id}/Avatar`)
  })

  return mutate
}

export default useDeleteCandidateAvatar
