/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import CN from 'classnames'
import moment from 'moment'
import { setDateRange } from 'store/reducers/dashboard/dashboardSlice'
import { setMenu as acSetMenu } from 'store/reducers/side-menu/mainSideMenuReducer'

interface MenuItemProps {
  menuItems: any
  item: any | []
  index: number
  setIsSettingMenuActive: any
  isExpand?: boolean
}

/** this component for handle main side bar items actions and sub menu items action */
const MenuItem: FC<MenuItemProps> = ({
  item,
  index,
  menuItems,
  setIsSettingMenuActive,
  isExpand,
}) => {
  const { id, title, icon, isActive } = item

  const navigate = useNavigate()
  const dispatch = useDispatch()

  /** handle main menu items click */
  const handleMainMenuItemClick = (index: number) => {
    const toBeUpdatedMenuItems = menuItems.map((menuItem: any) => {
      if (menuItem.id === index) {
        navigate(menuItem.link)
        return { ...menuItem, isActive: true }
      }
      return { ...menuItem, isActive: false }
    })
    dispatch(acSetMenu(toBeUpdatedMenuItems))
    setIsSettingMenuActive(false)
  }

  return (
    <div
      key={index}
      onClick={(e) => {
        e.stopPropagation()
        handleMainMenuItemClick(id)
        dispatch(
          setDateRange([
            moment().startOf('month').format('YYYY-MM-DD').toString(),
            moment().format('YYYY-MM-DD').toString(),
          ]),
        )
      }}
      className='mt-3 cursor-pointer'>
      <div
        className={CN('h-10 flex items-center rounded-lg  text-heading-4', {
          'bg-Cobalt-600 text-white': isActive,
          'bg-white text-Gray-600 hover:bg-[#F3F3F3]': !isActive,
          'w-full p-2 justify-start': isExpand,
          'w-10 justify-center': !isExpand,
        })}
        data-tip={title}
        data-for={title}>
        {!isExpand && (
          <ReactTooltip
            id={title}
            place='right'
            effect='solid'
            textColor='#FFFFFF'
            backgroundColor='#4B5563'
            className='rounded-full text-extra-small'
          />
        )}
        <i className={icon} />
        {isExpand && (
          <div className='pl-2 text-[16px] leading-[18px] font-Medium '>
            {title}
          </div>
        )}
      </div>
    </div>
  )
}

export default MenuItem
