import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

export type SetupIntentDto = {
  id: string
  clientSecret: string
}

export const useGetSetupIntent = (
  isEnabled: boolean,
  onSuccess: (data: SetupIntentDto) => void,
) => {
  return useQuery(
    ['payments_setupintents'],
    async () => {
      const res = await get(`/payments/setupintents`)
      return res.data.setupIntent
    },
    {
      enabled: isEnabled,
      refetchOnWindowFocus: false,
      onSuccess,
      retry: 1,
      onError: () => {
        Toast({
          alertHeader: 'Error',
          alertBody: 'Unable to fetch payment details',
          status: 'Error',
        })
      },
    },
  )
}
