import { FC } from 'react'

export const PhoneRefIcon: FC = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <rect width='40' height='40' rx='20' fill='#075BA9' />
      <g clip-path='url(#clip0_8411_58169)'>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M27.8792 14.9023L28.212 15.0397C28.4458 15.1363 28.6723 15.2202 28.9005 15.3047C29.7083 15.6041 30.5374 15.9113 31.7871 16.813L31.9811 16.953L31.9941 17.1886C32.0832 18.8127 31.2222 23.4483 27.24 27.3439C23.2578 31.2395 18.5192 32.0819 16.859 31.9947L16.6182 31.982L16.475 31.7922C15.5533 30.5696 15.2393 29.7586 14.9333 28.9683C14.8468 28.7451 14.761 28.5235 14.6624 28.2948L14.5425 28.0169L14.7378 27.7836C15.2409 27.1827 16.1216 26.5929 16.9716 26.1308C17.8288 25.6648 18.7359 25.2853 19.3424 25.1356L19.6305 25.0645L21.0753 26.5373C21.7843 26.317 22.9891 25.8582 24.3552 24.5218C25.7241 23.1827 26.161 22.0296 26.3783 21.3409L24.9031 19.8828L24.9875 19.6001C25.4751 17.9678 26.6381 16.2327 27.6357 15.1633L27.8792 14.9023ZM28.1571 16.0976C27.3532 17.0255 26.4933 18.3538 26.0585 19.6033L27.5325 21.0602L27.4445 21.3451C27.4354 21.3746 27.4259 21.4058 27.4159 21.4385C27.2 22.1459 26.7621 23.5815 25.078 25.2289C23.3599 26.9097 21.8349 27.3591 21.1222 27.5692C21.105 27.5743 21.0883 27.5792 21.0721 27.584L20.7722 27.6727L19.3136 26.1857C18.8235 26.3439 18.1445 26.6372 17.4681 27.0049C16.772 27.3834 16.136 27.81 15.7287 28.2041C15.7916 28.359 15.8491 28.5072 15.9053 28.652C16.1851 29.3729 16.433 30.0114 17.1511 31.0005C18.7552 30.9843 22.9444 30.132 26.5172 26.6368C30.09 23.1417 30.9613 19.0436 30.9779 17.4744C29.9667 16.7718 29.3141 16.5294 28.5771 16.2556C28.4409 16.205 28.3019 16.1534 28.1571 16.0976Z'
          fill='#F2F9FF'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M22.8 19.5H17.1777V18.5H22.8V19.5Z'
          fill='#F2F9FF'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M22.8 21.5H17.1777V20.5H22.8V21.5Z'
          fill='#F2F9FF'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M20.7555 23.5H17.1777V22.5H20.7555V23.5Z'
          fill='#F2F9FF'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M22.8 11H17.1777V10H22.8V11Z'
          fill='#F2F9FF'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M22.8 13H17.1777V12H22.8V13Z'
          fill='#F2F9FF'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M20.7555 15H17.1777V14H20.7555V15Z'
          fill='#F2F9FF'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M13.7498 22.8536L10.6831 19.8536L11.4059 19.1465L14.4726 22.1465L13.7498 22.8536Z'
          fill='#F2F9FF'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M10.6831 22.1465L13.7498 19.1465L14.4726 19.8536L11.4059 22.8536L10.6831 22.1465Z'
          fill='#F2F9FF'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M9 17.5H16.1556V24.5H9V17.5ZM10.0222 18.5V23.5H15.1333V18.5H10.0222Z'
          fill='#F2F9FF'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M12.0666 13.0986L13.6858 10.7227L14.5363 11.2774L12.0666 14.9014L10.6191 12.7774L11.4697 12.2227L12.0666 13.0986Z'
          fill='#F2F9FF'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M9 9H16.1556V16H9V9ZM10.0222 10V15H15.1333V10H10.0222Z'
          fill='#F2F9FF'
        />
      </g>
      <defs>
        <clipPath id='clip0_8411_58169'>
          <rect
            width='23'
            height='23'
            fill='white'
            transform='translate(9 9)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default PhoneRefIcon
