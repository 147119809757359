import { TextField } from '@labourhub/labour-hub-ds'

type StartCountSectionProps = {
  startCount: string
  setStarCount: (meta: string[]) => void
}

export const StartCountSection = ({
  startCount,
  setStarCount,
}: StartCountSectionProps) => {
  const setStarCountValue = (value: string) => {
    const count = parseInt(value, 10)
    const meta = Array.from({ length: count }, (_, index) => String(index + 1))
    setStarCount(meta)
  }

  return (
    <div className='flex flex-col gap-y-5'>
      <div className='w-full px-4 py-2 rounded-lg font-Regular text-Cobalt-800 bg-Cobalt-50'>
        <span>Star Count</span>
      </div>
      <div className='flex flex-col w-full gap-y-3'>
        <TextField
          value={startCount}
          placeholder='Max star count 10'
          onChange={(e: any) => {
            const value = e.target.value
            if (value >= 1 || value <= 10) setStarCountValue(value)
          }}
          min={3}
          max={10}
          type='number'
        />
      </div>
    </div>
  )
}
