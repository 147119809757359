import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

export type MappedVariableRequest = {
  candidateId: string
  refereeId?: string
}

const getMappedVariables = async ({ queryKey }: any) => {
  const { candidateId, refereeId } = queryKey[1] as MappedVariableRequest
  let URI = `/templates/variables/mapped?candidateId=${candidateId}`
  if (refereeId) URI += `&refereeId=${refereeId}`
  const res = await get(URI)
  return res.data?.variables
}

export const useGetMappedVariables = (
  request: MappedVariableRequest,
  onSuccess: (variables: Record<string, string>) => void,
) => {
  return useQuery(['mapped_variables', request], getMappedVariables, {
    enabled: request.candidateId != '' && request.refereeId != '',
    refetchOnWindowFocus: false,
    onSuccess,
    onError: () => {
      Toast({
        alertHeader: 'Error fetching mapped variables',
        status: 'Error',
      })
    },
  })
}
