import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

export type SmsProviderProps = {
  tenantId: string
  name: string
  description: string
  userName: string
  password: string
  phoneNumber: string
}

export const useAddSmsProvider = () => {
  const mutate = useMutation((provider: SmsProviderProps) => {
    return postAPI(`/SMS/Providers`, provider)
  })

  return mutate
}
