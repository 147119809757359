import { FC } from 'react'

export const CandidateIcon: FC = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <rect width='40' height='40' rx='20' fill='#027EAB' />
      <g clip-path='url(#clip0_9763_61750)'>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M23.9375 30.1875V24.5625H25.0625V30.1875H23.9375Z'
          fill='#EDFAFF'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M21.3894 31.3979L25.8894 28.5854L26.4856 29.5394L22.25 32.1867V32.9999H21.125V31.8749C21.125 31.681 21.2249 31.5007 21.3894 31.3979Z'
          fill='#EDFAFF'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M14.9375 30.1875V24.5625H16.0625V30.1875H14.9375Z'
          fill='#EDFAFF'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M26.0228 25.8523L27.7103 27.5398C27.7173 27.5468 27.724 27.5539 27.7306 27.5612C27.751 27.5839 27.7734 27.6082 27.7971 27.634C27.9171 27.7644 28.0728 27.9336 28.1883 28.1233C28.3465 28.3834 28.4375 28.682 28.4375 29.0625V31.3125H27.3125V29.0625C27.3125 28.8806 27.2742 28.7854 27.2271 28.708C27.1744 28.6213 27.1104 28.5511 26.9975 28.4274C26.9696 28.3968 26.9388 28.363 26.9044 28.3249L25.2273 26.6478L26.0228 25.8523Z'
          fill='#EDFAFF'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M13.0956 28.3249L14.7727 26.6478L13.9773 25.8523L12.2898 27.5398C12.2828 27.5468 12.276 27.5539 12.2694 27.5612C12.249 27.5839 12.2267 27.6082 12.2029 27.634C12.0829 27.7644 11.9272 27.9336 11.8118 28.1233C11.6536 28.3834 11.5625 28.682 11.5625 29.0625V31.3125H12.6875V29.0625C12.6875 28.8806 12.7258 28.7854 12.7729 28.708C12.8257 28.6213 12.8897 28.5511 13.0026 28.4274C13.0304 28.3968 13.0612 28.363 13.0956 28.3249Z'
          fill='#EDFAFF'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M17.75 32.1867L13.5144 29.5394L14.1107 28.5854L18.6107 31.3979C18.7751 31.5007 18.875 31.681 18.875 31.8749V32.9999H17.75V32.1867Z'
          fill='#EDFAFF'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M14.375 17.25V20.625C14.375 20.9589 14.4154 21.4854 14.5027 21.9955C14.5945 22.5326 14.7213 22.9437 14.8423 23.1244C14.9085 23.2231 15.0651 23.4039 15.2956 23.6434C15.5168 23.8733 15.7795 24.1303 16.0337 24.3727C16.2873 24.6145 16.5294 24.8388 16.7082 25.0029C16.7976 25.0848 16.871 25.1516 16.9219 25.1977L16.9806 25.2508L17.0004 25.2686L16.625 25.6875C16.2493 26.1062 16.2493 26.1061 16.2493 26.1061L16.2425 26.1L16.2274 26.0864L16.1666 26.0314C16.1142 25.984 16.039 25.9156 15.9478 25.8319C15.7654 25.6647 15.5178 25.4351 15.2574 25.1869C14.9976 24.9392 14.722 24.6698 14.485 24.4235C14.2572 24.1868 14.0356 23.9415 13.9077 23.7506C13.6406 23.3519 13.4861 22.7252 13.3938 22.1851C13.2968 21.618 13.25 21.0279 13.25 20.625V17.25H14.375ZM23.7575 26.1001C22.8414 26.9742 21.6777 27.375 20.5625 27.375H19.4375C18.3223 27.375 17.1586 26.9742 16.2425 26.1M23.7575 26.1001L23.7726 26.0864L23.8334 26.0314C23.8858 25.984 23.961 25.9156 24.0522 25.8319C24.2346 25.6647 24.4822 25.4351 24.7426 25.1869C25.0024 24.9392 25.278 24.6698 25.515 24.4235C25.7428 24.1868 25.9644 23.9415 26.0923 23.7506C26.3594 23.3519 26.5139 22.7252 26.6062 22.1851C26.7032 21.618 26.75 21.0279 26.75 20.625V17.25H25.625V20.625C25.625 20.9589 25.5846 21.4854 25.4973 21.9955C25.4055 22.5326 25.2787 22.9437 25.1577 23.1244C25.0915 23.2231 24.9349 23.4039 24.7044 23.6434C24.4832 23.8733 24.2205 24.1303 23.9663 24.3727C23.7127 24.6145 23.4706 24.8388 23.2918 25.0029C23.2024 25.0848 23.129 25.1516 23.0781 25.1977L23.0194 25.2508L22.9996 25.2686'
          fill='#EDFAFF'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M17.75 6.5625C17.75 6.25184 18.0018 6 18.3125 6H21.6875C21.9982 6 22.25 6.25184 22.25 6.5625V11.625H21.125V7.125H18.875V11.625H17.75V6.5625Z'
          fill='#EDFAFF'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M11 16.125H29V17.25H11V16.125Z'
          fill='#EDFAFF'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M23.0904 6.63983C23.2625 6.53884 23.4753 6.53663 23.6495 6.634C25.9041 7.8943 27.875 9.60555 27.875 12.75V13.5274L28.6891 13.9344C28.8796 14.0297 29 14.2244 29 14.4375V15.5625H27.875V14.7851L27.0609 14.3781C26.8704 14.2828 26.75 14.0881 26.75 13.875V12.75C26.75 10.5693 25.6193 9.21566 23.9375 8.11917V9.9375H22.8125V7.125C22.8125 6.92543 22.9182 6.74081 23.0904 6.63983Z'
          fill='#EDFAFF'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M16.9122 6.64136C17.0829 6.74271 17.1875 6.92651 17.1875 7.125V9.9375H16.0625V8.10849C15.3358 8.57403 14.722 9.08471 14.2555 9.68085C13.6308 10.4793 13.25 11.4566 13.25 12.75V13.875C13.25 14.0881 13.1296 14.2828 12.9391 14.3781L12.125 14.7851V15.5625H11V14.4375C11 14.2244 11.1204 14.0297 11.3109 13.9344L12.125 13.5274V12.75C12.125 11.2084 12.588 9.98634 13.3695 8.98758C14.1405 8.00227 15.2007 7.26062 16.3558 6.63109C16.5301 6.5361 16.7416 6.54 16.9122 6.64136Z'
          fill='#EDFAFF'
        />
      </g>
      <defs>
        <clipPath id='clip0_9763_61750'>
          <rect
            width='18'
            height='27'
            fill='white'
            transform='translate(11 6)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CandidateIcon
