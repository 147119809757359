const Progressbar = ({ values, totalMarks }) => {
  const total =
    values?.correct +
    values?.partiallyCorrect +
    values?.incorrect +
    values?.notAnswered

  // Calculate percentages and widths
  const calculatePercentage = (count: number) =>
    total === 0 ? 0 : (count / total) * 100

  const correctPercentage = calculatePercentage(values?.correct)
  const partiallyCorrectPercentage = calculatePercentage(
    values?.partiallyCorrect,
  )
  const incorrectPercentage = calculatePercentage(values?.incorrect)
  const notAnsweredPercentage = calculatePercentage(values?.notAnswered)

  return (
    <div className='w-full mx-auto py-3'>
      {/* Progress Bar Section */}
      <div className='relative flex items-center'>
        <div className='relative flex-1 h-5 rounded-full overflow-hidden bg-gray-200'>
          <div className='flex h-full'>
            <div
              className='relative h-full flex items-center justify-center text-[#757575] font-bold'
              style={{
                width: `${correctPercentage}%`,
                backgroundColor: '#8FEEA9',
              }}>
              {values?.correct > 0 && <span>{values?.correct}</span>}
            </div>
            <div
              className='relative h-full flex items-center justify-center text-[#757575] font-bold'
              style={{
                width: `${partiallyCorrectPercentage}%`,
                backgroundColor: '#CEE5FA',
              }}>
              {values?.partiallyCorrect > 0 && (
                <span>{values?.partiallyCorrect}</span>
              )}
            </div>
            <div
              className='relative h-full flex items-center justify-center text-[#757575] font-bold'
              style={{
                width: `${incorrectPercentage}%`,
                backgroundColor: '#FDCDCA',
              }}>
              {values?.incorrect > 0 && <span>{values?.incorrect}</span>}
            </div>
            <div
              className='relative h-full flex items-center justify-center text-[#757575] font-bold'
              style={{
                width: `${notAnsweredPercentage}%`,
                backgroundColor: '#D1D5DB',
              }}>
              {values?.notAnswered > 0 && <span>{values?.notAnswered}</span>}
            </div>
          </div>
        </div>
        <div className='ml-4 text-gray-700 font-bold text-heading-2'>
          <span>
            {/* {`${
                total > 0
                  ? Math.round(
                      ((values?.correct + values?.partiallyCorrect) / total) *
                        100,
                    )
                  : '0'
              }%`} */}
            {`${totalMarks ? Math.round(totalMarks) : 0}%`}
          </span>
        </div>
      </div>

      {/* Color Indicators Section */}
      <div className='flex gap-4 mt-4'>
        <div className='flex  items-center gap-2'>
          <div className='w-2 h-2  bg-[#8FEEA9]'></div>
          <div className='text-sm'>Correct</div>
        </div>
        <div className='flex  items-center gap-2'>
          <div className='w-2 h-2  bg-[#CEE2FA]'></div>
          <div className='text-sm'>Partially Correct</div>
        </div>
        <div className='flex  items-center gap-2'>
          <div className='w-2 h-2  bg-[#FDCDCA]'></div>
          <div className='text-sm'>Incorrect</div>
        </div>
        <div className='flex  items-center gap-2'>
          <div className='w-2 h-2  bg-[#D1D5DB]'></div>
          <div className='text-sm'>Not Answered</div>
        </div>
      </div>
    </div>
  )
}
export default Progressbar
