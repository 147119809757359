import { forwardRef } from 'react'
import CN from 'classnames'

type RadioProps = {
  id?: string | undefined
  defaultChecked?: boolean
  onRadioClick?: () => void
  onChange?: any | undefined
  name?: string | undefined
  isSelected: boolean
  icon: string | undefined
  title: string | undefined
  subTitle?: string
}

export const RefereeTypeCard = forwardRef<HTMLInputElement, RadioProps>(
  (
    {
      id,
      onRadioClick,
      defaultChecked,
      onChange,
      name,
      isSelected = false,
      icon,
      title,
      subTitle,
      ...restProps
    }: RadioProps,
    ref,
  ) => {
    const RefereeTypeCardClassName = CN(
      'flex border-[2px] cursor-pointer p-[10px] rounded-lg',
      {
        'bg-Cobalt-50 border-Cobalt-500': isSelected,
        'bg-white border-Gray-300': !isSelected,
      },
    )

    const RadioClassName = CN(
      'form-check-input appearance-none rounded-full h-4 w-4  bg-white border border-Gray-300 checked:bg-white checked:border-Cobalt-600 checked:border-[5px] hover:bg-Gray-300 active:bg-Gray-400 focus:outline-none focus:ring focus:ring-Cobalt-300 transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer',
    )

    return (
      <label htmlFor={id} className={RefereeTypeCardClassName}>
        <div className='flex flex-col w-full'>
          <div className='flex items-center justify-start'>
            <i
              className={CN('text-[40px] leading-none font-semibold', icon, {
                'text-Cobalt-600 ': isSelected,
                'text-Gray-400 ': !isSelected,
              })}
            />
            <div className='font-Medium pl-[10px] text-base'>{title}</div>
          </div>
          {subTitle && (
            <div className='text-extra-small font-Medium pt-[6px]'>
              {subTitle}
            </div>
          )}
        </div>
        <div className='right-side'>
          <input
            id={id}
            onChange={onChange}
            ref={ref}
            type='radio'
            onClick={onRadioClick}
            className={RadioClassName}
            defaultChecked={defaultChecked}
            name={name}
            {...restProps}
          />
        </div>
      </label>
    )
  },
)
