import { useEffect, useState } from 'react'
import { Toggle } from '@labourhub/labour-hub-ds'
import { CardBillingAddressDto } from 'api/payments'
import { defaultBillingAddress } from 'features/payments/data'

import { PageLoader } from 'components/atoms'
import { BillingAddressForm } from 'components/molecules'

type CardBillingAddressProps = {
  setIsValidBillingAddress: any
  billingAddress?: CardBillingAddressDto
  cardAddress?: CardBillingAddressDto
  setCardAddress: any
}

export const CardBillingAddress = ({
  setIsValidBillingAddress,
  billingAddress,
  cardAddress,
  setCardAddress,
}: CardBillingAddressProps) => {
  const [useDifferentAddress, setUseDifferentAddress] = useState<boolean>(false)

  useEffect(() => {
    if (billingAddress) setCardAddress(billingAddress)
    else setCardAddress(defaultBillingAddress)
  }, [billingAddress])

  if (!billingAddress && cardAddress) {
    return (
      <BillingAddressForm
        setIsValidBillingAddress={setIsValidBillingAddress}
        billingAddress={cardAddress}
        setBillingAddress={setCardAddress}
        isCheckoutModal={true}
      />
    )
  }

  return cardAddress ? (
    <div>
      {useDifferentAddress ? (
        <BillingAddressForm
          setIsValidBillingAddress={setIsValidBillingAddress}
          billingAddress={cardAddress}
          setBillingAddress={setCardAddress}
          isCheckoutModal={true}
        />
      ) : (
        <div className='flex flex-col p-6 mt-6 bg-white border rounded-lg border-Gray-200'>
          <div className='text-[#414244] font-SemiBold'>Billing Address</div>

          <div className='w-4/5 mt-3 text-extra-small font-Regular text-Gray-500'>
            {cardAddress.firstName} {cardAddress.lastName},
            <br />
            {cardAddress.lineOne},
            <br />
            {cardAddress?.lineTwo && (
              <>
                {cardAddress?.lineTwo},
                <br />
              </>
            )}
            {cardAddress?.suburb && (
              <>
                {cardAddress?.suburb},
                <br />
              </>
            )}
            {cardAddress.state},
            <br />
            {cardAddress?.postCode},
            <br />
            {cardAddress?.country}.
            <br />
          </div>

          <div className='flex items-center justify-start w-full mt-3'>
            <Toggle
              className=''
              setValue={useDifferentAddress}
              onToggleClick={(e: any) => {
                setUseDifferentAddress(e.target.checked)
                setCardAddress(defaultBillingAddress)
              }}
            />

            <span className='flex ml-2 text-Gray-800 text-small font-Medium'>
              Use a different billing address
            </span>
          </div>
        </div>
      )}
    </div>
  ) : (
    <PageLoader />
  )
}
