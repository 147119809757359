import { Column } from 'components/atoms/Column'
import { H4 } from 'components/atoms/Headings'

import { CandidatesTable } from './CandidatesTable'

type RecentCandidatesProps = {
  isAddCandidateDrawerOpen?: boolean
  setIsAddCandidateDrawerOpen?: any
}

export const RecentCandidates = ({
  isAddCandidateDrawerOpen,
  setIsAddCandidateDrawerOpen,
}: RecentCandidatesProps) => {
  return (
    <Column className=''>
      <H4 title='Recent Candidates' className='my-3 font-Medium' />
      <CandidatesTable
        drawerTitle='Start new reference check'
        drawerSubTitle='(Add new candidate)'
        showFilter={false}
        showAddButton={false}
        pageSize={10}
        isAddCandidateDrawerOpen={isAddCandidateDrawerOpen}
        setIsAddCandidateDrawerOpen={setIsAddCandidateDrawerOpen}
      />
    </Column>
  )
}
