import CN from 'classnames'

type MoreButtonProps = {
  className?: string | undefined
  isCustomStyles?: boolean
  onClick: any
}

export const MoreButton = ({
  className,
  onClick,
  isCustomStyles,
}: MoreButtonProps) => {
  /** button main styles*/
  const buttonClassName = !isCustomStyles
    ? CN(
        'flex items-center justify-center font-Medium p-[10px] rounded-md focus:border focus:border-Gray-300 active:border active:border-Gray-300 hover:text-Gray-500 active:bg-Gray-50 focus:bg-white focus:outline focus:outline-2 focus:outline-offset-1 focus:outline-[#348AD9]',
        className,
      )
    : className

  return (
    <button className={buttonClassName} onClick={onClick}>
      <div className='flex justify-center items-center h-[16.67px] w-[16.67px]'>
        <i className='ri-more-2-fill font-SemiBold text-Gray-700' />
      </div>
    </button>
  )
}
