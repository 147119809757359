import { useEffect } from 'react'

export const useIdleTimeout = (
  timeoutMs: number,
  onTimeoutAction: () => void,
) => {
  useEffect(() => {
    let idleTimeout: NodeJS.Timeout | null = null

    const handleIdle = () => {
      idleTimeout = setTimeout(() => {
        onTimeoutAction() // Call the callback function
      }, timeoutMs)
    }

    const resetIdleTimeout = () => {
      if (idleTimeout) {
        clearTimeout(idleTimeout)
      }
      handleIdle()
    }

    // Set up event listeners for user activity
    const events = [
      'keydown',
      'mousedown',
      'mousemove',
      'MSPointerDown',
      'MSPointerMove',
      'scroll',
      'touchmove',
      'touchstart',
      'visibilitychange',
      'wheel',
    ]
    events.forEach((event) => {
      document.addEventListener(event, resetIdleTimeout)
    })

    // Initialize the idle timeout
    handleIdle()

    // Clear the timeout and remove event listeners on unmount
    return () => {
      if (idleTimeout) {
        clearTimeout(idleTimeout)
      }
      events.forEach((event) => {
        document.removeEventListener(event, resetIdleTimeout)
      })
    }
  }, [timeoutMs, onTimeoutAction])
}
