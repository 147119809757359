import { Button, Toast } from '@labourhub/labour-hub-ds'
import { useRequestReferee } from 'framework/api/methods'
import { ModalProps } from 'types'

import { Modal, ModalFooter } from 'components/molecules'
import { timeToAESTWithSeconds } from 'utils'

type RequestRefereeModalProps = ModalProps & {
  candidateId: string
  requestSentOn?: string
  getCandidateById: any
}

export const RequestRefereeModal = ({
  isModalActive,
  setIsModalActive,
  candidateId,
  requestSentOn,
  getCandidateById,
}: RequestRefereeModalProps) => {
  const { mutate: requestRefereesMutate, isLoading: requestRefereeIsLoading } =
    useRequestReferee()

  /** request referees */
  const requestReferees = () => {
    requestRefereesMutate(candidateId, {
      onSuccess: () => {
        Toast({
          alertBody: 'Referee request successfully sent to candidate',
          status: 'Success',
        })
        setIsModalActive(false)
        getCandidateById()
      },
      onError: ({ response: { data: errData } }: any) => {
        Toast({
          alertHeader: 'Error...!',
          alertBody: errData?.message
            ? errData?.message
            : 'Error requesting referees!',
          status: 'Error',
        })
      },
    })
  }

  return (
    <Modal
      subHeaderTitle='Send form to candidate asking all the referee information'
      headerTitle='Request Referee'
      isFooterShow={true}
      size='md'
      isActive={isModalActive}
      onHeaderCloseButtonClick={() => setIsModalActive(false)}
      onOverlayClick={() => setIsModalActive(false)}>
      <div className='flex flex-col p-6 w-full'>
        <span className='text-small text-Gray-500 mb-5'>
          Request sent on:{' '}
          <span className='font-SemiBold'>
            {requestSentOn ? timeToAESTWithSeconds(requestSentOn) : 'Never'}
          </span>
        </span>
        <span className='text-small text-Gray-800 mb-5'>
          This will send an email to the candidate asking for referee details.
        </span>
        <span className='text-small text-Gray-800'>
          Candidate can fill out the form and send it back to us.
        </span>
      </div>
      <ModalFooter>
        <div className='flex justify-end flex-1'>
          <Button
            isLoading={requestRefereeIsLoading}
            onClick={() => requestReferees()}
            className='mr-2'
            theme='cobalt'>
            Request Referee
          </Button>
          <Button
            onClick={() => setIsModalActive(false)}
            textTheme='blue'
            theme='white'>
            Cancel
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}
