import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

export type CreateAssessment = {
  name: string
  type: string
  duration: number
  questions: AssessmentQuestionDto[]
  isActive: boolean
  difficultyLevel: string
  AssessmentType?: string
}

export type AssessmentQuestionDto = {
  title: string
  type: string
  isRequired: boolean
  metaData: AssessmentQuestionMetaData
}
export type AssessmentQuestionMetaData = {
  meta: string[]
  correctAnswer: string
  rate: string
  imageUrl: string
  videoUrl: string
  isAIGenerated: boolean
  comment: string
  uploadType: string
  videoPlatform: string | null
}

export const useCreateAssessment = () => {
  const mutate = useMutation(
    ({
      name,
      type,
      duration,
      questions,
      isActive,
      difficultyLevel,
      AssessmentType = 'Assessment',
    }: CreateAssessment) => {
      return postAPI(`/assessment`, {
        assessment: {
          name,
          type,
          duration,
          questions,
          isActive,
          difficultyLevel,
          AssessmentType,
        },
      })
    },
  )

  return mutate
}
