import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Modal, Toast } from '@labourhub/labour-hub-ds'
import { useGetTokenByRefreshToken } from 'api/auth/getTokenByRefreshToken'

type SessionTimeoutModalProps = {
  isModalActive: boolean
  setIsModalActive: (active: boolean) => void
}

export const SessionTimeoutModal = ({
  isModalActive,
  setIsModalActive,
}: SessionTimeoutModalProps) => {
  const navigate = useNavigate()
  const notify = (props: any) => Toast(props)
  const [timeoutId, setTimeoutId] = useState<any>(null)

  const { mutate, isLoading: isTokenByRefreshTokenLoading } =
    useGetTokenByRefreshToken()

  const handleSignedIn = () => {
    const refreshTokenLocalStorage = localStorage.getItem('refreshToken')
    const refreshTokenSessionStorage = sessionStorage.getItem('refreshToken')
    const refreshToken = refreshTokenSessionStorage || refreshTokenLocalStorage
    if (refreshToken) {
      mutate(
        {
          refreshToken,
        },
        {
          onSuccess: ({ data }: any) => {
            localStorage.setItem('token', data.token)
            localStorage.setItem('refreshToken', data.refreshToken)
          },
          onError: () => {
            notify({
              alertHeader: 'Error: Failed to refresh session token',
              status: 'Error',
            })
          },
          onSettled: () => {
            setIsModalActive(false)
          },
        },
      )
    }
  }

  const handleSignOut = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('refreshToken')
    setIsModalActive(false)
    navigate('/auth/login')
  }

  const handleClose = () => {
    setIsModalActive(false)

    // Clear the existing timeout, if any
    if (timeoutId) {
      clearTimeout(timeoutId)
    }

    // Set a new timeout
    const newTimeoutId = setTimeout(() => {
      handleSignOut()
    }, 10 * 60 * 1000)

    // Save the new timeout ID
    setTimeoutId(newTimeoutId)
  }

  return (
    <div>
      <Modal
        isActive={isModalActive}
        onClickPrimaryBtn={handleSignedIn}
        onClickSecondaryBtn={handleSignOut}
        primaryButtonProps={{
          style: { width: '100%', marginLeft: '12px' },
          isLoading: isTokenByRefreshTokenLoading,
        }}
        secondaryButtonProps={{
          style: { width: '100%', color: '#1F2937' },
        }}
        onOverlayClick={() => {
          setIsModalActive(false)
        }}
        modalProps={{
          style: { width: '466px', height: '300px' },
        }}
        footerProps={{
          style: {
            justifyContent: 'space-between',
            paddingLeft: '23px',
            paddingRight: '23px',
            flexDirection: 'row-reverse',
          },
        }}
        isHeaderShow={false}
        primaryButtonTitle='Stay Signed in'
        secondaryButtonTitle='Sign out now'>
        <div className='relative flex flex-col items-center justify-center p-6'>
          <div className='absolute top-0 right-0 flex justify-end mt-2 mr-2'>
            <Button
              theme='link'
              textTheme='black'
              isIconOnly
              onClick={handleClose}
              icon={
                <i className='ri-close-circle-line text-Gray-400 text-heading-3' />
              }></Button>
          </div>
          <div className='flex items-center justify-center w-12 h-12 rounded-full bg-Yellow-50'>
            <i className='ri-error-warning-line text-Yellow-500 text-heading-3'></i>
          </div>

          <span className='pt-5 text-Gray-900 text-heading-5 font-Medium'>
            Your session is about to expire
          </span>

          <span className='pt-2 text-center text-Gray-500 text-small font-Regular'>
            Your session is about to expire due to inactivity. To ensure the
            security of your data, please click 'Stay Signed in' to continue
            your session. <b>Do you want to stay signed in?</b>
          </span>
        </div>
      </Modal>
    </div>
  )
}
