/** candidate list status code */
export const Active = 'Active'
export const Pending = 'Pending'
export const Disabled = 'Disabled'

export const statusList = [
  {
    value: 'Active',
    label: 'Active',
  },
  {
    value: 'Pending',
    label: 'Pending',
  },
  {
    value: 'Deactive',
    label: 'Inactive',
  },
]
