import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

type GetUserProfileDetailsByIdResponse = {
  message?: string
  messageTitle?: string | null
  user?:
    | {
        alternateEmail?: string | null
        avatarUrl?: string | null
        createdOn?: string | null
        displayName?: string | null
        dob?: string | null
        email?: string | null
        emailSignature?: string | null
        firstName?: string | null
        gender?: string | null
        id?: string | null
        identityGuid?: string | null
        isActive?: boolean
        jobTitle?: string | null
        lastName?: string | null
        passwordResetRequestedOn?: string | null
        phoneNumber?: string | null
        roleId?: string | null
        roleName?: string | null
        status?: string | null
        tenantId?: string | null
        updatedOn?: string | null
        userName?: string
      }
    | undefined
}

export const useGetUserProfileDetailsById = (
  userId: string,
  onSuccess: any,
) => {
  return useQuery(
    ['user_details', userId],

    async () => {
      const data = await get(`/UserProfile/${userId}`)
      return (
        (data?.data?.userProfile as GetUserProfileDetailsByIdResponse) || []
      )
    },

    {
      onError: () => {
        Toast({
          alertHeader: 'Error fetching user data',
          status: 'Error',
        })
      },
      enabled: false,
      onSuccess,
    },
  )
}
