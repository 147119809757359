import { useMutation } from 'react-query'
import { postAPIwithFormData } from 'framework/api/http'

export type ImageUpload = {
  assessmenttId?: string
  questionId?: string
  assessmentImage: File
}

export const useImageUpload = () => {
  const mutate = useMutation(
    ({ assessmenttId, assessmentImage, questionId }: ImageUpload) => {
      const bodyFormData = new FormData()
      bodyFormData.append('assessmentImage', assessmentImage)
      if (assessmenttId) bodyFormData.append('assessmenttId', assessmenttId)
      if (questionId) bodyFormData.append('questionId', questionId)
      return postAPIwithFormData(`/assessment/image`, bodyFormData, {})
    },
  )

  return mutate
}
