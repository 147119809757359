import { Column } from 'components/atoms/Column'

type ReportExecutiveSummarySectionProps = {
  executiveSummary: string
}

export const ReportExecutiveSummarySection = ({
  executiveSummary,
}: ReportExecutiveSummarySectionProps) => {
  return (
    <Column className='items-start justify-start w-full p-6'>
      <div className='w-full mb-4'>
        <label
          htmlFor=''
          className='text-left text-base text-[#2F2E41] text-heading-5 font-Bold'>
          Executive Summary{' '}
        </label>
      </div>

      <div className='p-4 whitespace-pre-line border rounded-lg border-Gray-200'>
        {executiveSummary}
      </div>
    </Column>
  )
}
