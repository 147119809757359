import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

type GetDashboardDataResponse = {
  message: string
  messageTitle: string
  dashboard: DashboardData
}

type DashboardData = {
  candidateCard: {
    completed: number
    pending: number
    expired: number
    candidateCount: number
  }
  refereeCard: {
    completed: number
    pending: number
    phoneRef: number
    declined: number
    refereeCount: number
  }
  fullMetricsCard: {
    completed: number
    pending: number
    pass: number
    fail: number
  }
  fraudDetectedCount: number
  avgTimeCompletedRefCheck: number
}

export const useGetDashboardData = (startDate: string, endDate: string) => {
  return useQuery(
    ['GET_DASHBOARD_DATA'],
    async () => {
      const res = await get(`facade/Dashboard?From=${startDate}&To=${endDate}`)
      return res.data as GetDashboardDataResponse
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onError: () => {
        Toast({
          alertHeader: 'Error fetching dashboard data',
          status: 'Error',
        })
      },
    },
  )
}
