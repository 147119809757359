import { cn } from 'utils'
import { countChars } from 'utils/countChars'

export default function Characters({
  typedHistory,
  wordList,
  isReport = false,
}: {
  typedHistory: string[]
  wordList: string[]
  isReport?: boolean
}) {
  const charStats = countChars(typedHistory, wordList)

  const characterStats = [
    { name: 'Correct', value: charStats.allCorrectChars },
    { name: 'Incorrect', value: charStats.incorrectChars },
    { name: 'Extra', value: charStats.extraChars },
    { name: 'Missed', value: charStats.missedChars },
  ]

  return (
    <div
      className={cn(
        'h-[200px] mx-auto my-6 bg-white border border-Gray-200 shadow hover:bg-Gray-100 break-inside-avoid',
        { 'w-full': !isReport, 'w-[751px] m-4': isReport },
      )}>
      <h1
        className={cn(
          'bg-Cobalt-50 px-5 font-SemiBold tracking-tight text-Gray-900',
          {
            'text-heading-4': !isReport,
            'text-small leading-[45px]': isReport,
          },
        )}>
        Characters
      </h1>
      <ul className='flex flex-col mt-5 px-5'>
        {characterStats.map(({ name, value }) => (
          <li key={name} className='flex flex-row items-center justify-around'>
            <p
              className={cn('leading-7 font-Medium text-Gray-600', {
                'text-[16px]': !isReport,
                'text-extra-small': isReport,
              })}>
              {name}
            </p>
            <p
              className={cn('ml-auto text-Gray-700 font-Medium', {
                'text-heading-5': !isReport,
                'text-extra-small leading-7': isReport,
              })}>
              {value}
            </p>
          </li>
        ))}
      </ul>
    </div>
  )
}
