import React, { useState } from 'react'

import { setEmbededVideoLink } from 'utils/setEmbededVideoLink'

type IFrameVideoProps = {
  link: string
  className?: string
}

const IFrameVideo = ({ link, className = 'w-1/2' }: IFrameVideoProps) => {
  const [loading, setLoading] = useState(true)
  return (
    <div className={`relative pointer-events-auto rounded-md ${className}`}>
      {loading && (
        <div className='absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 z-10'>
          <div className='w-12 h-12 border-4 border-Blue-500 border-t-transparent border-solid rounded-full animate-pulse200'></div>
        </div>
      )}
      <iframe
        // width={500}
        height={235}
        className='w-full rounded-md'
        src={setEmbededVideoLink(link)}
        title='Video Preview'
        frameBorder={0}
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
        onLoad={() => setLoading(false)}
      />
    </div>
  )
}

export default IFrameVideo
