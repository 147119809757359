import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'
import { TenantIdProp } from 'types'

import { ListSubscriptionDto } from '../../features/subscriptions/types'

export const useGetActiveSubscriptionList = (
  tenantId: TenantIdProp,
  onSuccess: (data: any) => void,
) => {
  return useQuery(
    ['subscriptions', tenantId],
    async (): Promise<ListSubscriptionDto> => {
      const res = await get(`/Subscriptions/ListActive?tenantId=${tenantId}`)
      return res.data
    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
      onSuccess,
      onError: () => {
        Toast({
          alertHeader: 'Error fetching active subscription list',
          status: 'Error',
        })
      },
    },
  )
}
