import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

export type SendCustomSurveyDto = {
  surveyId?: string
  templateName?: string
  surveyLibraryId?: string
}
type SendCustomSurvey = {
  candidateId: string
  requestData: SendCustomSurveyDto
}

export const useSendCustomSurvey = () => {
  const mutate = useMutation(
    ({ requestData, candidateId }: SendCustomSurvey) => {
      const { surveyId, templateName, surveyLibraryId } = requestData || {}
      return postAPI(`Candidates/${candidateId}/SendCustomSurveys`, {
        surveyId,
        templateName,
        surveyLibraryId,
      })
    },
  )

  return mutate
}
