/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import { TextField } from '@labourhub/labour-hub-ds'
import { useGetEmailValidation, useUserInvite } from 'framework/api/methods'

import { PageLoader, PhoneInputField } from 'components/atoms'
import { RoundedModal } from 'components/ui/RoundedModal'
import { isAlphaNumeric, isEmail, isPhoneNumber } from 'utils'

export type InviteNewUserModalProps = {
  buttonTitle: string
  headerSubtitle: string
  headerTitle: string
  isButtonDisabled: boolean
  [x: string]: any
}

export const InviteNewUserModal = ({
  isActive,
  setIsActive,
  buttonTitle,
  getTableData,
  headerSubtitle,
  headerTitle,
  notify,
  roleId,
}: InviteNewUserModalProps) => {
  const [showSuccessScreen, setShowSuccessScreen] = useState(false)
  const [emailValid, setEmailValid] = useState(false)
  const [userDetails, setUserDetails] = useState({
    fullName: '',
    displayName: '',
    jobTitle: '',
    email: '',
    telNo: '',
  })

  /** email and phone number validation */
  const [isErrorField, setIsErrorField] = useState<any>({
    fullName: false,
    displayName: false,
    jobTitle: false,
    email: false,
    telNo: false,
  })

  /** API call for the invite new user */
  const { mutate: addNewInvite, isLoading: isLoadingNewUserInvite } =
    useUserInvite()

  /** Process the invite new User  */
  async function newInvite() {
    addNewInvite(
      {
        email: userDetails.email,
        roleId: roleId,
        displayName: userDetails.displayName,
        fullName: userDetails.fullName,
        jobTitle: userDetails.jobTitle,
        phoneNumber: userDetails.telNo,
      },
      {
        onSuccess: () => {
          setShowSuccessScreen(true)
          getTableData()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: errData.messageTitle ? errData.messageTitle : 'Error!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** API Call for get user email validation */
  const { mutate: userListByNameMutate } = useGetEmailValidation()

  /** Process the get User Email validation */
  async function getUserEmailValidation(email: any) {
    userListByNameMutate(
      { userEmail: email },
      {
        onSuccess: () => {
          setEmailValid(true)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: errData.messageTitle ? errData.messageTitle : 'Error!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** front end validation for all input fields */
  const validateInputs = () => {
    const { fullName, displayName, jobTitle, email, telNo } = userDetails

    setIsErrorField({
      ...isErrorField,
      fullName: isAlphaNumeric(fullName) ? false : true,
      displayName: displayName ? false : true,
      jobTitle: jobTitle ? false : true,
      email: isEmail(email) ? false : true,
      telNo: !isPhoneNumber(telNo),
    })

    const isAllValidFields =
      isEmail(email) &&
      isPhoneNumber(telNo) &&
      isAlphaNumeric(fullName) &&
      displayName &&
      jobTitle

    return isAllValidFields
  }

  /** After submission clearing the records. */
  const resetFieldValues = () => {
    setUserDetails({
      fullName: '',
      displayName: '',
      jobTitle: '',
      email: '',
      telNo: '',
    })
  }

  /** user email onchange function */
  const handleChange = (e: any) => {
    setEmailValid(false)
    setUserDetails({ ...userDetails, email: e.target.value })
  }

  useEffect(() => {
    isEmail(userDetails.email)
      ? getUserEmailValidation(userDetails.email)
      : setEmailValid(false)
  }, [userDetails.email])

  /** Process of posting user details */
  const handleSubmit = () => {
    const valid = validateInputs()

    if (valid) {
      newInvite()
    }
  }

  // Add a function to reset error state
  const resetErrorState = () => {
    setIsErrorField({
      fullName: false,
      displayName: false,
      jobTitle: false,
      email: false,
      telNo: false,
    })
  }
  const handleCloseModal = () => {
    setShowSuccessScreen(false)
    setEmailValid(false)
    setIsActive(false)
    resetFieldValues()
    resetErrorState()
  }

  return (
    <RoundedModal
      isActive={isActive}
      isHeaderShow={true}
      isFooterShow={showSuccessScreen ? false : true}
      isSeparator={true}
      headerTitle={headerTitle}
      headerSubtitle={headerSubtitle}
      size='md'
      className='!z-[150]'
      isPrimaryButtonDisable={
        userDetails.fullName &&
        userDetails.displayName &&
        userDetails.jobTitle &&
        isEmail(userDetails.email) &&
        isPhoneNumber(userDetails.telNo) &&
        emailValid
          ? false
          : true
      }
      primaryButtonTitle={buttonTitle}
      secondaryButtonTitle='Cancel'
      onHeaderCloseButtonClick={() => {
        handleCloseModal()
      }}
      onClickSecondaryBtn={() => {
        handleCloseModal()
      }}
      onClickPrimaryBtn={handleSubmit}>
      {/** Body section of the drawer modal */}
      {!showSuccessScreen ? (
        <>
          {isLoadingNewUserInvite && <PageLoader size='xxs' />}
          {
            <div className='flex flex-col justify-between w-full h-full'>
              <div className='px-6'>
                <TextField
                  isRequired
                  className='mt-8'
                  label='Full name'
                  value={userDetails.fullName}
                  onChange={(e: any) =>
                    setUserDetails({ ...userDetails, fullName: e.target.value })
                  }
                  placeholder='Full name'
                  isErrored={isErrorField.fullName}
                />

                <TextField
                  isRequired
                  className='mt-5'
                  label='Display name'
                  value={userDetails.displayName}
                  onChange={(e: any) =>
                    setUserDetails({
                      ...userDetails,
                      displayName: e.target.value,
                    })
                  }
                  placeholder='Display name'
                  isErrored={isErrorField.displayName}
                />

                <TextField
                  isRequired
                  className='mt-5'
                  label='Job title'
                  value={userDetails.jobTitle}
                  onChange={(e: any) =>
                    setUserDetails({
                      ...userDetails,
                      jobTitle: e.target.value,
                    })
                  }
                  placeholder='Job title'
                  isErrored={isErrorField.jobTitle}
                />

                <TextField
                  isRequired
                  className='mt-5'
                  label='Email'
                  value={userDetails.email}
                  onChange={(e: any) => handleChange(e)}
                  placeholder='Email'
                  isErrored={isErrorField.email}
                />

                {/** Phone number field */}

                <PhoneInputField
                  isRequired
                  label='Phone number'
                  value={userDetails.telNo}
                  onChange={(number: any) =>
                    setUserDetails({ ...userDetails, telNo: number })
                  }
                  className='mt-5 mb-16'
                  isErrored={isErrorField.telNo}
                />
              </div>
            </div>
          }
        </>
      ) : (
        /** Email sent informing screen */
        <div className='flex flex-col flex-auto justify-center my-10 items-center'>
          <div className='flex justify-center items-center w-12 h-12 rounded-full bg-Green-300 border-[6px] border-Green-100'>
            <i className='ri-mail-send-line text-white text-[22px]'></i>
          </div>

          <span className='font-Medium text-heading-5 text-Gray-800 pt-1'>
            Successfully sent!
          </span>

          <span className='font-Regular text-small text-Gray-600  pt-1'>
            {` Sent a request to ${userDetails.email} `}
          </span>
        </div>
      )}
    </RoundedModal>
  )
}
