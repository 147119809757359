import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface useGetInvitedUserDetailsInterface {
  userName: string
  emailToken: string
}

export const useGetInvitedUserDetails = () => {
  const mutate = useMutation(
    ({ userName, emailToken }: useGetInvitedUserDetailsInterface) => {
      return get(
        `/facade/GetInvited/User?UserName=${userName}&EmailToken=${emailToken}`,
      )
    },
  )

  return mutate
}

export default useGetInvitedUserDetails
