/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

const initialState = {
  dateRange: [
    moment().startOf('month').format('YYYY-MM-DD').toString(),
    moment().format('YYYY-MM-DD').toString(),
  ],
}

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    resetToInitialState(state, action) {
      state.dateRange = [
        moment().startOf('month').format('YYYY-MM-DD').toString(),
        moment().format('YYYY-MM-DD').toString(),
      ]
    },

    setDateRangeState(state, { payload }) {
      state.dateRange = payload
    },
  },
})

export const { resetToInitialState, setDateRangeState } = dashboardSlice.actions

export const setToInitial =
  (values, cb = () => {}) =>
  (dispatch) => {
    dispatch(resetToInitialState(values))
    return cb(values)
  }

export const setDateRange =
  (payload, cb = () => {}) =>
  (dispatch) => {
    dispatch(setDateRangeState(payload))
  }

export default dashboardSlice.reducer
