import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

type TokenByRefreshTokenRequest = {
  refreshToken: string
}

export const useGetTokenByRefreshToken = () => {
  const mutate = useMutation(({ refreshToken }: TokenByRefreshTokenRequest) => {
    return postAPI(`identity/token`, { refreshToken })
  })
  return mutate
}
