type CustomRadioProps = {
  id: string
  label?: string
  name: string
  value?: string
  helperText?: string
  onChange: React.ChangeEventHandler<HTMLInputElement>
}

export const CustomRadio = ({
  id,
  label,
  name,
  value,
  helperText,
  onChange,
}: CustomRadioProps) => {
  return (
    <label className='flex'>
      <input
        id={id}
        type='radio'
        className='form-check-input appearance-none rounded-full mt-1 h-4 w-4 border border-Gray-300 bg-white checked:bg-white checked:border-Cobalt-600 checked:border-[5px] hover:bg-Gray-300 active:bg-Gray-400 focus:outline-none focus:ring focus:ring-Cobalt-300 transition duration-200 bg-no-repeat bg-center bg-contain float-left cursor-pointer'
        name={name}
        value={value}
        onChange={onChange}
      />
      <div className='flex flex-col justify-center pl-2'>
        {label && (
          <span className='font-Medium text-small text-Gray-700'>{label}</span>
        )}
        {helperText && (
          <span className='font-Regular text-small text-Gray-500'>
            {helperText}
          </span>
        )}
      </div>
    </label>
  )
}
