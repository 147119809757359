import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Active,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  Over,
  PointerSensor,
  rectIntersection,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { SortableData } from '@dnd-kit/sortable'
import { Toast } from '@labourhub/labour-hub-ds'
import { useCreateAssessment } from 'api/assessment/createAssessment'
import { useGetAssessmentBuildById } from 'api/assessment/getAssessmentBuildById'
import { useUpdateAssessment } from 'api/assessment/updateAssessment'
import { assessmentQuestionTypes } from 'features/questionnaires/data'
import { setAIAssesmentQuestionData } from 'features/questionnaires/store/actions'
import { getAssessments } from 'features/questionnaires/store/selectors'
import { AssessmentQuestion } from 'features/questionnaires/types'
import { v4 as uuid } from 'uuid'

import { Row } from 'components/atoms/Row'

import { EditAssessmentDrawer } from './EditAssessmentDrawer/EditAssessmentDrawer'
import { AssessmentBuilderHeaderSection } from './AssessmentBuilderHeaderSection'
import { AssessmentEditPreviewModal } from './AssessmentEditPreviewModal'
import { QuestionCardsColumn } from './QuestionCardsColumn'
import {
  DraggableQuestionType,
  QuestionTypesColumn,
} from './QuestionTypesColumn'

export const AssessmentBuilder = () => {
  const notify = (props: any) => Toast(props)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [itemsMap, setItemsMap] = useState<
    Map<UniqueIdentifier, AssessmentQuestion[]>
  >(
    new Map([
      ['question-types', assessmentQuestionTypes],
      ['question-cards', []],
    ]),
  )

  const { aiAssessmentQuestionData } = useSelector(getAssessments)
  const { assessmentId } = useParams()

  const [showEditDrawer, setShowEditDrawer] = useState(false)
  const [isScrollToBottom, setIsScrollToBottom] = useState(false)
  const [selectedQuestion, setSelectedQuestion] = useState<AssessmentQuestion>()
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null)
  const [aId, setAId] = useState<any>(assessmentId)
  const [lastUpdatedBy, setLastUpdatedBy] = useState<string>('')
  const [isDraft, setIsDraft] = useState<boolean>(false)
  const [editableFields, setEditableFields] = useState<any>([])
  const [isSaveBtnEnabled] = useState<boolean>(false)

  const [isShowPreviewModal, setIsShowPreviewModal] = useState<boolean>(false)

  const [formData, setFormData] = useState<any>({
    name: '',
    duration: 0,
    category: 'Custom',
    difficultyLevel: '',
  })

  const getFromData = useCallback((active: Active) => {
    const activeData = (active.data.current as SortableData).sortable
    return {
      ...activeData,
      items:
        activeData.containerId == 'question-types'
          ? assessmentQuestionTypes
          : itemsMap.get('question-cards') || [],
    }
  }, [])

  const getToData = useCallback(
    (over: Over) => {
      const items = itemsMap.get(over.id)
      if (typeof items === 'undefined') {
        const overData = (over.data.current as SortableData).sortable
        return {
          ...overData,
          items:
            overData.containerId == 'question-types'
              ? assessmentQuestionTypes
              : itemsMap.get('question-cards') || [],
        }
      } else {
        return {
          containerId: over.id,
          items,
          index: items.length,
        }
      }
    },
    [itemsMap],
  )

  const getData = (event: { active: Active; over: Over | null }) => {
    const { active, over } = event
    if (!over || active.id === over.id) {
      return null
    }
    return {
      from: getFromData(active),
      to: getToData(over),
    }
  }

  const handleDragStart = useCallback((event: DragStartEvent) => {
    setActiveId(event.active.id)
  }, [])

  const handleDragEnd = (event: DragEndEvent) => {
    setIsScrollToBottom(false)

    const data = getData(event)

    if (data == null) {
      return
    }

    const { from, to } = data

    setItemsMap((prevItemsMap) => {
      const newMap = new Map(prevItemsMap)

      // Moving items within the same container
      if (
        from.containerId === 'question-cards' &&
        to.containerId === 'question-cards'
      ) {
        const currentItems = newMap.get('question-cards') || []
        const updatedItems = [...currentItems]
        const [movedItem] = updatedItems.splice(from.index, 1)
        updatedItems.splice(to.index, 0, movedItem)
        newMap.set('question-cards', updatedItems)
      }

      // Adding new items from question-types to question-cards
      else if (
        from.containerId === 'question-types' &&
        to.containerId === 'question-cards'
      ) {
        const draggedItem = from.items[from.index]
        let newToItems = [...to.items]
        newToItems = newToItems.filter((item) => item.id !== 'empty-space')
        newToItems.splice(to.index, 0, { ...draggedItem, id: uuid() })
        newMap.set('question-cards', newToItems)
      }

      return newMap
    })

    setActiveId(null)
    return
  }

  const handleQuestionTypeDoubleClick = useCallback((id: UniqueIdentifier) => {
    const item = assessmentQuestionTypes.find((q) => q.id === id)
    if (!item) return

    setItemsMap((prevItemsMap) => {
      const newItems = [
        ...(prevItemsMap.get('question-cards') || []),
        {
          ...item,
          id: uuid(),
        },
      ]
      const newMap = new Map(prevItemsMap)
      newMap.set('question-cards', newItems)
      return newMap
    })

    setIsScrollToBottom(true)
  }, [])

  const updateQuestion = useCallback((updatedQuestion: AssessmentQuestion) => {
    setItemsMap((prevItemsMap) => {
      const currentItems = prevItemsMap.get('question-cards') || []
      const updatedItems = currentItems.map((item) =>
        item.id === updatedQuestion.id ? { ...item, ...updatedQuestion } : item,
      )
      const newMap = new Map(prevItemsMap)
      newMap.set('question-cards', updatedItems)
      return newMap
    })
  }, [])

  const addNewQuestion = useCallback((newQuestion: AssessmentQuestion) => {
    setItemsMap((prevItemsMap) => {
      const currentItems = prevItemsMap.get('question-cards') || []
      const updatedItems = [...currentItems, newQuestion]
      const newMap = new Map(prevItemsMap)
      newMap.set('question-cards', updatedItems)
      return newMap
    })
  }, [])

  const addOrUpdateQuestions = useCallback(
    (newQuestions: AssessmentQuestion[]) => {
      setItemsMap((prevItemsMap) => {
        const currentItems = prevItemsMap.get('question-cards') || []
        const updatedItems = newQuestions.reduce(
          (acc, newQuestion) => {
            const index = acc.findIndex((item) => item.id === newQuestion.id)
            if (index !== -1) {
              acc[index] = { ...acc[index], ...newQuestion }
            } else {
              acc.push(newQuestion)
            }
            return acc
          },
          [...currentItems],
        )

        const newMap = new Map(prevItemsMap)
        newMap.set('question-cards', updatedItems)
        return newMap
      })
    },
    [],
  )

  // const addNewQuestions = useCallback((newQuestions: AssessmentQuestion[]) => {
  //   setItemsMap((prevItemsMap) => {
  //     const currentItems = prevItemsMap.get('question-cards') || []
  //     const updatedItems = [...currentItems, ...newQuestions]
  //     const newMap = new Map(prevItemsMap)
  //     newMap.set('question-cards', updatedItems)
  //     return newMap
  //   })
  // }, [])

  const removeQuestion = useCallback((id: UniqueIdentifier) => {
    setItemsMap((prevItemsMap) => {
      const currentItems = prevItemsMap.get('question-cards') || []
      const updatedItems = currentItems.filter((item) => item.id !== id)
      const newMap = new Map(prevItemsMap)
      newMap.set('question-cards', updatedItems)
      return newMap
    })
  }, [])

  const activeQuestion = useMemo(
    () => assessmentQuestionTypes.filter((i) => i.id === activeId)[0],
    [activeId],
  )

  const questionCardItems: AssessmentQuestion[] = useMemo(
    () => itemsMap.get('question-cards') || [],
    [itemsMap],
  )
  const questionCardPreviewItems: AssessmentQuestion[] = useMemo(
    () => itemsMap.get('question-cards') || [],
    [itemsMap],
  )

  const isActiveQuestionCard = useMemo(
    () => questionCardItems.findIndex((i) => i.id === activeId) != -1,
    [questionCardItems, activeId],
  )
  const sensors = useSensors(useSensor(PointerSensor))

  const { mutate: createAssessment } = useCreateAssessment()

  const { mutate: updateAssessment } = useUpdateAssessment()

  const { refetch: getAssessment } = useGetAssessmentBuildById(
    {
      aId,
    },
    async (data: any) => {
      setLastUpdatedBy(data.assessment?.updatedOn)
      setIsDraft(data.assessment?.isActive)
      setFormData({
        name: data?.assessment?.name,
        category: data?.assessment?.type,
        duration: data?.assessment?.duration,
        difficultyLevel: data?.assessment?.difficultyLevel,
      })
      setEditableFields({
        name: data?.assessment?.name,
        category: data?.assessment?.type,
        duration: data?.assessment?.duration,
        difficultyLevel: data?.assessment?.difficultyLevel,
      })
      const editableDestinationColumnTemp: any = []
      data?.assessment?.questions.map((question: any) => {
        switch (question.type) {
          case 'text':
            editableDestinationColumnTemp.push({
              id: question.id,
              icon: 'ri-text-spacing',
              name: 'Short Answer',
              isRequired: question.isRequired,
              type: 'text',
              title: question.title,
              meta: [...question.meta.enum],
              answer: question.meta.answer,
              correctAnswer: question.meta.correctAnswer,
              uploadType: question.meta.uploadType,
              imagePath: question.meta.imageUrl,
              imageUrl: question.meta.publicImageUrl,
              videoLink: question.meta.videoUrl,
              videoPlatform: question.meta.videoPlatform,
              isAIGenerated: question.meta.isAIGenerated,
            })
            break
          case 'text_area':
            editableDestinationColumnTemp.push({
              id: question.id,
              icon: 'ri-text-wrap',
              name: 'Long Answer',
              isRequired: question.isRequired,
              type: 'text_area',
              title: question.title,
              meta: [...question.meta.enum],
              answer: question.meta.answer,
              correctAnswer: question.meta.correctAnswer,
              uploadType: question.meta.uploadType,
              imagePath: question.meta.imageUrl,
              imageUrl: question.meta.publicImageUrl,
              videoLink: question.meta.videoUrl,
              videoPlatform: question.meta.videoPlatform,
              isAIGenerated: question.meta.isAIGenerated,
            })
            break
          case 'textarea':
            editableDestinationColumnTemp.push({
              id: question.id,
              icon: 'ri-text-wrap',
              name: 'Long Answer',
              isRequired: question.isRequired,
              type: 'textarea',
              title: question.title,
              meta: [...question.meta.enum],
              answer: question.meta.answer,
              correctAnswer: question.meta.correctAnswer,
              uploadType: question.meta.uploadType,
              imagePath: question.meta.imageUrl,
              imageUrl: question.meta.publicImageUrl,
              videoLink: question.meta.videoUrl,
              videoPlatform: question.meta.videoPlatform,
              isAIGenerated: question.meta.isAIGenerated,
            })
            break
          case 'select':
            editableDestinationColumnTemp.push({
              id: question.id,
              icon: 'ri-star-line',
              name: 'Select Box',
              isRequired: question.isRequired,
              type: 'select',
              title: question.title,
              meta: [...question.meta.enum],
              answer: question.meta.answer,
              correctAnswer: question.meta.correctAnswer,
              uploadType: question.meta.uploadType,
              imagePath: question.meta.imageUrl,
              imageUrl: question.meta.publicImageUrl,
              videoLink: question.meta.videoUrl,
              videoPlatform: question.meta.videoPlatform,
              isAIGenerated: question.meta.isAIGenerated,
            })
            break
          case 'rating':
            editableDestinationColumnTemp.push({
              id: question.id,
              icon: 'ri-star-line',
              name: 'Rating',
              isRequired: question.isRequired,
              type: 'rating',
              title: question.title,
              meta: [...question.meta.enum],
              answer: question.meta.answer,
              correctAnswer: question.meta.correctAnswer,
              uploadType: question.meta.uploadType,
              imagePath: question.meta.imageUrl,
              imageUrl: question.meta.publicImageUrl,
              videoLink: question.meta.videoUrl,
              videoPlatform: question.meta.videoPlatform,
              isAIGenerated: question.meta.isAIGenerated,
            })
            break
          case 'checkbox':
            editableDestinationColumnTemp.push({
              id: question.id,
              icon: 'ri-checkbox-multiple-line',
              name: 'Multi Choice',
              isRequired: question.isRequired,
              type: 'checkbox',
              title: question.title,
              meta: [...question.meta.enum],
              answer: question.meta.answer,
              correctAnswer: question.meta.correctAnswer,
              uploadType: question.meta.uploadType,
              imagePath: question.meta.imageUrl,
              imageUrl: question.meta.publicImageUrl,
              videoLink: question.meta.videoUrl,
              videoPlatform: question.meta.videoPlatform,
              isAIGenerated: question.meta.isAIGenerated,
            })
            break
          case 'radio':
            editableDestinationColumnTemp.push({
              id: question.id,
              icon: 'ri-radio-button-line',
              name: 'Yes/No',
              isRequired: question.isRequired,
              type: 'radio',
              title: question.title,
              meta: [...question.meta.enum],
              answer: question.meta.answer,
              correctAnswer: question.meta.correctAnswer,
              uploadType: question.meta.uploadType,
              imagePath: question.meta.imageUrl,
              imageUrl: question.meta.publicImageUrl,
              videoLink: question.meta.videoUrl,
              videoPlatform: question.meta.videoPlatform,
              isAIGenerated: question.meta.isAIGenerated,
            })
            break
          case 'radio_pos_detail':
            editableDestinationColumnTemp.push({
              id: question.id,
              icon: 'ri-checkbox-circle-line',
              name: 'Detailed Yes',
              isRequired: question.isRequired,
              type: 'radio_pos_detail',
              title: question.title,
              meta: [...question.meta.enum],
              answer: question.meta.answer,
              correctAnswer: question.meta.correctAnswer,
              uploadType: question.meta.uploadType,
              imagePath: question.meta.imageUrl,
              imageUrl: question.meta.publicImageUrl,
              videoLink: question.meta.videoUrl,
              videoPlatform: question.meta.videoPlatform,
              isAIGenerated: question.meta.isAIGenerated,
            })
            break
          case 'radio_neg_detail':
            editableDestinationColumnTemp.push({
              id: question.id,
              icon: 'ri-checkbox-circle-line',
              name: 'Detailed No',
              isRequired: question.isRequired,
              type: 'radio_neg_detail',
              title: question.title,
              meta: [...question.meta.enum],
              answer: question.meta.answer,
              correctAnswer: question.meta.correctAnswer,
              uploadType: question.meta.uploadType,
              imagePath: question.meta.imageUrl,
              imageUrl: question.meta.publicImageUrl,
              videoLink: question.meta.videoUrl,
              videoPlatform: question.meta.videoPlatform,
              isAIGenerated: question.meta.isAIGenerated,
            })
            break
          case 'heading':
            editableDestinationColumnTemp.push({
              id: question.id,
              icon: 'ri-heading',
              name: 'Heading',
              isRequired: question.isRequired,
              type: 'heading',
              title: question.title,
              meta: [...question.meta.enum],
              answer: question.meta.answer,
              correctAnswer: question.meta.correctAnswer,
              uploadType: question.meta.uploadType,
              imagePath: question.meta.imageUrl,
              imageUrl: question.meta.publicImageUrl,
              videoLink: question.meta.videoUrl,
              videoPlatform: question.meta.videoPlatform,
              isAIGenerated: question.meta.isAIGenerated,
            })
            break
          case 'paragraph':
            editableDestinationColumnTemp.push({
              id: question.id,
              icon: 'ri-text',
              name: 'Paragraph',
              isRequired: question.isRequired,
              type: 'paragraph',
              title: question.title,
              meta: [...question.meta.enum],
              answer: question.meta.answer,
              correctAnswer: question.meta.correctAnswer,
              uploadType: question.meta.uploadType,
              imagePath: question.meta.imageUrl,
              imageUrl: question.meta.publicImageUrl,
              videoLink: question.meta.videoUrl,
              videoPlatform: question.meta.videoPlatform,
              isAIGenerated: question.meta.isAIGenerated,
            })
        }
      })
      //   updateQuestion(editableDestinationColumnTemp)
      //   addNewQuestions(editableDestinationColumnTemp)
      await editableDestinationColumnTemp.map((item: AssessmentQuestion) => {
        if (assessmentId) {
          addNewQuestion(item)
        } else {
          updateQuestion(item)
        }
      })
      //   addNewQuestions(editableDestinationColumnTemp)
    },
    () => {
      notify({
        alertHeader: 'Something went wrong',
        alertBody: '',
        status: 'Error',
      })
    },
  )

  const AiQuestions = useMemo(() => {
    const formattedQuestion: any[] = []
    aiAssessmentQuestionData?.questionnaire?.questions.map((question: any) => {
      switch (question.type) {
        case 'text':
          formattedQuestion.push({
            id: question.id,
            icon: 'ri-text-spacing',
            name: 'Short Answer',
            isRequired: question.isRequired,
            type: 'text',
            title: question.title,
            meta: [...question.meta.enum],
            answer: question.meta.answer,
            correctAnswer: question.meta.correctAnswer,
            uploadType: question.meta.uploadType,
            imagePath: question.meta.imageUrl,
            imageUrl: question.meta.publicImageUrl,
            videoLink: question.meta.videoUrl,
            videoPlatform: question.meta.videoPlatform,
            isAIGenerated: question.meta.isAIGenerated,
          })
          break
        case 'text_area':
          formattedQuestion.push({
            id: question.id,
            icon: 'ri-text-wrap',
            name: 'Long Answer',
            isRequired: question.isRequired,
            type: 'text_area',
            title: question.title,
            meta: [...question.meta.enum],
            answer: question.meta.answer,
            correctAnswer: question.meta.correctAnswer,
            uploadType: question.meta.uploadType,
            imagePath: question.meta.imageUrl,
            imageUrl: question.meta.publicImageUrl,
            videoLink: question.meta.videoUrl,
            videoPlatform: question.meta.videoPlatform,
            isAIGenerated: question.meta.isAIGenerated,
          })
          break
        case 'textarea':
          formattedQuestion.push({
            id: question.id,
            icon: 'ri-text-wrap',
            name: 'Long Answer',
            isRequired: question.isRequired,
            type: 'textarea',
            title: question.title,
            meta: [...question.meta.enum],
            answer: question.meta.answer,
            correctAnswer: question.meta.correctAnswer,
            uploadType: question.meta.uploadType,
            imagePath: question.meta.imageUrl,
            imageUrl: question.meta.publicImageUrl,
            videoLink: question.meta.videoUrl,
            videoPlatform: question.meta.videoPlatform,
            isAIGenerated: question.meta.isAIGenerated,
          })
          break
        case 'select':
          formattedQuestion.push({
            id: question.id,
            icon: 'ri-star-line',
            name: 'Select Box',
            isRequired: question.isRequired,
            type: 'select',
            title: question.title,
            meta: [...question.meta.enum],
            answer: question.meta.answer,
            correctAnswer: question.meta.correctAnswer,
            uploadType: question.meta.uploadType,
            imagePath: question.meta.imageUrl,
            imageUrl: question.meta.publicImageUrl,
            videoLink: question.meta.videoUrl,
            videoPlatform: question.meta.videoPlatform,
            isAIGenerated: question.meta.isAIGenerated,
          })
          break
        case 'rating':
          formattedQuestion.push({
            id: question.id,
            icon: 'ri-star-line',
            name: 'Rating',
            isRequired: question.isRequired,
            type: 'rating',
            title: question.title,
            meta: [...question.meta.enum],
            answer: question.meta.answer,
            correctAnswer: question.meta.correctAnswer,
            uploadType: question.meta.uploadType,
            imagePath: question.meta.imageUrl,
            imageUrl: question.meta.publicImageUrl,
            videoLink: question.meta.videoUrl,
            videoPlatform: question.meta.videoPlatform,
            isAIGenerated: question.meta.isAIGenerated,
          })
          break
        case 'checkbox':
          formattedQuestion.push({
            id: question.id,
            icon: 'ri-checkbox-multiple-line',
            name: 'Multi Choice',
            isRequired: question.isRequired,
            type: 'checkbox',
            title: question.title,
            meta: [...question.meta.enum],
            answer: question.meta.answer,
            correctAnswer: question.meta.correctAnswer,
            uploadType: question.meta.uploadType,
            imagePath: question.meta.imageUrl,
            imageUrl: question.meta.publicImageUrl,
            videoLink: question.meta.videoUrl,
            videoPlatform: question.meta.videoPlatform,
            isAIGenerated: question.meta.isAIGenerated,
          })
          break
        case 'radio':
          formattedQuestion.push({
            id: question.id,
            icon: 'ri-radio-button-line',
            name: 'Yes/No',
            isRequired: question.isRequired,
            type: 'radio',
            title: question.title,
            meta: [...question.meta.enum],
            answer: question.meta.answer,
            correctAnswer: question.meta.correctAnswer,
            uploadType: question.meta.uploadType,
            imagePath: question.meta.imageUrl,
            imageUrl: question.meta.publicImageUrl,
            videoLink: question.meta.videoUrl,
            videoPlatform: question.meta.videoPlatform,
            isAIGenerated: question.meta.isAIGenerated,
          })
          break
        case 'radio_pos_detail':
          formattedQuestion.push({
            id: question.id,
            icon: 'ri-checkbox-circle-line',
            name: 'Detailed Yes',
            isRequired: question.isRequired,
            type: 'radio_pos_detail',
            title: question.title,
            meta: [...question.meta.enum],
            answer: question.meta.answer,
            correctAnswer: question.meta.correctAnswer,
            uploadType: question.meta.uploadType,
            imagePath: question.meta.imageUrl,
            imageUrl: question.meta.publicImageUrl,
            videoLink: question.meta.videoUrl,
            videoPlatform: question.meta.videoPlatform,
            isAIGenerated: question.meta.isAIGenerated,
          })
          break
        case 'radio_neg_detail':
          formattedQuestion.push({
            id: question.id,
            icon: 'ri-checkbox-circle-line',
            name: 'Detailed No',
            isRequired: question.isRequired,
            type: 'radio_neg_detail',
            title: question.title,
            meta: [...question.meta.enum],
            answer: question.meta.answer,
            correctAnswer: question.meta.correctAnswer,
            uploadType: question.meta.uploadType,
            imagePath: question.meta.imageUrl,
            imageUrl: question.meta.publicImageUrl,
            videoLink: question.meta.videoUrl,
            videoPlatform: question.meta.videoPlatform,
            isAIGenerated: question.meta.isAIGenerated,
          })
          break
        case 'heading':
          formattedQuestion.push({
            id: question.id,
            icon: 'ri-heading',
            name: 'Heading',
            isRequired: question.isRequired,
            type: 'heading',
            title: question.title,
            meta: [...question.meta.enum],
            answer: question.meta.answer,
            correctAnswer: question.meta.correctAnswer,
            uploadType: question.meta.uploadType,
            imagePath: question.meta.imageUrl,
            imageUrl: question.meta.publicImageUrl,
            videoLink: question.meta.videoUrl,
            videoPlatform: question.meta.videoPlatform,
            isAIGenerated: question.meta.isAIGenerated,
          })
          break
        case 'paragraph':
          formattedQuestion.push({
            id: question.id,
            icon: 'ri-text',
            name: 'Paragraph',
            isRequired: question.isRequired,
            type: 'paragraph',
            title: question.title,
            meta: [...question.meta.enum],
            answer: question.meta.answer,
            correctAnswer: question.meta.correctAnswer,
            uploadType: question.meta.uploadType,
            imagePath: question.meta.imageUrl,
            imageUrl: question.meta.publicImageUrl,
            videoLink: question.meta.videoUrl,
            videoPlatform: question.meta.videoPlatform,
            isAIGenerated: question.meta.isAIGenerated,
          })
      }
    })
    return formattedQuestion
  }, [aiAssessmentQuestionData])

  useEffect(() => {
    const fetchData = async () => {
      if (aId) {
        await getAssessment()
      } else {
        if (aiAssessmentQuestionData) {
          setFormData({
            name: aiAssessmentQuestionData?.questionnaire?.name,
            category: aiAssessmentQuestionData?.questionnaire?.type,
            duration: aiAssessmentQuestionData?.questionnaire?.duration || 0,
            difficultyLevel:
              aiAssessmentQuestionData?.questionnaire?.difficultyLevel || '',
          })

          // Use a for loop with await to handle async operations
          //   for (const item of AiQuestions) {
          //     await addNewQuestion(item)
          //   }
          //   addNewQuestions(AiQuestions)
          addOrUpdateQuestions(AiQuestions)
          //   await AiQuestions.map(async (item: AssessmentQuestion) => {
          //     addNewQuestion(item)
          //   })
        }
      }
    }

    fetchData()
  }, [aId, aiAssessmentQuestionData])

  const onSaveClick = ({ isPublished }) => {
    const { name, category, duration, difficultyLevel }: any = formData
    const questions = questionCardItems?.map((column) => {
      const {
        title,
        type,
        isRequired,
        meta,
        correctAnswer,
        imageUrl,
        videoLink,
        uploadType,
        videoPlatform,
        isAIGenerated,
      } = column
      return {
        title,
        type,
        isRequired,
        metaData: {
          meta: meta || [],
          correctAnswer: correctAnswer || '',
          rate: '0',
          imageUrl: imageUrl || '',
          videoUrl: videoLink || '',
          isAIGenerated: isAIGenerated || false,
          comment: '',
          uploadType: uploadType || '',
          videoPlatform: videoPlatform || null,
        },
      }
    })
    if (aId) {
      updateAssessment(
        {
          AId: aId,
          name: name,
          type: category,
          duration: duration,
          questions: questions,
          isActive: isPublished,
          difficultyLevel: difficultyLevel,
        },
        {
          onSettled: (data: any) => {
            Toast({
              alertHeader: isPublished
                ? name + ' Template published successfully'
                : 'Template updated as a draft successfully',
              alertBody: '',
              status: 'Success',
            })
            if (data?.data?.assessment?.isActive)
              navigate('/questionnaires?tab=3')
            else {
              setLastUpdatedBy(data?.data?.assessment?.updatedOn)
              setIsDraft(data?.data?.assessment?.isActive)
              setAId(data?.data?.assessment?.id)
            }
          },
          onError: () => {
            notify({
              alertHeader: 'Something went wrong',
              alertBody: '',
              status: 'Error',
            })
          },
        },
      )
    } else {
      createAssessment(
        {
          name: name,
          type: category,
          duration: duration,
          questions: questions,
          isActive: isPublished,
          difficultyLevel: difficultyLevel,
        },
        {
          onSettled: (data: any) => {
            Toast({
              alertHeader: isPublished
                ? name + ' Template published successfully'
                : 'Template saved as a draft successfully',
              alertBody: '',
              status: 'Success',
            })
            dispatch(setAIAssesmentQuestionData(null))
            if (isPublished) {
              navigate('/questionnaires?tab=3')
            }

            if (data?.data?.questionnaire?.isActive) {
              setEditableFields({
                name: data?.data?.assessment?.name,
                category: data?.data?.assessment?.type,
                duration: data?.data?.assessment?.duration,
                difficultyLevel: data?.assessment?.difficultyLevel,
              })
            } else {
              setLastUpdatedBy(data?.data?.assessment?.updatedOn)
              setIsDraft(data?.data?.assessment?.isActive)
              setAId(data?.data?.assessment?.id)
            }
          },
          onError: () => {
            notify({
              alertHeader: 'Something went wrong',
              alertBody: '',
              status: 'Error',
            })
          },
        },
      )
    }
  }

  return (
    <>
      <AssessmentBuilderHeaderSection
        assessmentId={aId}
        formData={formData}
        questionCardItems={questionCardItems}
        lastUpdatedBy={lastUpdatedBy}
        isDraft={isDraft}
        setFormData={setFormData}
        editableFields={editableFields}
        isSaveBtnEnabled={isSaveBtnEnabled}
        onSaveClick={() => onSaveClick({ isPublished: false })}
        onPublishClick={() => onSaveClick({ isPublished: true })}
        onPreviewClick={() => {
          setIsShowPreviewModal(true)
        }}
        isLoading={false}
      />
      <Row className='space-x-4 w-full'>
        <DndContext
          sensors={sensors}
          collisionDetection={rectIntersection}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}>
          <QuestionTypesColumn />
          <QuestionCardsColumn
            items={questionCardItems}
            removeQuestion={removeQuestion}
            isScrollToBottom={isScrollToBottom}
            setShowEditDrawer={setShowEditDrawer}
            setSelectedQuestion={setSelectedQuestion}
            updateQuestion={updateQuestion}
          />

          <DragOverlay>
            {activeId && activeQuestion ? (
              <DraggableQuestionType
                question={activeQuestion}
                onDoubleClick={() =>
                  handleQuestionTypeDoubleClick(activeQuestion.id)
                }
              />
            ) : activeId && isActiveQuestionCard ? (
              <div className='h-[60px] w-full bg-Red-600' />
            ) : null}
          </DragOverlay>
        </DndContext>
      </Row>

      {selectedQuestion && (
        <EditAssessmentDrawer
          isModalActive={showEditDrawer}
          setIsModalActive={setShowEditDrawer}
          question={selectedQuestion}
          updateQuestion={updateQuestion}
        />
      )}
      {isShowPreviewModal && (
        <AssessmentEditPreviewModal
          isModalActive={isShowPreviewModal}
          setIsModalActive={setIsShowPreviewModal}
          templateName={formData.name}
          questionCardItems={questionCardPreviewItems}
        />
      )}
    </>
  )
}
