import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  setGuideStepperSubTitle,
  setGuideStepperTitle,
  setShowGuideStepper,
} from 'features/onboarding/store'

import { TemplateRoleList } from './TemplateRoleList/TemplateRoleList'
import { GenerateAiTemplate } from './GenerateAiTemplate'

export const ChooseReferenceCheckTemplate = () => {
  const [isAiTemplate, setIsAiTemplate] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      setGuideStepperTitle(
        isAiTemplate ? "Let's get started" : 'What are you recruiting for?',
      ),
    )

    dispatch(
      setGuideStepperSubTitle(
        isAiTemplate
          ? "Based on the information you share about the role, we'll craft your editable template."
          : undefined,
      ),
    )

    dispatch(setShowGuideStepper(!isAiTemplate))
  }, [isAiTemplate])

  return isAiTemplate ? (
    <GenerateAiTemplate setIsAiTemplate={setIsAiTemplate} />
  ) : (
    <TemplateRoleList setIsAiTemplate={setIsAiTemplate} />
  )
}
