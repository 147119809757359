import { useEffect, useState } from 'react'
import { Modal, Toast } from '@labourhub/labour-hub-ds'
import { ModalProps } from 'types'

import { LexicalPlainTextEditor } from 'components/ui/LexicalEditor'
import { envConfig } from 'config/framework.config'

type ReferenceReportEditExecutiveSummaryModalProps = ModalProps & {
  candidateId: string
  refereeIds: string[]
  executiveSummary: string
  updateNewPreviewData: (executiveSummary: string) => void
}

export const ReferenceReportEditExecutiveSummaryModal = ({
  isModalActive,
  setIsModalActive,
  executiveSummary,
  updateNewPreviewData,
}: ReferenceReportEditExecutiveSummaryModalProps) => {
  const notify = (props: any) => Toast(props)

  const [key, setKey] = useState<any>(null)
  const [summary, setSummary] = useState<any>(executiveSummary)
  const maxCharLimit = envConfig.execSummaryCharLimit || 2000

  useEffect(() => {
    setKey(Math.random)
  }, [isModalActive])

  useEffect(() => {
    setSummary(executiveSummary)
  }, [executiveSummary])

  const handleClose = () => {
    setIsModalActive(false)
    setSummary(executiveSummary)
  }

  const handleEditExecutiveSummarySave = () => {
    if (summary.length > maxCharLimit) {
      notify({
        alertHeader: `Maximum character limit reached. Please shorten your input to ${maxCharLimit} characters or less.`,
        status: 'Warning',
      })
      return
    }

    updateNewPreviewData(summary)
    setIsModalActive(false)
  }

  return (
    <Modal
      key={key}
      isActive={isModalActive}
      onClickPrimaryBtn={handleEditExecutiveSummarySave}
      onHeaderCloseButtonClick={handleClose}
      onClickSecondaryBtn={handleClose}
      isFooterShow={true}
      className='!z-[150]'
      footerProps={{
        style: {
          flexDirection: 'row-reverse',
        },
      }}
      modalProps={{
        style: { minWidth: '680px', height: '95vh' },
      }}
      primaryButtonProps={{
        theme: 'cobalt',
        style: {
          marginLeft: '12px',
        },
      }}
      secondaryButtonProps={{
        textTheme: 'blue',
      }}
      headerTitle={'Edit Executive Summary'}
      primaryButtonTitle='Save'
      secondaryButtonTitle='Cancel'>
      <div className='flex flex-col items-start justify-start w-full h-full px-6 pt-5 pb-10 space-y-4 overflow-y-auto styled-scroll'>
        <div className='h-[80vh] w-full'>
          <LexicalPlainTextEditor
            initialState={summary}
            onChange={(value) => setSummary(value)}
          />
          <div className='w-full h-6' />
        </div>
      </div>
    </Modal>
  )
}
