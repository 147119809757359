import { useMutation } from 'react-query'
import { SubscriptionPackageType } from 'features/subscriptions'
import { postAPI } from 'framework/api/http'

export type CreateCustomSubscriptionDto = {
  tenantId: string
  packageType: string
  packageName: string
  paymentMethodType: string
  startDate: string
  endDate?: string
  subscriptionType?: string
  isTrial?: boolean
  credits?: number
  amount?: number
}

export const useCreateCustomSubscription = () => {
  const mutate = useMutation((dto: CreateCustomSubscriptionDto) => {
    const payload =
      dto.packageType === SubscriptionPackageType.PayAsYouGo ||
      dto.packageType === SubscriptionPackageType.Free
        ? {
            tenantId: dto.tenantId,
            packageType: dto.packageType,
            packageName: dto.packageName,
            paymentMethodType: dto.paymentMethodType,
            startDate: dto.startDate,
            credits: dto.credits,
          }
        : dto

    return postAPI(`/subscriptions/custom`, payload)
  })

  return mutate
}
