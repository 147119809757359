import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Modal, Radio, Toast } from '@labourhub/labour-hub-ds'
import { setAIAssesmentQuestionData } from 'features/questionnaires/store/actions'
import { ModalProps } from 'types'

import { TemplateCustomizeModal } from './TemplateCustomizeModal'

type SelectTemplateModalProps = ModalProps

export const SelectTemplateModal = ({
  isModalActive,
  setIsModalActive,
}: SelectTemplateModalProps) => {
  const [isTemplateCustomizeModalOpen, setIsTemplateCustomizeModalOpen] =
    useState(false)
  //   const [
  //     isTemplateCustomizeCandidateModalOpen,
  //     setIsTemplateCustomizeCandidateModalOpen,
  //   ] = useState(false)

  const [renderKey, setRenderKey] = useState<any>(0)

  const [templateType, setTemplateType] = useState('')
  const notify = (props: any) => Toast(props)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleClose = () => {
    setTemplateType('')
    setIsModalActive(false)
    setRenderKey(Math.random())
  }

  const handleCreate = () => {
    // Check if a radio button is selected
    if (!templateType) {
      notify({
        alertHeader: 'Please select one option',
        status: 'Warning',
      })
      return
    }

    if (templateType === 'aiTemplate') {
      setIsTemplateCustomizeModalOpen(true)
      handleClose()
    } else if (templateType === 'customTemplate') {
      dispatch(setAIAssesmentQuestionData(null))
      handleClose()
      navigate(`/assessments/new`)
    } else {
      // No radio button is selected, do not process
      return
    }
  }

  return (
    <>
      <Modal
        isActive={isModalActive}
        onOverlayClick={handleClose}
        modalProps={{
          style: { width: '450px' },
        }}
        onClickPrimaryBtn={handleCreate}
        onClickSecondaryBtn={handleClose}
        onHeaderCloseButtonClick={handleClose}
        primaryButtonProps={{ style: { marginLeft: '12px' } }}
        secondaryButtonProps={{ textTheme: 'black' }}
        footerProps={{
          style: {
            justifyContent: 'flex-start',
            flexDirection: 'row-reverse',
          },
        }}
        headerTitle='Build New Assessment'
        primaryButtonTitle='Create'
        secondaryButtonTitle='Cancel'>
        <div className='px-8 py-4'>
          <div className='text-Gray-500'>
            Please choose the specific assessment type you want to create.
          </div>

          <div key={renderKey} className='mt-4 space-y-4'>
            <Radio
              id='aiTemplate'
              labelText='Generate AI Assessment'
              helperText=''
              name='templateType'
              onChange={(e: any) => setTemplateType(e.target.id)}
            />

            <Radio
              id='customTemplate'
              labelText='Create Custom Assessment'
              helperText=''
              name='templateType'
              onChange={(e: any) => setTemplateType(e.target.id)}
            />
          </div>
        </div>
      </Modal>
      <TemplateCustomizeModal
        isModalActive={isTemplateCustomizeModalOpen}
        setIsModalActive={setIsTemplateCustomizeModalOpen}
      />
      {/* <TemplateCustomizeSurveyModal
        isModalActive={isTemplateCustomizeCandidateModalOpen}
        setIsModalActive={setIsTemplateCustomizeCandidateModalOpen}
      /> */}
    </>
  )
}
