import { useEffect, useState } from 'react'
import { TextArea, Toast } from '@labourhub/labour-hub-ds'
import { useUpdateCandidateNote } from 'api/candidates'
import { ModalProps } from 'types'

import { RoundedModal } from 'components/ui/RoundedModal'

type EditNoteModalProps = ModalProps & {
  getCandidateById: any
  noteId: string
  description: string
}

export const EditNoteModal = ({
  isModalActive,
  setIsModalActive,
  getCandidateById,
  noteId,
  description,
}: EditNoteModalProps) => {
  const [formDetails, setFormDetails] = useState<any>({
    type: 'Note',
    note: description,
  })

  useEffect(() => {
    setFormDetails((prevFormDetails) => ({
      ...prevFormDetails,
      note: description,
    }))
  }, [description])

  const {
    mutate: updateCandidateNoteMutate,
    isLoading: updateCandidateNoteIsLoading,
  } = useUpdateCandidateNote()

  const handleUpdateNote = () => {
    updateCandidateNoteMutate(
      {
        id: noteId,
        body: {
          description: formDetails.note,
        },
      },
      {
        onSuccess: () => {
          Toast({
            alertHeader: 'Note updated successfully',
            status: 'Success',
          })
          handleCloseModal()
          getCandidateById()
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message
              ? errData?.message
              : 'Error updating the note!',
            status: 'Error',
          })
        },
      },
    )
  }

  const resetFields = () => {
    setFormDetails({
      type: 'Note',
      note: '',
    })
  }

  const handleCloseModal = () => {
    setIsModalActive(false)
    resetFields()
  }

  return (
    <RoundedModal
      isActive={isModalActive}
      modalBodyClassName='h-[50vh]'
      headerTitle='Edit Note'
      isHeaderShow={true}
      isFooterShow={true}
      isSeparator={true}
      primaryButtonTitle='Save'
      secondaryButtonTitle='Cancel'
      isPrimaryButtonLoading={updateCandidateNoteIsLoading}
      onHeaderCloseButtonClick={() => {
        handleCloseModal()
      }}
      onClickPrimaryBtn={() => {
        handleUpdateNote()
      }}
      onClickSecondaryBtn={() => {
        handleCloseModal()
      }}>
      <div className='w-full py-2 px-6 overflow-y-auto styled-scroll'>
        <TextArea
          className='pt-4 notes'
          label='Note'
          value={formDetails?.note}
          onChange={(e: any) => {
            setFormDetails({
              ...formDetails,
              note: e.target.value,
            })
          }}
          placeholder='Add a Note'
          rows={4}
        />
      </div>
    </RoundedModal>
  )
}
