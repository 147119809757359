import { SurveyResponseDto } from 'api/candidates'

import { Rate } from 'components/atoms'
import { Column } from 'components/atoms/Column'

type ReportSurveySummarySectionProps = {
  surveyResponse: SurveyResponseDto
  dynamicFieldMapping: Record<string, string> | null
  changeTextSchema: (text: any) => any
}

export const ReportSurveySummarySection = ({
  surveyResponse,
  dynamicFieldMapping,
  changeTextSchema,
}: ReportSurveySummarySectionProps) => {
  return (
    <Column className='items-start justify-start w-full space-y-3'>
      <span className='mt-3 uppercase text-base text-[#085DA8] font-Medium'>
        SURVEY RESPONSES
      </span>

      {dynamicFieldMapping &&
        surveyResponse?.reportQuestions?.length > 0 &&
        surveyResponse?.reportQuestions?.map((item, index) => (
          <div
            key={index}
            className='w-full py-3'
            style={{ pageBreakInside: 'avoid' }}>
            {item.questionType == 'rating' ? (
              <div className='space-y-3'>
                <p className='pb-2 border-b text-Gray-800 font-Medium text-small border-Gray-200'>
                  {item.questionNumber}. {changeTextSchema(item.question)}
                </p>
                <Rate rating={item.answer} count={item?.ratingCount} />
              </div>
            ) : item.questionType == 'checkboxes' ? (
              <div className='space-y-3'>
                <p className='pb-2 border-b text-Gray-800 font-Medium text-small border-Gray-200'>
                  {item.questionNumber}. {changeTextSchema(item.question)}
                </p>
                <p className='ml-3 text-small'>
                  <ul>
                    {JSON.parse(item.answer)?.map(
                      (answerItem: string, index: number) => (
                        <li key={index}>{answerItem}</li>
                      ),
                    )}
                  </ul>
                </p>
              </div>
            ) : item.questionType == 'heading' ? (
              <div className='space-y-3'>
                <p className='mb-2 whitespace-pre-wrap text-Gray-800 font-Bold text-heading-4'>
                  {changeTextSchema(item.question)}
                </p>
              </div>
            ) : item.questionType == 'paragraph' ? (
              <div className='space-y-3'>
                <p className='mb-1 whitespace-pre-wrap text-small text-Gray-800 font-Medium'>
                  {changeTextSchema(item.question)}
                </p>
              </div>
            ) : (
              <div className='space-y-3'>
                <p className='pb-2 border-b text-Gray-800 font-Medium text-small border-Gray-200'>
                  {item.questionNumber}. {changeTextSchema(item.question)}
                </p>
                <p className='pb-3 ml-3 text-small'>
                  {item.answerDetails
                    ? item.answer == 'True'
                      ? 'Yes'
                      : 'No'
                    : item.answer}
                </p>
                {item.answerDetails && (
                  <p className='ml-3 text-small mb-[20px]'>
                    {item.answerDetails}
                  </p>
                )}
              </div>
            )}{' '}
            <div className='flex flex-col flex-1' />
          </div>
        ))}
    </Column>
  )
}
