import { useSelector } from 'react-redux'

import { Card } from 'components/atoms/Card'
import { H5 } from 'components/atoms/Headings'

import { BillingHistoryTable } from './BillingHistoryTable'

export const BillingHistoryCard = () => {
  const { userDetails } = useSelector((state: any) => state.user)

  return (
    <Card className='px-0'>
      <div className='px-5'>
        <H5 title='Billing History' fontWeight='font-SemiBold' />
      </div>
      <BillingHistoryTable tenantId={userDetails.tenantId} />
    </Card>
  )
}
