export const dateToReadableString2 = (inputDateString?: string): string => {
  if (!inputDateString) {
    return 'NA'
  }

  // Create a Date object from the input string
  const inputDate = new Date(inputDateString)

  // Check if the inputDate is valid
  if (isNaN(inputDate.getTime())) {
    return 'NA'
  }

  // Define the options for formatting the date
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  }

  // Format the date
  const formattedDate: string = inputDate.toLocaleDateString('en-US', options)

  return formattedDate
}
