import CN from 'classnames'

type CandidateReferenceCheckSnackProps = {
  status: null | 'Failed' | 'Incomplete' | 'Passed' | 'NotSent'
  state:
    | null
    | 'RESPONSE_RECEVIED'
    | 'AWAITING_RESPONSE'
    | 'REQUEST_PHONE_REF'
    | 'REQUEST_DECLINED'
    | 'NOT_SENT'
}

export const CandidateReferenceCheckSnack = ({
  status,
  state,
}: CandidateReferenceCheckSnackProps) => {
  const isSubmitted = state === 'RESPONSE_RECEVIED' && status === 'Incomplete'

  return (
    <span
      className={CN(
        'flex items-center justify-center py-[2px] pr-[10px] pl-[8px] gap-[6px] border-2 rounded-[10px] h-[20px] box-border',
        {
          'bg-Green-500 border-Green-500': status === 'Passed',
          'bg-Red-500 border-Red-500': status === 'Failed',
          'bg-[#F48602] border-[#F48602]':
            (state === 'AWAITING_RESPONSE' || state === 'REQUEST_PHONE_REF') &&
            status === 'Incomplete',
          'bg-Cobalt-100 border-Cobalt-100': isSubmitted,
          'bg-Gray-500 border-Gray-500':
            status === null || status === 'NotSent',
        },
      )}>
      <i
        className={CN('flex items-center justify-center text-extra-small', {
          'text-white': !isSubmitted,
          'text-Cobalt-800': isSubmitted,
          'ri-checkbox-circle-line': status === 'Passed',
          'ri-close-circle-line': status === 'Failed',
          'ri-indeterminate-circle-line':
            (state === 'AWAITING_RESPONSE' || state === 'REQUEST_PHONE_REF') &&
            status === 'Incomplete',
          'ri-edit-circle-line': isSubmitted,
          'ri-mail-close-line': status === null || status === 'NotSent',
        })}
      />
      <span
        className={CN('text-extra-small font-SemiBold', {
          'text-white': !isSubmitted,
          'text-Cobalt-800': isSubmitted,
        })}>
        {status === null || status === 'NotSent'
          ? 'Not Sent'
          : isSubmitted
          ? 'Submitted'
          : status}
      </span>
    </span>
  )
}
