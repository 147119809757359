import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getAgency } from 'features/agencies/store/selectors'

interface AgencySubdomainValidatorProps {
  subdomainList: readonly string[]
}

const useAgencySubdomainValidator = ({
  subdomainList,
}: AgencySubdomainValidatorProps): boolean => {
  const { agency: agencyDetails }: any = useSelector(getAgency)

  const subDomain = agencyDetails?.subdomain
  return useMemo(() => {
    if (!subDomain || !subdomainList || subdomainList.length === 0) {
      return false
    }

    const normalizedSubdomain = subDomain.toLowerCase().trim()
    const normalizedList = subdomainList.map((str) => str.toLowerCase().trim())

    return normalizedList.includes(normalizedSubdomain)
  }, [subDomain, subdomainList])
}

export default useAgencySubdomainValidator
