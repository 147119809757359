import { useMutation } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { postAPIwithFormData } from 'framework/api/http'

interface sendAssessmentCertificate {
  candidateId: string
  assessmentId: string
  assessmentResponseId: string
  certificateId: string
  issueDate: Date
  certificate: File
}

export const useSendAssessmentCertificate = () => {
  const mutate = useMutation(
    ({
      candidateId,
      assessmentId,
      certificate,
      assessmentResponseId,
      certificateId,
      issueDate,
    }: sendAssessmentCertificate) => {
      const formData = new FormData()
      formData.append('candidateId', candidateId)
      formData.append('assessmentId', assessmentId)
      formData.append('assessmentResponseId', assessmentResponseId)
      formData.append('certificateId', certificateId)
      formData.append('issueDate', issueDate.toISOString())
      formData.append('certificate', certificate)

      return postAPIwithFormData(
        'assessment/candidates/certificate',
        formData,
        {},
      )
    },
    {
      onSuccess() {
        Toast({
          alertHeader: 'Certificate was sent successfully.',
          status: 'Success',
        })
      },
      onError() {
        Toast({
          alertHeader: 'Something went wrong.',
          status: 'Error',
        })
      },
    },
  )

  return mutate
}

export default useSendAssessmentCertificate
