import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button } from '@labourhub/labour-hub-ds'
import { getSubscriptionStore } from 'features/subscriptions'
import { useGetRefereesByCandidate } from 'framework/api/methods'
import { useDynamicFieldMapping } from 'hooks'

import { MoreMenuButton, PageLoader } from 'components/atoms'
import {
  CallRecordingModal,
  RefereeReportViewModal,
  WebRefCheck,
} from 'components/molecules'

import { RequestRefereeModal } from '../CandidateEmployeeProfileCard/CandidateMenuSection/RequestRefereeModal'
import { RequestRefereeReminderModal } from '../CandidateEmployeeProfileCard/CandidateMenuSection/RequestRefereeReminderModal'

import { AddRefereeModal } from './AddRefereeCard/AddRefereeModal'
import { CandidateRefereeCard } from './RefereeCard/RefereeCard'
import { AddRefereeCard } from './AddRefereeCard'
import {
  CandidateRefereeEmptyState,
  refereeModalActionTypes,
} from './CandidateRefereeEmptyState'
import { CandidateReferenceReportDownloadModal } from './CandidateReferenceReportDownloadModal'
import { EditRefereeModal } from './EditRefereeModal'
import { RefereeReferenceReportDownloadModal } from './RefereeReferenceReportDownloadModal'
import { RequestRefereeCommonModal } from './RequestRefereeCommonModal'

type RefereesTabProps = {
  [x: string]: any
}

export const RefereesTab = ({
  candidateId,
  candidateDetails,
  getCandidateById,
  notify,
  requestReferees,
}: RefereesTabProps) => {
  const [showAddRefereeModal, setShowAddRefereeModal] = useState(false)
  const [showEditRefereeModal, setShowEditRefereeModal] = useState(false)
  const [showCallRecordingModal, setShowCallRecordingModal] = useState(false)
  const [showViewReportModal, setShowViewReportModal] = useState(false)
  const [
    showDownlaodReferenceReportModal,
    setShowDownlaodReferenceReportModal,
  ] = useState(false)

  const [selectedRefereeDetails, setSelectedRefereeDetails] = useState<any>()
  const [isWebRefCheckModal, setIsWebRefCheckModal] = useState<any>({
    enable: false,
    selectAll: false,
    selectedRefereeId: '',
  })

  // Request referee modal states
  const [showRefereeRequestModal, setShowRefereeRequestModal] = useState(false)
  const [showRequestRefereeReminderModal, setShowRequestRefereeReminderModal] =
    useState(false)

  // Request Referee modal Details
  const [refereeModalDetails, setRefereeModalDetails] = useState<
    (typeof refereeModalActionTypes)[keyof typeof refereeModalActionTypes]
  >(refereeModalActionTypes.Request)

  /**Fetch referees for the candidate */
  const {
    refetch: getCandidateRefereesList,
    data: candidateReferees,
    isLoading: loadingCandidateReferees,
  } = useGetRefereesByCandidate(candidateId)

  const [initial, setInitial] = useState('')

  useEffect(() => {
    selectedRefereeDetails && getInitial()
  }, [selectedRefereeDetails])

  const { changeJsonSchema } = useDynamicFieldMapping({
    candidateId,
    refereeId: selectedRefereeDetails?.id,
  })

  const [
    isDownloadReferenceReportModalActive,
    setIsDownloadReferenceReportModalActive,
  ] = useState(false)

  const [hasValidDownloadableReferees, setHasValidDownloadableReferees] =
    useState(false)

  const getInitial = () => {
    const title =
      selectedRefereeDetails?.firstName + ' ' + selectedRefereeDetails?.lastName
    const Array: any = title?.split(' ')
    const firstLetter = Array[0]?.charAt(0).toUpperCase()
    const SecondLetter = Array[1]?.charAt(0).toUpperCase()
    setInitial(
      SecondLetter !== undefined ? firstLetter + SecondLetter : firstLetter,
    )
  }

  const { fixedQuotas, payAsYouGoQuotas } = useSelector(getSubscriptionStore)

  const isQuotaNotAvailable =
    fixedQuotas.referenceCheck.remainingQuota +
      payAsYouGoQuotas.ReferenceCheck.remainingQuota ==
    0

  const candidateMainDetails = candidateDetails?.basicCandidateProfile

  const isRefereeCountZero = candidateMainDetails.refereeCount === 0

  const requestSendDate = candidateMainDetails.requestSendDate

  const isReferenceTypesCountZero =
    candidateDetails.basicCandidateProfile.referenceTypes.length === 0

  return (
    <div className='relative referee-tab-view'>
      {loadingCandidateReferees && (
        <PageLoader size='xxs' className='absolute min-h-[50vh]' />
      )}

      <div className='flex flex-col'>
        <div className='flex justify-between pb-8'>
          <span className='font-Medium text-heading-4 text-[#000000]'>
            Referees
          </span>

          {!candidateDetails?.basicCandidateProfile?.isEmployee && (
            <div className='flex gap-2'>
              <Button
                textTheme='blue'
                theme='white'
                isDisabled={!hasValidDownloadableReferees}
                onClick={() => setIsDownloadReferenceReportModalActive(true)}>
                <span className='flex items-center text-small font-Medium'>
                  <i className='flex items-center ri-download-line text-small mr-3' />
                  Generate Reference Report
                </span>
              </Button>
              <Button
                iconLeft={<i className='ri-send-plane-line' />}
                textTheme='blue'
                theme='white'
                isDisabled={
                  (candidateReferees &&
                    candidateReferees?.referees?.length == 0) ||
                  (candidateReferees &&
                    candidateReferees?.referees.filter(
                      (r) => r.state !== 'NOT_SENT',
                    ).length ===
                      candidateDetails?.basicCandidateProfile?.refereeCount)
                }
                onClick={() => {
                  setIsWebRefCheckModal({
                    ...isWebRefCheckModal,
                    selectedRefereeId: '',
                    selectAll: true,
                    enable: true,
                  })
                }}>
                Send Ref Check for All
              </Button>
              <MoreMenuButton
                isBaseIconVertical={true}
                actionButtons={[
                  {
                    onClick: () => {
                      setRefereeModalDetails!(refereeModalActionTypes.Request)
                      setShowRefereeRequestModal(true)
                    },
                    title: 'Request Referee Details',
                    status: 'normal',
                    disabled: requestSendDate !== null || isQuotaNotAvailable,
                  },
                  {
                    onClick: () => setShowRequestRefereeReminderModal(true),
                    title: 'Send Reminder (Request Referees)',
                    status: 'normal',
                    disabled: requestSendDate === null || isQuotaNotAvailable,
                    isBottomSeparator: true,
                  },
                ]}
              />
            </div>
          )}
        </div>
        {!loadingCandidateReferees &&
          (candidateDetails &&
          candidateReferees &&
          candidateReferees?.referees?.length > 0 ? (
            <>
              {candidateReferees?.referees?.map((candidateReferee, index) => {
                return (
                  <div key={index} className='mb-4'>
                    <CandidateRefereeCard
                      key={index}
                      referee={candidateReferee}
                      getCandidateById={getCandidateById}
                      refereeCount={candidateReferees?.referees?.length || 0}
                      getCandidateRefereesList={getCandidateRefereesList}
                      refereeIndex={index + 1}
                      candidate={candidateDetails.basicCandidateProfile}
                      setSelectedRefereeDetails={setSelectedRefereeDetails}
                      onEditClick={() => setShowEditRefereeModal(true)}
                      onCallRecordingsClick={() => {
                        setShowCallRecordingModal(true)
                      }}
                      onClickWebRefCheck={() => {
                        setIsWebRefCheckModal({
                          ...isWebRefCheckModal,
                          selectedRefereeId: candidateReferee?.id,
                          selectAll: false,
                          enable: true,
                        })
                      }}
                      onViewReportClick={() => setShowViewReportModal(true)}
                      setShowDownlaodReferenceReportModal={
                        setShowDownlaodReferenceReportModal
                      }
                    />
                  </div>
                )
              })}

              {!candidateDetails?.basicCandidateProfile.isEmployee && (
                <AddRefereeCard
                  candidateId={candidateId}
                  getCandidateById={getCandidateById}
                  activeRefereeCount={candidateReferees?.referees?.length || 0}
                  setShowAddRefereeModal={setShowAddRefereeModal}
                />
              )}

              {/* ConvertCandidateEmployee Temporary Hide */}
              {/* {candidateDetails?.basicCandidateProfile?.isMarkAsPass !==
                null && (
                <ConvertCandidateEmployee
                  candidateId={candidateId}
                  candidateName={candidateDetails?.basicCandidateProfile.name}
                  getCandidateById={getCandidateById}
                  isEmployee={
                    candidateDetails?.basicCandidateProfile.isEmployee
                  }
                />
              )} */}
            </>
          ) : (
            /** Empty State */
            <>
              {!candidateDetails?.basicCandidateProfile.isEmployee ? (
                <>
                  <CandidateRefereeEmptyState
                    candidateId={candidateId}
                    candidateName={candidateDetails?.basicCandidateProfile.name}
                    candidateDetails={candidateDetails?.basicCandidateProfile}
                    setShowAddRefereeModal={setShowAddRefereeModal}
                    setRefereeModalDetails={setRefereeModalDetails}
                    setShowRefereeRequestModal={setShowRefereeRequestModal}
                    setShowRequestRefereeReminderModal={
                      setShowRequestRefereeReminderModal
                    }
                  />

                  {/* ConvertCandidateEmployee Temporary Hide */}
                  {/* {candidateDetails?.basicCandidateProfile?.isMarkAsPass !==
                    null && (
                    <ConvertCandidateEmployee
                      candidateId={candidateId}
                      candidateName={
                        candidateDetails?.basicCandidateProfile.name
                      }
                      getCandidateById={getCandidateById}
                      isEmployee={
                        candidateDetails?.basicCandidateProfile.isEmployee
                      }
                    />
                  )} */}
                </>
              ) : (
                <CandidateRefereeEmptyState
                  candidateId={candidateId}
                  candidateName={candidateDetails?.basicCandidateProfile.name}
                  candidateDetails={candidateDetails?.basicCandidateProfile}
                  setShowAddRefereeModal={setShowAddRefereeModal}
                />
              )}
            </>
          ))}

        <EditRefereeModal
          isModalActive={showEditRefereeModal}
          setIsModalActive={setShowEditRefereeModal}
          candidateId={candidateId}
          getCandidateRefereesList={getCandidateRefereesList}
          notify={notify}
          getCandidateById={getCandidateById}
          selectedRefereeDetails={selectedRefereeDetails}
          setSelectedRefereeDetails={setSelectedRefereeDetails}
        />
      </div>

      <AddRefereeModal
        isModalActive={showAddRefereeModal}
        setIsModalActive={setShowAddRefereeModal}
        candidateId={candidateId}
        getCandidateRefereesList={getCandidateRefereesList}
        getCandidateById={getCandidateById}
      />

      <CallRecordingModal
        isModalActive={showCallRecordingModal}
        setIsModalActive={setShowCallRecordingModal}
        selectedRefereeDetails={selectedRefereeDetails}
        onRequestRefereeClick={() => requestReferees()}
      />

      {/* web ref check modal  */}
      {!loadingCandidateReferees &&
        candidateReferees &&
        candidateReferees?.referees?.length > 0 && (
          <WebRefCheck
            isOpen={isWebRefCheckModal?.enable}
            setIsWebRefCheckModal={setIsWebRefCheckModal}
            selectedRefereeId={isWebRefCheckModal?.selectedRefereeId}
            selectAll={isWebRefCheckModal?.selectAll}
            candidateId={candidateId}
            refereeList={candidateReferees?.referees}
            notify={notify}
            getCandidateRefereesList={getCandidateRefereesList}
          />
        )}

      {showViewReportModal && (
        <RefereeReportViewModal
          isModalActive={showViewReportModal}
          setIsModalActive={setShowViewReportModal}
          changeJsonSchema={changeJsonSchema}
          getCandidateRefereesList={getCandidateRefereesList}
          refereeIndex={candidateReferees?.referees.findIndex(
            (data) => data.id === selectedRefereeDetails.id,
          )}
          referee={selectedRefereeDetails}
          title={initial}
          selectedReferenceDoneDate={selectedRefereeDetails?.referenceDoneDate}
          selectedReferenceDoneBy={selectedRefereeDetails?.referenceDoneBy}
          setShowDownlaodReferenceReportModal={
            setShowDownlaodReferenceReportModal
          }
          setSelectedRefereeDetails={setSelectedRefereeDetails}
        />
      )}

      {candidateDetails && selectedRefereeDetails && (
        <RefereeReferenceReportDownloadModal
          isModalActive={showDownlaodReferenceReportModal}
          setIsModalActive={setShowDownlaodReferenceReportModal}
          candidateDetails={candidateDetails.basicCandidateProfile}
          referee={selectedRefereeDetails}
        />
      )}

      {/* Download reference report modal */}
      <CandidateReferenceReportDownloadModal
        isModalActive={isDownloadReferenceReportModalActive}
        setIsModalActive={setIsDownloadReferenceReportModalActive}
        candidateId={candidateDetails?.basicCandidateProfile.candidateId}
        candidateDetails={candidateDetails?.basicCandidateProfile}
        setHasValidDownloadableReferees={setHasValidDownloadableReferees}
      />

      {/* Request referees modals */}

      {!isRefereeCountZero && !isReferenceTypesCountZero ? (
        <RequestRefereeModal
          isModalActive={showRefereeRequestModal}
          setIsModalActive={setShowRefereeRequestModal}
          getCandidateById={getCandidateById}
          candidateId={candidateMainDetails?.candidateId}
        />
      ) : (
        <RequestRefereeCommonModal
          isModalActive={showRefereeRequestModal}
          setIsModalActive={setShowRefereeRequestModal}
          getCandidateById={getCandidateById}
          modalDetails={refereeModalDetails}
          candidateDetails={candidateMainDetails}
        />
      )}

      <RequestRefereeReminderModal
        isModalActive={showRequestRefereeReminderModal}
        setIsModalActive={setShowRequestRefereeReminderModal}
        candidateId={candidateMainDetails?.candidateId}
        requestSendDate={candidateMainDetails?.requestSendDate}
        lastSendRequest={candidateMainDetails?.lastSendRequest}
        getCandidateById={getCandidateById}
      />
    </div>
  )
}
