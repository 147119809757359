import { BeautifulMentionsMenuProps } from 'lexical-beautiful-mentions'

export const CustomMenu = ({ ...props }: BeautifulMentionsMenuProps) => {
  return (
    <ul
      className='min-w-[14rem] max-h-[12.1rem] overflow-y-auto styled-scroll m-0 bg-Cobalt-50'
      {...props}
    />
  )
}
