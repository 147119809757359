import { cn } from 'utils'

type ProfileAvatarProps = {
  imageContainerClassName?: string
  imageSource?: string
  imageText?: string
  bgColor?: string
}

export const ProfileAvatar = ({
  imageContainerClassName = 'w-[100px] h-[100px]',
  imageSource,
  imageText = 'BS',
  bgColor,
}: ProfileAvatarProps) => {
  const ImageContainerClasses = cn(
    `flex items-center justify-center rounded-full overflow-hidden border border-Gray-200`,
    imageContainerClassName,
    bgColor,
    {
      'object-contain': imageSource === undefined,
      'bg-Cobalt-500': bgColor === undefined,
      'object-fill': imageSource !== undefined,
    },
  )

  return (
    <div className='relative flex flex-col items-center'>
      <div className={ImageContainerClasses}>
        {imageSource === undefined && imageText && (
          <span className='object-contain text-white text-heading-1 font-Medium'>
            {imageText.toUpperCase()}
          </span>
        )}

        {imageText === undefined && imageSource && (
          <img
            src={imageSource}
            alt=''
            className='flex object-contain w-full h-auto rounded-full'
          />
        )}
      </div>
    </div>
  )
}
