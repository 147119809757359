import { useState } from 'react'
import { Modal, TextField, Toast } from '@labourhub/labour-hub-ds'
import { useUpdateRefereeCount } from 'api/candidates'
import { ModalProps } from 'types'

import { Column } from 'components/atoms/Column'
import { CustomRadio } from 'components/ui/CustomRadio'

type AddRefereeSelectionModalProps = ModalProps & {
  candidateId: string
  getCandidateById: any
  setShowAddRefereeModal: any
  activeRefereeCount: number
}

export const AddRefereeSelectionModal = ({
  candidateId,
  isModalActive,
  setIsModalActive,
  getCandidateById,
  activeRefereeCount,
  setShowAddRefereeModal,
}: AddRefereeSelectionModalProps) => {
  const notify = (props: any) => Toast(props)

  const [renderKeys, setRenderKeys] = useState<any>({
    refereeDetails: 0,
  })

  const [selectedOption, setSelectedOption] = useState('')
  const [refereeCount, setRefereeCount] = useState(0)

  const handleAddReferee = () => {
    if (selectedOption == '') {
      notify({
        alertBody: 'Please select at least one referee option',
        status: 'Warning',
      })
      return
    }

    if (selectedOption == 'add') {
      setShowAddRefereeModal(true)
      closeModal()
      return
    }

    if (selectedOption == 'request') {
      if (refereeCount == 0) {
        notify({
          alertBody: 'Please select a referee count',
          status: 'Warning',
        })
        return
      }

      addAddtionalReferee()
      return
    }
  }

  const { mutate, isLoading } = useUpdateRefereeCount()

  /** Process the add new candidate */
  const addAddtionalReferee = () => {
    mutate(
      {
        candidateId,
        existingCount: activeRefereeCount,
        additionalCount: refereeCount,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify({
            alertHeader: successData?.message,
            alertBody: '',
            status: 'Success',
          })
          closeModal()
          getCandidateById()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  const closeModal = () => {
    setSelectedOption('')
    setRefereeCount(0)
    setRenderKeys({
      ...renderKeys,
      refereeDetails: Math.random(),
    })
    setIsModalActive(false)
  }

  return (
    <Modal
      isActive={isModalActive}
      className='w-full'
      headerTitle='Add Referee'
      primaryButtonTitle='Done'
      secondaryButtonTitle='Cancel'
      primaryButtonProps={{
        isLoading,
        style: { marginLeft: '12px' },
      }}
      secondaryButtonProps={{
        style: { color: '#1F2937' },
      }}
      modalProps={{
        style: { width: '600px', maxHeight: '90vh' },
      }}
      footerProps={{
        style: {
          justifyContent: 'end',
          paddingLeft: '23px',
          paddingRight: '23px',
          flexDirection: 'row-reverse',
        },
      }}
      isHeaderShow={true}
      isFooterShow={true}
      isSeparator={true}
      onHeaderCloseButtonClick={() => {
        closeModal()
      }}
      onClickPrimaryBtn={() => {
        handleAddReferee()
      }}
      onClickSecondaryBtn={() => {
        closeModal()
      }}>
      {/* {addRefereeIsLoading && <PageLoader size='xxs' />} */}

      <div className='w-full pl-12 py-5 my-4'>
        <span className='text-small font-Medium'>Please choose an option:</span>
        <Column className='my-4'>
          <div key={renderKeys.refereeDetails} className='ml-1 space-y-5'>
            <CustomRadio
              id='request'
              label='Send request email to candidate asking referee details'
              helperText='Add referee details by updating the number of referees required for the candidate'
              name='refereeDetails'
              onChange={(selectedItem: any) => {
                if (selectedItem.target.checked) {
                  setSelectedOption(selectedItem.target.id)
                }
              }}
              value={''}
            />
            {selectedOption === 'request' && (
              <Column>
                <TextField
                  className='ml-5 mr-12'
                  type='number'
                  value={refereeCount}
                  onChange={(selectedCount: any) => {
                    if (selectedCount.target.value > 0) {
                      setRefereeCount(selectedCount.target.value)
                    }
                  }}
                  placeholder='Enter Referee Count'
                />
              </Column>
            )}

            <CustomRadio
              id='add'
              label='Add referee details manually '
              helperText='Add the contact information of 1 more referee manually'
              name='refereeDetails'
              onChange={(selectedItem: any) => {
                setSelectedOption(selectedItem.target.id)
              }}
            />
          </div>
        </Column>
      </div>
    </Modal>
  )
}
