/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, TextField, Toast } from '@labourhub/labour-hub-ds'
import {
  useOnboardingResendOTP,
  useOnboardingVerifyAccount,
} from 'api/onboarding'
import { setCurrentStep, setToken } from 'features/onboarding/store'
import { getOnboardingStore } from 'features/onboarding/store/selectors'

export const VerifyAccountSection = () => {
  const dispatch = useDispatch()
  const [verificationCode, setVerificationCode] = useState('')
  const { currentStep, tenantId, createAccount } =
    useSelector(getOnboardingStore)

  const onNextStepClick = () => {
    onboardingVerifyAccount()
  }

  /** verify account on success */
  const onSuccessVerifyAccount = (data: any) => {
    dispatch(setCurrentStep(currentStep + 1))
    dispatch(setToken(data?.token))
    sessionStorage.setItem('onboardingToken', data?.token)
  }

  /** verify account on error */
  const onErrorVerifyAccount = ({ response: { data: errData } }: any) => {
    Toast({
      alertHeader: 'Error...!',
      alertBody: errData?.message,
      status: 'Error',
    })
  }

  /** API Handling request for Get All Uniform List */
  const {
    refetch: onboardingVerifyAccount,
    isLoading: onboardingVerifyAccountIsLoading,
    isFetching: onboardingVerifyAccountIsFetching,
  } = useOnboardingVerifyAccount(
    {
      tenantId: tenantId,
      otp: verificationCode,
    },
    onSuccessVerifyAccount,
    onErrorVerifyAccount,
  )

  /** resend otp on success */
  const onSuccessResendOTP = (data: any) => {
    Toast({
      alertHeader: data?.message,
      status: 'Success',
    })
  }

  /** resend otp on error */
  const onErrorResendOTP = ({ response: { data: errData } }: any) => {
    Toast({
      alertHeader: 'Error...!',
      alertBody: errData?.message,
      status: 'Error',
    })
  }

  /** API Handling request for Get All Uniform List */
  const { refetch: onboardingResendOTP } = useOnboardingResendOTP(
    {
      tenantId: tenantId,
    },
    onSuccessResendOTP,
    onErrorResendOTP,
  )

  return (
    <div className='flex flex-col items-start justify-center w-7/12'>
      <div className='w-full text-left text-heading-2 font-Medium text-Gray-800'>
        Verify Your Account
      </div>

      <TextField
        type='text'
        label='Verification Code'
        placeholder=''
        className='w-full pt-5'
        value={verificationCode}
        onChange={(e: any) => {
          setVerificationCode(e.target.value)
        }}
      />

      <div className='w-full text-left mt-5 text-extra-small tracking-[0.25px]'>
        {`We have sent a verification code to your phone number ${createAccount?.phoneNumber}`}
        <span
          onClick={() => {
            onboardingResendOTP()
          }}
          className='mx-1 cursor-pointer text-Cobalt-600 font-Medium hover:text-Cobalt-400'>
          Resend here
        </span>
      </div>

      <Button
        isBlock
        isDisabled={!verificationCode}
        isLoading={
          onboardingVerifyAccountIsLoading || onboardingVerifyAccountIsFetching
        }
        onClick={() => onNextStepClick()}
        className='mt-5 next-btn'>
        Next
      </Button>
    </div>
  )
}
