import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Avatar,
  Button,
  Modal,
  SimpleSelect,
  Toast,
} from '@labourhub/labour-hub-ds'
import JSONForm from '@rjsf/core'
import { CandidateProfileBasicDetailsType } from 'api/candidates'
import {
  QuestionnaireCategoryType,
  useGetDynamicForm,
  useGetQuestionnaireTemplateList,
} from 'api/questionnaires'
import CN from 'classnames'
import {
  CandidateReferee,
  useAddRefCheck,
  useDeclineRefCheck,
} from 'framework/api/methods'
import { useDynamicFieldMapping } from 'hooks'
import { ReferenceCheckType } from 'types'

import { PageLoader, TextEditor } from 'components/atoms'
import { FormBuilder } from 'components/molecules'

type PhoneRefCheckProps = {
  referee: CandidateReferee
  candidate: CandidateProfileBasicDetailsType
  isOpen: boolean
  hideModal: () => void
  getCandidateRefereesList: () => void
  refreshCandidateProfileData: any
  [x: string]: any
}

export const PhoneRefCheck = ({
  className,
  candidate,
  referee,
  isOpen,
  hideModal,
  getCandidateRefereesList,
  refreshCandidateProfileData,
  ...restProps
}: PhoneRefCheckProps) => {
  const PhoneRefCheckClasses = CN(`phone-ref-check`, className)

  const { changeJsonSchema } = useDynamicFieldMapping({
    candidateId: referee.candidateId,
    refereeId: referee.id,
  })

  const [blankTemplateContent, setBlankTemplateContent] = useState('')
  const [selectedTemplate, setSelectedTemplate] = useState({
    value: 'Blank',
    label: 'Blank',
  })
  const [isOpenRefCheckDecline, setIsOpenRefereeDecline] = useState(false)
  const [
    isShowNewTemplateNavigationConfirmationModal,
    setIsShowNewTemplateNavigationConfirmationModal,
  ] = useState(false)
  const [isFormTouched, setIsFormTouched] = useState(false)

  const navigate = useNavigate()
  const dynamicFormRef = useRef<JSONForm<any, any> | null>(null)
  const notify = (props: any) => Toast(props)

  // Adding Phone ref check
  const { mutate: addRefCheck, isLoading: isSavingRefCheck } = useAddRefCheck()

  // Fetching dynamic data for the template
  const { data: dynamicForm, isLoading: isLoadingDynamicForm } =
    useGetDynamicForm(
      selectedTemplate.value !== 'Blank' ? selectedTemplate.value : '',
    )

  // Fetching Questionnaire list
  const { data: questionnaireTemplates } = useGetQuestionnaireTemplateList()

  const { mutateAsync: declineRefCheck, isLoading: isLoadingDeclineRefCheck } =
    useDeclineRefCheck(candidate.candidateId, referee.id)

  // Adding check by template
  const addTemplatedFormCheck = (jsonData: string) => {
    addRefCheck(
      {
        refereeId: referee.id,
        htmlResponse: blankTemplateContent,
        responseType: 'PHONE_CHECK',
        templateId:
          selectedTemplate.value !== 'Blank' ? selectedTemplate.value : null,
        jsonData,
      },
      {
        onSuccess: () => {
          hideModal()
          getCandidateRefereesList()
          refreshCandidateProfileData()
        },
        onError: () => {
          notify({
            alertHeader: 'Error...!',
            alertBody: `Unable to save phone reference check response`,
            status: 'Error',
          })
        },
      },
    )
  }

  const saveCustomResponse = () => {
    addRefCheck(
      {
        refereeId: referee.id,
        htmlResponse: blankTemplateContent,
        responseType: 'PHONE_CHECK',
        templateId: null,
        jsonData: '',
      },
      {
        onSuccess: () => {
          hideModal()
          getCandidateRefereesList()
          refreshCandidateProfileData()
        },
        onError: () => {
          notify({
            alertHeader: 'Error...!',
            alertBody: `Unable to save phone reference check response`,
            status: 'Error',
          })
        },
      },
    )
  }

  return (
    <div className={PhoneRefCheckClasses} {...restProps}>
      {/* Decline ref check modal */}
      <Modal
        isActive={isOpenRefCheckDecline}
        onClickPrimaryBtn={() => {
          declineRefCheck().finally(() => {
            setIsOpenRefereeDecline(false)
            getCandidateRefereesList()
            hideModal()
          })
        }}
        onClickSecondaryBtn={() => {
          setIsOpenRefereeDecline(false)
        }}
        primaryButtonProps={{
          style: { width: '100%', marginRight: '12px' },
          isLoading: isLoadingDeclineRefCheck,
        }}
        secondaryButtonProps={{
          style: { width: '100%', color: '#1F2937' },
        }}
        onOverlayClick={() => {
          setIsOpenRefereeDecline(false)
        }}
        modalProps={{
          style: { width: '466px', zIndex: 51 },
        }}
        footerProps={{
          style: {
            justifyContent: 'space-between',
            paddingLeft: '23px',
            paddingRight: '23px',
          },
        }}
        isHeaderShow={false}
        primaryButtonTitle='Confirm'
        secondaryButtonTitle='Close'>
        <div className='flex flex-col items-center justify-center p-6'>
          <div className='flex items-center justify-center w-12 h-12 rounded-full bg-Red-100'>
            <i className='ri-delete-bin-6-line text-Red-500 text-heading-3'></i>
          </div>

          <span className='pt-5 text-Gray-900 text-heading-5 font-Medium'>
            Referee declined the ref check?
          </span>

          <span className='pt-2 text-center text-Gray-500 text-small font-Regular'>
            This means ref check status for this referee will be Failed. Are you
            sure to continue?
          </span>
        </div>
      </Modal>
      <Modal
        headerTitle='Phone Ref Check'
        customWidth='90%'
        customZIndex={10}
        isActive={isOpen}
        onHeaderCloseButtonClick={() => {
          hideModal()
          setSelectedTemplate({
            value: 'Blank',
            label: 'Blank',
          })
        }}
        isFooterShow={false}
        modalProps={{
          style: { maxHeight: '95vh' },
        }}>
        <div className='flex flex-col w-full p-8 overflow-y-auto styled-scroll'>
          <div className='flex w-full pb-5 mb-6 x'>
            <div className='flex flex-col w-[340px] gap-6 mr-4'>
              {/* Referee details card */}
              <div className='max-h-[380px] overflow-y-auto styled-scroll'>
                <div className='w-[340px]'>
                  <div className='box-border flex gap-4 p-4 border rounded-lg bg-Gray-50 border-Gray-200'>
                    <div className='relative flex-none w-min-[344px]'>
                      <Avatar
                        isHeader={false}
                        title={referee.firstName + ' ' + referee.lastName}
                        color={'four'}
                      />
                    </div>
                    <div className='w-fit'>
                      <div className='flex items-start flex-column'>
                        <span className='mr-2 break-all text-heading-5 text-Gray-800 font-Medium'>
                          {referee.firstName + ' ' + referee.lastName}
                        </span>
                        <span className='flex items-center mt-[5px] bg-Gray-200 px-[10px] py-[2px] rounded-[10px] h-[20px] gap-[6px]'>
                          <span className='text-extra-small font-Medium text-Gray-800'>
                            Referee
                          </span>
                        </span>
                      </div>
                      <div className='flex w-full mt-2'>
                        <div className='flex flex-col flex-auto gap-2 mr-8'>
                          <div className='flex items-center'>
                            <i className='flex items-center ri-phone-line text-[18px] text-Cobalt-600 mr-2' />
                            <span className='text-small font-Medium text-Gray-600'>
                              {referee.phone}
                            </span>
                          </div>
                          <div className='flex items-start'>
                            <i className='flex items-center ri-mail-line text-[18px] leading-[20px] text-Cobalt-600 mr-2' />
                            <span className='break-all text-small font-Medium text-Gray-600'>
                              {referee.email}
                            </span>
                          </div>
                          {referee?.referenceType?.type !=
                            ReferenceCheckType.FriendFamily && (
                            <div className='flex items-center'>
                              <i className='flex items-center ri-suitcase-line text-[18px] text-Cobalt-600 mr-2' />
                              <span className='text-small font-Medium text-Gray-600'>
                                {referee.jobTitle} at {referee.company}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Candidate details card */}
                <div className='w-[340px] my-1'>
                  <div className='box-border flex gap-4 p-4 border rounded-lg bg-Gray-50 border-Gray-200'>
                    <div className='relative flex-none w-min-[344px]'>
                      <Avatar
                        isHeader={false}
                        title={candidate.name}
                        color={'four'}
                        imageURL={candidate.avatarUrl}
                      />
                    </div>
                    <div className='w-fit'>
                      <div className='flex items-start flex-column'>
                        <span className='mr-2 break-all text-heading-5 text-Gray-800 font-Medium'>
                          {candidate.name}
                        </span>
                        <span className='flex items-center mt-[5px] bg-Gray-200 px-[10px] py-[2px] rounded-[10px] h-[20px] gap-[6px]'>
                          <span className='text-extra-small font-Medium text-Gray-800'>
                            Candidate
                          </span>
                        </span>
                      </div>

                      <div className='flex w-full mt-2'>
                        <div className='flex flex-col flex-auto gap-2 mr-8'>
                          <div className='flex items-start'>
                            <i className='flex items-center ri-phone-line text-[18px] leading-[20px] text-Cobalt-600 mr-2' />
                            <span className='break-all text-small font-Medium text-Gray-600'>
                              {candidate.phone}
                            </span>
                          </div>
                          <div className='flex items-start'>
                            <i className='flex items-center ri-mail-line text-[18px]  leading-[20px] text-Cobalt-600 mr-2' />
                            <span className='break-all text-small font-Medium text-Gray-600'>
                              {candidate.email}
                            </span>
                          </div>
                          <div className='flex items-start'>
                            <i className='flex items-center ri-suitcase-line text-[18px] leading-[20px] text-Cobalt-600 mr-2' />
                            <span className='break-all text-small font-Medium text-Gray-600'>
                              {candidate.jobTitle}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <SimpleSelect
                label='Choose Template'
                options={[
                  { value: 'Blank', label: 'Blank' },
                  ...(questionnaireTemplates?.map((template) => ({
                    value: template.id,
                    label: template.name,
                  })) || []),
                ]}
                value={selectedTemplate}
                maxMenuHeight={150}
                onChange={(selectedItem: any) => {
                  setSelectedTemplate(selectedItem)
                }}
              />
              <div className='flex items-center'>
                <hr className='flex-auto text-Gray-300' />
                <span className='mx-2 text-small font-Medium text-Gray-400'>
                  OR
                </span>
                <hr className='flex-auto text-Gray-300' />
              </div>
              <Button
                textTheme={'blue'}
                theme='white'
                onClick={() => {
                  setIsShowNewTemplateNavigationConfirmationModal(true)
                }}>
                Create New Template
              </Button>
            </div>
            <div className='w-[680px] h-[525px] overflow-y-auto styled-scroll'>
              {selectedTemplate.value === 'Blank' && (
                <div className='h-[460px] w-full'>
                  <TextEditor
                    onContentChange={(content) => {
                      setBlankTemplateContent(content)
                    }}
                  />
                </div>
              )}
              {selectedTemplate.value !== 'Blank' &&
                !isLoadingDynamicForm &&
                dynamicForm && (
                  <div className='flex flex-col flex-auto justify-start p-5 min-h-[30vh] overflow-y-auto styled-scroll'>
                    <FormBuilder
                      ref={dynamicFormRef}
                      uiSchema={JSON.parse(dynamicForm.jsonUiSchema)}
                      schema={changeJsonSchema(
                        JSON.parse(dynamicForm.jsonSchema),
                      )}
                      onSubmit={(data) => {
                        addTemplatedFormCheck(JSON.stringify(data?.formData))
                      }}
                      onFocus={() => {
                        setIsFormTouched(true)
                      }}
                    />
                  </div>
                )}
              {isLoadingDynamicForm && <PageLoader size='xxs' />}
            </div>
          </div>

          {/* Bottom Custom Footer */}
          <div className='fixed bottom-0 left-0 flex justify-between w-full py-3 bg-Cobalt-50'>
            <Button
              textTheme='terracotta'
              className={'ml-6'}
              theme='white'
              onClick={() => {
                setIsOpenRefereeDecline(true)
              }}>
              Decline Ref Check
            </Button>
            <div className='flex mr-6'>
              <Button
                theme={'cobalt'}
                className='mr-2'
                isLoading={isSavingRefCheck}
                isDisabled={
                  selectedTemplate.value === 'Blank'
                    ? blankTemplateContent == ''
                    : !isFormTouched
                }
                onClick={() => {
                  if (selectedTemplate.value === 'Blank') {
                    saveCustomResponse()
                  } else {
                    if (dynamicFormRef.current?.submit) {
                      dynamicFormRef?.current?.submit()
                    }
                  }
                }}>
                Save Ref Check
              </Button>
              <Button
                textTheme='blue'
                theme='white'
                onClick={() => {
                  hideModal()
                  setSelectedTemplate({
                    value: 'Blank',
                    label: 'Blank',
                  })
                }}>
                Close
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isActive={isShowNewTemplateNavigationConfirmationModal}
        onClickPrimaryBtn={() => {
          navigate(
            `/questionnaires/new?buildMode=${QuestionnaireCategoryType.ReferenceCheck}`,
          )
        }}
        onClickSecondaryBtn={() => {
          setIsShowNewTemplateNavigationConfirmationModal(false)
        }}
        primaryButtonProps={{
          style: { width: '100%', marginRight: '12px' },
        }}
        secondaryButtonProps={{
          style: { width: '100%', color: '#1F2937' },
        }}
        onOverlayClick={() => {
          setIsShowNewTemplateNavigationConfirmationModal(false)
        }}
        modalProps={{
          style: { width: '466px' },
        }}
        footerProps={{
          style: {
            justifyContent: 'space-between',
            paddingLeft: '23px',
            paddingRight: '23px',
          },
        }}
        isHeaderShow={false}
        primaryButtonTitle='Yes, I’m Sure'
        secondaryButtonTitle='Cancel'>
        <div className='flex flex-col items-center justify-center p-6'>
          <div className='flex items-center justify-center w-12 h-12 rounded-full bg-Yellow-50'>
            <i className='ri-error-warning-line text-Yellow-500 text-heading-3'></i>
          </div>

          <span className='pt-5 text-Gray-900 text-heading-5 font-Medium'>
            Warning
          </span>

          <span className='pt-2 text-center text-Gray-500 text-small font-Regular'>
            Please note you will be directed to the create questionnaire page
            and the content in this page will not be saved.
          </span>
        </div>
      </Modal>
    </div>
  )
}
