import { cn } from 'utils'

export default function InputHistory({
  typedHistory,
  wordList,
  isReport = false,
}: {
  typedHistory: string[]
  wordList: string[]
  isReport?: boolean
}) {
  return (
    <div
      className={cn(
        'h-fit my-6 mx-auto flex flex-col bg-white border border-Gray-200 hover:bg-Gray-100 break-inside-avoid',
        {
          'w-full': !isReport,
          'w-[751px] m-4': isReport,
        },
      )}>
      <h1
        className={cn(
          'py-3 px-5 bg-Cobalt-50 font-SemiBold tracking-tight text-Gray-900',
          {
            'text-[20px] leading-5': !isReport,
            'text-small': isReport,
          },
        )}>
        Input History
      </h1>
      <p
        className={cn('font-Medium px-5 mt-5 h-fit', {
          'text-heading-4': !isReport,
          'text-small': !isReport,
        })}>
        {wordList.map((word, i) => {
          const typedWord = typedHistory[i] // Get the corresponding word from typedHistory
          let textColor = 'Gray'
          const isWordMatch = word === typedWord // lowercase only

          if (typedWord && isWordMatch) {
            textColor = 'black'
          } else if (typedWord) {
            const wordCharArray = word.split('')
            const typedWordCharArray = typedWord.split('')
            const charSpans = wordCharArray.map((char, j) => {
              const typedChar = typedWordCharArray[j] // Get the corresponding character from typedHistory
              const isCharMatch = char === typedChar
              const charColor = typedChar
                ? isCharMatch
                  ? 'black'
                  : 'red'
                : 'Gray' // Determine character color
              return (
                <span
                  style={{ color: charColor }}
                  key={`${typedWord}${char}${i}${j}`}>
                  {char}
                </span>
              )
            })
            return <span key={`${typedWord}${i}`}>{charSpans} </span>
          }
          return (
            <span
              style={{ color: textColor }}
              key={`${typedWord}${i}`}>{` ${word} `}</span>
          )
        })}
      </p>
    </div>
  )
}
