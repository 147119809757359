import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Modal, Toast } from '@labourhub/labour-hub-ds'
import { CandidateProfileBasicDetailsType } from 'api/candidates'
import { QuestionnaireCategoryType } from 'api/questionnaires'
import { TemplateCustomizeSurveyModal } from 'features/questionnaires/components/QuestionnaireTabContent/QuestionnaireSearchHeader/TemplateCustomizeModal/TemplateCustomizeSurveyModal'
import { setAIQuestionData } from 'features/questionnaires/store/actions'
import { ModalProps } from 'types'

import { RadioGroup } from 'components/core/RadioGroup'

type BuildNewTemplateModalProps = ModalProps & {
  getCandidateById: () => void
  candidateDetails: CandidateProfileBasicDetailsType
}

export const BuildNewTemplateModal = ({
  isModalActive,
  setIsModalActive,
  getCandidateById,
  candidateDetails,
}: BuildNewTemplateModalProps) => {
  const { isEmployee } = candidateDetails

  const [
    isTemplateCustomizeCandidateModalOpen,
    setIsTemplateCustomizeCandidateModalOpen,
  ] = useState(false)
  const [renderKey, setRenderKey] = useState<any>({ surveyType: 0, mode: 0 })

  const [templateType, setTemplateType] = useState('')
  const [surveyType, setSurveyType] = useState<string>(
    isEmployee
      ? QuestionnaireCategoryType.EmployeeSurvey
      : QuestionnaireCategoryType.CandidateSurvey,
  )
  const notify = (props: any) => Toast(props)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    setRenderKey({ surveyType: Math.random(), mode: Math.random() })
    //   getCandidateById()
  }, [candidateDetails, getCandidateById])

  const handleClose = () => {
    setTemplateType('')
    setIsModalActive(false)
    setRenderKey({ surveyType: Math.random(), mode: Math.random() })
  }

  const handleCreate = () => {
    // Check if a radio button is selected
    if (!templateType) {
      notify({
        alertHeader: 'Please select one option',
        status: 'Warning',
      })
      return
    }

    if (templateType === 'aiTemplate') {
      setIsTemplateCustomizeCandidateModalOpen(true)
      handleClose()
    } else if (templateType === 'customTemplate') {
      dispatch(setAIQuestionData(null))
      handleClose()
      navigate(
        `/questionnaires/new?buildMode=${
          isEmployee
            ? QuestionnaireCategoryType.EmployeeSurvey
            : QuestionnaireCategoryType.CandidateSurvey
        }`,
      )
    } else {
      // No radio button is selected, do not process
      return
    }
  }

  return (
    <>
      <Modal
        isActive={isModalActive}
        onOverlayClick={handleClose}
        modalProps={{
          style: { width: '450px' },
        }}
        onClickPrimaryBtn={handleCreate}
        onClickSecondaryBtn={handleClose}
        onHeaderCloseButtonClick={handleClose}
        primaryButtonProps={{ style: { marginLeft: '12px' } }}
        secondaryButtonProps={{ textTheme: 'black' }}
        footerProps={{
          style: {
            justifyContent: 'flex-start',
            flexDirection: 'row-reverse',
          },
        }}
        headerTitle='Select Template Type'
        primaryButtonTitle='Create'
        secondaryButtonTitle='Cancel'>
        <div className='px-3 py-4'>
          <>
            <div className='text-Gray-500'>Who is this survey for?</div>
            <div
              key={renderKey.surveyType}
              className='flex items-center my-4 space-x-6'>
              <RadioGroup
                direction='horizontal'
                options={[
                  {
                    label: 'Candidate',
                    value: QuestionnaireCategoryType.CandidateSurvey,
                    disabled: isEmployee,
                  },
                  {
                    label: 'Employee',
                    value: QuestionnaireCategoryType.EmployeeSurvey,
                    disabled: !isEmployee,
                  },
                ]}
                name='surveyType'
                selectedValue={
                  isEmployee
                    ? QuestionnaireCategoryType.EmployeeSurvey
                    : QuestionnaireCategoryType.CandidateSurvey
                }
                onChange={(value) => setSurveyType(value)}
              />
            </div>
          </>

          <div className='text-Gray-500'>
            Please choose the specific template type you wish to create.
          </div>

          <div key={renderKey.mode} className='mt-4 space-y-4'>
            <RadioGroup
              name='templateType'
              options={[
                {
                  label: 'Generate AI Template',
                  value: 'aiTemplate',
                },
                {
                  label: 'Create Custom Template',
                  value: 'customTemplate',
                },
              ]}
              selectedValue={templateType}
              onChange={(value) => setTemplateType(value)}
            />
          </div>
        </div>
      </Modal>

      <TemplateCustomizeSurveyModal
        isModalActive={isTemplateCustomizeCandidateModalOpen}
        setIsModalActive={setIsTemplateCustomizeCandidateModalOpen}
        surveyType={surveyType}
      />
    </>
  )
}
