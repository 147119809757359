import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

export type GenerateQuestionnairesSurveyTemplateRequest = {
  surveyPurpose: string
  surveyFor: string
  noOfQuestions: number
  description: string
  competencies: string[]
}

export const useGenerateQuestionnairesSurveyTemplate = () => {
  const mutate = useMutation(
    (request: GenerateQuestionnairesSurveyTemplateRequest) => {
      return postAPI('/AI/VertexAI/GenerateAiSurveyQuestions', { request })
    },
  )
  return mutate
}
