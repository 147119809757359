import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'

export type QuestionnaireTemplateType = {
  id: string
  createdOn: string
  updatedOn: string
  name: string
  type: string
  isActive: true
  questionCount: number
}

export const useGetQuestionnaireTemplateList = (isEnabled = true) => {
  return useQuery(
    ['GET_QUESTIONNAIRE_TEMPLATE_LIST'],
    async () => {
      const res = await get(`Questionnaire?IsActive=true`)
      return res.data.questionnaires as QuestionnaireTemplateType[]
    },
    {
      enabled: isEnabled,
      refetchOnWindowFocus: false,
      onError: () => {
        Toast({
          alertHeader: 'Error fetching template list',
          status: 'Error',
        })
      },
    },
  )
}
