import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetEmailValidationInterface {
  userEmail?: any
}

export const useGetEmailValidation = () => {
  const mutate = useMutation(({ userEmail }: GetEmailValidationInterface) => {
    return get(`/Identity/Username?UserName=${userEmail}`)
  })

  return mutate
}

export default useGetEmailValidation
