import { useSelector } from 'react-redux'
import { Badge } from '@labourhub/labour-hub-ds'
import { QuestionnaireCategoryType } from 'api/questionnaires'
import { getReportSettings } from 'features/settings'

import { Column } from 'components/atoms/Column'
import { Row } from 'components/atoms/Row'

import { ReportDetailColumns } from '../ReportDetailColumns'

type SurveyResponseReportCandidateDetailsProps = {
  details: SurveyResponseReportCandidateDetailsDto
}

export type SurveyResponseReportCandidateDetailsDto = {
  name: string
  candidateNo: string
  email: string
  phoneNumber: string
  avatarUrl?: string
  jobTitle?: string
  surveyName: string
  surveyType: string
  completedOn: string
}

export const SurveyResponseReportCandidateDetails = ({
  details,
}: SurveyResponseReportCandidateDetailsProps) => {
  const { textColour } = useSelector(getReportSettings)

  return (
    <Column className='px-6 py-4 mt-4'>
      <Row className='w-full h-auto space-x-4'>
        {details.avatarUrl && (
          <img
            src={details.avatarUrl}
            alt=''
            className='object-fill rounded-full w-[62px] h-[62px] overflow-hidden '
          />
        )}
        <div className='max-w-[650px]'>
          <p className='text-small text-Gray-500 font-Bold'>
            {details.surveyName.toUpperCase()} OF
          </p>
          <p
            className='leading-6 uppercase text-heading-3 font-Bold'
            style={{ color: textColour }}>
            {details.name}
          </p>
          {details.jobTitle &&
            details.surveyType ===
              QuestionnaireCategoryType.CandidateSurvey && (
              <p className='text-base font-Medium text-Gray-600'>
                Applying for role of {details.jobTitle}
              </p>
            )}
        </div>
      </Row>

      <div className='py-4 mt-5'>
        <ReportDetailColumns
          title='Candidate Details'
          leftDetails={[
            { label: 'ID No', value: details.candidateNo, type: 'text' },
            { label: 'Phone', value: details.phoneNumber, type: 'link' },
            {
              label: 'Type',
              component: (
                <>
                  {details.surveyType ===
                  QuestionnaireCategoryType.EmployeeSurvey ? (
                    <Badge
                      size='small'
                      theme='purple'
                      type='basic'
                      isIcon={true}
                      icon='ri-user-follow-line'>
                      Employee
                    </Badge>
                  ) : (
                    <Badge
                      size='small'
                      theme='primary'
                      type='basic'
                      isIcon={true}
                      icon='ri-user-line'>
                      Candidate
                    </Badge>
                  )}
                </>
              ),
              type: 'component',
            },
          ]}
          rightDetails={[
            { label: 'Email', value: details.email, type: 'link' },
            {
              label: 'Completed on',
              value: details.completedOn,
              type: 'text',
            },
          ]}
        />
      </div>
    </Column>
  )
}
