import { useQuery } from 'react-query'
import { Toast } from '@labourhub/labour-hub-ds'
import { get } from 'framework/api/http'
import { ReportQuestion } from 'framework/api/methods'
import { QueryParams } from 'types/queryParams'

type ExecutiveSummaryListDto = {
  summary: ExecutiveSummaryDto
  totalCount: number
  take: number
  skip: number
}

export type ExecutiveSummaryDto = {
  id: string
  reportNumber: number
  refereeCount: number
  refereeType: string
  summary: string
  lastDownloadedOn: string
  type: string
  reportType: string
  createdBy: string
  clientLogo?: string
  isClientLogoOnly?: boolean
  refereeStatsCount: ReportRefereeStatsCountDto
  candidateSentiment: ReportCandidateSentimentDto
  referees: ReportRefereeDto[]
}

export type ReportRefereeStatsCountDto = {
  refereesProvided: number
  responseReceived: number
  declinedReference: number
}

export type ReportCandidateSentimentDto = {
  positiveSentiment: number
  negativeSentiment: number
  neutralSentiment: number
}

export type ReportRefereeDto = {
  id: string
  firstName: string
  lastName: string
  phone: string
  countryCode: string
  email: string
  company: string
  jobTitle: string
  startDate: string
  endDate: string
  status: string | null
  isCurrentlyWorking: boolean
  referenceType: ReferenceType
  refereeResponse: RefereeResponse
}

type ReferenceType = {
  type: string
}

type RefereeResponse = {
  responseType: string
  fraudDetectedDate: any
  reportQuestions: ReportQuestion[]
  updatedOn: string
}

type ExecutiveSummaryListQueryParams = QueryParams & {
  candidateId: string
}

const getExecutiveSummaryList = async ({ queryKey }: any) => {
  const { candidateId, take, skip, sortColumnName, sortDirection } =
    queryKey[1] as ExecutiveSummaryListQueryParams

  let URL = `/ExecutiveSummary/List?candidateId=${candidateId}&take=${take}&skip=${skip}`

  if (sortColumnName) {
    URL += `&sortColumnName=${sortColumnName}`
  }

  if (sortDirection) {
    URL += `&sortDirection=${sortDirection}`
  }

  const res = await get(URL)
  return res.data
}

export const useGetExecutiveSummaryList = (
  queryParams: ExecutiveSummaryListQueryParams,
  onSuccess: (data: ExecutiveSummaryListDto) => void,
) => {
  return useQuery(
    ['ExecutiveSummaryList', queryParams],
    getExecutiveSummaryList,
    {
      enabled: true,
      refetchOnWindowFocus: false,
      onSuccess,
      onError: () => {
        Toast({
          alertHeader: 'Error fetching executive summary list',
          status: 'Error',
        })
      },
    },
  )
}
