import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  SimpleSelect,
  TextField,
  Toast,
} from '@labourhub/labour-hub-ds'
import { useGenerateAssessmenTemplate } from 'api/ai/generateAssessmentTemplate'
import { difficultyList } from 'features/questionnaires/data/assessmentDifficultyLevels'
import { setAIAssessmentQuestionDataState } from 'features/questionnaires/store/reducer'
import { ModalProps } from 'types'

import { PercentageLoader } from 'components/atoms/PercentageLoader'
import { MultiLableTextArea } from 'components/common/MultiLableTextArea'
import { Modal, ModalFooter } from 'components/molecules'

type TemplateCustomizeModalProps = ModalProps & {
  isRequired?: boolean
}

export const TemplateCustomizeModal = ({
  isModalActive,
  setIsModalActive,
}: TemplateCustomizeModalProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const notify = (props: any) => Toast(props)
  const [hasReachedMaxChars, setHasReachedMaxChars] = useState(false)

  const [formData, setFormData] = useState({
    assessmentName: '',
    noOfQuestions: 0,
    assessmentPurpose: '',
    industry: '',
    difficulty: '',
  })

  // Percentage loader state
  const [isCustomLoaderOpen, setIsCustomLoaderOpen] = useState(false)
  const [isComplete, setIsComplete] = useState(false)

  const handleFormData = (name: string, value: string | string[]) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const { mutate: generateTemplate, isLoading: isTemplateLoading } =
    useGenerateAssessmenTemplate()

  useEffect(() => {
    if (isTemplateLoading) {
      setIsCustomLoaderOpen(true)
      setIsComplete(false)
    }
  }, [isTemplateLoading])

  const handleCreate = () => {
    const {
      assessmentName,
      assessmentPurpose,
      noOfQuestions,
      difficulty,
      industry,
    } = formData

    if (
      !assessmentName ||
      !assessmentPurpose ||
      !noOfQuestions ||
      !difficulty ||
      !industry
    ) {
      Toast({
        alertHeader: 'Please fill in all required fields.',
        status: 'Warning',
      })
      return
    } else {
      generateTemplate(
        {
          assessmentName: assessmentName,
          noOfQuestions: noOfQuestions,
          assessmentPurpose: assessmentPurpose,
          difficultyLevel: difficulty,
          industry: industry,
        },
        {
          onSuccess: ({ data }: any) => {
            if (data?.aiResponse == null) {
              notify({
                alertHeader: 'Error...!',
                alertBody: data.message,
                status: 'Error',
              })
              setIsCustomLoaderOpen(false)
            } else {
              dispatch(setAIAssessmentQuestionDataState(data?.aiResponse))
              setIsComplete(true)
            }
          },
          onError: ({ response: { data: errData } }: any) => {
            notify({
              alertHeader: 'Error...!',
              alertBody: errData?.message,
              status: 'Error',
            })
            setIsCustomLoaderOpen(false)
          },
        },
      )
    }
  }

  const handleClose = () => {
    setFormData({
      assessmentName: '',
      assessmentPurpose: '',
      noOfQuestions: 0,
      industry: '',
      difficulty: '',
    })

    setIsModalActive(false)
  }
  const handleKeyDown = (event) => {
    if (event.key === '-') {
      event.preventDefault()
    }
  }

  const [delay, setDelay] = useState(15000)

  useEffect(() => {
    if (formData.noOfQuestions <= 10) setDelay(15000)
    if (formData.noOfQuestions > 10 && formData.noOfQuestions <= 20)
      setDelay(35000)
    if (formData.noOfQuestions > 20 && formData.noOfQuestions <= 40)
      setDelay(55000)
    if (formData.noOfQuestions > 40) setDelay(70000)
  }, [formData.noOfQuestions])

  return (
    <>
      <Modal
        isHeaderShow={true}
        isFooterShow={true}
        isActive={isModalActive}
        modalProps={{
          style: { width: '816px', maxHeight: '95vh' },
        }}
        onHeaderCloseButtonClick={handleClose}
        headerTitle="Let's get started"
        subHeaderTitle="Based on the information you share, we'll craft your editable assessment.">
        {isCustomLoaderOpen && (
          <PercentageLoader
            delay={delay}
            handleLoadingComplete={() => {
              navigate('/assessments/new')
            }}
            isComplete={isComplete}
            message='Your assessment is being generated'
          />
        )}
        <div className='flex flex-col w-full pb-5 px-6 my-5 overflow-y-auto styled-scrollbar'>
          <div className='flex flex-row justify-start items-start flex-wrap gap-4'>
            <div>
              <TextField
                id={'assessmentName'}
                label='Assessment Name'
                className='w-[360px]'
                placeholder=''
                value={formData.assessmentName}
                onChange={(e) =>
                  handleFormData('assessmentName', e.target.value)
                }
                isRequired
              />
              <span className='text-Gray-400 text-[12px]'>
                (Ex: Software Engineer Assessment)
              </span>
            </div>

            <TextField
              label='Industry'
              className='w-[360px]'
              placeholder=''
              value={formData.industry}
              onChange={(e: any) => {
                handleFormData('industry', e.target.value)
              }}
              onKeyDown={handleKeyDown}
              isRequired
            />
            <SimpleSelect
              label='Difficulty Level'
              placeholder='Select Difficulty'
              value={difficultyList?.filter(
                (item: any) => item?.value === formData.difficulty,
              )}
              className='w-[360px]'
              onChange={(selectedItem: any) => {
                handleFormData('difficulty', selectedItem.value)
              }}
              options={difficultyList}
              isRequired
            />
            <TextField
              label='No of questions?'
              className='w-[360px]'
              type='number'
              min={0}
              placeholder=''
              value={formData.noOfQuestions}
              onChange={(e: any) => {
                const newValue = e.target.value
                if (
                  newValue === '' ||
                  (!isNaN(newValue) && Number(newValue) >= 0)
                ) {
                  const filteredValue = newValue.replace(/-/g, '')
                  handleFormData('noOfQuestions', filteredValue)
                }
              }}
              onKeyDown={handleKeyDown}
              isRequired
            />
          </div>

          <div className='pl-2'>
            <MultiLableTextArea
              firstLabel='Assessment purpose (or paste Job Description)'
              secondLabel="(Ex: I need to evaluate the candidate's technical proficiency in .NET. It is important that they demonstrate a strong core knowledge of the C programming language.)"
              id={'description'}
              isRequired
              rows={5}
              value={formData.assessmentPurpose}
              onChange={(value) => handleFormData('assessmentPurpose', value)}
              showCount={true}
              setHasReachedMaxChars={setHasReachedMaxChars}
            />
          </div>
        </div>
        <ModalFooter>
          <div className='flex justify-end flex-1'>
            <Button
              textTheme='blue'
              onClick={() => {
                handleClose()
              }}
              theme='white'>
              Cancel
            </Button>
            <Button
              isLoading={isTemplateLoading}
              className='ml-3 mr-2'
              onClick={handleCreate}
              isDisabled={hasReachedMaxChars}
              theme='cobalt'>
              Build Assessment
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  )
}
