import { useEffect, useState } from 'react'
import {
  Checkbox,
  Radio,
  SimpleSelect,
  TextArea,
  TextField,
} from '@labourhub/labour-hub-ds'
import { AssessmentQuestion } from 'features/questionnaires/types'

type AnswerSectionProps = {
  setEditableQuestion: React.Dispatch<React.SetStateAction<AssessmentQuestion>>
  editableQuestion: AssessmentQuestion
}

const AnswerSection = ({
  setEditableQuestion,
  editableQuestion,
}: AnswerSectionProps) => {
  const [checkedItems, setCheckedItems] = useState<string[]>([])

  useEffect(() => {
    if (
      editableQuestion.type === 'checkbox' &&
      editableQuestion.correctAnswer
    ) {
      try {
        const parsedAnswers = JSON.parse(editableQuestion.correctAnswer)
        setCheckedItems(Array.isArray(parsedAnswers) ? parsedAnswers : [])
      } catch (error) {
        console.error('Error parsing checkbox data:', error)
        setCheckedItems([])
      }
    }
  }, [editableQuestion.correctAnswer, editableQuestion.type])

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target
    setCheckedItems((prevState) => {
      if (checked) {
        return [...prevState, name]
      } else {
        return prevState.filter((item) => item !== name)
      }
    })
  }

  useEffect(() => {
    if (editableQuestion.type === 'checkbox') {
      setEditableQuestion({
        ...editableQuestion,
        correctAnswer: JSON.stringify(checkedItems),
      })
    }
  }, [checkedItems])

  return (
    <>
      <div className='w-full px-4 py-2 rounded-lg font-Regular text-Cobalt-800 bg-Cobalt-50'>
        <span>Answer</span>
      </div>
      {editableQuestion.type === 'text' && (
        <TextField
          className=''
          value={editableQuestion?.correctAnswer}
          onChange={(e: any) => {
            setEditableQuestion({
              ...editableQuestion,
              correctAnswer: e.target.value,
            })
          }}
          label=''
          placeholder=''
        />
      )}

      {editableQuestion.type === 'text_area' && (
        <TextArea
          className='w-full'
          value={editableQuestion?.correctAnswer}
          onChange={(e: any) => {
            setEditableQuestion({
              ...editableQuestion,
              correctAnswer: e.target.value,
            })
          }}
          placeholder=''
          rows={4}
        />
      )}

      {editableQuestion.type === 'textarea' && (
        <TextArea
          className='w-full'
          value={editableQuestion?.correctAnswer}
          onChange={(e: any) => {
            setEditableQuestion({
              ...editableQuestion,
              correctAnswer: e.target.value,
            })
          }}
          placeholder=''
          rows={4}
        />
      )}

      {editableQuestion.type === 'rating' && (
        <TextField
          className=''
          value={editableQuestion?.correctAnswer}
          onChange={(e: any) => {
            setEditableQuestion({
              ...editableQuestion,
              correctAnswer: e.target.value,
            })
          }}
          label=''
          placeholder=''
          type='number'
          inputProps={{
            min: '0',
            max: editableQuestion.meta.length,
          }}
        />
      )}

      {editableQuestion.type === 'checkbox' && (
        <div className='flex flex-col gap-y-2'>
          {[...editableQuestion.meta].map((item, idx) => (
            <Checkbox
              key={idx}
              id={`custom-checkbox-${idx}`}
              labelText={item}
              name={item}
              checked={checkedItems.includes(item)}
              onChange={handleCheckboxChange}
              labelTextClassName='font-Regular'
              checkboxClassName='ml-0'
            />
          ))}
        </div>
      )}

      {editableQuestion.type === 'select' && (
        <SimpleSelect
          className='w-full'
          options={editableQuestion.meta.map((option: any) => ({
            value: option,
            label: option,
          }))}
          value={{
            value: editableQuestion.correctAnswer,
            label: editableQuestion.correctAnswer,
          }}
          onChange={(item: any) => {
            setEditableQuestion({
              ...editableQuestion,
              correctAnswer: item.value,
            })
          }}
          placeholder='Select Option'
        />
      )}

      {editableQuestion.type === 'radio' && (
        <div className='flex gap-6'>
          <Radio
            id='yes'
            onChange={(e: any) => {
              setEditableQuestion({
                ...editableQuestion,
                correctAnswer: e.target.id,
              })
            }}
            className='cursor-pointer select-none'
            name='statusYesNo'
            helperText=''
            labelText='Yes'
            defaultChecked={editableQuestion.correctAnswer === 'yes'}
          />

          <Radio
            className='cursor-pointer select-none'
            id='no'
            onChange={(e: any) => {
              setEditableQuestion({
                ...editableQuestion,
                correctAnswer: e.target.id,
              })
            }}
            name='statusYesNo'
            helperText=''
            labelText='No'
            defaultChecked={editableQuestion.correctAnswer === 'no'}
          />
        </div>
      )}

      {(editableQuestion.type === 'radio_neg_detail' ||
        editableQuestion.type === 'radio_pos_detail') && (
        <div className='flex flex-col w-full'>
          <div className='flex gap-6'>
            <Radio
              checked={editableQuestion.type === 'radio_pos_detail'}
              id={`${editableQuestion.id}-detailed-yes`}
              className='cursor-pointer select-none'
              name={`${editableQuestion.id}-detailed`}
              helperText=''
              labelText='Yes'
            />

            <Radio
              checked={editableQuestion.type === 'radio_neg_detail'}
              className='cursor-pointer select-none'
              id={`${editableQuestion.id}-detailed-no`}
              name={`${editableQuestion.id}-detailed`}
              helperText=''
              labelText='No'
            />
          </div>

          <TextArea
            className='w-full mt-3'
            label='Explain'
            value={editableQuestion?.correctAnswer}
            onChange={(e: any) => {
              setEditableQuestion({
                ...editableQuestion,
                correctAnswer: e.target.value,
              })
            }}
            placeholder=''
            rows={4}
          />
        </div>
      )}
    </>
  )
}

export default AnswerSection
