import { Avatar } from '@labourhub/labour-hub-ds'
import candidateDefaultLogo from 'assets/images/Profile-Icon.png'

export const RefereeListColumn = () => {
  const COLUMNS = [
    {
      Header: 'REFEREE NAME',
      accessor: 'firstName',
      forSorting: 'FirstName',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[212px] pl-6',
      headerClassName: 'flex items-center',
      cellClassName: 'flex items-center w-full',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}
        return (
          <div className='w-full'>
            <Avatar
              imageURL={
                original?.avatarUrl ? original?.avatarUrl : candidateDefaultLogo
              }
              titleClassName='flex w-max line-clamp-1'
              size='xs'
              color='one'
              title={`${original?.firstName} ${original?.lastName}`}
              /** Showing warning and medical notification icons */
              titleIcons={
                <div className='flex'>
                  {original.isWarning && (
                    <i className='pl-1 ri-error-warning-fill text-Red-500'></i>
                  )}

                  {original.isMedicalNotification && (
                    <i className='pl-1 ri-shield-cross-fill text-Cobalt-500'></i>
                  )}
                </div>
              }
              tag={original?.email}
            />
          </div>
        )
      },
    },
    {
      Header: 'CANDIDATE NAME',
      accessor: 'candidateName',
      forSorting: 'candidateName',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[150px] pl-6',
      headerClassName: 'flex items-center justify-start',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <div>{original?.candidateName}</div>
      },
    },
    {
      Header: 'CANDIDATE POSITION',
      accessor: 'candidatePosition',
      forSorting: 'candidatePosition',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[167px] pl-6',
      headerClassName: 'flex items-center justify-start text-left',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <div>{original?.candidatePosition || '-'}</div>
      },
    },
    {
      Header: 'STATUS',
      accessor: 'status',
      forSorting: 'status',
      isSortable: false,
      hasFilter: false,
      isVisible: true,
      commonClassName: 'w-full min-w-[167px] pl-6',
      headerClassName: 'flex items-center justify-start text-left',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <div>{original?.status}</div>
      },
    },
    {
      Header: 'COMPANY',
      accessor: 'company',
      forSorting: 'company',
      isSortable: true,
      hasFilter: true,
      isVisible: true,
      commonClassName: 'w-full min-w-[167px] pl-6',
      headerClassName: 'flex items-center justify-start text-left',
      cellClassName: 'flex items-center justify-start',
      Cell: (props: any) => {
        const {
          row: { original },
        } = props || {}

        return <div>{original?.company || '-'}</div>
      },
    },
  ]

  return COLUMNS
}

export default RefereeListColumn
