/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice } from '@reduxjs/toolkit'
import { TemplateVariablesDto } from 'api/templates'

const questionnaireVariables = [
  'CANDIDATE_FULL_NAME',
  "REFEREE'S_NAME",
  'PRIOR_ROLE',
  'ROLE',
  'CANDIDATE_COMPANY',
]

const questionnaireVariablesWithFirstName = [
  'CANDIDATE_FULL_NAME',
  'CANDIDATE_FIRST_NAME',
  "REFEREE'S_NAME",
  'PRIOR_ROLE',
  'ROLE',
  'CANDIDATE_COMPANY',
]

const assessmentVariables = [
  'ASSESSMENT_NAME',
  'ASSESSMENT_DUE_DATE',
  'ASSESSMENT_DURATION',
  'ASSESSMENT_WEBCAM',
  'CANDIDATE_FULL_NAME',
  'COMPANY',
]

const assessmentSMSVariables = ['CANDIDATE_FULL_NAME', 'COMPANY']

const assessmentBuilderVariables = ['CANDIDATE_FULL_NAME', 'ROLE']

const surveyVariables = ['CANDIDATE_FIRST_NAME', 'ROLE_SURVEY', 'TEMPLATE_NAME']

const templateVariablesWithoutSurvey = [
  'ASSESSMENT_NAME',
  'ASSESSMENT_DUE_DATE',
  'ASSESSMENT_DURATION',
  'ASSESSMENT_WEBCAM',
  'CANDIDATE_COMPANY',
  'CANDIDATE_FIRST_NAME',
  'CANDIDATE_FULL_NAME',
  'COMPANY',
  'CONSULTANT',
  'NUMBER_OF_REFEREES',
  "REFEREE'S_NAME",
  'PRIOR_ROLE',
  'ROLE',
]

const templateVariablesWithoutAssessment = [
  'CANDIDATE_COMPANY',
  'CANDIDATE_FIRST_NAME',
  'CANDIDATE_FULL_NAME',
  'COMPANY',
  'CONSULTANT',
  'NUMBER_OF_REFEREES',
  "REFEREE'S_NAME",
  'PRIOR_ROLE',
  'ROLE',
  'ROLE_SURVEY',
  'TEMPLATE_NAME',
]

const templateVariablesWithoutSurveyAndAssessment = [
  'CANDIDATE_COMPANY',
  'CANDIDATE_FIRST_NAME',
  'CANDIDATE_FULL_NAME',
  'COMPANY',
  'CONSULTANT',
  'NUMBER_OF_REFEREES',
  "REFEREE'S_NAME",
  'PRIOR_ROLE',
  'ROLE',
]

type GlobalStoreProps = {
  templateVariables: TemplateVariablesDto[]
  questionnaireVariables: TemplateVariablesDto[]
  questionnaireVariablesWithFirstName: TemplateVariablesDto[]
  assessmentVariables: TemplateVariablesDto[]
  assessmentBuilderVariables: TemplateVariablesDto[]
  assessmentSMSVariables: TemplateVariablesDto[]
  surveyVariables: TemplateVariablesDto[]
  templateVariablesWithoutSurvey: TemplateVariablesDto[]
  templateVariablesWithoutAssessment: TemplateVariablesDto[]
  templateVariablesWithoutSurveyAndAssessment: TemplateVariablesDto[]
}

const initialState: GlobalStoreProps = {
  templateVariables: [],
  questionnaireVariables: [],
  questionnaireVariablesWithFirstName: [],
  assessmentVariables: [],
  assessmentBuilderVariables: [],
  assessmentSMSVariables: [],
  surveyVariables: [],
  templateVariablesWithoutSurvey: [],
  templateVariablesWithoutAssessment: [],
  templateVariablesWithoutSurveyAndAssessment: [],
}

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    resetToInitialState(state, action) {
      state.templateVariables = []
      state.questionnaireVariables = []
      state.questionnaireVariablesWithFirstName = []
      state.assessmentVariables = []
      state.assessmentBuilderVariables = []
      state.assessmentSMSVariables = []
      state.surveyVariables = []
      state.templateVariablesWithoutSurvey = []
      state.templateVariablesWithoutAssessment = []
      state.templateVariablesWithoutSurveyAndAssessment = []
    },

    setTemplateVariablesState(state, { payload }) {
      state.templateVariables = payload
      state.questionnaireVariables = payload?.filter((t) =>
        questionnaireVariables.includes(t.variable),
      )
      state.questionnaireVariablesWithFirstName = payload?.filter((t) =>
        questionnaireVariablesWithFirstName.includes(t.variable),
      )
      state.assessmentVariables = payload?.filter((t) =>
        assessmentVariables.includes(t.variable),
      )
      state.assessmentBuilderVariables = payload?.filter((t) =>
        assessmentBuilderVariables.includes(t.variable),
      )
      state.assessmentSMSVariables = payload?.filter((t) =>
        assessmentSMSVariables.includes(t.variable),
      )
      state.surveyVariables = payload?.filter((t) =>
        surveyVariables.includes(t.variable),
      )
      state.templateVariablesWithoutSurvey = payload?.filter((t) =>
        templateVariablesWithoutSurvey.includes(t.variable),
      )
      state.templateVariablesWithoutAssessment = payload?.filter((t) =>
        templateVariablesWithoutAssessment.includes(t.variable),
      )
      state.templateVariablesWithoutSurveyAndAssessment = payload?.filter((t) =>
        templateVariablesWithoutSurveyAndAssessment.includes(t.variable),
      )
    },
  },
})

export const { resetToInitialState, setTemplateVariablesState } =
  globalSlice.actions

export const setToInitial =
  (values, cb: any = () => {}) =>
  (dispatch) => {
    dispatch(resetToInitialState(values))
    return cb(values)
  }

export const setTemplateVariables =
  (payload, cb = () => {}) =>
  (dispatch) => {
    dispatch(setTemplateVariablesState(payload))
  }

export default globalSlice.reducer
