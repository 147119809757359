import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

interface QuestionnairePublishInterface {
  id?: string
  surveyType?: string
}

export const usePublishQuestionnaireLibrary = () => {
  const mutate = useMutation(
    ({ id, surveyType }: QuestionnairePublishInterface) => {
      return putAPI(`/questionnaires/library/${id}/publish`, { surveyType })
    },
  )

  return mutate
}
