import { TextArea, TextField } from '@labourhub/labour-hub-ds'
import { ReferenceCheckType } from 'types'

import { RefereeTypeCard } from './RefereeTypeCard'

export const RefereeTypeAccordionContent = ({
  refereeDetails,
  setRefereeDetails,
}) => {
  const handleTypeChange = (type: string) => {
    setRefereeDetails({
      ...refereeDetails,
      type,
      note: '',
      specificEmployer: '',
    })
  }

  const handleSpecificEmployerChange = (e: any) => {
    setRefereeDetails({
      ...refereeDetails,
      specificEmployer: e.target.value,
    })
  }

  const handleNoteChange = (e: any) => {
    setRefereeDetails({
      ...refereeDetails,
      note: e.target.value,
    })
  }

  return (
    <div className='flex flex-col w-full p-5'>
      <div className='flex flex-col gap-5'>
        <>
          <RefereeTypeCard
            id={ReferenceCheckType?.Employment}
            name='referee'
            onChange={() => handleTypeChange(ReferenceCheckType?.Employment)}
            isSelected={refereeDetails.type === ReferenceCheckType?.Employment}
            defaultChecked={
              refereeDetails.type === ReferenceCheckType?.Employment
            }
            icon='ri-community-line'
            title='Employment Reference'
            subTitle='From a past employer. Candidate must enter prior employment details with this referee.'
          />

          {refereeDetails?.type === ReferenceCheckType?.Employment && (
            <div>
              <TextField
                placeholder='Woolworth'
                className='specific-emp'
                label='From Specific Employer'
                onChange={handleSpecificEmployerChange}
                value={refereeDetails?.specificEmployer}
              />

              <TextArea
                className='pt-4 notes'
                value={refereeDetails.note}
                onChange={handleNoteChange}
                label='Note to Candidate'
                placeholder='E.g. Please provide the referee details from your most recent employer.'
                rows={4}
                isErrored={refereeDetails?.note?.length > 500}
              />
              {refereeDetails?.note?.length > 500 && (
                <span className='text-Red-500'>
                  Maximum 500 character limit exceeded.
                </span>
              )}
            </div>
          )}
        </>

        <>
          <RefereeTypeCard
            id={ReferenceCheckType?.Professional}
            name='referee'
            onChange={() => handleTypeChange(ReferenceCheckType?.Professional)}
            isSelected={
              refereeDetails?.type === ReferenceCheckType?.Professional
            }
            defaultChecked={
              refereeDetails.type === ReferenceCheckType?.Professional
            }
            icon='ri-briefcase-fill'
            title='Professional Reference'
            subTitle='From a professional contact, not necessarily a past employer. No prior employment details required.'
          />

          {refereeDetails?.type == ReferenceCheckType?.Professional && (
            <div>
              <TextArea
                className='notes'
                value={refereeDetails?.note}
                onChange={handleNoteChange}
                label='Note to Candidate'
                placeholder='E.g. Please provide details from a professional in the aerospace industry.'
                rows={4}
                isErrored={refereeDetails?.note?.length > 500}
              />
              {refereeDetails?.note?.length > 500 && (
                <span className='text-Red-500'>
                  Maximum 500 character limit exceeded.
                </span>
              )}
            </div>
          )}
        </>

        <>
          <RefereeTypeCard
            id={ReferenceCheckType?.FriendFamily}
            name='referee'
            onChange={() => handleTypeChange(ReferenceCheckType?.FriendFamily)}
            isSelected={
              refereeDetails?.type === ReferenceCheckType?.FriendFamily
            }
            defaultChecked={
              refereeDetails.type === ReferenceCheckType?.FriendFamily
            }
            icon='ri-account-pin-circle-line'
            title='Friends & Family'
            subTitle='Personal reference on character and work ethic. No prior employment details required.'
          />

          {refereeDetails?.type == ReferenceCheckType?.FriendFamily && (
            <div>
              <TextArea
                className='notes'
                value={refereeDetails?.note}
                onChange={handleNoteChange}
                label='Note to Candidate'
                placeholder='E.g. Please provide a character reference from someone at your school or educational institution.'
                rows={4}
                isErrored={refereeDetails?.note?.length > 500}
              />
              {refereeDetails?.note?.length > 500 && (
                <span className='text-Red-500'>
                  Maximum 500 character limit exceeded.
                </span>
              )}
            </div>
          )}
        </>
      </div>
    </div>
  )
}
