import { useMutation } from 'react-query'
import { postAPIwithFormData } from 'framework/api/http'

interface CreateAgencyProfileLogoInterface {
  Id: string
  logoObject: string
}

export const useCreateAgencyProfileLogo = () => {
  const mutate = useMutation(
    ({ Id, logoObject }: CreateAgencyProfileLogoInterface) => {
      const bodyFormData = new FormData()
      bodyFormData.append('Logo', logoObject)

      return postAPIwithFormData(`/AgencyProfile/${Id}/Logo`, bodyFormData, {})
    },
  )

  return mutate
}

export default useCreateAgencyProfileLogo
