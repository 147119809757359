import { RootState } from 'store/reducers'

export const getQuestionnaires = (state: RootState) => state.questionnaire
export const getAssessments = (state: RootState) => state.questionnaire
export const getTotalAssessments = (state: RootState) =>
  state.questionnaire.totalAssessments
export const getQuestionnairesTab = (state: RootState) =>
  state.questionnaire.surveyType
export const isNewAssessmentModalActive = (state: RootState) =>
  state.questionnaire.isNewAssessmentModalOpen
export const isNavigatingFromNewAssessmentModal = (state: RootState) =>
  state.questionnaire.isNavigatingFromNewAssessmentModal
export const isNewRefCheckModalActive = (state: RootState) =>
  state.questionnaire.isNewRefCheckModalOpen
export const isNavigatingFromRefCheckFlowModal = (state: RootState) =>
  state.questionnaire.isNavigatingFromRefCheckFlowModal
