/* eslint-disable import/no-extraneous-dependencies */
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'

import { rootReducer } from './reducers'

export default configureStore({
  reducer: rootReducer,
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
  ],
  devTools: process.env.NODE_ENV !== 'production',
})
