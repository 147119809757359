import { Card } from 'components/atoms/Card'
import { timeToLocalTimeZoneWithISOFormat2 } from 'utils'

import { CreditListItem } from '../CreditListItem'

type AssessmentCompletedSectionProps = {
  title?: string

  reportViewData: any
}

const AssessmentCompletedSection = ({
  reportViewData,
}: AssessmentCompletedSectionProps) => {
  return (
    <Card>
      <div className='mt-3'>
        <CreditListItem
          title='Completed on:'
          data={timeToLocalTimeZoneWithISOFormat2(reportViewData.completedOn)}
        />
        <CreditListItem title='Finished in:' data={reportViewData.finishedIn} />
        <CreditListItem
          title='Mouse out of screen:'
          data={reportViewData.mouseOutOfScreen}
        />
        <CreditListItem title='Status:' data={reportViewData.candidateStage} />
      </div>
    </Card>
  )
}

export default AssessmentCompletedSection
