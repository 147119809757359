import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const getRightToWorkDetails = async ({ queryKey }: any) => {
  const reqBody = queryKey[1]
  const res = await get(`/NCC/RtwChecks/${reqBody.Id}`)
  return res
}

export const useGetRightToWorkDetails = ({ ...reqBody }, onSuccess: any) => {
  return useQuery(['RtwCheck', reqBody], getRightToWorkDetails, {
    enabled: false,
    refetchOnWindowFocus: false,
    retry: 1,
    onSuccess,
  })
}
