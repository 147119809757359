import {
  setAIAssessmentQuestionDataState,
  setAIQuestionDataState,
  setAssessmentFilterVariablesState,
  setFilterVariablesState,
  setIsAssessmentRefreshListState,
  setIsNavigatingFromNewAssessmentModalState,
  setIsNavigatingFromRefCheckFlowModalState,
  setIsNewAssessmentModalOpenState,
  setIsNewRefCheckModalOpenState,
  setIsRefreshListState,
  setSurveyTypeState,
  setTotalAssessmentsState,
  setTotalQuestionnairesState,
} from './reducer'

export const setTotalQuestionnaires =
  (value: number, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setTotalQuestionnairesState(value))
    cb()
  }

export const setTotalAssessments =
  (value: number, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setTotalAssessmentsState(value))
    cb()
  }

export const setFilterVariables =
  (filterVariables: any, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setFilterVariablesState(filterVariables))
    cb()
  }

export const setAIQuestionData =
  (data: any, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setAIQuestionDataState(data))
    cb()
  }

export const refreshQuestionnaireList =
  (cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setIsRefreshListState())
    cb()
  }
export const setSurveyType =
  (surveyType: any, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setSurveyTypeState(surveyType))
    cb()
  }

export const refreshAssessmentList =
  (cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setIsAssessmentRefreshListState())
    cb()
  }

export const setAssessmentFilterVariables =
  (filterVariables: any, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setAssessmentFilterVariablesState(filterVariables))
    cb()
  }

export const setAIAssesmentQuestionData =
  (data: any, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setAIAssessmentQuestionDataState(data))
    cb()
  }

export const setIsNewAssessmentModalOpen =
  (data: any, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setIsNewAssessmentModalOpenState(data))
    cb()
  }

export const setIsNavigatingFromNewAssessmentModal =
  (data: any, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setIsNavigatingFromNewAssessmentModalState(data))
    cb()
  }

export const setIsNewRefCheckModalOpen =
  (data: any, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setIsNewRefCheckModalOpenState(data))
    cb()
  }

export const setIsNavigatingFromRefCheckFlowModal =
  (data: any, cb = () => {}) =>
  (dispatch: any) => {
    dispatch(setIsNavigatingFromRefCheckFlowModalState(data))
    cb()
  }
