import React, { FC, useEffect, useState } from 'react'
import { Button } from '@labourhub/labour-hub-ds'
import CN from 'classnames'

import './UITable.scss'

export type UITablePaginationProps = {
  onChangePage?: any
  defaultSortBy?: any
  allowRowSelection?: boolean
  isPaginated?: boolean
  isFetching?: boolean
  hasStripes?: boolean
  selectedRowsIds?: any
  setInitialPage?: any
  initialPage?: boolean
  emptyBtnOnClick?: any
  [x: string]: any
}
export const UITablePagination: FC<UITablePaginationProps> = ({
  //   columns: userColumns,
  data,
  paginationVariables,
  setPaginationVariables,
  totalRowCount,
  initialPage,
  setInitialPage,
  defaultPageSize,
  setPageNumber,
  pageNumber,
  firstPageCustomCardCount = 0,
  ...restProps
}: UITablePaginationProps) => {
  React.useEffect(() => {
    if (initialPage) {
      setPageNumber(1)
      setInitialPage(false)
    }
  }, [initialPage])

  //   const [pageNumber, setPageNumber] = useState(1)
  const [pageSizes, setPageSizes] = useState<any[]>([])

  const [pageNumberVariables, setPageNumberVariables] = useState({
    start:
      Number(paginationVariables.take) * Number(pageNumber) -
      Number(paginationVariables.take) +
      1,
    end:
      Number(paginationVariables.take) * Number(pageNumber) >= totalRowCount
        ? totalRowCount
        : Number(paginationVariables.take) * Number(pageNumber) -
          firstPageCustomCardCount,
  })

  /** Pagination Functions */
  useEffect(() => {
    setPageNumberVariables({
      ...pageNumberVariables,
      start:
        Number(paginationVariables.take) * Number(pageNumber) -
        Number(paginationVariables.take) +
        (firstPageCustomCardCount && pageNumber === 1
          ? firstPageCustomCardCount
          : 0),
      end:
        Number(paginationVariables.take) * Number(pageNumber) >= totalRowCount
          ? totalRowCount
          : pageNumber === 1
          ? Number(paginationVariables.take) * Number(pageNumber)
          : Number(paginationVariables.take) * Number(pageNumber) -
            firstPageCustomCardCount,
    })
  }, [totalRowCount, pageNumber, paginationVariables])

  useEffect(() => {
    paginationVariables.search && setPageNumber(1)
  }, [paginationVariables.search])

  /** Pagination Functions */
  useEffect(() => {
    setPaginationVariables({
      ...paginationVariables,
      skip:
        pageNumber === 1
          ? 0
          : Number(paginationVariables.take) * (Number(pageNumber) - 1),
    })
  }, [pageNumber])

  useEffect(() => {
    if (defaultPageSize) {
      setPageSizes([defaultPageSize, 10, 20, 30, 50])
    } else {
      setPageSizes([10, 20, 30, 50])
    }
  }, [])

  return (
    <div
      className={CN(`h-auto ui-table__wrapper`, {
        'bg-white': data?.length > 0,
      })}
      style={{ borderRadius: '50%' }}>
      <div
        className='bottom-0 left-0 bg-white flex items-center justify-between rounded-b-lg w-full px-8 py-[9px] border-t border-Gray-200 mt-4'
        {...restProps}>
        <div className='flex items-center w-1/2'>
          <span className='text-Gray-800 font-Regular'>Items per page</span>
          <div className='border border-Gray-300 rounded-md py-[5px] pr-[10px] ml-2'>
            <select
              className='px-2 outline-none'
              value={paginationVariables?.take || 10}
              onChange={(e) => {
                setPageNumber(1)
                setPaginationVariables({
                  ...paginationVariables,
                  take: e.target.value,
                })
              }}>
              {pageSizes.map((pageSizeValue) => (
                <option key={pageSizeValue} value={pageSizeValue}>
                  {pageSizeValue}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='flex items-center'>
          <Button
            size='sm'
            isIconOnly
            theme='white'
            isDisabled={pageNumber <= 1}
            onClick={() => {
              setPageNumber(1)
            }}
            icon={
              <i className='ri-arrow-left-s-fill text-Gray-500 text-heading-4'></i>
            }></Button>
          <span className='mx-3'>
            {pageNumberVariables.start} - {pageNumberVariables.end} of{' '}
            {totalRowCount}
          </span>
          <Button
            className='mr-3'
            size='sm'
            isIconOnly
            theme='white'
            isDisabled={pageNumber <= 1}
            onClick={() => {
              setPageNumber(pageNumber - 1)
            }}
            icon={
              <i className='ri-arrow-left-s-line text-Gray-500 text-heading-4'></i>
            }></Button>
          <Button
            size='sm'
            isIconOnly
            theme='white'
            onClick={() => {
              setPageNumber(pageNumber + 1)
            }}
            isDisabled={
              Number(pageNumber) * Number(paginationVariables.take) >=
              Number(totalRowCount)
            }
            icon={
              <i className='ri-arrow-right-s-line text-Gray-500 text-heading-4'></i>
            }></Button>
        </div>
      </div>
    </div>
  )
}
UITablePagination.defaultProps = {
  isManualSortBy: false,
  handleSortByChange: undefined,
  isPaginated: true,
  isSorted: true,
  disableInnerLoading: false,
  disableInnerNoItemFound: false,
  selectedRowsIds: {},
  isManualSort: false,
  hasFooter: true,
  hasCheckBox: true,
  isExpandable: false,
  isActiveRowHighlights: false,
  isHeader: false,
  isFilterEnabled: false,
  filterExtraButton: undefined,
  filterContent: undefined,
  fixedHighlightBody: false,
}
export default UITablePagination
