import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CardBillingAddressDto } from 'api/payments'
import bfBanner from 'assets/images/blackfriday/refcheck-banner.jpg'
import CN from 'classnames'
import {
  getPayAsYouGoPaymentAmountSummary,
  InvoiceDto,
  PaymentInvoiceModal,
} from 'features/invoices'
import { PaymentErrorType } from 'features/payments/types'
import { setPromotion } from 'features/promotions'
import { PlanQuotaBadgesRow } from 'features/subscriptions'
import { LegacyPromotion } from 'features/subscriptions/components/LegacyPromotion'

import { Column } from 'components/atoms/Column'
import { RowWithAlignment } from 'components/atoms/Row'
import { Modal } from 'components/molecules'
import { isBlackFriday, isChristmas } from 'utils/offers'

import { getPaymentStore, setPayAsYouGoCreditsModalActive } from '../../store'
import { PromoCreditRedeemSuccessModal } from '../PromoCreditRedeemSuccessModal'

import { ExisitngPaymentRow } from './ExisitngPaymentRow'
import { PaymentCardElementModal } from './PaymentCardElementModal'
import { QuantityControlSection } from './QuantityControlSection'

export const PayAsYouGoSubscriptionPaymentModal = () => {
  const dispatch = useDispatch()

  const { isPayAsYouGoPaymentModalActive } = useSelector(getPaymentStore)

  const [isPaymentCardElementModalActive, setIsPaymentCardElementModalActive] =
    useState(false)
  const [paymentError, setPaymentError] = useState<PaymentErrorType>(null)
  const [isInvoiceModalActive, setIsInvoiceModalActive] = useState(false)

  const [isPayNowLoading, setIsPayNowLoading] = useState(false)

  const [invoice, setInvoice] = useState<InvoiceDto | null>(null)
  const [cardBillingAddress, setCardBillingAddress] = useState<
    CardBillingAddressDto | undefined
  >(undefined)

  const handleModalClose = () => {
    dispatch(setPayAsYouGoCreditsModalActive(false))
  }
  const amountSummary = useSelector(getPayAsYouGoPaymentAmountSummary)

  useEffect(() => {
    setIsPayNowLoading(false)
    dispatch(setPromotion(null))
  }, [isPayAsYouGoPaymentModalActive])

  return (
    <>
      <Modal
        headerTitle='Buy Credits'
        isFooterShow={true}
        modalContainerClassNames='w-[650px]'
        isActive={isPayAsYouGoPaymentModalActive}
        onHeaderCloseButtonClick={handleModalClose}
        onOverlayClick={handleModalClose}>
        <Column className='overflow-x-hidden overflow-y-auto styled-scroll max-h-[680px]'>
          {isBlackFriday() && <img src={bfBanner} alt='' className='mb-5' />}
          <div className='px-3 mx-2'>
            <RowWithAlignment className='my-3'>
              <p
                className={CN('pt-1.5', {
                  'w-[47%]': isChristmas(),
                  'w-[55%]': !isChristmas(),
                })}></p>
              <PlanQuotaBadgesRow />
            </RowWithAlignment>
            <LegacyPromotion isSmall />
            <QuantityControlSection
              isModalActive={isPayAsYouGoPaymentModalActive}
              amountSummary={amountSummary}
              isFullWidth={true}
            />

            <ExisitngPaymentRow
              amountSummary={amountSummary}
              setIsPaymentCardElementModalActive={
                setIsPaymentCardElementModalActive
              }
              setCardBillingAddress={setCardBillingAddress}
              isPayNowLoading={isPayNowLoading}
              setIsPayNowLoading={setIsPayNowLoading}
              setInvoice={setInvoice}
              setIsInvoiceModalActive={setIsInvoiceModalActive}
            />

            <div className='pb-10' />

            {/* <PoliceCheckLayout>
              <RowWithAlignment className='p-2 pb-4'>
                <span className='w-2/3 text-extra-small font-Regular text-Gray-900'>
                  Please note that you might be required to submit an ACIC
                  Registration Pack before conducting your initial check.
                </span>

                <Button
                  onClick={() => {
                    window.open(buyCreditRegistration, '_blank')
                  }}
                  size='sm'
                  textTheme='black'
                  theme='white'>
                  Register Now!
                </Button>
              </RowWithAlignment>
            </PoliceCheckLayout> */}
          </div>
        </Column>
      </Modal>

      <PaymentCardElementModal
        isModalActive={isPaymentCardElementModalActive}
        setIsModalActive={setIsPaymentCardElementModalActive}
        modalMode='pay'
        setInvoice={setInvoice}
        setCardBillingAddress={setCardBillingAddress}
        setIsInvoiceModalActive={setIsInvoiceModalActive}
        setIsPayNowLoading={setIsPayNowLoading}
        setPaymentError={setPaymentError}
      />

      <PaymentInvoiceModal
        isActive={isInvoiceModalActive}
        setIsActive={setIsInvoiceModalActive}
        invoice={invoice}
        cardBillingAddress={cardBillingAddress}
        isSuccess={invoice != null}
        paymentError={paymentError}
      />
      <PromoCreditRedeemSuccessModal />
    </>
  )
}
