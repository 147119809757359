import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface addPoliceCheckInterface {
  CandidateId?: string
  CheckType?: string
  HealthcareType?: string
  Reason: string
}

export const useAddPoliceCheck = () => {
  const mutate = useMutation(
    ({
      CandidateId,
      CheckType,
      HealthcareType,
      Reason,
    }: addPoliceCheckInterface) => {
      return postAPI(`/NCC/PoliceChecks`, {
        CandidateId,
        CheckType,
        HealthcareType,
        Reason,
      })
    },
  )

  return mutate
}

export default useAddPoliceCheck
