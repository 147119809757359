import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const downloadCheckReport = async ({ queryKey }: any) => {
  const reqBody = queryKey[1]
  const res = await get(
    `/NCC/Checks/${reqBody.id}/Report?NccCheckType=${reqBody?.type}`,
    {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/pdf',
      },
    },
  )
  return res
}

export const useDownloadCheckReport = (
  { ...reqBody },
  onSuccess: any,
  onError: any,
) => {
  return useQuery(['downloadCheck', reqBody], downloadCheckReport, {
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess,
    onError,
  })
}
