import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@labourhub/labour-hub-ds'
import candidates from 'assets/images/guide-stepper/candidates.svg'
import {
  getOnboardingStore,
  setShowGuideTooltipModal,
} from 'features/onboarding/store'

import { ColumnWithAlignment } from 'components/atoms/Column'
import { RowWithAlignment } from 'components/atoms/Row'
import { cn } from 'utils'

import './GuideTooltipModal.scss'

export const GuideTooltipModal = () => {
  const dispatch = useDispatch()
  const { showGuideTooltipModal } = useSelector(getOnboardingStore)

  return (
    <div
      className={cn('absolute left-[210px] top-[-60px]', {
        hidden: !showGuideTooltipModal,
      })}>
      <div className='fixed inset-0 z-40 bg-black opacity-50' />
      <div className='absolute border border-Gray-200 w-[400px] pb-4 bg-white shadow-lg rounded-lg z-50 ml-4 mt-12 p-2'>
        <div className='arrow top-[32.5px] left-0 transform -translate-x-1/2 -translate-y-1/2 -z-10' />
        <ColumnWithAlignment>
          <img src={candidates} alt='' className='w-[150px] h-[100px]' />
          <span className='font-SemiBold text-[#105DA4] my-3'>Candidates</span>
          <span className='text-center font-Regular text-Gray-600 text-small'>
            Manage all your candidates in one place. Track their progress
            through the hiring process and stay organized.
          </span>

          <RowWithAlignment className='px-4 mt-4' justifyAlignment='center'>
            {/* <button
                  onClick={() => {
                    dispatch(setShowGuideTooltipModal(false))
                  }}
                  className='mt-1 text-center font-Regular text-Gray-400 text-small'>
                  Skip
                </button> */}

            <Button
              size='sm'
              iconRight={<i className='ri-arrow-right-line' />}
              onClick={() => {
                // dispatch(setCurrentStep(currentStep + 1))
                // dispatch(setShowGuideTooltipModal(false))
                // dispatch(setShowGuideModal(true))
                dispatch(setShowGuideTooltipModal(false))
              }}
              className='mt-2 cursor-pointer'>
              Next
            </Button>
          </RowWithAlignment>
        </ColumnWithAlignment>
      </div>
    </div>
  )
}
