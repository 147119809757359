import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  SimpleSelect,
  TextField,
  Toast,
} from '@labourhub/labour-hub-ds'
import {
  useUpdateOnboardingStepper,
  useUpdateOnboardingUserProfile,
} from 'api/onboarding'
import { aboutYou } from 'features/onboarding/data'
import { setCurrentStep } from 'features/onboarding/store'
import { getOnboardingStore } from 'features/onboarding/store/selectors'
import {
  useCreateUserProfilePicture,
  useDeleteUserProfilePicture,
} from 'framework/api/methods'

import { ProfileAvatar } from 'components/atoms'
import { CustomizableEditProfilePictureModal } from 'components/molecules'
import { isLetters } from 'utils'

export const TellUsAboutYouSection = () => {
  const dispatch = useDispatch()
  const { currentStep, userProfileId, paymentData } =
    useSelector(getOnboardingStore)

  const [formDetails, setFormDetails] = useState({
    firstName: '',
    lastName: '',
    role: '',
    hearAboutUs: '',
  })

  useEffect(() => {
    if (paymentData) {
      setFormDetails({
        ...formDetails,
        firstName: paymentData?.billingAddress?.firstName,
        lastName: paymentData?.billingAddress?.lastName,
      })
    }
  }, [paymentData])

  const [isErrorField, setIsErrorField] = useState<any>({
    firstName: false,
    lastName: false,
    role: false,
    hearAboutUs: false,
  })

  /** next button disable state */
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false)

  const [files, setFiles] = useState<any>()
  const [imageUrl, setImageUrl] = useState<any>('')
  const [showAddEditProfilePictureModal, setShowAddEditProfilePictureModal] =
    useState(false)

  const [avatarUrl, setAvatarUrl] = useState<string | null>(null)

  /** hear about us list */
  const referredByList = [
    { value: 'Article', label: 'Article' },
    { value: 'From a colleague', label: 'From a colleague' },
    { value: 'Google Ads', label: 'Google Ads' },
    { value: 'Google Search', label: 'Google Search' },
    { value: 'Jobadder', label: 'Jobadder' },
    { value: 'Jobtoolz', label: 'Jobtoolz' },
    { value: 'Linkedin ads', label: 'Linkedin ads' },
    { value: 'Linkedin post', label: 'Linkedin post' },
    { value: 'Referee Form', label: 'Referee Form' },
    { value: 'RecAlliance', label: 'RecAlliance' },
  ]

  /** upload profile picture api */
  const {
    mutate: UploadProfilePictureMutate,
    isLoading: uploadProfilePictureIsLoading,
  } = useCreateUserProfilePicture()

  /** Process the upload profile picture */
  async function UploadProfilePicture(Id: any, avatarObject: any) {
    UploadProfilePictureMutate(
      {
        Id,
        avatarObject,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          Toast({
            alertHeader: 'Profile photo updated!',
            alertBody: successData?.message,

            status: 'Success',
          })
          setShowAddEditProfilePictureModal(false)
          setAvatarUrl(successData?.userProfile?.avatarUrl)
        },
        onError: ({ response: { data: errData } }: any) => {
          setShowAddEditProfilePictureModal(false)
          Toast({
            alertHeader: errData?.messageTitle
              ? errData?.messageTitle
              : 'Error!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  /** API Call for upload profile picture using react-query */
  const handleUploadProfileAvatar = (files: any) => {
    UploadProfilePicture(userProfileId, files)
  }

  /** API Call for delete user avatar using react-query */
  const {
    mutate: deleteUserProfilePictureMutate,
    isLoading: deleteUserProfilePictureIsLoading,
  } = useDeleteUserProfilePicture()

  /** Process the Delete User Avatar */
  async function deleteUserProfileAvatar(Id: any) {
    deleteUserProfilePictureMutate(
      { Id },
      {
        onSuccess: ({ data: successData }: any) => {
          Toast({
            alertHeader: successData.messageTitle
              ? successData.messageTitle
              : 'Profile photo was removed!',
            alertBody: '',
            status: 'Success',
          })
          setAvatarUrl(null)
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: errData?.messageTitle
              ? errData?.messageTitle
              : 'Error!',
            alertBody: errData?.message ? errData?.message : 'Error!',
            status: 'Error',
          })
        },
      },
    )
  }

  /** API Call for Update user profile data */
  const {
    mutate: updateUserProfileDetailsMutate,
    isLoading: updateUserProfileDetailsIsLoading,
  } = useUpdateOnboardingUserProfile()

  /** Process update user data API call */
  async function updateUserProfileDetails() {
    if (userProfileId) {
      updateUserProfileDetailsMutate(
        {
          userId: userProfileId,
          firstName: formDetails.firstName,
          lastName: formDetails.lastName,
          jobTitle: formDetails.role,
        },
        {
          onError: ({ response: { data: errData } }: any) => {
            Toast({
              alertHeader: errData.messageTitle
                ? errData.messageTitle
                : 'Error!',
              alertBody: errData.message,
              status: 'Error',
            })
          },
        },
      )
    }
  }

  const validateInputs = () => {
    const { role, firstName, lastName, hearAboutUs } = formDetails

    setIsErrorField({
      ...isErrorField,
      firstName: isLetters(firstName) ? false : true,
      lastName: isLetters(lastName) ? false : true,
      role: role ? false : true,
      hearAboutUs: hearAboutUs ? false : true,
    })

    const isAllValidFields =
      role && isLetters(firstName) && isLetters(lastName) && hearAboutUs

    return isAllValidFields
  }

  const onNextStepClick = () => {
    const valid = validateInputs()

    if (valid) {
      updateUserProfileDetails()
      companyOnboarding()
    } else {
      Toast({
        alertHeader: 'Invalid input type.',
        status: 'Warning',
      })
    }
  }

  /** handle next button disable */
  useEffect(() => {
    const { firstName, lastName, hearAboutUs, role } = formDetails || {}

    firstName && lastName && hearAboutUs && role
      ? setIsNextButtonDisabled(false)
      : setIsNextButtonDisabled(true)
  }, [formDetails, avatarUrl])

  /** APi call for the onboarding stepper */
  const {
    mutate: companyOnboardingMutate,
    isLoading: companyOnboardingIsLoading,
  } = useUpdateOnboardingStepper()

  /** Process the onboarding stepper */
  const companyOnboarding = () => {
    companyOnboardingMutate(
      {
        aboutYou: {
          id: aboutYou,
          // avatarUrl: avatarUrl ?? '',
          firstName: formDetails.firstName,
          lastName: formDetails.lastName,
          role: formDetails.role,
          hearAboutUs: formDetails.hearAboutUs,
        },
      },
      {
        onSuccess: () => {
          dispatch(setCurrentStep(currentStep + 1))
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: errData?.messageTitle
              ? errData?.messageTitle
              : 'Error!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  return (
    <>
      <div className='flex flex-col items-center justify-center w-7/12'>
        <ProfileAvatar
          isProfilePictureAvailable={avatarUrl ? true : false}
          imageSource={avatarUrl ? avatarUrl : undefined}
          imageContainerClassName='w-[132px] h-[132px]'
          isOnboarding={true}
          onAddClick={() => {
            setShowAddEditProfilePictureModal(true)
          }}
          onDeleteClick={() => {
            deleteUserProfileAvatar(userProfileId)
          }}
          onEditClick={() => {
            setShowAddEditProfilePictureModal(true)
          }}
          isStatusDisable={false}
        />

        <div className='flex flex-col items-center justify-start w-full'>
          <div className='w-full mt-5 text-left text-heading-2 font-Medium text-Gray-800'>
            Tell Us About You
          </div>

          <div className='flex items-center justify-center w-full pt-5 gap-x-3'>
            <TextField
              type='text'
              label='First Name'
              placeholder=''
              className='w-full'
              isErrored={isErrorField.firstName}
              value={formDetails?.firstName}
              onChange={(e: any) => {
                setFormDetails({
                  ...formDetails,
                  firstName: e.target.value,
                })
              }}
            />
            <TextField
              type='text'
              label='Last Name'
              placeholder=''
              className='w-full'
              isErrored={isErrorField.lastName}
              value={formDetails?.lastName}
              onChange={(e: any) => {
                setFormDetails({
                  ...formDetails,
                  lastName: e.target.value,
                })
              }}
            />
          </div>

          <TextField
            type='text'
            label='Your Role'
            placeholder=''
            className='w-full pt-5'
            isErrored={isErrorField.role}
            value={formDetails?.role}
            onChange={(e: any) => {
              setFormDetails({
                ...formDetails,
                role: e.target.value,
              })
            }}
          />

          {/* hear about us section */}
          <SimpleSelect
            className='w-full mt-5 hear-us'
            value={
              formDetails?.hearAboutUs
                ? {
                    label: formDetails?.hearAboutUs,
                    value: formDetails?.hearAboutUs,
                  }
                : ''
            }
            onChange={(selectedItem: any) => {
              setFormDetails({
                ...formDetails,
                hearAboutUs: selectedItem.label,
              })
            }}
            label='How did you hear about us?'
            options={referredByList}
            placeholder='Please select one option'
          />

          {/* next button */}
          <Button
            isBlock
            onClick={onNextStepClick}
            className='mt-5 next-btn'
            isDisabled={isNextButtonDisabled}
            isLoading={
              companyOnboardingIsLoading ||
              deleteUserProfilePictureIsLoading ||
              updateUserProfileDetailsIsLoading
            }>
            Next
          </Button>
        </div>
      </div>

      <CustomizableEditProfilePictureModal
        isActive={showAddEditProfilePictureModal}
        onHeaderCloseButtonClick={() => {
          setShowAddEditProfilePictureModal(false)
        }}
        onClickSecondaryBtn={() => {
          setShowAddEditProfilePictureModal(false)
        }}
        onOverlayClick={() => {
          setShowAddEditProfilePictureModal(false)
        }}
        onClickPrimaryBtn={() => {
          handleUploadProfileAvatar(files)
        }}
        imageUrl={imageUrl}
        setImageUrl={setImageUrl}
        setFiles={setFiles}
        headerTitle={avatarUrl ? 'Change profile photo' : 'Add profile photo'}
        headerSubTitle='Update your profile photo here.'
        primaryButtonIsLoading={uploadProfilePictureIsLoading}
      />
    </>
  )
}
