/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CN from 'classnames'
import { resetToInitial } from 'store/reducers/side-menu/mainSideMenuReducer'

import { useOutsideClickIdentify } from 'utils'

type HeaderMoreButtonDropDownProps = {
  className?: string | undefined
  isCustomStyles?: boolean
  setShowDropdown: (arg0: boolean) => void
  [x: string]: any
}

export const HeaderMoreButtonDropDown: FC<HeaderMoreButtonDropDownProps> = ({
  className,
  isCustomStyles,
  setShowDropdown,
  ...restProps
}: HeaderMoreButtonDropDownProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const focusedDivRef = useRef(null)
  useOutsideClickIdentify('header-more-action-button', focusedDivRef, () => {
    setShowDropdown(false)
  })

  /** user drop down main class name */
  const textFieldMainClassName = !isCustomStyles
    ? CN(
        'absolute top-2 right-[20px] flex flex-col w-[224px] rounded-[8px] z-20 bg-white shadow',
        className,
      )
    : className

  return (
    <div className={textFieldMainClassName} {...restProps} ref={focusedDivRef}>
      <div className='flex pl-[14px] pt-[13px] cursor-pointer text-Gray-500 hover:text-Gray-900'>
        <i className='ri-user-settings-line text-heading-5' />

        <span
          className='font-Regular pl-[10px] text-Gray-500 hover:text-Gray-900'
          onClick={() => {
            dispatch(resetToInitial())
            setShowDropdown(false)
            navigate('/user-groups')
          }}>
          Users
        </span>
      </div>

      <div className='flex pl-[14px] pt-4 pb-3 cursor-pointer text-Gray-500 hover:text-Gray-900'>
        <i className='ri-profile-line text-heading-5' />

        <span
          className='font-Regular pl-[10px] text-Gray-500 hover:text-Gray-900'
          onClick={() => {
            dispatch(resetToInitial())
            setShowDropdown(false)
            navigate('/agency-profile')
          }}>
          Company Profile
        </span>
      </div>
    </div>
  )
}

export default HeaderMoreButtonDropDown

HeaderMoreButtonDropDown.defaultProps = {
  className: undefined,
  isCustomStyles: false,
}
