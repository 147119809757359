import React from 'react'
import { useDispatch } from 'react-redux'
import { Button, Toast } from '@labourhub/labour-hub-ds'
import { useCancelAssessmentRequest } from 'api/assessment/cancelAssessmentRequest'
import { useGetActiveSubscriptions } from 'api/subscriptions'
import { setSubscriptions } from 'features/subscriptions'

import { Modal, ModalFooter } from 'components/molecules/Modal'

import './styles.scss'

type CancelRequestModalProps = {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  candidateId: string
  selectedResponse: any
  refreshAssessmentList: () => void
}

export const CancelRequestModal = ({
  isOpen,
  setIsOpen,
  candidateId,
  selectedResponse,
  refreshAssessmentList,
}: CancelRequestModalProps) => {
  const dispatch = useDispatch()

  const { mutate: cancelRequest, isLoading } = useCancelAssessmentRequest()

  const { refetch: refetchSubscriptions } = useGetActiveSubscriptions(
    (subscriptions) => dispatch(setSubscriptions(subscriptions)),
  )

  const handleCancelRequest = () => {
    cancelRequest(
      {
        cId: candidateId,
        assessmentId: selectedResponse.assessmentId,
        responseId: selectedResponse.id,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          Toast({
            alertHeader: successData?.messageTitle,
            status: 'Success',
          })
          setIsOpen(false)
          refetchSubscriptions()
          refreshAssessmentList()
        },
        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: 'Error...!',
            alertBody: errData?.message,
            status: 'Error',
          })
        },
      },
    )
  }

  return (
    <Modal
      isActive={isOpen}
      headerTitle=''
      size='md'
      className='!z-[150]'
      isFooterShow
      isPrimaryButtonDisable={false}
      onHeaderCloseButtonClick={() => setIsOpen(false)}>
      <div className='flex flex-col items-center justify-center p-6 pt-0'>
        <div className='flex items-center justify-center w-12 h-12 rounded-full bg-[#FFF6EC]'>
          <i className='ri-error-warning-line text-[#DD7F0B] text-heading-3'></i>
        </div>
        <span className='pt-5 text-Gray-900 text-heading-5 font-Medium'>
          Cancel Assessment Request
        </span>
        <span className='pt-2 text-center text-Gray-500 text-small font-Regular'>
          Canceling the assessment request will allow you to regain the credit.
          Please be aware that this action will invalidate the assessment
          request link for the candidate.
        </span>
      </div>
      <ModalFooter className='border-t-0 shadow-none bg-Gray-50'>
        <div className='flex justify-end flex-1 gap-2'>
          <Button
            isDisabled={isLoading}
            onClick={() => setIsOpen(false)}
            textTheme='blue'
            theme='white'>
            Cancel
          </Button>
          <Button
            isDisabled={isLoading}
            isLoading={isLoading}
            onClick={handleCancelRequest}
            className='mr-2'
            theme='cobalt'>
            Yes, I&apos;m Sure
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}
