import { ForwardedRef, forwardRef, useMemo } from 'react'
import {
  CandidateProfileBasicDetailsType,
  SurveyResponseDto,
} from 'api/candidates'
import { SurveyReportPreviewData } from 'features/candidates/data'

import { PageLoader } from 'components/atoms'
import { Column } from 'components/atoms/Column'
import { timeToAESTWithSeconds } from 'utils'

import {
  ReportBanner,
  ReportFooter,
  ReportLogoHeader,
  ReportSurveySummarySection,
  SurveyResponseReportCandidateDetails,
  SurveyResponseReportCandidateDetailsDto,
} from '../../sections'

import '../style.scss'

type SurveyResponseReportPrintPreviewProps = {
  candidateDetails: CandidateProfileBasicDetailsType
  previewData: SurveyReportPreviewData
  selectedResponse: SurveyResponseDto
  dynamicFieldMapping: any
  changeTextSchema: any
  isMappingVariablesLoading?: boolean
}

export const SurveyResponseReportPrintPreview = forwardRef(
  (
    {
      candidateDetails,
      previewData,
      selectedResponse,
      dynamicFieldMapping,
      changeTextSchema,
      isMappingVariablesLoading,
    }: SurveyResponseReportPrintPreviewProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const candidateReportDetails = useMemo(() => {
      const details: SurveyResponseReportCandidateDetailsDto = {
        name: candidateDetails.name,
        candidateNo: candidateDetails.candidateNo,
        email: candidateDetails.email,
        phoneNumber: candidateDetails.phone,
        avatarUrl: candidateDetails.avatarUrl,
        jobTitle: candidateDetails.jobTitle,
        surveyName: selectedResponse.surveyName,
        surveyType: selectedResponse.surveyTemplateType,
        completedOn: timeToAESTWithSeconds(selectedResponse.completedOn),
      }
      return details
    }, [candidateDetails, selectedResponse])

    return isMappingVariablesLoading ? (
      <PageLoader size='xs' />
    ) : (
      <Column className='hidden bg-white a4-preview w-[794px] pointer-events-none'>
        <div ref={ref} className='w-full h-full p-0 m-0'>
          <div style={{ pageBreakBefore: 'always' }}>
            <div className='h-[1123px] relative'>
              <ReportLogoHeader
                clientlogo={previewData.clientLogoUrl}
                hasClientLogo={previewData.hasClientLogo}
                hasCompanyLogo={previewData.hasCompanyLogo}
              />
              <ReportBanner />
              <SurveyResponseReportCandidateDetails
                details={candidateReportDetails}
              />
            </div>
          </div>

          <div className='relative'>
            <div className='relative' style={{ pageBreakBefore: 'always' }}>
              <div className='px-6'>
                <ReportSurveySummarySection
                  surveyResponse={selectedResponse}
                  dynamicFieldMapping={dynamicFieldMapping}
                  changeTextSchema={changeTextSchema}
                />
              </div>
            </div>
          </div>

          <ReportFooter isFixed={true} />
        </div>
      </Column>
    )
  },
)
