import { SubscriptionPackageType } from '../types'

export const subscriptionPackageTypeSelectOptions = [
  {
    label: SubscriptionPackageType.Free,
    value: SubscriptionPackageType.Free,
  },
  {
    label: SubscriptionPackageType.Fixed,
    value: SubscriptionPackageType.Fixed,
  },
  {
    label: 'Pay As You Go (OTP)',
    value: SubscriptionPackageType.PayAsYouGo,
  },
]
