/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import { Badge, Button } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { CandidateReferee, useUpdateRefereeStatus } from 'framework/api/methods'
import { ModalProps, ReferenceCheckType } from 'types'

import { TextEditor } from 'components/atoms'
import { FormBuilder, Modal, ModalFooter } from 'components/molecules'
import { timeToAESTWithSeconds } from 'utils'

type RefereeReportViewModalProps = ModalProps & {
  changeJsonSchema: any
  getCandidateRefereesList: () => void
  isDownload?: boolean
  referee: CandidateReferee
  refereeIndex?: number
  selectedReferenceDoneBy: any
  selectedReferenceDoneDate: any
  title: any
  setShowDownlaodReferenceReportModal: any
  setSelectedRefereeDetails: React.Dispatch<any>
}

export const RefereeReportViewModal = ({
  isModalActive,
  setIsModalActive,
  changeJsonSchema,
  getCandidateRefereesList,
  isDownload,
  referee,
  refereeIndex,
  selectedReferenceDoneBy,
  selectedReferenceDoneDate,
  title,
  setShowDownlaodReferenceReportModal,
  setSelectedRefereeDetails,
}: RefereeReportViewModalProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const [referenceDoneDate, setReferenceDoneDate] = useState<any>(
    selectedReferenceDoneDate,
  )
  const [referenceDoneBy, setReferenceDoneBy] = useState(
    selectedReferenceDoneBy,
  )

  const componentRef = useRef<HTMLElement>(null)

  const [status, setStatus] = useState<
    null | 'Incomplete' | 'Passed' | 'Failed'
  >(referee?.status)

  const adjustTextFieldHeight = () => {
    const textInputs = componentRef.current
      ?.querySelector('form.jason-theme')
      ?.querySelectorAll('input[type="text"]')

    if (textInputs) {
      textInputs.forEach((input) => {
        if (input instanceof HTMLInputElement) {
          // Check if a div with the same id already exists in the parent node
          const existingDiv = input?.parentNode?.querySelector(
            `#custom-div-${input.id}`,
          )

          if (!existingDiv) {
            // Create a new div element
            const newDiv = document.createElement('div')

            // Get the value of the input
            const inputValue = input.value

            // Set the div's content to the input value
            newDiv.textContent = inputValue
            newDiv.className = 'custom-div'

            // Set the div's id to match the input's id
            newDiv.id = `custom-div-${input.id}`

            // Prepend the div to the input's parent element
            input?.parentNode?.insertBefore(newDiv, input)

            input.style.display = 'none'
          }
        }
      })
    }
  }

  const adjustTextAreaHeight = (heightRatio = 1) => {
    const textareas = componentRef.current
      ?.querySelector('form.jason-theme')
      ?.querySelectorAll('textarea')

    if (textareas) {
      textareas.forEach((textarea) => {
        if (textarea instanceof HTMLTextAreaElement) {
          textarea.style.height = 'auto'
          textarea.style.height = `${textarea.scrollHeight * heightRatio}px`
        }
      })
    }
  }

  useEffect(() => {
    if (componentRef.current) {
      adjustTextAreaHeight()
      adjustTextFieldHeight()
    }
  }, [
    componentRef.current,
    referee?.refereeResponse,
    adjustTextAreaHeight,
    adjustTextFieldHeight,
  ])

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Referee ${
      refereeIndex !== undefined &&
      (refereeIndex < 8 ? `0${refereeIndex + 1}` : refereeIndex + 1)
    } Detail Report`,
    removeAfterPrint: true,
    onBeforeGetContent: () => adjustTextAreaHeight(1.5),
    onBeforePrint: () => adjustTextAreaHeight(),
    onAfterPrint: () => {
      if (isDownload) {
        handleCloseModel()
      }
    },
  })

  useEffect(() => {
    if (isModalActive && componentRef.current && isDownload) {
      handlePrint()
    }
  }, [])

  /** APi call for the update status */
  const { mutate: updateStatusMutate } = useUpdateRefereeStatus()

  const updateStatus = async (status: 'Incomplete' | 'Passed' | 'Failed') => {
    updateStatusMutate(
      {
        candidateId: referee?.candidateId,
        refereeId: referee?.id,
        status,
      },
      {
        onSuccess: async ({ data: successData }: any) => {
          getCandidateRefereesList()
          setSelectedRefereeDetails(successData?.referee)
          setReferenceDoneBy(successData?.referee?.referenceDoneBy)
          setReferenceDoneDate(successData?.referee?.referenceDoneDate)
        },
      },
    )
  }

  const handleCloseModel = () => {
    setIsModalActive(false)
    searchParams.delete('report_state')
    searchParams.delete('ref_id')
    navigate(
      {
        pathname: location.pathname,
        search: searchParams.toString(),
      },
      { replace: true },
    )
  }

  useEffect(() => {
    referee?.referenceDoneDate &&
      setReferenceDoneDate(referee?.referenceDoneDate)

    referee?.referenceDoneBy && setReferenceDoneBy(referee?.referenceDoneBy)
  }, [referee])

  const RefereeResponseReport = React.forwardRef((props, ref) => {
    return (
      <div ref={ref as React.RefObject<HTMLDivElement>}>
        <div className='flex items-start p-4 border rounded-lg bg-Gray-50 border-Gray-200'>
          <div className='flex items-center mr-4 avatar-div'>
            <div
              className={
                'flex justify-center items-center rounded-full font-Regular pointer-events-none w-10 h-10 text-heading-5 bg-NavyBlue text-white'
              }>
              {title}
            </div>
          </div>

          <div className='flex flex-col w-full'>
            <div className='divide-y divide-Gray-300'>
              <div className='flex flex-col items-start mb-3'>
                <div className='flex items-center mb-1'>
                  <span className='text-Gray-800 font-Medium text-[18px] mr-2'>
                    {`${referee?.firstName} ${referee?.lastName}`}
                  </span>
                  <span className='text-Gray-800 text-extra-small font-Medium bg-Gray-200 py-[2px] px-[10px] rounded-lg'>
                    Referee
                  </span>
                  <div className='py-[2px] px-[10px]'>
                    {referee?.refereeResponse?.responseType === 'WEB_CHECK' ? (
                      <Badge
                        size='small'
                        theme='primary'
                        type='basic'
                        isIcon={true}
                        icon='ri-global-line'>
                        Web Ref Check
                      </Badge>
                    ) : referee?.refereeResponse?.responseType ===
                      'PHONE_CHECK' ? (
                      <Badge
                        size='small'
                        theme='primary'
                        type='basic'
                        isIcon={true}
                        icon='ri-phone-line'>
                        Phone Ref Check
                      </Badge>
                    ) : null}
                  </div>
                </div>
                <div className='flex items-center'>
                  <div className='flex items-center mb-1 mr-5 min-w-fit'>
                    <i className='ri-phone-line text-Gray-500 mr-[10px] text-extra-small' />
                    <span className='text-Gray-600 font-Medium '>
                      {referee?.phone}
                    </span>
                  </div>
                  <div className='flex items-center mb-1'>
                    <i className='ri-mail-line text-Gray-500 mr-[10px] text-extra-small' />
                    <span className='text-Gray-600 font-Medium '>
                      {referee?.email}
                    </span>
                  </div>
                </div>

                {referee?.referenceType?.type !=
                  ReferenceCheckType.FriendFamily && (
                  <div className='flex items-center mb-1'>
                    <i className='ri-suitcase-line text-Gray-500 mr-[10px] text-extra-small' />
                    <span className='text-Gray-600 font-Medium '>
                      {referee?.jobTitle} at {referee?.company}
                    </span>
                  </div>
                )}

                {referee?.refereeResponse?.ipAddress && (
                  <div className='flex items-center mb-1'>
                    <i className='ri-global-line text-Gray-500 mr-[10px] text-extra-small' />
                    <span
                      className={CN('font-Medium', {
                        'text-Cobalt-600':
                          !referee?.refereeResponse?.fraudDetectedDate,
                        'text-Red-600':
                          referee?.refereeResponse?.fraudDetectedDate,
                      })}>
                      {referee?.refereeResponse?.ipAddress} -{' '}
                      {referee?.refereeResponse?.city},{' '}
                      {referee?.refereeResponse?.region},{' '}
                      {referee?.refereeResponse?.country}
                    </span>
                  </div>
                )}
              </div>

              {(referenceDoneBy || selectedReferenceDoneBy) && (
                <div className='flex py-3 text-Gray-600 text-small'>
                  {`Reference done by ${referenceDoneBy} on ${
                    referenceDoneDate &&
                    timeToAESTWithSeconds(referenceDoneDate)
                  }`}
                </div>
              )}
            </div>

            <div className='flex flex-col items-start justify-start'>
              <span className='text-Gray-800 text-[16px] mb-2'>
                Set the status of the ref check
              </span>
              <div className='flex flex-row text-Gray-600'>
                <div
                  onClick={() => {
                    setStatus('Passed')
                    updateStatus('Passed')
                  }}
                  className={CN(
                    'py-[9px] px-4 flex flex-row items-center border border-Gray-300 rounded-l-[6px] cursor-pointer',
                    {
                      'bg-Green-600': status === 'Passed',
                    },
                  )}>
                  <i
                    className={CN(
                      'ri-checkbox-circle-line mr-[10px] text-[20px]',
                      {
                        'text-white': status === 'Passed',
                      },
                    )}
                  />
                  <span
                    className={CN('font-Medium', {
                      'text-white': status === 'Passed',
                    })}>
                    Passed
                  </span>
                </div>
                <div
                  onClick={() => {
                    setStatus('Failed')
                    updateStatus('Failed')
                  }}
                  className={CN(
                    'py-[9px] px-4 flex flex-row items-center border border-Gray-300 cursor-pointer',
                    {
                      'bg-Red-600': status === 'Failed',
                    },
                  )}>
                  <i
                    className={CN(
                      'ri-close-circle-line mr-[10px] text-[20px]',
                      {
                        'text-white': status === 'Failed',
                      },
                    )}
                  />
                  <span
                    className={CN(' font-Medium', {
                      'text-white': status === 'Failed',
                    })}>
                    Failed
                  </span>
                </div>
                <div
                  onClick={() => {
                    setStatus('Incomplete')
                    updateStatus('Incomplete')
                  }}
                  className={CN(
                    'py-[9px] px-4 flex flex-row items-center border border-Gray-300 rounded-r-[6px] cursor-pointer',
                    {
                      'bg-Gray-600': status === 'Incomplete',
                    },
                  )}>
                  <i
                    className={CN(
                      'ri-indeterminate-circle-line mr-[10px] text-[20px]',
                      {
                        'text-white': status === 'Incomplete',
                      },
                    )}
                  />
                  <span
                    className={CN('font-Medium', {
                      'text-white': status === 'Incomplete',
                    })}>
                    Incomplete
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {referee?.refereeResponse?.fraudDetectedDate && (
          <div className='flex flex-row items-center w-full p-4 mt-4 border rounded bg-Red-50 border-Red-300'>
            <i className='ri-close-circle-fill text-Red-500 text-[16px] mr-[10px]' />
            <span className='text-base text-Red-800 font-Medium'>
              Fraud Detected. Candidate IP and referee IP are identical.
            </span>
          </div>
        )}
        <div className='p-4 mt-2'>
          {referee?.refereeResponse?.jsonSchema &&
            referee?.refereeResponse?.jsonData &&
            referee?.refereeResponse?.jsonUiSchema && (
              <FormBuilder
                schema={changeJsonSchema(
                  JSON.parse(referee?.refereeResponse?.jsonSchema),
                )}
                formData={JSON.parse(referee?.refereeResponse?.jsonData)}
                uiSchema={JSON.parse(referee?.refereeResponse?.jsonUiSchema)}
                readonly={true}
              />
            )}
          {referee?.refereeResponse?.htmlResponse && (
            <TextEditor
              readOnly={true}
              value={referee?.refereeResponse?.htmlResponse}
              hideToolbar={true}
            />
          )}
        </div>
      </div>
    )
  })

  return (
    <Modal
      className={CN('mt-0', {
        'mt-[-1000px]': isDownload,
      })}
      headerTitle={`Referee ${
        refereeIndex !== undefined &&
        (refereeIndex < 8 ? `0${refereeIndex + 1}` : refereeIndex + 1)
      } Detailed Report`}
      customWidth='1125px'
      isFooterShow={false}
      size='lg'
      isActive={isModalActive}
      onHeaderCloseButtonClick={handleCloseModel}
      onOverlayClick={handleCloseModel}>
      <div className='flex flex-col w-full justify-start p-5 max-h-[720px] overflow-y-auto'>
        <RefereeResponseReport ref={componentRef} />
      </div>

      <ModalFooter>
        <div className='flex justify-end flex-1'>
          <Button onClick={handleCloseModel} textTheme='blue' theme='white'>
            Close
          </Button>
          <Button
            onClick={() => {
              handleCloseModel()
              setShowDownlaodReferenceReportModal(true)
            }}
            className='ml-2'
            theme='cobalt'>
            Download
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}
