import { FC, ReactNode } from 'react'
import CN from 'classnames'

export interface URLTextInputProps {
  className?: string | undefined
  description?: string | undefined
  helperText?: string | undefined
  isCustomStyles?: boolean
  isDisabled?: boolean
  isErrored?: boolean
  isRequired?: boolean
  label?: string | undefined
  onChange?: (e: any) => void | undefined
  onKeyPress?: (e: any) => void | undefined
  placeholder?: string | undefined
  scheme?: 'http://' | 'https://' | undefined
  textFiledClassName?: string | undefined
  topLevelDomain?: string | undefined
  value?: string
  verifyContent?: ReactNode | undefined
  [x: string]: any
}

export const URLTextInput: FC<URLTextInputProps> = ({
  className,
  description,
  helperText,
  isCustomStyles,
  isDisabled,
  isErrored,
  isRequired,
  label,
  onChange,
  onKeyPress,
  placeholder,
  scheme,
  textFiledClassName,
  topLevelDomain,
  value,
  verifyContent,
  ...restProps
}: URLTextInputProps) => {
  /** url text field main class name */
  const URLTextInputClasses = !isCustomStyles
    ? CN('main-container flex flex-col', className)
    : className

  /** helper text style */
  const helperTextClassName = CN('text-small font-Regular pt-1', {
    'text-Gray-500': !isErrored,
    'text-Red-600': isErrored,
  })

  /** text input style */
  const TextInputFieldClassName = CN(
    'flex w-full items-center px-3 py-2 text-Gray-800 rounded-md border shadow-sm pl-[86px] pr-[141px] focus:outline-offset-2 focus:outline-Cobalt-400',
    textFiledClassName,
    {
      'border-Gray-300': !isErrored,
      'border-Red-500': isErrored,
    },
  )

  return (
    <div className={URLTextInputClasses} {...restProps}>
      {/* label */}
      {label !== undefined && (
        <div className='label-container flex justify-between  items-end text-small pb-1'>
          <div className='label-container text-small pb-1'>
            <div className='label flex text-Gray-800 font-Medium'>
              {/* label text */}
              <span>{label}</span>

              {/* required */}
              {isRequired && <span className='text-Red-500 pl-[2px]'>*</span>}
            </div>

            {/* description */}
            {description && (
              <span className='text-Gray-500 font-Regular pt-1'>
                ({description})
              </span>
            )}
          </div>

          {/* verify Content */}
          {verifyContent && <div className='pb-1'>{verifyContent}</div>}
        </div>
      )}

      {/* form fields*/}
      <div className='relative flex items-center'>
        {/* right side with http or https scheme */}
        <div className='absolute  h-[97%] w-[74px] flex justify-center items-center text-Gray-500 text-small bg-Gray-50 ml-[0.5px] rounded-l-md border-r border-r-Gray-200'>
          {scheme}
        </div>

        {/* input field */}
        <input
          type='text'
          value={value}
          onChange={onChange}
          onKeyPress={onKeyPress}
          placeholder={placeholder}
          disabled={isDisabled}
          className={TextInputFieldClassName}
          {...restProps.inputProps}
        />

        {/* left side with domain */}
        <div className='absolute right-0 h-[97%] w-[129px] flex justify-center items-center bg-Gray-50 text-Gray-500 text-small mr-[1px] rounded-r-md  border-l border-l-Gray-200'>
          {topLevelDomain}
        </div>
      </div>

      {/* helper text */}
      {helperText !== undefined && (
        <span className={helperTextClassName}>{helperText}</span>
      )}
    </div>
  )
}

URLTextInput.defaultProps = {
  description: undefined,
  helperText: undefined,
  isCustomStyles: false,
  isDisabled: false,
  isErrored: false,
  isRequired: false,
  label: undefined,
  onChange: undefined,
  onKeyPress: undefined,
  placeholder: '',
  scheme: 'https://',
  textFiledClassName: undefined,
  topLevelDomain: '.refhub.com.au',
  value: undefined,
  verifyContent: undefined,
}

export default URLTextInput
