import { useState } from 'react'
import Dropzone from 'react-dropzone'
import { Button } from '@labourhub/labour-hub-ds'
import unionIcon from 'assets/images/union.svg'
import CN from 'classnames'

import { cn } from 'utils'

type ImageUploadProps = {
  imageFile: any
  setImageFile: (imageFile: any) => void
  dropzoneDescription: string
  onDeleteClick?: () => void
  className?: string
  showRemoveBtn?: boolean
}

export const ImageUpload = ({
  imageFile,
  setImageFile,
  dropzoneDescription,
  onDeleteClick,
  className,
  showRemoveBtn = true,
}: ImageUploadProps) => {
  const [warningMessage, setWarningMessage] = useState('')

  const addFile = (files: any) => {
    files.map((file: any) => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
      setImageFile(file)
    })
  }

  /** handle image upload file on drop */
  const onDrop = (accepted: any) => {
    if (
      accepted[0].type === 'image/jpeg' ||
      accepted[0].type === 'image/png' ||
      accepted[0].type === 'image/jpg'
    ) {
      addFile(accepted)
      setWarningMessage('')
    } else {
      const message = 'Please submit valid file type (jpg, png, jpeg)'
      setWarningMessage(message)
    }
  }

  return (
    <div className='flex flex-col w-full my-1'>
      {imageFile ? (
        <div className='flex items-center justify-between w-full mr-10 space-x-4'>
          <img
            src={imageFile.preview || imageFile}
            alt='drag-and-drop-area'
            className={cn('rounded h-[80px] w-full', className)}
          />
          {showRemoveBtn && (
            <Button
              icon={
                <i className='ri-delete-bin-6-line text-Red-500 text-heading-5' />
              }
              isIconOnly
              onClick={() => onDeleteClick && onDeleteClick()}
              className='w-8 h-8 my-1 ml-2 border-2 border-Red-500'
              textTheme='red'
              theme='white'
            />
          )}
        </div>
      ) : (
        <div className='border-2 rounded-md cursor-pointer border-dashed border-Gray-300 '>
          <Dropzone
            key={Math.random()}
            multiple={false}
            onDrop={(accepted) => {
              onDrop(accepted)
            }}>
            {({ getRootProps, getInputProps }) => (
              <div id={Math.random().toString()}>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div
                    className={CN(
                      'flex flex-col justify-center items-center space-y-1',
                    )}>
                    <img
                      src={unionIcon}
                      className='px-[26px] py-[10px] h-[60px]'
                      alt='drag-and-drop-area'
                    />

                    <div className='font-Regular text-extra-small text-Gray-400 text-center w-[70%]'>
                      {dropzoneDescription}
                    </div>

                    <p className='flex items-center justify-center pt-3 pb-2 space-x-3 font-Medium text-Cobalt-600'>
                      <i className='ri-upload-2-line text-heading-5'></i>
                      <span>Upload a photo</span>
                    </p>
                  </div>
                </div>
              </div>
            )}
          </Dropzone>
        </div>
      )}
      {warningMessage != '' && (
        <span className='my-2 text-Red-600'> {warningMessage}</span>
      )}
    </div>
  )
}
