import { ReactNode } from 'react'

type DragButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  children: ReactNode
  className?: string
  [x: string]: any
}

export const DragButton = ({
  onClick,
  className,
  children,
  ...props
}: DragButtonProps) => {
  const buttonClasses = `
    flex justify-center items-center rounded h-[30px] w-[30px]
    text-Gray-600 bg-Gray-50 border border-Gray-200
    hover:text-Cobalt-600 hover:bg-Cobalt-50 hover:border-Cobalt-200
    ${className}
  `

  return (
    <button className={buttonClasses.trim()} onClick={onClick} {...props}>
      {children}
    </button>
  )
}
