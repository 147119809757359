type AgencyProfileAboutCardProps = {
  about?: string
}

export const AgencyProfileAboutCard = ({
  about,
}: AgencyProfileAboutCardProps) => {
  return (
    /** About section of agency profile */
    <div className='pt-6 agencyProfile-about'>
      <div className='flex flex-col w-full h-auto overflow-y-auto border-[1px] border-Gray-200 rounded-md bg-white pb-4'>
        <span className='pt-4 pl-4 font-Medium text-heading-4 text-Gray-800'>
          About
        </span>

        <span className='px-4 pt-2 font-Regular text-Gray-800'>{about}</span>
      </div>
    </div>
  )
}
