export const breadcrumbData = {
  'home': {
    text: 'Home',
  },
  'candidate-list': {
    text: 'Candidates',
    subText: 'candidate_name',
    hasQueryParam: true,
  },
  'employee-list': {
    text: 'Employees',
    subText: 'candidate_name',
    hasQueryParam: true,
  },
  'questionnaire-list': {
    text: 'Questionnaires',
  },
  'questionnaires': {
    text: 'Questionnaire',
  },
  'build-new-questionnaire': {
    text: 'Build New Questionnaire',
  },
  'user-groups': {
    text: 'Users',
    subText: 'user_role',
    hasQueryParam: true,
  },
  'user-list': {
    text: 'Users',
    subText: 'Permissions',
    hasQueryParam: false,
  },
  'super-admin': {
    text: 'Super Admin Dashboard',
  },
  'super-admin/*': {
    hasParam: true,
    param: 'tenantId',
    text: 'Company Configurations',
  },
  'settings': {
    text: '',
  },
}
