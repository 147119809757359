import { calculatePromotionAmount } from 'features/promotions'
import { RootState } from 'store/reducers'

import { AmountSummary } from '../types'

// Create a selector function to calculate the sub-total
export const getPayAsYouGoPaymentAmountSummary = (
  state: RootState,
): AmountSummary => {
  const { payAsYouGoCredits } = state.payment
  const { payAsYouGoUnitPrices } = state.subscription
  const { promotion } = state.promotion

  const { subTotal, promotionAmount } = calculatePromotionAmount(
    promotion,
    payAsYouGoCredits,
    payAsYouGoUnitPrices,
  )

  const tax = (subTotal - promotionAmount) * 0.1

  const totalAmount = subTotal - promotionAmount + tax

  return { subTotal, promotionAmount, tax, totalAmount }
}
