/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect, useMemo, useState } from 'react'
import { Button, SimpleSelect, Toast } from '@labourhub/labour-hub-ds'
import {
  SurveyResponseListDto,
  useGetSurveyResponsesList,
} from 'api/candidates'
import CN from 'classnames'
import { surveyResponseStatusOptions } from 'features/candidates/data'
import { AnimatePresence, motion } from 'framer-motion'

import { TableHeader, UITable } from 'components/common/UITable'

import { CandidateReportDownloadModal } from './CandidateReportDownloadModal'
import { SendReminderModal } from './SendReminderModal'
import { SurveyResponseCountSection } from './SurveyResponseCountSection'
import { SurveyResponseDetailsPreviewModal } from './SurveyResponseDetailsPreviewModal'
import { SurveyResponsesListColumn } from './SurveyResponsesListColumn'

export enum SurveyCategoryType {
  Candidate = 'Candidate',
  Employee = 'Employee',
}

type SurveyResponsesTabProps = {
  candidateId: string
  candidateDetails: any
}
export const SurveyResponsesTab = ({
  candidateId,
  candidateDetails,
}: SurveyResponsesTabProps) => {
  const notify = (props: any) => Toast(props)

  /** Table States & Variables */
  const [tableData, setTableData] = useState<SurveyResponseListDto>({
    skip: 0,
    take: 0,
    surveyResponses: [],
    totalCount: 0,
  })
  const [paginationVariables, setPaginationVariables] = useState<any>({
    skip: tableData?.skip || 0,
    take: tableData?.take || 20,
    search: '',
    sortColumnName: '',
    sortDirection: '',
  })

  /** Table Filter states & Variables */
  const [searchKeyWord, setSearchKeyWord] = useState<string>('')
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false)
  const [filterActive, setFilterActive] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [selectedStatus, setSelectedStatus] = useState<any>({
    label: '',
    value: '',
  })

  const [, setFilterVariables] = useState<any>({
    status: '',
  })

  const [selectedResponse, setSelectedResponse] = useState<any>()

  const [isDownlaodSurveyReport, setIsDownlaodSurveyReport] = useState(false)

  const [showSurveyResponseDetailsModal, setShowSurveyResponseDetailsModal] =
    useState(false)

  const [isReminderModalActive, setIsReminderModalActive] = useState(false)

  /** API Handling request for Get Survey Response List */
  const {
    refetch: refreshSurveyList,
    isLoading: getSurveyListIsLoading,
    isFetching: getSurveyListIsFetching,
  } = useGetSurveyResponsesList(
    {
      candidateId: candidateId,
      take: paginationVariables.take,
      skip: paginationVariables.skip,
      search: paginationVariables.search,
      status: selectedStatus?.value || '',
      sortColumnName: paginationVariables.sortColumnName,
      sortDirection: paginationVariables.sortDirection,
    },
    (data: any) => {
      setTableData(data || {})
      setIsDownlaodSurveyReport(false)
    },
    ({ response: { data: errData } }: any) => {
      notify({
        alertHeader: errData.messageTitle ? errData.messageTitle : '',
        alertBody: errData.message ? errData.message : 'Error!',
        status: 'Error',
      })
    },
  )

  useEffect(() => {
    refreshSurveyList()
  }, [paginationVariables])

  const tableColumns = useMemo(() => {
    if (tableData?.surveyResponses?.length > 0) {
      const columns: any = SurveyResponsesListColumn(
        setSelectedResponse,
        setIsReminderModalActive,
        setShowSurveyResponseDetailsModal,
        setIsDownlaodSurveyReport,
      )
      return columns
    }
    return []
  }, [tableData])

  const applyFilter = () => {
    setFilterActive(true)
    refreshSurveyList()
    setIsFilterMenuOpen(false)
    setFilterVariables({
      status:
        selectedStatus?.value === 'Awaiting for Response'
          ? 'Awaiting for Response'
          : selectedStatus?.value === 'Response Received'
          ? 'Response Received'
          : 'Request Declined',
    })
  }

  const resetFilter = () => {
    setSelectedStatus('')
    setFilterActive(false)
    setIsFilterMenuOpen(false)

    setFilterVariables({
      status: '',
    })

    setPaginationVariables({
      ...paginationVariables,
      search: '',
    })
  }

  useEffect(() => {
    setFilterVariables({
      status: '',
    })
  }, [])

  useEffect(() => {
    surveyResponseStatusOptions?.find((item) => {
      if (item?.label === '') {
        setSelectedStatus({
          ...selectedStatus,
          label: item?.label,
          value: item?.value,
        })
      }
    })
  }, [surveyResponseStatusOptions])

  return (
    <div className='w-full h-full'>
      {selectedResponse && (
        <>
          <SurveyResponseDetailsPreviewModal
            isModalActive={showSurveyResponseDetailsModal}
            setIsModalActive={setShowSurveyResponseDetailsModal}
            candidateDetails={candidateDetails?.basicCandidateProfile}
            selectedResponse={selectedResponse}
          />

          <CandidateReportDownloadModal
            isModalActive={isDownlaodSurveyReport}
            setIsModalActive={setIsDownlaodSurveyReport}
            selectedResponse={selectedResponse}
            candidateDetails={candidateDetails?.basicCandidateProfile}
          />
        </>
      )}

      <SendReminderModal
        isModalActive={isReminderModalActive}
        setIsModalActive={setIsReminderModalActive}
        candidateId={candidateId}
        selectedResponse={selectedResponse}
        refreshSurveyList={refreshSurveyList}
      />

      <SurveyResponseCountSection
        stats={candidateDetails?.basicCandidateProfile}
      />

      <div className='flex flex-col w-full px-4 bg-white border rounded-t-lg outline-none border-Gray-100'>
        <TableHeader
          isFilterEnable={true}
          filterActive={filterActive}
          searchKeyWord={searchKeyWord}
          setSearchKeyWord={setSearchKeyWord}
          setIsFilterMenuOpen={setIsFilterMenuOpen}
          searchFieldPlaceholder={'Search user with survey Name'}
          isFilterMenuOpen={isFilterMenuOpen}
          paginationVariables={paginationVariables}
          setPaginationVariables={setPaginationVariables}></TableHeader>
        {/* expanding filter panel with open close animation */}
        <AnimatePresence initial={false}>
          {isFilterMenuOpen && (
            <motion.div
              className='flex w-full bg-white'
              initial='collapsed'
              animate='open'
              exit='collapsed'
              transition={{ type: 'tween', duration: 0.3 }}
              variants={{
                open: { opacity: 1, y: 0, height: 'auto' },
                collapsed: { opacity: -1, y: -35, height: 0 },
              }}>
              <div className='flex items-center w-full px-4 pt-3 pb-4 z-[4]'>
                <SimpleSelect
                  label='Status'
                  value={surveyResponseStatusOptions?.filter((item: any) => {
                    return item?.value === selectedStatus?.value
                  })}
                  className='w-1/4 mr-5'
                  onChange={(selectedItem: any) => {
                    setSelectedStatus(selectedItem)
                  }}
                  placeholder='All'
                  options={surveyResponseStatusOptions}
                />

                <Button
                  className='mr-4 h-[38px] w-[139px] mt-5'
                  isDisabled={!selectedStatus?.value}
                  onClick={() => {
                    setPageNumber(1)
                    applyFilter()
                  }}>
                  Apply Filter
                </Button>
                <Button
                  className='ml-2 h-[38px] w-[100px] mt-5'
                  theme='white'
                  textTheme='blue'
                  onClick={() => {
                    resetFilter()
                  }}>
                  Reset
                </Button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div className='w-full h-[calc(90vh-80px)] relative '>
        <div
          className={CN(
            'styled-scroll overflow-auto w-full h-[calc(100%-55px)] bg-white',
          )}>
          <UITable
            data={tableData?.surveyResponses || []}
            isLoading={getSurveyListIsLoading || getSurveyListIsFetching}
            className=''
            columns={tableColumns}
            allowRowSelection={false}
            isSorted={true}
            isManualSortBy={true}
            hasFooter={true}
            hasCheckBox={true}
            isHeader={true}
            isFilterEnabled={false}
            paginationVariables={paginationVariables}
            setPaginationVariables={setPaginationVariables}
            totalRowCount={tableData?.totalCount || 0}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </div>
      </div>
    </div>
  )
}
