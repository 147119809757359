import UnderMaintenanceBG from 'assets/images/under_maintenance.svg'

import { ColumnWithAlignment } from 'components/atoms/Column'
import { RowWithAlignment } from 'components/atoms/Row'

export const UnderMaintenancePage = () => {
  return (
    <ColumnWithAlignment justifyAlignment='center' className='pt-3 h-screen'>
      <RowWithAlignment justifyAlignment='center'>
        {/* Image */}``
        <img
          src={UnderMaintenanceBG}
          alt=''
          className='flex w-[330px] justify-center items-center h-[250px]'
        />
      </RowWithAlignment>
      <div className=''>
        {/* Title */}
        <p className='mt-2 text-heading-3 text-center text-Gray-800 font-SemiBold'>
          Sorry! We Are Under Maintenance
        </p>
        {/* Subtitle*/}
        <p className='mb-2 text-center text-heading-4 w-[620px] text-Gray-500'>
          We apologize for the inconvenience, but our website is currently
          undergoing maintenance. Please check back shortly.<br></br>
          <b className='text-Gray-900'> Thank you for your patience.</b>
        </p>
      </div>
    </ColumnWithAlignment>
  )
}
