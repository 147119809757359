import React from 'react'
import { Modal } from '@labourhub/labour-hub-ds'

type DeleteAddonModalProps = {
  isOpenAddonDelete: boolean
  deleteMessage: string
  setIsOpenAddonDelete: (value: React.SetStateAction<boolean>) => void
  handleDeleteConfirm: () => void
}

const DeleteAddonConfirmationModal = ({
  isOpenAddonDelete,
  setIsOpenAddonDelete,
  handleDeleteConfirm,
  deleteMessage,
}: DeleteAddonModalProps) => {
  return (
    <Modal
      isActive={isOpenAddonDelete}
      onClickPrimaryBtn={() => {
        handleDeleteConfirm()
        setIsOpenAddonDelete(false)
      }}
      onClickSecondaryBtn={() => {
        setIsOpenAddonDelete(false)
      }}
      primaryButtonProps={{
        style: {
          width: 'fit',
          marginRight: '12px',
          backgroundColor: '#D1534C',
        },
        iconLeft: <i className='ri-delete-bin-line text-white'></i>,
      }}
      secondaryButtonProps={{
        style: { width: 'fit', color: '#1F2937' },
      }}
      onOverlayClick={() => {
        setIsOpenAddonDelete(false)
      }}
      modalProps={{
        style: { width: '466px' },
      }}
      footerProps={{
        style: {
          flexDirection: 'row-reverse',
          gap: '12px',
          justifyContent: 'end',
          paddingLeft: '23px',
          paddingRight: '0px',
        },
      }}
      isHeaderShow={false}
      primaryButtonTitle='Remove'
      secondaryButtonTitle='Cancel'>
      <div className='flex flex-col items-center justify-center p-6'>
        <div className='flex items-center justify-center w-12 h-12 rounded-full bg-Red-100'>
          <i className='ri-delete-bin-6-line text-Red-500 text-heading-3'></i>
        </div>

        <span className='pt-5 text-Gray-900 text-heading-5 font-Medium'>
          Remove Add-on Assessment
        </span>

        <span className='pt-2 text-center text-Gray-500 text-small font-Regular w-3/4'>
          {deleteMessage}
        </span>
      </div>
    </Modal>
  )
}

export default DeleteAddonConfirmationModal
