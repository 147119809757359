import { useEffect, useState } from 'react'

import { cn } from 'utils'

import DoughnutChart from './DoughnutChart'
import InfoCard from './InfoCard'
import RadarChart from './RadarChart'

export type PersonalityTestResponseType = {
  agreeableness: number
  conscientiousness: number
  extraversion: number
  neuroticism: number
  opennessToExperience: number
  traitAnalysisData: TraitAnalysis
}

export type TraitAnalysis = {
  traitAnalysis: TraitAnalysisType
}

export type TraitAnalysisType = {
  [trait: string]: {
    score: number
    description: string
  }
}

const traitIcons: Record<string, string> = {
  extraversion: 'ri-user-voice-line',
  agreeableness: 'ri-hearts-line',
  conscientiousness: 'ri-settings-2-line',
  neuroticism: 'ri-emotion-unhappy-line',
  opennessToExperience: 'ri-lightbulb-line',
}

export default function PersonalityTestResult({
  reportViewData,
  isReport = false,
  isPrint = false,
  position = 0,
}: {
  reportViewData: any
  isReport?: boolean
  isPrint?: boolean
  position?: number
}) {
  const [personalityTestResponse, setPersonalityTestResponse] = useState<
    PersonalityTestResponseType | undefined
  >()

  useEffect(() => {
    setPersonalityTestResponse(reportViewData?.personalityTestResponse)
  }, [reportViewData])

  const formatHeading = (trait) => {
    // Split camel case into words and capitalize the first letter of each
    return trait
      .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space before capital letters
      .replace(/^./, (str) => str.toUpperCase()) // Capitalize the first letter
  }
  return (
    <div
      className={cn('relative styled-scroll bg-Gray-50  overflow-y-auto ', {
        'w-[1261px] p-6 ': !isReport,
        'w-[747px] mx-auto': isReport,
      })}>
      {personalityTestResponse && (
        <>
          <div
            className={cn('break-inside-avoid', {
              'h-fit w-full': !isReport,
              'min-h-[600px] h-fit w-[747px]': isReport,
            })}
            style={{
              pageBreakBefore: position === 1 ? 'always' : 'avoid',
              paddingTop: isPrint ? '32px' : '0',
            }}>
            <h1 className='bg-Cobalt-50 text-Cobalt-800 mb-6 text-heading-5 font-Medium my-4 pl-4 h-9 flex items-center text-left'>
              Individual and Normal Comparison
            </h1>
            {personalityTestResponse?.traitAnalysisData?.traitAnalysis && (
              <div
                className={cn('flex flex-row gap-2 mb-10 ', {
                  'flex-nowrap': !isReport,
                  'flex-wrap': isReport,
                })}>
                {Object.entries(
                  personalityTestResponse?.traitAnalysisData?.traitAnalysis,
                )
                  .filter((x) => x[0] !== 'tokenUsage')
                  .map(([trait, { score }]) => {
                    return (
                      <DoughnutChart
                        icon={traitIcons[trait]}
                        heading={formatHeading(trait)}
                        value={score}
                        key={trait}
                      />
                    )
                  })}
              </div>
            )}
          </div>
          <div
            className={cn('break-inside-auto', {
              'h-fit w-full': !isReport,
              'min-h-[600px] h-fit w-[747px] ': isReport,
            })}
            style={{
              pageBreakBefore: 'always',
              paddingTop: isPrint ? '32px' : '0',
            }}>
            <h1
              className={cn(
                'bg-Cobalt-50  text-Cobalt-800 mb-8  font-Medium my-4 pl-4 h-9 flex items-center text-left',
                {
                  'text-heading-5': !isReport,
                  'text-[14px] leading-7': isReport,
                },
              )}>
              Big-Five Factor Markers (BFFM)
            </h1>
            <RadarChart
              isReport={isReport}
              personalityTestResponse={personalityTestResponse}
            />
          </div>
          <h1
            className={cn(
              'bg-Cobalt-50 text-Cobalt-800  font-Medium my-4 pl-4 h-9 flex items-center text-left',
              {
                'text-heading-5 w-full': !isReport,
                'text-[14px] leading-7 w-[747px]': isReport,
              },
            )}>
            Big-Five Factor Markers (BFFM)
          </h1>
          {personalityTestResponse?.traitAnalysisData.traitAnalysis &&
            Object.entries(
              personalityTestResponse?.traitAnalysisData.traitAnalysis,
            )
              .filter((x) => x[0] !== 'tokenUsage')
              .map(([trait, { description }]) => (
                <InfoCard
                  icon={traitIcons[trait]}
                  heading={formatHeading(trait)}
                  description={description}
                  isReport={isReport}
                  key={trait}
                />
              ))}
        </>
      )}
    </div>
  )
}
