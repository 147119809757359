import { useMutation } from 'react-query'
import { deleteAPI } from 'framework/api/http'

type DeleteExecutiveSummaryRequest = {
  id: string
}

export const useDeleteExecutiveSummary = () => {
  const mutate = useMutation(({ id }: DeleteExecutiveSummaryRequest) => {
    return deleteAPI(`/ExecutiveSummary/${id}`)
  })

  return mutate
}
