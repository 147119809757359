import { ReportAssessmentStatsCountDto } from 'api/assessment/getAssessmentCountByCandidate'

import { Column, ColumnWithAlignment } from 'components/atoms/Column'

type TotalAssessmentsSectionProps = {
  stats: ReportAssessmentStatsCountDto
}

export const TotalAssessmentsSection = ({
  stats,
}: TotalAssessmentsSectionProps) => {
  return (
    <Column className='w-full space-y-3'>
      <span className='pb-2 text-base border-b font-Medium border-Gray-200'>
        Total Assessments
      </span>
      <div className='grid grid-cols-3 gap-6 pt-2'>
        <ColumnWithAlignment
          itemAlignment='start'
          justifyAlignment='center'
          className='bg-Cobalt-50 h-[93px] text-[#085DA8] pl-4 font-Medium'>
          <div className='text-start space-y-0.5'>
            <p className='mb-1 text-small'>Assessment Sent</p>
            <p className='text-heading-4'>
              {stats?.assessmentTotalCount?.assessmentCount < 10
                ? `0${stats?.assessmentTotalCount?.assessmentCount}`
                : stats?.assessmentTotalCount?.assessmentCount}
            </p>
          </div>
        </ColumnWithAlignment>
        <ColumnWithAlignment
          itemAlignment='start'
          justifyAlignment='center'
          className='bg-Green-50 h-[93px] text-Green-800 pl-4 font-Medium'>
          <div className='text-start space-y-0.5'>
            <p className='mb-1 text-small'>Assessment Completed</p>
            <p className='text-heading-4'>
              {stats?.assessmentTotalCount?.completed < 10
                ? `0${stats?.assessmentTotalCount?.completed}`
                : stats?.assessmentTotalCount?.completed}
            </p>
          </div>
        </ColumnWithAlignment>
        <ColumnWithAlignment
          itemAlignment='start'
          justifyAlignment='center'
          className='bg-TerraCotta-50 h-[93px] text-TerraCotta-800 pl-4 font-Medium'>
          <div className='text-start space-y-0.5'>
            <p className='mb-1 text-small'>Canceled Assessment</p>
            <p className='text-heading-4'>
              {stats?.assessmentTotalCount?.cancelled < 10
                ? `0${stats?.assessmentTotalCount?.cancelled}`
                : stats?.assessmentTotalCount?.cancelled}
            </p>
          </div>
        </ColumnWithAlignment>
      </div>
    </Column>
  )
}
