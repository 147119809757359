import { Modal, Toast } from '@labourhub/labour-hub-ds'
import { CandidateMarkingStatus } from 'features/candidates/data'
import { useUpdateCandidateMarkPassFail } from 'framework/api/methods'
import { ModalProps } from 'types'

type UpdateCandidateMarkPassFailModalProps = ModalProps & {
  candidateId: string
  getCandidateById: any
  markType: CandidateMarkingStatus
}

export const UpdateCandidateMarkPassFailModal = ({
  isModalActive,
  setIsModalActive,
  candidateId,
  getCandidateById,
  markType,
}: UpdateCandidateMarkPassFailModalProps) => {
  /** API Call for Update candidate mark as pass/fail */
  const {
    mutate: updateCandidateMarkPassFailMutate,
    isLoading: updateCandidateMarkPassFailIsLoading,
  } = useUpdateCandidateMarkPassFail()

  /** Process update candidate mark as pass/fail API call */
  const updateCandidateMarkPassFail = (markingStatus: string | null) => {
    updateCandidateMarkPassFailMutate(
      {
        candidateId,
        markingStatus,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          Toast({
            alertHeader: successData.messageTitle
              ? successData.messageTitle
              : 'Success!',
            alertBody: successData?.message,
            status: 'Success',
          })

          getCandidateById()
          setIsModalActive(false)
        },

        onError: ({ response: { data: errData } }: any) => {
          Toast({
            alertHeader: errData.messageTitle ? errData.messageTitle : 'Error!',
            alertBody: errData.message,
            status: 'Error',
          })
        },
      },
    )
  }

  return (
    <Modal
      isActive={isModalActive}
      onClickPrimaryBtn={() => {
        updateCandidateMarkPassFail(
          markType == 'Passed'
            ? CandidateMarkingStatus.Passed
            : CandidateMarkingStatus.Failed,
        )
      }}
      onClickSecondaryBtn={() => setIsModalActive(false)}
      primaryButtonProps={{
        style: { width: '100%', marginRight: '12px' },
        isLoading: updateCandidateMarkPassFailIsLoading,
      }}
      secondaryButtonProps={{
        style: { width: '100%', color: '#1F2937' },
      }}
      modalProps={{
        style: { width: '466px' },
      }}
      footerProps={{
        style: {
          justifyContent: 'space-between',
          paddingLeft: '23px',
          paddingRight: '23px',
        },
      }}
      isHeaderShow={false}
      primaryButtonTitle='Yes, Continue'
      secondaryButtonTitle='Cancel'>
      <div className='flex flex-col items-center justify-center p-6'>
        <div className='flex items-center justify-center w-12 h-12 rounded-full bg-Yellow-50'>
          <i className='ri-error-warning-line text-Yellow-500 text-heading-3'></i>
        </div>

        <span className='pt-5 text-Gray-900 text-heading-5 font-Medium'>
          {markType == 'Passed'
            ? 'Mark as Passed & Completed?'
            : 'Mark as Failed & Completed?'}
        </span>

        <span className='pt-2 text-center text-Gray-500 text-small font-Regular'>
          {markType == 'Passed'
            ? 'Are you sure to make this one passed & completed?'
            : 'Are you sure to make this one failed & completed?'}
        </span>
      </div>
    </Modal>
  )
}
